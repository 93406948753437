import {FC, useContext} from 'react'

import {ExcludeCouponContext} from '../../../../providers/campaign_and_coupon/ExcludeCouponProvider'
import {paginationMetaType} from '../../../models/GeneralModel'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const ExcludedProductList: FC = () => {
  const {columns, handleOnFilter, excludedProductsData, meta, setMeta} =
    useContext(ExcludeCouponContext)
  return (
    <>
      <KrcTable
        columns={columns}
        data={excludedProductsData}
        searchableProps={{
          isSearchable: true,
          onSearch: (search, id) => {
            handleOnFilter(search, id)
          },
        }}
        paginationProps={{
          isPagination: true,
          page: meta.page,
          limit: meta.limit,
          paginationData: meta,
          onChangePage: (page) => {
            setMeta((meta: paginationMetaType) => ({...meta, page}))
          },
          onChangeLimit: (limit) => {
            setMeta((meta: paginationMetaType) => ({...meta, limit}))
          },
        }}
      ></KrcTable>
    </>
  )
}
