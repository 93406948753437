import {BarsOutlined, EditOutlined} from '@ant-design/icons'
import {Badge, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {createContext, useEffect, useMemo, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Cell, Column} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {KrcSelect} from '../../components/atoms/forms/KrcSelect'
import {usePagination} from '../../components/hooks/pagination/usePagination'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {
  IBMGroupDataType,
  IBmGroupSortListType,
  createIBMGroupDataType,
} from '../../components/models/ibm/IBMGroupModel'
import {removeEmptyItemInObject} from '../../helpers/ObjectHelper'
import {
  createIBMGroupRequest,
  fetchIBMGroupsRequest,
  updateIBMGroupRequest,
  updateIBMGroupSort,
} from '../../requests/ibm/IBMGroupRequest'
import {initialMetaData} from '../tables/ProductSelectionProvider'

interface IIBMContext {
  isLoading: boolean
  meta: paginationMetaType
  filter: any
  columns: Column<any>[]
  sortColumns: Column<any>[]
  setFilter: React.Dispatch<React.SetStateAction<any>>
  handleOnFilter: (data: any, key: string | number) => void
  IBMGroupData: IBMGroupDataType[]
  showUpdateModal: boolean
  setShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>
  willBeUpdatedGroup: IBMGroupDataType
  setWillBeUpdatedGroup: React.Dispatch<React.SetStateAction<IBMGroupDataType>>
  updateIBMGroup: (data: IBMGroupDataType) => void
  createIBMGroup: (data: createIBMGroupDataType) => void
  showCreateModal: boolean
  setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>
  IBMSortedGroups: IBmGroupSortListType[]
  setIBMSortedGroups: React.Dispatch<React.SetStateAction<IBmGroupSortListType[]>>
  isShowSortingGroupModal: boolean
  setIsShowSortingGroupModal: React.Dispatch<React.SetStateAction<boolean>>
  handleOnSaveIBMGroupSorting: () => void
  changePage: (page: number) => void
  changeLimit: (limit: number) => void
}

export const IBMContext = createContext<IIBMContext>({
  isLoading: false,
  meta: initialMetaData,
  filter: {},
  setFilter: () => {},
  handleOnFilter: () => {},
  columns: [],
  IBMGroupData: [],
  showUpdateModal: false,
  setShowUpdateModal: () => {},
  willBeUpdatedGroup: {} as IBMGroupDataType,
  setWillBeUpdatedGroup: () => {},
  updateIBMGroup: () => {},
  showCreateModal: false,
  setShowCreateModal: () => {},
  createIBMGroup: () => {},
  sortColumns: [],
  IBMSortedGroups: [],
  setIBMSortedGroups: () => {},
  isShowSortingGroupModal: false,
  setIsShowSortingGroupModal: () => {},
  handleOnSaveIBMGroupSorting: () => {},
  changePage: () => {},
  changeLimit: () => {},
})

export const IBMProvider = ({children}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const initialRef = useRef(0)
  const [filter, setFilter] = useState<any>({})
  const [IBMGroupData, setIBMGroupData] = useState<IBMGroupDataType[]>([])
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false)
  const [willBeUpdatedGroup, setWillBeUpdatedGroup] = useState<IBMGroupDataType>(
    {} as IBMGroupDataType
  )
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [IBMSortedGroups, setIBMSortedGroups] = useState<IBmGroupSortListType[]>([])
  const [isShowSortingGroupModal, setIsShowSortingGroupModal] = useState<boolean>(false)

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'ibm_group_id',
        manualWidth: 100,
        maxWidth: 100,
      },
      {
        Header: 'Grup Adı',
        accessor: 'ibm_group_name',
      },
      {
        Header: 'Grup Başlığı',
        accessor: 'ibm_group_title',
      },
      {
        Header: 'Durum',
        accessor: 'ibm_group_status',
        manualWidth: 180,
        maxWidth: 180,
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.ibm_group_status && (
                <div>
                  Aktif <Badge className={'ml-2'} color='green' />
                </div>
              )}
              {!row.original.ibm_group_status && (
                <div>
                  Pasif <Badge className={'ml-2'} color='red' />
                </div>
              )}
            </>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Yayın Durumu'}
              options={[
                {value: 1, label: 'Aktif'},
                {value: 2, label: 'Pasif'},
              ]}
              maxTagCount={'responsive'}
              style={{width: '100%'}}
              className={'ant-selector-border'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }
                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'ibm_group_status')
              }}
            />
          )
        },
      },
      {
        Header: 'Grup Sırası',
        accessor: 'ibm_group_sort',
        manualWidth: 120,
        maxWidth: 120,
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        Filter: <KrcEmptyFilter />,
        manualWidth: 120,
        maxWidth: 120,
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row justify-content-around'>
              <Tooltip title={'Düzenle'}>
                <div
                  onClick={() => {
                    setWillBeUpdatedGroup(row.original)
                    setShowUpdateModal(true)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  <EditOutlined />
                </div>
              </Tooltip>
              <Tooltip title={'Kampanyalar'}>
                <Link
                  to={`/ibm/group/${row.original.ibm_group_id}`}
                  state={{from: row.original.ibm_group_title}}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                  style={{color: 'unset'}}
                >
                  <BarsOutlined />
                </Link>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    []
  )

  const sortColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'ibm_group_id',
      },
      {
        Header: 'Grup Adı',
        accessor: 'ibm_group_name',
      },
      {
        Header: 'Grup Başlığı',
        accessor: 'ibm_group_title',
      },
    ],
    []
  )

  useEffect(() => {
    initialRef.current = 1
    getIBMGroups()
  }, [])

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }
    return debounce(loadTableFilter, 250)
  }, [])

  const getIBMGroups = () => {
    const _filter = removeEmptyItemInObject(filter)
    setIsLoading(true)
    fetchIBMGroupsRequest(_filter, meta)
      .then((response) => {
        const _meta = response.data.meta
        updateMetaData({limit: _meta.per_page, page: _meta.current_page, ..._meta})
        setIBMGroupData(response.data.data)
      })
      .catch((error) => {
        message.error('IBM Kampanya Grupları getirilirken bir hata oluştu.')
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const {meta, updateMetaData, changePage, changeLimit} = usePagination({
    callback: getIBMGroups,
    filter,
  })

  const updateIBMGroup = (data: IBMGroupDataType) => {
    message.info('IBM Kampanya Grubu güncelleniyor...')
    updateIBMGroupRequest(data.ibm_group_id, data)
      .then((response) => {
        message.success('IBM Kampanya Grubu başarıyla güncellendi.')
        setShowUpdateModal(false)
        getIBMGroups()
        setShowUpdateModal(false)
      })
      .catch((error) => {
        message.error('IBM Kampanya Grubu güncellenirken bir hata oluştu.')
        message.error(error.response.data.message)
      })
  }

  const createIBMGroup = (data: createIBMGroupDataType) => {
    message.info('IBM Kampanya Grubu oluşturuluyor...')
    createIBMGroupRequest(data)
      .then((response) => {
        message.success('IBM Kampanya Grubu başarıyla oluşturuldu.')
        setShowCreateModal(false)
        getIBMGroups()
      })
      .catch((error) => {
        message.error('IBM Kampanya Grubu oluşturulurken bir hata oluştu.')
        message.error(error.response.data.message)
      })
  }

  const handleOnSaveIBMGroupSorting = () => {
    let sortedGroupIds = IBMSortedGroups.map((item) => item.ibm_group_id)
    message.info('Kampanyalar sıralanıyor... Bu işlem biraz uzun sürebilir. Lütfe bekleyiniz.')
    updateIBMGroupSort(sortedGroupIds)
      .then((res) => {
        message.success('Kampanyalar sıralandı.')
      })
      .catch((err) => {
        message.error('Kampanyalar sıralanırken bir hata oluştu.')
        message.error(err.response.data.message)
      })
      .finally(() => {
        getIBMGroups()
        setIsShowSortingGroupModal(false)
      })
  }

  return (
    <>
      <IBMContext.Provider
        value={{
          isLoading,
          meta,
          filter,
          setFilter,
          columns,
          handleOnFilter,
          IBMGroupData,
          showUpdateModal,
          setShowUpdateModal,
          willBeUpdatedGroup,
          setWillBeUpdatedGroup,
          updateIBMGroup,
          showCreateModal,
          setShowCreateModal,
          createIBMGroup,
          sortColumns,
          IBMSortedGroups,
          setIBMSortedGroups,
          isShowSortingGroupModal,
          setIsShowSortingGroupModal,
          handleOnSaveIBMGroupSorting,
          changePage,
          changeLimit,
        }}
      >
        {children}
      </IBMContext.Provider>
    </>
  )
}
