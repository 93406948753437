import debounce from 'lodash/debounce'
import {Moment} from 'moment'
import React, {useEffect, useRef, useState} from 'react'

import {LogType} from '../../../../enums/LogType'
import {initialMetaData} from '../../../../providers/tables/CategorySelectionProvider'
import {getMenuLogsRequest} from '../../../../requests/menus/MenuRequest'
import {viewDateFormat} from '../../../../service/DateService'
import {KrcAlert} from '../../../atoms/KrcAlert'
import {KrcEmptyFilter} from '../../../atoms/KrcEmptyFilter'
import {KrcRangePicker} from '../../../atoms/forms/KrcRangePicker'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {paginationMetaType} from '../../../models/GeneralModel'
import {menuLogFilterType, menuLogType} from '../../../models/menus/MenuModel'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const MenuActivityList = () => {
  const [data, setData] = useState<any[]>([])
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [filter, setFilter] = useState<menuLogFilterType>({})
  const fetchRequestRef = useRef(0)
  const initialRef = useRef(0)

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Menü',
        accessor: 'menu_id',
        Cell: ({row}: any) => {
          return (
            <>
              <span>Adı: {row.original.menu_name ? row.original.menu_name : '-'}</span>
              <div className='text-muted'>ID: {row.original.menu_id}</div>
            </>
          )
        },
        maxWidth: 100,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Store',
        accessor: 'store_id',
        Cell: ({row}: any) => {
          return (
            <>
              <span>Adı: {row.original.store_name ? row.original.store_name : '-'}</span>
              <div className='text-muted'>ID: {row.original.store_id}</div>
            </>
          )
        },
        maxWidth: 100,
        Filter: <KrcEmptyFilter />,
      },

      {
        Header: 'İşlem Türü',
        accessor: 'action_text',
        maxWidth: 100,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={undefined}
                placeholder={'İşlem Filtresi'}
                options={[
                  {value: 'create', label: 'Oluşturma'},
                  {value: 'update', label: 'Güncelleme'},
                  {value: 'delete', label: 'Silme'},
                ]}
                style={{width: 120}}
                className={'ant-selector-border'}
                onChange={(data) => {
                  handleOnFilter(data, 'action')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Durum',
        accessor: 'type',
        maxWidth: 130,
        manualWidth: 130,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={undefined}
                placeholder={'Durum Filtresi'}
                options={[
                  {value: 'success', label: 'Başarılı'},
                  {value: 'error', label: 'Başarısız'},
                ]}
                style={{width: 120}}
                className={'ant-selector-border'}
                onChange={(data) => {
                  handleOnFilter(data, 'type')
                }}
              />
            </>
          )
        },
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                {row.original.type === LogType.SUCCESS ? (
                  <KrcAlert message='Başarılı' type='success' className={'py-1'} />
                ) : (
                  <></>
                )}

                {row.original.type === LogType.ERROR ? (
                  <KrcAlert message='Başarısız' type='error' className={'py-1'} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        },
      },
      {
        Header: 'İşlemi Gerçekleşiren \n Kullanıcı Adı',
        accessor: 'user_name',
        maxWidth: 150,
        manualWidth: 150,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Açıklama',
        accessor: 'message',
        Cell: ({row}: any) => <div dangerouslySetInnerHTML={{__html: row.original.message}}></div>,
        maxWidth: 250,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Gerçekleşme Tarihi',
        accessor: 'created_at',
        maxWidth: 100,
        Cell: ({row}: any) => viewDateFormat(row.original.created_at),
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                onSelected={handleOnSelectedRangePicker}
                size={'middle'}
                showTime={true}
                format={'DD.MM.YYYY HH:mm'}
              />
            </>
          )
        },
      },
    ]
  }, [])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getMenuActivityLogs()
  }, [filter])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getMenuActivityLogs()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getMenuActivityLogs()
  }, [meta.page])

  useEffect(() => {
    initialRef.current = 1

    getMenuActivityLogs()
  }, [])

  const getMenuActivityLogs = () => {
    fetchRequestRef.current += 1
    const fetchId = fetchRequestRef.current

    getMenuLogsRequest(filter, meta).then((res) => {
      if (fetchRequestRef.current !== fetchId) {
        return
      }

      const _data = res.data.data.map((item: menuLogType) => {
        return {
          menu_id: item.menu_id,
          menu_name: item.menu?.title,
          store_id: item.store_id,
          store_name: item.store?.name,
          user_id: item.user_id,
          user_name: item.user?.fullname,
          action: item.action,
          action_text: item.action_text,
          type: item.type,
          message: item.message,
          created_at: viewDateFormat(item.created_at),
        }
      })

      setData(_data)
      const resMeta = res.data.meta
      setMeta({limit: resMeta.per_page, page: resMeta.current_page, ...resMeta})
    })
  }

  const handleOnSelectedRangePicker = (date: Moment[] | null) => {
    if (!date) {
      setFilter((data) => {
        return {...data, created_at_range: undefined}
      })
      return
    }

    setFilter((data) => {
      return {
        ...data,
        created_at_range: [date[0].toISOString(), date[1].toISOString()],
      }
    })
  }

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data?: menuLogFilterType) => {
        if (!data) {
          return {[rowId]: value}
        }

        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  return (
    <>
      <KrcTable
        columns={columns}
        data={data}
        searchableProps={{
          isSearchable: true,
          onSearch: handleOnFilter,
        }}
        paginationProps={{
          isPagination: true,
          paginationData: meta,
          page: meta.page,
          limit: meta.limit,
          onChangePage: (page: number) => {
            setMeta({...meta, page})
          },
          onChangeLimit: (limit: number) => {
            setMeta({...meta, limit})
          },
        }}
      />
    </>
  )
}
