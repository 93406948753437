import debounce from 'lodash/debounce'
import {OptionData} from 'rc-select/lib/interface'
import React, {useState} from 'react'

import {getBadgesRequest} from '../../../requests/badges/BadgeRequest'
import {IBadge} from '../../models/badges/BadgeModel'

export const useBadgeFilter = (debounceTime = 500) => {
  const [options, setOptions] = useState<OptionData[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [badgeIds, setBadgeIds] = useState<number[] | number>([])
  const [selectedItems, setSelectedItems] = useState<OptionData[] | OptionData>([])
  const fetchRef = React.useRef(0)

  const handleSearch = React.useMemo(() => {
    const loadOptions = (search: string) => {
      if (search.length < 2) {
        return
      }

      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      getBadgesRequest({name: search}, {page: 1, limit: 10}).then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }

        const badges: OptionData[] = res.data.data.map((badge: IBadge) => {
          return {value: badge.badge_id, label: badge.name}
        })

        setOptions(badges)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTime)
  }, [])

  function handleOnChange(payload: Array<OptionData> | OptionData) {
    if (!payload) {
      setSelectedItems([])
      setBadgeIds([])
      return
    }
    setSelectedItems(payload)

    if (Array.isArray(payload)) {
      const badgeIds = payload.map((data: OptionData) => data.value) as number[]
      setBadgeIds(badgeIds)
    } else {
      const badgeId = payload.value as number
      setBadgeIds(badgeId)
    }
  }

  return {
    handleOnChange,
    handleSearch,
    badgeIds,
    fetching,
    options,
    selectedItems,
  }
}
