import {Drawer, Space} from 'antd'
import Title from 'antd/lib/typography/Title'
import React, {useContext} from 'react'

import {ExcludeCouponContext} from '../../../providers/campaign_and_coupon/ExcludeCouponProvider'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {ProductSelection} from '../../layout/filters/ProductSelection'

export const AddProductsToCouponExcludeDrawer = () => {
  const {
    isShowCreateExcludeCouponDrawer,
    setIsShowCreateExcludeCouponDrawer,
    addExcludedCouponProduct,
    setSelectedProducts,
    couponPrefixOptions,
    setWillBeExcludedCouponPrefixId,
  } = useContext(ExcludeCouponContext)

  const onClose = () => {
    setIsShowCreateExcludeCouponDrawer(false)
  }

  return (
    <>
      <Drawer
        title={
          <Title level={4} className={'mb-0 mt-1'}>
            Ürün(leri) Exclude Et
          </Title>
        }
        placement='right'
        size={'large'}
        onClose={onClose}
        keyboard={false}
        maskClosable={false}
        visible={isShowCreateExcludeCouponDrawer}
        extra={
          <Space>
            <KrcButton type={'default'} size={'large'} onClick={onClose}>
              İptal
            </KrcButton>
            <KrcButton
              size={'large'}
              onClick={() => {
                addExcludedCouponProduct()
              }}
            >
              Seçilen Ürünleri Ekle
            </KrcButton>
          </Space>
        }
      >
        <div className={'d-flex justify-content-start mb-7 mt-2'}>
          <div className={'d-flex flex-column'}>
            <div className={'d-flex'}>
              <Title level={5}>Kupon Prefix</Title>
            </div>
            <KrcSelect
              options={couponPrefixOptions.map((item) => {
                return {
                  value: item.id,
                  label: item.prefix,
                }
              })}
              onChange={(data) => {
                setWillBeExcludedCouponPrefixId(data)
              }}
              placeholder={'Lütfen seçiniz'}
              className={'select-filter-custom'}
            />
          </div>
        </div>
        {isShowCreateExcludeCouponDrawer ? (
          <ProductSelection
            headerProps={{
              titleLevel: 5,
              searchOption: {
                advancedStatus: false,
              },
            }}
            textAreaProps={{
              placeholder:
                'Model numarası veya Ürün ID ile toplu arama yapılabilir. \nÖrneğin: \n7126119 \n6949288 \n153.03.01.1091 ',
            }}
            onSelectedItems={(data) => {
              setSelectedProducts(data ?? [])
            }}
            transferProps={{
              button: {
                title: '',
                isActive: false,
              },
            }}
          />
        ) : null}
      </Drawer>
    </>
  )
}
