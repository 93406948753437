import {FC} from 'react'

import {ProductVariantProvider} from '../../../../../providers/products/updates/variants/ProductVariantProvider'
import {ProductVariantList} from './ProductVariantList'

const ProductVariantWrapperWithoutProvider: FC = () => {
  return (
    <>
      <ProductVariantList />
    </>
  )
}

export const ProductVariantWrapper: FC = () => {
  return (
    <>
      <ProductVariantProvider>
        <ProductVariantWrapperWithoutProvider />
      </ProductVariantProvider>
    </>
  )
}
