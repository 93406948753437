import React, {useContext} from 'react'

import {ScoreBoardContext} from '../../../providers/games/ScoreBoardProvider'
import {KrcTable} from './KrcTable'

export const GameScoreBoardListTable: React.FC = () => {
  const {columns, gameData} = useContext(ScoreBoardContext)
  return (
    <>
      <div className={'mr-5 ml-5'}>
        <KrcTable columns={columns} data={gameData} showHeader={true} />
      </div>
    </>
  )
}
