import {ExclamationCircleOutlined} from '@ant-design/icons'
import * as ant from 'antd'
import {message} from 'antd'
import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'

import {ModalEnum} from '../../../enums/ModalEnum'
import {MenuMapContext} from '../../../providers/tables/MenuMapProvider'
import {createMenuRequest, updateMenuRequest} from '../../../requests/menus/MenuRequest'
import {IUseMenuModal, menuModalInitValuesType} from '../../models/menus/MenuModel'

const {confirm} = ant.Modal

const initialValues = {
  icon: '',
  image: '',
  fullImage: '',
  seourl: '',
  sort: '',
  status: '',
  title: '',
  show_top_menu: '',
  link_id: '',
  link_type: '',
}

const schema = Yup.object({
  title: Yup.string().required('Menü başlığı zorunlu alan'),
  status: Yup.string().required('Menü durumu zorunlu alan'),
})

export const useMenuModal = (payload: IUseMenuModal) => {
  const [initValues, setInitValues] = useState<menuModalInitValuesType>(initialValues)
  const {
    setWillBeUpdateData,
    updateMenuData,
    getMenus,
    modalConfig,
    getParentIdByDeep,
    setVisibleCreateModal,
  } = useContext(MenuMapContext)

  useEffect(() => {
    if (payload.type === ModalEnum.UPDATE) {
      setInitValues({
        icon: payload?.menuData?.icon ?? '',
        image: payload?.menuData?.image ?? '',
        fullImage: payload?.menuData?.full_image ?? '',
        seourl: payload?.menuData?.seourl ?? '',
        sort: payload?.menuData?.sort?.toString() ?? '',
        status: Number(payload?.menuData?.status).toString() ?? '',
        title: payload?.menuData?.title ?? '',
        show_top_menu: Number(payload?.menuData?.show_top_menu).toString() ?? '',
        link_id: payload?.menuData?.link_id ? Number(payload?.menuData?.link_id).toString() : '',
        link_type: payload?.menuData?.link_type ?? '',
      })
    }
  }, [payload.type])

  const createMenu = (values: any) => {
    confirm({
      title: `Menü oluşturulacak emin misin ?`,
      icon: <ExclamationCircleOutlined />,
      content: `${values.title} başlıklı bir menü oluşturulacak ve yayına alınacak`,
      okText: 'Tamam',
      cancelText: 'İptal',
      zIndex: 9999,
      onOk() {
        const data = {
          ...values,
          store_id: payload?.menuData?.store_id,
          top: payload.deep === 0 ? 1 : 0,
          top_id: payload?.menuData?.menu_id,
        }

        const messageKey = 'create-menu'

        createMenuRequest(data, ['subMenus'])
          .then((res) => {
            if (modalConfig && getMenus) {
              if (modalConfig.deep - 1 > 0) {
                getMenus(modalConfig.deep - 1, getParentIdByDeep(modalConfig.deep - 1) ?? undefined)
              }
              getMenus(modalConfig.deep, modalConfig?.parentId ?? undefined)
            }
            message.success({content: 'Menü Oluşturuldu', key: messageKey, duration: 2})
            setVisibleCreateModal(false)
          })
          .catch((e) => {
            if (e.response.status === 409) {
              message.error({content: e.response.data.message, key: messageKey, duration: 2})
              return
            }
            message.error({content: 'Menü oluşturulamadı', key: messageKey, duration: 2})
            message.error({content: e.response.data.message, key: messageKey, duration: 4})
            setVisibleCreateModal(false)
          })
      },
    })
  }

  const updateMenu = (values: any) => {
    confirm({
      title: `Menü güncellenecek emin misin ?`,
      icon: <ExclamationCircleOutlined />,
      content: `Menü güncellenecek ve yayına alınacak`,
      okText: 'Tamam',
      cancelText: 'İptal',
      zIndex: 9999,
      onOk() {
        const messageKey = 'update-menu'
        message.loading({content: 'Menü Güncelleniyor', key: messageKey})

        if (!payload?.menuData?.menu_id) {
          message.loading({content: 'Menu bulunamadı', key: messageKey})
          return
        }

        updateMenuRequest(payload?.menuData?.menu_id, values, ['subMenus'])
          .then((res) => {
            updateMenuData(res.data.data, payload.deep)
            // if (modalConfig) {
            //   getMenus && getMenus(modalConfig.deep, modalConfig?.parentId ?? undefined)
            // }
            setWillBeUpdateData(null)
            message.success({content: 'Menü Güncellendi', key: messageKey, duration: 2})
          })
          .catch((e) => {
            if (e.response.status === 409) {
              message.error({content: e.response.data.message, key: messageKey, duration: 2})
              return
            }
            message.error({content: 'Menü Güncellenemedi', key: messageKey, duration: 2})
            message.error({content: e.response.data.message, key: messageKey, duration: 4})
          })
          .finally(() => {
            setVisibleCreateModal(false)
          })
      },
    })
  }

  const handleOnSubmit = (data: any) => {
    if (payload.type === ModalEnum.CREATE) {
      createMenu(data)
    } else if (payload.type === ModalEnum.UPDATE) {
      updateMenu(data)
    }
  }

  return {initValues, setInitValues, schema, handleOnSubmit}
}
