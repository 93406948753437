import React, {FC, useContext} from 'react'
import {useLocation} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  ProductUpdateContext,
  ProductUpdateProvider,
} from '../../../../providers/products/updates/ProductUpdateProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcTabs} from '../../../molecules/KrcTabs'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {ProductVariantWrapper} from './variant/ProductVariantWrapper'

const ProductVariantUpdateRightField: FC = () => {
  const {deleteProductVariants, setProductVariantDrawerVisible} = useContext(ProductUpdateContext)

  return (
    <>
      <div className='d-flex align-items-center py-1'>
        <KrcButton
          size={'middle'}
          type={'default'}
          onClick={() => {
            deleteProductVariants()
          }}
          id='kt_toolbar_primary_button'
        >
          {'Varyantları Sil'}
        </KrcButton>
        <KrcButton
          size={'middle'}
          className={'ml-1'}
          onClick={() => {
            setProductVariantDrawerVisible(true)
          }}
          id='kt_toolbar_primary_button'
        >
          {'Variant Ekle'}
        </KrcButton>
      </div>
    </>
  )
}

export const ProductUpdateWrapperWithoutProvider: FC = () => {
  const {product} = useContext(ProductUpdateContext)
  const location = useLocation()

  return (
    <>
      {location.hash === '#variant' ? (
        <Toolbar rightField={<ProductVariantUpdateRightField />} />
      ) : (
        <Toolbar />
      )}
      <PageTitle>{product?.details?.name} - Ürün Güncelleme</PageTitle>
      <KrcTabs
        isActiveKey={true}
        tabsProps={{defaultActiveKey: 'variant'}}
        tabProps={[
          {
            eventKey: 'variant',
            title: 'Varyant',
            children: <ProductVariantWrapper />,
          },
        ]}
      />
    </>
  )
}

export const ProductUpdateWrapper: FC = () => {
  return (
    <>
      <ProductUpdateProvider>
        <ProductUpdateWrapperWithoutProvider />
      </ProductUpdateProvider>
    </>
  )
}
