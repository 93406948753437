import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {ReviewListWrapper} from '../components/pages/reviews/ReviewListWrapper'

export const ReviewRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<ReviewListWrapper />} />
      </Routes>
    </>
  )
}
