import cn from 'classnames'
import {ErrorMessage} from 'formik'
import React, {FC} from 'react'

import {InfoTooltip} from '../../atoms/InfoTooltip'
import {KrcFormikInput} from '../../atoms/forms/KrcFormikInput'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {IKrcFormikField} from '../../models/components/KrcFormikFieldModel'

const tooltipPropsConfig = {
  status: false,
}

const advancedTooltipPropsConfig = {
  status: false,
}

const formikPropsConfig = {
  className: 'form-control',
}

export const KrcFormikField: FC<IKrcFormikField> = ({
  className = 'mb-2 col-md-6',
  labelTitle,
  required = false,
  name,
  type,
  children,
  tooltipProps,
  advancedTooltipProps,
  placeholder,
  readonly,
  labelStyle,
  autoComplete = 'off',
  formikProps,
}) => {
  const currentTooltipProps = useKrcProp({
    config: tooltipPropsConfig,
    props: tooltipProps,
  }).getCurrentProps()

  const currentFormikProps = useKrcProp({
    config: formikPropsConfig,
    props: formikProps,
  }).getCurrentProps()

  const currentAdvancedTooltipProps = useKrcProp({
    config: advancedTooltipPropsConfig,
    props: advancedTooltipProps,
  }).getCurrentProps()

  return (
    <>
      <div className={className}>
        {labelTitle && (
          <label className='form-label'>
            <div
              className={`d-flex flex-row align-items-center justify-content-center ${labelStyle}`}
            >
              <span className={cn({required: required}, 'mr-1')}>{labelTitle}</span>
              <InfoTooltip
                status={currentTooltipProps.status}
                {...currentTooltipProps}
              ></InfoTooltip>
              {currentAdvancedTooltipProps?.status && (
                <div
                  className={'pb-2 ml-1 cursor-pointer'}
                  onClick={currentAdvancedTooltipProps.onClick}
                >
                  {currentAdvancedTooltipProps.icon}
                </div>
              )}
            </div>
          </label>
        )}
        {children ? (
          children
        ) : (
          <KrcFormikInput
            {...currentFormikProps}
            type={type}
            name={name}
            readOnly={readonly}
            placeholder={placeholder}
            className={'form-control'}
            autoComplete={autoComplete}
          />
        )}
        <div className='fv-plugins-message-container text-danger'>
          <ErrorMessage name={name} />
        </div>
      </div>
    </>
  )
}
