export const PRODUCT = 'Product'
export const MANUFACTURER = 'Manufacturer'
export const CATEGORY = 'Category'
export const FREE = 'Free'
export const PACKET = 'Packet'
export const LISTING = 'Listing'
export const VIDEO = 'Video'
export const CAMPAIGN_PAGE = 'CampaignPage'
export const HELP_PAGE = 'HelpPage'
export const BROKEN_PART_PAGE = 'KirikParcaPage'
export const CATEGORY_PAGE = 'CategoryPage'
export const GIFT_PAGE = 'GiftPage'
export const STORE_PAGE = 'StorePage'
export const CART_PAGE = 'CartPage'
export const FAVORITE_PAGE = 'FavoritePage'
export const MANUFACTURER_PAGE = 'ManufacturerPage'
export const YOUTUBE = 'Youtube'
export const IFRAME = 'Iframe'
export const SHOWS = 'Shows'
export const DEALS = 'Deals'
export const GAME = 'Game'
export const WEB = 'Web'
export const GIFT_ASSISTANT = 'GiftAssistant'

export const IBM_CAMPAIGN_SEO_URL_TYPES = [
  {
    value: PRODUCT,
    label: 'Product',
  },
  {
    value: MANUFACTURER,
    label: 'Manufacturer',
  },
  {
    value: CATEGORY,
    label: 'Category',
  },
  {
    value: FREE,
    label: 'Free',
  },
  {
    value: PACKET,
    label: 'Packet',
  },
  {
    value: LISTING,
    label: 'Listing',
  },
  {
    value: VIDEO,
    label: 'Video',
  },
  {
    value: CAMPAIGN_PAGE,
    label: 'Campaign Page',
  },
  {
    value: HELP_PAGE,
    label: 'Help Page',
  },
  {
    value: BROKEN_PART_PAGE,
    label: 'Broken Part Page',
  },
  {
    value: CATEGORY_PAGE,
    label: 'Category Page',
  },
  {
    value: GIFT_PAGE,
    label: 'Gift Page',
  },
  {
    value: STORE_PAGE,
    label: 'Store Page',
  },
  {
    value: CART_PAGE,
    label: 'Cart Page',
  },
  {
    value: FAVORITE_PAGE,
    label: 'Favorite Page',
  },
  {
    value: MANUFACTURER_PAGE,
    label: 'Manufacturer Page',
  },
  {
    value: YOUTUBE,
    label: 'Youtube',
  },
  {
    value: IFRAME,
    label: 'Iframe',
  },
  {
    value: SHOWS,
    label: 'Shows',
  },
  {
    value: DEALS,
    label: 'Deals',
  },
  {
    value: GAME,
    label: 'Game',
  },
  {
    value: WEB,
    label: 'Web',
  },
  {
    value: GIFT_ASSISTANT,
    label: 'Gift Assistant',
  },
]
