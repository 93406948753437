import React, {FC, useContext} from 'react'
import {Link} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  InformationSubTopicContext,
  InformationSubTopicProvider,
} from '../../../../providers/information/InformationSubTopicProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {InformationSubTopicList} from './InformationSubTopicList'

const ToolBar: FC = () => {
  const {selectedSubTopicTitle, informationId, selectedStoreId} = useContext(
    InformationSubTopicContext
  )
  return (
    <>
      <Toolbar>
        <div>
          <Link to={`/information/${informationId}/create?store=${selectedStoreId}`}>
            <KrcButton size={'middle'} className={'mr-3'} id='kt_toolbar_primary_button'>
              Yeni Başlık Oluştur
            </KrcButton>
          </Link>
        </div>
      </Toolbar>
      {selectedSubTopicTitle && (
        <PageTitle>Bilgilendirme Sayfa Başlıkları - {selectedSubTopicTitle}</PageTitle>
      )}
    </>
  )
}

export const InformationSubTopicListWrapperWithoutProvider: FC = () => {
  return (
    <>
      <ToolBar />
      <InformationSubTopicList />
    </>
  )
}

export const InformationSubTopicListWrapper: FC = () => {
  return (
    <>
      <InformationSubTopicProvider>
        <InformationSubTopicListWrapperWithoutProvider />
      </InformationSubTopicProvider>
    </>
  )
}
