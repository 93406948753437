import React, {FC, useContext} from 'react'
import {Row} from 'react-table'

import {CategoryLandingContext} from '../../../../../providers/category/landings/CategoryLandingProvider'
import {ICategoryLandingMap} from '../../../../models/categories/CategoryModel'
import {MappingTableBody} from '../../../../organisms/mapping_table/MappingTableBody'
import {MappingTableHeader} from '../../../../organisms/mapping_table/MappingTableHeader'

export const CategoryLandingMap: FC<ICategoryLandingMap> = ({
  headerProps,
  categories,
  onSelect,
}) => {
  const {categoryColumns} = useContext(CategoryLandingContext)

  return (
    <>
      <MappingTableHeader {...headerProps}>
        <div className={'d-flex flex-column w-50'}></div>
      </MappingTableHeader>
      <MappingTableBody
        data={categories}
        columns={categoryColumns}
        onSelect={(data: Row) => {
          if (!onSelect) {
            return
          }

          onSelect(data.original)
        }}
      />
    </>
  )
}
