import {message} from 'antd'
import {Field, Form, Formik} from 'formik'
import React, {FC, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import * as Yup from 'yup'

import {CustomerContext, CustomerProvider} from '../../../providers/CustomerProvider'
import {OrderContext} from '../../../providers/orders/OrderProvider'
import {updateCustomer} from '../../../requests/customer/CustomerRequest'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'
import {KrcModal} from '../KrcModal'

const _initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  telephone: '',
  customer_group_id: 0,
  status: 0,
  is_fraud: false,
}

const schema = Yup.object({
  firstname: Yup.string().required('Zorunlu alan.'),
  lastname: Yup.string().required('Zorunlu alan.'),
  email: Yup.string().email('Invalid email').required('Zorunlu alan.'),
  telephone: Yup.string().required('Zorunlu alan.'),
  customer_group_id: Yup.number().required('Zorunlu alan.'),
  status: Yup.number().required('Zorunlu alan.'),
  is_fraud: Yup.boolean().required('Zorunlu alan.'),
})

const CustomerForm: FC = () => {
  const {order} = useContext(OrderContext)
  const {customer, getCustomers, customerGroups, getCustomerGroups} = useContext(CustomerContext)

  const [initialValues, setInitialValues] = useState<Record<string, any>>(_initialValues)

  useEffect(() => {
    getCustomerGroups()
  }, [])

  useEffect(() => {
    if (order) {
      const customerId = Number(order.invoiceCustomer.customerId)
      getCustomers(customerId)
    }
  }, [order])

  useEffect(() => {
    if (customer) {
      setInitialValues({
        firstname: customer.firstname,
        lastname: customer.lastname,
        email: customer.email,
        telephone: customer.telephone,
        customer_group_id: customer.customer_group_id,
        status: customer.status,
        is_fraud: customer.is_fraud,
      })
    }
  }, [customer])

  const handleOnSubmit = (values: any) => {
    updateCustomer(customer.customer_id, values)
      .then((data) => {
        message.success('Güncelleme başarılı.')
      })
      .catch((error) => {
        message.error('Güncelleme hatası!', error)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleOnSubmit}
      enableReinitialize
    >
      {({values, setFieldValue}) => (
        <Form className='form space-between m-5'>
          <div className={'row'}>
            <KrcFormikField
              className={'mb-3 col-6'}
              type={'text'}
              name={'firstname'}
              labelTitle={'Ad'}
            />
            <KrcFormikField
              className={'mb-3 col-6'}
              type={'text'}
              name={'lastname'}
              labelTitle={'Soyad'}
            />
          </div>
          <div className={'row'}>
            <KrcFormikField
              className={'mb-3 col-6'}
              type={'text'}
              name={'email'}
              labelTitle={'E-posta'}
            />
            <KrcFormikField
              className={'mb-3 col-6'}
              type={'text'}
              name={'telephone'}
              labelTitle={'Telefon'}
            />
          </div>
          <div className={'row'}>
            <KrcFormikField
              className={'mb-3 col-4'}
              name={'is_fraud'}
              labelTitle={'Fraud Şüphesi Var mı?'}
            >
              <Field
                as='select'
                name='is_fraud'
                className='form-select'
                onChange={(e: any) => setFieldValue('is_fraud', e.target.value === 'true')}
                value={values.is_fraud.toString()}
              >
                <option value='true'>Evet</option>
                <option value='false'>Hayır</option>
              </Field>
            </KrcFormikField>
            <KrcFormikField
              className={'mb-3 col-4'}
              name={'customer_group_id'}
              labelTitle={'Müşteri Grubu'}
            >
              <Field
                as='select'
                name='customer_group_id'
                className='form-select'
                onChange={(e: any) => setFieldValue('customer_group_id', Number(e.target.value))}
                value={values.customer_group_id || 0}
              >
                {customerGroups &&
                  customerGroups.map((customerGroup: any) => (
                    <option key={customerGroup.value} value={customerGroup.value}>
                      {customerGroup.label}
                    </option>
                  ))}
              </Field>
            </KrcFormikField>
            <KrcFormikField className={'mb-3 col-4'} name={'status'} labelTitle={'Status'}>
              <Field
                as='select'
                name='status'
                className='form-select'
                onChange={(e: any) => setFieldValue('status', Number(e.target.value))}
                value={values.status || 0}
              >
                <option value={1}>Açık</option>
                <option value={0}>Kapalı</option>
              </Field>
            </KrcFormikField>
          </div>
          <div className={'d-flex justify-content-end mt-7'}>
            <button
              type='button'
              onClick={() => {}}
              className='btn btn-secondary'
              style={{marginLeft: 10}}
            >
              İptal
            </button>
            <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
              Kaydet
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export const CustomerModal: FC<{onHide: () => void}> = ({onHide}) => {
  return (
    <KrcModal modalProps={{size: 'lg'}} modalHeaderProps={{title: 'Müşteri Güncelle', onHide}}>
      <Modal.Body className='py-lg-10 px-lg-10'>
        <CustomerForm />
      </Modal.Body>
    </KrcModal>
  )
}

export const CustomerModalWrapper: FC<{onHide: () => void}> = ({onHide}) => {
  return (
    <CustomerProvider>
      <CustomerModal onHide={onHide} />
    </CustomerProvider>
  )
}
