import {message} from 'antd'
import {Field, Form, Formik} from 'formik'
import React, {FC, useEffect} from 'react'
import * as Yup from 'yup'

import {INFORMATION_MAIN_TOPICS} from '../../../../../enums/InformationMainTopicsTypeEnum'
import {INFORMATION_STORES} from '../../../../../enums/InformationStoresTypeEnum'
import {KrcRichTextEditorWithTable} from '../../../../atoms/forms/KrcRichTextEditorWithTable'
import {KrcSelect} from '../../../../atoms/forms/KrcSelect'
import {useInformationSubTopicCreateForm} from '../../../../hooks/information/useInformationSubTopicCreateForm'
import {KrcFormikField} from '../../../../molecules/forms/KrcFormikField'

const initialValues = {
  bottom: 0,
  description: '',
  meta_description: '',
  meta_title: '',
  seo_url_keyword: '',
  status: 0,
  sort_order: 0,
  stores: [],
  title: '',
  topic_id: '',
}
const schema = Yup.object({
  title: Yup.string().required('Bilgi Başlığı zorunlu alan'),
  status: Yup.string().required('Durum zorunlu alan'),
  sort_order: Yup.number().required('Sıra zorunlu alan'),
  stores: Yup.array().required('Mağaza zorunlu alan'),
  bottom: Yup.string().required('Alt zorunlu alan'),
})

export const InformationSubTopicCreateForm: FC = () => {
  const {topicId, selectedStoreId, createInformationSubTopic} = useInformationSubTopicCreateForm()
  const [stores, setStores] = React.useState<string[]>([])
  const [formikInitialValues, setFormikInitialValues] = React.useState<any>(initialValues)
  const [parentTopic, setParentTopic] = React.useState<any>('')
  const [metaTitle, setMetaTitle] = React.useState<string>('')
  const [metaDescription, setMetaDescription] = React.useState<string>('')
  const [description, setDescription] = React.useState<string>('')

  useEffect(() => {
    if (!topicId || selectedStoreId) return
    setParentTopic(topicId)
    setStores([selectedStoreId])
    setFormikInitialValues((prev: any) => {
      return {
        ...prev,
        topic_id: topicId,
        stores: [selectedStoreId],
      }
    })
  }, [topicId, selectedStoreId])

  const handleOnSubmit = (values: any) => {
    const data = {
      ...values,
      status: Number(values.status),
      bottom: Number(values.bottom),
      description: description,
      meta_description: metaDescription,
      meta_title: metaTitle,
      stores: stores.map((store) => Number(store)),
      topic_id: Number(parentTopic),
    }

    if (data.stores.length === 0) {
      message.error('En az bir mağaza seçmelisiniz.')
      return
    }

    if (!data.meta_title) {
      message.error('Meta başlığı alanı zorunludur.')
      return
    }

    if (!data.meta_description) {
      message.error('Meta açıklaması alanı zorunludur.')
      return
    }

    if (!data.description) {
      message.error('Açıklama alanı zorunludur.')
      return
    }

    createInformationSubTopic(data)
  }

  return (
    <>
      <Formik
        initialValues={formikInitialValues}
        onSubmit={handleOnSubmit}
        validationSchema={schema}
      >
        {() => {
          return (
            <Form className={'form mt-3 space-between'}>
              <div className={'row'}>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'text'}
                    name={'title'}
                    required
                    labelTitle={'Bilgi Başlığı'}
                  />
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'text'}
                    name={'seo_url_keyword'}
                    labelTitle={'Seo Kelimesi'}
                  />
                </div>
                <div className={'col-3'}>
                  <KrcFormikField className={'mb-2'} name={'status'} labelTitle={'Durum'} required>
                    <Field as='select' name='status' className='form-select'>
                      <option value={''} disabled={true}>
                        Seçiniz
                      </option>
                      <option value={1}>Aktif</option>
                      <option value={0}>Pasif</option>
                    </Field>
                  </KrcFormikField>
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2'}
                    required
                    type={'number'}
                    name={'sort_order'}
                    labelTitle={'Sıra'}
                  />
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2 z-index-9999'}
                    name={'stores'}
                    required
                    labelTitle={'Mağazalar'}
                  >
                    <KrcSelect
                      size={'large'}
                      mode={'multiple'}
                      maxTagCount='responsive'
                      className={
                        'ant-selector-border custom-ant-select-selection-placeholder w-full'
                      }
                      defaultValue={[selectedStoreId]}
                      value={stores}
                      style={{width: '100%'}}
                      onChange={(data) => setStores(data)}
                      options={INFORMATION_STORES.map((store) => {
                        return {value: store.id.toString(), label: store.store_name}
                      })}
                    />
                  </KrcFormikField>
                </div>
                <div className={'col-3'}>
                  <KrcFormikField className={'mb-2'} name={'bottom'} required labelTitle={'Alt'}>
                    <Field as='select' name='bottom' className='form-select'>
                      <option value={''} disabled={true}>
                        Seçiniz
                      </option>
                      <option value={1}>Evet</option>
                      <option value={0}>Hayır</option>
                    </Field>
                  </KrcFormikField>
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2 z-index-9999'}
                    name={'parent'}
                    required
                    labelTitle={'Grup'}
                  >
                    <KrcSelect
                      size={'large'}
                      maxTagCount='responsive'
                      aria-readonly={true}
                      className={
                        'ant-selector-border custom-ant-select-selection-placeholder w-full'
                      }
                      defaultValue={topicId}
                      value={parentTopic}
                      style={{width: '100%'}}
                      onChange={(data) => setParentTopic(data)}
                      options={INFORMATION_MAIN_TOPICS.map((information: any) => {
                        return {
                          value: information.id.toString(),
                          label: information.topic_name,
                        }
                      })}
                    />
                  </KrcFormikField>
                </div>
              </div>
              <div className={'row mt-2'}>
                <div className={'col-6'}>
                  <div className={'form-group'}>
                    <label className={'mb-2'}>
                      Meta Başlığı<span className={'ms1 text-danger'}>*</span>
                    </label>
                    <textarea
                      className={'form-control'}
                      rows={5}
                      onChange={(e) => {
                        setMetaTitle(e.target.value)
                      }}
                      name={'meta_title'}
                      defaultValue={metaTitle}
                      value={metaTitle}
                    />
                  </div>
                </div>
                <div className={'col-6'}>
                  <div className={'form-group'}>
                    <label className={'mb-2'}>
                      Meta Açıklaması<span className={'ms1 text-danger'}>*</span>
                    </label>
                    <textarea
                      className={'form-control'}
                      rows={5}
                      onChange={(e) => {
                        setMetaDescription(e.target.value)
                      }}
                      name={'meta_description'}
                      defaultValue={metaDescription}
                      value={metaDescription}
                    />
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className={'form-group mt-3'}>
                  <label className={'mb-1'}>
                    Açıklama<span className={'text-danger ms-1'}>*</span>
                  </label>
                  <KrcRichTextEditorWithTable
                    className={'mt-4'}
                    height={300}
                    value={description}
                    onChange={(value) => {
                      if (value) {
                        setDescription(value)
                      } else {
                        setDescription('')
                      }
                    }}
                  />
                </div>
              </div>
              <div className={'d-flex justify-content-end mt-7'}>
                <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                  Kaydet
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
