import {DeleteOutlined, SettingOutlined} from '@ant-design/icons'
import {Popconfirm, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {createContext, useEffect, useMemo, useRef, useState} from 'react'
import {Cell, Column} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {PermissionDataType} from '../../components/models/permissions/PermissionModel'
import {RoleDataType, createRoleDataType} from '../../components/models/roles/RoleModel'
import {removeEmptyItemInObject} from '../../helpers/ObjectHelper'
import {
  createRoleRequest,
  deleteRoleRequest,
  fetchRolesRequest,
} from '../../requests/roles/RoleRequest'
import {
  addPermissionsToRoleRequest,
  deletePermissionFromRoleRequest,
  fetchRolePermissionsRequest,
} from '../../requests/roles/permissions/RolePermissionRequest'
import {initialMetaData} from '../tables/ProductSelectionProvider'

interface IRoleContext {
  isLoading: boolean
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  filter: any
  columns: Column<any>[]
  setFilter: React.Dispatch<React.SetStateAction<any>>
  handleOnFilter: (data: any, key: string | number) => void
  RoleData: RoleDataType[]
  showCreateModal: boolean
  setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>
  createRole: (data: createRoleDataType) => void
  deleteRole: (roleId: number) => void
  RolePermissionData: PermissionDataType[]
  permissionColumns: Column<any>[]
  hideRolePermissionModal: () => void
  assignSelectedPermissionsToRole: () => void
  willBeAddedPermissionIds: number[]
  setWillBeAddedPermissionIds: React.Dispatch<React.SetStateAction<number[]>>
  PermissionManagementTargetRole: RoleDataType | null
}

export const RoleContext = createContext<IRoleContext>({
  isLoading: false,
  meta: initialMetaData,
  setMeta: () => {},
  filter: {},
  setFilter: () => {},
  handleOnFilter: () => {},
  columns: [],
  RoleData: [],
  showCreateModal: false,
  setShowCreateModal: () => {},
  createRole: () => {},
  deleteRole: () => {},
  RolePermissionData: [],
  permissionColumns: [],
  hideRolePermissionModal: () => {},
  assignSelectedPermissionsToRole: () => {},
  willBeAddedPermissionIds: [],
  setWillBeAddedPermissionIds: () => {},
  PermissionManagementTargetRole: null,
})

export const RoleProvider = ({children}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const initialRef = useRef(0)
  const [filter, setFilter] = useState<any>({})
  const [RoleData, setRoleData] = useState<RoleDataType[]>([])

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)

  const [RolePermissionData, setRolePermissionData] = useState<PermissionDataType[]>([])
  const [PermissionManagementTargetRole, setPermissionManagementTargetRole] =
    useState<RoleDataType | null>(null)
  const [willBeAddedPermissionIds, setWillBeAddedPermissionIds] = useState<number[]>([])

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 100,
      },
      {
        Header: 'Rol Adı',
        accessor: 'name',
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        Filter: <KrcEmptyFilter />,
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row justify-content-start gap-3'>
              <Tooltip title={'Role Tanımlı İzinleri Yönet'}>
                <div
                  onClick={() => openRolePermissionModal(row.original)}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  <SettingOutlined />
                </div>
              </Tooltip>
              <Tooltip title={'Sil'}>
                <Popconfirm
                  title='Bu rolü silmek istediğinden emin misin ?'
                  onConfirm={(e) => deleteRole(Number(row.values.id))}
                  okButtonProps={{loading: isLoading}}
                  okText='Evet'
                  cancelText='Hayır'
                >
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <DeleteOutlined />
                  </div>
                </Popconfirm>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    []
  )

  const permissionColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 100,
      },
      {
        Header: 'İzin Adı',
        accessor: 'name',
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        Filter: <KrcEmptyFilter />,
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row justify-content-around'>
              <Tooltip title={'Sil'}>
                <Popconfirm
                  title='Bu izini/yetkiyi silmek istediğinden emin misin ?'
                  onConfirm={(e) => deletePermissionOfRole(row.original.id)}
                  okButtonProps={{loading: isLoading}}
                  okText='Evet'
                  cancelText='Hayır'
                >
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <DeleteOutlined />
                  </div>
                </Popconfirm>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getRoles()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    getRoles()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getRoles()
  }, [filter])

  useEffect(() => {
    initialRef.current = 1
    getRoles()
  }, [])

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }
    return debounce(loadTableFilter, 250)
  }, [])

  const getRoles = () => {
    const _filter = removeEmptyItemInObject(filter)
    setIsLoading(true)
    fetchRolesRequest(_filter, meta)
      .then((response) => {
        const _meta = response.data.meta
        setMeta({limit: _meta.per_page, page: _meta.current_page, ..._meta})
        setRoleData(response.data.data)
      })
      .catch((error) => {
        message.error('Roller getirilirken bir hata oluştu.')
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const createRole = (data: createRoleDataType) => {
    message.info('Rol oluşturuluyor...')
    createRoleRequest(data)
      .then((response) => {
        message.success('Rol başarıyla oluşturuldu.')
        setShowCreateModal(false)
        getRoles()
      })
      .catch((error) => {
        message.error('Rol oluşturulurken bir hata oluştu.')
        message.error(error.response.data.message)
      })
  }

  const deleteRole = (roleId: number) => {
    message.info('Rol siliniyor...')
    deleteRoleRequest(roleId)
      .then((response) => {
        message.success('Rol başarıyla silindi.')
        getRoles()
      })
      .catch((error) => {
        message.error('Rol silinirken bir hata oluştu.')
        message.error(error.response.data.message)
      })
  }

  const openRolePermissionModal = (role: RoleDataType) => {
    setPermissionManagementTargetRole(role)
    getRolePermissions(role)
  }

  const getRolePermissions = (role: RoleDataType) => {
    setIsLoading(true)
    fetchRolePermissionsRequest(role.id)
      .then((response) => {
        setRolePermissionData(response.data.data)
      })
      .catch((error) => {
        message.error(`Role (${role.name}) bağlı izinler getirilirken bir hata oluştu.`)
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const hideRolePermissionModal = () => {
    setPermissionManagementTargetRole(null)
    setRolePermissionData([])
  }

  const assignSelectedPermissionsToRole = () => {
    if (willBeAddedPermissionIds.length < 1) {
      message.warn('Lütfen en az bir izin seçiniz.')
      return
    }

    if (!PermissionManagementTargetRole) {
      message.error('İzin tanımlanacak ro bulunamadı. Sayfayı yenileyip tekrar dene.')
      return
    }

    setIsLoading(true)
    message.loading('Role izinl(ler) tanımlanıyor...')
    addPermissionsToRoleRequest(PermissionManagementTargetRole.id, willBeAddedPermissionIds)
      .then((response) => {
        message.success(response.data.message)
        getRolePermissions(PermissionManagementTargetRole)
        setWillBeAddedPermissionIds([])
      })
      .catch((error) => {
        message.error('Role izin(ler) tanımlanırken bir hata oluştu.')
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deletePermissionOfRole = (permissionId: number) => {
    if (!PermissionManagementTargetRole) {
      message.error('İzin kaldırılacak rol bulunamadı. Sayfayı yenileyip tekrar dene.')
      return
    }

    setIsLoading(true)
    message.loading('Rolden izin kaldırılıyor...')
    deletePermissionFromRoleRequest(PermissionManagementTargetRole.id, permissionId)
      .then((response) => {
        message.success('İzin, rolden kaldırıldı.')
        getRolePermissions(PermissionManagementTargetRole)
        setWillBeAddedPermissionIds([])
      })
      .catch((error) => {
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <RoleContext.Provider
        value={{
          isLoading,
          meta,
          setMeta,
          filter,
          setFilter,
          columns,
          handleOnFilter,
          RoleData,
          showCreateModal,
          setShowCreateModal,
          createRole,
          deleteRole,
          RolePermissionData,
          permissionColumns,
          hideRolePermissionModal,
          assignSelectedPermissionsToRole,
          willBeAddedPermissionIds,
          setWillBeAddedPermissionIds,
          PermissionManagementTargetRole,
        }}
      >
        {children}
      </RoleContext.Provider>
    </>
  )
}
