import {ConfigProvider, DatePicker} from 'antd'
import locale from 'antd/es/locale/tr_TR'
import {RangePickerProps} from 'antd/lib/date-picker'
import React, {FC} from 'react'

import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useKrcRangePicker} from '../../hooks/components/useKrcRangePicker'
import {IRangePicker} from '../../models/components/RangePickerModel'

const {RangePicker} = DatePicker

const rangePickerConfig = {
  default: {
    size: 'large',
    placeholder: ['Başlangıç Tarihi', 'Bitiş Harihi'],
  },
}

export const KrcRangePicker: FC<RangePickerProps & IRangePicker> = ({
  onSelected,
  enableReinitialize,
  ...props
}) => {
  const {filterDate, handleOnChange} = useKrcRangePicker({
    enableReinitialize,
    onSelected,
    defaultValue: props.defaultValue,
  })
  const currentProps = useKrcProp({config: rangePickerConfig.default, props}).getCurrentProps()

  return (
    <>
      <ConfigProvider locale={locale}>
        <RangePicker {...currentProps} value={filterDate} onChange={handleOnChange} />
      </ConfigProvider>
    </>
  )
}
