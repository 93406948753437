import {FC, useContext} from 'react'

import {ProductSelectionEnum} from '../../../enums/ProductSelectionEnum'
import {ProductSelectionContext} from '../../../providers/tables/ProductSelectionProvider'
import {IProductSelectionFilter} from '../../models/products/ProductSelectionModel'
import {ProductTextAreaFilter} from '../../molecules/filters/ProductTextAreaFilter'
import {ProductVerticalAdvancedFilter} from '../../organisms/filters/ProductVerticalAdvancedFilter'

export const ProductSelectionFilter: FC<IProductSelectionFilter> = ({onFilter, textAreaProps}) => {
  const {type} = useContext(ProductSelectionContext)

  return (
    <>
      {type === ProductSelectionEnum.ADVANCED ? (
        <ProductVerticalAdvancedFilter onFilter={onFilter} />
      ) : (
        <ProductTextAreaFilter onFilter={onFilter} textAreaProps={textAreaProps} />
      )}
    </>
  )
}
