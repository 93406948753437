import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, message, notification} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, {FC, useContext, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Link, useLocation, useParams} from 'react-router-dom'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {PAGE_MODE, PAGE_TYPE} from '../../../../../enums/CampaignTypeEnum'
import {errorMessage} from '../../../../../helpers/ErrorHelper'
import {
  CampaignProductContext,
  CampaignProductProvider,
} from '../../../../../providers/tables/CampaignProductProvider'
import {
  categoryPendingApprovalApproveRequest,
  categoryPendingApprovalRejectRequest,
} from '../../../../../requests/categories/products/CategoryProductRequest'
import {
  getCategoryDetailPendingApprovalProductsExportFrontendUrl,
  getCategoryHistoryApprovalProductsExportFrontendUrl,
} from '../../../../../requests/categories/products/exports/CategoryProductExportRequest'
import {KrcButton} from '../../../../atoms/buttons/KrcButton'
import {categoryUrlType} from '../../../../models/categories/CategoryProductModel'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'
import {CampaignProductList} from './list/CampaignProductList'

const {confirm} = Modal

const CampaignAction: FC = () => {
  const {categoryId} = useParams<categoryUrlType>()
  const {
    modalVisible,
    setModalVisible,
    selectedCampaignProductIds,
    note,
    setNote,
    setSelectedCampaignProductIds,
    getCategoryPendingApproval,
  } = useContext(CampaignProductContext)

  return (
    <>
      <div className='category-products-footer'>
        <div className='category-products-footer-actions w-100 d-flex justify-content-end'>
          <KrcButton
            className={'mt-3'}
            size={'large'}
            danger={true}
            onClick={() => {
              setModalVisible(true)
            }}
            disabled={selectedCampaignProductIds.length === 0}
          >
            Reddet
          </KrcButton>

          <Modal
            visible={modalVisible}
            title={`Reddetmek istediğinizden emin misiniz? `}
            okText={'Devam'}
            cancelText={'Vazgeç'}
            onCancel={() => {
              setModalVisible(false)
            }}
            onOk={() => {
              const products = selectedCampaignProductIds.map(
                (productToCategoryApprovalId: any) => {
                  return {product_to_category_approval_id: productToCategoryApprovalId, note}
                }
              )
              const data = {
                will_be_rejected_products: [...products],
              }

              categoryPendingApprovalRejectRequest(Number(categoryId), data)
                .then(() => {
                  setSelectedCampaignProductIds([])
                  getCategoryPendingApproval({page: 1})
                  setModalVisible(false)
                })
                .catch((error) => {
                  errorMessage(error)
                })
            }}
          >
            {' '}
            <TextArea
              rows={3}
              placeholder={'Reddediğiniz ürünler için not girmek ister misiniz?'}
              onChange={(e) => {
                if (!setNote) {
                  return
                }

                setNote(e.target.value)
              }}
            />
            <div>
              Yapılan tüm değişiklikler Reddedilecektir. Devam etmek istediğinizden emin misiniz?
            </div>
          </Modal>

          <KrcButton
            type={'primary'}
            className={'mt-3 ml-3'}
            size={'large'}
            disabled={selectedCampaignProductIds.length === 0}
            onClick={() => {
              confirm({
                title: `Onaylamak istediğinizden emin misiniz? `,
                icon: <ExclamationCircleOutlined />,
                content:
                  'Yapılan tüm değişiklikler Onaylanacaktır. Devam etmek istediğinizden emin misiniz?',
                okText: 'Devam',
                cancelText: 'Vazgeç',
                onOk() {
                  const key = 'pending-approval-approve-product'
                  message.loading({content: 'Ürünler onaylanıyor...', key, duration: 0})

                  categoryPendingApprovalApproveRequest(Number(categoryId), {
                    will_be_approved_products: selectedCampaignProductIds.map(
                      (productToCategoryApprovalId: any) => {
                        return {product_to_category_approval_id: productToCategoryApprovalId}
                      }
                    ),
                  })
                    .then((res) => {
                      if (res.data.errors.length > 0) {
                        notification.warning({
                          className: 'custom-notification',
                          style: {width: 500},
                          message: `Ürünler eklenemedi.`,
                          description: res.data.errors.map(
                            (errorMessage: {product_id: number; message: string}) => (
                              <div
                                className={'pb-2 pt-2'}
                                style={{borderBottom: '1px solid #3333'}}
                                dangerouslySetInnerHTML={{__html: errorMessage.message}}
                              ></div>
                            )
                          ),
                          duration: 0,
                          placement: 'top',
                        })
                      }
                      if (res.data.status) {
                        message.success({
                          content: 'Ürünler başarıyla onayladı ve yayına alındı.',
                          key,
                        })
                      } else {
                        message.destroy(key)
                      }

                      getCategoryPendingApproval({page: 1})
                    })
                    .catch((error) => {
                      message.error({
                        content:
                          'Ürünler onaylanamadı. Sunucuda bir hata oluştu lütfen sayfayı yenileyip tekrar deneyiniz.',
                        key,
                      })
                      errorMessage(error)
                    })
                },
              })
            }}
          >
            Onayla
          </KrcButton>
        </div>
      </div>
    </>
  )
}

const CampaignPendingApprovalProductsWrapperWithProvider: FC = () => {
  const {categoryId} = useParams<categoryUrlType>()
  const {campaign, pageType, filterPendingApprovalCampaignHistorySearchData} =
    useContext(CampaignProductContext)
  const {user} = useSelector((state: any) => state.auth)
  const location = useLocation()

  const [pageMode, setPageMode] = React.useState<PAGE_MODE.NORMAL | PAGE_MODE.MY_REQUESTS>(
    user?.data?.can_approval_campaign ? PAGE_MODE.NORMAL : PAGE_MODE.MY_REQUESTS
  )

  useEffect(() => {
    if (user?.data?.can_approval_campaign) {
      setPageMode(PAGE_MODE.NORMAL)

      return
    }

    setPageMode(PAGE_MODE.MY_REQUESTS)
  }, [location, user])

  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <div className='me-4' style={{marginLeft: 'auto'}}>
            <Link
              to={
                pageType === PAGE_TYPE.CURRENT
                  ? `/categories/${categoryId}/products/pending-approvals/history`
                  : `/categories/${categoryId}/products/pending-approvals`
              }
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-2'
            >
              {PAGE_TYPE.HISTORY === pageType ? 'Güncel' : 'Geçmiş'}
            </Link>
            {PAGE_TYPE.CURRENT === pageType ? (
              <a
                type={'button'}
                className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                href={getCategoryDetailPendingApprovalProductsExportFrontendUrl(
                  Number(categoryId),
                  pageMode === PAGE_MODE.MY_REQUESTS
                )}
                target={'_blank'}
                rel='noreferrer'
              >
                Dışarı Aktar
              </a>
            ) : (
              <a
                type={'button'}
                className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                href={getCategoryHistoryApprovalProductsExportFrontendUrl({
                  categoryIds: [Number(categoryId)],
                  hasMyHistoryApprovalProductsRequests: pageMode === PAGE_MODE.MY_REQUESTS,
                  filterSearchData: filterPendingApprovalCampaignHistorySearchData,
                })}
                target={'_blank'}
                rel='noreferrer'
              >
                Dışarı Aktar
              </a>
            )}
            <Link
              to={`/categories/details/${categoryId}/products`}
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder ml-2'
              target={'_blank'}
            >
              Kampanya Detayı
            </Link>
          </div>
        </div>
      </Toolbar>
      <PageTitle>{`Kampanyaya Ürün Ekleme/Silme Onayı - ${
        campaign?.details?.name ?? ''
      }`}</PageTitle>
      <CampaignProductList />
      {user?.data?.can_approval_campaign && PAGE_TYPE.CURRENT === pageType && <CampaignAction />}
    </>
  )
}

export const CampaignPendingApprovalProductsWrapper: FC = () => {
  return (
    <>
      <CampaignProductProvider>
        <CampaignPendingApprovalProductsWrapperWithProvider />
      </CampaignProductProvider>
    </>
  )
}
