import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
export const PAGE_URL = `${API_URL}/layouts/components`

export const getComponentsRequest = () => {
  return axios.get(`${PAGE_URL}`)
}

export const getComponentRequest = (componentId: number) => {
  return axios.get(`${PAGE_URL}/${componentId}`)
}

export const updateComponentRequest = (componentId: number, body: any) => {
  return axios.put(`${PAGE_URL}/${componentId}`, body)
}

export const updateComponentsVisibleRequest = (
  data: {page_component_id: number; is_active: '1' | '0'}[]
) => {
  return axios.put(`${PAGE_URL}/visible`, {components_visible: data})
}

export const addComponentInLayoutRequest = (body: {component_ids: number[]; page_id: number}) => {
  return axios.post(`${PAGE_URL}`, body)
}

export const deleteComponentRequest = (componentId: number) => {
  return axios.delete(`${PAGE_URL}/${componentId}`)
}

export const deleteComponentsRequest = (pageComponentIds: number[]) => {
  return axios.delete(`${PAGE_URL}`, {data: {page_component_ids: pageComponentIds}})
}

export const createComponentBannerRequest = (pageComponentId: number, body: any) => {
  return axios.post(`${PAGE_URL}/${pageComponentId}/banners`, body)
}

export const sortComponentsRequest = (componentIds: number[]) => {
  return axios.put(`${PAGE_URL}/sort`, {component_ids: componentIds})
}
