import {Moment} from 'moment'
import {useEffect, useState} from 'react'

interface IUseKrcRangePicker {
  onSelected?: (data: Moment[] | null) => void
  enableReinitialize?: boolean
  defaultValue?: any // RangeValue antdesign tarafından typescript olarak sunulmadığından dolayı bu hale getirilmiştir.
}

export const useKrcRangePicker = ({
  enableReinitialize = false,
  onSelected,
  defaultValue,
}: IUseKrcRangePicker) => {
  const [filterDate, setFilterDate] = useState<Moment[] | null>()

  const handleOnChange = (date: any) => {
    if (!date) {
      setFilterDate(null)
      if (onSelected) {
        onSelected(null)
      }
      return
    }

    setFilterDate(date)

    if (!onSelected) {
      return
    }

    const dateStart = date[0]
    const dateEnd = date[1]

    onSelected([dateStart, dateEnd])
  }

  useEffect(() => {
    if (!enableReinitialize) {
      return
    }

    setFilterDate(defaultValue)
  }, [defaultValue])

  return {
    filterDate,
    handleOnChange,
  }
}
