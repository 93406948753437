import Title from 'antd/lib/typography/Title'
import React, {FC, useContext} from 'react'

import {ProductSelectionEnum} from '../../../../enums/ProductSelectionEnum'
import {ProductSelectionContext} from '../../../../providers/tables/ProductSelectionProvider'
import {useKrcProp} from '../../../hooks/components/useKrcProp'
import {IProductSelectionHeader} from '../../../models/products/ProductSelectionModel'

const initialProductSelectionHeaderProps = {
  default: {
    title: 'Ürün seçimi',
    titleLevel: 4,
    selectedItemCount: 0,
    searchOption: {
      advancedStatus: true,
      advancedTitle: 'Gelişmiş seçim',
      textStatus: true,
      textTitle: 'Metin seçimi',
    },
    selectedItemText: 'Seçili ürün sayısı',
    type: ProductSelectionEnum.TEXT,
  },
}

export const ProductSelectionHeader: FC<IProductSelectionHeader> = ({...props}) => {
  const currentProps = useKrcProp({
    config: initialProductSelectionHeaderProps.default,
    props,
  }).getCurrentProps()

  const {type, setType, selectedItems} = useContext(ProductSelectionContext)

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <div className={'d-flex align-items-center'}>
          <Title level={currentProps.titleLevel}>{currentProps.title}</Title>
          <span className='h-20px border-gray-200 border-start ms-3 mx-2 mb-2' />
          <span className={'mb-2 ml-2 text-muted'}>
            {currentProps.selectedItemText} ({selectedItems?.length || 0})
          </span>
        </div>
        {type === ProductSelectionEnum.ADVANCED && currentProps.searchOption.advancedStatus ? (
          <div
            className={'text-primary mt-1 text-end fs-5 pointer'}
            role={'button'}
            onClick={() => {
              if (!setType || !currentProps.searchOption.textStatus) {
                return
              }

              setType(ProductSelectionEnum.TEXT)
            }}
          >
            {currentProps.searchOption.textStatus ? currentProps.searchOption.textTitle : null}
          </div>
        ) : (
          <div
            className={'text-primary mt-1 text-end fs-5 pointer'}
            role={'button'}
            onClick={() => {
              if (!setType || !currentProps.searchOption.advancedStatus) {
                return
              }

              setType(ProductSelectionEnum.ADVANCED)
            }}
          >
            {currentProps.searchOption.advancedStatus
              ? currentProps.searchOption.advancedTitle
              : null}
          </div>
        )}
      </div>
    </>
  )
}
