import debounce from 'lodash/debounce'
import {Moment} from 'moment/moment'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'

import {ProductBadgeLogType} from '../../../../enums/ProductBadgeLogType'
import {ProductBadgeContext} from '../../../../providers/products/badges/ProductBadgeProvider'
import {getProductBadgeLogsRequest} from '../../../../requests/products/ProductRequest'
import {viewDateFormat} from '../../../../service/DateService'
import {KrcAlert} from '../../../atoms/KrcAlert'
import {KrcRangePicker} from '../../../atoms/forms/KrcRangePicker'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {usePagination} from '../../../hooks/pagination/usePagination'
import {productBadgeLogFilterType, productBadgeLogType} from '../../../models/products/ProductModel'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const ProductBadgeActivity: FC = () => {
  const [data, setData] = useState<any[]>([])
  const [filter, setFilter] = useState<productBadgeLogFilterType>({})
  const fetchRequestRef = useRef(0)
  const initialRef = useRef(0)
  const {setActivityFilter} = useContext(ProductBadgeContext)

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Ürün Model',
        accessor: 'model',
        maxWidth: 100,
        manualWidth: 150,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'product_name',
      },
      {
        Header: 'Badge Adı',
        accessor: 'badge_name',
        maxWidth: 150,
        manualWidth: 150,
      },
      {
        Header: 'İşlemi Gerçekleşiren \n Kullanıcı Adı',
        accessor: 'user_name',
        maxWidth: 150,
        manualWidth: 150,
      },
      {
        Header: 'Durum',
        accessor: 'type',
        maxWidth: 130,
        manualWidth: 130,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={undefined}
                placeholder={'Durum Filtresi'}
                options={[
                  {value: 'success', label: 'Başarılı'},
                  {value: 'error', label: 'Başarısız'},
                ]}
                style={{width: 120}}
                className={'ant-selector-border'}
                onChange={(data) => {
                  handleOnFilter(data, 'type')
                }}
              />
            </>
          )
        },
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                {row.original.type === ProductBadgeLogType.SUCCESS ? (
                  <KrcAlert message='Başarılı' type='success' className={'py-1'} />
                ) : (
                  <></>
                )}

                {row.original.type === ProductBadgeLogType.ERROR ? (
                  <KrcAlert message='Başarısız' type='error' className={'py-1'} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Açıklama',
        accessor: 'message',
        Cell: ({row}: any) => <div dangerouslySetInnerHTML={{__html: row.original.message}}></div>,
        maxWidth: 250,
        Filter: <></>,
      },
      {
        Header: 'Gerçekleşme Tarihi',
        accessor: 'created_at',
        maxWidth: 150,
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                onSelected={handleOnSelectedRangePicker}
                size={'middle'}
                showTime={true}
                format={'DD.MM.YYYY HH:mm'}
              />
            </>
          )
        },
      },
    ]
  }, [])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    setActivityFilter(filter)

    getProductBadgeLogs()
  }, [filter])

  useEffect(() => {
    initialRef.current = 1

    getProductBadgeLogs()
  }, [])

  const getProductBadgeLogs = () => {
    fetchRequestRef.current += 1
    const fetchId = fetchRequestRef.current

    getProductBadgeLogsRequest(filter, meta).then((res) => {
      if (fetchRequestRef.current !== fetchId) {
        return
      }

      const _data = res.data.data.map((item: productBadgeLogType) => {
        return {
          model: item.product.model,
          product_name: item.product.minimal_details?.name,
          badge_name: item.badge.name,
          user_name: item.user?.fullname,
          type: item.type,
          message: item.message,
          created_at: viewDateFormat(item.created_at),
        }
      })

      setData(_data)
      const resMeta = res.data.meta
      updateMetaData({limit: resMeta.per_page, page: resMeta.current_page, ...resMeta})
    })
  }

  const {meta, updateMetaData, changePage, changeLimit} = usePagination({
    callback: getProductBadgeLogs,
    filter: filter,
  })

  const handleOnSelectedRangePicker = (date: Moment[] | null) => {
    if (!date) {
      setFilter((data) => {
        return {...data, created_at_range: undefined}
      })
      return
    }

    setFilter((data) => {
      return {
        ...data,
        created_at_range: [date[0].toISOString(), date[1].toISOString()],
      }
    })
  }

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data?: productBadgeLogFilterType) => {
        if (!data) {
          return {[rowId]: value}
        }

        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  return (
    <>
      <KrcTable
        columns={columns}
        data={data}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            changePage(page)
          },
          onChangeLimit: (limit) => {
            changeLimit(limit)
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: handleOnFilter,
        }}
      />
    </>
  )
}
