import {message} from 'antd'
import {Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {PermissionContext} from '../../../providers/permissions/PermissionProvider'
import {
  ICreatePermissionModalProps,
  createPermissionDataType,
} from '../../models/permissions/PermissionModel'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'
import {KrcModal} from '../KrcModal'

const formikInitialValues = {
  name: '',
}

export const CreatePermissionModal: FC<ICreatePermissionModalProps> = ({onHide}) => {
  const {createPermission} = useContext(PermissionContext)
  const handleOnSubmit = (values: FormikValues) => {
    if (!values.name) {
      message.error('İzin adı boş bırakılamaz')
      return
    }
    createPermission(values as createPermissionDataType)
  }

  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
        }}
        modalHeaderProps={{
          title: 'İzin Oluştur',
          onHide,
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10'>
          <Formik initialValues={formikInitialValues} onSubmit={handleOnSubmit}>
            {() => {
              return (
                <Form className='form mt-6 space-between'>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'name'}
                        labelTitle={'İzin Adı'}
                      />
                    </div>
                  </div>
                  <div className={'d-flex justify-content-end mt-7'}>
                    <button
                      type='button'
                      onClick={onHide}
                      className='btn btn-secondary'
                      style={{marginLeft: 10}}
                    >
                      İptal
                    </button>
                    <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                      Kaydet
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
