import {Input} from 'antd'
import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext, useEffect} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {IBMContext} from '../../../../providers/ibm/IBMProvider'
import {InfoTooltip} from '../../../atoms/InfoTooltip'
import {IBMGroupDataType, IUpdateIBMGroupModalProps} from '../../../models/ibm/IBMGroupModel'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'
import {KrcModal} from '../../KrcModal'

export const UpdateIBMGroupModal: FC<IUpdateIBMGroupModalProps> = ({onHide}) => {
  const {willBeUpdatedGroup, updateIBMGroup} = useContext(IBMContext)
  const [formikInitialValues, setFormikInitialValues] = React.useState<IBMGroupDataType>(
    {} as IBMGroupDataType
  )

  useEffect(() => {
    if (!willBeUpdatedGroup) return
    setFormikInitialValues({
      ibm_group_id: willBeUpdatedGroup?.ibm_group_id,
      ibm_group_name: willBeUpdatedGroup?.ibm_group_name,
      ibm_group_title: willBeUpdatedGroup?.ibm_group_title,
      ibm_group_status: willBeUpdatedGroup?.ibm_group_status ? 1 : 0,
    })
  }, [])

  const handleOnSubmit = (values: FormikValues) => {
    updateIBMGroup(values as IBMGroupDataType)
  }
  return (
    <>
      {formikInitialValues.ibm_group_name && (
        <>
          <KrcModal
            modalProps={{
              size: 'lg',
            }}
            modalHeaderProps={{
              title: `(${willBeUpdatedGroup?.ibm_group_name}) Grubunu Düzenle`,
              onHide,
            }}
          >
            <Modal.Body className='py-lg-10 px-lg-10'>
              <Formik initialValues={formikInitialValues} onSubmit={handleOnSubmit}>
                {() => {
                  return (
                    <Form className='form mt-6 space-between'>
                      <div className={'row'}>
                        <div className={'col-6'}>
                          <label className={'mb-2 fs-6 d-flex align-items-center gap-1'}>
                            IBM Grup ID
                            <InfoTooltip status={true} text={'Düzenlenemez.'} />
                          </label>
                          <Input
                            disabled
                            value={formikInitialValues.ibm_group_id}
                            className={'form-control-lg'}
                          />
                        </div>
                        <div className={'col-6'}>
                          <label className={'mb-2 fs-6 d-flex align-items-center gap-1'}>
                            IBM Grup Adı
                            <InfoTooltip status={true} text={'Düzenlenemez.'} />
                          </label>
                          <Input
                            disabled
                            value={formikInitialValues.ibm_group_name}
                            className={'form-control-lg'}
                          />
                        </div>
                      </div>
                      <div className={'row mt-2'}>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'mb-2'}
                            type={'text'}
                            name={'ibm_group_title'}
                            labelTitle={'IBM Grup Başlığı'}
                            tooltipProps={{
                              status: true,
                              text: 'Grubun başlığını giriniz.',
                            }}
                          />
                        </div>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'w-100'}
                            name={'ibm_group_status'}
                            labelTitle={'IBM Grup Durum'}
                            required
                            tooltipProps={{
                              status: true,
                              text: 'Grubun yayın durumunu seçiniz',
                            }}
                          >
                            <Field as='select' name='ibm_group_status' className='form-select'>
                              <option value={''} disabled={true}>
                                Seçiniz
                              </option>
                              <option value={1}>Aktif</option>
                              <option value={0}>Pasif</option>
                            </Field>
                          </KrcFormikField>
                        </div>
                      </div>
                      <div className={'d-flex justify-content-end mt-7'}>
                        <button
                          type='button'
                          onClick={onHide}
                          className='btn btn-secondary'
                          style={{marginLeft: 10}}
                        >
                          İptal
                        </button>
                        <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                          Kaydet
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </Modal.Body>
          </KrcModal>
        </>
      )}
    </>
  )
}
