import {Form, Formik} from 'formik'
import React, {FC, useContext} from 'react'
import * as Yup from 'yup'

import {EMPTY_STRING} from '../../../../enums/RichTextEditorEnum'
import {CategoryDetailContext} from '../../../../providers/category/detail/CategoryDetailProvider'
import {KrcAlert} from '../../../atoms/KrcAlert'
import {KrcRichTextEditorWithTable} from '../../../atoms/forms/KrcRichTextEditorWithTable'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'
import {PickCategoryIcon} from '../../../organisms/categories/PickCategoryIcon'

const descSchema = Yup.object({
  meta_title: Yup.string(),
  meta_description: Yup.string(),
  meta_keyword: Yup.string(),
  description: Yup.string(),
})

export const CategoryDesc: FC = () => {
  const {desc, handleDescOnSubmit, onChangeDescValidate, setIsDescValid, updateDesc} =
    useContext(CategoryDetailContext)

  return (
    <>
      {desc.is_ibm_campaign && (
        <KrcAlert
          className={'mt-2'}
          style={{borderRadius: '5px'}}
          type={'warning'}
          message={'Bu Kategori IBM kampanyalı olduğu için düzenlenemez.'}
        />
      )}
      <Formik
        enableReinitialize
        validationSchema={descSchema}
        initialValues={desc}
        onSubmit={handleDescOnSubmit}
        validate={onChangeDescValidate}
        validateOnMount={true}
      >
        {(formik) => {
          setTimeout(() => {
            setIsDescValid(formik.isValid)
          }, 0)

          return (
            <Form className='form row mt-5' noValidate>
              <div
                className={'col-md-6 d-flex'}
                style={{flexDirection: 'column', paddingRight: 40}}
              >
                <KrcFormikField
                  className={'mb-2'}
                  name={'meta_title'}
                  labelTitle={'Meta Title'}
                  required={false}
                />
                <KrcFormikField
                  className={'mb-2'}
                  name={'meta_description'}
                  labelTitle={'Meta Description'}
                  required={false}
                />
                <KrcFormikField
                  className={'mb-2'}
                  name={'meta_keyword'}
                  labelTitle={'Meta Kelimeleri'}
                  required={false}
                />
                <KrcFormikField
                  className={'mb-2'}
                  name={'description'}
                  labelTitle={'Açıklama'}
                  required={false}
                >
                  <KrcRichTextEditorWithTable
                    className={'mh-500px'}
                    height={300}
                    value={desc.description}
                    onChange={(data) => {
                      if (data === EMPTY_STRING) {
                        data = ''
                      }

                      if (!data) {
                        data = ''
                      }

                      updateDesc('description', data)
                    }}
                  />
                </KrcFormikField>
              </div>
              <div className={'col-md-6 d-flex gap-5 flex-column'} style={{paddingLeft: 40}}>
                <div>
                  <PickCategoryIcon
                    defaultIcon={desc.icon}
                    onChangeIcon={(icon) => {
                      updateDesc('icon', icon.key)
                    }}
                  />
                </div>
                <div>
                  {/*<BannerImages*/}
                  {/*  title={'Kategori Web Image'}*/}
                  {/*  images={[*/}
                  {/*    'https://via.placeholder.com/125x125',*/}
                  {/*    'https://via.placeholder.com/125x125',*/}
                  {/*    'https://via.placeholder.com/125x125',*/}
                  {/*    'https://via.placeholder.com/125x125',*/}
                  {/*    'https://via.placeholder.com/125x125',*/}
                  {/*  ]}*/}
                  {/*/>*/}
                </div>
                <div>
                  {/*<BannerImages*/}
                  {/*  title={'Kategori Mobile Image'}*/}
                  {/*  images={[*/}
                  {/*    'https://via.placeholder.com/125x125',*/}
                  {/*    'https://via.placeholder.com/125x125',*/}
                  {/*    'https://via.placeholder.com/125x125',*/}
                  {/*    'https://via.placeholder.com/125x125',*/}
                  {/*    'https://via.placeholder.com/125x125',*/}
                  {/*  ]}*/}
                  {/*/>*/}
                </div>
              </div>
              {!desc.is_ibm_campaign && (
                <div className={'d-flex justify-content-end mt-7 mb-5'}>
                  <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                    Kaydet
                  </button>
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
