import {useState} from 'react'

const columns = [
  {
    Header: '',
    accessor: 'name', // accessor is the "key" in the data
  },
  {
    Header: '',
    accessor: 'count',
    maxWidth: 60,
    minWidth: 60,
    manualWidth: 60,
  },
]

export const useCategoryMap = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>()

  return {
    columns,
    selectedCategoryId,
    setSelectedCategoryId,
  }
}
