import {Modal as AndModal} from 'antd'
import {Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import * as Yup from 'yup'

import {LayoutContext} from '../../../providers/layouts/LayoutProvider'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'
import {KrcModal} from '../KrcModal'

const layoutSchema = Yup.object({
  layout_name: Yup.string().required('Layout adı zorunlu alan.').max(255),
})
const {confirm} = AndModal

export const CopyLayoutModal: FC = () => {
  const {updateCopyLayoutModalVisible, copyPage} = useContext(LayoutContext)
  const [layout, setLayout] = useState({layout_name: ''})
  const handleOnSubmit = (data: FormikValues) => {
    confirm({
      title: `Page ekleme işlemini onaylıyormusunuz?`,
      content: `Layout içerisine ${data.layout_name} isimli bir sayfa oluşturulacak emin misiniz?`,
      okText: 'Tamam',
      cancelText: 'İptal',
      zIndex: 99999,
      onOk() {
        copyPage(data.layout_name)
        setLayout(data.layout_name)
      },
    })
  }

  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
          dialogClassName: 'w-600px',
        }}
        modalHeaderProps={{
          title: 'Kopya Layout',
          onHide: () => {
            updateCopyLayoutModalVisible(false)
            setLayout({layout_name: ''})
          },
        }}
        modalDialogProps={{
          className: 'mt-0',
        }}
      >
        <Modal.Body className='py-lg-2 px-6'>
          <Formik
            enableReinitialize
            initialValues={layout}
            onSubmit={handleOnSubmit}
            validationSchema={layoutSchema}
            validateOnMount={true}
          >
            {() => {
              return (
                <Form className='form mt-2 space-between'>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <KrcFormikField
                        required
                        type={'text'}
                        className={'mb-2'}
                        name={'layout_name'}
                        labelTitle={'Layout Adı'}
                      />
                    </div>
                  </div>
                  <div className={'d-flex justify-content-end mt-3'}>
                    <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                      Kaydet
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
