import {EditOutlined, OrderedListOutlined, ProfileOutlined} from '@ant-design/icons'
import {Select, Tooltip} from 'antd'
import cn from 'classnames'
import debounce from 'lodash/debounce'
import * as querystring from 'query-string'
import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {Cell} from 'react-table'

import {
  exportCategoryListProductsRequest,
  exportCategoryRequest,
  getCategoriesRequest,
} from '../../../requests/categories/CategoryRequest'
import {
  addSelectedCategory,
  deleteSelectedCategory,
  setSelectCategories,
} from '../../../store/Category/CategoryAction'
import {KrcEmptyFilter} from '../../atoms/KrcEmptyFilter'
import {KrcNumberInput} from '../../atoms/forms/KrcNumberInput'
import {categoriesFetchParamType} from '../../models/categories/CategoryListModel'
import {
  CategoryTypeEnum,
  categoryDataType,
  minimalCategoryType,
} from '../../models/categories/CategoryModel'
import {useExport} from '../exports/useExport'
import {usePagination} from '../pagination/usePagination'
import {oldWithCategoryTypeOptions} from './useCategoryTypeFilter'

export const useCategoryList = () => {
  const [fetchParams, setFetchParams] = useState<categoriesFetchParamType>({
    status: 1,
    is_active_product: 1,
  })
  const [categoryTypeText, setCategoryTypeText] = useState('Kategori Türü')
  const [categoryStatus, setCategoryStatus] = useState({value: 1, label: 'Aktif'})
  const [isActiveProduct, setIsActiveProduct] = useState<number | null>(1)
  const fetchRef = useRef(0)
  const [categories, setCategories] = useState<minimalCategoryType[]>([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {selectedCategories} = useSelector((state: any) => state.category)
  const [exportErrorMessage, setExportErrorMessage] = useState<string>()
  const {checkExport, downloadExcelUrl} = useExport()

  const onSelectedCategories = (original: minimalCategoryType, isSelected: boolean) => {
    if (isSelected) {
      const selectedCategory = categories.find(
        (category: minimalCategoryType) => category.category_id === original.category_id
      )
      if (!selectedCategory) {
        return
      }

      dispatch(addSelectedCategory(selectedCategory))
      return
    }

    dispatch(deleteSelectedCategory(original.category_id))
  }

  const onSelectedCategoriesTest = (originals: minimalCategoryType[]) => {
    dispatch(setSelectCategories(originals))
  }

  const updateFetchParams = (value: any, key: string | number) => {
    setFetchParams((data) => ({...data, [key]: value}))
  }

  const handleOnSelectActiveProduct = (e: any) => {
    let value = e ? e.value : null

    updateFetchParams(value, 'is_active_product')
    setIsActiveProduct(value)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Kategori ID',
        accessor: 'id', // accessor is the "key" in the data
        maxWidth: 100,
        manualWidth: 100,
        Filter: () => {
          return (
            <>
              <KrcNumberInput
                style={{width: 100, padding: 1, border: '1px solid #e4e6ef'}}
                size={'middle'}
                min={0}
                className={'form-control'}
                placeholder={'Kategori ID'}
                onChange={(data) => {
                  updateFetchParams(data, 'id')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Kategori Adı',
        accessor: 'name', // accessor is the "key" in the data
        manualWidth: 400,
        maxWidth: 400,
      },
      {
        Header: () => (
          <Select
            size='middle'
            placeholder='Kategori tipi'
            defaultValue={categoryTypeText}
            filterOption={false}
            labelInValue
            onChange={handleCategoryTypeSelectChange}
            options={oldWithCategoryTypeOptions}
            showArrow
            style={{width: '100%'}}
          />
        ),
        accessor: 'category_type',
        Filter: KrcEmptyFilter,
        manualWidth: 220,
        maxWidth: 220,
      },
      {
        Header: 'Ürün sayısı',
        accessor: 'products_count',
        manualWidth: 120,
        maxWidth: 120,
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Aktif ürün sayısı',
        accessor: 'active_product_count',
        maxWidth: 220,
        manualWidth: 220,
        Filter: (
          <>
            <Select
              size='middle'
              placeholder='Durum'
              defaultValue={isActiveProduct}
              filterOption={false}
              labelInValue
              onChange={handleOnSelectActiveProduct}
              options={[
                {value: 1, label: 'Aktif Ürün Var'},
                {value: 0, label: 'Aktif Ürün Yok'},
              ]}
              showArrow
              allowClear={true}
              className='form-select'
            />
          </>
        ),
      },
      {
        Header: () => (
          <Select
            size='middle'
            placeholder='Durum'
            defaultValue={categoryStatus}
            filterOption={false}
            labelInValue
            onChange={handleCategoryStatusSelectChange}
            options={[
              {value: 1, label: 'Aktif'},
              {value: 0, label: 'Pasif'},
            ]}
            showArrow
            allowClear={true}
            className='form-select'
          />
        ),
        accessor: 'status',
        Cell: ({row}: any) => <div>{row.values.status ? 'Aktif' : 'Pasif'}</div>,
        Filter: KrcEmptyFilter,
        manualWidth: 150,
        maxWidth: 150,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: 'right',
            }}
          >
            Aksiyon
          </div>
        ),
        manualWidth: 150,
        maxWidth: 150,
        accessor: 'col5',
        id: 'click-me-button',
        Cell: ({row}: Cell) => (
          <span className='d-flex justify-content-end'>
            <Tooltip title={'Ürün Sırala'} className={cn('ml-2')}>
              <Link
                to={`/categories/${row.values.id}/sorting`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                style={{color: 'unset'}}
              >
                <OrderedListOutlined />
              </Link>
            </Tooltip>
            {row.values.category_type !== CategoryTypeEnum.GHOST && (
              <>
                <Tooltip title={'Ürün Listesi'}>
                  <Link
                    to={`/categories/details/${row.values.id}/products`}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    style={{color: 'unset'}}
                  >
                    <ProfileOutlined />
                  </Link>
                </Tooltip>
              </>
            )}
            <Tooltip title={'Güncelle'}>
              <Link
                to={`/categories/details/${row.values.id}`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                style={{color: 'unset'}}
              >
                <EditOutlined />
              </Link>
            </Tooltip>
          </span>
        ),
        Filter: KrcEmptyFilter,
      },
    ],
    []
  )

  const handleOnSearch = React.useMemo(() => {
    const loadSearch = (value: string, key: string | number) => {
      setFetchParams((data: any) => ({...data, [key]: value}))
    }
    return debounce(loadSearch, 500)
  }, [])

  const handleCategoryTypeSelectChange = (type: any) => {
    if (type && type.value === '') {
      return
    }

    setCategoryTypeText(type.value)
    setFetchParams((data: any) => ({...data, type: type.value}))
  }

  const handleCategoryStatusSelectChange = (type: any) => {
    if (!type) {
      setCategoryStatus(type)
      setFetchParams((data: any) => ({...data, status: type}))
      return
    }

    setCategoryStatus(type.value)
    setFetchParams((data: any) => ({...data, status: type.value}))
  }

  const getCategories = () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current

    getCategoriesRequest(meta, ['description'], fetchParams).then((res) => {
      if (fetchId !== fetchRef.current) {
        return
      }

      const categories = res.data.data.map((category: categoryDataType) => {
        return {
          id: category.category_id,
          category_type: category.category_type,
          status: category.status,
          products_count: category.products_count,
          active_product_count: category.active_product_count,
          name: category.details?.name,
        }
      })
      const restMeta = res.data.meta
      setCategories(categories)
      updateMetaData({limit: restMeta.per_page, page: restMeta.current_page, ...restMeta})
    })
  }

  const {meta, updateMetaData, changePage, changeLimit} = usePagination({
    callback: getCategories,
    filter: fetchParams,
  })

  const exportCategoryDetailPendingApprovalProducts = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: true,
    })

    exportCategoryRequest(params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
        if (err.response.status === 500) {
          setExportErrorMessage(err.response.data.message)
        }
      })
  }

  const exportCategoryListProducts = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: true,
    })

    exportCategoryListProductsRequest(params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
        if (err.response.status === 500) {
          setExportErrorMessage(err.response.data.message)
        }
      })
  }

  return {
    columns,
    getCategories,
    handleOnSearch,
    onSelectedCategories,
    onSelectedCategoriesTest,
    categories,
    selectedCategories,
    fetchParams,
    meta,
    changePage,
    changeLimit,
    exportCategoryDetailPendingApprovalProducts,
    exportCategoryListProducts,
    downloadExcelUrl,
    exportErrorMessage,
  }
}
