import React, {FC} from 'react'
import {useNavigate} from 'react-router-dom'

import {PageTitle} from '../../../../_metronic/layout/core'
import {ShoppingListProvider} from '../../../providers/shopping-lists/ShoppingListProvider'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {ShoppingList} from './ShoppingList'

const ShoppingListWithoutProvider: FC = () => {
  const navigate = useNavigate()
  return (
    <>
      <Toolbar
        rightField={
          <div className={'d-flex align-items-center py-1'}>
            <KrcButton
              type={'primary'}
              onClick={(e) => {
                navigate('/shopping-lists/create')
              }}
            >
              Alışveriş Listesi Oluştur
            </KrcButton>
          </div>
        }
      ></Toolbar>
      <PageTitle>Alışveriş Listeleri</PageTitle>
      <ShoppingList />
    </>
  )
}

export const ShoppingListWrapper = () => {
  return (
    <ShoppingListProvider>
      <ShoppingListWithoutProvider />
    </ShoppingListProvider>
  )
}
