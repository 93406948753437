import axios from 'axios'

import {paginationMetaType} from '../../../components/models/GeneralModel'
import {categorySortingRequestBody} from '../../../providers/category/sorting/CategoryProductSortingProvider'

const API_URL = process.env.REACT_APP_API_URL || ''
const CATEGORY_IMPORT_URL = `${API_URL}/categories`

export function updateCategoryProductsEditorChoiceSortingRequest(
  categoryId: number,
  productIds: number[]
) {
  return axios.put(
    `${CATEGORY_IMPORT_URL}/${categoryId}/products/sorts/editors-choice/manual-sorting`,
    {
      product_ids: productIds,
    }
  )
}

export function updateCategoryProductMostSellerSortingRequest(
  categoryId: number,
  sortData: categorySortingRequestBody
) {
  return axios.put(
    `${CATEGORY_IMPORT_URL}/${categoryId}/products/sorts/editors-choice/parametric-sorting`,
    sortData
  )
}

export function getCategoryProductsRequest(categoryId: number | string, meta: paginationMetaType) {
  return axios.get(`${CATEGORY_IMPORT_URL}/${categoryId}/products/sorts`, {
    params: {
      limit: meta.limit,
      page: meta.page,
    },
  })
}

export function getCategoryProductEditorChoiceManualSortingRequest(categoryId: number | string) {
  return axios.get(
    `${CATEGORY_IMPORT_URL}/${categoryId}/products/sorts/editors-choice/manual-sorting`
  )
}

export function getCategoryProductEditorsChoiceParametricSortingRequest(categoryId: number) {
  return axios.get(
    `${CATEGORY_IMPORT_URL}/${categoryId}/products/sorts/editors-choice/parametric-sorting`
  )
}

export function getCategoryProductMostSellerInputAttributesRequest(categoryId: number) {
  return axios.get(`${CATEGORY_IMPORT_URL}/${categoryId}/products/attributes`)
}

export function getCategoryProductMostSellerManufacturersTypeRequest(categoryId: number) {
  return axios.get(`${CATEGORY_IMPORT_URL}/${categoryId}/products/manufacturers`)
}

export function getActiveCategoryProductSortMethodRequest(categoryId: number) {
  return axios.get(`${CATEGORY_IMPORT_URL}/${categoryId}/products/sorts/active-method`)
}

export function deleteActiveCategoryProductSortRequest(categoryId: number) {
  return axios.delete(`${CATEGORY_IMPORT_URL}/${categoryId}/products/sorts/editors-choice`)
}

export function getActiveCategoryEditorsChoiceLogsRequest(
  categoryId: number,
  meta: paginationMetaType
) {
  return axios.get(`${CATEGORY_IMPORT_URL}/${categoryId}/products/sorts/editors-choice/logs`, {
    params: {
      limit: meta.limit,
      page: meta.page,
    },
  })
}

export function getCategoryDefaultSortingTypesRequest(categoryId: number) {
  return axios.get(`${CATEGORY_IMPORT_URL}/${categoryId}/products/sorts/default-sorting-types`)
}

export function getCategoryDefaultSortingTypeLogsRequest(
  categoryId: number,
  meta: paginationMetaType
) {
  return axios.get(
    `${CATEGORY_IMPORT_URL}/${categoryId}/products/sorts/default-sorting-types/logs`,
    {
      params: {
        limit: meta.limit,
        page: meta.page,
      },
    }
  )
}

export function updateCategoryDefaultSortingTypeRequest(
  categoryId: number,
  storeId: number | null,
  defaultSortingType: string
) {
  return axios.post(`${CATEGORY_IMPORT_URL}/${categoryId}/products/sorts/default-sorting-types`, {
    store_id: storeId,
    default_sorting_type: defaultSortingType,
  })
}

export function destroyCategoryDefaultSortingTypeRequest(
  categoryId: number,
  storeId: number | null
) {
  return axios.delete(`${CATEGORY_IMPORT_URL}/${categoryId}/products/sorts/default-sorting-types`, {
    params: {
      store_id: storeId,
    },
  })
}

export function getCategorySortsRequest({page, limit}: paginationMetaType, filters: any) {
  return axios.get(`${CATEGORY_IMPORT_URL}/sorts`, {
    params: {
      page,
      limit,
      ...filters,
    },
  })
}
