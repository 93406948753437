export const ALL = 1
export const UNTAGGED = 2
export const BUNDLE_CAMPAIGN = 3
export const DM_AFFILIATE_CAMPAIGN = 4
export const DM_CAMPAIGN = 5
export const DM_INFLUENCER_CAMPAIGN = 6
export const E_COMMERCE_CART_CAMPAIGN = 7
export const E_COMMERCE_SPECIAL_CAMPAIGN = 8
export const ENGAGEMENT_CAMPAIGN = 9
export const MARRIAGE_PACKET_CAMPAIGN = 10
export const EMPLOYEE_CAMPAIGN = 11
export const DATA_ANALYSIS_CAMPAIGN = 12

export const CAMPAIGN_TAGS = [
  {
    value: ALL,
    label: 'Hepsi',
  },
  {
    value: UNTAGGED,
    label: 'Etiketsiz',
  },
  {
    value: BUNDLE_CAMPAIGN,
    label: 'Bundle',
  },
  {
    value: DM_AFFILIATE_CAMPAIGN,
    label: 'DM Affiliate',
  },
  {
    value: DM_CAMPAIGN,
    label: 'DM Kampanyası',
  },
  {
    value: DM_INFLUENCER_CAMPAIGN,
    label: 'DM Influencer',
  },
  {
    value: E_COMMERCE_CART_CAMPAIGN,
    label: 'E-Ticaret Sepet',
  },
  {
    value: E_COMMERCE_SPECIAL_CAMPAIGN,
    label: 'E-Ticaret Özel',
  },
  {
    value: ENGAGEMENT_CAMPAIGN,
    label: 'Engagement',
  },
  {
    value: MARRIAGE_PACKET_CAMPAIGN,
    label: 'Evlilik Paketi',
  },
  {
    value: EMPLOYEE_CAMPAIGN,
    label: 'Personel Kampanyası',
  },
  {
    value: DATA_ANALYSIS_CAMPAIGN,
    label: 'Veri Analitik',
  },
]

export const OUTSOURCE_STORE = 3
export const EMSAN_STORE = 4
export const HOMEND_STORE = 5
export const KARACA_STORE = 6
export const KARACA_HOME_STORE = 7
export const KASMIR_HALI_STORE = 8
export const COOKPLUS_STORE = 9
export const JUMBO_STORE = 10
export const PERA_BULVARI_STORE = 11
export const MIX_STORE = 12

export const CAMPAIGN_COMPANIES = [
  {
    value: ALL,
    label: 'Hepsi',
  },
  {
    value: UNTAGGED,
    label: 'Etiketsiz',
  },
  {
    value: OUTSOURCE_STORE,
    label: 'Dış Marka',
  },
  {
    value: EMSAN_STORE,
    label: 'Emsan',
  },
  {
    value: HOMEND_STORE,
    label: 'Homend',
  },
  {
    value: KARACA_STORE,
    label: 'Karaca',
  },
  {
    value: KARACA_HOME_STORE,
    label: 'Karaca Home',
  },
  {
    value: KASMIR_HALI_STORE,
    label: 'Kaşmir Halı',
  },
  {
    value: COOKPLUS_STORE,
    label: 'Cookplus',
  },
  {
    value: JUMBO_STORE,
    label: 'Jumbo',
  },
  {
    value: PERA_BULVARI_STORE,
    label: 'Pera Bulvarı',
  },
  {
    value: MIX_STORE,
    label: 'Mix',
  },
]

export const LISTING_CAMPAIGN_TYPE = 1
export const PACKET_CAMPAIGN_TYPE = 2

export const CAMPAIGN_TYPES = [
  {
    value: LISTING_CAMPAIGN_TYPE,
    label: 'Listing',
  },
  {
    value: PACKET_CAMPAIGN_TYPE,
    label: 'Packet',
  },
]
