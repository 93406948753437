import React, {FC, useContext} from 'react'
import {useDispatch} from 'react-redux'

import {CategoryBannerContext} from '../../../../providers/category/detail/CategoryBannerProvider'
import {selectBanners, setBanner} from '../../../../store/Category/Banner/CategoryBannerAction'
import {CategoryBannerDrawer} from '../../../drawers/category/CategoryBannerDrawer'
import {ICategoryBanner} from '../../../models/categories/CategoryBannerModel'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const CategoryBannerList: FC = () => {
  const {
    columns,
    banners,
    banner,
    visibleDrawer,
    updateVisibleDrawer,
    drawerType,
    getCategoryBanners,
  } = useContext(CategoryBannerContext)

  const dispatch = useDispatch()

  return (
    <>
      <KrcTable
        stylesProps={{className: ['category-banner-list']}}
        columns={columns}
        data={banners}
        outerHeight={160}
        selectableProps={{
          isSelectable: true,
          selectableAlign: 'left',
          uniqueKey: 'id',
          onSelectedItems: (selectedBanners: ICategoryBanner[]) => {
            const bannerObj = new Set(banners.map((item) => item.id))
            const selectedObj = selectedBanners.filter((item) => bannerObj.has(item.id))

            dispatch(selectBanners(selectedObj))
          },
        }}
      />
      <CategoryBannerDrawer
        visible={visibleDrawer}
        type={drawerType}
        bannerData={banner}
        onHide={() => {
          updateVisibleDrawer(false)
          dispatch(setBanner(null))
        }}
        onSave={() => {
          getCategoryBanners()
        }}
      />
    </>
  )
}
