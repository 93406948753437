import {FC} from 'react'

import {useCouponHistoryList} from '../../../hooks/coupon/useCouponHistoryList'
import {paginationMetaType} from '../../../models/GeneralModel'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const CouponHistoryList: FC = () => {
  const {data, columns, meta, setMeta} = useCouponHistoryList()

  return (
    <>
      <KrcTable
        columns={columns}
        data={data}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            setMeta((data: paginationMetaType) => ({...data, page}))
          },
          onChangeLimit: (limit) => {
            setMeta((data: paginationMetaType) => ({...data, limit}))
          },
        }}
      />
    </>
  )
}
