import clsx from 'clsx'
import React, {FC, useEffect} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'

import {useKrcTabs} from '../hooks/components/useKrcTabs'
import {IKrcTabs} from '../models/components/KrcTabsModel'

export const KrcTabs: FC<IKrcTabs> = ({
  tabsProps,
  tabsClassName,
  tabProps,
  isActiveKey,
  onActiveKeyChange,
}) => {
  const {activeKey, handleOnSelect} = useKrcTabs(tabProps, tabsProps, isActiveKey)

  const initialRef = React.useRef(0)

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    onActiveKeyChange && onActiveKeyChange(activeKey)
  }, [activeKey])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  return (
    <>
      <Tabs
        className={tabsClassName}
        {...tabsProps}
        activeKey={activeKey}
        onSelect={handleOnSelect}
      >
        {tabProps.map((tabProp, index) => {
          if (tabProp.disabled !== undefined && tabProp.disabled) {
            return null
          }

          let isChanged = false

          if (tabProp.isChanged !== undefined) {
            isChanged = tabProp?.isChanged
            delete tabProp.isChanged
          }

          return (
            <Tab
              {...tabProp}
              key={`custom-tabs-${tabProp.title}-${index}`}
              tabClassName={clsx({'red-dot': isChanged, 'p-0': tabProp?.isIcon})}
            >
              {tabProp.eventKey === activeKey && tabProp.children}
            </Tab>
          )
        })}
      </Tabs>
    </>
  )
}
