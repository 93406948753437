import update from 'immutability-helper'
import _ from 'lodash'
import React, {FC, Fragment, useEffect, useState} from 'react'
import {DndProvider, useDrag, useDrop} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {Row, useFilters, useGlobalFilter, useRowSelect, useTable} from 'react-table'
import styled from 'styled-components'

import {IUseKrcTable, calcWidthType} from '../../models/components/TableModel'

const Styles = styled.div`
  max-height: 600px;
  overflow: auto;
  padding-right: 20px;

  thead tr th {
    position: sticky;
    top: 0;
  }

  table {
    border-collapse: collapse;
  }

  th {
    text-align: left;
    background: #fff;
  }

  table {
    width: 100%;
  }
`

const DND_ITEM_TYPE = 'row'

const DragAndDropRow: FC<{
  row: any
  index: number
  moveRow: any
  onSortChange: (data: any[]) => void
  records: any[]
}> = ({row, index, moveRow, onSortChange, records}) => {
  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item: any, monitor: any) {
      if (!dropRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      // @ts-ignore
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveRow(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
    drop() {
      onSortChange && onSortChange(records)
    },
  })

  const [{isDragging}, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: {type: DND_ITEM_TYPE, index},
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <tr
      ref={dropRef}
      style={{opacity}}
      {...row.getRowProps()}
      key={`tbody_row_tr_key_${row.original.id}`}
    >
      <td ref={dragRef}>
        <div className='btn btn-secondary btn-sm'>Sürükle</div>
      </td>
      {row.cells.map((cell: any) => {
        return (
          <td {...cell.getCellProps()} className={'text-dark fw-bolder text-hover-primary fs-6'}>
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

export const calcWidth = (column: any) => {
  let data = {} as calcWidthType

  if (column.id === 'selection') {
    data['width'] = 20
    data['maxWidth'] = 20
  }

  if (column.manualWidth) {
    data['width'] = column.manualWidth
  }
  if (column.maxWidth) {
    data['maxWidth'] = column.maxWidth
  }
  if (column.minWidth) {
    data['minWidth'] = column.minWidth
  }

  return data
}

const IndeterminateCheckbox = React.forwardRef(
  // @ts-ignore
  ({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <div className='form-check form-check-sm form-check-custom form-check-solid mb-3'>
          <input
            className='form-check-input'
            type='checkbox'
            // @ts-ignore
            ref={resolvedRef}
            {...rest}
          />
        </div>
      </>
    )
  }
)

export const useKrcTable = ({
  columns,
  data,
  initialRowIdsOptions,
  selectableProps,
  searchableProps,
  sortableProps,
}: IUseKrcTable) => {
  const [records, setRecords] = useState(data)
  const [allSelectedOriginal, setAllSelectedOriginal] = useState<any[]>([])
  const [defaultInitialRowIds, setDefaultInitialRowIds] = useState<any>([])
  const [chosenItem, setChosenItem] = useState<Row>()

  useEffect(() => {
    setChosenItem(undefined)
  }, [data])

  const Provider = sortableProps?.isSortable ? DndProvider : Fragment
  const ProviderProps = Provider === DndProvider ? {backend: HTML5Backend} : {}

  const defaultColumnFilter = ({column: {filterValue, setFilter, id}}: any) => {
    if (!searchableProps || !searchableProps?.isSearchable) {
      return null
    }

    return (
      <>
        {searchableProps.onSearch ? (
          <input
            onChange={(e) => {
              if (!searchableProps.onSearch) {
                return
              }

              searchableProps.onSearch(e.target.value || undefined, id) // Set undefined to remove the filter entirely
            }}
            className='form-control'
            style={{height: 35, ...searchableProps.style}}
            placeholder={`Arama`}
          />
        ) : (
          <input
            value={filterValue || ''}
            onChange={(e) => {
              setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            className='form-control'
            style={{height: 35, ...searchableProps.style}}
            placeholder={`Arama`}
          />
        )}
      </>
    )
  }
  const defaultColumn = React.useMemo(() => ({Filter: defaultColumnFilter}), [])

  const selectedUnique = (original: any, isChecked: boolean) => {
    setAllSelectedOriginal((data: any) => {
      let _data = [...data]

      if (isChecked) {
        return _.uniqBy([..._data, original], selectableProps?.uniqueKey ?? 'id')
      }

      return _data.filter((item: any) => {
        return (
          item[selectableProps?.uniqueKey as string] !==
          original[selectableProps?.uniqueKey as string]
        )
      })
    })
  }

  useEffect(() => {
    if (!selectableProps?.uniqueKey) {
      return
    }

    let selectedRowIds: {[key: number]: boolean} = {}
    data.forEach((item: any, key: number) => {
      const some = allSelectedOriginal.some((selectedItem: any) => {
        // @ts-ignore
        if (!item[selectableProps?.uniqueKey]) {
          return false
        }
        // @ts-ignore
        return item[selectableProps?.uniqueKey] === selectedItem[selectableProps?.uniqueKey]
      })

      if (some) {
        selectedRowIds[key] = true
      }
    })

    setDefaultInitialRowIds(selectedRowIds)
  }, [data])

  useEffect(() => {
    setAllSelectedOriginal([])
    setDefaultInitialRowIds([])
  }, [selectableProps?.unSelectAllItems])

  const columnsOptions = ({columns}: any) => {
    if (!selectableProps || !selectableProps?.isSelectable) {
      return columns
    }

    const selection = {
      id: 'selection',
      Header: ({getToggleAllRowsSelectedProps, rows}: any) => (
        <div>
          <IndeterminateCheckbox
            {...getToggleAllRowsSelectedProps({
              onClick: (e: any) => {
                rows.forEach((row: {original: any}) => {
                  if (selectableProps?.uniqueKey) {
                    selectedUnique(row.original, e.target.checked)
                  }

                  if (!selectableProps.onSelectedItem) {
                    return
                  }

                  selectableProps.onSelectedItem(row.original, e.target.checked)
                })
              },
            })}
          />
        </div>
      ),
      Cell: ({row}: any) => (
        <div>
          <IndeterminateCheckbox
            {...row.getToggleRowSelectedProps({
              onClick: () => {
                if (selectableProps?.uniqueKey) {
                  selectedUnique(row.original, !row.isSelected)
                }

                if (!selectableProps.onSelectedItem) {
                  return
                }

                selectableProps.onSelectedItem(row.original, !row.isSelected)
              },
            })}
          />
        </div>
      ),
    }

    if (selectableProps.selectableAlign === 'left') {
      return [selection, ...columns]
    }

    return [...columns, selection]
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    visibleColumns,
    // @ts-ignore
    preGlobalFilteredRows,
    // @ts-ignore
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: records,
      // @ts-ignore
      defaultColumn,
      initialState: {
        // @ts-ignore
        selectedRowIds: initialRowIdsOptions || defaultInitialRowIds,
      },
    },
    useFilters,
    useRowSelect,
    useGlobalFilter,
    (hooks) => {
      hooks.visibleColumns.push((columns) => columnsOptions({columns}))
    }
  )

  useEffect(() => {
    setRecords(data)
  }, [data])

  useEffect(() => {
    if (!selectableProps || !selectableProps?.onSelectedItems) {
      return
    }

    selectableProps.onSelectedItems(allSelectedOriginal)
  }, [allSelectedOriginal])

  const moveRow = (dragIndex: any, hoverIndex: any) => {
    const dragRecord = records[dragIndex]
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    )
  }

  return {
    calcWidth,
    IndeterminateCheckbox,
    DragAndDropRow,
    defaultColumnFilter,
    columnsOptions,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    chosenItem,
    setChosenItem,
    rows,
    Styles,
    moveRow,
    records,
    Provider,
    ProviderProps,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    // @ts-ignore
    globalFilter: state.globalFilter,
  }
}
