import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''

type updateBannerImageDataType = {
  description: {
    title: string
    content: string
  }
  image: {
    link: string
    mobile_link_type: {} | null
    mobile_link_id: number | null
    deeplink: string
    image: string | null
    mobile_image: string | null
    column: number
  }
}

export const BANNER_URL = `${API_URL}/banners`

export const getBannerImageRequest = (bannerImageId: number) => {
  return axios.get(`${BANNER_URL}/images/${bannerImageId}`)
}

export const getBannerImageTypesRequest = () => {
  return axios.get(`${BANNER_URL}/images/types`)
}

export const updateBannerImageRequest = (
  bannerImageId: number,
  data: updateBannerImageDataType
) => {
  return axios.put(`${BANNER_URL}/images/${bannerImageId}`, data)
}

export const addBannerImagesRequest = (
  bannerId: number,
  bannerData: {
    name: string
    banner_group_id: number | null
  }[]
) => {
  return axios.post(`${BANNER_URL}/${bannerId}/images`, {banner_images: bannerData})
}

export const multipleDeleteBannerImagesRequest = (bannerId: number, bannerImageIds: number[]) => {
  return axios.delete(`${BANNER_URL}/${bannerId}/images/multiple`, {
    data: {banner_image_ids: bannerImageIds},
  })
}

export const sortBannerImagesRequest = (bannerImageIds: number[]) => {
  return axios.put(`${BANNER_URL}/images/sort`, {banner_image_ids: bannerImageIds})
}
