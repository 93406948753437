import {Route, Routes} from 'react-router-dom'

import {PermissionListWrapper} from '../components/pages/permissions/PermissionListWrapper'

export const PermissionRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<PermissionListWrapper />} />
      </Routes>
    </>
  )
}
