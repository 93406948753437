import {Drawer, Space} from 'antd'
import {Field, Form, Formik} from 'formik'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'

import {GameContext} from '../../../providers/games/GameProvider'
import {FileManagerModal} from '../../../service/FileManager/FileManagerModal'
import {KrcFormikInput} from '../../atoms/forms/KrcFormikInput'
import {gameInitialValueType} from '../../models/games/GameModel'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'

const schema = Yup.object({
  game_name: Yup.string().required('Oyun Adı zorunlu alan'),
  game_short_name: Yup.string().required('Oyun Adı Kısaltma zorunlu alan'),
  game_title: Yup.string().required('Başlık zorunlu alan'),
  game_subtitle: Yup.string().required('Alt Başlık zorunlu alan'),
  game_link: Yup.string().required('Oyun Url zorunlu alan'),
  is_active: Yup.string().required('Durum zorunlu alan'),
  ratio: Yup.number().required('Çarpan Oranı zorunlu alan'),
})

const _initialValue = {
  game_name: '',
  game_short_name: '',
  game_title: '',
  game_subtitle: '',
  game_description: '',
  game_link: '',
  is_active: 0,
  is_leaderboard_active: 0,
  ratio: 1,
  storifyme_widget_id: '',
} as gameInitialValueType

export const CreateOrUpdateGameDrawer: FC = () => {
  const [initialValue, setInitialValue] = useState<gameInitialValueType>(_initialValue)
  const submitRef = useRef<HTMLButtonElement | null>(null)
  const [image, setImage] = useState<string | null>()
  const [howToPlayImage, setHowToPlayImage] = useState<string | null>(null)

  const {
    willBeUpdatedGame,
    gameDrawerVisible,
    setGameDrawerVisible,
    setWillBeUpdatedGame,
    createGame,
    updateGame,
  } = useContext(GameContext)

  useEffect(() => {
    if (!willBeUpdatedGame) {
      setInitialValue(_initialValue)
      return
    }

    setInitialValue({
      game_name: willBeUpdatedGame.game_name,
      game_short_name: willBeUpdatedGame.game_short_name,
      game_title: willBeUpdatedGame.game_title,
      game_subtitle: willBeUpdatedGame.game_subtitle,
      game_description: willBeUpdatedGame.game_description || '',
      game_link: willBeUpdatedGame.game_link,
      is_active: Number(willBeUpdatedGame.is_active),
      is_leaderboard_active: Number(willBeUpdatedGame.is_leaderboard_active),
      ratio: willBeUpdatedGame.ratio,
      storifyme_widget_id: willBeUpdatedGame.storifyme_widget_id,
    })

    setImage(willBeUpdatedGame.game_image)
    setHowToPlayImage(willBeUpdatedGame.how_to_play_image)
  }, [willBeUpdatedGame])

  const handleOnHide = () => {
    setGameDrawerVisible(false)
    setWillBeUpdatedGame(null)
  }

  const handleOnSubmit = (value: any) => {
    const data = {
      ...value,
      is_active: Number(value.is_active),
      is_leaderboard_active: Number(value.is_leaderboard_active),
      game_image: image,
      how_to_play_image: howToPlayImage,
    }

    if (willBeUpdatedGame) {
      updateGame(data)
    } else {
      createGame(data)
    }
  }

  return (
    <>
      <Drawer
        title={'Karaca Oyun'}
        placement='right'
        size={'large'}
        onClose={handleOnHide}
        keyboard={false}
        maskClosable={false}
        visible={gameDrawerVisible}
        extra={
          <Space>
            <button
              onClick={() => {
                submitRef.current?.click()
              }}
              className='btn btn-success'
              style={{marginLeft: 10}}
            >
              Kaydet
            </button>
          </Space>
        }
      >
        <Formik
          validationSchema={schema}
          initialValues={initialValue}
          onSubmit={handleOnSubmit}
          enableReinitialize
        >
          {() => (
            <Form className='form row' noValidate>
              <KrcFormikField name={'game_name'} labelTitle={'Oyun Adı'} required />
              <KrcFormikField
                name={'game_short_name'}
                className={'mb-2 col-md-4'}
                labelTitle={'Oyun Adı Kısaltması'}
                required
              />

              <KrcFormikField name={'game_title'} labelTitle={'Oyun Başlığı'} required />

              <KrcFormikField
                name={'game_subtitle'}
                labelTitle={'Oyun Alt Başlığı'}
                className={'mb-2 col-md-4'}
                required
              />

              <KrcFormikField
                name={'game_description'}
                labelTitle={'Açıklama'}
                className={'mb-2 col-md-12'}
              >
                <Field
                  component='textarea'
                  name={'game_description'}
                  className='form-control'
                  rows={3}
                  style={{resize: 'none'}}
                />
              </KrcFormikField>

              <KrcFormikField
                name={'game_link'}
                labelTitle={'Oyun Link'}
                className={'mb-2 col-md-6'}
                required
              />

              <KrcFormikField
                name={'storifyme_widget_id'}
                labelTitle={'Storifme Widget ID'}
                className={'mb-2 col-md-4'}
                required
              >
                <KrcFormikInput
                  type={'number'}
                  name={'storifyme_widget_id'}
                  className={'form-control'}
                  min={0}
                />
              </KrcFormikField>

              <KrcFormikField
                name={'ratio'}
                labelTitle={'Çarpan Oranı'}
                className={'mb-2 col-md-4'}
                required
              >
                <KrcFormikInput type={'number'} name={'ratio'} className={'form-control'} min={0} />
              </KrcFormikField>

              {willBeUpdatedGame && (
                <KrcFormikField
                  className={'mb-2 col-md-4'}
                  name={'is_active'}
                  labelTitle={'Durum'}
                  required
                >
                  <Field
                    as='select'
                    name='is_active'
                    className='form-select'
                    aria-label='Select example'
                  >
                    <option value={''}>Seçiniz</option>
                    <option value={1}>Aktif</option>
                    <option value={0}>Pasif</option>
                  </Field>
                </KrcFormikField>
              )}

              <KrcFormikField
                className={'mb-2 col-md-4'}
                name={'is_leaderboard_active'}
                labelTitle={'Lider Tablosu Durumu'}
                required
              >
                <Field
                  as='select'
                  name='is_leaderboard_active'
                  className='form-select'
                  aria-label='Select example'
                >
                  <option value={''}>Seçiniz</option>
                  <option value={1}>Aktif</option>
                  <option value={0}>Pasif</option>
                </Field>
              </KrcFormikField>

              <KrcFormikField
                className={'mb-2 mt-2 col-md-6'}
                name={'game_image'}
                labelTitle={'Oyun Görseli'}
              >
                <FileManagerModal
                  onSelected={(link) => {
                    setImage(link)
                  }}
                  defaultImage={willBeUpdatedGame?.game_image ?? ''}
                />
              </KrcFormikField>

              <KrcFormikField
                className={'mb-2 mt-2 col-md-6'}
                name={'how_to_play_image'}
                labelTitle={'Nasıl Oynanır Görseli'}
              >
                <FileManagerModal
                  onSelected={(link) => {
                    setHowToPlayImage(link)
                  }}
                  defaultImage={willBeUpdatedGame?.how_to_play_image ?? ''}
                />
              </KrcFormikField>

              <button type={'submit'} ref={submitRef} className='d-none' style={{marginLeft: 10}} />
            </Form>
          )}
        </Formik>
      </Drawer>
    </>
  )
}
