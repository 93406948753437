import {Drawer, Space} from 'antd'
import Title from 'antd/lib/typography/Title'
import React, {FC, useContext, useState} from 'react'

import {ProductSelectionEnum} from '../../enums/ProductSelectionEnum'
import {prioritizeArrayItemsByAnotherArrayItemsOrdered} from '../../helpers/ArrayHelper'
import {DealContext} from '../../providers/DealProvider'
import {KrcButton} from '../atoms/buttons/KrcButton'
import {KrcRangePicker} from '../atoms/forms/KrcRangePicker'
import {ProductSelection} from '../layout/filters/ProductSelection'
import {CategoryTypeEnum} from '../models/categories/CategoryModel'
import {productVerticalAdvancedFilterDataType} from '../models/products/ProductListFilterModel'
import {
  ProductSelectionType,
  productTextAreaFilterDataType,
} from '../models/products/ProductSelectionModel'
import {BadgeFilter} from '../molecules/filters/BadgeFilter'
import {CategoryFilter} from '../molecules/filters/CategoryFilter'

export const DealProductDrawer: FC = () => {
  const {
    updateWillBeAddedDealProductIds,
    setWillBeAddedDealDateRange,
    visibleAddProductsDrawer,
    setVisibleAddProductsDrawer,
    addDealProduct,
    setWillBeAddedDealProductBadge,
    willBeAddedDealProductBadge,
    willBeAddedDealProductCampaign,
    setWillBeAddedDealProductCampaign,
  } = useContext(DealContext)

  const [productSelectionFilterCurrentType, setProductSelectionFilterCurrentType] =
    useState<ProductSelectionType>(ProductSelectionEnum.DEFAULT)

  const [productSelectionCurrentFilter, setProductSelectionCurrentFilter] = useState<
    productVerticalAdvancedFilterDataType | productTextAreaFilterDataType
  >()

  const onClose = () => {
    setVisibleAddProductsDrawer(false)
  }

  return (
    <>
      <Drawer
        title={
          <Title level={4} className={'mb-0 mt-1'}>
            Fırsat Ürün(leri) Ekle
          </Title>
        }
        placement='right'
        size={'large'}
        onClose={onClose}
        keyboard={false}
        maskClosable={false}
        visible={visibleAddProductsDrawer}
        extra={
          <Space>
            <KrcButton type={'default'} size={'large'} onClick={onClose}>
              İptal
            </KrcButton>
            <KrcButton
              size={'large'}
              onClick={() => {
                addDealProduct()
              }}
            >
              Seçilen Ürünleri Ekle
            </KrcButton>
          </Space>
        }
      >
        <div className={'d-flex justify-content-start mb-7 mt-2'}>
          <div className={'d-flex flex-column'}>
            <div className={'d-flex'}>
              <Title level={5}>Tarih Aralığı</Title>
              <span className='required'></span>
            </div>
            <div className={'d-flex flex-row'}>
              <KrcRangePicker
                className={'flex-1'}
                showTime
                placeholder={['Eklenme Tarihi Başlangıç', 'Ekleme Tarihi Bitiş']}
                format={'DD.MM.YYYY HH:mm'}
                allowClear
                onSelected={(value) => {
                  if (!value) {
                    setWillBeAddedDealDateRange({
                      date_start: null,
                      date_end: null,
                    })
                    return
                  }
                  setWillBeAddedDealDateRange({
                    date_start: value[0].format('YYYY-MM-DD HH:mm'),
                    date_end: value[1].format('YYYY-MM-DD HH:mm'),
                  })
                }}
                size={'middle'}
              />
            </div>
          </div>
        </div>
        <div className={'d-flex justify-content-start mb-7 mt-2'}>
          <div className={'d-flex flex-column'}>
            <div className={'d-flex'}>
              <Title level={5}>Badge</Title>
            </div>
            <div className={'d-flex flex-row'}>
              <BadgeFilter
                value={willBeAddedDealProductBadge}
                className={'select-filter-custom'}
                style={{width: 402}}
                onSelected={(data) => {
                  setWillBeAddedDealProductBadge(data as number[])
                }}
                placeholder={'Lütfen seçiniz'}
              />
            </div>
          </div>
        </div>
        <div className={'d-flex justify-content-start mb-7 mt-2'}>
          <div className={'d-flex flex-column'}>
            <div className={'d-flex'}>
              <Title level={5}>Kampanya</Title>
            </div>
            <div className={'d-flex flex-row'}>
              <CategoryFilter
                value={willBeAddedDealProductCampaign}
                className={'select-filter-custom'}
                style={{width: 402}}
                placeholder={'Lütfen seçiniz'}
                categoryType={CategoryTypeEnum.CAMPAIGN}
                onSelected={(data) => {
                  setWillBeAddedDealProductCampaign(data as number[])
                }}
              />
            </div>
          </div>
        </div>
        {visibleAddProductsDrawer ? (
          <ProductSelection
            onFilterChange={(data) => {
              setProductSelectionCurrentFilter(data)
            }}
            headerProps={{
              titleLevel: 5,
              searchOption: {
                advancedStatus: false,
              },
            }}
            textAreaProps={{
              placeholder:
                'Model numarası veya Ürün ID ile toplu arama yapılabilir. \nÖrneğin: \n7126119 \n6949288 \n153.03.01.1091 ',
            }}
            onSelectedItems={(data) => {
              let transformedData = data ?? []
              if (
                productSelectionCurrentFilter &&
                productSelectionFilterCurrentType === ProductSelectionEnum.TEXT
              ) {
                transformedData = prioritizeArrayItemsByAnotherArrayItemsOrdered(
                  transformedData,
                  (productSelectionCurrentFilter as productTextAreaFilterDataType).all,
                  (item: string, searchData) =>
                    String(item) === String(searchData.id) ||
                    String(item) === String(searchData.model)
                )
              }
              updateWillBeAddedDealProductIds(transformedData)
            }}
            transferProps={{
              button: {
                title: '',
                isActive: false,
              },
            }}
            withFullPrice={true}
            withTotalQuantity={true}
            onFilterTypeChange={(type) => {
              setProductSelectionFilterCurrentType(type)
            }}
          />
        ) : null}
      </Drawer>
    </>
  )
}
