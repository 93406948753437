import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, message} from 'antd'
import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {
  ACTIVITIES_LOGS,
  PROCESS_AND_PRODUCT_LISTING,
  PROMOTION,
} from '../../../../../enums/CategoryProductProcessPageEnumType'
import {CategoryProductsProcessContext} from '../../../../../providers/category/products/CategoryProductsProcessProvider'
import {KrcButton} from '../../../../atoms/buttons/KrcButton'
import {productTextSearchType} from '../../../../models/products/ProductListFilterModel'
import {productTextAreaFilterDataType} from '../../../../models/products/ProductSelectionModel'
import {KrcTabs} from '../../../../molecules/KrcTabs'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'
import {CategoryLinkedPromotions} from '../../promotions/CategoryLinkedPromotions'
import {CategoryProductProcessList} from './tabs/CategoryProductProcessList'
import {CategoryProductsProcessActivity} from './tabs/CategoryProductsProcessActivity'

const {confirm} = Modal

const CategoryProductsProcessListToolbar: FC = () => {
  const {
    setAddProductDrawerVisible,
    addSelectedProductsToWillBeDeletedProducts,
    removeCategoryProductByFilteredProducts,
    setVisibleConstraintListFilter,
    category,
    meta,
    textFilter,
  } = useContext(CategoryProductsProcessContext)

  /**
   * Filtrelenen ürünleri direkt olarak işleyebilir miyiz gerekli koşullar sağlanıyor mu kontrol eder.
   */
  const checkFilteredAllProductsIsAvailableToHandle = () => {
    if (!meta?.total || meta?.total < 1) {
      message.warn('Filtrelenmiş ürün bulunamadı.')
      return false
    }

    if (textFilter === undefined) {
      message.warn('Önce metin seçimi ile filtre yapmalısınız.')
      return false
    }

    if (
      (textFilter as productTextAreaFilterDataType)?.productIds.length === 0 &&
      (textFilter as productTextAreaFilterDataType)?.models.length === 0
    ) {
      message.warn('Önce metin seçimi ile filtre yapmalısınız.')
      return false
    }

    return true
  }

  const handleToRemoveFilteredAllProducts = () => {
    if (!checkFilteredAllProductsIsAvailableToHandle()) {
      return
    }

    confirm({
      title: `DİKKAT!`,
      icon: <ExclamationCircleOutlined />,
      content: `${meta.total} ürünün tümünü kontrol etmeden silip kaydetmek üzeresiniz. Ürünleri silip kaydetmek istediğinize emin misiniz?`,
      okText: 'Devam',
      cancelText: 'Vazgeç',
      onOk() {
        confirm({
          title: `${meta.total} adet ürün silinecek`,
          icon: <ExclamationCircleOutlined />,
          content: `${meta.total} adet ürün kategoriden silinecek ve kaydedilecek. Devam etmek istiyor musunuz?`,
          okText: 'Ürünleri Sil ve Kaydet',
          cancelText: 'İptal',
          onOk() {
            removeCategoryProductByFilteredProducts(textFilter as productTextSearchType)
          },
        })
      },
    })
  }

  return (
    <>
      <div>
        {category?.category_type === 'campaign' && (
          <KrcButton
            size={'middle'}
            type={'default'}
            onClick={() => {
              setVisibleConstraintListFilter(true)
            }}
            id='kt_toolbar_primary_button'
            className={'mr-3'}
          >
            Kısıtlı Ürün Listesi
          </KrcButton>
        )}
        <KrcButton
          size={'middle'}
          type={'default'}
          onClick={() => {
            addSelectedProductsToWillBeDeletedProducts()
          }}
          className={'mr-3'}
          id='kt_toolbar_primary_button'
        >
          Seçili Ürünleri Sil
        </KrcButton>
        <KrcButton
          size={'middle'}
          type={'default'}
          onClick={() => {
            handleToRemoveFilteredAllProducts()
          }}
          className={'mr-3'}
          id='kt_toolbar_primary_button'
        >
          Filtrelenen Ürünleri Sil ve Kaydet
        </KrcButton>
        <KrcButton
          size={'middle'}
          onClick={() => {
            setAddProductDrawerVisible(true)
          }}
          id='kt_toolbar_primary_button'
        >
          Ürün Ekle
        </KrcButton>
      </div>
    </>
  )
}

export const CategoryProductsProcess: FC = () => {
  const {pageTitle, pageType} = useContext(CategoryProductsProcessContext)

  return (
    <>
      <Toolbar>
        {pageType === PROCESS_AND_PRODUCT_LISTING && <CategoryProductsProcessListToolbar />}
      </Toolbar>
      <PageTitle>{pageTitle}</PageTitle>

      <KrcTabs
        isActiveKey={true}
        tabsProps={{defaultActiveKey: 'home'}}
        tabProps={[
          {
            eventKey: 'home',
            title: 'Kategori Ürünleri',
            children: <CategoryProductProcessList pageType={PROCESS_AND_PRODUCT_LISTING} />,
          },
          {
            eventKey: 'log',
            title: 'Kategori Ürün Aktiviteleri',
            children: <CategoryProductsProcessActivity pageType={ACTIVITIES_LOGS} />,
          },
          {
            eventKey: 'promotion',
            title: 'Bağlı Olduğu Kampanyalar',
            children: <CategoryLinkedPromotions pageType={PROMOTION} />,
          },
        ]}
      />
    </>
  )
}
