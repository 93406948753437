import {useEffect, useRef, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import {useCategoryProductSorting} from '../../../../../hooks/categories/products/sorts/useCategoryProductSorting'
import {paginationMetaType} from '../../../../../models/GeneralModel'
import {IKrcGridTableProductObject} from '../../../../../models/components/GridProductTableModel'
import {LoadingInTheMiddle} from '../../../../../molecules/LoadingInTheMiddle'
import {KrcGridProductTable} from '../../../../../organisms/tables/KrcGridProductTable'

export const CategoryProductSortingGridView = () => {
  // InfiniteScroll sayfa sonuna geldiğinde loadMore fonksiyonunu sadece bir kere calistirmak icin kullanacagimiz bayrak degeri.
  const triggeredRef = useRef(0)
  const {categorySortingViewData, categorySortingViewMeta, setCategorySortingViewMeta} =
    useCategoryProductSorting()

  // Gelen datayi burada tutacagiz. Paginare ile daha sonradan gelen veriyi buraya ekleyecegiz.
  const [incomeData, setIncomeData] = useState<IKrcGridTableProductObject[]>([])

  // Ilk calistmada varsayilan olan limit 10 istegi yerine limit 40 ile istek atiyoruz
  useEffect(() => {
    setCategorySortingViewMeta((data: paginationMetaType) => ({...data, page: 1, limit: 40}))
  }, [])

  // Verilerin sonuna geldigimizde loadMore fonksiyonu calisacak ve burada gelen datayi incomeData state ine ekleyecegiz.
  useEffect(() => {
    // Bu useEffect calisirsa yeni veri veri gelmis demektir. Bu nedenle bayrak degerini tekrar sifirlayacagiz.
    // Boylelikle bu pagination page degeri de tamamlandiginda loadMore fonksiyonu tekrar calisailir hale gelecek.
    triggeredRef.current = 0
    setIncomeData((prevState) => {
      return [...prevState, ...categorySortingViewData]
    })
  }, [categorySortingViewData])

  // Son sayfaya geldigimizde hasMoreData false olacak ve InfiniteScroll component i artik loadMore fonksiyonunu calistirmayacak.
  const hasMoreData = categorySortingViewMeta.last_page !== categorySortingViewMeta.current_page

  // InfiniteScroll component i loadMore fonksiyonunu sadece bir kere calistirmak icin kullanacagimiz bayrak degeri ile birlikte calisacak ve
  // mevcut sayfa degerini bir arttirarak yeni verileri cekmeye calisacak.
  const loadMoreData = () => {
    triggeredRef.current += 1
    if (triggeredRef.current === 1) {
      setCategorySortingViewMeta((data: paginationMetaType) => ({
        ...data,
        page: data.current_page! + 1 ?? 1,
      }))
    }
  }

  return (
    <>
      {incomeData.length > 0 ? (
        <InfiniteScroll
          pageStart={1}
          loadMore={loadMoreData}
          hasMore={hasMoreData}
          loader={<LoadingInTheMiddle />}
        >
          <KrcGridProductTable data={incomeData} />
        </InfiniteScroll>
      ) : (
        <LoadingInTheMiddle />
      )}
    </>
  )
}
