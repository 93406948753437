import React, {FC} from 'react'
import {useNavigate} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {CouponListProvider} from '../../../../providers/campaign_and_coupon/CouponListProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {CouponList} from '../list/CouponList'

const CouponManagerWrapperWithoutProvider: FC = () => {
  const navigate = useNavigate()
  return (
    <>
      <Toolbar
        rightField={
          <div className={'d-flex align-items-center py-1'}>
            <KrcButton
              type={'primary'}
              onClick={(e) => {
                navigate('/coupon/create/')
              }}
            >
              Kupon Oluştur
            </KrcButton>
          </div>
        }
      ></Toolbar>
      <PageTitle>Kupon Yönetimi</PageTitle>
      <CouponList />
    </>
  )
}

export const CouponManagerWrapper: FC = () => {
  return (
    <CouponListProvider>
      <CouponManagerWrapperWithoutProvider />
    </CouponListProvider>
  )
}
