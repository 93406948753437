import axios from 'axios'

import {paginationMetaType} from '../../../components/models/GeneralModel'
import {
  getPassiveProductsType,
  passiveProductLogFilterType,
} from '../../../components/models/products/passives/PassiveProductModel'

const API_URL = process.env.REACT_APP_API_URL || ''

export const getPassiveProductRequest = ({
  page,
  limit,
  relations,
  filterSearchData,
  excludedProducts,
}: getPassiveProductsType) => {
  let url = `${API_URL}/products/passive`

  return axios.get(url, {
    params: {
      page,
      limit: limit,
      with: relations,
      product_id: filterSearchData?.id,
      product_ids: filterSearchData?.ids,
      name: filterSearchData?.name,
      model: filterSearchData?.model,
      models: filterSearchData?.models,
      store_ids: filterSearchData?.store_ids,
      updated_ts: filterSearchData?.updated_ts,
      excluded_products: excludedProducts,
    },
  })
}

export const passiveProductStoreRequest = (body: object) => {
  let url = `${API_URL}/products/passive`

  return axios.post(url, body)
}

export const passiveProductActivateRequest = (body: object) => {
  let url = `${API_URL}/products/passive/activate`

  return axios.put(url, body)
}

export function getPassiveProductLogsRequest(
  filters: passiveProductLogFilterType,
  meta: paginationMetaType
) {
  return axios.get(`${API_URL}/products/passive/logs`, {
    params: {
      ...filters,
      limit: meta.limit,
      page: meta.page,
    },
  })
}
