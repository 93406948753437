import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {OrderDetailWrapper} from '../components/pages/orders/OrderDetailWrapper'
import {OrderListWrapper} from '../components/pages/orders/OrderListWrapper'
import {OrderQuestioningWrapper} from '../components/pages/orders/OrderQuestioningWrapper'

export const OrderRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/:orderId/details'} element={<OrderDetailWrapper />} />
        <Route path={'/questioning'} element={<OrderQuestioningWrapper />} />
        <Route path={'/'} element={<OrderListWrapper />} />
      </Routes>
    </>
  )
}
