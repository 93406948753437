import axios from 'axios'
import * as querystring from 'query-string'

import {
  getCampaignSalesProductsExportFrontendUrlType,
  getCategoryApprovalProductsExportType,
  getCategoryHistoryApprovalProductsExportType,
  getCategoryProductsExportType,
} from '../../../../components/models/categories/CategoryProductModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const CATEGORY_PRODUCT_URL = `${API_URL}/categories`

export const categoryProductExportRequest = (categoryId: number, params: object) => {
  return axios.get(`${CATEGORY_PRODUCT_URL}/${categoryId}/products/export`, {
    params,
  })
}

export const categoryProductCheckExportRequest = (categoryId: number, fileName: string) => {
  return axios.get(`${CATEGORY_PRODUCT_URL}/${categoryId}/products/check-export`, {
    params: {
      file_name: fileName,
    },
  })
}

export const categoryListApprovalProductExportRequest = (params: object) => {
  return axios.get(`${CATEGORY_PRODUCT_URL}/approval-products/export`, {
    params: params,
  })
}

export const categoryHistoryListApprovalProductExportRequest = (params: object) => {
  return axios.get(`${CATEGORY_PRODUCT_URL}/approval/history/export`, {
    params: params,
  })
}

export const categoryDetailPendingApprovalProductExportRequest = (
  categoryId: number,
  params: object
) => {
  return axios.get(`${CATEGORY_PRODUCT_URL}/${categoryId}/products/pending-approval/export`, {
    params,
  })
}

export const categoryDetailPendingApprovalProductCheckExportRequest = (
  categoryId: number,
  fileName: string
) => {
  return axios.get(`${CATEGORY_PRODUCT_URL}/${categoryId}/products/pending-approval/check-export`, {
    params: {
      file_name: fileName,
    },
  })
}

export const campaignSalesProductsExportRequest = (params: object) => {
  return axios.get(`${CATEGORY_PRODUCT_URL}/sales/products/export`, {
    params: params,
  })
}

export const campaignSalesProductsCheckExportRequest = (fileName: string) => {
  return axios.get(`${CATEGORY_PRODUCT_URL}/sales/products/check-export`, {
    params: {
      file_name: fileName,
    },
  })
}

export const getCategoryDetailPendingApprovalProductsExportFrontendUrl = (
  categoryId: number,
  hasMyPendingApprovalProductsRequests: boolean = false
) => {
  return `/categories/${categoryId}/products/pending-approvals/export?${querystring.stringify(
    {
      has_my_pending_approval_products_requests: Number(hasMyPendingApprovalProductsRequests),
    },
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}

export const getCategoryProductExportFrontendUrl = ({
  categoryId,
  excludedProductIds,
  filterTable,
  filter,
}: getCategoryProductsExportType) => {
  return `/categories/${categoryId}/products/export?${querystring.stringify(
    {
      excluded_product_ids: excludedProductIds,
      product_ids: filter?.productIds || [],
      models: filter?.models || [],
      product_id: filterTable?.id,
      name: filterTable?.name,
      model: filterTable?.model,
      date_added_start: filterTable?.date_added_start,
      date_added_end: filterTable?.date_added_end,
    },
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}

export const getCategoryApprovalProductsExportFrontendUrl = ({
  categoryIds,
  hasMyPendingApprovalProductsRequests,
}: getCategoryApprovalProductsExportType) => {
  return `/categories/campaigns-waiting-approval/export?${querystring.stringify(
    {
      category_ids: categoryIds || [],
      has_my_pending_approval_products_requests: Number(hasMyPendingApprovalProductsRequests),
    },
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}

export const getCategoryHistoryApprovalProductsExportFrontendUrl = ({
  categoryIds,
  filterSearchData,
  hasMyHistoryApprovalProductsRequests,
}: getCategoryHistoryApprovalProductsExportType) => {
  let url = `/categories/campaigns-waiting-approval/history/export`

  return `${url}?${querystring.stringify(
    {
      category_ids: categoryIds || [],
      ...filterSearchData,
      has_my_history_approval_products_requests: Number(hasMyHistoryApprovalProductsRequests),
    },
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}

export const getCampaignSalesProductsExportFrontendUrl = ({
  categoryIds,
}: getCampaignSalesProductsExportFrontendUrlType) => {
  let url = `/categories/sales/products/export`

  return `${url}?${querystring.stringify(
    {
      category_ids: categoryIds,
    },
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}
