import cn from 'classnames'
import React, {FC} from 'react'

import {KrcSelect} from '../atoms/forms/KrcSelect'
import {IPagination} from '../models/components/TableModel'

const defaultPagination = {
  default: {
    options: [
      {
        value: 10,
        label: '10',
      },
      {
        value: 25,
        label: '25',
      },
      {
        value: 50,
        label: '50',
      },
      {
        value: 100,
        label: '100',
      },
      {
        value: 200,
        label: '200',
      },
    ],
  },
}

export const Pagination: FC<IPagination> = ({
  page,
  onChangePage,
  paginationData,
  limit,
  onChangeLimit,
}) => {
  return (
    <>
      <div className='kr-pagination-wrap'>
        <div className='kr-pagination'>
          <div
            className={cn({'kr-page': true, disabled: page <= 1})}
            onClick={() => {
              if (!onChangePage) {
                return
              }

              onChangePage(1)
            }}
          >
            <i className='fa fa-angle-double-left' />
          </div>
          <div
            className={cn({'kr-page': true, disabled: page <= 1})}
            onClick={() => {
              if (!onChangePage) {
                return
              }

              onChangePage(page - 1)
            }}
          >
            <i className='fa fa-angle-left' />
          </div>
          <div
            className={cn({'kr-page': true, disabled: page >= paginationData.last_page})}
            onClick={() => {
              if (!onChangePage) {
                return
              }

              onChangePage(page + 1)
            }}
          >
            <i className='fa fa-angle-right' />
          </div>
          <div
            className={cn({'kr-page': true, disabled: page >= paginationData.last_page})}
            onClick={() => {
              if (!onChangePage) {
                return
              }

              onChangePage(paginationData.last_page)
            }}
          >
            <i className='fa fa-angle-double-right' />
          </div>
        </div>
        <span className='kr-pagination-info'>
          <strong>
            {paginationData.from}-{paginationData.to}
          </strong>{' '}
          <span>of&nbsp;</span>
          <strong>{paginationData.total}</strong>
        </span>
        <div className='kr-filter-per-page'>
          <KrcSelect
            size={'middle'}
            value={limit}
            allowClear={false}
            options={defaultPagination.default.options}
            onChange={(value) => {
              if (!onChangeLimit || !value) {
                return
              }

              onChangeLimit(Number(value))
            }}
          ></KrcSelect>
        </div>
      </div>
    </>
  )
}
