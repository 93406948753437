import {EditOutlined} from '@ant-design/icons'
import {Image, Tooltip} from 'antd'
import React, {FC, createContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Cell, Column, Row} from 'react-table'

import {paginationMetaType} from '../../components/models/GeneralModel'
import {initialMetaData} from '../tables/ProductSelectionProvider'

interface IProductListContext {
  data: any[]
  setData: React.Dispatch<React.SetStateAction<any[]>>
  columns: Column<any>[]
  willBeFilteredProductIds: number[]
  setWillBeFilteredProductIds: React.Dispatch<React.SetStateAction<number[]>>
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  isShowStoreDrawer: boolean
  setIsShowStoreDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

export const ProductListContext = createContext<IProductListContext>({
  data: [],
  setData: () => {},
  columns: [],
  willBeFilteredProductIds: [],
  setWillBeFilteredProductIds: () => {},
  meta: initialMetaData,
  setMeta: () => {},
  isShowStoreDrawer: false,
  setIsShowStoreDrawer: () => {},
})

export const ProductListProvider: FC = ({children}) => {
  const [data, setData] = useState<any[]>([])
  const [meta, setMeta] = useState<paginationMetaType>({limit: 10, page: 1})
  const [willBeFilteredProductIds, setWillBeFilteredProductIds] = useState<number[]>([])
  const navigate = useNavigate()
  const [isShowStoreDrawer, setIsShowStoreDrawer] = useState<boolean>(false)

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Görsel',
        accessor: 'image',
        Cell: ({row}: any) => (
          <Image
            preview={{
              maskClassName: 'rounded-circle',
            }}
            className={'rounded-circle'}
            width={52}
            height={52}
            src={row.original.image}
            style={{minWidth: 52}}
          />
        ),
        maxWidth: 52,
        manualWidth: 52,
      },
      {
        Header: 'Model',
        accessor: 'model',
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex'}>
              <div className={'ml-2'}>
                <div className={'mb-1'}>{row.original.name}</div>
                <span className={'text-gray-400 fs-7'}>
                  Güncellendi: {row.original.lastUpdateText}
                </span>
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Kategori Adı',
        accessor: 'categories',
        maxWidth: 400,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex'}>
              <div className={'ml-2'}>
                <div className={'mb-1'}>{row.original.category?.details?.name}</div>
                <span className={'text-gray-400 fs-7'}>
                  Son güncelleme: {row.original.lastUpdate}
                </span>
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Stok',
        accessor: 'quantity',
      },
      {
        Header: 'Stok Durumu',
        accessor: 'stock_status',
        Cell: ({row}: any) => <div>{row.original.stock_status ? 'Aktif' : 'Pasif'}</div>,
      },
      {
        Header: 'Fiyat',
        accessor: 'price',
        maxWidth: 150,
        manualWidth: 150,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                <div className={'mb-1'}>{row.original.priceKmiy} ₺</div>
                <span className={'text-gray-400 fs-7'}>{row.original.price} ₺</span>
              </div>
            </div>
          )
        },
      },
      {
        Header: 'İndirim',
        maxWidth: 150,
        manualWidth: 150,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <>
                {row.original.specialPrice ? (
                  <div className={'mb-1'}>{row.original.specialPrice} ₺</div>
                ) : (
                  <>-</>
                )}
              </>
            </div>
          )
        },
      },
      {
        Header: 'Sepet İndirim',
        maxWidth: 150,
        manualWidth: 150,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              {row.original.cartPrice ? (
                <>
                  <div className={'mb-1'}>{row.original.cartPrice} ₺</div>
                </>
              ) : (
                <>-</>
              )}
            </div>
          )
        },
      },
      {
        Header: 'Durum',
        accessor: 'status',
        Cell: ({row}: any) => <div>{row.original.status ? 'Aktif' : 'Pasif'}</div>,
      },
      {
        Header: 'Aksiyonlar',
        accessor: '',
        manualWidth: 100,
        Filter: () => {
          return <></>
        },
        Cell: ({row}: Cell<Row>) => (
          <>
            <div className='d-flex flex-row justify-content-between'>
              <div></div>
              <Tooltip title={'Güncelle'}>
                <div
                  onClick={() => {
                    navigate(`/products/${row.original.id}/updates`)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                >
                  <EditOutlined />
                </div>
              </Tooltip>
            </div>
          </>
        ),
      },
    ]
  }, [])

  return (
    <>
      <ProductListContext.Provider
        value={{
          data,
          setData,
          columns,
          willBeFilteredProductIds,
          setWillBeFilteredProductIds,
          meta,
          setMeta,
          isShowStoreDrawer,
          setIsShowStoreDrawer,
        }}
      >
        {children}
      </ProductListContext.Provider>
    </>
  )
}
