import {BarsOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {Badge, Popconfirm, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {createContext, useEffect, useMemo, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Column} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {KrcSelect} from '../../components/atoms/forms/KrcSelect'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {FaqDataType} from '../../components/models/faq/FaqModel'
import {FAQ_STORES} from '../../enums/FaqStoresTypeEnum'
import {removeEmptyItemInObject} from '../../helpers/ObjectHelper'
import {deleteFaqRequest, getFaqRequest} from '../../requests/faq/FaqRequest'
import {initialMetaData} from '../tables/ProductSelectionProvider'

interface IFaqContext {
  isLoading: boolean
  setIsLoading: (value: boolean) => void
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  columns: Column<any>[]
  visibleUpdateModal: boolean
  setVisibleUpdateModal: (value: boolean) => void
  topics: FaqDataType[]
  setTopics: (value: FaqDataType[]) => void
  filter: any
  setFilter: React.Dispatch<React.SetStateAction<any>>
  handleOnFilter: (data: any, key: string | number) => void
  selectedStoreId: string
  setSelectedStoreId: (value: string) => void
}

export const FaqContext = createContext<IFaqContext>({
  isLoading: false,
  meta: initialMetaData,
  setMeta: () => {},
  columns: [],
  setIsLoading: () => {},
  visibleUpdateModal: false,
  setVisibleUpdateModal: () => {},
  topics: [],
  setTopics: () => {},
  filter: {},
  setFilter: () => {},
  handleOnFilter: () => {},
  selectedStoreId: FAQ_STORES[2].id.toString(),
  setSelectedStoreId: () => {},
})

export const FaqProvider = ({children}: {children: React.ReactNode}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<any>({})
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [visibleUpdateModal, setVisibleUpdateModal] = useState<boolean>(false)
  const [topics, setTopics] = useState<FaqDataType[]>([])
  const fetchRef = useRef(0)
  const initialRef = useRef(0)
  const [selectedStoreId, setSelectedStoreId] = useState<string>(FAQ_STORES[2].id.toString())

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'faq_id',
        manualWidth: 130,
        maxWidth: 130,
      },
      {
        Header: 'Konular/Sorular',
        accessor: 'title',
        manualWidth: 530,
        maxWidth: 530,
      },
      {
        Header: 'Başlık Sayısı',
        accessor: 'sub_topics_count',
        manualWidth: 100,
        maxWidth: 100,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Durum',
        accessor: 'status',
        manualWidth: 180,
        maxWidth: 180,
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.status ? (
                <div>
                  Aktif <Badge className={'ml-2'} color='green' />
                </div>
              ) : (
                <div>
                  Pasif <Badge className={'ml-2'} color='red' />
                </div>
              )}
            </>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Seçiniz'}
              options={[
                {value: 1, label: 'Aktif'},
                {value: 2, label: 'Pasif'},
              ]}
              maxTagCount={'responsive'}
              className={'ant-selector-border w-100'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }

                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'status')
              }}
            />
          )
        },
      },
      {
        Header: 'Sıra',
        accessor: 'sort_order',
        manualWidth: 100,
        maxWidth: 100,
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        manualWidth: 200,
        maxWidth: 200,
        Filter: <KrcEmptyFilter />,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex justify-content-center gap-3'}>
              <Tooltip title={'Güncelle'}>
                <Link
                  to={`/faq/update/${row.original.faq_id}?store=${selectedStoreId}`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  style={{color: 'unset'}}
                >
                  <EditOutlined />
                </Link>
              </Tooltip>
              <Tooltip title={'Listele'}>
                <Link
                  to={`/faq/${row.original.faq_id}?store=${selectedStoreId}`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  style={{color: 'unset'}}
                >
                  <BarsOutlined />
                </Link>
              </Tooltip>
              <Tooltip title={'Sil'}>
                <Popconfirm
                  title={`Bu konuyu silmek istediğinden emin misiniz?`}
                  onConfirm={() => removeFaq(row.original.faq_id)}
                  okText='Evet'
                  cancelText='Hayır'
                >
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <DeleteOutlined />
                  </div>
                </Popconfirm>
              </Tooltip>
            </div>
          )
        },
      },
    ]
  }, [selectedStoreId])

  useEffect(() => {
    if (!setMeta) {
      return
    }

    setMeta(meta)
  }, [meta.to, meta.from, meta.last_page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (!setMeta) {
      return
    }
    setMeta(meta)
    getFaqTopics()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }
    getFaqTopics()
  }, [meta.limit])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  useEffect(() => {
    if (!meta) {
      return
    }

    setMeta(meta)
  }, [meta])

  useEffect(() => {
    if (!topics) {
      return
    }

    setTopics(topics)
  }, [topics])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getFaqTopics()
  }, [filter, selectedStoreId])

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  const getFaqTopics = (page: null | number = null) => {
    setIsLoading(true)
    fetchRef.current += 1
    const fetchId = fetchRef.current
    let _meta = meta
    const _filter = removeEmptyItemInObject(filter)
    if (page) {
      _meta.page = page
    }
    getFaqRequest(_filter, _meta, Number(selectedStoreId))
      .then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }
        const metaData = res.data.meta
        setTopics(res.data.data)
        setMeta({limit: metaData.per_page, page: metaData.current_page, ...metaData})
      })
      .catch((err) => {
        message.error('Sıkça sorulan sorular getirilirken bir hata oluştu')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const removeFaq = (faqId: number) => {
    deleteFaqRequest(faqId, Number(selectedStoreId))
      .then((response) => {
        message.success('Sıkça sorulan soru başarıyla silindi.')
        getFaqTopics()
      })
      .catch((error) => {
        message.error('Sıkça sorulan soru silinirken bir hata oluştu!')
      })
  }

  return (
    <FaqContext.Provider
      value={{
        isLoading,
        setIsLoading,
        visibleUpdateModal,
        setVisibleUpdateModal,
        topics,
        setTopics,
        meta,
        setMeta,
        columns,
        filter,
        setFilter,
        handleOnFilter,
        selectedStoreId,
        setSelectedStoreId,
      }}
    >
      {children}
    </FaqContext.Provider>
  )
}
