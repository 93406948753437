import {InfoCircleOutlined} from '@ant-design/icons'
import {Alert, message} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'

import {PDP_INFORMATION} from '../../../../../enums/IBMCampaignTabTypeEnums'
import {IBMCampaignSpecialGroupContext} from '../../../../../providers/ibm/IBMCampaignSpecialGroupProvider'
import {
  fetchIBMCampaignGeneralInformation,
  updateIBMCampaignGeneralInformation,
} from '../../../../../requests/ibm/IBMCampaignRequest'
import {KrcButton} from '../../../../atoms/buttons/KrcButton'
import {useDeepLinkInformationModal} from '../../../../hooks/useDeepLinkInformationModal'
import {DeepLinkInformationModal} from '../../../../modals/DeepLinkInformationModal'
import {IBMCampaignPdpInformationType} from '../../../../models/ibm/IBMCampaignDetail'
import {Loader} from '../../../../molecules/Loader'
import {KrcFormikField} from '../../../../molecules/forms/KrcFormikField'

interface CampaignPdpInformationTabProps {
  activeCampaignId: number
}

const initialState: IBMCampaignPdpInformationType = {
  campaign_id: 0,
  campaign_pdp_title: '',
  campaign_pdp_status: 0,
  campaign_pdp_description: '',
  campaign_pdp_url: '',
  campaign_pdp_deep_link: '',
}

export const CampaignPdpInformationTab: FC<CampaignPdpInformationTabProps> = ({
  activeCampaignId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isChanged, setIsChanged] = useState<boolean>(false)
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [campaignPdpInformation, setCampaignPdpInformation] =
    useState<IBMCampaignPdpInformationType>(initialState)
  const [campaignPdpDescription, setCampaignPdpDescription] = useState<string>('')

  const initialRef = useRef(0)
  const {setPageType} = useContext(IBMCampaignSpecialGroupContext)
  const {showDeepLinkInformationModal, setShowDeepLinkInformationModal} =
    useDeepLinkInformationModal()

  useEffect(() => {
    setPageType(PDP_INFORMATION)
    if (initialRef.current === 0) {
      fetchIBMCampaignGeneralInformation(activeCampaignId, 0, 0, 1)
        .then((response) => {
          setCampaignPdpInformation((prev) => {
            return {
              ...prev,
              campaign_id: response.data.data.campaign_id,
              campaign_pdp_title: response.data.data.campaign_pdp_title
                ? response.data.data.campaign_pdp_title.toString()
                : '',
              campaign_pdp_status: response.data.data.campaign_pdp_status.toString(),
              campaign_pdp_url: response.data.data.campaign_pdp_url
                ? response.data.data.campaign_pdp_url.toString()
                : '',
              campaign_pdp_deep_link: response.data.data.campaign_pdp_deep_link,
            }
          })
          setCampaignPdpDescription(response.data.data.campaign_pdp_description)
        })
        .catch((error) => {
          message.error('Kampanya PDP bilgileri getirilirken bir hata oluştu.')
          message.error(error.response.data.message)
        })
        .finally(() => {
          initialRef.current = 1
          setIsLoading(false)
        })
    }
  }, [activeCampaignId])

  const handleSubmit = (values: FormikValues, {setSubmitting, resetForm}: any) => {
    message.loading('Kampanya PDP bilgileri güncelleniyor...')
    const data = {
      ...values,
      campaign_pdp_description: campaignPdpDescription,
    }
    updateIBMCampaignGeneralInformation(activeCampaignId, data, 0, 0, 1)
      .then((res) => {
        setIsSubmit(true)
        setSubmitting(true)
        setIsChanged(false)
        message.success('Kampanya bilgileri başarıyla güncellendi.')
        setTimeout(() => {
          setIsSubmit(false)
          resetForm({values: {...values}})
        }, 500)
      })
      .catch((err) => {
        message.error('Kampanya bilgileri güncellenirken bir hata oluştu.')
        message.error(err.response.data.message, 3)
      })
  }

  return (
    <>
      {isLoading || campaignPdpInformation.campaign_id === 0 ? (
        <Loader />
      ) : (
        <Formik
          initialValues={campaignPdpInformation}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formik) => {
            return (
              <Form className={'form mt-6'}>
                {(formik.dirty || isChanged) && !isSubmit ? (
                  <Alert
                    message='Değişiklik yaptığınızı fark ettik. Sağ alttan değişiklikleri kaydetmeyi unutma!'
                    type='warning'
                    showIcon
                    closable
                  />
                ) : null}
                <div className={'row mt-2'}>
                  <div className={'col-6'}>
                    <div className={'col-6 mb-5'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'campaign_pdp_title'}
                        labelTitle={'PDP Başlığı'}
                      />
                    </div>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'campaign_pdp_url'}
                        labelTitle={'PDP URL'}
                      />
                    </div>
                  </div>
                  <div className={'col-6'}>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-5'}
                        name={'campaign_pdp_status'}
                        labelTitle={'PDP Durumu'}
                      >
                        <Field as='select' name='campaign_pdp_status' className='form-select'>
                          <option value={undefined} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Aktif</option>
                          <option value={0}>Pasif</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'campaign_pdp_deep_link'}
                        labelTitle={'PDP Deep Link'}
                        advancedTooltipProps={{
                          status: true,
                          icon: <InfoCircleOutlined />,
                          onClick: () => setShowDeepLinkInformationModal(true),
                        }}
                      />
                    </div>
                    <DeepLinkInformationModal
                      show={showDeepLinkInformationModal}
                      onHide={() => setShowDeepLinkInformationModal(false)}
                    />
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-8'}>
                    <KrcFormikField
                      className={'mb-2'}
                      type={'text'}
                      name={'campaign_pdp_description'}
                      labelTitle={'Kampanya PDP Açıklaması'}
                    >
                      <TextArea
                        rows={5}
                        placeholder={'Açıklama giriniz...'}
                        value={campaignPdpDescription}
                        style={{borderRadius: '7px'}}
                        onChange={(e) => {
                          setCampaignPdpDescription(e.target.value)
                        }}
                      />
                    </KrcFormikField>
                  </div>
                </div>
                <div className={'d-flex justify-content-end mt-7'}>
                  <KrcButton
                    onClick={() => {
                      formik.handleSubmit()
                    }}
                    type={'text'}
                    size={'large'}
                    className={`custom-hover w-200px text-white button-bg-purple 0 ${
                      (formik.dirty || isChanged) && !isSubmit
                        ? 'border border-2 border-danger'
                        : ''
                    } `}
                  >
                    Kaydet
                  </KrcButton>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}
