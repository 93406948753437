import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import React, {FC, createContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {approveImportRequest} from '../../../requests/categories/campaigns/import/CampaignWaitingApprovalImportRequest'

interface InterfaceImportContext {
  importId?: number
  setImportId: (importId: number | undefined) => void
  setImportLink: (signature: string | undefined) => void
  approveImport: () => void
}

export const ImportContext = createContext<InterfaceImportContext>({
  importId: 0,
  setImportId: () => {},
  setImportLink: () => {},
  approveImport: () => {},
})

const {confirm} = Modal

export const CampaignWaitingApprovalImportProvider: FC = ({children}) => {
  const [importId, setImportId] = useState<number>()
  const [importLink, setImportLink] = useState<string>()
  let navigate = useNavigate()

  useEffect(() => {
    return () => {
      setImportId(undefined)
      setImportLink(undefined)
    }
  }, [])

  const approveImport = () => {
    if (!importId || !importLink) {
      return
    }

    confirm({
      title: `Onay Bekleyen Kampanyaları Toplu Onaylama/Reddetme`,
      icon: <ExclamationCircleOutlined />,
      content:
        'Onay bekleyen kampanyaları toplu şekilde onaylamak/reddetmek istediğinizden emin misiniz? ',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        approveImportRequest(importLink).then(() => {
          navigate(`/categories/campaigns-waiting-approval/import/${importId}/importing`)
        })
      },
    })
  }

  return (
    <>
      <ImportContext.Provider
        value={{
          importId: importId,
          approveImport: approveImport,
          setImportLink,
          setImportId: setImportId,
        }}
        children={children}
      />
    </>
  )
}
