import React, {FC, useContext} from 'react'

import {CategorySelectionEnum} from '../../../../enums/CategorySelectionEnum'
import {CategorySelectionContext} from '../../../../providers/tables/CategorySelectionProvider'
import {transferPropsType} from '../../../models/components/TransferTableModel'
import {TransferTable} from '../../tables/TransferTable'

interface ICategorySelectionList {
  removeAllExcluded?: number
  transferProps?: transferPropsType
  clear?: number
}

export const CategorySelectionList: FC<ICategorySelectionList> = ({
  removeAllExcluded = 0,
  transferProps,
  clear,
}) => {
  const {
    data,
    meta,
    type,
    columns,
    transferIds,
    updateMeta,
    setSelectedItems,
    updateTableFilter,
    updateTransferData,
    updateExcludedCategoryIds,
    setExcludedTempCategoryIds,
  } = useContext(CategorySelectionContext)

  return (
    <>
      <TransferTable
        data={data}
        columns={columns}
        outerHeight={type === CategorySelectionEnum.TEXT ? 485 : 580}
        transferProps={{
          onTransfer: (data) => {
            if (!updateTransferData) {
              return
            }

            updateTransferData(data)
          },
          newTransferredIds: transferIds,
          ...transferProps,
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: (data, id) => {
            if (!updateTableFilter) {
              return
            }

            updateTableFilter({[id]: data})
          },
        }}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangeLimit: (limit) => {
            if (!updateMeta) {
              return
            }
            updateMeta({limit})
          },
          onChangePage: (page) => {
            if (!updateMeta) {
              return
            }
            updateMeta({page})
          },
        }}
        clear={clear}
        excludedProps={{
          onExcluded: (data) => {
            if (updateExcludedCategoryIds) {
              updateExcludedCategoryIds(data)
            }
          },
          onTempExcluded: (data) => {
            if (setExcludedTempCategoryIds) {
              setExcludedTempCategoryIds(data)
            }
          },
          removeAllExcluded,
        }}
        onSelectedItems={setSelectedItems}
      />
    </>
  )
}
