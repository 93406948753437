import {SelectProps, Spin} from 'antd'
import React, {FC, useEffect} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useRoleFilter} from '../../hooks/roles/useRoleFilter'
import {IRoleFilter} from '../../models/roles/RoleFilterModel'

const roleFilterConfig = {
  default: {
    style: {width: '100%'},
    mode: 'multiple',
    size: 'middle',
    placeholder: 'Rol Filtre Listesi',
    allowClear: false,
  },
}

export const RoleFilter: FC<IRoleFilter & SelectProps> = ({
  onSelected,
  dataType = 'value',
  clear,
  ...props
}) => {
  const currentProps = useKrcProp({config: roleFilterConfig.default, props}).getCurrentProps()
  const {handleOnChange, handleSearch, roleIds, fetching, options, selectedItems} = useRoleFilter()

  useEffect(() => {
    if (!onSelected) {
      return
    }

    if (dataType === 'value') {
      onSelected(roleIds)
      return
    }

    onSelected(selectedItems)
  }, [roleIds])

  return (
    <>
      <KrcSelect
        clear={clear}
        {...currentProps}
        showArrow
        filterOption={false}
        labelInValue
        onSearch={handleSearch}
        onChange={handleOnChange}
        options={options}
        notFoundContent={fetching ? <Spin size='small' /> : null}
      />
    </>
  )
}
