import React, {FC} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {BannerImageManagerProvider} from '../../../../providers/images/BannerImageManagerProvider'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {BannerImageManager} from './BannerImageManager'

const BannerImageManagerWrapperProvider: FC = () => {
  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Banner Image Manager</PageTitle>
      <BannerImageManager />
    </>
  )
}

export const BannerImageManagerWrapper: FC = () => {
  return (
    <>
      <BannerImageManagerProvider>
        <BannerImageManagerWrapperProvider />
      </BannerImageManagerProvider>
    </>
  )
}
