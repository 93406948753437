import {Button, Empty} from 'antd'
import React, {FC, useContext} from 'react'

import {FooterModulesContext} from '../../../../providers/FooterModulesProvider'
import {StoreModuleDataType} from '../../../models/footer/StoreModulesModel'
import {KrcTabs} from '../../../molecules/KrcTabs'
import {FooterSingleModuleTabWrapper} from './Tabs/FooterSingleModuleTabWrapper'

export const FooterModuleTabView: FC = () => {
  const {currentStore, selectedStoreModules, currentModuleId, setVisibleCreateModal} =
    useContext(FooterModulesContext)
  return (
    <>
      {selectedStoreModules.length > 0 ? (
        <KrcTabs
          isActiveKey={false}
          tabsClassName={'mb-1'}
          tabsProps={{
            defaultActiveKey: currentModuleId.toString(),
          }}
          tabProps={selectedStoreModules.map((storeModule: StoreModuleDataType) => {
            return {
              eventKey: storeModule.module_id.toString(),
              title: storeModule.module_name,
              children: (
                <FooterSingleModuleTabWrapper key={storeModule.module_id} module={storeModule} />
              ),
              key: storeModule.module_id,
            }
          })}
        />
      ) : (
        <Empty
          image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
          imageStyle={{
            height: 60,
          }}
          description={<span>{currentStore.store_name} mağazası için modül bulunamadı.</span>}
        >
          <Button type='primary' onClick={() => setVisibleCreateModal(true)}>
            Şimdi Oluştur
          </Button>
        </Empty>
      )}
    </>
  )
}
