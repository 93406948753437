import {Route, Routes} from 'react-router-dom'

import {LayoutWrapper} from '../components/pages/layouts/LayoutWrapper'
import {LayoutComponentDetailWrapper} from '../components/pages/layouts/details/LayoutComponentDetailWrapper'

export const LayoutRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<LayoutWrapper />} />
        <Route path={'components/:componentId'} element={<LayoutComponentDetailWrapper />} />
      </Routes>
    </>
  )
}
