import {message} from 'antd'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'

import {DrawerEnum} from '../../../../enums/DrawerEnum'
import {
  CREATE_CATEGORY_BANNER_HANDLE_LINK_KEY,
  CREATE_CATEGORY_BANNER_KEY,
  UPDATE_CATEGORY_BANNER_KEY,
} from '../../../../enums/category/banner/CategoryBannerMessageKeyEnum'
import {errorMessage} from '../../../../helpers/ErrorHelper'
import {fillAllNullValueInObject} from '../../../../helpers/ObjectHelper'
import {
  createCategoryBannerRequest,
  updateCategoryBannerRequest,
} from '../../../../requests/categories/banner/CategoryBannerRequest'
import {getStoresRequest} from '../../../../requests/stores/StoreRequest'
import {getUrlAliasByKeywordRequest} from '../../../../requests/url/UrlAliasRequest'
import {setBanner} from '../../../../store/Category/Banner/CategoryBannerAction'
import {
  IUseCategoryBannerDrawer,
  categoryBannerDrawerInitValuesType,
  drawerDataWithoutFormikType,
} from '../../../models/categories/CategoryBannerModel'
import {krcSelectOptions} from '../../../models/components/KrcSelectModel'
import {storeDataType} from '../../../models/stores/StoreModel'

const initialValues: categoryBannerDrawerInitValuesType = {
  id: 0,
  category_id: 0,
  title: '',
  link: '',
  image: '',
  mobile_image: '',
  type: '',
  type_id: 0,
  stores: [],
  sort_order: 0,
  start_time: null,
  end_time: null,
}

const schema = Yup.object({
  link: Yup.string().required('Bağlantı zorunlu alandır.'),
})

export const useCategoryBannerDrawer = (payload: IUseCategoryBannerDrawer) => {
  const dispatch = useDispatch()
  const {categoryId} = useParams<string>()
  const [initValues, setInitValues] = useState<categoryBannerDrawerInitValuesType>(initialValues)
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(true)
  const [stores, setStores] = useState<krcSelectOptions[]>()
  const [drawerDataWithoutFormik, setDrawerDataWithoutFormik] =
    useState<drawerDataWithoutFormikType>()

  useEffect(() => {
    getStores()
  }, [])

  useEffect(() => {
    let inits = initialValues

    if (payload.type === DrawerEnum.UPDATE && payload?.bannerData) {
      inits = {...inits, ...payload.bannerData}

      const storeIds = payload.bannerData.stores?.map((store) => store.store_id) ?? []
      setDrawerDataWithoutFormik((data) => ({...data, bannerStore: storeIds}))
    }

    fillAllNullValueInObject(inits)

    setInitValues(inits)
  }, [payload.type, payload.bannerData])

  const onSaveHandle = () => {
    setVisibleDrawer(false)
    dispatch(setBanner(null))

    payload?.onSave && payload.onSave()
  }

  const getStores = () => {
    getStoresRequest().then((res) => {
      const _stores = res.data.data.map((store: storeDataType) => {
        return {value: store.store_id, label: store.name}
      })

      setStores(_stores)
    })
  }

  const handleOnSubmit = (values: categoryBannerDrawerInitValuesType) => {
    const data = {
      id: values.id,
      category_id: values.category_id,
      title: values.title,
      link: values.link,
      image: drawerDataWithoutFormik?.image,
      mobile_image: drawerDataWithoutFormik?.mobile_image,
      type: values.type,
      type_id: values.type_id,
      sort_order: values.sort_order,
      store_ids: values.stores,
      start_time: values.start_time,
      end_time: values.end_time,
    }

    if (payload.type === DrawerEnum.UPDATE) {
      handleOnUpdateCategoryBanner(values, data)
    } else {
      handleOnCreateCategoryBanner(values, data)
    }
  }

  const handleLink = (values: any, keyword: any) => {
    keyword = keyword.trim()

    if (!keyword) {
      return
    }

    message.loading({content: 'ID alınıyor.', CREATE_CATEGORY_BANNER_HANDLE_LINK_KEY})

    getUrlAliasByKeywordRequest(keyword)
      .then((response) => {
        const res = response.data.data
        const link = keyword
        const type = res.type
        const type_id = Number(res.type_id)

        setInitValues({...values, link, type, type_id})

        if (type) {
          message.success({
            content: 'ID alındı.',
            CREATE_CATEGORY_BANNER_HANDLE_LINK_KEY,
            duration: 2,
          })
        } else {
          message.error({
            content: 'Bu keyword ile ID bulunamadı.',
            CREATE_CATEGORY_BANNER_HANDLE_LINK_KEY,
            duration: 2,
          })
        }
      })
      .catch((error) => {
        errorMessage(error)
        message.destroy(CREATE_CATEGORY_BANNER_HANDLE_LINK_KEY)
      })
  }

  const handleDates = (values: any, dates: any) => {
    const [start_time, end_time] = dates.map((date: any) => {
      if (date) {
        date.set({second: 0, millisecond: 0})

        return date.format('YYYY-MM-DD H:mm:ss')
      }

      return null
    })

    if (start_time && end_time) {
      setInitValues({...values, start_time, end_time})
    }
  }

  const handleOnCreateCategoryBanner = (
    values: categoryBannerDrawerInitValuesType,
    data: object
  ) => {
    message.loading({content: 'Banner oluşturuluyor.', CREATE_CATEGORY_BANNER_KEY, duration: 1})

    createCategoryBannerRequest(Number(categoryId), data)
      .then((res) => {
        message.success({content: 'Banner oluşturuldu.', CREATE_CATEGORY_BANNER_KEY, duration: 2})
        onSaveHandle()
      })
      .catch((error) => {
        errorMessage(error)
        message.destroy(CREATE_CATEGORY_BANNER_KEY)
      })
  }

  const handleOnUpdateCategoryBanner = (
    values: categoryBannerDrawerInitValuesType,
    data: object
  ) => {
    message.loading({content: 'Banner güncelleniyor.', UPDATE_CATEGORY_BANNER_KEY, duration: 1})

    updateCategoryBannerRequest(values.category_id, values.id, data)
      .then((res) => {
        message.success({content: 'Banner güncellendi.', UPDATE_CATEGORY_BANNER_KEY, duration: 2})
        onSaveHandle()
      })
      .catch((error) => {
        errorMessage(error)
        message.destroy(UPDATE_CATEGORY_BANNER_KEY)
      })
  }

  return {
    initValues,
    setInitValues,
    visibleDrawer,
    setVisibleDrawer,
    schema,
    handleOnSubmit,
    handleLink,
    handleDates,
    stores,
    drawerDataWithoutFormik,
    setDrawerDataWithoutFormik,
    initialValues,
  }
}
