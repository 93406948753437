import {IOrderModel} from '../../../../components/models/order/OrderModel'
import {KrcTabs} from '../../../molecules/KrcTabs'
import PaymentGeneralInformation from './tabs/PaymentGeneralInformation'

const Payment: React.FC<IOrderModel> = (props) => {
  return (
    <div className='block-wrapper radius krc'>
      <KrcTabs
        isActiveKey={true}
        tabsProps={{defaultActiveKey: 'payment'}}
        tabProps={[
          {
            eventKey: 'payment',
            title: 'Ödeme Detayı',
            children: props.order ? <PaymentGeneralInformation order={props.order} /> : '',
          },
        ]}
      />
    </div>
  )
}

export default Payment
