import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  ProductImportContext,
  ProductImportProvider,
} from '../../../../providers/products/ProductImportProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {ProductsImport} from './ProductsImport'

const ProductImportToolbarRightField: FC = () => {
  const {productImportId, approveProductImport, redirectToDownloadExampleFile} =
    useContext(ProductImportContext)
  return (
    <>
      {productImportId ? (
        <div className='d-flex align-items-center py-1'>
          <KrcButton size={'middle'} onClick={approveProductImport} id='kt_toolbar_primary_button'>
            {'Onayla'}
          </KrcButton>
        </div>
      ) : (
        <div className='d-flex align-items-center py-1'>
          <KrcButton
            size={'middle'}
            onClick={redirectToDownloadExampleFile}
            id='kt_toolbar_primary_button'
          >
            {'Örnek Excel İndir'}
          </KrcButton>
        </div>
      )}
    </>
  )
}

const ProductsImportWrapperWithoutProvider: FC = () => {
  return (
    <>
      <Toolbar rightField={<ProductImportToolbarRightField />}></Toolbar>
      <PageTitle>Toplu Ürün Açma</PageTitle>
      <ProductsImport />
    </>
  )
}

export const ProductsImportWrapper: FC = () => {
  return (
    <>
      <ProductImportProvider>
        <ProductsImportWrapperWithoutProvider />
      </ProductImportProvider>
    </>
  )
}
