import React, {FC} from 'react'

import {IMappingTableBodyModel} from '../../models/menus/MenuMapModel'
import {KrcTable} from '../tables/KrcTable'

export const MappingTableBody: FC<IMappingTableBodyModel> = ({data, columns, onSelect}) => {
  return (
    <KrcTable
      data={data}
      columns={columns}
      outerHeight={250}
      isGlobalFilter={true}
      onChoose={(row) => {
        if (!onSelect) {
          return
        }

        onSelect(row)
      }}
    />
  )
}
