import {BarsOutlined} from '@ant-design/icons'
import {Tooltip} from 'antd'
import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Column} from 'react-table'

import {InformationMainTopicType} from '../../components/models/information/InformationModel'
import {INFORMATION_STORES} from '../../enums/InformationStoresTypeEnum'
import {fetchMainInformationRequest} from '../../requests/information/InformationRequest'

interface IInformationContext {
  columns: Column<any>[]
  topics: InformationMainTopicType[]
  setTopics: (topics: InformationMainTopicType[]) => void
  selectedStoreId: string
  setSelectedStoreId: (storeId: string) => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
}

export const InformationContext = React.createContext<IInformationContext>({
  columns: [],
  topics: [],
  setTopics: () => {},
  selectedStoreId: INFORMATION_STORES[2].id.toString(),
  setSelectedStoreId: () => {},
  isLoading: false,
  setIsLoading: () => {},
})

export const InformationProvider = ({children}: {children: React.ReactNode}) => {
  const [topics, setTopics] = React.useState<InformationMainTopicType[]>([])
  const [selectedStoreId, setSelectedStoreId] = React.useState<string>(
    INFORMATION_STORES[2].id.toString()
  )
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'topic_id',
      },
      {
        Header: 'Bilgi Başlığı',
        accessor: 'topic_name',
      },
      {
        Header: 'Başlık Sayısı',
        accessor: 'sub_topic_count',
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        Cell: ({row}: any) => {
          return (
            <div className='d-flex justify-content-center'>
              <Tooltip title={'Listele'}>
                <Link
                  to={`/information/${row.original.topic_id}?store=${selectedStoreId}`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                  style={{color: 'unset'}}
                >
                  <BarsOutlined />
                </Link>
              </Tooltip>
            </div>
          )
        },
      },
    ],
    [selectedStoreId]
  )

  useEffect(() => {
    fetchInformation()
  }, [selectedStoreId])

  const fetchInformation = () => {
    setIsLoading(true)
    fetchMainInformationRequest(Number(selectedStoreId))
      .then((response) => {
        setTopics(response.data.data)
      })
      .catch((error) => {
        setTopics([])
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <InformationContext.Provider
      value={{
        columns,
        topics,
        setTopics,
        selectedStoreId,
        setSelectedStoreId,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </InformationContext.Provider>
  )
}
