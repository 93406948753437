import axios from 'axios'
import * as querystring from 'query-string'

const API_URL = process.env.REACT_APP_API_URL || ''
export const DEALS_URL = `${API_URL}/deals`

export const exportDealsRequest = (params: object) => {
  return axios.get(`${DEALS_URL}/export`, {
    params: params,
  })
}

export const checkDealsExportRequest = ($fileName: string) => {
  return axios.get(`${DEALS_URL}/check-export`, {
    params: {
      file_name: $fileName,
    },
  })
}

export const getDealsExportFrontendUrl = () => {
  return `/deals/export?${querystring.stringify(
    {},
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}

export const exportDealProductsRequest = (params: object, dealId: string) => {
  return axios.get(`${DEALS_URL}/${dealId}/products/export`, {
    params: params,
  })
}

export const checkDealProductsExportRequest = ($fileName: string) => {
  return axios.get(`${DEALS_URL}/products/check-export`, {
    params: {
      file_name: $fileName,
    },
  })
}

export const getDealProductsExportFrontendUrl = (dealId: number, filters: any) => {
  return `/deals/${dealId}/products/export?${querystring.stringify(
    {...filters},
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}
