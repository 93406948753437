import axios from 'axios'

import {
  IUpdateFooterModuleSubModule,
  updateOrCreateModuleWithoutSubModulesDataType,
} from '../../components/models/footer/StoreModulesModel'

const API_URL = process.env.REACT_APP_API_URL || ''
export const FOOTER_MODULE_URL = `${API_URL}/footer-modules`
export function getFooterModulesByStoreIdRequest(storeId: number) {
  return axios.get(`${FOOTER_MODULE_URL}`, {
    params: {
      store_id: storeId,
    },
  })
}

export function createFooterModuleByStoreIdRequest(
  storeId: number,
  data: updateOrCreateModuleWithoutSubModulesDataType
) {
  return axios.post(`${FOOTER_MODULE_URL}`, {
    ...data,
    store_id: storeId,
  })
}

export function updateFooterModuleInformationByModuleIdRequest(
  moduleId: number,
  data: updateOrCreateModuleWithoutSubModulesDataType
) {
  return axios.put(`${FOOTER_MODULE_URL}/${moduleId}`, data)
}

export function deleteFooterModuleByModuleIdRequest(moduleId: number) {
  return axios.delete(`${FOOTER_MODULE_URL}/${moduleId}`)
}

export function getFooterModuleInformationByModuleIdRequest(moduleId: number) {
  return axios.get(`${FOOTER_MODULE_URL}/${moduleId}`)
}

export function getFooterModuleSubModulesByModuleIdRequest(moduleId: number) {
  return axios.get(`${FOOTER_MODULE_URL}/${moduleId}/sub-modules`)
}

export function createFooterSubModuleToParentModuleRequest(
  moduleId: number,
  data: IUpdateFooterModuleSubModule
) {
  return axios.put(`${FOOTER_MODULE_URL}/${moduleId}/sub-modules`, data)
}

export function saveFooterModuleSubModulesRequest(
  moduleId: number,
  subModules: IUpdateFooterModuleSubModule
) {
  return axios.put(`${FOOTER_MODULE_URL}/${moduleId}/sub-modules`, subModules)
}
