import axios from 'axios'

import {paginationMetaType} from '../../../components/models/GeneralModel'
import {
  categoryProductLogFilterType,
  getCategoryProductsType,
} from '../../../components/models/categories/CategoryProductModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const CATEGORY_URL = `${API_URL}/categories`

export const categoryProductsRequest = ({
  categoryId,
  page,
  limit,
  relations,
  excludedProductIds,
  filterTable,
  filter,
}: getCategoryProductsType) => {
  return axios.post(`${CATEGORY_URL}/${categoryId}/products/search`, {
    page,
    limit: limit,
    with: relations,
    excluded_product_ids: excludedProductIds,
    product_ids: filter?.productIds || [],
    models: filter?.models || [],
    product_id: filterTable?.id,
    name: filterTable?.name,
    model: filterTable?.model,
    date_added_start: filterTable?.date_added_start,
    date_added_end: filterTable?.date_added_end,
  })
}

export function getCategoryPendingApprovalRequest(
  categoryId: number,
  relations: string[] = [],
  params: object = {}
) {
  return axios.get(`${CATEGORY_URL}/${categoryId}/products/pending-approval`, {
    params: {
      with: relations,
      ...params,
    },
  })
}

export function getCategoryHistoryApprovalProductsRequest(
  categoryId: number,
  relations: string[] = [],
  params: object = {}
) {
  return axios.get(`${CATEGORY_URL}/${categoryId}/products/approval/history`, {
    params: {
      with: relations,
      ...params,
    },
  })
}

export function categoryPendingApprovalApproveRequest(categoryId: number, data: object) {
  return axios.post(`${CATEGORY_URL}/${categoryId}/products/pending-approval/approve`, data)
}

export function categoryPendingApprovalRejectRequest(categoryId: number, data: object) {
  return axios.post(`${CATEGORY_URL}/${categoryId}/products/pending-approval/reject`, data)
}

export function updateCategoryProductRequest(categoryId: number, data: any) {
  return axios.put(`${CATEGORY_URL}/${categoryId}/products`, data)
}

export function updateCategoryProductInformationRequest(categoryId: number, data: any) {
  return axios.put(`${CATEGORY_URL}/${categoryId}/constraint/products`, data)
}

export function deleteCategoryProductRequest(categoryId: number, data: any) {
  return axios.post(`${CATEGORY_URL}/${categoryId}/constraint/product/delete`, data)
}

export function getCategoryProductsActivityRequests(
  categoryId: number,
  filters: categoryProductLogFilterType,
  meta: paginationMetaType
) {
  return axios.get(`${CATEGORY_URL}/${categoryId}/products/logs`, {
    params: {
      ...filters,
      limit: meta.limit,
      page: meta.page,
    },
  })
}
