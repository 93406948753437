import {FC} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {INFORMATION, OTHER_INFORMATION} from '../../enums/DeepLinkInformationEnum'
import {useDeepLinkInformationModal} from '../hooks/useDeepLinkInformationModal'
import {KrcTable} from '../organisms/tables/KrcTable'
import {KrcModal} from './KrcModal'

interface DeepLinkInformationModalProps {
  show: boolean
  onHide: () => void
}

export const DeepLinkInformationModal: FC<DeepLinkInformationModalProps> = ({show, onHide}) => {
  const {columns} = useDeepLinkInformationModal()
  return (
    <>
      {show && (
        <KrcModal
          modalProps={{
            size: 'lg',
            onHide,
          }}
          modalHeaderProps={{
            onHide,
            title: 'Deep Link Bilgileri',
          }}
        >
          <Modal.Body className='py-lg-5 px-lg-10'>
            <h3>Temel Deeplinkler</h3>
            <KrcTable columns={columns} data={INFORMATION} cellClassName={'fs-8'} />
            <h3>Diğer Deeplinkler</h3>
            <KrcTable columns={columns} data={OTHER_INFORMATION} cellClassName={'fs-8'} />
          </Modal.Body>
        </KrcModal>
      )}
    </>
  )
}
