import {OptionData} from 'rc-select/lib/interface'
import {useState} from 'react'

import {CategoryTypeEnum} from '../../models/categories/CategoryModel'

export const categoryTypeOptions = [
  {
    value: CategoryTypeEnum.GHOST,
    label: 'Ghost Kategori',
  },
  {
    value: CategoryTypeEnum.CATEGORY,
    label: 'Kategori',
  },
  {
    value: CategoryTypeEnum.BOUTIQUE,
    label: 'Butik',
  },
]

export const oldWithCategoryTypeOptions = [
  {
    value: '*',
    label: 'Hepsi',
  },
  {
    value: CategoryTypeEnum.GHOST,
    label: 'Ghost Kategori',
  },
  {
    value: CategoryTypeEnum.CATEGORY,
    label: 'Kategori',
  },
  {
    value: CategoryTypeEnum.CAMPAIGN,
    label: 'Kampanya',
  },
  {
    value: CategoryTypeEnum.BOUTIQUE,
    label: 'Butik',
  },
]

export const useCategoryTypeFilter = () => {
  const [selectedItems, setSelectedItems] = useState<OptionData[]>([])

  const handleOnChange = (payload: Array<OptionData> | OptionData) => {
    if (!payload) {
      return
    }

    if (Array.isArray(payload)) {
      setSelectedItems(payload)
      return
    }

    setSelectedItems([payload] as Array<OptionData>)
  }

  return {
    options: categoryTypeOptions,
    handleOnChange,
    selectedItems,
  }
}
