import {Space} from 'antd'
import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {UrlAliasContext, UrlAliasProvider} from '../../../providers/UrlAliasProvider'
import {getUrlAliasesExportFrontendUrl} from '../../../requests/url/UrlAliasExportRequest'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {UrlAliasList} from './UrlAliasList'

export const UrlAliasListBody: FC = () => {
  return (
    <>
      <UrlAliasList />
    </>
  )
}

const UrlAliasListWrapperWithoutProvider = () => {
  const {filter, setVisibleCreateModal} = useContext(UrlAliasContext)
  return (
    <>
      <Toolbar
        rightField={
          <Space>
            <a
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-3'
              href={getUrlAliasesExportFrontendUrl(filter)}
              target={'_blank'}
              rel='noreferrer'
            >
              Dışarı Aktar
            </a>

            <KrcButton
              size={'middle'}
              onClick={() => {
                setVisibleCreateModal(true)
              }}
              id='kt_toolbar_primary_button'
            >
              {'Yeni Oluştur'}
            </KrcButton>
          </Space>
        }
      />
      <PageTitle>Url Alias</PageTitle>
      <UrlAliasListBody />
    </>
  )
}

export const UrlAliasListWrapper: FC = () => {
  return (
    <>
      <UrlAliasProvider>
        <UrlAliasListWrapperWithoutProvider />
      </UrlAliasProvider>
    </>
  )
}
