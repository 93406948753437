import {useEffect, useMemo, useState} from 'react'

import {IOrderModel} from '../../../../../components/models/order/OrderModel'
import {orderSubBlockModel} from '../../../../models/order/OrderSubBlockModel'
import {KrcTable} from '../../../../organisms/tables/KrcTable'

const CustomerInvoiceInformation: React.FC<IOrderModel> = (props) => {
  const [data, setData] = useState<orderSubBlockModel[]>([])

  useEffect(() => {
    setData([
      {
        key: '1',
        field: 'Ad - Soyad',
        value: props.order.invoiceCustomer.firstName + ' ' + props.order.invoiceCustomer.lastName,
      },
      {
        key: '2',
        field: 'Adres 1',
        value: props.order.invoiceCustomer.address.addressText,
      },
      {
        key: '3',
        field: 'Mahalle',
        value: props.order.invoiceCustomer.address.neighborhood,
      },
      {
        key: '4',
        field: 'İlçe / Semt',
        value: props.order.invoiceCustomer.address.county,
      },
      {
        key: '5',
        field: 'Şehir / Eyalet',
        value: props.order.invoiceCustomer.address.city,
      },
      {
        key: '7',
        field: 'Ülke',
        value: props.order.invoiceCustomer.address.country,
      },
    ])
  }, [props.order.invoiceCustomer])

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'field',
        width: '40%',
      },
      {
        Header: '',
        accessor: 'value',
      },
    ]
  }, [])

  return (
    <>
      {props.order && (
        <KrcTable
          columns={columns}
          data={data}
          showHeader={false}
          cellClassName='font-weight-normal'
          stylesProps={{style: {margin: 0, padding: 0}, className: ['order-block-table']}}
        />
      )}
    </>
  )
}

export default CustomerInvoiceInformation
