export const COOKPLUS = 0
export const KARACA_HOME = 1
export const KARACA = 2
export const EMSAN = 3
export const JUMBO = 7
export const KARACA_APP = 12
export const PERA_BULVARI = 13
export const WEIMER = 16
export const HOMEND = 18
export const KASMIR = 22

export const FAQ_STORES = [
  {
    id: COOKPLUS,
    store_name: 'Cookplus',
  },
  {
    id: KARACA_HOME,
    store_name: 'Karaca Home',
  },
  {
    id: KARACA,
    store_name: 'Karaca',
  },
  {
    id: EMSAN,
    store_name: 'Emsan',
  },
  {
    id: JUMBO,
    store_name: 'Jumbo',
  },
  {
    id: KARACA_APP,
    store_name: 'Karaca App',
  },
  {
    id: PERA_BULVARI,
    store_name: 'Pera Bulvarı',
  },
  {
    id: WEIMER,
    store_name: 'Weimer',
  },
  {
    id: HOMEND,
    store_name: 'Homend',
  },
  {
    id: KASMIR,
    store_name: 'Kaşmir',
  },
]

export const AVAILABLE_FAQ_STORES = FAQ_STORES.map((store) => store.id)
