import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''

export const FAQ_URL = `${API_URL}/faq`

export function getFaqRequest(
  filter: any,
  {page, limit}: paginationMetaType,
  storeId: number,
  allData: number = 0
) {
  return axios.get(`${FAQ_URL}`, {
    params: {
      store_id: storeId,
      all_data: allData,
      page,
      limit,
      ...filter,
    },
  })
}

export function showFaqRequest(id: number) {
  return axios.get(`${FAQ_URL}/${id}`)
}

export function createFaqRequest(data: any) {
  return axios.post(`${FAQ_URL}`, data)
}

export function updateFaqRequest(id: number, data: any) {
  return axios.put(`${FAQ_URL}/${id}`, data)
}

export function deleteFaqRequest(id: number, storeId: number) {
  return axios.delete(`${FAQ_URL}/${id}`, {
    params: {
      store_id: storeId,
    },
  })
}
