import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const ORDERS_URL = `${API_URL}/orders`

export function fetchOrders(filter: any, {page, limit}: paginationMetaType) {
  return axios.get(`${ORDERS_URL}`, {
    params: {
      page,
      limit,
      ...filter,
    },
  })
}

export function fetchOrders2(filter: any, {page, limit}: paginationMetaType) {
  return axios.get(`${ORDERS_URL}/list`, {
    params: {
      page_number: page,
      page_size: limit,
      ...filter,
    },
  })
}

export function fetchOrder(order_id: any) {
  return axios.get(`${ORDERS_URL}/${order_id}`)
}

export function fetchOrderStatusOptions() {
  return axios.get(`${ORDERS_URL}/status`)
}

export function orderCancel(order_id: number, item: number) {
  return axios.delete(`${ORDERS_URL}/${order_id}/line/${item}`)
}

export function updateOrderAddress(orderId: number, address: any) {
  return axios.patch(`${ORDERS_URL}/${orderId}`, address)
}
