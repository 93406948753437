import {Checkbox} from 'antd'
import type {CheckboxChangeEvent} from 'antd/es/checkbox'
import {Form, Formik} from 'formik'
import React, {FC, useContext} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {MenuMapContext} from '../../../providers/tables/MenuMapProvider'
import {KrcModal} from '../KrcModal'

interface IinitialValues {
  delete_top_menu: boolean
}

const initialValues: IinitialValues = {
  delete_top_menu: true,
}

export const DeleteMenuModal: FC = () => {
  const {setIsShowDeleteModal, willBeRemovedMenuData, deleteMenu} = useContext(MenuMapContext)
  const [isShowTopMenu, setIsShowTopMenu] = React.useState(true)
  const onToggleShowTopMenu = (e: CheckboxChangeEvent) => {
    setIsShowTopMenu(e.target.checked)
  }
  return (
    <>
      <KrcModal
        modalProps={{
          onHide: () => {
            setIsShowDeleteModal(false)
          },
        }}
        modalHeaderProps={{
          title: 'Menü Sil: (' + willBeRemovedMenuData?.data.name + ')',
          onHide: () => {
            setIsShowDeleteModal(false)
          },
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10'>
          <div>
            <h3>Menüyü Silmek İstediğinize Emin Misiniz?</h3>
            <p>
              Aşşağıdaki kutuyu işaretlemeniz durumunda menü silinirken kategorinin de üst menüde
              göster değeri kaldırılacaktır. <br />
              Üst menüde göster değeri, mobil uygulmaya iletilecek kategorileri belirler.
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              deleteMenu(
                willBeRemovedMenuData.menuId,
                willBeRemovedMenuData.deep,
                willBeRemovedMenuData.data,
                isShowTopMenu
              )
            }}
          >
            {(formik) => (
              <Form className='form row ml-4' noValidate>
                <Checkbox checked={isShowTopMenu} onChange={onToggleShowTopMenu}>
                  Üst Menüde Görünürlüğü Kaldır
                </Checkbox>
                <p className={'mt-2 text-danger'}>
                  Silmek istenilen menü bir kategoriye bağlı değil ise bu değer güncellenmeyecektir.
                </p>
                <div className={'d-flex justify-content-end mt-7'}>
                  <div className='btn btn-secondary' onClick={() => setIsShowDeleteModal(false)}>
                    İptal
                  </div>
                  <button type='submit' className='btn btn-success' style={{marginLeft: 10}}>
                    Kaydet
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
