import TextArea from 'antd/lib/input/TextArea'
import {FC} from 'react'

import {KrcButton} from '../../atoms/buttons/KrcButton'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useCategoryTextAreaFilter} from '../../hooks/products/useCategoryTextAreaFilter'
import {ICategoryTextAreaFilter} from '../../models/categories/CategoryListFilterModel'

const defaultTextAreaProps = {
  default: {
    rows: 6,
    placeholder: 'Kategori ID ile toplu arama yapılabilir. \nÖrneğin: \n11 \n13846 ',
  },
}

const defaultKrcButtonProps = {
  default: {
    className: 'w-15',
    size: 'large',
    type: 'primary',
  },
}

export const CategoryTextAreaFilter: FC<ICategoryTextAreaFilter> = ({
  textAreaProps,
  krcButtonProps,
  onFilter,
  withExtraText = false,
}) => {
  const {handleOnChange, getCategoryIdInText} = useCategoryTextAreaFilter()

  const currentTextAreaProps = useKrcProp({
    config: defaultTextAreaProps.default,
    props: textAreaProps,
  }).getCurrentProps()

  const currentKrcButtonProps = useKrcProp({
    config: defaultKrcButtonProps.default,
    props: krcButtonProps,
  }).getCurrentProps()

  const handleOnFilter = () => {
    if (!onFilter) {
      return
    }

    onFilter(getCategoryIdInText(withExtraText))
  }

  return (
    <>
      <TextArea {...currentTextAreaProps} onChange={handleOnChange}></TextArea>
      <div className={'d-flex justify-content-end mt-3'}>
        <KrcButton {...currentKrcButtonProps} onClick={handleOnFilter}>
          Filtrele
        </KrcButton>
      </div>
    </>
  )
}
