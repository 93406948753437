import axios from 'axios'

import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || ''

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/me`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const OTP_VERIFICATION_URL = `${API_URL}/auth/otp/verification`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {username: email, password})
}

export function otpVerification(email: string, code: string) {
  return axios.post(OTP_VERIFICATION_URL, {email, code})
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
