import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
export const GAMES_URL = `${API_URL}/games`

export const getGamesRequest = () => {
  return axios.get(`${GAMES_URL}`)
}

export const getGameDetailRequest = (gameId: number) => {
  return axios.get(`${GAMES_URL}/${gameId}`)
}

export const createGameRequest = (data: any) => {
  return axios.post(`${GAMES_URL}`, data)
}

export const updateGameRequest = (gameId: number, data: any) => {
  return axios.put(`${GAMES_URL}/${gameId}`, data)
}

export const deleteGameRequest = (gameId: number) => {
  return axios.delete(`${GAMES_URL}/${gameId}`)
}

export const updateGameSettingsRequest = (gameId: number, data: any) => {
  return axios.put(`${GAMES_URL}/${gameId}/settings`, data)
}

export const updateSortGameRequest = (gameIds: number[]) => {
  return axios.put(`${GAMES_URL}/sort`, {sorted_game_ids: gameIds})
}
