import {SelectProps, Spin} from 'antd'
import {OptionData} from 'rc-select/es/interface'
import React, {FC, useEffect} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useCustomerFilter} from '../../hooks/customer/useCustomerFilter'

const customerFilterConfig = {
  default: {
    style: {width: '100%'},
    mode: undefined,
    size: 'middle',
    placeholder: 'Kullanıcı Filtre Listesi',
    allowClear: false,
  },
}

interface ICustomerFilter {
  onSelected: (data: number[] | OptionData[]) => void
  dataType?: 'value' | 'item'
  clear?: number
}

export const CustomerFilter: FC<ICustomerFilter & SelectProps> = ({
  onSelected,
  dataType = 'value',
  children,
  clear,
  ...props
}) => {
  const currentProps = useKrcProp({config: customerFilterConfig.default, props}).getCurrentProps()
  const {fetching, options, handleSearch, handleOnChange, selectedCustomerIds, selectedItems} =
    useCustomerFilter({})
  const initialRef = React.useRef(0)

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current = 1

      return
    }

    if (dataType === 'value') {
      onSelected(selectedCustomerIds)
      return
    }

    onSelected(selectedItems)
  }, [selectedCustomerIds])

  return (
    <>
      <KrcSelect
        clear={clear}
        {...currentProps}
        showArrow
        filterOption={false}
        labelInValue
        onSearch={handleSearch}
        onChange={handleOnChange}
        options={options}
        notFoundContent={fetching ? <Spin size='small' /> : null}
      >
        {children}
      </KrcSelect>
    </>
  )
}
