import {InputNumberProps} from 'antd/lib/input-number'
import {FC, useEffect, useRef} from 'react'

import {KrcNumberInput} from '../../atoms/forms/KrcNumberInput'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useMinMaxFilter} from '../../hooks/components/useMinMaxFilter'
import {INumberInputConfig} from '../../models/components/KrcNumberInputModel'
import {IMinMaxFilterModel} from '../../models/components/MinMaxFilterModel'

const minMaxFilterConfig = {
  default: {
    size: 'middle',
  },
}

export const MinMaxFilter: FC<InputNumberProps & INumberInputConfig & IMinMaxFilterModel> = ({
  onChanged,
  containerClassName = 'd-flex gap-3',
  minPlaceholder = 'Min',
  maxPlaceholder = 'Max',
  clear,
  minMaxDefaultValue,
  ...props
}) => {
  const currentProps = useKrcProp({config: minMaxFilterConfig.default, props}).getCurrentProps()
  const {inputPrice, handleOnChanged} = useMinMaxFilter(
    minMaxDefaultValue ?? {min: null, max: null}
  )
  const initialRef = useRef(0)

  useEffect(() => {
    onChanged(inputPrice)
  }, [inputPrice])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    handleOnChanged(null, 'min')
    handleOnChanged(null, 'max')
  }, [clear])

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current = 1

      return
    }
  }, [])

  return (
    <>
      <div className={containerClassName}>
        <KrcNumberInput
          value={inputPrice.min}
          {...currentProps}
          placeholder={minPlaceholder}
          onChange={(value) => {
            handleOnChanged(value, 'min')
          }}
        />
        <KrcNumberInput
          value={inputPrice.max}
          {...currentProps}
          placeholder={maxPlaceholder}
          onChange={(value) => {
            handleOnChanged(value, 'max')
          }}
        />
      </div>
    </>
  )
}
