import {MinusCircleOutlined} from '@ant-design/icons'
import Title from 'antd/lib/typography/Title'
import React, {FC} from 'react'

import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {useDeleteCategoriesBadge} from '../../../hooks/categories/badges/useDeleteCategoriesBadge'
import {CategorySelection} from '../../../layout/filters/category/CategorySelection'
import {BadgeFilter} from '../../../molecules/filters/BadgeFilter'

export const DeleteCategoriesBadge: FC = () => {
  const {
    willBeDeletedBadgeId,
    clear,
    setSelectedCategoryIds,
    setWillBeDeletedBadgeId,
    deleteBadgeToCategory,
    deleteBadgeKey,
  } = useDeleteCategoriesBadge()

  return (
    <>
      <div className={'d-flex row'}>
        <div className={'col-8'}>
          <CategorySelection
            clear={clear}
            headerProps={{
              titleLevel: 5,
            }}
            textAreaProps={{
              placeholder: 'Kategori ID ile toplu arama yapılabilir. \nÖrneğin: \n11 \n13846',
            }}
            onSelectedItems={(data) => {
              setSelectedCategoryIds(
                data?.map((item) => {
                  return item.id
                }) as number[]
              )
            }}
            transferProps={{
              button: {
                title: '',
                isActive: false,
              },
            }}
          />
        </div>
        <div className={'col-4'} style={{width: 'max-content'}}>
          <div className={'d-flex flex-column mt-3'}>
            <div className={'d-flex'}>
              <Title level={5}>Badge</Title>
              <span className={'required'} />
            </div>
            <div className={'d-flex flex-row'} key={deleteBadgeKey}>
              <BadgeFilter
                value={willBeDeletedBadgeId}
                className={'select-filter-custom'}
                style={{maxWidth: 402, width: 300}}
                dropdownMatchSelectWidth={true}
                showSearch={true}
                mode={undefined}
                dataType={'value'}
                allowClear={true}
                onClear={() => {
                  setWillBeDeletedBadgeId(null)
                }}
                onSelected={(badgeId) => {
                  setWillBeDeletedBadgeId(badgeId as number)
                }}
                placeholder={'Lütfen seçiniz'}
              />
            </div>
          </div>
          <div className={'d-flex justify-content-between mt-3'}>
            <div></div>
            <KrcButton
              className={'d-flex align-items-center justify-content-center'}
              type={'primary'}
              onClick={deleteBadgeToCategory}
              icon={<MinusCircleOutlined style={{lineHeight: 0}} />}
            >
              <span>Tamamla</span>
            </KrcButton>
          </div>
        </div>
      </div>
    </>
  )
}
