import {message} from 'antd'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'

import {DrawerEnum} from '../../../enums/DrawerEnum'
import {errorMessage} from '../../../helpers/ErrorHelper'
import {fillAllNullValueInObject} from '../../../helpers/ObjectHelper'
import {createBadgeRequest, updateBadgeRequest} from '../../../requests/badges/BadgeRequest'
import {getStoresRequest} from '../../../requests/stores/StoreRequest'
import {setBadge} from '../../../store/Badge/BadgeAction'
import {
  IUseBadgeDrawer,
  badgeDrawerInitValuesType,
  badgeUrlAppDataType,
  drawerDataWithoutFormikType,
} from '../../models/badges/BadgeModel'
import {krcSelectOptions} from '../../models/components/KrcSelectModel'
import {storeDataType} from '../../models/stores/StoreModel'

let initialValues: badgeDrawerInitValuesType = {
  badge_id: 0,
  badge_type: '',
  name: '',
  panel_name: '',
  location: '',
  location_letter: '',
  priority: 0,
  amount: '',
  priceOperation: '',
  fontSize: '',
  fontColor: '',
  fontBackgroundColor: '',
  status: '',
  status_text: '',
  badge_description: '',
  badgeUrl: '',
  badge_url_app: '',
  badge_url_app_type: '',
  badge_url_app_type_id: 0,
  badgeStore: [],
  image: '',
  full_image: '',
  plp_status: '',
  pdp_status: '',
}

const schema = Yup.object({
  name: Yup.string().required('Badge Adı zorunlu alan'),
  badge_type: Yup.number()
    .min(1, 'Badge türü zorunlu alan')
    .max(5, 'Badge türü zorunlu alan')
    .required('Badge türü zorunlu alan'),
  location: Yup.string().required('Konum zorunlu alan'),
  priority: Yup.string().required('Öncelik zorunlu alan'),
  status: Yup.string().required('Durum zorunlu alan'),
  plp_status: Yup.string().required('PLP Durum zorunlu alan'),
  pdp_status: Yup.string().required('PDP Durum zorunlu alan'),
})

export const useBadgeDrawer = (payload: IUseBadgeDrawer) => {
  const dispatch = useDispatch()

  const [initValues, setInitValues] = useState<badgeDrawerInitValuesType>(initialValues)
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(true)
  const [stores, setStores] = useState<krcSelectOptions[]>()
  const [drawerDataWithoutFormik, setDrawerDataWithoutFormik] =
    useState<drawerDataWithoutFormikType>()
  const [badgeAppUrlData, setBadgeAppUrlData] = useState<badgeUrlAppDataType>()

  useEffect(() => {
    getStores()
  }, [])

  useEffect(() => {
    let inits = initialValues

    if (payload.type === DrawerEnum.UPDATE && payload?.badgeData) {
      inits = {...inits, ...payload.badgeData}
      inits.status = parseInt(inits.status) === 1 ? '1' : '2'
      let style = null

      if (payload.badgeData.style) {
        style = JSON.parse(payload.badgeData.style)
      }

      if (payload.badgeData?.percentage) {
        inits.priceOperation = payload.badgeData?.percentage.toString()
      }

      if (payload.badgeData?.price) {
        inits.amount = payload.badgeData.price.toString()
      }

      if (style !== null && Object.keys(style).length > 0) {
        inits.fontSize = style.fontSize
        inits.fontColor = style.color
        inits.fontBackgroundColor = style.backgroundColor
      }

      inits.badgeUrl = payload.badgeData.badge_url
      const storeIds = payload.badgeData.stores?.map((store) => store.store_id) ?? []
      setDrawerDataWithoutFormik((data) => ({
        ...data,
        badgeStore: storeIds,
        description: payload.badgeData?.badge_description,
      }))
      setBadgeAppUrlData({
        badge_url_app: payload.badgeData.badge_url_app,
        badge_url_app_type: payload.badgeData.badge_url_app_type,
        badge_url_app_type_id: payload.badgeData.badge_url_app_type_id,
      })
    }
    fillAllNullValueInObject(inits)

    setInitValues(inits)
  }, [payload.type, payload.badgeData])

  const onSaveHandle = () => {
    setVisibleDrawer(false)
    dispatch(setBadge(null))

    payload?.onSave && payload.onSave()
  }

  const getStores = () => {
    getStoresRequest().then((res) => {
      const _stores = res.data.data.map((store: storeDataType) => {
        return {value: store.store_id, label: store.name}
      })

      setStores(_stores)
    })
  }

  const handleOnSubmit = (values: badgeDrawerInitValuesType) => {
    if (
      values.priceOperation === '2' &&
      values.amount &&
      (parseInt(values.amount) < 0 || parseInt(values.amount) > 100)
    ) {
      message.error("Tutar işlemi yüzde seçildiğinde 100'den küçük 0'dan büyük olmalıdır.")
      return
    }

    const data = {
      name: values.name,
      badge_type: values.badge_type,
      location: values.location,
      priority: values.priority,
      price: values.amount,
      percentage: values.priceOperation,
      status: values.status,
      panel_name: values.panel_name,
      style: JSON.stringify({
        fontSize: values.fontSize || '13',
        color: values.fontColor || '#fff',
        backgroundColor: values.fontBackgroundColor || '#000',
      }),
      image: drawerDataWithoutFormik?.image,
      badge_description: drawerDataWithoutFormik?.description,
      badge_url: values.badgeUrl,
      badge_url_app: badgeAppUrlData?.badge_url_app,
      badge_url_app_type: badgeAppUrlData?.badge_url_app_type,
      badge_url_app_type_id: badgeAppUrlData?.badge_url_app_type_id,
      store_ids: drawerDataWithoutFormik?.badgeStore,
      plp_status: values.plp_status,
      pdp_status: values.pdp_status,
    }

    if (payload.type === DrawerEnum.UPDATE) {
      handleOnUpdateBadge(values, data)
    } else {
      handleOnCreateBadge(values, data)
    }
  }

  const handleOnCreateBadge = (values: badgeDrawerInitValuesType, data: object) => {
    const key = 'create-badge'
    message.loading({content: 'Badge Oluşturuluyor', key, duration: 1})

    createBadgeRequest(data)
      .then((res) => {
        message.success({content: 'Badge Oluşturuldu', key, duration: 2})
        onSaveHandle()
      })
      .catch((error) => {
        errorMessage(error)
        message.destroy(key)
      })
  }

  const handleOnUpdateBadge = (values: badgeDrawerInitValuesType, data: object) => {
    const key = 'updatable'
    message.loading({content: 'Badge Güncelleniyor', key, duration: 1})

    updateBadgeRequest(values.badge_id, data)
      .then((res) => {
        message.success({content: 'Badge Güncellendi', key, duration: 2})
        onSaveHandle()
      })
      .catch((error) => {
        errorMessage(error)
        message.destroy(key)
      })
  }

  return {
    initValues,
    setInitValues,
    visibleDrawer,
    setVisibleDrawer,
    schema,
    handleOnSubmit,
    stores,
    drawerDataWithoutFormik,
    setDrawerDataWithoutFormik,
    badgeAppUrlData,
    setBadgeAppUrlData,
    initialValues,
  }
}
