import React, {FC, createContext, useState} from 'react'

import {categoryBadgeLogFilterType} from '../../../components/models/categories/CategoryBadgeModel'
import {productBadgeLogFilterType} from '../../../components/models/products/ProductModel'

interface ICategoryBadgeContext {
  activityFilter: productBadgeLogFilterType
  setActivityFilter: any
}

export const CategoryBadgeContext = createContext<ICategoryBadgeContext>({
  activityFilter: {},
  setActivityFilter: () => {},
})

export const CategoryBadgeProvider: FC = ({children}) => {
  const [activityFilter, setActivityFilter] = useState<categoryBadgeLogFilterType>({})

  return (
    <>
      <CategoryBadgeContext.Provider
        value={{
          activityFilter,
          setActivityFilter,
        }}
      >
        {children}
      </CategoryBadgeContext.Provider>
    </>
  )
}
