import {PictureOutlined} from '@ant-design/icons'
import {Avatar, Divider, Drawer, Image, Space} from 'antd'
import {Field, Form, Formik, FormikProps} from 'formik'
import moment, {Moment} from 'moment'
import React, {FC, useEffect, useRef, useState} from 'react'

import {DrawerEnum} from '../../../enums/DrawerEnum'
import {CategoryBannerPageTypeEnum} from '../../../enums/category/banner/CategoryBannerPageTypeEnum'
import {imagePath, uploadPath} from '../../../providers/category/detail/CategoryBannerProvider'
import {FileManagerModal} from '../../../service/FileManager/FileManagerModal'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {KrcRangePicker} from '../../atoms/forms/KrcRangePicker'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useCategoryBannerDrawer} from '../../hooks/categories/banners/useCategoryBannerDrawer'
import {ICategoryBannerDrawer} from '../../models/categories/CategoryBannerModel'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'

export const CategoryBannerDrawer: FC<ICategoryBannerDrawer> = ({
  onHide,
  onSave,
  type,
  bannerData,
  visible = false,
}) => {
  const {
    stores,
    initValues,
    visibleDrawer,
    schema,
    handleOnSubmit,
    handleLink,
    handleDates,
    setInitValues,
    setDrawerDataWithoutFormik,
    initialValues,
    setVisibleDrawer,
  } = useCategoryBannerDrawer({
    type,
    bannerData,
    onSave,
  })

  const [selectedStores, setSelectedStores] = useState<number[]>([])
  const formikRef = useRef<FormikProps<any>>(null)
  const submitRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (!visibleDrawer) {
      onClose()
    }
  }, [visibleDrawer])

  useEffect(() => {
    if (!visible) {
      setInitValues(initialValues)
      setDrawerDataWithoutFormik(undefined)
      setVisibleDrawer(true)
    }
  }, [visible])

  useEffect(() => {
    if (initValues?.stores) {
      setSelectedStores(initValues.stores)
    }
  }, [initValues])

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.values.stores = selectedStores
    }
  }, [selectedStores])

  const onClose = () => {
    onHide && onHide()
  }

  const validateAndGetRangeDates = (start: any, end: any) => {
    if (start && end && moment(end).isAfter(start)) {
      return [moment(start), moment(end)] as [Moment, Moment]
    }
    return null
  }

  return (
    <>
      <Drawer
        title={type === DrawerEnum.CREATE ? 'Banner Ekle' : 'Banner Güncelle'}
        className='category-banner-drawer'
        placement='right'
        size={'large'}
        onClose={onClose}
        keyboard={false}
        maskClosable={false}
        visible={visible}
        extra={
          <Space>
            <KrcButton type={'default'} size={'large'} onClick={onHide}>
              İptal
            </KrcButton>
            <KrcButton
              type={'primary'}
              size={'large'}
              onClick={() => {
                submitRef.current?.click()
              }}
              style={{marginLeft: 10}}
            >
              Kaydet
            </KrcButton>
          </Space>
        }
      >
        <Formik
          innerRef={formikRef}
          validationSchema={schema}
          initialValues={initValues}
          onSubmit={(values, actions) => {
            handleOnSubmit(values)
            onClose()
          }}
          enableReinitialize
        >
          {({setFieldValue}) => (
            <Form className='form row' noValidate>
              <KrcFormikField
                className={'mb-5 col-md-12'}
                type={'number'}
                name={'stores'}
                labelTitle={'Mağazalar'}
              >
                <KrcSelect
                  mode={'multiple'}
                  placeholder={'Mağaza Seçiniz'}
                  size={'large'}
                  options={stores}
                  maxTagCount={'responsive'}
                  style={{width: '100%'}}
                  className={'ant-selector-border'}
                  showArrow={true}
                  defaultValue={selectedStores}
                  onChange={(data) => {
                    setSelectedStores(data)
                  }}
                />
              </KrcFormikField>

              <KrcFormikField
                className={'mb-5 col-md-6'}
                name={'title'}
                labelTitle={'Başlık / Açıklama'}
              >
                <Field type='text' name='title' className='form-control' autoComplete='off' />
              </KrcFormikField>

              <KrcFormikField
                className={'mb-5 col-md-6'}
                name={'link'}
                labelTitle={'Bağlantı'}
                required
              >
                <Field
                  type='text'
                  name='link'
                  className='form-control'
                  autoComplete='off'
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (formikRef.current) {
                      formikRef.current.values.stores = selectedStores

                      setFieldValue('link', e.target.value)
                      handleLink(formikRef?.current?.values, e.target.value)
                    }
                  }}
                />
              </KrcFormikField>

              <KrcFormikField className={'mb-5 col-md-6'} name={'image'} labelTitle={'Görsel'}>
                <div className={'d-flex flex-row justify-content-center gap-6 align-items-center'}>
                  <div className={'d-flex justify-content-center mb-2 me-2'}>
                    {!initValues?.image || type === DrawerEnum.CREATE ? (
                      <div className={'d-flex flex-column align-items-center'}>
                        <Avatar
                          size={50}
                          icon={<PictureOutlined />}
                          style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        />
                      </div>
                    ) : (
                      initValues.image && (
                        <Image
                          src={imagePath + initValues.image}
                          className={'rounded-circle'}
                          preview={{
                            maskClassName: 'rounded-circle',
                          }}
                          width={50}
                          height={50}
                        />
                      )
                    )}
                  </div>
                  {visible && (
                    <FileManagerModal
                      onSelected={(link) => {
                        setDrawerDataWithoutFormik((data) => ({...data, image: link}))
                      }}
                      defaultImage={initValues?.image ? imagePath + initValues.image : ''}
                      tempUploadDir={uploadPath}
                    />
                  )}
                </div>
              </KrcFormikField>

              <KrcFormikField
                className={'mb-5 col-md-6'}
                name={'mobile_image'}
                labelTitle={'Mobil Görsel'}
              >
                <div className={'d-flex flex-row justify-content-center gap-6 align-items-center'}>
                  <div className={'d-flex justify-content-center mb-2 me-2'}>
                    {!initValues?.mobile_image || type === DrawerEnum.CREATE ? (
                      <div className={'d-flex flex-column align-items-center'}>
                        <Avatar
                          size={50}
                          icon={<PictureOutlined />}
                          style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        />
                      </div>
                    ) : (
                      initValues.mobile_image && (
                        <Image
                          src={imagePath + initValues.mobile_image}
                          className={'rounded-circle'}
                          preview={{
                            maskClassName: 'rounded-circle',
                          }}
                          width={50}
                          height={50}
                        />
                      )
                    )}
                  </div>
                  {visible && (
                    <FileManagerModal
                      onSelected={(link) => {
                        setDrawerDataWithoutFormik((data) => ({...data, mobile_image: link}))
                      }}
                      defaultImage={
                        initValues?.mobile_image ? imagePath + initValues.mobile_image : ''
                      }
                      tempUploadDir={uploadPath}
                    />
                  )}
                </div>
              </KrcFormikField>

              <KrcFormikField
                className={'mb-5 col-md-4'}
                type={'number'}
                name={'type_id'}
                labelTitle={'ID'}
              />

              <KrcFormikField className={'mb-5 col-md-4'} name={'type'} labelTitle={'Grup'}>
                <Field as='select' name='type' className='form-select'>
                  <option>Seçiniz</option>
                  {Object.entries(CategoryBannerPageTypeEnum).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Field>
              </KrcFormikField>

              <KrcFormikField
                className={'mb-5 col-md-4'}
                type={'number'}
                name={'sort_order'}
                labelTitle={'Sıra'}
              />

              <KrcFormikField className='mb-5 col-md-12' name={'date'} labelTitle={'Tarih'}>
                <KrcRangePicker
                  className={'rounded w-100'}
                  allowClear={false}
                  defaultValue={validateAndGetRangeDates(
                    initValues?.start_time,
                    initValues?.end_time
                  )}
                  enableReinitialize
                  format={'YYYY-MM-DD HH:mm'}
                  showTime
                  onCalendarChange={(data) => {
                    handleDates(formikRef?.current?.values, data)
                  }}
                />
              </KrcFormikField>

              <button type='submit' ref={submitRef} className='d-none' />
            </Form>
          )}
        </Formik>
        <Divider />
        <div>
          <div>Notlar:</div>
          <div>
            (<span className={'fw-bold text-danger'}>*</span>) Kırmızı alanlar zorunludur.
          </div>
        </div>
      </Drawer>
    </>
  )
}
