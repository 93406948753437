import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'
import {createReviewType} from '../../components/models/reviews/ReviewModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const ROLE_URL = `${API_URL}/reviews`

export function getReviewsRequest(filter: any, {page, limit}: paginationMetaType) {
  return axios.get(`${ROLE_URL}`, {
    params: {
      page,
      limit,
      ...filter,
    },
  })
}

export function createReviewsRequest(data: createReviewType) {
  return axios.post(`${ROLE_URL}`, data)
}

export function updateReviewRequest(reviewId: number, data: createReviewType) {
  return axios.put(`${ROLE_URL}/${reviewId}`, data)
}

export function updateStatusReviewsRequest(reviewIds: number[], status: 1 | 2) {
  return axios.put(`${ROLE_URL}/update-status`, {
    review_ids: reviewIds,
    status,
  })
}

export function deleteReviewsRequest(reviewIds: number[]) {
  return axios.delete(`${ROLE_URL}/multiple-delete`, {
    data: {review_ids: reviewIds},
  })
}
