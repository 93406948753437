import {Drawer, Space} from 'antd'
import {Field, Form, Formik} from 'formik'
import React, {FC, useRef} from 'react'

import {KrcButton} from '../../atoms/buttons/KrcButton'
import {
  ICategoryBannerDescriptionDrawerProps,
  useCategoryBannerDescriptionDrawer,
} from '../../hooks/categories/banners/useCategoryBannerDescriptionDrawer'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'

const CategoryBannerDescriptionDrawer: FC<ICategoryBannerDescriptionDrawerProps> = ({
  visible,
  onClose,
  onSave,
}) => {
  const submitRef = useRef<HTMLButtonElement | null>(null)
  const {categoryDescription, handleOnSubmit, validationSchema} =
    useCategoryBannerDescriptionDrawer()

  return (
    <Drawer
      title='Açıklama ve Durum'
      placement='right'
      size='large'
      onClose={onClose}
      visible={visible}
      extra={
        <Space>
          <KrcButton type={'default'} size={'large'} onClick={onClose}>
            İptal
          </KrcButton>
          <KrcButton
            type={'primary'}
            size={'large'}
            onClick={() => {
              submitRef.current?.click()
            }}
            style={{marginLeft: 10}}
          >
            Kaydet
          </KrcButton>
        </Space>
      }
    >
      <Formik
        initialValues={categoryDescription}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleOnSubmit(values)
          onClose()
        }}
      >
        {() => (
          <Form>
            <KrcFormikField className='mb-5 col-12' name={'description'} labelTitle={'Açıklama'}>
              <Field type='text' name='description' className='form-control' autoComplete='off' />
            </KrcFormikField>

            <KrcFormikField className='mb-5 col-12' name={'status'} labelTitle={'Durum'} required>
              <Field as='select' name='status' className='form-select'>
                <option value=''>Seçiniz</option>
                <option value='1'>Aktif</option>
                <option value='0'>Pasif</option>
              </Field>
            </KrcFormikField>

            <button type='submit' ref={submitRef} className='d-none' />
          </Form>
        )}
      </Formik>
    </Drawer>
  )
}

export default CategoryBannerDescriptionDrawer
