import {EditOutlined} from '@ant-design/icons'
import {Empty, Image} from 'antd'
import {Form, Formik} from 'formik'
import React, {FC, useContext, useState} from 'react'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'

import {EMPTY_STRING} from '../../../../enums/RichTextEditorEnum'
import {BannerImageManagerContext} from '../../../../providers/images/BannerImageManagerProvider'
import {FileManagerModal} from '../../../../service/FileManager/FileManagerModal'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcRangePicker} from '../../../atoms/forms/KrcRangePicker'
import {KrcRichTextEditorWithTable} from '../../../atoms/forms/KrcRichTextEditorWithTable'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {krcSelectOptions} from '../../../models/components/KrcSelectModel'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

const schema = Yup.object({
  title: Yup.string().required('Image Adı Zorunlu'),
  content: Yup.string(),
  web_link: Yup.string(),
  mobile_link_type: Yup.object().nullable(),
  mobile_link_id: Yup.number().nullable(),
  deeplink: Yup.string(),
  component_banner: Yup.object().nullable(),
  date: Yup.object().nullable(),
  time: Yup.object().nullable(),
  column: Yup.number().nullable(),
})

export const BannerImageManager: FC = () => {
  const {bannerImage, bannerImageTypes, updateBannerImage, handleOnSubmit} =
    useContext(BannerImageManagerContext)
  const [openWebImage, setOpenWebImage] = useState<number>(0)
  const [openMobileImage, setOpenMobileImage] = useState<number>(0)
  const {bannerId, imageId} = useParams()

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={bannerImage}
        onSubmit={handleOnSubmit}
        validate={updateBannerImage}
        validateOnMount={true}
      >
        {(formik) => {
          return (
            <Form className='form row' noValidate>
              <div className={'d-flex gap-5'}>
                <div className={'d-flex flex-column col-md-5 gap-4 w-530px'}>
                  <KrcFormikField
                    className={'col-md-10 w-500px'}
                    name={'title'}
                    labelTitle={'Image Adı'}
                    required={true}
                  />
                  <KrcFormikField
                    className={'col-md-10 w-500px h-300px'}
                    name={'content'}
                    labelTitle={'Image Açıklama'}
                    required={false}
                  >
                    <KrcRichTextEditorWithTable
                      value={bannerImage.content}
                      height={250}
                      onChange={(content) => {
                        if (content === EMPTY_STRING) {
                          content = ''
                        }
                        if (!content) {
                          content = ''
                        }
                        updateBannerImage({content})
                      }}
                    />
                  </KrcFormikField>
                  <KrcFormikField
                    className={'col-md-10 w-500px'}
                    name={'web_link'}
                    labelTitle={'Web Link'}
                    placeholder={'WEB LINK'}
                    tooltipProps={{
                      status: true,
                      text: 'URL eğer girilmezse, image adı kullanılır.',
                    }}
                  />
                  <KrcFormikField
                    className={'w-500px'}
                    name={'mobile_link_type'}
                    labelTitle={'Link Type'}
                  >
                    <div className={'d-flex justify-content-between'}>
                      <KrcSelect
                        value={bannerImage.mobile_link_type}
                        className={'ant-selector-border w-300px'}
                        placeholder={'Link Type'}
                        options={bannerImageTypes}
                        showArrow={true}
                        labelInValue={true}
                        size={'large'}
                        onChange={(mobileLinkType: krcSelectOptions) => {
                          updateBannerImage({
                            mobile_link_type: {
                              value: mobileLinkType.value,
                              label: mobileLinkType.label,
                            },
                          })
                        }}
                      />

                      <KrcFormikField
                        type={'number'}
                        className={'w-175px'}
                        name={'mobile_link_id'}
                        placeholder={'Link ID'}
                        autoComplete='off'
                      />
                    </div>
                  </KrcFormikField>
                  <KrcFormikField
                    type={'number'}
                    className={'col-md-10 w-200px'}
                    name={'column'}
                    labelTitle={'Sütun'}
                  />

                  {/*<KrcFormikField className={'mt-3'} name={'image_type'}>*/}
                  {/*  <div className={'d-flex gap-10'}>*/}
                  {/*    <div className={' d-flex align-items-center'}>*/}
                  {/*      <Field*/}
                  {/*        className='form-label mr-2 w-18px h-18px'*/}
                  {/*        type='radio'*/}
                  {/*        name='image_type'*/}
                  {/*        value={'dynamic'}*/}
                  {/*        checked={true}*/}
                  {/*      />*/}
                  {/*      <span className={'lh-0'}>Dinamik Banner</span>*/}
                  {/*    </div>*/}
                  {/*    <div className={'d-flex align-items-center'}>*/}
                  {/*      <Field*/}
                  {/*        className='form-label mr-2 w-18px h-18px'*/}
                  {/*        type='radio'*/}
                  {/*        name='image_type'*/}
                  {/*        value={'standard'}*/}
                  {/*        checked={true}*/}
                  {/*      />*/}
                  {/*      <span className={'lh-0'}>Standart Banner</span>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</KrcFormikField>*/}
                  <KrcFormikField
                    className={'col-md-10 w-450px'}
                    name={'deeplink'}
                    labelTitle={'Deeplink'}
                  />

                  {/*<div className={'d-flex align-items-end gap-3 w-450px mt-2'}>*/}
                  {/*  <KrcFormikField*/}
                  {/*    className={'w-250px'}*/}
                  {/*    name={'component_banner_group'}*/}
                  {/*    labelTitle={'Component Banners Grup'}*/}
                  {/*  >*/}
                  {/*    <KrcSelect*/}
                  {/*      className={'ant-selector-border w-250px'}*/}
                  {/*      placeholder={'Component Banners Grup'}*/}
                  {/*      options={[*/}
                  {/*        {*/}
                  {/*          value: 'text',*/}
                  {/*          label: 'Text',*/}
                  {/*        },*/}
                  {/*      ]}*/}
                  {/*      showArrow={true}*/}
                  {/*      onChange={(componentBannerGroup) => {*/}
                  {/*        updateBannerImage({component_banner_group: {value: componentBannerGroup.value, label: componentBannerGroup.label}})*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*  </KrcFormikField>*/}
                  {/*  <KrcButton type={'default'}>Yeni Ekle</KrcButton>*/}
                  {/*</div>*/}

                  <div className={'d-flex gap-4 w-450px'}>
                    <KrcFormikField
                      className={'w-350px'}
                      name={'date'}
                      required={false}
                      labelTitle={'Tarih'}
                    >
                      <KrcRangePicker
                        className={'rounded w-100'}
                        allowClear={false}
                        defaultValue={[bannerImage.start_time, bannerImage.end_time]}
                        enableReinitialize
                        format={'YYYY-MM-DD HH:mm'}
                        showTime
                        onSelected={(date) => {
                          if (!date) {
                            return
                          }

                          updateBannerImage({start_time: date[0], end_time: date[1]})
                        }}
                      />
                    </KrcFormikField>
                  </div>

                  {/*<KrcFormikField*/}
                  {/*  className={'mb-2 col-md-2'}*/}
                  {/*  name={'status'}*/}
                  {/*  labelTitle={'Durum'}*/}
                  {/*  required={true}*/}
                  {/*>*/}
                  {/*  <Field*/}
                  {/*    as='select'*/}
                  {/*    name='status'*/}
                  {/*    className='form-select'*/}
                  {/*    aria-label='Select example'*/}
                  {/*  >*/}
                  {/*    <option value=''>Seçiniz</option>*/}
                  {/*    <option value='1'>Aktif</option>*/}
                  {/*    <option value='0'>Pasif</option>*/}
                  {/*  </Field>*/}
                  {/*</KrcFormikField>*/}
                </div>
                <div className={'d-flex flex-column gap-5'}>
                  <div
                    className={'d-flex flex-column gap-5 p-6 rounded w-500px'}
                    style={{backgroundColor: '#F3F3F3'}}
                  >
                    <div className={'d-flex justify-content-between align-items-center'}>
                      <span className={'lh-0'}>
                        <b>Web Image</b>
                      </span>
                      <FileManagerModal
                        tempUploadDir={`/image/banners/${bannerId}/visual/${imageId}`}
                        onSelected={(link, fullUrl) => {
                          updateBannerImage({web_image: link})
                          updateBannerImage({full_web_image: fullUrl})
                        }}
                        defaultImage={bannerImage?.full_web_image ?? ''}
                        open={openWebImage}
                        showImage={false}
                      >
                        <KrcButton
                          type={'text'}
                          className={'custom-hover'}
                          icon={<EditOutlined />}
                          onClick={() => {
                            setOpenWebImage((open) => open + 1)
                          }}
                        />
                      </FileManagerModal>
                    </div>
                    {bannerImage?.full_web_image ? (
                      <Image width={450} height={200} src={bannerImage?.full_web_image} />
                    ) : (
                      <>
                        <Empty
                          image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                          imageStyle={{
                            height: 60,
                          }}
                          description={<span>Web görseli bulunamadı.</span>}
                        />
                      </>
                    )}
                  </div>
                  <div
                    className={'d-flex flex-column gap-5 p-6 rounded w-500px'}
                    style={{backgroundColor: '#F3F3F3'}}
                  >
                    <div className={'d-flex justify-content-between align-items-center'}>
                      <span className={'lh-0'}>
                        <b>Mobile Image</b>
                      </span>
                      <FileManagerModal
                        tempUploadDir={`/image/banners/${bannerId}/visual/${imageId}`}
                        onSelected={(link, fullUrl) => {
                          updateBannerImage({mobile_image: link})
                          updateBannerImage({full_mobile_image: fullUrl})
                        }}
                        defaultImage={bannerImage?.full_mobile_image ?? ''}
                        open={openMobileImage}
                        showImage={false}
                      >
                        <KrcButton
                          type={'text'}
                          className={'custom-hover'}
                          icon={<EditOutlined />}
                          onClick={() => {
                            setOpenMobileImage((open) => open + 1)
                          }}
                        />
                      </FileManagerModal>
                    </div>
                    {bannerImage?.full_mobile_image ? (
                      <Image width={450} height={200} src={bannerImage?.full_mobile_image} />
                    ) : (
                      <>
                        <Empty
                          image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                          imageStyle={{
                            height: 60,
                          }}
                          description={<span>Mobil görseli bulunamadı.</span>}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={'d-flex justify-content-end gap-5 col-md-9 mb-5 mt-10'}>
                <KrcButton
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                  type={'text'}
                  size={'large'}
                  className={'custom-hover w-200px text-white button-bg-purple'}
                >
                  Kaydet
                </KrcButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
