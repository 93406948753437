import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {
  checkIBMCampaignSpecialGroupExportRequest,
  exportIBMCampaignSpecialGroupRequest,
} from '../../../requests/ibm/IBMCampaignSpecialGroupRequest'

export const useIBMCampaignSpecialGroupExport = () => {
  const navigate = useNavigate()
  const [downloadExcelUrl, setDownloadExcelUrl] = useState<any>(null)
  const urlParams = window.location.search.substring(1)
  const ibmCampaignId = urlParams.split('campaign_id=')[1].split('&')[0]

  const exportIBMCampaignSpecialGroups = () => {
    exportIBMCampaignSpecialGroupRequest(Number(ibmCampaignId))
      .then((res) => {
        if (!res.data?.success) {
          return
        }
        checkIBMCampaignSpecialGroupExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  const checkIBMCampaignSpecialGroupExport = (filename: string) => {
    checkIBMCampaignSpecialGroupExportRequest(Number(ibmCampaignId), filename).then((response) => {
      if (!response.data.data.is_exists) {
        setTimeout(() => {
          checkIBMCampaignSpecialGroupExport(filename)
        }, 1000)
        return
      }
      setDownloadExcelUrl(response.data.data.download_excel_url)
    })
  }

  return {
    exportIBMCampaignSpecialGroups,
    downloadExcelUrl,
  }
}
