import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import React, {FC, useContext} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

import {PageTitle} from '../../../../_metronic/layout/core'
import {DrawerEnum} from '../../../enums/DrawerEnum'
import {BadgeContext, BadgeProvider} from '../../../providers/BadgeProvider'
import {getBadgeExportFrontendUrl} from '../../../requests/badges/BadgeExportRequest'
import {multiDeleteBadgeRequest} from '../../../requests/badges/BadgeRequest'
import {deleteBadge} from '../../../store/Badge/BadgeAction'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {IBadge} from '../../models/badges/BadgeModel'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {BadgeList} from './BadgeList'

const {confirm} = Modal

const BadgeToolbarRightField: FC = () => {
  const {updateVisibleDrawer, updateDrawerType, badgeFilter} = useContext(BadgeContext)
  const {selectedBadges} = useSelector((state: any) => state.badge)
  const dispatch = useDispatch()

  const deleteBadges = () => {
    confirm({
      title: `${selectedBadges.length} badge silenecek.`,
      icon: <ExclamationCircleOutlined />,
      content: 'Silme işlemi geri alınamaz emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        const badgeIds: number[] = selectedBadges.map((badge: IBadge) => badge.badge_id)

        multiDeleteBadgeRequest(badgeIds).then(() => {
          badgeIds.forEach((badgeId: number) => dispatch(deleteBadge(badgeId)))
        })
      },
    })
  }

  return (
    <>
      <div className='d-flex align-items-center py-1'>
        <div className='me-4'>
          <a
            type={'button'}
            className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
            href={getBadgeExportFrontendUrl({
              filters: badgeFilter,
              badgeIds: selectedBadges.map((badge: IBadge) => badge.badge_id) ?? [],
            })}
            target={'_blank'}
            rel='noreferrer'
          >
            Dışarı Aktar
          </a>
        </div>
        <div className='me-4'>
          <KrcButton
            type={'default'}
            onClick={(e) => {
              e.preventDefault()
              deleteBadges()
            }}
          >
            Seçilenleri Sil
          </KrcButton>
        </div>
        <KrcButton
          size={'middle'}
          onClick={() => {
            updateDrawerType(DrawerEnum.CREATE)
            updateVisibleDrawer(true)
          }}
          id='kt_toolbar_primary_button'
        >
          {'Badge Oluştur'}
        </KrcButton>
      </div>
    </>
  )
}

const BadgeListWrapperWithProvider: FC = () => {
  const intl = useIntl()

  return (
    <>
      <Toolbar rightField={<BadgeToolbarRightField />}></Toolbar>
      <PageTitle>{intl.formatMessage({id: 'MENU.BADGE'})}</PageTitle>
      <BadgeList />
    </>
  )
}

export const BadgeListWrapper: FC = () => {
  return (
    <>
      <BadgeProvider>
        <BadgeListWrapperWithProvider />
      </BadgeProvider>
    </>
  )
}
