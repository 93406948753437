export const arrayUnique = (array: any[]) => {
  let a = array.concat()
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1)
    }
  }

  return a
}

export const getAddItemToArray = (
  currentArray: any[],
  array: any[],
  isUnique = false,
  currentArrayUniqueId = 'id',
  arrayUniqueId = 'id'
) => {
  if (isUnique) {
    array = array.filter((arrayItem) => {
      return !currentArray.some(
        (currentArrayItem) => currentArrayItem[currentArrayUniqueId] === arrayItem[arrayUniqueId]
      )
    })
  }

  return [...currentArray, ...array]
}

/*
birinci parametrede array alıyoruz ve array içerisinde string, integer tipinde veriler var
ve bunun içindeki bazı veri kümsenin sırasını önceliklendirmek istiyoruz.
Hangi veri kümsenin önceliklendirileceğini de ikinci parametrede alıyoruz.
İkinci parametre de array olacak. birebir birinci parametreyle aynı özelliklere sahip olacak.
ikinci parametrede array içerisindeki veriler, birinci parametretdekiler verilerde birebir match arayarak
ikinci parametrenin index sıralamasına göre birebir aynı şekilde önceliklendirerek
birinci parametrenin sıralamasını güncelleyecek ve return edecek.

Test için aşağıdaki iki değişkeni tarayıcı konsolunda tanımlayıp functionu çalıştırabilirsiniz.

var orderedArray = ["153.03.08.2731", "180.01.01.0040", "153.03.06.6206" , "700.01.01.0272", "153.01.06.5182"]
var array = ["153.03.06.6206" , "500.01.21.0034",  "180.01.01.0040", "153.01.06.5182", "500.01.21.0035", "700.01.01.0272", "153.03.08.2731"]
 */
export function prioritizeArrayItemsByAnotherArrayItemsOrdered(
  array: Array<any>,
  orderedArray: Array<any>,
  orderedArrayFindIndexCallback: (item: any, searchData: any) => boolean = (item, searchData) =>
    item === searchData
) {
  if (!orderedArray) {
    return array
  }

  return array.sort(function (a, b) {
    let indexA = orderedArray.findIndex((item: any) => orderedArrayFindIndexCallback(item, a))
    let indexB = orderedArray.findIndex((item: any) => orderedArrayFindIndexCallback(item, b))

    let returnData = 0

    if (indexA === -1) {
      return 1
    }

    if (indexB === -1) {
      return -1
    }

    if (indexA === -1 && indexB === -1) {
      return 0
    }

    if (indexA < indexB) {
      return -1
    }

    if (indexA > indexB) {
      return 1
    }

    return returnData
  })
}

export function getProperty(properties: any[], key: string) {
  return properties.find((item) => item.key === key)?.value
}
