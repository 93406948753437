import React, {FC, useContext} from 'react'

import {CategoryProductSortingContext} from '../../../../../providers/category/sorting/CategoryProductSortingProvider'
import {CategoryTypeEnum} from '../../../../models/categories/CategoryModel'
import {KrcTabs} from '../../../../molecules/KrcTabs'
import {CategoryProductEditorsChoiceSorting} from './CategoryProductEditorsChoiceSorting'
import {CategoryProductSuggestedSorting} from './CategoryProductSuggestedSorting'

export const CategoryProductSorting: FC = () => {
  return (
    <>
      <CategorySortingTabs />
    </>
  )
}

const CategorySortingTabs: FC = () => {
  const {category} = useContext(CategoryProductSortingContext)

  return (
    <KrcTabs
      isActiveKey={false}
      tabsClassName={'mb-1'}
      tabsProps={{defaultActiveKey: 'suggested-sorting'}}
      tabProps={[
        {
          eventKey: 'editors-choice',
          title: 'Editörün Seçimi',
          disabled: category?.category_type === CategoryTypeEnum.GHOST,
          children: <CategoryProductEditorsChoiceSorting />,
        },
        {
          eventKey: 'suggested-sorting',
          title: 'Önerilen Sıralama',
          children: <CategoryProductSuggestedSorting />,
        },
      ]}
    />
  )
}
