import {ChangeEvent, useState} from 'react'

import {
  IUseInputConstraintFilter,
  InputConstraintDataType,
} from '../../models/components/InputConstraintModel'

export const useInputConstraintFilter = ({
  placeholderList,
  defaultPlaceholder = 'Giriniz',
}: IUseInputConstraintFilter) => {
  const [data, setData] = useState<InputConstraintDataType>({
    key: 'model',
    value: null,
  })
  const [placeholder, setPlaceholder] = useState<string>(defaultPlaceholder)

  const handleSelectOnChange = (value: string) => {
    if (placeholderList) {
      // @ts-ignore
      setPlaceholder(placeholderList[value] ?? defaultPlaceholder)
    }

    setData((data) => ({...data, key: value}))
  }

  const handleInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData((data) => ({...data, value: e.target.value}))
  }

  return {
    handleSelectOnChange,
    handleInputOnChange,
    data,
    setData,
    placeholder,
  }
}
