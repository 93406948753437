import {SelectProps} from 'antd'
import {FC, useEffect} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useBadgeTypeFilter} from '../../hooks/badges/useBadgeTypeFilter'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {IBadgeTypeFilter} from '../../models/badges/BadgeTypeFilterModel'

const badgeTypeFilterConfig = {
  default: {
    mode: 'multiple',
    style: {width: '100%'},
    placeholder: 'Badge Tipi Filtre Listesi',
    size: 'middle',
  },
}

export const BadgeTypeFilter: FC<IBadgeTypeFilter & SelectProps> = ({onSelected, ...props}) => {
  const currentProps = useKrcProp({
    config: badgeTypeFilterConfig.default,
    props,
  }).getCurrentProps()
  const {badgeTypeIds, options, handleChange} = useBadgeTypeFilter()

  useEffect(() => {
    if (!onSelected) {
      return
    }

    onSelected(badgeTypeIds)
  }, [badgeTypeIds])

  return (
    <>
      <KrcSelect onChange={handleChange} {...currentProps} options={options} />
    </>
  )
}
