import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Alert, Modal, message} from 'antd'
import cn from 'classnames'
import _ from 'lodash'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'

import {ProductNewPriceContext} from '../../../../providers/ProductNewPriceProvider'
import {
  getProductPriceWillBeUpdatedRequest,
  updateProductPriceWillBeUpdatedRequest,
} from '../../../../requests/products/new_price/ProductNewPriceRequest'
import {paginationMetaType} from '../../../models/GeneralModel'
import {minimalProductType} from '../../../models/products/MinimalProductModel'
import {tableFilterSearchParamsType} from '../../../models/products/passives/PassiveProductModel'
import {Loader} from '../../../molecules/Loader'
import {ProductTextAreaFilter} from '../../../molecules/filters/ProductTextAreaFilter'
import {TableHeader} from '../../../molecules/headers/TableHeader'
import {ReceivingTable} from '../../tables/ReceivingTable'

const {confirm} = Modal

export const ProductsNewPriceTable: FC<any> = ({transferData, onDelete, save}) => {
  const [visibleTextFilter, setVisibleTextFilter] = useState<boolean>(false)
  const [meta, setMeta] = useState<paginationMetaType>({limit: 10, page: 1})
  const [data, setData] = useState<any[]>([])
  const [selectedProducts, setSelectedProducts] = useState<any>([])
  const [unSelectAllItems, setUnSelectAllItems] = useState<number>(0)
  const [willBeDeletedProductIds, setWillBeDeletedProductIds] = useState<number[]>([])
  const [willBeAddedProducts, setWillBeAddedProducts] = useState<any[]>([])
  const fetchRef = React.useRef(0)
  const {updateApiQueue, apiQueue, willBeUpdatedQueue} = useContext(ProductNewPriceContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [additionalInfo, setAdditionalInfo] = useState<any>({})

  const [filterSearchData, setFilterSearchData] = useState<tableFilterSearchParamsType>({
    id: undefined,
    ids: undefined,
    name: undefined,
    model: undefined,
    models: undefined,
  })

  const initialRef = useRef(0)

  useEffect(() => {
    if (loading) {
      return
    }

    if (meta.page > 1) {
      return setMeta((data) => ({...data, page: 1}))
    }

    getProductPriceWillBeUpdated()
  }, [filterSearchData])

  useEffect(() => {
    const _transferData = transferData.map((data: any) => {
      return {
        id: data.id,
        model: data.model,
        name: data.name,
        price: data.price,
        priceKmiy: data.price_kmiy,
        specialPrice: data.special_price,
        cartPrice: data.price_cart_discount,
      }
    })

    addWillBeAddedProducts(_transferData)
  }, [transferData])

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        manualWidth: 200,
      },
      {
        Header: 'Model',
        accessor: 'model',
        manualWidth: 250,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
        manualWidth: 350,
      },
      {
        Header: 'Fiyat',
        maxWidth: 100,
        manualWidth: 100,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                <div className={'mb-1'}>{row.original.priceKmiy} ₺</div>
                <span className={'text-gray-400 fs-7'}>{row.original.price} ₺</span>
              </div>
            </div>
          )
        },
      },
      {
        Header: 'İndirim',
        maxWidth: 100,
        manualWidth: 100,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <>
                {row.original.specialPrice ? (
                  <div className={'mb-1'}>{row.original.specialPrice} ₺</div>
                ) : (
                  <>-</>
                )}
              </>
            </div>
          )
        },
      },
      {
        Header: 'Sepet İndirim',
        maxWidth: 100,
        manualWidth: 100,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              {row.original.cartPrice ? (
                <>
                  <div className={'mb-1'}>{row.original.cartPrice} ₺</div>
                </>
              ) : (
                <>-</>
              )}
            </div>
          )
        },
      },
    ]
  }, [])

  const setSelectedItems = (data: any) => {
    setSelectedProducts(data)
  }

  const getProductPriceWillBeUpdated = () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    setLoading(true)

    getProductPriceWillBeUpdatedRequest(
      apiQueue?.id ?? 0,
      filterSearchData,
      meta.page,
      meta.limit
    ).then((res) => {
      if (fetchId !== fetchRef.current) {
        return
      }

      let products = res.data.data.map((product: minimalProductType) => {
        return {
          id: product.product_id,
          model: product.model,
          name: product.details?.name,
          price: product.prices?.price,
          priceKmiy: product.prices?.price_kmiy,
          specialPrice: product.prices?.special_price,
          cartPrice: product.prices?.price_cart_discount,
        }
      })

      const meta = res.data.meta
      const _additionalInfo = res.data.additional

      if (willBeDeletedProductIds.length > 0) {
        products = products.filter((product: any) => {
          return !willBeDeletedProductIds.some((productId) => productId === product.id)
        })
      }

      if (willBeAddedProducts.length > 0 && res.data.meta.current_page === 1) {
        products = [...products, ...willBeAddedProducts]
      }

      setData(products)
      setMeta({...meta, limit: meta.per_page, page: meta.current_page})
      setAdditionalInfo(_additionalInfo)
      setLoading(false)
    })
  }

  const updateProductPriceWillBeUpdated = () => {
    const willBeAddedProductIds = willBeAddedProducts.map((item) => item.id)

    confirm({
      title: 'Fiyatı Güncellenecek Ürünler Ekle',
      icon: <ExclamationCircleOutlined />,
      content: `Eklenecek ürünler ${willBeUpdatedQueue.nextjobtime} tarihinde fiyatları güncellenecek. Eklemek istediğinizden emin misiniz?`,
      okText: 'Tamam',
      cancelText: 'İptal',
      style: {width: 550},
      onOk() {
        const messageKey = 'add-product-price-will-be-updated'

        message.loading({
          content: 'Ürünler ekleniyor ve siliniyor...',
          key: messageKey,
          duration: 0,
        })

        updateProductPriceWillBeUpdatedRequest(apiQueue?.id ?? 0, {
          will_be_deleted_product_ids: willBeDeletedProductIds,
          will_be_added_product_ids: willBeAddedProductIds,
        })
          .then(() => {
            message.success({
              content: 'Ürünler fiyat güncelleme işlemleri için yapılan değişiklikler gerçekleşti.',
              key: messageKey,
            })
            setWillBeDeletedProductIds([])
            setWillBeAddedProducts([])
            setUnSelectAllItems((data) => data + 1)
            setSelectedProducts([])
            getProductPriceWillBeUpdated()
          })
          .catch((error) => {
            message.error({
              content: 'Ürünler eklenemedi veya silinemedi. Lütfen tekrar deneyiniz.',
              key: messageKey,
            })
            message.error({
              content: error.response.data.message,
              key: messageKey,
            })
          })

        if (
          apiQueue?.nextjobtime !== willBeUpdatedQueue.nextjobtime ||
          apiQueue?.jobinterval !== willBeUpdatedQueue.jobinterval
        ) {
          updateApiQueue()
        }
      },
    })
  }

  useEffect(() => {
    getProductPriceWillBeUpdated()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta({...meta, page: 1})
      return
    }

    getProductPriceWillBeUpdated()
  }, [meta.limit])

  useEffect(() => {
    if (unSelectAllItems === 0) {
      return
    }

    if (willBeDeletedProductIds.length === 0) {
      return
    }

    getProductPriceWillBeUpdated()
    onDelete(willBeDeletedProductIds)
  }, [willBeDeletedProductIds])

  useEffect(() => {
    if (!save) {
      return
    }

    updateProductPriceWillBeUpdated()
  }, [save])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  const addWillBeDeleteProductIds = () => {
    const selectedProductIds = selectedProducts.map((product: any) => product.id)

    if (selectedProductIds.length === 0) {
      return
    }

    confirm({
      title: `${selectedProductIds.length} ürün kategoriden çıkartılacaktır.`,
      icon: <ExclamationCircleOutlined />,
      content: 'Silme işlemi kaydet buttonuna basılana kadar gerçekleşmeyecektir.',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        setWillBeDeletedProductIds((productIds) => _.uniq([...productIds, ...selectedProductIds]))

        setWillBeAddedProducts((products) => {
          return products.filter(
            (product) => !selectedProductIds.some((id: number) => id === product.id)
          )
        })
        setUnSelectAllItems((data) => data + 1)
        setSelectedItems([])
      },
    })
  }

  const addWillBeAddedProducts = (data: any[]) => {
    setWillBeAddedProducts((products) => {
      const _data = data.filter((item) => {
        return !products.some((product) => {
          return product.id === item.id
        })
      })
      return [..._data, ...products]
    })

    setData((products) => {
      const _data = data.filter((item) => {
        return !products.some((product) => {
          return product.id === item.id
        })
      })
      return [..._data, ...products]
    })
  }

  return (
    <>
      {meta.total ? (
        <Alert
          className={'mb-3 rounded'}
          message='Bilgilendirme'
          description={`Kuyrukta maximum ${additionalInfo.update_product_price_count_limit_at_once} adet ürün olabilir. Şu anda ${meta.total} adet ürün var.`}
          type='info'
          showIcon
        />
      ) : meta.total === 0 ? (
        <Alert
          className={'mb-3 rounded'}
          message='Bilgilendirme'
          description={`Kuyrukta maximum ${additionalInfo.update_product_price_count_limit_at_once} adet ürün olabilir. Şu anda 0 adet ürün var.`}
          type='info'
          showIcon
        />
      ) : (
        <Loader />
      )}
      <TableHeader title={'Fiyatı Güncellenecek Ürünler'} selectedItemCount={0}>
        <div className={'d-flex'}>
          <div
            className={'text-primary'}
            role={'button'}
            onClick={() => {
              setVisibleTextFilter((data) => !data)
            }}
          >
            Metin ile ara
          </div>
        </div>
      </TableHeader>
      <div className={cn({'d-block': visibleTextFilter}, {'d-none': !visibleTextFilter})}>
        <ProductTextAreaFilter
          onFilter={(data) => {
            setFilterSearchData((filterSearch) => ({
              ...filterSearch,
              ids: data.productIds,
              models: data.models,
            }))
          }}
        />
      </div>
      <div className={cn({'mt-2': visibleTextFilter})}>
        <ReceivingTable
          columns={columns}
          data={data}
          meta={meta}
          outerHeight={visibleTextFilter ? 482 : 280}
          unSelectAllItems={unSelectAllItems}
          onSelectedItems={setSelectedItems}
          onChangeTableFilter={setFilterSearchData}
          onChangeLimit={(limit) => {
            setMeta((data) => ({...data, limit}))
          }}
          onChangePage={(page) => {
            setMeta((data) => ({...data, page}))
          }}
        />
        <div
          className={'text-primary mt-3 text-end fs-5 pointer'}
          role={'button'}
          style={{marginRight: 20}}
          onClick={addWillBeDeleteProductIds}
        >
          Kaldır
        </div>
      </div>
    </>
  )
}
