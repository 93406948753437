import _ from 'lodash'
import debounce from 'lodash/debounce'
import {OptionData} from 'rc-select/lib/interface'
import React, {useEffect, useState} from 'react'

import {searchProductAttributesRequest} from '../../../requests/products/attribute/ProductAttributeRequest'
import {productAttributeFilterType} from '../../models/products/ProductAttributeFilterModel'

interface IUseProductAttributeFilter {
  attributeId: number
  initialLoad?: boolean
}

export const useProductAttributeFilter = ({
  attributeId,
  initialLoad,
}: IUseProductAttributeFilter) => {
  const [spinning, setSpinning] = useState<boolean>(false)
  const [options, setOptions] = useState<OptionData[]>([])
  const [defaultOptions, setDefaultOptions] = useState<OptionData[]>([])
  const [selectedItems, setSelectedItems] = useState<OptionData[]>([])
  const [search, setSearch] = useState<string>('')
  const fetchRef = React.useRef(0)

  const handleOnSearch = React.useMemo(() => {
    const loadOption = (search: string) => {
      setSearch(search)
      if (search.length === 0) {
        setOptions(defaultOptions)
        return
      }

      searchProductAttributes(search)
    }

    return debounce(loadOption, 300)
  }, [defaultOptions])

  const searchProductAttributes = (search: string, initial = false) => {
    setSpinning(true)
    fetchRef.current += 1
    const fetchId = fetchRef.current

    searchProductAttributesRequest(search, attributeId)
      .then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }
        const attributes = res.data.data.map(
          (productAttribute: productAttributeFilterType, key: number) => {
            if (defaultOptions.length > 0) {
              const defaultOption = _.find(defaultOptions, function (o) {
                return o.label === productAttribute.text
              })
              if (defaultOption) {
                return {value: defaultOption.value, label: productAttribute.text}
              }
            }

            return {value: key, label: productAttribute.text}
          }
        )

        if (initial) {
          setDefaultOptions(attributes)
        }
        setOptions(attributes)
      })
      .finally(() => {
        setSpinning(false)
      })
  }

  useEffect(() => {
    if (!initialLoad) {
      return
    }

    if (!search) {
      return
    }

    setOptions(defaultOptions)
  }, [selectedItems])

  useEffect(() => {
    if (!initialLoad) {
      return
    }

    searchProductAttributes('', true)
  }, [])

  const handleOnChange = (data: OptionData[]) => {
    if (!data) {
      return
    }

    setSelectedItems(data)
  }

  return {
    options,
    spinning,
    selectedItems,
    handleOnChange,
    handleOnSearch,
  }
}
