import axios from 'axios'

import {willBeUpdatedApiQueueType} from '../../components/models/ApiQueueModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const API_QUEUE_URL = `${API_URL}/queues`

export const getApiQueuesRequest = (names?: string[]) => {
  return axios.get(`${API_QUEUE_URL}`, {
    params: {
      names,
    },
  })
}

export const getApiQueueRequest = (apiQueueId: number) => {
  return axios.get(`${API_QUEUE_URL}/${apiQueueId}`)
}

export const updateApiQueueRequest = (apiQueueId: number, data: willBeUpdatedApiQueueType) => {
  return axios.put(`${API_QUEUE_URL}/${apiQueueId}`, data)
}
