import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import React, {FC, useContext, useEffect} from 'react'

import {CategoryProductSortingContext} from '../../../../../../../providers/category/sorting/CategoryProductSortingProvider'
import {KrcButton} from '../../../../../../atoms/buttons/KrcButton'
import {AddProductToCategorySortingDrawer} from '../../../../../../drawers/category/sorting/AddProductToCategorySortingDrawer'
import {ICategorySortPage} from '../../../../../../models/categories/CategoryModel'
import {KrcTable} from '../../../../../../organisms/tables/KrcTable'

const {confirm} = Modal

export const CategoryProductManualSorting: FC<ICategorySortPage> = ({pageType}) => {
  const {
    visibleAddProductToCategorySortingDrawer,
    setVisibleAddProductToCategorySortingDrawer,
    addCategorySortedProducts,
    categorySortedEditorChoiceProducts,
    categorySortingEditorsChoiceColumns,
    unSelectAllItems,
    setCategorySortingEditorsChoiceSelectedItems,
    setCategorySortedEditorChoiceProducts,
    handleOnSaveCategorySortingEditorsChoice,
    setPageType,
    getCategorySortingEditorChoice,
    categorySortingEditorsChoiceSelectedItems,
    deleteSelectedCategorySortingProducts,
  } = useContext(CategoryProductSortingContext)

  useEffect(() => {
    getCategorySortingEditorChoice()
    setPageType(pageType)
  }, [])

  return (
    <>
      <div className={'d-flex justify-content-end my-4'}>
        <KrcButton
          size={'middle'}
          type={'default'}
          disabled={categorySortingEditorsChoiceSelectedItems.length === 0}
          onClick={() => {
            confirm({
              title: `${categorySortingEditorsChoiceSelectedItems.length} ürün silinecek.`,
              icon: <ExclamationCircleOutlined />,
              content: 'Seçili ürünler silinecek. Onaylıyor musunuz?',
              okText: 'Tamam',
              cancelText: 'İptal',
              onOk() {
                deleteSelectedCategorySortingProducts()
              },
            })
          }}
          className={'mr-3 col-2'}
          id='kt_toolbar_primary_button'
        >
          Seçili Ürünleri Sil
        </KrcButton>
        <KrcButton
          size={'middle'}
          onClick={() => {
            setVisibleAddProductToCategorySortingDrawer(true)
          }}
          className={'mr-3 col-2'}
          id='kt_toolbar_primary_button'
        >
          Ürün Ekle
        </KrcButton>
      </div>
      <>
        <KrcTable
          columns={categorySortingEditorsChoiceColumns}
          data={categorySortedEditorChoiceProducts}
          outerHeight={280}
          selectableProps={{
            isSelectable: true,
            onSelectedItems: setCategorySortingEditorsChoiceSelectedItems,
            unSelectAllItems: unSelectAllItems,
          }}
          sortableProps={{
            isSortable: true,
            onSortChange: (products) => {
              setCategorySortedEditorChoiceProducts(() => products)
            },
          }}
        />
        <div className='d-flex justify-content-end'>
          <KrcButton
            size={'middle'}
            onClick={() => {
              handleOnSaveCategorySortingEditorsChoice()
            }}
            id='kt_toolbar_primary_button'
          >
            Kaydet
          </KrcButton>
        </div>
      </>
      {/* )}*/}
      <AddProductToCategorySortingDrawer
        visible={visibleAddProductToCategorySortingDrawer}
        onHide={() => {
          setVisibleAddProductToCategorySortingDrawer(false)
        }}
        onOk={(products) => {
          addCategorySortedProducts(products ?? [])
        }}
      />
    </>
  )
}
