/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'

import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {toAbsoluteUrl} from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const {user} = useSelector((selector: any) => selector.auth)

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  return (
    <div
      //className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      className='menu menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-100 max-w-275px d-flex flex-row align-items-center !p-0'
      data-kt-menu='true'
    >
      <div className={'symbol ms-2 pb-2'}>
        <img sizes={'small'} src={toAbsoluteUrl('/media/avatars/blank.png')} alt='metronic' />
      </div>
      <div>
        <div className='menu-item px-3'>
          <div className='d-flex align-items-center px-3'>
            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {user.data.firstname} {user.data.lastname}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-dark fs-7'>
                {user.data.email}
              </a>
            </div>
          </div>
        </div>
        <div className='menu-item px-2'>
          <a onClick={logout} className='menu-link py-2 px-5'>
            Sign Out
          </a>
        </div>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
