import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {ProductAvailableWrapper} from '../components/pages/products/available/ProductAvailableWrapper'
import {ProductsBadgeWrapper} from '../components/pages/products/badge/ProductsBadgeWrapper'
import {ProductBadgeLogsExportWrapper} from '../components/pages/products/badge/export/ProductBadgeLogsExportWrapper'
import {BulkPriceTriggerWrapper} from '../components/pages/products/bulk-price-trigger/BulkPriceTriggerWrapper'
import {ProductsImportWrapper} from '../components/pages/products/import/ProductsImportWrapper'
import {ProductsImporting} from '../components/pages/products/import/ProductsImporting'
import {ProductListWrapper} from '../components/pages/products/lists/ProductListWrapper'
import {ProductListExportWrapper} from '../components/pages/products/lists/export/ProductListExportWrapper'
import {PassiveProductListWrapper} from '../components/pages/products/passive/list/PassiveProductListWrapper'
import {PassiveProductExportWrapper} from '../components/pages/products/passive/list/export/PassiveProductExportWrapper'
import {ProductNewPriceWrapper} from '../components/pages/products/price/ProductNewPriceWrapper'
import {ProductsUpdateImportWrapper} from '../components/pages/products/update-import/ProductsUpdateImportWrapper'
import {ProductsUpdateImporting} from '../components/pages/products/update-import/ProductsUpdateImporting'
import {ProductUpdateWrapper} from '../components/pages/products/update/ProductUpdateWrapper'

export const ProductRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/importing/:productImportId'} element={<ProductsImporting />} />
        <Route path={'/update-importing/:productImportId'} element={<ProductsUpdateImporting />} />
        <Route path={'/import'} element={<ProductsImportWrapper />} />
        <Route path={'/update'} element={<ProductsUpdateImportWrapper />} />
        <Route path={'/new/prices'} element={<ProductNewPriceWrapper />} />
        <Route path={'/passive/export'} element={<PassiveProductExportWrapper />} />
        <Route path={'/badges'} element={<ProductsBadgeWrapper />} />
        <Route path={'/passive'} element={<PassiveProductListWrapper />} />
        <Route path={'/export'} element={<ProductListExportWrapper />} />
        <Route path={'/badges/logs/export'} element={<ProductBadgeLogsExportWrapper />} />
        <Route path={'/bulk-price-trigger'} element={<BulkPriceTriggerWrapper />} />
        <Route path={'/available'} element={<ProductAvailableWrapper />} />
        <Route path={':productId/updates'} element={<ProductUpdateWrapper />}></Route>
        <Route path={'/'} element={<ProductListWrapper />} />
      </Routes>
    </>
  )
}
