import {SelectProps, Spin} from 'antd'
import React, {FC, useEffect, useRef} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useIBMCampaignSpecialFilter} from '../../hooks/IBM/useIBMCampaignSpecialFilter'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {IIBMCampaignSpecialFilter} from '../../models/ibm/IBMCampaignSpecialGroupModel'

const specialGroupFilterConfig = {
  default: {
    style: {width: '100%'},
    mode: 'multiple',
    size: 'middle',
    placeholder: 'Kampanya Özel Grup Filtre Listesi',
  },
}

export const IBMCampaignSpecialFilter: FC<IIBMCampaignSpecialFilter & SelectProps> = ({
  debounceTime = 500,
  onSelected,
  initialLoad,
  ...props
}) => {
  const initialRef = useRef(0)
  const currentProps = useKrcProp({
    config: specialGroupFilterConfig.default,
    props,
  }).getCurrentProps()

  const {handleOnSearch, handleOnChange, options, loading, selectedItems} =
    useIBMCampaignSpecialFilter({
      debounceTime,
      initialLoad,
    })

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current = 1

      return
    }

    if (onSelected) {
      onSelected(selectedItems)
    }
  }, [selectedItems])

  return (
    <>
      <KrcSelect
        {...currentProps}
        showArrow
        filterOption={false}
        allowClear={false}
        labelInValue
        onSearch={handleOnSearch}
        onChange={handleOnChange}
        options={options}
        notFoundContent={loading ? <Spin size='small' /> : null}
      />
    </>
  )
}
