export const ALL = -1
export const COOKLPUS = 0
export const KARACA_HOME = 1
export const KARACA = 2
export const KARACA_APP = 12
export const EMSAN = 3
export const PERA_BULVARI = 13
export const HOMEND = 18
export const KASMIR = 22

export const STORES = [
  {
    store_id: ALL,
    store_id_text: 'Tüm Mağazalar',
  },
  {
    store_id: COOKLPUS,
    store_id_text: 'Cookplus',
  },
  {
    store_id: KARACA_HOME,
    store_id_text: 'Karaca Home',
  },
  {
    store_id: KARACA,
    store_id_text: 'Karaca',
  },
  {
    store_id: KARACA_APP,
    store_id_text: 'Karaca App',
  },
  {
    store_id: EMSAN,
    store_id_text: 'Emsan',
  },
  {
    store_id: PERA_BULVARI,
    store_id_text: 'Pera Bulvarı',
  },
  {
    store_id: HOMEND,
    store_id_text: 'Homend',
  },
  {
    store_id: KASMIR,
    store_id_text: 'Kaşmir Halı',
  },
]
