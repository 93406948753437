import {Drawer} from 'antd'
import moment from 'moment/moment'
import React, {FC, useEffect, useState} from 'react'
import {Cell} from 'react-table'

import {KrcCopyToClipboard} from '../atoms/KrcCopyToClipboard'
import {TABLE_TYPE_NAMES, badgeListDataType} from '../models/badges/BadgeModel'
import {KrcTable} from '../organisms/tables/KrcTable'

export const BadgeListDrawer: FC<{badges: badgeListDataType[] | null; onClose: () => any}> = ({
  badges,
  onClose,
}) => {
  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Badge ID',
        accessor: 'id',
        maxWidth: 100,
      },
      {
        Header: 'Badge Adı',
        accessor: 'name',
        maxWidth: 150,
      },
      {
        Header: 'Badge Türü',
        accessor: 'table_type',
        maxWidth: 150,
        Cell: ({row}: Cell<any>) => (
          <div>
            {/* @ts-ignore */}
            {TABLE_TYPE_NAMES[row.original.table_type]} (
            <KrcCopyToClipboard text={row.original.table_id}>
              <span style={{cursor: 'pointer'}}>{row.original.table_id}</span>
            </KrcCopyToClipboard>
            )
          </div>
        ),
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'startDate',
        maxWidth: 150,
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'endDate',
        maxWidth: 150,
      },
    ]
  }, [])
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    const _data =
      badges?.map((badge) => {
        return {
          id: badge.badge_id,
          name: badge.badge?.name,
          table_id: badge.table_id,
          table_type: badge.table_type,
          startDate: badge.start_date ? moment(badge.start_date).format('DD.MM.YYYY HH:mm') : '-',
          endDate: badge.end_date ? moment(badge.end_date).format('DD.MM.YYYY HH:mm') : '-',
        }
      }) ?? []
    setData(_data)
  }, [badges])

  return (
    <>
      <Drawer
        title={'Badge Listesi'}
        placement='right'
        size={'large'}
        onClose={onClose}
        keyboard={false}
        visible={badges !== null}
      >
        <KrcTable columns={columns} data={data} />
      </Drawer>
    </>
  )
}
