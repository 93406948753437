import debounce from 'lodash/debounce'
import {OptionData} from 'rc-select/lib/interface'
import React, {useEffect, useRef, useState} from 'react'

import {categorySearchRequest} from '../../../requests/categories/CategoryRequest'
import {
  IUseCategoryFilter,
  categoryPathType,
  categorySearchType,
} from '../../models/categories/CategoryModel'

export const useCategoryFilter = ({
  debounceTime = 500,
  categoryType,
  withPath,
}: IUseCategoryFilter) => {
  const [options, setOptions] = useState<OptionData[]>()
  const [fetching, setFetching] = useState<boolean>(false)
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([])
  const [selectedItems, setSelectedItems] = useState<OptionData[]>([])
  const fetchRef = React.useRef(0)
  const initialRef = useRef(0)
  const [initialOptions, setInitialOptions] = useState<OptionData[]>([])
  const [search] = useState<string>('')

  const handleSearch = React.useMemo(() => {
    const loadOptions = (name: string, initial: boolean = false) => {
      if (name.length < 2 && !initial) {
        return
      }

      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      categorySearchRequest(name, categoryType, withPath).then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }

        const categories: OptionData[] = res.data.data.map(
          (category: categorySearchType | categoryPathType) => {
            if (withPath) {
              return {value: category.category_id, label: (category as categoryPathType).path}
            }

            return {
              value: category.category_id,
              label: (category as categorySearchType)?.details?.name,
            }
          }
        )

        if (initial) {
          setInitialOptions(categories)
        }

        setOptions(categories)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTime)
  }, [categoryType])

  const handleOnChange = (payload: Array<OptionData> | OptionData) => {
    if (!payload) {
      return
    }

    if (Array.isArray(payload)) {
      setSelectedItems(payload)
      const categoryIds = payload.map((data: OptionData) => data.value) as number[]
      setSelectedCategoryIds(categoryIds)

      return
    }

    setSelectedItems([payload] as Array<OptionData>)
    setSelectedCategoryIds([payload.value as number])
  }

  useEffect(() => {
    handleSearch('', true)
  }, [categoryType])

  useEffect(() => {
    if (initialRef.current !== 0 && search.length !== 0) {
      return
    }

    setOptions(initialOptions)
  }, [search])

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current += 1
    }
  }, [])

  return {
    fetching,
    options,
    handleSearch,
    handleOnChange,
    selectedCategoryIds,
    selectedItems,
  }
}
