import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
const IBM_CAMPAIGN_URL = `${API_URL}/ibm/campaigns/special-groups`

export function searchIBMCampaignSpecialRequest(name: string) {
  return axios.get(`${IBM_CAMPAIGN_URL}/search`, {
    params: {
      search: name,
    },
  })
}
