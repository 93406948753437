import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
const PRODUCT_URL = `${API_URL}/products`

export function searchProductAttributesRequest(search: string, attributeId: number) {
  return axios.get(`${PRODUCT_URL}/attributes/search`, {
    params: {
      search,
      attribute_id: attributeId,
    },
  })
}
