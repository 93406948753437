import {useEffect, useMemo, useState} from 'react'

import {IOrderModel} from '../../../../../components/models/order/OrderModel'
import {getProperty} from '../../../../../helpers/ArrayHelper'
import {priceFormat} from '../../../../../helpers/NumberHelper'
import {orderSubBlockModel} from '../../../../models/order/OrderSubBlockModel'
import {KrcTable} from '../../../../organisms/tables/KrcTable'

const PaymentGeneralInformation: React.FC<IOrderModel> = (props) => {
  const [data, setData] = useState<orderSubBlockModel[]>([])

  useEffect(() => {
    setData([
      {
        key: 1,
        field: 'Ödeme Bilgisi',
        value:
          props.order && props.order.payments && props.order.payments.length > 0
            ? props.order.payments[0].type
            : '',
      },
      {
        key: 2,
        field: 'Açıklama',
        value: props.order.description,
      },
      {
        key: 3,
        field: 'Provizyon No',
        value: '#',
      },
      {
        key: 4,
        field: 'Sipariş Toplamı',
        value: (
          <b>
            {' '}
            {priceFormat(props.order.orderAmount)}{' '}
            {getProperty(props.order.customProperties, 'Currency')}{' '}
          </b>
        ),
      },
    ])
  }, [props.order])

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'field',
        width: '40%',
      },
      {
        Header: '',
        accessor: 'value',
      },
    ]
  }, [])

  return (
    <div>
      {props.order && (
        <>
          <KrcTable
            columns={columns}
            data={data}
            showHeader={false}
            cellClassName='font-weight-normal'
            stylesProps={{style: {margin: 0, padding: 0}, className: ['order-block-table']}}
          />
        </>
      )}
    </div>
  )
}

export default PaymentGeneralInformation
