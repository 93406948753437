import {ExclamationCircleOutlined} from '@ant-design/icons'
import {captureException} from '@sentry/react'
import {Modal, message} from 'antd'
import React, {FC, createContext, useContext, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {Column} from 'react-table'

import {
  addProductVariantType,
  productUrlType,
} from '../../../../components/models/products/ProductModel'
import {addProductVariantsRequest} from '../../../../requests/products/ProductRequest'
import {ProductUpdateContext} from '../ProductUpdateProvider'

const {confirm} = Modal

interface IProductVariantContext {
  addProductVariant: (data: addProductVariantType[]) => void

  columns: Column<any>[]
}

export const ProductVariantContext = createContext<IProductVariantContext>({
  columns: [],
  addProductVariant: () => {},
})

export const ProductVariantProvider: FC = ({children}) => {
  const {getProductVariants, setProductVariantDrawerVisible} = useContext(ProductUpdateContext)
  const {productId} = useParams<productUrlType>()

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'Model',
        accessor: 'model',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
      },
      {
        Header: 'Tür',
        accessor: 'type',
      },
      {
        Header: 'Sıralama',
        accessor: 'sort_order',
      },
    ],
    []
  )

  useEffect(() => {
    getProductVariants()
  }, [])

  const addProductVariant = (willBeAddedProductVariants: addProductVariantType[]) => {
    if (!productId) {
      return
    }

    if (willBeAddedProductVariants?.length === 0) {
      message.warn('Ürüne varyant(lar) ekleyebilmek için lütfen ürün seçiniz.')
      return
    }

    confirm({
      title: `${willBeAddedProductVariants?.length} varyant eklenecek.`,
      icon: <ExclamationCircleOutlined />,
      content: 'Ürün varyant eklemek istediğinizden emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        const key = 'add-product-variants'

        message.loading({content: 'Varyantlar ürüne ekleniyor...', key})

        addProductVariantsRequest(productId, willBeAddedProductVariants)
          .then(() => {
            message.success({content: 'Varyant(lar) ürüne eklendi.', key})
            setProductVariantDrawerVisible(false)
            getProductVariants()
          })
          .catch((e) => {
            captureException(e)
            message.error({content: 'Varyant(lar) ürüne eklenemedi.', key})
          })
      },
    })
  }

  return (
    <>
      <ProductVariantContext.Provider
        value={{
          addProductVariant,
          columns,
        }}
      >
        {children}
      </ProductVariantContext.Provider>
    </>
  )
}
