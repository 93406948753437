import moment from 'moment/moment'
import React, {useEffect, useState} from 'react'

import {IOrderModel} from '../../../../components/models/order/OrderModel'
import {OrderType} from '../../../../components/models/order/OrderTypeModel'
import {getProperty} from '../../../../helpers/ArrayHelper'
import {KrcAlert} from '../../../atoms/KrcAlert'

interface IStatus {
  gift: boolean
  warranty: boolean
  canceled: boolean
  delayDelivery: boolean
  fastDelivery: boolean
  storeDelivery: boolean
}

const defaultStyle: React.CSSProperties = {
  background: 'white',
  opacity: '0.3',
  color: 'black',
  fontWeight: 500,
  margin: '5px 5px',
  display: 'inline-block',
  border: '1px solid black',
  borderRadius: 20,
  padding: '3px 15px',
}

const Highlight: React.FC<IOrderModel> = (props) => {
  const [statuses, setStatuses] = useState<IStatus>({
    gift: false,
    warranty: false,
    canceled: false,
    delayDelivery: false,
    fastDelivery: false,
    storeDelivery: false,
  })

  useEffect(() => {
    const putStatuses = getStatuses(props.order)

    setStatuses(putStatuses)
  }, [props.order])

  const getStatuses = (order: OrderType): IStatus => {
    const putStatuses = {...statuses}

    order.orderItems.forEach((item) => {
      putStatuses.warranty =
        putStatuses.warranty || getProperty(item.customProperties, 'IsAddWarranty') === 'Evet'
      putStatuses.gift =
        putStatuses.gift || getProperty(item.customProperties, 'IsGiftWrap') === 'Evet'
      putStatuses.canceled =
        putStatuses.canceled || (item.cancelRequests && item.cancelRequests.length > 0)

      putStatuses.fastDelivery =
        putStatuses.fastDelivery || item.salesDeliveryType === 'AyniGunKargo'
      putStatuses.storeDelivery = putStatuses.storeDelivery || !!item.deliveryStoreId

      const estimated = moment(item.shippingDateRequested.split('T')[0])
      const delivered = item.deliveredDate ? moment(item.deliveredDate.split('T')[0]) : moment()

      putStatuses.delayDelivery = putStatuses.delayDelivery || delivered.isAfter(estimated)
    })

    return putStatuses
  }

  return (
    <div className='block-wrapper radius' style={{display: 'ruby-text'}}>
      <KrcAlert
        style={{
          ...defaultStyle,
          ...(statuses.delayDelivery ? {opacity: 1, background: '#FFD9A1'} : {}),
        }}
        message='Kargo Gecikmesi'
        type='warning'
      />
      <KrcAlert
        style={{...defaultStyle, ...(statuses.canceled ? {opacity: 1, background: '#FFACAC'} : {})}}
        message='İptal Talebi'
        type='error'
      />
      <KrcAlert
        style={{
          ...defaultStyle,
          ...(statuses.fastDelivery ? {opacity: 1, background: '#ACFFBD'} : {}),
        }}
        message='Hızlı Teslimat'
        type='warning'
      />
      <KrcAlert
        style={{
          ...defaultStyle,
          ...(statuses.storeDelivery ? {opacity: 1, background: '#A1F8FF'} : {}),
        }}
        message='Mağaza Teslimat'
        type='warning'
      />
      <KrcAlert
        style={{...defaultStyle, ...(statuses.warranty ? {opacity: 1, background: '#E8EFFA'} : {})}}
        message='Ek Garanti'
        type='info'
      />
      <KrcAlert
        style={{...defaultStyle, ...(statuses.gift ? {opacity: 1, background: '#EAACFF'} : {})}}
        message='Hediye'
        type='success'
      />
    </div>
  )
}

export default Highlight
