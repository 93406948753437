import {Form, Formik} from 'formik'
import React, {FC, useContext} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {SortingWeightContext} from '../../../providers/sorting/SortingWeightProvider'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'
import {KrcModal} from '../KrcModal'

export const CreateSortingWeightModal: FC = () => {
  const {visibleCreateSortingWeightModal, setVisibleCreateSortingWeightModal, createSortingWeight} =
    useContext(SortingWeightContext)

  return (
    <>
      <KrcModal
        modalProps={{
          onHide: () => {
            setVisibleCreateSortingWeightModal(false)
          },
        }}
        modalHeaderProps={{
          title: 'Sıralama Ağırlığı Oluştur',
          onHide: () => {
            setVisibleCreateSortingWeightModal(false)
          },
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10 row justify-content-center'>
          {visibleCreateSortingWeightModal && (
            <Formik
              enableReinitialize
              onSubmit={(values) => {
                createSortingWeight(values)
              }}
              initialValues={{}}
            >
              {() => (
                <Form className='form row' noValidate>
                  <KrcFormikField
                    name={'sorting_type'}
                    labelTitle={'Sıralama Türü'}
                    tooltipProps={{
                      status: true,
                      text: 'Oluşturulacak sıralama ağırlığının adını belirleyiniz.',
                    }}
                  />

                  <div className='col-md-12 d-flex justify-content-end mt-5'>
                    <button
                      type='submit'
                      className='w-25 btn btn-secondary mr-3'
                      onClick={() => {
                        setVisibleCreateSortingWeightModal(false)
                      }}
                    >
                      İptal
                    </button>
                    <button type='submit' className='w-25 btn btn-primary'>
                      Oluştur
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </KrcModal>
    </>
  )
}
