import debounce from 'lodash/debounce'
import {OptionData} from 'rc-select/lib/interface'
import React, {useState} from 'react'

import {fetchPermissionsRequest} from '../../../requests/permissions/PermissionRequest'
import {PermissionDataType} from '../../models/permissions/PermissionModel'

export const usePermissionFilter = (debounceTime = 500) => {
  const [options, setOptions] = useState<OptionData[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [permissionIds, setPermissionIds] = useState<number[] | number>([])
  const [selectedItems, setSelectedItems] = useState<OptionData[] | OptionData>([])
  const fetchRef = React.useRef(0)

  const handleSearch = React.useMemo(() => {
    const loadOptions = (search: string) => {
      if (search.length < 2) {
        return
      }

      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchPermissionsRequest({name: search}, {page: 1, limit: 10}).then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }

        const permissions: OptionData[] = res.data.data.map((permission: PermissionDataType) => {
          return {value: permission.id, label: permission.name}
        })

        setOptions(permissions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTime)
  }, [])

  function handleOnChange(payload: Array<OptionData> | OptionData) {
    if (!payload) {
      setSelectedItems([])
      setPermissionIds([])
      return
    }
    setSelectedItems(payload)

    if (Array.isArray(payload)) {
      const formattedPermissionIds = payload.map((data: OptionData) => data.value) as number[]
      setPermissionIds(formattedPermissionIds)
    } else {
      const permissionIds = payload.value as number
      setPermissionIds(permissionIds)
    }
  }

  return {
    handleOnChange,
    handleSearch,
    permissionIds,
    fetching,
    options,
    selectedItems,
  }
}
