import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, message} from 'antd'
import React, {createContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {
  approveProductUpdateImportRequest,
  getDownloadExampleFileUrl,
} from '../../requests/products/import-update/ProductUpdateImportRequest'

interface IProductUpdateImportContext {
  productUpdateImportId?: number
  setProductUpdateImportId: (productImportId: number | undefined) => void
  setImportLink: (signature: string | undefined) => void
  approveProductUpdateImport: () => void
  redirectToDownloadExampleFile: () => void
}

export const ProductUpdateImportContext = createContext<IProductUpdateImportContext>({
  productUpdateImportId: 0,
  setProductUpdateImportId: () => {},
  setImportLink: () => {},
  approveProductUpdateImport: () => {},
  redirectToDownloadExampleFile: () => {},
})

const {confirm} = Modal

export const ProductUpdateImportProvider = ({children}: any) => {
  const [productUpdateImportId, setProductUpdateImportId] = useState<number>()
  const [importLink, setImportLink] = useState<string>()
  let navigate = useNavigate()

  useEffect(() => {
    return () => {
      setProductUpdateImportId(undefined)
      setImportLink(undefined)
    }
  }, [])

  const approveProductUpdateImport = () => {
    if (!productUpdateImportId || !importLink) {
      return
    }

    confirm({
      title: `Toplu Ürün Güncelleme`,
      icon: <ExclamationCircleOutlined />,
      content: 'Toplu bir şekilde ürünleri güncellemek istediğinizden emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        approveProductUpdateImportRequest(importLink)
          .then(() => {
            navigate(`/products/update-importing/${productUpdateImportId}`)
          })
          .catch(($e) => {
            message.error($e.response.data.message)
          })
      },
    })
  }

  const redirectToDownloadExampleFile = () => {
    window.location.href = getDownloadExampleFileUrl()
  }

  return (
    <ProductUpdateImportContext.Provider
      value={{
        productUpdateImportId,
        setProductUpdateImportId,
        setImportLink,
        approveProductUpdateImport,
        redirectToDownloadExampleFile,
      }}
    >
      {children}
    </ProductUpdateImportContext.Provider>
  )
}
