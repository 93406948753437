import {Alert} from 'antd'
import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  CategoryLandingContext,
  CategoryLandingProvider,
} from '../../../../providers/category/landings/CategoryLandingProvider'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {Loader} from '../../../molecules/Loader'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {CategoryLandingMaps} from './CategoryLandingMaps'

const CategoryLandingMapWrapperWithProvider: FC = () => {
  const {currentStore, options, selectCurrentStore, isLoading} = useContext(CategoryLandingContext)

  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <span className={'fs-5 mr-3 text-dark fw-bolder'}>Mağazalar:</span>
          {currentStore && Object.keys(currentStore).length > 0 && (
            <KrcSelect
              showSearch
              defaultValue={currentStore.store_id}
              options={options}
              onChange={(storeId) => {
                if (!storeId) {
                  return
                }

                selectCurrentStore(storeId)
              }}
              style={{width: 200}}
              placeholder='Arama'
              optionFilterProp='children'
            />
          )}
        </div>
      </Toolbar>
      <PageTitle>Kategori Landing</PageTitle>
      {isLoading ? (
        <div className={'d-flex flex-column justify-content-center align-items-center'}>
          <Loader />
          <Alert message='Menüler getiriliyor, lütfen bekleyiniz..' type='info' showIcon />
        </div>
      ) : (
        <CategoryLandingMaps />
      )}
    </>
  )
}

export const CategoryLandingMapWrapper: FC = () => {
  return (
    <>
      <CategoryLandingProvider>
        <CategoryLandingMapWrapperWithProvider />
      </CategoryLandingProvider>
    </>
  )
}
