import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
const PRODUCT_IMPORT_URL = `${API_URL}/categories/products/pending-approval/import`

export const PRODUCT_IMPORT_UPLOAD_URL = `${PRODUCT_IMPORT_URL}/upload`

export function approveImportRequest(importLink: string) {
  return axios.post(importLink)
}

export function checkExcelImportStatus(productExcelImportId: number) {
  return axios.get(`${PRODUCT_IMPORT_URL}/${productExcelImportId}/check-status`)
}
