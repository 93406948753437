import {FC, useContext, useEffect, useState} from 'react'

import {OrderContext} from '../../../providers/orders/OrderQuestioningProvider'
import {paginationMetaType} from '../../models/GeneralModel'
import {Loader} from '../../molecules/Loader'
import {KrcTable} from '../../organisms/tables/KrcTable'
import {Results} from '../design/result/Results'
import LeftForm from './forms/LeftForm'
import RightForm from './forms/RightForm'

export const OrderQuestioning: FC = () => {
  const {orders, columns, filter, meta, setMeta, isLoading} = useContext(OrderContext)
  const [questioned, setQuestioned] = useState<boolean>(false)

  useEffect(() => {
    if (Object.keys(filter).length > 0) {
      setQuestioned(true)
    }
  }, [filter])

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <LeftForm />
        <RightForm />
      </div>
      {isLoading ? (
        <Loader />
      ) : orders.length > 0 ? (
        <KrcTable
          columns={columns}
          data={orders}
          paginationProps={{
            isPagination: true,
            limit: meta.limit,
            page: meta.page,
            paginationData: meta,

            onChangePage: (page) => {
              setMeta((data: paginationMetaType) => ({...data, page}))
            },
            onChangeLimit: (limit) => {
              setMeta((data: paginationMetaType) => ({...data, limit}))
            },
          }}
        />
      ) : (
        <Results
          title={questioned === true ? 'Sipariş bulunamadı.' : 'Henüz sipariş sorgulamadınız.'}
        />
      )}
    </>
  )
}
