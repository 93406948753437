import {message} from 'antd'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {createFaqRequest, getFaqRequest} from '../../../requests/faq/FaqRequest'

export const useFaqCreateForm = () => {
  const navigate = useNavigate()
  const [faqs, setFaqs] = useState<any>([])

  const urlParams = window.location.search.substring(1)
  const currentStore = urlParams.split('store=')[1].split('&')[0]

  useEffect(() => {
    getAllFaqTitles()
  }, [])

  const getAllFaqTitles = () => {
    getFaqRequest({}, {page: 1, limit: 10}, Number(currentStore), 1)
      .then((response) => {
        setFaqs(response.data.data)
      })
      .catch((error) => {
        message.error('Sıkça sorulan sorular getirilirken bir hata oluştu!')
      })
  }

  const createFaq = (values: any) => {
    message.info('Sıkça sorulan soru oluşturuluyor...')
    createFaqRequest(values)
      .then((response) => {
        message.success('Sıkça sorulan soru başarıyla oluşturuldu.')
        navigate('/faq')
      })
      .catch((error) => {
        message.error('Sıkça sorulan soru oluşturulurken bir hata oluştu!')
      })
  }

  return {
    faqs,
    createFaq,
    currentStore: Number(currentStore),
  }
}
