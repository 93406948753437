import {isEmpty} from 'lodash'
import {FC} from 'react'

import {MANUFACTURER_STORES} from '../../../enums/ManufacturerStoresEnum'
import {manufacturerTabDataType} from '../../models/manufacturers/ManufacturerModel'
import {KrcTabs} from '../../molecules/KrcTabs'
import {ManufacturerStoreImageInformation} from './tabs/ManfucaturerStoreImageInformation'
import {ManufacturerGeneralInformation} from './tabs/ManufacturerGeneralInformation'

interface IManufacturerTabViewProps {
  manufacturerGeneralInformation: manufacturerTabDataType
}

export const ManufacturerTabView: FC<IManufacturerTabViewProps> = ({
  manufacturerGeneralInformation,
}) => {
  return (
    <>
      <div>
        {!isEmpty(manufacturerGeneralInformation) && (
          <KrcTabs
            isActiveKey={false}
            tabsProps={{
              defaultActiveKey: 'general',
            }}
            tabProps={[
              {
                eventKey: 'general',
                title: 'Genel Bilgiler',
                children: (
                  <ManufacturerGeneralInformation
                    manufacturerGeneralInformation={manufacturerGeneralInformation}
                  />
                ),
              },
              ...manufacturerGeneralInformation.store_ids.map((store) => {
                return {
                  eventKey: store.store_id.toString(),
                  title: MANUFACTURER_STORES.map((s) =>
                    s.value === store.store_id ? s.label : ''
                  ),
                  children: (
                    <ManufacturerStoreImageInformation
                      manufacturerGeneralInformation={manufacturerGeneralInformation}
                      storeId={store.store_id}
                    />
                  ),
                }
              }),
            ]}
          />
        )}
      </div>
    </>
  )
}
