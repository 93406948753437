import cn from 'classnames'
import React, {FC} from 'react'
import {Link} from 'react-router-dom'

import {usePassiveProductReceivingProductTable} from '../../../hooks/products/passives/usePassiveProductReceivingProductTable'
import {ProductTextAreaFilter} from '../../../molecules/filters/ProductTextAreaFilter'
import {TableHeader} from '../../../molecules/headers/TableHeader'
import {ReceivingTable} from '../../tables/ReceivingTable'

export const PassiveProductReceivingProductsTable: FC<any> = ({onActive}) => {
  const {
    changeLimit,
    changePage,
    setFilterSearchData,
    setSelectedPassiveProducts,
    activateSelectedPassiveProducts,
    getExportPassiveProductsUrl,
    data,
    selectedPassiveProducts,
    columns,
    meta,
    unSelectAllItems,
    setVisibleTextFilter,
    visibleTextFilter,
  } = usePassiveProductReceivingProductTable(onActive)

  return (
    <>
      <TableHeader title={'Pasif Ürünler'} selectedItemCount={selectedPassiveProducts.length}>
        <div className={'d-flex'}>
          <Link
            className={'text-primary mr-3'}
            role={'button'}
            target={'_blank'}
            to={getExportPassiveProductsUrl()}
          >
            Ürünleri dışarı aktar
          </Link>
          <div
            className={'text-primary'}
            role={'button'}
            onClick={() => {
              setVisibleTextFilter((data) => !data)
            }}
          >
            Metin ile ara
          </div>
        </div>
      </TableHeader>
      <div className={cn({'d-block': visibleTextFilter}, {'d-none': !visibleTextFilter})}>
        <ProductTextAreaFilter
          onFilter={(data) => {
            setFilterSearchData((filterSearch) => ({
              ...filterSearch,
              ids: data.productIds,
              models: data.models,
            }))
          }}
        />
      </div>
      <div className={cn({'mt-2': visibleTextFilter})}>
        <ReceivingTable
          columns={columns}
          data={data}
          meta={meta}
          outerHeight={visibleTextFilter ? 482 : 280}
          unSelectAllItems={unSelectAllItems}
          onSelectedItems={setSelectedPassiveProducts}
          onChangeTableFilter={setFilterSearchData}
          onChangeLimit={(limit) => {
            changeLimit(limit)
          }}
          onChangePage={(page) => {
            changePage(page)
          }}
        />
        <div
          className={'text-primary mt-3 text-end fs-5 pointer'}
          role={'button'}
          style={{marginRight: 20}}
          onClick={activateSelectedPassiveProducts}
        >
          Aktif Et
        </div>
      </div>
    </>
  )
}
