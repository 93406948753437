import debounce from 'lodash/debounce'
import {OptionData} from 'rc-select/lib/interface'
import React, {useState} from 'react'

import {fetchRolesRequest} from '../../../requests/roles/RoleRequest'
import {RoleDataType} from '../../models/roles/RoleModel'

export const useRoleFilter = (debounceTime = 500) => {
  const [options, setOptions] = useState<OptionData[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [roleIds, setRoleIds] = useState<number[] | number>([])
  const [selectedItems, setSelectedItems] = useState<OptionData[] | OptionData>([])
  const fetchRef = React.useRef(0)

  const handleSearch = React.useMemo(() => {
    const loadOptions = (search: string) => {
      if (search.length < 2) {
        return
      }

      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchRolesRequest({name: search}, {page: 1, limit: 10}).then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }

        const roles: OptionData[] = res.data.data.map((role: RoleDataType) => {
          return {value: role.id, label: role.name}
        })

        setOptions(roles)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTime)
  }, [])

  function handleOnChange(payload: Array<OptionData> | OptionData) {
    if (!payload) {
      setSelectedItems([])
      setRoleIds([])
      return
    }
    setSelectedItems(payload)

    if (Array.isArray(payload)) {
      const roleIds = payload.map((data: OptionData) => data.value) as number[]
      setRoleIds(roleIds)
    } else {
      const roleId = payload.value as number
      setRoleIds(roleId)
    }
  }

  return {
    handleOnChange,
    handleSearch,
    roleIds,
    fetching,
    options,
    selectedItems,
  }
}
