import {Popover} from 'antd'
import {PopoverProps} from 'antd/lib/popover'
import React, {FC, useEffect, useState} from 'react'

import {useKrcProp} from '../../hooks/components/useKrcProp'

const popoverConfig = {
  default: {
    trigger: 'click',
    title: 'Default',
  } as PopoverProps,
}

export const KrcPopover: FC<PopoverProps & {close?: number}> = ({children, close, ...props}) => {
  const [visible, setVisible] = useState<boolean>(false)

  const currentPopoverProps = useKrcProp({
    config: popoverConfig.default,
    props,
  }).getCurrentProps()

  useEffect(() => {
    if (!close) {
      return
    }

    setVisible(false)
  }, [close])

  return (
    <>
      <Popover
        {...currentPopoverProps}
        visible={visible}
        onVisibleChange={() => {
          setVisible((prev) => !prev)
        }}
      >
        {children}
      </Popover>
    </>
  )
}
