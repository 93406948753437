import {FC} from 'react'

import {CategoryProductsProcessProvider} from '../../../../providers/category/products/CategoryProductsProcessProvider'
import {CategoryProductsProcess} from './listing/CategoryProductsProcess'

const CategoryProductsProcessWrapperWithoutProvider: FC = () => {
  return (
    <>
      <CategoryProductsProcess />
    </>
  )
}

export const CategoryProductsProcessWrapper: FC = () => {
  return (
    <>
      <CategoryProductsProcessProvider>
        <CategoryProductsProcessWrapperWithoutProvider />
      </CategoryProductsProcessProvider>
    </>
  )
}
