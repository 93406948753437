import React, {useState} from 'react'

import {productTextSearchType} from '../../models/products/ProductListFilterModel'

export const useProductTextAreaFilter = () => {
  const [value, setValue] = useState<any>()

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const getProductIdOrModelInText = (withExtraText: boolean): productTextSearchType => {
    if (!value) {
      return {productIds: [], models: []}
    }

    const numberRegex = new RegExp('^([0-9])*$')
    //const modelRegex = new RegExp('[0-9]+\\.[0-9]+\\.[0-9]+.[0-9]+')
    const modelRegex = new RegExp('^\\d{18}$')
    const data = value.split('\n')
    let productIds: string[] = []
    let models: string[] = []
    let productIdsAndModelsByOrder: string[] = []
    let extraText: string[] = []

    data.forEach((item: string) => {
      const text = item.trim()
      if (text.length <= 0) {
        return
      }

      if (modelRegex.exec(text)) {
        models.push(text)
        productIdsAndModelsByOrder.push(text)
      } else if (numberRegex.exec(text)) {
        productIds.push(text)
        productIdsAndModelsByOrder.push(text)
      } else if (withExtraText) {
        extraText.push(text)
        productIdsAndModelsByOrder.push(text)
      }
    })

    if (withExtraText) {
      return {productIds, models, extraText, all: productIdsAndModelsByOrder}
    }

    return {productIds, models, all: productIdsAndModelsByOrder}
  }

  return {handleOnChange, getProductIdOrModelInText}
}
