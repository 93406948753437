import React, {FC} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {FaqUpdateForm} from './FaqUpdateForm'

const ToolBar: FC = () => {
  return (
    <>
      <Toolbar />
      <PageTitle>Sıkça Sorulan Sorular Güncelleme</PageTitle>
    </>
  )
}

export const FaqUpdateFormWrapper: FC = () => {
  return (
    <>
      <ToolBar />
      <FaqUpdateForm />
    </>
  )
}
