import {Form, Formik} from 'formik'
import React, {FC, useEffect} from 'react'

import {KrcSelect} from '../../../../../../atoms/forms/KrcSelect'
import {useCategoryProductSortingMostSeller} from '../../../../../../hooks/categories/products/sorts/editors_choice/method/useCategoryProductSortingMostSeller'
import {ICategorySortPage} from '../../../../../../models/categories/CategoryModel'
import {Loader} from '../../../../../../molecules/Loader'
import {KrcFormikField} from '../../../../../../molecules/forms/KrcFormikField'

export const CategoryProductParametricSorting: FC<ICategorySortPage> = () => {
  const {
    initialValues,
    schema,
    updateCategoryProductMostSellerSorting,
    categoryMostSellerInputAttributes,
    categoryMostSellerManufacturers,
  } = useCategoryProductSortingMostSeller()

  const [selectedBrands, setSelectedBrands] = React.useState<Array<string>>([])
  const [selectedColors, setSelectedColors] = React.useState<Array<string>>([])
  const [selectedProductGroup, setSelectedProductGroup] = React.useState<Array<string>>([])
  const [selectedPersonCount, setSelectedPersonCount] = React.useState<Array<string>>([])
  const [selectedPieceCount, setSelectedPieceCount] = React.useState<Array<string>>([])
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    if (
      categoryMostSellerInputAttributes?.color.length > 0 &&
      categoryMostSellerManufacturers.length > 0
    ) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [categoryMostSellerInputAttributes, categoryMostSellerManufacturers])

  return (
    <>
      <div className={'row'}></div>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          enableReinitialize
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={(values) => {
            const sortRequestData = {
              first_line_count: values.first_line_count,
              product_name: values.product_name,
              product_group: selectedProductGroup,
              discount_rate: values.discount_rate,
              product_manufacturer: selectedBrands,
              product_color: selectedColors,
              product_number_of_people: selectedPersonCount,
              product_number_of_piece: selectedPieceCount,
            }
            updateCategoryProductMostSellerSorting(sortRequestData)
          }}
          validateOnMount={true}
        >
          {(formik) => {
            return (
              <Form className='form mt-6 space-between' noValidate>
                <div className={'row'}>
                  <div className={'col-3'}>
                    <KrcFormikField
                      className={'mb-2'}
                      type={'number'}
                      name={'first_line_count'}
                      labelTitle={'Öne çıkacak maksimum ürün sayısı'}
                      tooltipProps={{
                        status: true,
                        text: "0'dan büyük bir sayı giriniz. 0 girilirse veya boş bırakılırsa öne çıkacak ürün sayısı filtreden eşleşen tüm ürünler olacaktır.",
                      }}
                    />
                  </div>
                  <div className={'col-3'}>
                    <KrcFormikField
                      className={'mb-2'}
                      type={'text'}
                      name={'product_name'}
                      labelTitle={'İçerik Kelimesi'}
                      placeholder={'Örn: Yılbaşı'}
                      tooltipProps={{
                        status: true,
                        text: 'Sıralamada üstte olması beklenen ürünlerin içermesi istenilen kelimeyi giriniz.',
                      }}
                    />
                  </div>

                  <div className={'col-3'}>
                    <KrcFormikField
                      className={'mb-2'}
                      type={'number'}
                      name={'discount_rate'}
                      labelTitle={'İndirim Oranı'}
                      tooltipProps={{
                        status: true,
                        text: 'Hangi indirim oranının üstünde olan ürünlerin sıralanacağını giriniz.',
                      }}
                    />
                  </div>
                  <div className={'col-3'}>
                    <KrcSelect
                      mode={'multiple'}
                      placeholder={`Örn: ${categoryMostSellerInputAttributes?.number_of_people[0]?.text}`}
                      size={'large'}
                      defaultValue={initialValues?.product_number_of_people}
                      options={categoryMostSellerInputAttributes?.number_of_people?.map(
                        (item: any) => {
                          return {value: item?.text, label: item?.text}
                        }
                      )}
                      maxTagCount={'responsive'}
                      onChange={(value) => {
                        setSelectedPersonCount(value)
                      }}
                      style={{width: '100%'}}
                      className={'ant-selector-border mb-2'}
                      labelTitle={'Kişi Sayısı'}
                      tooltipProps={{
                        status: true,
                        text: 'Sıralamada üstte olması istenilen ürünlerin kişi sayısını giriniz.',
                      }}
                    />
                  </div>
                </div>
                <div className={'row mt-4'}>
                  <div className={'col-3'}>
                    <KrcSelect
                      mode={'multiple'}
                      placeholder={'Marka İsmi'}
                      size={'large'}
                      defaultValue={initialValues?.product_manufacturer}
                      options={categoryMostSellerManufacturers?.map((item: any) => {
                        return {value: item?.text, label: item?.text, key: item?.id}
                      })}
                      maxTagCount={'responsive'}
                      onChange={(value) => {
                        setSelectedBrands(value)
                      }}
                      style={{width: '100%'}}
                      className={'ant-selector-border mb-2'}
                      labelTitle={'Marka İsmi'}
                      tooltipProps={{
                        status: true,
                        text: 'Sıralamada üstte olması istenilen ürünlerin marka isimlerini giriniz.',
                      }}
                    />
                  </div>
                  <div className={'col-3'}>
                    <KrcSelect
                      mode={'multiple'}
                      placeholder={`Örn: ${categoryMostSellerInputAttributes?.group[0]?.text}`}
                      size={'large'}
                      defaultValue={initialValues?.product_group}
                      options={categoryMostSellerInputAttributes?.group?.map((item: any) => {
                        return {value: item?.text, label: item?.text}
                      })}
                      maxTagCount={'responsive'}
                      onChange={(value) => {
                        setSelectedProductGroup(value)
                      }}
                      style={{width: '100%'}}
                      className={'ant-selector-border mb-2'}
                      labelTitle={'Ürün Grubu'}
                      tooltipProps={{
                        status: true,
                        text: 'Sıralamada üstte olması istenilen ürünlerin grubunu giriniz.',
                      }}
                    />
                  </div>
                  <div className={'col-3'}>
                    <KrcSelect
                      mode={'multiple'}
                      placeholder={`Örn: ${categoryMostSellerInputAttributes?.color[3]?.text}`}
                      size={'large'}
                      defaultValue={initialValues?.product_color}
                      options={categoryMostSellerInputAttributes?.color?.map((item: any) => {
                        return {value: item?.text, label: item?.text}
                      })}
                      maxTagCount={'responsive'}
                      onChange={(value) => {
                        setSelectedColors(value)
                      }}
                      style={{width: '100%'}}
                      className={'ant-selector-border mb-2'}
                      labelTitle={'Ürün Rengi'}
                      tooltipProps={{
                        status: true,
                        text: 'Sıralamada üstte olması istenilen ürünlerin renk değerlerini giriniz.',
                      }}
                    />
                  </div>
                  <div className={'col-3'}>
                    <KrcSelect
                      mode={'multiple'}
                      placeholder={'Örn: 8 Parca'}
                      size={'large'}
                      defaultValue={initialValues?.product_number_of_piece}
                      options={categoryMostSellerInputAttributes?.number_of_piece?.map(
                        (item: any) => {
                          return {value: item?.text, label: item?.text}
                        }
                      )}
                      maxTagCount={'responsive'}
                      onChange={(value) => {
                        setSelectedPieceCount(value)
                      }}
                      style={{width: '100%', height: '40px'}}
                      className={'ant-selector-border mb-2'}
                      labelTitle={'Parça Sayısı'}
                      tooltipProps={{
                        status: true,
                        text: 'Sıralamada üstte olması istenilen ürünlerin parça sayısını giriniz.',
                      }}
                    />
                  </div>
                </div>

                <div className={'d-flex justify-content-end mt-7'}>
                  <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                    Kaydet
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}
