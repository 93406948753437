import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {useBadge} from '../../../hooks/badges/useBadge'
import {ExcelExport} from '../../../layout/excel/ExcelExportWrapper'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'

export const BadgeExportWrapper: FC = () => {
  const {exportBadges, downloadExcelUrl} = useBadge()

  return (
    <>
      <Toolbar />
      <PageTitle>Badge'leri Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport handleExportData={exportBadges} downloadExcelUrl={downloadExcelUrl} />
          }
        />
      </Routes>
    </>
  )
}
