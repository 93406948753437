import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const USER_URL = `${API_URL}/users`

export function fetchUsersRequest(filter: any, {page, limit}: paginationMetaType) {
  return axios.get(`${USER_URL}`, {
    params: {
      page,
      limit,
      ...filter,
    },
  })
}
