import PropTypes from 'prop-types'
import React from 'react'
import {useSelector} from 'react-redux'

const propTypes = {
  domain: PropTypes.string.isRequired,
  pathdialog: PropTypes.string,
  type: PropTypes.oneOf([1, 2, 3]),
  crossdomain: PropTypes.bool,
  target: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  akey: PropTypes.string.isRequired,
}

const defaultProps = {
  pathdialog: 'filemanager/dialog.php',
  type: 2,
  width: '100%',
  height: 500,
}

interface IFileManger {
  domain: string
  pathdialog?: string
  type?: string
  crossdomain?: boolean
  target?: any
  isOpen?: boolean
  width?: string | number
  height?: string | number
  akey: string
  className?: string
  tempUploadDir?: string
}

const FileManager = ({
  domain,
  pathdialog,
  type,
  crossdomain,
  target,
  isOpen,
  width,
  height,
  akey,
  className = '',
  tempUploadDir = '',
}: IFileManger) => {
  const {accessToken} = useSelector((state: any) => state.auth)

  return (
    <>
      {isOpen ? (
        <iframe
          className={className}
          title='File Manager'
          width={width}
          height={height}
          src={`${domain}/${pathdialog}?type=${type}&field_id=${target}${
            crossdomain ? '&crossdomain=1' : '&crossdomain=0'
          }&akey=${akey}&token=${accessToken}&temp_upload_dir=${tempUploadDir}`}
          frameBorder='0'
        />
      ) : null}
    </>
  )
}

FileManager.propTypes = propTypes
FileManager.defaultProps = defaultProps

export {FileManager}
