import moment, {Moment} from 'moment'

export const requestDateFormat = (
  date: Moment | null | undefined,
  format = 'YYYY-MM-DD HH:mm:ss'
): string | null => {
  if (!date) {
    return null
  }

  return date.format(format)
}

export const viewDateFormat = (date: string | null, format = 'DD-MM-YYYY HH:mm:ss') => {
  if (!date) {
    return null
  }

  return moment(date).format(format)
}
