import debounce from 'lodash/debounce'
import {OptionData} from 'rc-select/lib/interface'
import React, {useEffect, useState} from 'react'

import {manufacturerSearchRequest} from '../../../requests/manufacturers/ManufacturerRequest'
import {manufacturerSearchType} from '../../models/manufacturers/ManufacturerFilterModel'

export const useManufacturerFilter = (debounceTime = 500, dataType?: 'value' | 'item') => {
  const [manufacturerIds, setManufacturerIds] = useState<number[]>([])
  const [manufacturers, setManufacturers] = useState<OptionData[]>([])
  const [options, setOptions] = useState<OptionData[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const fetchRef = React.useRef(0)

  const handleSearch = React.useMemo(() => {
    const loadOptions = (search: string, initial: boolean = false) => {
      if (search.length < 2 && !initial) {
        return
      }

      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      manufacturerSearchRequest(search).then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }

        const manufacturers = res.data.data.map((manufacturer: manufacturerSearchType) => {
          return {value: manufacturer.manufacturer_id, label: manufacturer.name}
        })

        setOptions(manufacturers)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTime)
  }, [])

  function handleOnChange(payload: Array<OptionData>) {
    if (!payload) {
      return
    }

    if (dataType === 'item') {
      setManufacturers(payload)
    }

    const manufacturerIds = payload.map((data) => data.value) as number[]
    setManufacturerIds(manufacturerIds)
  }

  useEffect(() => {
    handleSearch('', true)
  }, [])

  return {
    manufacturers,
    manufacturerIds,
    options,
    fetching,
    handleSearch,
    handleOnChange,
  }
}
