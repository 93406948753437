import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
export const DEALS_URL = `${API_URL}/deals`

export function getDealsRequest() {
  return axios.get(DEALS_URL)
}

export function getDealRequest(dealId: number, params: object = {}) {
  return axios.get(`${DEALS_URL}/${dealId}`, {params})
}

export function createDealRequest(body: any, params: object = {}) {
  return axios.post(DEALS_URL, body, {params})
}

export function updateDealRequest(dealId: number, body: any, params: object = {}) {
  return axios.put(`${DEALS_URL}/${dealId}`, body, {params})
}

export function deleteDealRequest(dealId: number) {
  return axios.delete(`${DEALS_URL}/${dealId}`)
}

export function multiDeleteDealRequest(dealIds: number[]) {
  return axios.delete(`${DEALS_URL}/multi`, {data: {deal_ids: dealIds}})
}
