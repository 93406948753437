import {message} from 'antd'
import {FormikValues} from 'formik'
import {FC, createContext, useEffect, useRef, useState} from 'react'

import {
  sortingWeightOrderType,
  sortingWeightWeightType,
} from '../../components/models/Sorting/SortingModel'
import {errorMessage} from '../../helpers/ErrorHelper'
import {
  createSortingWeightRequest,
  getSortingTypesRequest,
  getSortingWeightRequest,
  getWeightTypesRequest,
  updateSortingWeightRequest,
} from '../../requests/sorting/SortingWeightRequest'

const initialOrderTypes: sortingWeightOrderType[] = [
  {
    label: '',
    value: '',
  },
]

const initialWeightTypes: sortingWeightWeightType[] = [
  {
    label: '',
    value: '',
  },
]

interface ISortingWeightContext {
  setFormikInitialValues?: any
  orderTypes: sortingWeightOrderType[]
  weightTypes: sortingWeightWeightType[]
  updateSortingWeight: (sortingType: string, weightType: string, value: number) => void
  sortingWeightList: any[]
  visibleCreateSortingWeightModal: boolean
  setVisibleCreateSortingWeightModal: (visible: boolean) => void
  createSortingWeight: (values: FormikValues) => void
}

export const SortingWeightContext = createContext<ISortingWeightContext>({
  setFormikInitialValues: () => {},
  orderTypes: [],
  weightTypes: [],
  updateSortingWeight: () => {},
  sortingWeightList: [],
  visibleCreateSortingWeightModal: false,
  setVisibleCreateSortingWeightModal: () => {},
  createSortingWeight: () => {},
})

export const SortingWeightProvider: FC = ({children}) => {
  const [orderTypes, setOrderTypes] = useState<sortingWeightOrderType[]>(initialOrderTypes)
  const [weightTypes, setWeightTypes] = useState<sortingWeightWeightType[]>(initialWeightTypes)
  const [sortingWeightList, setSortingWeightList] = useState<any[]>([])

  const [visibleCreateSortingWeightModal, setVisibleCreateSortingWeightModal] = useState(false)

  const fetchRef = useRef(0)

  useEffect(() => {
    fetchRef.current += 1
    if (fetchRef.current === 1) {
      getOrderTypes()
      getWeightTypes()
      getSortingWeight()
    }
  }, [])

  const getOrderTypes = () => {
    getSortingTypesRequest()
      .then((res) => {
        if (!res.data) {
          return
        }
        setOrderTypes(res.data.data)
      })
      .catch((err) => {
        message.error('Sıralama tipleri getirilirken bir hata oluştu')
        message.error(err.response.data.message)
      })
  }

  const getWeightTypes = () => {
    getWeightTypesRequest()
      .then((res) => {
        if (!res.data) {
          return
        }
        setWeightTypes(res.data.data)
      })
      .catch((err) => {
        message.error('Ağırlık tipleri getirilirken bir hata oluştu')
        message.error(err.response.data.message)
      })
  }

  const updateSortingWeight = (sortingType: string, weightType: string, value: number) => {
    message.info('Sıralama ağırlığı güncelleniyor..')
    updateSortingWeightRequest(sortingType, weightType, value)
      .then((res) => {
        message.success('Sıralama ağırlığı güncellendi')
        getSortingWeight()
      })
      .catch((err) => {
        message.error('Sıralama ağırlığı güncellenirken bir hata oluştu')
      })
  }

  const getSortingWeight = () => {
    getSortingWeightRequest()
      .then((res) => {
        if (!res.data) {
          return
        }
        setSortingWeightList(res.data.data)
      })
      .catch((err) => {
        message.error('Sıralama ağırlığı getirilirken bir hata oluştu')
        message.error(err.response.data.message)
      })
  }

  const createSortingWeight = (values: FormikValues) => {
    message.loading({
      content: 'Sıralama ağırlığı ekleniyor..',
    })

    createSortingWeightRequest(values.sorting_type)
      .then((res) => {
        message.success({content: 'Sıralama ağırlığı kaydedildi.'})

        setVisibleCreateSortingWeightModal(false)

        getOrderTypes()
        getWeightTypes()
        getSortingWeight()
      })
      .catch((err) => {
        errorMessage(err)
      })
  }

  return (
    <>
      <SortingWeightContext.Provider
        value={{
          orderTypes,
          weightTypes,
          updateSortingWeight,
          sortingWeightList,
          visibleCreateSortingWeightModal,
          setVisibleCreateSortingWeightModal,
          createSortingWeight,
        }}
      >
        {children}
      </SortingWeightContext.Provider>
    </>
  )
}
