import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, Tooltip, message} from 'antd'
import React, {FC, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  getCategoryFilterListExportFrontendUrl,
  getCategoryListProductsExportFrontendUrl,
  multiDeleteCategoriesRequest,
} from '../../../../requests/categories/CategoryRequest'
import {getCampaignSalesProductsExportFrontendUrl} from '../../../../requests/categories/products/exports/CategoryProductExportRequest'
import {deleteCategory, setSelectCategories} from '../../../../store/Category/CategoryAction'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {CategoryList} from './CategoryList'

const {confirm} = Modal

const CategoryListWrapperRightField: FC<any> = ({filterParams}) => {
  const {selectedCategories} = useSelector((state: any) => state.category)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleOnClick = () => {
    confirm({
      title: `${selectedCategories.length} kategori silenecek.`,
      icon: <ExclamationCircleOutlined />,
      content: 'Silme işlemi geri alınamaz emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        const categoryIds = selectedCategories.map((category: any) => {
          return category.id
        })
        multiDeleteCategoriesRequest(categoryIds)
          .then(() => {
            categoryIds.forEach((categoryId: number) => dispatch(deleteCategory(categoryId)))
            dispatch(setSelectCategories([]))
            message.success('Kategori başarıyla silindi')
          })
          .catch(() => {
            message.error('Kategori silinirken bilinmeyen bir hata oluştu lütfen tekrar deneyiniz.')
          })
      },
    })
  }

  return (
    <>
      <div className='d-flex align-items-center py-1'>
        {filterParams.type === 'campaign' && (
          <div className='me-4'>
            <Tooltip title={'Kampanya satışlarını dışarı aktarır.'}>
              <KrcButton
                type={'default'}
                onClick={() => {
                  const categoryIds = selectedCategories.map((category: any) => {
                    return category.id
                  })

                  if (categoryIds.length === 0) {
                    message.info('Lütfen kampanya seçiniz')
                    return
                  }

                  navigate(getCampaignSalesProductsExportFrontendUrl({categoryIds: categoryIds}))
                }}
              >
                Satılan Ürünleri Dışarı Aktar
              </KrcButton>
            </Tooltip>
          </div>
        )}
        <div className='me-4'>
          <KrcButton
            type={'primary'}
            onClick={(e) => {
              navigate('/categories/details/')
            }}
          >
            Kategori Oluştur
          </KrcButton>
        </div>
        <div className='me-4'>
          <KrcButton
            type={'default'}
            onClick={(e) => {
              const categoryIds = selectedCategories.map((category: any) => {
                return category.id
              })
              navigate(
                getCategoryListProductsExportFrontendUrl({
                  ...filterParams,
                  category_ids: categoryIds,
                })
              )
            }}
          >
            Ürünleri Dışarı Aktar
          </KrcButton>
        </div>
        <div className='me-4'>
          <KrcButton
            type={'default'}
            onClick={(e) => {
              const categoryIds = selectedCategories.map((category: any) => {
                return category.id
              })
              navigate(
                getCategoryFilterListExportFrontendUrl({...filterParams, category_ids: categoryIds})
              )
            }}
          >
            Dışarı Aktar
          </KrcButton>
        </div>
        <div className='me-4'>
          <KrcButton
            type={'default'}
            onClick={(e) => {
              e.preventDefault()
              handleOnClick()
            }}
          >
            Seçilenleri Sil
          </KrcButton>
        </div>
      </div>
    </>
  )
}

export const CategoryListWrapper: FC = () => {
  const [filterParams, setFilterParams] = useState({})

  return (
    <>
      <Toolbar rightField={<CategoryListWrapperRightField filterParams={filterParams} />} />
      <PageTitle breadcrumbs={[]}>Kategori</PageTitle>
      <CategoryList onChangeFilterParams={setFilterParams} />
    </>
  )
}
