import {FC, useContext} from 'react'

import {IBMContext} from '../../../../providers/ibm/IBMProvider'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const IBMGroupList: FC = () => {
  const {columns, IBMGroupData, meta, changePage, changeLimit, handleOnFilter} =
    useContext(IBMContext)

  return (
    <>
      <KrcTable
        columns={columns}
        data={IBMGroupData}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            changePage(page)
          },
          onChangeLimit: (limit) => {
            changeLimit(limit)
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: (search, id) => {
            handleOnFilter(search, id)
          },
        }}
      />
    </>
  )
}
