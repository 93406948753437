import {PlusCircleOutlined} from '@ant-design/icons'
import Title from 'antd/lib/typography/Title'
import React, {FC, useContext} from 'react'

import {ProductAvailableContext} from '../../../../providers/products/available/ProductAvailableProvider'
import {requestDateFormat} from '../../../../service/DateService'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcDatePicker} from '../../../atoms/forms/KrcDatePicker'
import {ProductSelection} from '../../../layout/filters/ProductSelection'

export const ProductAvailable: FC = () => {
  const {clear, setSelectedProducts, setDateAvailable, updateDateAvailable} =
    useContext(ProductAvailableContext)

  return (
    <>
      <div className={'d-flex row'}>
        <div className={'col-9'}>
          <ProductSelection
            clear={clear}
            headerProps={{
              titleLevel: 5,
            }}
            onSelectedItems={(data) => {
              if (!data) {
                setSelectedProducts([])
                return
              }
              setSelectedProducts(data)
            }}
            transferProps={{
              button: {
                title: '',
                isActive: false,
              },
            }}
            withFullPrice={true}
            withBadge={true}
            withAvailable={true}
          />
        </div>
        <div className={'col-3'} style={{width: 'max-content'}}>
          <div className={'d-flex flex-column'}>
            <div className={'d-flex'}>
              <Title className={'required'} level={5}>
                Geçerlilik Tarihi
              </Title>
            </div>
            <div className={'d-flex'}>
              <KrcDatePicker
                placeholder={'Geçerlilik Tarihi'}
                format={'DD.MM.YYYY'}
                size={'middle'}
                allowClear
                onChange={(date) => {
                  if (!date) {
                    setDateAvailable(undefined)
                    return
                  }

                  setDateAvailable(requestDateFormat(date, 'YYYY-MM-DD') ?? '')
                }}
              />
              <KrcButton
                className={'d-flex align-items-center justify-content-center ml-3'}
                type={'primary'}
                onClick={updateDateAvailable}
                icon={<PlusCircleOutlined style={{lineHeight: 0}} />}
              >
                <span>Tamamla</span>
              </KrcButton>
            </div>
          </div>
          <div className={'d-flex justify-content-between mt-3'}>
            <div></div>
          </div>
        </div>
      </div>
    </>
  )
}
