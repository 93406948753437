import React, {useContext, useState} from 'react'

import {OrderContext} from '../../../../providers/orders/OrderProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {CustomerModalWrapper} from '../../../modals/orders/CustomerModal'

const Header: React.FC = () => {
  const [modalId, setModalId] = useState<string | null>(null)
  const {order} = useContext(OrderContext)

  const showModal = (id: string) => {
    setModalId(id)
  }

  const handleCancel = () => {
    setModalId(null)
  }

  const buttons = [
    /* {
      id: 'addressModal',
      label: 'Adres Güncelle',
      modal: <AddressModalWrapper onHide={handleCancel} />,
    }, */
    {
      id: 'customerModal',
      label: 'Müşteri',
      modal: <CustomerModalWrapper onHide={handleCancel} />,
      disabled: order?.subChannel === 'MP',
    },
  ]

  return (
    <>
      {modalId && buttons.find((button) => button.id === modalId)?.modal}

      <div style={{overflowX: 'scroll', display: 'flex'}}>
        {buttons.map((button) => (
          <KrcButton
            key={button.id}
            size={'middle'}
            className={'mr-3'}
            onClick={() => showModal(button.id)}
            disabled={button.disabled}
          >
            {button.label}
          </KrcButton>
        ))}
      </div>
    </>
  )
}

export default Header
