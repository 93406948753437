import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const ROLE_URL = `${API_URL}/roles`

export function fetchRolesRequest(filter: any, {page, limit}: paginationMetaType) {
  return axios.get(`${ROLE_URL}`, {
    params: {
      page,
      limit,
      ...filter,
    },
  })
}

export function createRoleRequest(data: any) {
  return axios.post(`${ROLE_URL}`, data)
}

export function deleteRoleRequest(roleId: number) {
  return axios.delete(`${ROLE_URL}/${roleId}`)
}
