import debounce from 'lodash/debounce'
import {useContext, useEffect, useState} from 'react'

import {ELEMENT_CSS, ELEMENT_TWIG} from '../../../enums/LandingElementTypeEnum'
import {LandingContext} from '../../../providers/LandingProvider'
import {editorType} from '../../models/landings/LandingModel'

export const useLandingStatic = () => {
  const {landing, handleOnChangeElement} = useContext(LandingContext)
  const [twigEditor, setTwigEditor] = useState<editorType>({
    editor_id: 1,
    element_type: ELEMENT_TWIG,
    element_data:
      '{% extends "@default/base.html" %}' +
      '\n\n{% block content %}\n\n{% endblock %}\n\n{% block footer %}\n' +
      '<script>\n\n' +
      '</script>\n' +
      '{% endblock %}',
    element_id: undefined,
  })
  const [cssEditor, setCssEditor] = useState<editorType>({
    editor_id: 2,
    element_type: ELEMENT_CSS,
    element_data: '',
    element_id: undefined,
  })

  useEffect(() => {
    if (!landing) {
      return
    }

    landing.elements?.forEach((element) => {
      if (element.element_type === ELEMENT_TWIG) {
        setTwigEditor((data) => ({
          ...data,
          element_data: element.element_data,
          element_id: element.element_id,
        }))
      }

      if (element.element_type === ELEMENT_CSS) {
        setCssEditor((data) => ({
          ...data,
          element_data: element.element_data,
          element_id: element.element_id,
        }))
      }
    })
  }, [landing])

  const handleOnChangeEditor = (value: string, type: string) => {
    if (type === ELEMENT_TWIG) {
      setTwigEditor((data) => ({...data, element_data: value}))
      handleOnChangeElement({...twigEditor, element_data: value})
    }

    if (type === ELEMENT_CSS) {
      setCssEditor((data) => ({...data, element_data: value}))
      handleOnChangeElement({...cssEditor, element_data: value})
    }

    return debounce(handleOnChangeEditor, 500)
  }

  return {twigEditor, setTwigEditor, cssEditor, setCssEditor, handleOnChangeEditor}
}
