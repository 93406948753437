import React, {FC} from 'react'

import {KrcTabs} from '../../../../molecules/KrcTabs'
import {CategoryProductSortingGridView} from './views/CategoryProductSortingGridView'
import {CategoryProductSortingListView} from './views/CategoryProductSortingListView'

export const CategoryProductSortingView: FC = () => {
  return (
    <>
      <KrcTabs
        isActiveKey={false}
        tabsClassName={'mb-1'}
        tabsProps={{defaultActiveKey: 'list-view'}}
        tabProps={[
          {
            eventKey: 'list-view',
            title: 'Liste Görünümü',
            children: <CategoryProductSortingListView />,
          },
          {
            eventKey: 'grid-view',
            title: 'Grid Görünümü',
            children: <CategoryProductSortingGridView />,
          },
        ]}
      />
    </>
  )
}
