import {useState} from 'react'

import {checkExportRequest} from '../../../requests/exports/ExportRequest'

export const useExport = () => {
  const [downloadExcelUrl, setDownloadExcelUrl] = useState<string | null>(null)

  const checkExport = (fileName: string, repeatMs: number = 3000) => {
    checkExportRequest(fileName).then((res) => {
      if (!res.data.data.is_exists) {
        return setTimeout(() => {
          return checkExport(fileName)
        }, repeatMs)
      }

      setDownloadExcelUrl(res.data.data.download_excel_url)
    })
  }

  return {
    checkExport,
    downloadExcelUrl,
  }
}
