import React, {FC} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {FaqCreateForm} from './FaqCreateForm'

const ToolBar: FC = () => {
  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'></div>
      </Toolbar>
      <PageTitle>Yeni Sıkça Sorulan Soru Oluştur</PageTitle>
    </>
  )
}

export const FaqCreateFormWrapper: FC = () => {
  return (
    <>
      <ToolBar />
      <FaqCreateForm />
    </>
  )
}
