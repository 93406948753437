import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {Alert, Empty, Image, Switch, message} from 'antd'
import {Field, Form, Formik} from 'formik'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'

import {SPECIAL_GROUP_INFORMATION} from '../../../../../enums/IBMCampaignTabTypeEnums'
import {IBMCampaignSpecialGroupContext} from '../../../../../providers/ibm/IBMCampaignSpecialGroupProvider'
import {
  fetchIBMCampaignGeneralInformation,
  updateIBMCampaignGeneralInformation,
} from '../../../../../requests/ibm/IBMCampaignRequest'
import {FileManagerModal} from '../../../../../service/FileManager/FileManagerModal'
import {KrcButton} from '../../../../atoms/buttons/KrcButton'
import {KrcRichTextEditorWithTable} from '../../../../atoms/forms/KrcRichTextEditorWithTable'
import {IBMCampaignSpecialGroupInformationType} from '../../../../models/ibm/IBMCampaignDetail'
import {PacketDiscountType} from '../../../../models/ibm/IBMCampaignModel'
import {Loader} from '../../../../molecules/Loader'
import {KrcFormikField} from '../../../../molecules/forms/KrcFormikField'

interface SpecialGroupsInformationTabProps {
  activeCampaignId: number
}

export const SpecialGroupsInformationTab: FC<SpecialGroupsInformationTabProps> = ({
  activeCampaignId,
}) => {
  const DEFAULT_IMAGE_PATH = 'https://cdn.karaca.com/image/'
  const [isLoading, setIsLoading] = useState(false)
  const [campaignSpecialGroupInformation, setCampaignSpecialGroupInformation] =
    useState<IBMCampaignSpecialGroupInformationType | null>(null)
  const [campaignDetailImage, setCampaignDetailImage] = useState<string>('')
  const [isWizardActive, setIsWizardActive] = useState<boolean>(false)
  const [wizardDescription, setWizardDescription] = useState<string>('')
  const [packetText, setPacketText] = useState<string>('')
  const [isChanged, setIsChanged] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [openImage, setOpenImage] = useState<number>(0)

  const {setPageType} = useContext(IBMCampaignSpecialGroupContext)

  const initialRef = useRef(0)

  useEffect(() => {
    setPageType(SPECIAL_GROUP_INFORMATION)
    if (initialRef.current === 0) {
      setIsLoading(true)
      fetchIBMCampaignGeneralInformation(activeCampaignId, 0, 1)
        .then((response) => {
          setCampaignSpecialGroupInformation({
            ...response.data.data,
            wizard: response.data.data.wizard ? 1 : 0,
            discount_type: response.data.data.discount_type
              ? response.data.data.discount_type.toString()
              : '',
            campaign_first_price: response.data.data.campaign_first_price
              ? response.data.data.campaign_first_price.toString()
              : '',
            campaign_special_price: response.data.data.campaign_special_price
              ? response.data.data.campaign_special_price.toString()
              : '',
            wizard_description: response.data.data.wizard_description
              ? response.data.data.wizard_description
              : '',
          })
          setCampaignDetailImage(response.data.data.campaign_detail_page_image)
          setWizardDescription(response.data.data.wizard_description)
          setIsWizardActive(response.data.data.wizard)
          setPacketText(response.data.data.packet_text)
          initialRef.current = 1
        })
        .catch((error) => {
          message.error(error.response.data.message)
          initialRef.current = 1
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [])

  const handleOnChangeWizardSwitch = (checked: boolean) => {
    if (Boolean(campaignSpecialGroupInformation?.wizard) === checked) {
      setIsChanged(false)
    } else {
      setIsChanged(true)
    }
    setIsWizardActive(checked)
  }

  const handleSubmit = (values: any, {setSubmitting, resetForm}: any) => {
    const formikValues = {
      ...values,
      wizard: isWizardActive ? 1 : 0,
      wizard_description: wizardDescription,
      campaign_first_price:
        values.discount_type === PacketDiscountType.PERCENT ? null : values.campaign_first_price,
      campaign_detail_page_image: campaignDetailImage,
      packet_text: packetText ? packetText : '',
    }

    if (formikValues.discount_type === PacketDiscountType.PERCENT) {
      if (formikValues.campaign_special_price > 100) {
        message.error('İndirim yüzdesi değeri 100 üzerinde olamaz.')
        return
      }
    }

    updateIBMCampaignGeneralInformation(activeCampaignId, formikValues, 0, 1)
      .then((response) => {
        setIsSubmit(true)
        setIsChanged(false)
        message.success('Kampanya bilgileri başarıyla güncellendi.')
        setTimeout(() => {
          setIsSubmit(false)
          resetForm({values: {...formikValues}})
        }, 500)
      })
      .catch((error) => {
        message.error('Kampanya bilgileri güncellenirken bir hata oluştu.')
        message.error(error.response.data.message, 3)
      })
  }

  return (
    <>
      {isLoading || !campaignSpecialGroupInformation ? (
        <Loader />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={campaignSpecialGroupInformation}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            return (
              <Form className={'form mt-6'}>
                {(formik.dirty || isChanged) && !isSubmit ? (
                  <Alert
                    message='Değişiklik yaptığınızı fark ettik. Sağ alttan değişiklikleri kaydetmeyi unutma!'
                    type='warning'
                    showIcon
                    closable
                    className={'mb-2'}
                  />
                ) : null}
                <div className={'row mt-2'}>
                  <div className={'col-6'}>
                    <div className={'col-8 mb-4'}>
                      <KrcFormikField
                        className={'w-full'}
                        type={'number'}
                        name={'min_product'}
                        required
                        labelTitle={'Min Ürün Seçimi'}
                      />
                    </div>
                    <div className={'row mb-4'}>
                      <div className={'col-4'}>
                        <KrcFormikField
                          className={'mb-2 col-md-12'}
                          name={'discount_type'}
                          required
                          labelTitle={'İndirim Türü'}
                        >
                          <Field
                            as='select'
                            name='discount_type'
                            className='form-select'
                            aria-label={'Seçiniz'}
                          >
                            <option value='' disabled={true}>
                              Seçiniz
                            </option>
                            <option value={PacketDiscountType.PRICE}>Fiyat</option>
                            <option value={PacketDiscountType.PERCENT}>Yüzde</option>
                          </Field>
                        </KrcFormikField>
                      </div>
                      <div className={'col-8'}>
                        {formik.values.discount_type === PacketDiscountType.PRICE && (
                          <div className={'row'}>
                            <div className={'col-5'}>
                              <KrcFormikField
                                className={'w-full'}
                                type={'text'}
                                required
                                name={'campaign_first_price'}
                                labelTitle={'Kampanya İlk Fiyatı'}
                              />
                            </div>
                            <div className={'col-5'}>
                              <KrcFormikField
                                className={'w-full'}
                                type={'text'}
                                required
                                name={'campaign_special_price'}
                                labelTitle={'Kampanya Fiyatı'}
                              />
                            </div>
                          </div>
                        )}

                        {formik.values.discount_type === PacketDiscountType.PERCENT && (
                          <div className={'col-6'}>
                            <KrcFormikField
                              className={'w-full'}
                              type={'text'}
                              required
                              name={'campaign_special_price'}
                              labelTitle={'İndirim Yüzdesi'}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={'col-6'}>
                    <div className={'d-flex flex-column'}>
                      <div
                        className={'d-flex flex-column gap-2 px-6 py-2 rounded w-500px'}
                        style={{backgroundColor: '#F3F3F3'}}
                      >
                        <div className={'d-flex justify-content-between align-items-center'}>
                          <span className={'lh-0'}>
                            <b>Kampanya Detay Görseli</b>
                          </span>
                          <FileManagerModal
                            tempUploadDir={'/image/2024/ibm/detail_image'}
                            onSelected={(link) => {
                              if (link) {
                                setIsChanged(true)
                              }
                              setCampaignDetailImage(link ? link : '')
                            }}
                            defaultImage={DEFAULT_IMAGE_PATH + campaignDetailImage}
                            open={openImage}
                            showImage={false}
                          >
                            <KrcButton
                              type={'text'}
                              className={'custom-hover'}
                              icon={<EditOutlined />}
                              onClick={() => {
                                setOpenImage((open) => open + 1)
                              }}
                            />
                            <KrcButton
                              type={'text'}
                              className={'custom-hover'}
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                setIsChanged(true)
                                setCampaignDetailImage('')
                              }}
                            />
                          </FileManagerModal>
                        </div>
                        {campaignDetailImage ? (
                          <Image
                            height={140}
                            className={'rounded-2'}
                            style={{objectFit: 'contain'}}
                            src={DEFAULT_IMAGE_PATH + campaignDetailImage}
                            preview={{
                              maskClassName: 'rounded-2',
                            }}
                          />
                        ) : (
                          <>
                            <Empty
                              image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                              imageStyle={{
                                height: 60,
                              }}
                              description={<span>Kampanya Sayfa Görseli Bulunamadı.</span>}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'row mt-3'}>
                  <div className={'col-10'}>
                    <KrcFormikField
                      className={'w-full'}
                      type={'text'}
                      name={'packet_text'}
                      labelTitle={'Paket Metni'}
                    >
                      <KrcRichTextEditorWithTable
                        height={300}
                        value={packetText}
                        onChange={(value) => {
                          if (
                            campaignSpecialGroupInformation?.packet_text === null &&
                            value === ''
                          ) {
                            setIsChanged(false)
                            setPacketText(value)
                          } else if (
                            campaignSpecialGroupInformation?.packet_text !==
                            value.replace(/(<([^>]+)>)/gi, '')
                          ) {
                            setIsChanged(true)
                            setPacketText(value)
                          } else {
                            setIsChanged(false)
                            setPacketText(value)
                          }
                        }}
                      />
                    </KrcFormikField>
                  </div>
                </div>
                <div className={'row mt-3'}>
                  <div className={'col-11'}>
                    <KrcFormikField
                      className={'w-full'}
                      type={'text'}
                      name={'wizard_description'}
                      labelTitle={'Wizard Açıklaması / Durumu'}
                    >
                      <div className={'row'}>
                        <div className={'col-11'}>
                          <KrcRichTextEditorWithTable
                            height={300}
                            value={wizardDescription}
                            onChange={(value) => {
                              if (
                                campaignSpecialGroupInformation?.wizard_description === null &&
                                value === ''
                              ) {
                                setIsChanged(false)
                                setWizardDescription(value)
                              } else if (
                                campaignSpecialGroupInformation?.wizard_description !==
                                value.replace(/(<([^>]+)>)/gi, '')
                              ) {
                                setIsChanged(true)
                                setWizardDescription(value)
                              } else {
                                setIsChanged(false)
                                setWizardDescription(value)
                              }
                            }}
                          />
                        </div>
                        <div
                          className={
                            'col-1 d-flex flex-row align-items-center justify-content-center'
                          }
                        >
                          <Switch
                            defaultChecked={campaignSpecialGroupInformation?.wizard}
                            onChange={handleOnChangeWizardSwitch}
                          />
                        </div>
                      </div>
                    </KrcFormikField>
                  </div>
                </div>
                <div className={'d-flex justify-content-end mt-8'}>
                  <KrcButton
                    onClick={() => {
                      formik.handleSubmit()
                    }}
                    type={'text'}
                    size={'large'}
                    className={`custom-hover w-200px text-white button-bg-purple 0 ${
                      (formik.dirty || isChanged) && !isSubmit
                        ? 'border border-2 border-danger'
                        : ''
                    } `}
                  >
                    Kaydet
                  </KrcButton>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}
