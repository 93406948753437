import {message} from 'antd'
import moment from 'moment'
import {FC, createContext, useEffect, useState} from 'react'

import {apiQueueType, willBeUpdatedApiQueueType} from '../components/models/ApiQueueModel'
import {getApiQueuesRequest, updateApiQueueRequest} from '../requests/queues/ApiQueueRequest'

const defaultWillBeQueue = {
  nextjobtime: null,
  jobinterval: null,
}

interface IProductNewPriceContext {
  save: number
  setSave: (data: number) => void
  apiQueue: apiQueueType | null
  apiQueues: apiQueueType[]
  setApiQueue: (data: apiQueueType | null) => void
  setApiQueueWithApiQueueId: (apiQueueId: number) => void
  getApiQueues: () => void
  willBeUpdatedQueue: willBeUpdatedApiQueueType
  updateWillBeUpdatedQueue: (data: object) => void
  willBeUpdatedDateTime: string
  updateApiQueue: () => void
}

export const ProductNewPriceContext = createContext<IProductNewPriceContext>({
  save: 0,
  setSave: () => {},
  apiQueue: null,
  apiQueues: [],
  willBeUpdatedDateTime: '',
  setApiQueue: () => {},
  setApiQueueWithApiQueueId: () => {},
  willBeUpdatedQueue: defaultWillBeQueue,
  updateWillBeUpdatedQueue: () => {},
  updateApiQueue: () => {},
  getApiQueues: () => {},
})

export const ProductNewPriceProvider: FC = ({children}) => {
  const [save, setSave] = useState(0)
  const [apiQueue, setApiQueue] = useState<apiQueueType | null>(null)
  const [apiQueues, setApiQueues] = useState<apiQueueType[]>([])
  const [willBeUpdatedQueue, setWillBeUpdatedQueue] =
    useState<willBeUpdatedApiQueueType>(defaultWillBeQueue)
  const [willBeUpdatedDateTime, setWillBeUpdatedDateTime] = useState<string>('')

  const getApiQueues = () => {
    getApiQueuesRequest([
      'Hybris_getPriceProductUpdate',
      'Hybris_getPriceProductUpdate_Priority_High',
    ]).then((res) => {
      if (res.data.data.length === 0) {
        return
      }

      setApiQueues(res.data.data)

      setApiQueueWithApiQueueId(res.data.data[0].id)
    })
  }

  const setApiQueueWithApiQueueId = (apiQueueId: number) => {
    if (apiQueues.length === 0) {
      return
    }

    const apiQueueItem = apiQueues.find((apiQueueItem) => {
      return apiQueueItem.id === apiQueueId
    })

    if (!apiQueueItem) {
      return
    }

    setWillBeUpdatedQueue({
      nextjobtime: apiQueueItem.nextjobtime,
      jobinterval: apiQueueItem.jobinterval,
    })

    setApiQueue(apiQueueItem)
  }

  const updateWillBeUpdatedQueue = (item: object) => {
    setWillBeUpdatedQueue((data) => ({...data, ...item}))
  }

  useEffect(() => {
    if (!apiQueue) {
      return
    }

    setWillBeUpdatedDateTime(
      moment(apiQueue.lastjobtime)
        .add(willBeUpdatedQueue.jobinterval, 'seconds')
        .format('DD.MM.YYYY HH:mm')
    )
  }, [willBeUpdatedQueue, apiQueue])

  const updateApiQueue = () => {
    if (!apiQueue) {
      return
    }

    const key = 'update-api-queue'

    message.loading({
      content: 'Queue zamanlaması güncelleniyor...',
      key,
      duration: 0,
    })

    updateApiQueueRequest(apiQueue.id, willBeUpdatedQueue)
      .then(() => {
        message.success({
          content: 'Queue zamanlaması güncellendi.',
          key,
        })
      })
      .catch(() => {
        message.error({
          content: 'Queue zamanlaması güncellenemedi.',
          key,
        })
      })
  }

  return (
    <ProductNewPriceContext.Provider
      value={{
        save,
        setSave,
        apiQueue,
        setApiQueue,
        setApiQueueWithApiQueueId,
        willBeUpdatedQueue,
        updateWillBeUpdatedQueue,
        willBeUpdatedDateTime,
        updateApiQueue,
        getApiQueues,
        apiQueues,
      }}
    >
      {children}
    </ProductNewPriceContext.Provider>
  )
}
