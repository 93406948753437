import {isEmpty, toNumber} from 'lodash'
import {OptionData} from 'rc-select/lib/interface'
import React, {FC, createContext, useContext, useEffect, useState} from 'react'

import {attributeType} from '../../../components/models/attributes/attributeModel'
import {
  CategoryGhostFilterKeyType,
  CategoryGhostFilterType,
  CategoryGhostFilterValueType,
  ghostOptionsType,
} from '../../../components/models/categories/CategoryGhostModel'
import {krcSelectOptions} from '../../../components/models/components/KrcSelectModel'
import {CategoryFilter} from '../../../components/molecules/filters/CategoryFilter'
import {CategoryTypeFilter} from '../../../components/molecules/filters/CategoryTypeFilter'
import {IBMCampaignSpecialFilter} from '../../../components/molecules/filters/IBMCampaignSpecialFilter'
import {ManufacturerFilter} from '../../../components/molecules/filters/ManufacturerFilter'
import {MinMaxFilter} from '../../../components/molecules/filters/MinMaxFilter'
import {ProductAttributeFilter} from '../../../components/molecules/filters/ProductAttributeFilter'
import {IMinMaxDataType} from '../../../modules/partials/components/filter/models/FilterModel'
import {getAttributeRequest} from '../../../requests/attributes/AttributeRequest'
import {CategoryDetailContext} from '../detail/CategoryDetailProvider'

interface ICategoryGhostFilterContext {
  filters: CategoryGhostFilterType[]
  setFilters: React.Dispatch<React.SetStateAction<CategoryGhostFilterType[]>>
  updateFilter: (
    key: CategoryGhostFilterKeyType,
    value: CategoryGhostFilterValueType,
    oldKey?: CategoryGhostFilterKeyType
  ) => void
  options: ghostOptionsType[]
  addFilter: (item: CategoryGhostFilterType) => void
  deleteFilter: (key: CategoryGhostFilterKeyType) => void
}

export const CategoryGhostFilterContext = createContext<ICategoryGhostFilterContext>({
  filters: [],
  setFilters: () => {},
  updateFilter: () => {},
  options: [],
  addFilter: () => {},
  deleteFilter: () => {},
})

export const CategoryGhostFilterProvider: FC = ({children}) => {
  const [filters, setFilters] = useState<CategoryGhostFilterType[]>([{key: null, value: null}])
  const [attributes, setAttributes] = useState<attributeType[]>([])
  const {categoryType, updateCategoryType} = useContext(CategoryDetailContext)

  const initialAddOrUpdate = (
    key: CategoryGhostFilterKeyType,
    value: CategoryGhostFilterValueType,
    krcCategoryType?: krcSelectOptions
  ) => {
    if (key === 'categories') {
      if (value) {
        updateOptionsElement(key, {
          defaultValue: value,
          category_type: krcCategoryType ?? categoryType,
        })
        return
      }

      updateOptionsElement(key, {category_type: krcCategoryType ?? categoryType})
      return
    }

    if (key === 'price' && value) {
      updateOptionsElement(key, {
        minMaxDefaultValue: {
          min: (value as IMinMaxDataType).min ?? 0,
          max: (value as IMinMaxDataType).max ?? 0,
        },
      })
      return
    }

    if (!value) {
      updateOptionsElement(key, {})
      return
    }

    updateOptionsElement(key, {defaultValue: value})
  }

  const updateFilter = (
    key: CategoryGhostFilterKeyType,
    value: CategoryGhostFilterValueType,
    oldKey?: CategoryGhostFilterKeyType,
    categoryType?: krcSelectOptions
  ) => {
    setFilters((filters) => {
      initialAddOrUpdate(key, value, categoryType)

      if (oldKey) {
        const index = filters.findIndex((item) => item.key === oldKey)
        if (index !== -1) {
          filters[index]['key'] = key
          filters[index]['value'] = value
        }

        return [...filters]
      }

      const index = filters.findIndex((item) => item.key === key)
      if (index !== -1) {
        filters[index]['value'] = value

        return [...filters]
      }

      const emptyIndex = filters.findIndex((item) => item.key === null)

      if (emptyIndex !== -1) {
        filters[emptyIndex]['key'] = key
        filters[emptyIndex]['value'] = value
      }

      return [...filters]
    })
  }

  const addFilter = (item: CategoryGhostFilterType) => {
    initialAddOrUpdate(item.key, item.value)

    if (filters.some((filter) => filter.key === item.key)) {
      updateFilter(item.key, item.value)
      return
    }

    setFilters((filters) => {
      const emptyIndex = filters.findIndex((item) => item.key === null)

      if (emptyIndex !== -1) {
        filters[emptyIndex] = item

        return [...filters]
      }

      return [...filters, item]
    })
  }

  const deleteFilter = (key: CategoryGhostFilterKeyType) => {
    setFilters((filters) => filters.filter((item) => item.key !== key))
  }

  const getProductAttributeFilter = (attributeId: number, props?: any) => {
    return (
      <ProductAttributeFilter
        placeholder={'Lütfen Seçiniz'}
        showArrow
        attributeId={attributeId}
        style={{width: 250}}
        initialLoad={true}
        onChanged={(data) => {
          if (isEmpty(data) || !data) {
            return
          }

          updateFilter(
            `attribute_${attributeId}`,
            data.map((item: OptionData) => ({
              value: `attribute_${item.label}_${attributeId}`,
              label: item.label,
            }))
          )
        }}
        {...props}
      />
    )
  }

  const updateOptionsElement = (key: CategoryGhostFilterKeyType, props: any) => {
    let element: any = null

    if (key === 'categories') {
      let categoryType = props.category_type
      delete props.category_type

      element = (
        <>
          <div className={'d-flex gap-3'}>
            <CategoryTypeFilter
              className={'select-filter-custom w-200px h-content'}
              value={categoryType}
              mode={undefined}
              onSelected={(data) => {
                const item = (data[0] ?? null) as OptionData | null

                if (!item) {
                  return
                }

                updateCategoryType({value: item.value, label: item.label})
                updateOptionsElement('categories', {
                  defaultValue: props.defaultValue,
                  category_type: item,
                })
              }}
            />

            <CategoryFilter
              className={'select-filter-custom'}
              placeholder={'Lütfen seçiniz'}
              categoryType={categoryType.value}
              dataType={'item'}
              dropdownStyle={{zIndex: 99999}}
              style={{width: 400}}
              showSearch={true}
              withPath={true}
              onSelected={(data) => {
                const _data = data as OptionData[]
                updateFilter(
                  'categories',
                  _data.map((item: OptionData) => ({value: item.value, label: item.label})),
                  undefined,
                  categoryType
                )
              }}
              {...props}
            />
          </div>
        </>
      )
    }

    if (key === 'price') {
      element = (
        <MinMaxFilter
          containerClassName={'d-flex gap-3'}
          onChanged={(data) => {
            updateFilter('price', data)
          }}
          size={'middle'}
          style={{borderRadius: 5, minWidth: 120}}
          minPlaceholder={'Min Fiyat'}
          maxPlaceholder={'Max Fiyat'}
          {...props}
        />
      )
    }

    if (key === 'special_groups') {
      element = (
        <IBMCampaignSpecialFilter
          placeholder={'Lütfen seçiniz'}
          style={{width: 250}}
          initialLoad={true}
          onSelected={(data) => {
            updateFilter(
              'special_groups',
              data.map((item: OptionData) => ({value: item.value, label: item.label}))
            )
          }}
          {...props}
        />
      )
    }

    if (key === 'manufacturer') {
      element = (
        <ManufacturerFilter
          placeholder={'Lütfen seçiniz'}
          style={{width: 250}}
          initialLoad={true}
          dataType={'item'}
          onSelected={(data) => {
            updateFilter(
              'manufacturer',
              (data as OptionData[]).map((item: OptionData) => ({
                value: item.value,
                label: item.label,
              }))
            )
          }}
          {...props}
        />
      )
    }

    if (attributes.some((attribute) => `attribute_${attribute.attribute_id}` === key) && key) {
      const attributeId = Number((key as string).replace('attribute_', ''))
      element = getProductAttributeFilter(attributeId, props)
    }

    if (!element) {
      return
    }

    setOptions((options) => {
      return options.map((option) => {
        if (option.value === key) {
          return {...option, element}
        }

        return option
      })
    })
  }

  const [options, setOptions] = useState<ghostOptionsType[]>([
    {
      value: 'categories',
      label: 'Kategori',
    },
    {
      value: 'price',
      label: 'Fiyat Aralığı',
    },
    {
      value: 'special_groups',
      label: 'Kampanya Özel Grup',
    },
    {
      value: 'manufacturer',
      label: 'Marka',
    },
  ])

  useEffect(() => {
    getAttributeRequest().then((res) => {
      const attributes = res.data.data
      setAttributes(attributes)
      setOptions((options) => {
        return [
          ...options,
          ...attributes
            .filter((attribute: attributeType) => {
              return isNaN(toNumber(attribute.named))
            })
            .map((attribute: attributeType) => {
              return {
                label: attribute.named,
                value: `attribute_${attribute.attribute_id}`,
              }
            }),
        ]
      })
    })
  }, [])

  useEffect(() => {
    if (!attributes) {
      return
    }

    attributes.forEach((attribute) => {
      if (
        filters &&
        filters.some((filter) => filter.key === `attribute_${attribute.attribute_id}`)
      ) {
        const filter = filters.find(
          (filter) => filter.key === `attribute_${attribute.attribute_id}`
        )
        updateOptionsElement(`attribute_${attribute.attribute_id}`, {defaultValue: filter?.value})
      }
    })
  }, [attributes])

  return (
    <>
      <CategoryGhostFilterContext.Provider
        value={{
          filters,
          updateFilter,
          setFilters,
          options,
          addFilter,
          deleteFilter,
        }}
      >
        {children}
      </CategoryGhostFilterContext.Provider>
    </>
  )
}
