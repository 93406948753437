import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import React, {FC, useContext, useState} from 'react'
import {useSelector} from 'react-redux'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {DrawerEnum} from '../../../../enums/DrawerEnum'
import {
  CategoryBannerContext,
  CategoryBannerProvider,
} from '../../../../providers/category/detail/CategoryBannerProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import CategoryBannerDescriptionDrawer from '../../../drawers/category/CategoryBannerDescriptionDrawer'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {CategoryBannerList} from './CategoryBannerList'

interface CategoryBannerListWrapperProps {
  pageTitle: string
}

const {confirm} = Modal

const BannerToolbarRightField: FC = () => {
  const {updateVisibleDrawer, updateDrawerType, multiDeleteBanner} =
    useContext(CategoryBannerContext)
  const {selectedBanners} = useSelector((state: any) => state.categoryBanner)
  const [isCategoryBannerDescriptionDrawerVisible, setIsCategoryBannerDescriptionDrawerVisible] =
    useState(false)

  const deleteBanners = () => {
    confirm({
      title: `${selectedBanners.length} banner silenecek.`,
      icon: <ExclamationCircleOutlined />,
      content: 'Silme işlemi geri alınamaz, emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        multiDeleteBanner(selectedBanners)
      },
    })
  }

  return (
    <>
      <div className='d-flex align-items-center py-1'>
        <KrcButton
          size={'middle'}
          className='me-4'
          onClick={() => {
            updateDrawerType(DrawerEnum.CREATE)
            updateVisibleDrawer(true)
          }}
          id='kt_toolbar_primary_button'
        >
          Banner Oluştur
        </KrcButton>

        <KrcButton
          className='me-4'
          type={'primary'}
          danger
          hidden={selectedBanners.length === 0}
          onClick={(e) => {
            e.preventDefault()
            deleteBanners()
          }}
        >
          Sil
        </KrcButton>

        <KrcButton
          type={'default'}
          onClick={(e) => {
            e.preventDefault()
            setIsCategoryBannerDescriptionDrawerVisible(true)
          }}
        >
          Açıklama ve Durum
        </KrcButton>

        <CategoryBannerDescriptionDrawer
          visible={isCategoryBannerDescriptionDrawerVisible}
          onClose={() => setIsCategoryBannerDescriptionDrawerVisible(false)}
          onSave={() => {}}
        />
      </div>
    </>
  )
}

const CategoryBannerListWrapperWithProvider: FC<CategoryBannerListWrapperProps> = ({pageTitle}) => {
  return (
    <>
      <Toolbar rightField={<BannerToolbarRightField />}></Toolbar>
      <PageTitle>{pageTitle}</PageTitle>
      <CategoryBannerList />
    </>
  )
}

export const CategoryBannerListWrapper: FC<CategoryBannerListWrapperProps> = ({pageTitle}) => {
  return (
    <>
      <CategoryBannerProvider>
        <CategoryBannerListWrapperWithProvider pageTitle={pageTitle} />
      </CategoryBannerProvider>
    </>
  )
}
