import React, {FC} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {OrderProvider} from '../../../providers/orders/OrderProvider'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {OrderList} from './OrderList'

export const OrderListWrapperWithoutProvider: FC = () => {
  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Siparişler</PageTitle>
      <OrderList />
    </>
  )
}

export const OrderListWrapper: FC = () => {
  return (
    <OrderProvider>
      <OrderListWrapperWithoutProvider />
    </OrderProvider>
  )
}
