import {mergeAndPushObjectProperties} from '../../../helpers/ObjectHelper'

interface IUseKrcProp {
  config: object
  props: object | undefined
}

export const useKrcProp = ({config, props}: IUseKrcProp) => {
  const getCurrentProps = () => {
    if (!props) {
      return config as any
    }

    return mergeAndPushObjectProperties(props, config)
  }

  return {
    getCurrentProps,
  }
}
