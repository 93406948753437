import React, {FC, useContext, useEffect, useState} from 'react'

import {SPECIAL_GROUPS} from '../../../../../enums/IBMCampaignTabTypeEnums'
import {IBMCampaignSpecialGroupContext} from '../../../../../providers/ibm/IBMCampaignSpecialGroupProvider'
import {Loader} from '../../../../molecules/Loader'
import {KrcTable} from '../../../../organisms/tables/KrcTable'

interface SpecialGroupTabProps {
  activeCampaignId: number
}

export const SpecialGroupTab: FC<SpecialGroupTabProps> = ({activeCampaignId}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    meta,
    changePage,
    changeLimit,
    setPageType,
    columns,
    getIBMCampaignsSpecialGroup,
    IBMCampaignSpecialGroups,
  } = useContext(IBMCampaignSpecialGroupContext)

  useEffect(() => {
    setPageType(SPECIAL_GROUPS)
    if (activeCampaignId) {
      setIsLoading(true)
      getIBMCampaignsSpecialGroup(meta.page, activeCampaignId)
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    }
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <KrcTable
            columns={columns}
            data={IBMCampaignSpecialGroups}
            paginationProps={{
              isPagination: true,
              limit: meta.limit,
              page: meta.page,
              paginationData: meta,
              onChangePage: (page) => {
                changePage(page)
              },
              onChangeLimit: (limit) => {
                changeLimit(limit)
              },
            }}
          />
        </>
      )}
    </>
  )
}
