import {EditOutlined, EyeOutlined} from '@ant-design/icons'
import {Tooltip, message} from 'antd'
import React, {createContext, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Column} from 'react-table'

import {paginationMetaType} from '../../components/models/GeneralModel'
import {OrderType} from '../../components/models/order/OrderTypeModel'
import {removeEmptyItemInObject} from '../../helpers/ObjectHelper'
import {fetchOrder, fetchOrders2} from '../../requests/orders/OrderRequest'
import {initialMetaData} from '../tables/ProductSelectionProvider'

interface IOrderContext {
  columns: Column<any>[]
  isLoading: boolean
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  orders: OrderType[]
  getOrders: () => void
  filter: any[]
  setFilter: React.Dispatch<React.SetStateAction<any>>
}

export const OrderContext = createContext<IOrderContext>({
  columns: [],
  isLoading: false,
  meta: initialMetaData,
  setMeta: () => {},
  orders: [],
  getOrders: () => {},
  filter: [],
  setFilter: () => {},
})

export const OrderQuestioningProvider = ({children}: any) => {
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)

  const [filter, setFilter] = useState<any>([])
  const [orders, setOrders] = useState<OrderType[]>([])

  const fetchRef = useRef(0)
  const initial = useRef(0)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const columns = React.useMemo(
    () => [
      {
        Header: 'Sipariş No',
        accessor: 'orderId',
      },
      {
        Header: 'Mağaza',
        accessor: 'orderLocation',
      },
      {
        Header: 'Müşteri Adı',
        accessor: 'invoiceCustomer.firstName',
      },
      {
        Header: 'Toplam',
        accessor: 'orderAmount',
      },
      {
        Header: 'Eklenme Tarihi',
        accessor: 'orderDate',
        Cell: ({row}: any) => {
          return (
            <div>
              {row.original.orderDate ? (
                <div>{new Date(row.original.orderDate).toLocaleDateString('tr-TR')}</div>
              ) : null}
            </div>
          )
        },
      },
      {
        Header: 'Ödeme Türü',
        accessor: 'paymentType',
      },
      {
        Header: 'Aksiyon',
        Cell: ({row}: any) => (
          <span className='text-end d-flex'>
            <>
              <Tooltip title={'Göster'}>
                <Link
                  to={`/orders/${row.values.orderId}/details`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  style={{color: 'unset'}}
                >
                  <EyeOutlined />
                </Link>
              </Tooltip>

              <Tooltip title={'Düzenle'}>
                <Link
                  to={`/orders/${row.values.orderId}/edit`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 btn-disabled'
                  style={{color: 'unset'}}
                >
                  <EditOutlined />
                </Link>
              </Tooltip>
            </>
          </span>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    if (!setMeta) {
      return
    }

    setMeta(meta)
  }, [meta.to, meta.from, meta.last_page])

  useEffect(() => {
    if (initial.current === 0) {
      return
    }

    if (!setMeta) {
      return
    }

    setMeta(meta)

    getOrders()
  }, [meta.page])

  useEffect(() => {
    if (initial.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))

      return
    }

    getOrders()
  }, [meta.limit])

  useEffect(() => {
    if (!meta) {
      return
    }

    setMeta(meta)
  }, [meta])

  useEffect(() => {
    if (!orders) {
      return
    }

    setOrders(orders)
  }, [orders])

  useEffect(() => {
    if (filter.length === 0) {
      return
    }

    initial.current = 1

    getOrders()
  }, [filter])

  const getOrders = (page: null | number = null) => {
    fetchRef.current += 1

    const fetchId = fetchRef.current

    let _meta = meta

    const _filter = removeEmptyItemInObject(filter)

    if (page) {
      _meta.page = page
    }

    setIsLoading(true)

    fetchOrders2(_filter, _meta)
      .then((response) => {
        if (fetchId !== fetchRef.current) {
          return
        }

        setOrders(response.data.data)

        const meta = response.data.meta

        setMeta({
          limit: meta.per_page,
          page: meta.current_page,
          ...meta,
        })
      })
      .catch((err) => {
        message.error('Siparişler getirilirken bir hata oluştu')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <OrderContext.Provider
      value={{
        columns,
        isLoading,
        orders: orders,
        getOrders: () => {},
        filter,
        setFilter,
        meta,
        setMeta,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

export const getOrder = async (order: number) => {
  try {
    return await fetchOrder(order)
  } catch (err) {
    message.error('Sipariş bulunamadı.')

    return null
  }
}
