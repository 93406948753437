import {Route, Routes} from 'react-router-dom'

import {BadgeListWrapper} from '../components/pages/badges/BadgeListWrapper'
import {BadgeExportWrapper} from '../components/pages/badges/exports/BadgeExportWrapper'
import {BadgeTiedExportWrapper} from '../components/pages/badges/exports/BadgeTiedExportWrapper'

export const BadgeRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/export'} element={<BadgeExportWrapper />} />
        <Route path={'/tied/export'} element={<BadgeTiedExportWrapper />} />
        <Route path={'/'} element={<BadgeListWrapper />} />
      </Routes>
    </>
  )
}
