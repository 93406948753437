import {SelectProps, Spin} from 'antd'
import React, {FC, useEffect} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useBadgeFilter} from '../../hooks/badges/useBadgeFilter'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {IBadgeFilter} from '../../models/badges/BadgeFilterModel'

const badgeFilterConfig = {
  default: {
    style: {width: '100%'},
    mode: 'multiple',
    size: 'middle',
    placeholder: 'Badge Filtre Listesi',
    allowClear: false,
  },
}

export const BadgeFilter: FC<IBadgeFilter & SelectProps> = ({
  onSelected,
  dataType = 'value',
  clear,
  ...props
}) => {
  const currentProps = useKrcProp({config: badgeFilterConfig.default, props}).getCurrentProps()
  const {handleOnChange, handleSearch, badgeIds, fetching, options, selectedItems} =
    useBadgeFilter()

  useEffect(() => {
    if (!onSelected) {
      return
    }

    if (dataType === 'value') {
      onSelected(badgeIds)
      return
    }

    onSelected(selectedItems)
  }, [badgeIds])

  return (
    <>
      <KrcSelect
        clear={clear}
        {...currentProps}
        showArrow
        filterOption={false}
        labelInValue
        onSearch={handleSearch}
        onChange={handleOnChange}
        options={options}
        notFoundContent={fetching ? <Spin size='small' /> : null}
      />
    </>
  )
}
