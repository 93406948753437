import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {FooterModuleListWrapper} from '../components/pages/design/footer/FooterModuleListWrapper'

export const FooterRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<FooterModuleListWrapper />} />
      </Routes>
    </>
  )
}
