export const SCREEN_HEADER = 'Screen'
export const DEEPLINK_HEADER = 'Deeplink'
export const WEBVIEW_HEADER = 'Webview'
export const EXTERNAL_HEADER = 'External'

export const INFORMATION = [
  {
    screen: 'Anasayfa',
    deepLink: 'karaca://home',
    webview: 'karaca://webview?url=https://www.karaca.com/',
    external: 'karaca://external?url=https://www.karaca.com/',
  },
  {
    screen: 'Sepet',
    deepLink: 'karaca://cart',
    webview: 'karaca://webview?url=https://www.karaca.com/checkout/cart',
    external: 'karaca://external?url=https://www.karaca.com/checkout/cart',
  },
  {
    screen: 'Siparişlerim',
    deepLink: 'karaca://orders',
    webview: 'karaca://webview?url=https://www.karaca.com/account/order',
    external: 'karaca://external?url=https://www.karaca.com/account/order',
  },
  {
    screen: 'Sipariş Detay',
    deepLink: 'karaca://orders/{id}',
    webview: 'karaca://webview?url=https://www.karaca.com/account/order/info/57921491',
    external: 'karaca://external?url=https://www.karaca.com/account/order/info/57921491',
  },
  {
    screen: 'IBM Çeyiz Paketi',
    deepLink: 'karaca://dowry_set/{id}',
    webview: '',
    external: '',
  },
  {
    screen: 'Favorilerim',
    deepLink: 'karaca://favorites',
    webview: 'karaca://webview?url=https://www.karaca.com/account/wishlist',
    external: 'karaca://external?url=https://www.karaca.com/account/wishlist',
  },
  {
    screen: 'Mağazalar',
    deepLink: 'karaca://stores',
    webview: 'karaca://webview?url=https://www.karaca.com/stores',
    external: 'karaca://external?url=https://www.karaca.com/stores',
  },
  {
    screen: 'Kampanyalar Liste ekranı',
    deepLink: 'karaca://campaigns',
    webview: 'karaca://webview?url=https://www.karaca.com/kampanyalar',
    external: 'karaca://external?url=https://www.karaca.com/kampanyalar',
  },
  {
    screen: 'Kampanya Detay',
    deepLink: 'URL aliasa göre yönleniyor',
    webview: 'karaca://webview?url=https://www.karaca.com/airfryer-karaca-air-pro-cook',
    external: 'karaca://external?url=https://www.karaca.com/airfryer-karaca-air-pro-cook',
  },
  {
    screen: 'Landing Page (Listeleme)',
    deepLink: 'karaca://landing/{id}',
    webview: 'karaca://webview?url=https://www.karaca.com/hediye-lp',
    external: 'karaca://external?url=https://www.karaca.com/hediye-lp',
  },
  {
    screen: 'Landing Page (Hediye)',
    deepLink: 'karaca://gift',
    webview: 'karaca://webview?url=https://www.karaca.com/hediye-lp',
    external: 'karaca://external?url=https://www.karaca.com/hediye-lp',
  },
  {
    screen: 'Marka (Listeleme)',
    deepLink: 'karaca://plp/manufacturer/{id}',
    webview: 'karaca://webview?url=https://www.karaca.com/marka/kasmir-hali',
    external: 'karaca://external?url=https://www.karaca.com/marka/kasmir-hali',
  },
  {
    screen: 'Kategori Listeleme',
    deepLink: 'karaca://plp/category/{id}',
    webview: 'karaca://webview?url=https://www.karaca.com/elektrikli-icecek-hazirlama',
    external: 'karaca://external?url=https://www.karaca.com/elektrikli-icecek-hazirlama',
  },
  {
    screen: 'Register',
    deepLink: 'karaca://register',
    webview: 'karaca://webview?url=https://www.karaca.com/account/login?register',
    external: 'karaca://external?url=https://www.karaca.com/account/login?register',
  },
  {
    screen: 'Ürün Detay',
    deepLink: 'karaca://product/{id}',
    webview:
      'karaca://webview?url=https://www.karaca.com/urun/karaca-aida-15-parca-celik-ceyiz-seti',
    external:
      'karaca://external?url=https://www.karaca.com/urun/karaca-aida-15-parca-celik-ceyiz-seti',
  },
  {
    screen: 'Custom Fırsat Sayfası',
    deepLink: 'karaca://deals/{id}',
    webview: 'karaca://webview?url=https://www.karaca.com/gunun-urunleri',
    external: 'karaca://external?url=https://www.karaca.com/gunun-urunleri',
  },
  {
    screen: 'Canlı Yayınlar',
    deepLink: 'karaca://shows',
    webview: 'karaca://webview?url=https://www.karaca.com/canli-yayinlar',
    external: 'karaca://external?url=https://www.karaca.com/canli-yayinlar',
  },
  {
    screen: 'Oyun',
    deepLink: 'karaca://game',
    webview: '',
    external: '',
  },
  {
    screen: 'Kişisel Bilgilerim',
    deepLink: 'karaca://account',
    webview: 'karaca://webview?url=https://www.karaca.com/account/account',
    external: 'karaca://external?url=https://www.karaca.com/account/account',
  },
  {
    screen: 'Listelerim',
    deepLink: 'karaca://shoppinglist',
    webview: 'karaca://webview?url=https://www.karaca.com/account/shopping-list',
    external: 'karaca://external?url=https://www.karaca.com/account/shopping-list',
  },
  {
    screen: 'Adreslerim',
    deepLink: 'karaca://address',
    webview: '',
    external: '',
  },
  {
    screen: 'Keşfet Sayfası',
    deepLink: 'karaca://explore',
    webview: '',
    external: '',
  },
  {
    screen: 'İndirim Kuponlarım',
    deepLink: 'karaca://discounts',
    webview: '',
    external: '',
  },
  {
    screen: 'Yardım & Destek',
    deepLink: 'karaca://faq',
    webview: '',
    external: '',
  },
  {
    screen: 'Hediye Kartlarım',
    deepLink: 'karaca://giftcards',
    webview: '',
    external: '',
  },
  {
    screen: 'Çeyiz Randevu',
    deepLink: 'karaca://doorwayappointment',
    webview: '',
    external: '',
  },
  {
    screen: 'Şifre Değiştir',
    deepLink: 'karaca://passwordchange',
    webview: '',
    external: '',
  },
  {
    screen: 'Değerlendirmelerim',
    deepLink: 'karaca://assessment',
    webview: '',
    external: '',
  },
  {
    screen: 'Özel Gün Hatırlatıcı',
    deepLink: 'karaca://specday',
    webview: '',
    external: '',
  },
  {
    screen: 'Custom PLP Search',
    deepLink: 'karaca://plp/search?q=bardak',
    webview: 'karaca://webview?url=https://www.karaca.com/product/search?q=bardak',
    external: 'karaca://external?url=https://www.karaca.com/product/search?q=bardak',
  },
  {
    screen: 'Kategoriler',
    deepLink: 'karaca://categories',
    webview: '',
    external: '',
  },
  {
    screen: 'Karaca Hediye Kartı',
    deepLink: 'karaca://karacagiftcard',
    webview: '',
    external: '',
  },
  {
    screen: 'Paket (Çeyiz) Detay',
    deepLink: 'karaca://campaign/{id}',
    webview: '',
    external: '',
  },
  {
    screen: 'Hesabım -> Taleplerim',
    deepLink: 'karaca://requests',
    webview: '',
    external: '',
  },
  {
    screen: 'Hesabım -> Taleplerim -> Talep Detay',
    deepLink: 'karaca://requests/{id}',
    webview: 'karaca://webview?url=https://www.karaca.com/account/aftersales',
    external: 'karaca://external?url=https://www.karaca.com/account/aftersales',
  },
]

export const OTHER_INFORMATION = [
  {
    screen: "Bazı URL'lerin app dışına yönlendirilmesi gerekmektedir. ",
    deepLink:
      'app exclude links dosyası -> external yönlendirme ile url verilerek yönlendirilebiliyor.',
    webview: '',
    external: '',
  },
  {
    screen: 'Hediye Asistanı',
    deepLink: 'karaca://giftassistant',
    webview: '',
    external: '',
  },
  {
    screen:
      "Sosyal Medya'dan tıklanan linkler (Instagram, Facebook vb. uygulamalardan story veya reklamlara eklenen link tıklandığında app varsa appte açmalı, app yoksa app yüklemeye gitmeli) ",
    deepLink:
      'Home, PLP, PDP, LP, Favoriler, Alışveriş Listeleri, Çeyiz Paketleri, Oyun, Kişisel Bilgilerim, ',
    webview: '',
    external: '',
  },
  {
    screen:
      'Kişiselleştirme, basebros son ekranında Sepete Git butonu tıklanınca webviewdan native sepet sayfası açılması gerekiyor.',
    deepLink: '',
    webview: '',
    external: '',
  },
  {
    screen: 'Iframe açılan LP sayfalardaki  urller',
    deepLink: 'Basebros AR Evinde Görüntüle, PDF butonu, PLP ve PDP',
    webview: 'karaca://story/{id}',
    external:
      'karaca://external/?url=https://karaca.com/depremzede (external/view.dart parametre url )',
  },
  {
    screen: 'http://karaca.com/account/wallet/history',
    deepLink: 'karaca://wallet/history',
    webview: '',
    external: '',
  },
  {
    screen: 'http://karaca.com/account/wallet/information',
    deepLink: 'karaca://wallet/information',
    webview: '',
    external: 'karaca://external?url=http://karaca.com/account/wallet/information',
  },
]
