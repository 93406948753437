export const COOKPLUS_ID = 'cook'
export const KARACA_HOME_ID = 'krch'
export const KARACA_ID = 'krc'
export const EMSAN_ID = 'ems'
export const JUMBO_ID = 'jmbo'
export const KARACA_APP_ID = 'krc'
export const PERA_BULVARI_ID = 'pera'
export const HOMEND_ID = 'hedt'
export const KASMIR_ID = 'ksmr'

export const PRODUCT_SPECIAL_ALLOWED_DATA_AREAS = [
  {
    id: COOKPLUS_ID,
    store_name: 'Cookplus',
  },
  {
    id: KARACA_HOME_ID,
    store_name: 'Karaca Home',
  },
  {
    id: KARACA_ID,
    store_name: 'Karaca',
  },
  {
    id: EMSAN_ID,
    store_name: 'Emsan',
  },
  {
    id: JUMBO_ID,
    store_name: 'Jumbo',
  },
  {
    id: HOMEND_ID,
    store_name: 'Homend',
  },
]
