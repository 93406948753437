import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Drawer, Modal, Space, message} from 'antd'
import React, {FC, useState} from 'react'
import {useParams} from 'react-router-dom'

import {ProductSelectionEnum} from '../../../../enums/ProductSelectionEnum'
import {prioritizeArrayItemsByAnotherArrayItemsOrdered} from '../../../../helpers/ArrayHelper'
import {categoryProductsProcessTableDataType} from '../../../../providers/category/products/CategoryProductsProcessProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {ProductSelection} from '../../../layout/filters/ProductSelection'
import {categoryUrlType} from '../../../models/categories/CategoryProductModel'
import {productVerticalAdvancedFilterDataType} from '../../../models/products/ProductListFilterModel'
import {
  ProductSelectionType,
  productTextAreaFilterDataType,
} from '../../../models/products/ProductSelectionModel'

const {confirm} = Modal

interface IAddProductToCategorySortingDrawer {
  visible: boolean
  onHide: () => void
  onOk: (products: categoryProductsProcessTableDataType[]) => void
}

export const AddProductToCategorySortingDrawer: FC<IAddProductToCategorySortingDrawer> = ({
  visible,
  onHide,
  onOk,
}) => {
  const {categoryId} = useParams<categoryUrlType>()
  const [selectedProducts, setSelectedProducts] = useState<categoryProductsProcessTableDataType[]>(
    []
  )

  const [productSelectionFilterCurrentType, setProductSelectionFilterCurrentType] =
    useState<ProductSelectionType>(ProductSelectionEnum.DEFAULT)

  const [productSelectionCurrentFilter, setProductSelectionCurrentFilter] = useState<
    productVerticalAdvancedFilterDataType | productTextAreaFilterDataType
  >()

  const handleOnOk = () => {
    if (selectedProducts?.length === 0) {
      message.warn('Ürün ekleyebilmek için ürün seçilmedi. Lütfen ürün(leri) seçin.')
      return
    }

    confirm({
      title: `${selectedProducts?.length} ürün eklenecek.`,
      icon: <ExclamationCircleOutlined />,
      content: 'Ekleme işlemi kaydet buttonuna basılana kadar gerçekleşmeyecektir.',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        onOk(selectedProducts)
        onClose()
      },
    })
  }

  const onClose = () => {
    onHide && onHide()
  }

  return (
    <>
      <Drawer
        title={'Ürün Ekleme'}
        placement='right'
        size={'large'}
        onClose={onClose}
        keyboard={false}
        visible={visible}
        extra={
          <Space>
            <KrcButton type={'default'} size={'large'} onClick={onClose}>
              İptal
            </KrcButton>
            <KrcButton size={'large'} onClick={handleOnOk}>
              Seçilen Ürünleri Ekle
            </KrcButton>
          </Space>
        }
      >
        <ProductSelection
          onFilterChange={(data) => {
            setProductSelectionCurrentFilter(data)
          }}
          onFilterTypeChange={(type) => {
            setProductSelectionFilterCurrentType(type)
          }}
          headerProps={{
            searchOption: {
              advancedStatus: false,
            },
          }}
          onSelectedItems={(data) => {
            let transformedData = data ?? []
            if (
              productSelectionCurrentFilter &&
              productSelectionFilterCurrentType === ProductSelectionEnum.TEXT
            ) {
              transformedData = prioritizeArrayItemsByAnotherArrayItemsOrdered(
                transformedData,
                (productSelectionCurrentFilter as productTextAreaFilterDataType).all,
                (item: string, searchData) =>
                  String(item) === String(searchData.id) ||
                  String(item) === String(searchData.model)
              )
            }
            setSelectedProducts(transformedData)
          }}
          withFullPrice={true}
          transferProps={{
            button: {
              isActive: false,
            },
            config: {
              confirmActive: false,
            },
          }}
          filterCategoryIds={[Number(categoryId)]}
          disableExcludeCategoryIdFromUrl={true}
        />
      </Drawer>
    </>
  )
}
