import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
const ROLE_URL = `${API_URL}/roles`

export function fetchRolePermissionsRequest(roleId: number) {
  return axios.get(`${ROLE_URL}/${roleId}/permissions`)
}

export function addPermissionsToRoleRequest(roleId: number, permissionIds: number[]) {
  return axios.post(`${ROLE_URL}/${roleId}/permissions`, {
    permission_ids: permissionIds,
  })
}

export function deletePermissionFromRoleRequest(roleId: number, permissionId: number) {
  return axios.delete(`${ROLE_URL}/${roleId}/permissions/${permissionId}`)
}
