import axios from 'axios'
import * as querystring from 'query-string'

import {paginationMetaType} from '../../components/models/GeneralModel'
import {createCategoryType} from '../../components/models/categories/CategoryDetailModel'
import {CategoryTypeEnum} from '../../components/models/categories/CategoryModel'
import {tableFilterSearchParamsType} from '../../components/models/categories/CategorySelectionModel'

const API_URL = process.env.REACT_APP_API_URL || ''
export const CATEGORY_URL = `${API_URL}/categories`

export type fetchCategoriesParamType = {
  name?: string | null
  type?: string | null
  status?: number
  id?: number | null
  category_ids?: number[] | null
  is_active_product?: number | boolean
  has_pending_approval_products?: number | null | undefined
  has_history_approval_products?: number | null | undefined
  has_my_pending_approval_products_requests?: number | null | undefined
  has_my_history_approval_products_requests?: number | null | undefined
}

export function getCategoryRequest(categoryId: number, relations: string[] = []) {
  return axios.get(`${CATEGORY_URL}/${categoryId}`, {
    params: {
      with: relations,
    },
  })
}

export function createCategoryRequest(data: createCategoryType) {
  return axios.post(`${CATEGORY_URL}`, data)
}

export function updateCategoryRequest(categoryId: number, data: any) {
  return axios.put(`${CATEGORY_URL}/${categoryId}`, data)
}

export function getCategoriesMapRequest(
  parentId: number = 0,
  categoryType?: string,
  storeId?: number
) {
  return axios.get(`${CATEGORY_URL}/maps`, {
    params: {
      parent_id: parentId,
      type: categoryType,
      store_id: storeId,
    },
  })
}

export function getCategoriesRequest(
  {page, limit}: paginationMetaType,
  relations: Array<string> = [],
  fetchParams?: fetchCategoriesParamType
) {
  return axios.get(`${CATEGORY_URL}`, {
    params: {
      page,
      limit,
      with: relations,
      name: fetchParams?.name,
      status: fetchParams?.status,
      is_active_product: fetchParams?.is_active_product,
      category_id: fetchParams?.id,
      type: fetchParams?.type,
      has_pending_approval_products: fetchParams?.has_pending_approval_products,
      has_history_approval_products: fetchParams?.has_history_approval_products,
      has_my_pending_approval_products_requests:
        fetchParams?.has_my_pending_approval_products_requests,
      has_my_history_approval_products_requests:
        fetchParams?.has_my_history_approval_products_requests,
    },
  })
}

export function multiDeleteCategoriesRequest(categoryIds: number[]) {
  return axios.delete(`${CATEGORY_URL}/multi`, {data: {category_ids: categoryIds}})
}

export function categorySearchRequest(
  search: string,
  categoryType?: CategoryTypeEnum,
  withPath?: boolean
) {
  return axios.get(`${CATEGORY_URL}/search`, {
    params: {
      name: search,
      category_type: categoryType,
      with_path: withPath,
    },
  })
}

export function exportCategoryRequest(fetchParams: fetchCategoriesParamType) {
  return axios.get(`${CATEGORY_URL}/export`, {
    params: {
      name: fetchParams?.name,
      status: fetchParams?.status,
      category_id: fetchParams?.id,
      category_ids: fetchParams?.category_ids,
      type: fetchParams?.type,
      has_pending_approval_products: fetchParams?.has_pending_approval_products,
      has_history_approval_products: fetchParams?.has_history_approval_products,
      has_my_pending_approval_products_requests:
        fetchParams?.has_my_pending_approval_products_requests,
      has_my_history_approval_products_requests:
        fetchParams?.has_my_history_approval_products_requests,
    },
  })
}

/*
  exportCategoryRequest methoduyla ortak parametrelerle çalışıyor.
 */
export function exportCategoryListProductsRequest(fetchParams: fetchCategoriesParamType) {
  return axios.get(`${CATEGORY_URL}/export-products`, {
    params: {
      name: fetchParams?.name,
      status: fetchParams?.status,
      category_id: fetchParams?.id,
      category_ids: fetchParams?.category_ids,
      type: fetchParams?.type,
      has_pending_approval_products: fetchParams?.has_pending_approval_products,
      has_history_approval_products: fetchParams?.has_history_approval_products,
      has_my_pending_approval_products_requests:
        fetchParams?.has_my_pending_approval_products_requests,
      has_my_history_approval_products_requests:
        fetchParams?.has_my_history_approval_products_requests,
    },
  })
}

export function exportCategoryListProductsCheckRequest(fileName: string) {
  return axios.get(`${CATEGORY_URL}/check-export-products`, {
    params: {
      file_name: fileName,
    },
  })
}

export function getCategoryConstraintProductsRequest(categoryId: number, params?: object) {
  return axios.get(`${CATEGORY_URL}/${categoryId}/constraint/products`, {
    params: {
      ...params,
    },
  })
}

export function updateCategoryLandingRequest(categoryId: number, data: any) {
  return axios.put(`${CATEGORY_URL}/${categoryId}/landings`, data)
}

export const getCategoryFilterListExportFrontendUrl = (filterData: any) => {
  let url = `/categories/export`

  return `${url}?${querystring.stringify(
    {
      ...(filterData ?? {}),
    },
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}

/*
  getCategoryFilterListExportFrontendUrl methoduyla aynı koşullara sahip olmalıdır.
 */
export const getCategoryListProductsExportFrontendUrl = (filterData: any) => {
  let url = `/categories/export-products`

  return `${url}?${querystring.stringify(
    {
      ...(filterData ?? {}),
    },
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}

export function categorySelectionFilterRequest(
  filter: any,
  meta: paginationMetaType,
  excludedCategoryIds: any = [],
  filterSearchData?: tableFilterSearchParamsType,
  relation: string[] = []
) {
  return axios.post(`${CATEGORY_URL}/filter`, {
    category_ids: filter.categoryIds,
    category_name: filterSearchData?.name,
    category_id: filterSearchData?.id,
    category_type: filterSearchData?.type,
    category_status: filterSearchData?.status,
    page: meta.page,
    limit: meta.limit,
    excluded_category_ids: excludedCategoryIds,
    included_category_ids: filter.includedCategoryIds,
    with: relation,
  })
}
