import moment from 'moment'
import {FC, createContext, useEffect, useMemo, useState} from 'react'
import {Column} from 'react-table'
import * as Yup from 'yup'

import {gameType} from '../../components/models/games/GameModel'
import {gameOption} from '../../components/models/games/scores/GameModel'
import {DAILY, MANUEL, MONTHLY, WEEKLY} from '../../enums/GameTypeEnum'
import {errorMessage} from '../../helpers/ErrorHelper'
import {getGamesRequest} from '../../requests/games/GameRequest'
import {exportGameScoreRequest, getGameScoreRequest} from '../../requests/games/GameScoreRequest'

export interface IGameScoreContext {
  columns: Column<any>[]
  gameOptions: any
  gamePeriotOptionsSelectValue: string
  gamePeriotOptions: any
  filterCoupon: (formData: ISearchGameScoreForm) => void
  filterFormData: ISearchGameScoreForm
  defaultStartDate: string
  defaultEndDate: string
  gameData: gameType[]
  exportButton: boolean
  exportScores: (formData: ISearchGameScoreForm) => void
  schema: object
  setFilterFormData: React.Dispatch<React.SetStateAction<ISearchGameScoreForm>>
}

export interface ISearchGameScoreForm {
  gameId: number
  type: string
  startDate: any | null
  endDate: any | null
}

export const ScoreBoardContext = createContext<IGameScoreContext>({
  columns: [],
  gameOptions: '',
  gamePeriotOptionsSelectValue: '',
  gamePeriotOptions: '',
  filterCoupon: () => {},
  filterFormData: {gameId: 0, type: '', startDate: '', endDate: ''},
  defaultStartDate: '',
  defaultEndDate: '',
  gameData: [],
  exportButton: false,
  exportScores: () => {},
  schema: {},
  setFilterFormData: () => {},
})

export const schema = Yup.object({
  gameId: Yup.number().required('Oyun seçimi zorunlu alan'),
  type: Yup.string().required('Score Board türü zorunlu alan'),
  startDate: Yup.string().required('Tarih zorunlu alan'),
  endDate: Yup.string().required('Tarih zorunlu alan'),
})

export const ScoreBoardProvider: FC = ({children}) => {
  const [gameOptions, setGameOptions] = useState<gameOption[]>([])
  const gamePeriotOptionsSelectValue = DAILY
  const defaultStartDate = moment().add(-1, 'days').format('YYYY-MM-DD')
  const defaultEndDate = moment().format('YYYY-MM-DD')
  const [gameData, setGameData] = useState<gameType[]>([])
  const [exportButton, setExportButton] = useState<boolean>(false)
  const [filterFormData, setFilterFormData] = useState<ISearchGameScoreForm>({
    gameId: 0,
    type: '',
    startDate: '',
    endDate: '',
  })

  const gamePeriotOptions = [
    {
      value: DAILY,
      label: 'Günlük',
    },
    {
      value: WEEKLY,
      label: 'Haftalık',
    },
    {
      value: MONTHLY,
      label: 'Aylık',
    },
    {
      value: MANUEL,
      label: 'Manuel',
    },
  ]
  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: 'Sıra No',
        accessor: 'rank',
        manualWidth: 60,
        maxWidth: 60,
      },
      {
        Header: 'Puan',
        accessor: 'score',
        manualWidth: 60,
        maxWidth: 60,
      },
      {
        Header: 'Ad',
        accessor: 'name',
        manualWidth: 60,
        maxWidth: 60,
      },
      {
        Header: 'Soyad',
        accessor: 'surname',
        manualWidth: 60,
        maxWidth: 60,
      },
      {
        Header: 'Telefon',
        accessor: 'phone',
        manualWidth: 60,
        maxWidth: 60,
      },
      {
        Header: 'E - Posta',
        accessor: 'email',
        manualWidth: 60,
        maxWidth: 60,
      },
    ],
    []
  )

  useEffect(() => {
    getGames()
  }, [])

  const getGames = async () => {
    try {
      const res = await getGamesRequest()
      const resData = res.data.data ?? []
      const options = resData.map((game: gameType) => ({
        value: game.game_id,
        label: game.game_name,
      }))
      setGameOptions(options)
    } catch (error) {
      errorMessage(error)
    }
  }

  const filterCoupon = async (formData: ISearchGameScoreForm) => {
    setFilterFormData(formData)
    let result = await getGameScoreRequest(formData)
    setGameData(result.data.data ?? [])
    setExportButton(result.data.data && result.data.data.length > 0)
  }

  const exportScores = async (formData: ISearchGameScoreForm) => {
    return await exportGameScoreRequest(formData)
  }

  return (
    <ScoreBoardContext.Provider
      value={{
        columns,
        gameOptions,
        gamePeriotOptionsSelectValue,
        gamePeriotOptions,
        filterCoupon,
        filterFormData,
        defaultStartDate,
        defaultEndDate,
        gameData,
        exportButton,
        exportScores,
        schema,
        setFilterFormData,
      }}
    >
      {children}
    </ScoreBoardContext.Provider>
  )
}
