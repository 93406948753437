import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, message} from 'antd'
import debounce from 'lodash/debounce'
import moment, {Moment} from 'moment/moment'
import React, {FC, MutableRefObject, createContext, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {KrcRangePicker} from '../../../components/atoms/forms/KrcRangePicker'
import {paginationMetaType} from '../../../components/models/GeneralModel'
import {categoryDataType} from '../../../components/models/categories/CategoryModel'
import {
  categoryUrlType,
  constraintListTableFilterTye,
  constraintProToCatInitValueType,
  constraintProductType,
  getCategoryProductsExportType,
  getCategoryProductsType,
} from '../../../components/models/categories/CategoryProductModel'
import {minimalProductType} from '../../../components/models/products/MinimalProductModel'
import {productTextSearchType} from '../../../components/models/products/ProductListFilterModel'
import {productTextAreaFilterDataType} from '../../../components/models/products/ProductSelectionModel'
import {KrcWarningNotification} from '../../../components/molecules/KrcWarningNotification'
import {PROCESS_AND_PRODUCT_LISTING} from '../../../enums/CategoryProductProcessPageEnumType'
import {getAddItemToArray} from '../../../helpers/ArrayHelper'
import {errorMessage} from '../../../helpers/ErrorHelper'
import {addObjectToObject, objectIsEmpty, objectIsEveryEmpty} from '../../../helpers/ObjectHelper'
import {
  getCategoryConstraintProductsRequest,
  getCategoryRequest,
} from '../../../requests/categories/CategoryRequest'
import {
  categoryProductsRequest,
  deleteCategoryProductRequest,
  updateCategoryProductInformationRequest,
  updateCategoryProductRequest,
} from '../../../requests/categories/products/CategoryProductRequest'
import {getCategoryProductExportFrontendUrl} from '../../../requests/categories/products/exports/CategoryProductExportRequest'
import {requestDateFormat, viewDateFormat} from '../../../service/DateService'
import {initialMetaData} from '../../tables/ProductSelectionProvider'

const {confirm} = Modal

export type categoryProductsProcessTableDataType = {
  id: number
  name: string | null
  model: string
  dateAdded: string | null
  constraint?: constraintProToCatInitValueType
}

export type categoryProductProcessTableFilterType = {
  id?: number
  model?: string
  name?: string
  date_added_start?: string | null
  date_added_end?: string | null
}

export type categoryConstraintUpdateDataType = {
  quantity: number
  date_start: Moment | undefined
  date_end: Moment | undefined
  category_id: number
  constraint_ids: number[]
}

interface ICategoryProductsProcessContext {
  pageType: string
  setPageType: React.Dispatch<React.SetStateAction<string>>
  columns: any[]
  productsToTable: categoryProductsProcessTableDataType[]
  getCategoryProducts: () => void
  meta: paginationMetaType
  updateMeta: (data: object) => void
  updateFilterTable: (data: object) => void
  handleOnFilter: (data: any, key: string | number) => void
  category?: categoryDataType
  pageTitle?: string
  addProductDrawerVisible: boolean
  setAddProductDrawerVisible: (item: boolean) => void
  visibleTextFilter: boolean
  setVisibleTextFilter: any
  addWillBeAddedProducts: (data: any[], constraint?: constraintProToCatInitValueType) => void
  willBeAddedProducts: categoryProductsProcessTableDataType[]
  updateCategoryProduct: (isUrgentApproval?: boolean) => void
  willBeDeletedProducts: categoryProductsProcessTableDataType[]
  setWillBeDeletedProducts: any
  setSelectedProducts: any
  selectedProducts: categoryProductsProcessTableDataType[]
  addSelectedProductsToWillBeDeletedProducts: () => void
  unSelectAllItems: number
  textFilter?: productTextAreaFilterDataType | productTextSearchType
  setTextFilter: React.Dispatch<React.SetStateAction<productTextSearchType | undefined>>
  getExportCategoryProductsUrl: () => string
  visibleConstraintListFilter: boolean
  setVisibleConstraintListFilter: any
  addCategoryProductByFilteredProducts: (
    productFilters: productTextAreaFilterDataType,
    constraint?: constraintProToCatInitValueType
  ) => void
  removeCategoryProductByFilteredProducts: (productFilters: productTextSearchType) => void
  setWillBeUpdatedProduct: React.Dispatch<
    React.SetStateAction<categoryConstraintUpdateDataType | null>
  >
  willBeUpdatedBatchConstraintProducts: categoryConstraintUpdateDataType | null
  setWillBeUpdatedBatchConstraintProducts: React.Dispatch<
    React.SetStateAction<categoryConstraintUpdateDataType | null>
  >
  visibleCategoryProductModal: boolean
  setVisibleCategoryProductModal: (data: boolean) => void
  visibleCategoryConstraintProductModal: boolean
  setVisibleCategoryConstraintProductModal: (data: boolean) => void
  willBeUpdatedProduct: categoryConstraintUpdateDataType | null
  updateSelectedCategoryProduct: (data: any) => void
  getCategoryConstraintProducts: any
  categoryConstraintProducts: any[]
  selectedConstraintProducts: any[]
  setSelectedConstraintProducts: any
  unSelectAllConstraintProducts: number
  updateSelectedConstraintProducts: (data: any) => void
  isProductsToTableLoading: boolean
  deleteSelectedConstraintProduct: (data: any) => void
  handleOnConstraintFilter: (data: any, key: string | number) => void
  constraintListTableFilter: constraintListTableFilterTye
  setConstraintListTableFilter: any
  initialRef: MutableRefObject<number>
}

export const CategoryProductsProcessContext = createContext<ICategoryProductsProcessContext>({
  pageType: PROCESS_AND_PRODUCT_LISTING,
  setPageType: () => {},
  columns: [],
  productsToTable: [],
  getCategoryProducts: () => {},
  meta: initialMetaData,
  updateMeta: () => {},
  updateFilterTable: () => {},
  handleOnFilter: () => {},
  addProductDrawerVisible: false,
  setAddProductDrawerVisible: () => {},
  willBeAddedProducts: [],
  addWillBeAddedProducts: () => {},
  updateCategoryProduct: () => {},
  updateSelectedCategoryProduct: () => {},
  willBeDeletedProducts: [],
  setWillBeDeletedProducts: () => {},
  selectedProducts: [],
  setSelectedProducts: () => {},
  addSelectedProductsToWillBeDeletedProducts: () => {},
  unSelectAllItems: 0,
  visibleTextFilter: false,
  setVisibleTextFilter: () => {},
  setTextFilter: () => {},
  getExportCategoryProductsUrl: () => '',
  visibleConstraintListFilter: false,
  setVisibleConstraintListFilter: () => {},
  addCategoryProductByFilteredProducts: () => {},
  removeCategoryProductByFilteredProducts: () => {},
  visibleCategoryProductModal: false,
  willBeUpdatedProduct: null,
  setVisibleCategoryProductModal: () => {},
  visibleCategoryConstraintProductModal: false,
  setVisibleCategoryConstraintProductModal: () => {},
  setWillBeUpdatedProduct: () => {},
  getCategoryConstraintProducts: () => {},
  categoryConstraintProducts: [],
  selectedConstraintProducts: [],
  setSelectedConstraintProducts: () => {},
  unSelectAllConstraintProducts: 0,
  willBeUpdatedBatchConstraintProducts: null,
  setWillBeUpdatedBatchConstraintProducts: () => {},
  updateSelectedConstraintProducts: () => {},
  isProductsToTableLoading: false,
  deleteSelectedConstraintProduct: () => {},
  handleOnConstraintFilter: () => {},
  constraintListTableFilter: {},
  setConstraintListTableFilter: () => {},
  initialRef: {current: 0} as MutableRefObject<number>,
})

export const CategoryProductsProcessProvider: FC = ({children}) => {
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [pageTitle, setPageTitle] = useState<string>()
  const [category, setCategory] = useState<categoryDataType>()
  const [productsToTable, setProductsToTable] = useState<categoryProductsProcessTableDataType[]>([])
  const [isProductsToTableLoading, setIsProductsToTableLoading] = useState<boolean>(false)
  const [filterTable, setFilterTable] = useState<categoryProductProcessTableFilterType>({})
  const [addProductDrawerVisible, setAddProductDrawerVisible] = useState<boolean>(false)
  const [willBeAddedProducts, setWillBeAddedProducts] = useState<
    categoryProductsProcessTableDataType[]
  >([])
  const [willBeDeletedProducts, setWillBeDeletedProducts] = useState<
    categoryProductsProcessTableDataType[]
  >([])
  const [selectedProducts, setSelectedProducts] = useState<categoryProductsProcessTableDataType[]>(
    []
  )
  const [constraintListTableFilter, setConstraintListTableFilter] =
    useState<constraintListTableFilterTye>({})
  const [unSelectAllItems, setUnSelectAllItems] = useState<number>(0)
  const [visibleTextFilter, setVisibleTextFilter] = useState<boolean>(false)
  const [visibleConstraintListFilter, setVisibleConstraintListFilter] = useState<boolean>(false)
  const [textFilter, setTextFilter] = useState<productTextSearchType>()
  const [willBeUpdatedProduct, setWillBeUpdatedProduct] =
    useState<categoryConstraintUpdateDataType | null>(null)
  const [visibleCategoryProductModal, setVisibleCategoryProductModal] = useState<boolean>(false)
  const [visibleCategoryConstraintProductModal, setVisibleCategoryConstraintProductModal] =
    useState<boolean>(false)
  const [categoryConstraintProducts, setCategoryConstraintProducts] = useState<any[]>([])

  const {categoryId} = useParams<categoryUrlType>()
  const getCategoryProductRef = useRef(0)
  const navigate = useNavigate()

  const initialRef = useRef(0)
  const fetchRef = useRef(0)

  const [selectedConstraintProducts, setSelectedConstraintProducts] = useState<any[]>([])
  const [unSelectAllConstraintProducts, setUnSelectAllConstraintProducts] = useState<number>(0)
  const [willBeUpdatedBatchConstraintProducts, setWillBeUpdatedBatchConstraintProducts] =
    useState<categoryConstraintUpdateDataType | null>(null)
  const [pageType, setPageType] = useState<string>(PROCESS_AND_PRODUCT_LISTING)

  useEffect(() => {
    if (!visibleCategoryProductModal) {
      setWillBeUpdatedProduct(null)
    }
  }, [visibleCategoryProductModal])

  useEffect(() => {
    if (!willBeUpdatedProduct) {
      return
    }

    setVisibleCategoryProductModal(true)
  }, [willBeUpdatedProduct])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      updateMeta({page: 1})
      return
    }

    getCategoryProducts()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getCategoryProducts()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getCategoryProducts()
  }, [willBeDeletedProducts])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== initialMetaData.page || meta.limit !== initialMetaData.limit) {
      setMeta((meta) => ({...meta, ...initialMetaData}))
      return
    }

    getCategoryProducts()
  }, [filterTable])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    setTextFilter(undefined)

    if (!objectIsEmpty(filterTable) && !visibleTextFilter) {
      getCategoryProducts()
    }
  }, [visibleTextFilter])

  useEffect(() => {
    if (initialRef.current === 0 || textFilter === undefined) {
      return
    }

    getCategoryProducts()
  }, [textFilter])

  useEffect(() => {
    initialRef.current = 1

    if (initialRef.current !== 0) {
      getCategoryProducts()
    }

    getCategoryRequest(Number(categoryId), ['description']).then((res) => {
      setCategory(res.data.data)
    })
  }, [])

  useEffect(() => {
    if (!category) {
      return
    }

    if (category.category_type === 'campaign') {
      setPageTitle(`Kampanya Ürün İşlemleri - ${category?.details?.name}`)
    }

    if (category.category_type === 'category') {
      setPageTitle(`Kategori Ürün İşlemleri - ${category?.details?.name}`)
    }

    if (category.category_type === 'boutique') {
      setPageTitle(`Butik Ürün İşlemleri - ${category?.details?.name}`)
    }

    getCategoryProducts()
  }, [category])

  const handleOnConstraintFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setConstraintListTableFilter((data) => {
        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [constraintListTableFilter])

  const getCategoryProducts = () => {
    getCategoryProductRef.current += 1
    setIsProductsToTableLoading(() => true)

    const fetchId = getCategoryProductRef.current

    const willBeDeletedProductIds = willBeDeletedProducts.map((product) => {
      return product.id
    })

    let productIndexParams = {
      categoryId: Number(categoryId),
      page: meta.page,
      limit: meta.limit,
      relations: ['description'],
      excludedProductIds: willBeDeletedProductIds,
      filterTable,
    } as getCategoryProductsType

    if (visibleTextFilter) {
      productIndexParams.filterTable = {}
      if (textFilter) {
        productIndexParams.filter = textFilter
      }
    }

    categoryProductsRequest(productIndexParams)
      .then((res) => {
        if (fetchId !== getCategoryProductRef.current) {
          return
        }

        let products = getFormattedProductsForTable(res.data.data)

        if (meta.page === 1 && objectIsEmpty(filterTable)) {
          products = getAddItemToArray(willBeAddedProducts, products, true)
        }

        const _meta = res.data.meta
        setMeta({..._meta, limit: _meta.per_page, page: _meta.current_page})
        setProductsToTable(products)
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          navigate('/error/404')
        }
      })

    setIsProductsToTableLoading(() => false)
  }

  const updateSelectedCategoryProduct = (values: any) => {
    setWillBeUpdatedProduct(values)
    updateCategoryProductInformationRequest(values.category_id, values).then((r) => {
      getCategoryConstraintProducts()
      if (r.status === 204) {
        message.success({content: 'Başarıyla güncellendi.'})
        setVisibleCategoryProductModal(false)
      } else {
        message.error({content: 'Bir hata oluştu.'})
      }
    })
  }

  const updateSelectedConstraintProducts = (values: any) => {
    setWillBeUpdatedBatchConstraintProducts(values)
    updateCategoryProductInformationRequest(values.category_id, values)
      .then((r) => {
        getCategoryConstraintProducts()
        if (r.status === 204) {
          message.success({content: 'Başarıyla güncellendi.'})
          setVisibleCategoryConstraintProductModal(false)
          setUnSelectAllConstraintProducts((data) => data + 1)
        } else {
          message.error({content: 'Bir hata oluştu.'})
        }
      })
      .catch((err) => {
        message.error({content: 'Bir hata oluştu.' + err.response.data.message})
      })
  }

  const deleteSelectedConstraintProduct = (values: any) => {
    deleteCategoryProductRequest(values.category_id, values)
      .then((r) => {
        getCategoryConstraintProducts()
        if (r.status === 204) {
          message.success({content: 'Başarıyla listeden kaldırıldı.'})
        } else {
          message.error({content: 'Bir hata oluştu.'})
        }
      })
      .catch((err) => {
        message.error({content: 'Bir hata oluştu.' + err.response.data.message})
      })
  }

  const getCategoryConstraintProducts = () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    // @ts-ignore
    getCategoryConstraintProductsRequest(category.category_id, {
      limit: meta.limit,
      page: meta.page,
      ...constraintListTableFilter,
    }).then((res) => {
      if (fetchRef.current !== fetchId) {
        return
      }
      const constraintProducts = res.data.data.map((constraintProduct: constraintProductType) => {
        return {
          id: constraintProduct.id,
          model: constraintProduct.product.model,
          stock: constraintProduct.stock,
          soldStock: constraintProduct.sold_stock,
          remainingStock: constraintProduct.remaining_stock,
          startDate: constraintProduct.start_date
            ? moment(constraintProduct.start_date).format('DD.MM.YYYY HH:mm:ss')
            : '-',
          endDate: constraintProduct.end_date
            ? moment(constraintProduct.end_date).format('DD.MM.YYYY HH:mm:ss')
            : '-',
          type: constraintProduct.type,
          name: constraintProduct.product.minimal_details?.name,
        }
      })
      const restMeta = res.data.meta
      setMeta({limit: restMeta.per_page, page: restMeta.current_page, ...restMeta})
      setCategoryConstraintProducts(constraintProducts)
    })
  }

  const updateCategoryProduct = (isUrgentApproval: boolean = false) => {
    if (willBeAddedProducts.length === 0 && willBeDeletedProducts.length === 0) {
      message.warn('Kategoriye ürün işlemleri için lütfen ürün seçiniz.')
      return
    }

    const key = 'adding-product-to-category'
    message.loading({content: 'Ürün(ler) ekleniyor/çıkarılıyor...', key})

    const willBeAddedProductsWithConstraint = willBeAddedProducts.map((product) => {
      if (objectIsEveryEmpty(product.constraint)) {
        return {product_id: product.id}
      }

      return {
        product_id: product.id,
        constraint: {
          ...product.constraint,
          start_date: requestDateFormat(product.constraint?.start_date),
          end_date: requestDateFormat(product.constraint?.end_date),
        },
      }
    })

    const willBeDeletedProductsMapped = willBeDeletedProducts.map((product) => {
      return {
        product_id: product.id,
      }
    })

    updateCategoryProductRequest(Number(categoryId), {
      remove_products: willBeDeletedProductsMapped,
      add_products: willBeAddedProductsWithConstraint,
      is_urgent_approval: isUrgentApproval,
    })
      .then((res) => {
        if (
          res.data.error_message !== null &&
          res.data.error_message &&
          res.data.error_message.length > 0
        ) {
          KrcWarningNotification({
            data: res.data.error_message,
            title: 'Kategoriye eklenemeyen ürünler',
            copyKey: 'product_id',
          })
        }

        setWillBeAddedProducts([])
        setWillBeDeletedProducts([])
        setSelectedProducts([])
        setUnSelectAllItems((data) => data + 1)

        if (res.data.success) {
          message.success({content: 'Başarıyla kaydedildi yakında yayınlanacak.', key})
        } else {
          message.destroy(key)
        }
      })
      .catch(() => {
        message.error({content: 'Bir hata oluştu sayfayı yenileyerek tekrar deneyiniz.', key})
      })
  }

  /*
  Burada özel bir case'i handle ediyoruz. 1000+ Filtrelenen ürünleri kategoriye direkt olarak göndererek
  eklemek için mevcut yapının dışına çıkmamız gerekiyor.

  Normalde kategoriye ürün eklemek için ürünleri tek tek seçip kategoriye eklemek gerekiyor.
  Sonra da kategoride listeleniyor. Fakat 1000+ ürünü kategoriye eklemek için bu yöntem çok uzun sürüyor.
  Ayrıca kod kompleksleşiyor.

  Bu yüzden 1000+ ürünü kategoriye eklemek için özel bir javascript methodu var. Bu endpoint'e 1000+ ürünü tek seferde
  model ve product_id bilgisi karışık şekilde  endpointe gönderilecek.

  Endpointte bu case handle edilecek.
   */
  const addCategoryProductByFilteredProducts = (
    productFilters: productTextAreaFilterDataType,
    constraint?: constraintProToCatInitValueType
  ) => {
    let willBeAddedProductsWithConstraint: object[] = []

    const productFiltersByProductId = productFilters.productIds.map((productId) => {
      if (objectIsEveryEmpty(constraint)) {
        return {product_id: productId}
      }

      return {
        product_id: productId,
        constraint: constraint,
      }
    })

    if (productFiltersByProductId.length) {
      willBeAddedProductsWithConstraint =
        willBeAddedProductsWithConstraint.concat(productFiltersByProductId)
    }

    const productFiltersByModel = productFilters.models.map((model) => {
      if (objectIsEveryEmpty(constraint)) {
        return {model: model}
      }

      return {
        model: model,
        constraint: constraint,
      }
    })

    if (productFiltersByModel.length) {
      willBeAddedProductsWithConstraint =
        willBeAddedProductsWithConstraint.concat(productFiltersByModel)
    }

    if (willBeAddedProductsWithConstraint.length === 0) {
      message.warn('Kategoriye ürün eklemek ve kaydetmek için lütfen ürün seçiniz.')
      return
    }

    const key = 'adding-product-to-category'
    message.loading({content: 'Ürün(ler) ekleniyor...', key})

    updateCategoryProductRequest(Number(categoryId), {
      add_products: willBeAddedProductsWithConstraint,
      live_add_products: true,
    })
      .then((res) => {
        if (
          res.data.error_message !== null &&
          res.data.error_message &&
          res.data.error_message.length > 0
        ) {
          KrcWarningNotification({
            data: res.data.error_message,
            title: 'Kategoriye eklenemeyen ürünler',
            copyKey: 'product_id',
          })
        }

        setWillBeAddedProducts([])
        setWillBeDeletedProducts([])
        setSelectedProducts([])
        setUnSelectAllItems((data) => data + 1)

        if (res.data.success) {
          message.success({content: 'Başarıyla kaydedildi yakında yayınlanacak.', key})
        } else {
          message.destroy(key)
        }
      })
      .catch((e) => {
        const errMsg = errorMessage(e) || 'Bir hata oluştu sayfayı yenileyerek tekrar deneyiniz.'

        message.error({content: errMsg, key})
      })
  }

  const removeCategoryProductByFilteredProducts = (productFilters: productTextSearchType) => {
    let willBeDeletedProducts: object[] = []

    if (productFilters?.productIds) {
      const productFiltersByProductId = productFilters.productIds.map((productId) => {
        return {
          product_id: productId,
        }
      })

      if (productFiltersByProductId.length) {
        willBeDeletedProducts = willBeDeletedProducts.concat(productFiltersByProductId)
      }
    }

    if (productFilters?.models) {
      const productFiltersByModel = productFilters.models.map((model) => {
        return {
          model: model,
        }
      })

      if (productFiltersByModel.length) {
        willBeDeletedProducts = willBeDeletedProducts.concat(productFiltersByModel)
      }
    }

    if (willBeDeletedProducts.length === 0) {
      message.warn('Kategoriye ürün eklemek ve kaydetmek için lütfen ürün seçiniz.')
      return
    }

    const key = 'removing-product-to-category'
    message.loading({content: 'Ürün(ler) siliniyor...', key})

    updateCategoryProductRequest(Number(categoryId), {
      remove_products: willBeDeletedProducts,
      live_remove_products: true,
    })
      .then((res) => {
        if (
          res.data.error_message !== null &&
          res.data.error_message &&
          res.data.error_message.length > 0
        ) {
          KrcWarningNotification({
            data: res.data.error_message,
            title: 'Kategoriden silinemeyen ürünler',
            copyKey: 'product_id',
          })
        }

        setWillBeAddedProducts([])
        setWillBeDeletedProducts([])
        setSelectedProducts([])
        setUnSelectAllItems((data) => data + 1)

        if (res.data.success) {
          message.success({content: 'Başarıyla kaydedildi yakında yayınlanacak.', key})
        } else {
          message.destroy(key)
        }
      })
      .catch((e) => {
        const errMsg = errorMessage(e) || 'Bir hata oluştu sayfayı yenileyerek tekrar deneyiniz.'

        message.error({content: errMsg, key})
      })
  }

  const getFormattedProductsForTable = (
    products: minimalProductType[]
  ): categoryProductsProcessTableDataType[] => {
    return products.map((product: minimalProductType): categoryProductsProcessTableDataType => {
      return {
        id: product.product_id,
        name: product.details?.name ?? null,
        model: product.model,
        dateAdded: viewDateFormat(product.date_added),
      }
    })
  }

  const handleOnSelectedRangePicker = (date: Moment[] | null) => {
    if (!date) {
      updateFilterTable({date_added_end: undefined, date_added_start: undefined})
      return
    }

    setFilterTable((data) => {
      return {
        ...data,
        date_added_start: requestDateFormat(date[0], 'YYYY-MM-DD'),
        date_added_end: requestDateFormat(date[1], 'YYYY-MM-DD'),
      }
    })
  }

  const updateMeta = (data: object) => {
    setMeta((item) => ({...item, ...data}))
  }

  const updateFilterTable = (data: object) => {
    setFilterTable((filterTable) => {
      return addObjectToObject(filterTable, data, true)
    })
  }

  const addSelectedProductsToWillBeDeletedProducts = () => {
    if (selectedProducts.length === 0) {
      return
    }

    confirm({
      title: `${selectedProducts.length} ürün kategoriden çıkartılacaktır.`,
      icon: <ExclamationCircleOutlined />,
      content: 'Silme işlemi kaydet buttonuna basılana kadar gerçekleşmeyecektir.',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        const _selectedProducts = selectedProducts.filter((selectedProduct) => {
          return !willBeAddedProducts.some(
            (willBeAddedProduct) => willBeAddedProduct.id === selectedProduct.id
          )
        })
        setWillBeDeletedProducts((willBeDeletedProducts) => {
          return getAddItemToArray(willBeDeletedProducts, _selectedProducts, true)
        })

        setWillBeAddedProducts((products) => {
          return products.filter((product) => {
            return !selectedProducts.some((selectedProduct) => {
              return selectedProduct.id === product.id
            })
          })
        })
        setUnSelectAllItems((data) => data + 1)
        setSelectedProducts([])
      },
    })
  }

  const addWillBeAddedProducts = (
    products: any[],
    constraint?: constraintProToCatInitValueType
  ) => {
    let _products = products
    if (constraint) {
      _products = products.map((product) => {
        return {...product, constraint}
      })
    }

    setWillBeAddedProducts((items) => getAddItemToArray(_products, items, true))
    setWillBeDeletedProducts((items) => {
      return items.filter((item) => !_products.some((product) => product.id === item.id))
    })
    addProductToTable(_products)
  }

  const addProductToTable = (products: categoryProductsProcessTableDataType[]) => {
    setProductsToTable((items) => {
      return getAddItemToArray(products, items, true)
    })
  }

  const getExportCategoryProductsUrl = () => {
    let params = {
      categoryId: Number(categoryId),
      deletedProductIds: willBeDeletedProducts.map((item) => item.id),
      filterTable,
      excludedProductIds: willBeDeletedProducts.map((item) => item.id),
    } as getCategoryProductsExportType

    if (visibleTextFilter) {
      params.filter = textFilter
    }

    return getCategoryProductExportFrontendUrl(params)
  }

  const handleOnFilter = React.useMemo(() => {
    const filter = (value: any, id: string | number) => {
      updateFilterTable({[id]: value})
    }

    return debounce(filter, 250)
  }, [])

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 110,
        manualWidth: 110,
      },
      {
        Header: 'Model',
        accessor: 'model',
        maxWidth: 220,
        manualWidth: 220,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
        maxWidth: 700,
        manualWidth: 700,
      },
      {
        Header: 'Eklenme tarihi',
        accessor: 'dateAdded',
        maxWidth: 220,
        manualWidth: 220,
        Filter: (
          <KrcRangePicker
            onSelected={handleOnSelectedRangePicker}
            size={'middle'}
            format={'DD.MM.YYYY'}
          />
        ),
      },
    ]
  }, [])

  return (
    <>
      <CategoryProductsProcessContext.Provider
        value={{
          meta,
          columns,
          productsToTable,
          getCategoryProducts,
          updateMeta,
          updateFilterTable,
          handleOnFilter,
          category,
          pageTitle,
          addProductDrawerVisible,
          setAddProductDrawerVisible,
          willBeAddedProducts,
          addWillBeAddedProducts,
          updateCategoryProduct,
          willBeDeletedProducts,
          setWillBeDeletedProducts,
          selectedProducts,
          setSelectedProducts,
          addSelectedProductsToWillBeDeletedProducts,
          unSelectAllItems,
          visibleTextFilter,
          setVisibleTextFilter,
          setTextFilter,
          getExportCategoryProductsUrl,
          visibleConstraintListFilter,
          setVisibleConstraintListFilter,
          addCategoryProductByFilteredProducts,
          removeCategoryProductByFilteredProducts,
          setWillBeUpdatedProduct,
          visibleCategoryProductModal,
          setVisibleCategoryProductModal,
          willBeUpdatedProduct,
          updateSelectedCategoryProduct,
          getCategoryConstraintProducts,
          categoryConstraintProducts,
          selectedConstraintProducts,
          setSelectedConstraintProducts,
          unSelectAllConstraintProducts,
          visibleCategoryConstraintProductModal,
          setVisibleCategoryConstraintProductModal,
          willBeUpdatedBatchConstraintProducts,
          setWillBeUpdatedBatchConstraintProducts,
          updateSelectedConstraintProducts,
          textFilter,
          pageType,
          setPageType,
          isProductsToTableLoading,
          deleteSelectedConstraintProduct,
          handleOnConstraintFilter,
          constraintListTableFilter,
          setConstraintListTableFilter,
          initialRef,
        }}
      >
        {children}
      </CategoryProductsProcessContext.Provider>
    </>
  )
}
