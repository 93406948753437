import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''

export const updateSpecialProductCart = (productIds: number[], storeId: string) => {
  return axios.put(`${API_URL}/products/special-cart/update`, {
    product_ids: productIds,
    data_areas_id: storeId,
  })
}

export const updateAllSpecialProductCart = (willBeTriggeredStore: string) => {
  return axios.put(`${API_URL}/products/special-cart/update-all`, {
    data_areas_id: willBeTriggeredStore,
  })
}
