import * as querystring from 'query-string'
import {useNavigate, useParams} from 'react-router-dom'

import {categoryProductExportRequest} from '../../../../requests/categories/products/exports/CategoryProductExportRequest'
import {categoryUrlType} from '../../../models/categories/CategoryProductModel'
import {useExport} from '../../exports/useExport'

export const useCategoryProducts = () => {
  const navigate = useNavigate()
  const {categoryId} = useParams<categoryUrlType>()
  const {checkExport, downloadExcelUrl} = useExport()

  const exportCategoryProducts = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: true,
    })

    categoryProductExportRequest(Number(categoryId), params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  return {exportCategoryProducts, downloadExcelUrl}
}
