import {EyeOutlined} from '@ant-design/icons'
import {Checkbox, Empty} from 'antd'
import {OptionData} from 'rc-select/lib/interface'
import React, {FC, useCallback, useContext} from 'react'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {useNavigate} from 'react-router-dom'

import {LayoutContext} from '../../../providers/layouts/LayoutProvider'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {CopyLayoutModal} from '../../modals/layouts/CopyLayoutModal'
import {CreateLayoutModal} from '../../modals/layouts/CreateLayoutModal'
import {layoutComponentDetailDataType} from '../../models/layouts/LayoutModel'
import {StoreFilter} from '../../molecules/filters/StoreFilter'
import {ComponentCreateButton} from '../../molecules/layouts/ComponentCreateButton'
import {DndItemEditable} from '../../organisms/dnd/DndItemEditable'

export const LayoutManagement: FC = () => {
  const {
    layoutComponents,
    currentStore,
    pages,
    currentPage,
    addComponent,
    changeCurrentStore,
    changeCurrentLayout,
    createLayoutModalVisible,
    copyLayoutModalVisible,
    updateCreateLayoutModalVisible,
    updateCopyLayoutModalVisible,
    setLayoutComponents,
    pagePublish,
    components,
    deleteComponent,
    setIsDefaultPage,
    isDefaultPage,
    disabledSortButton,
    sortComponents,
    updateVisibleLayoutComponent,
  } = useContext(LayoutContext)

  const navigator = useNavigate()

  const renderComponent = useCallback((component: layoutComponentDetailDataType, index: number) => {
    return (
      <DndItemEditable
        index={index}
        key={component.reactKey}
        id={component.id}
        image={component.image}
        setState={setLayoutComponents}
        status={component.status === '1'}
        subTitle={component.name}
        isEditable={!!component.is_created}
        isVisibleButton={!!component.id}
        targetType={'target-component'}
        title={component.component_name}
        onRemove={handleOnRemove}
        onChangeVisible={() => {
          handleOnChangeVisible(component.id)
        }}
        onClickEdit={handleOnClickEdit}
      />
    )
  }, [])

  const handleOnRemove = (componentId: string | number) => {
    deleteComponent(Number(componentId))
  }

  const handleOnChangeVisible = (componentId: number) => {
    updateVisibleLayoutComponent(componentId)
  }

  const handleOnClickEdit = (id: string | number) => {
    navigator(`/layouts/components/${id}`)
  }

  return (
    <>
      <div className={'d-flex flex-column col-9 col-md-9'}>
        <div className={'d-flex flex-row justify-content-between mb-8'}>
          <div>
            <KrcButton
              type={'default'}
              onClick={() => {
                updateCreateLayoutModalVisible(true)
              }}
            >
              Yeni Layout
            </KrcButton>
            <KrcButton
              type={'default'}
              className={'ml-3'}
              onClick={() => {
                updateCopyLayoutModalVisible(true)
              }}
            >
              Layout Kopyala
            </KrcButton>
          </div>
          <div className={'d-flex flex-row gap-5'} style={{marginRight: -28}}>
            <StoreFilter
              value={currentStore}
              className={'select-filter-custom'}
              placeholder={'Lütfen seçiniz'}
              showSearch={false}
              dataType={'item'}
              mode={undefined}
              dropdownStyle={{zIndex: 99999}}
              storeType={'company'}
              onSelected={(stores) => {
                if (!stores) {
                  return
                }

                changeCurrentStore(stores[0] as OptionData)
              }}
            />
            <KrcSelect
              value={currentPage}
              style={{minWidth: '200px'}}
              options={pages}
              allowClear={false}
              onChange={(layoutId) => {
                changeCurrentLayout(layoutId)
              }}
            />
            <KrcButton type={'default'} icon={<EyeOutlined />} className={'p-2'}></KrcButton>
          </div>
        </div>
        <div className={'d-flex gap-9'}>
          <div
            className={'d-flex flex-column gap-3 col-3 mh-500px overflow-auto'}
            style={{overflowX: 'hidden'}}
          >
            {components.map((button) => {
              return (
                <ComponentCreateButton
                  key={button.type}
                  title={button.name}
                  onClick={() => addComponent(button.type)}
                />
              )
            })}
          </div>
          <div className={'d-flex flex-column col-9 gap-5'}>
            {layoutComponents.length === 0 && (
              <Empty
                image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                imageStyle={{
                  height: 60,
                }}
                description={'Herhangi bir component bulunmamaktadır.'}
              />
            )}
            <DndProvider backend={HTML5Backend}>
              {layoutComponents.map((component, index) => {
                return renderComponent(component, index)
              })}
            </DndProvider>
            {layoutComponents.length > 0 && (
              <div className={'mt-10 mb-5'}>
                <div className={'d-flex align-items-center justify-content-end gap-5'}>
                  <Checkbox
                    checked={isDefaultPage}
                    onChange={(e) => {
                      setIsDefaultPage(e.target.checked)
                    }}
                  >
                    Varsayılan olarak ata
                  </Checkbox>
                  <KrcButton
                    type={'default'}
                    size={'large'}
                    style={{width: 200}}
                    disabled={disabledSortButton}
                    onClick={sortComponents}
                  >
                    Sıralamayı Kaydet
                  </KrcButton>
                  <KrcButton
                    type={'text'}
                    size={'large'}
                    className={'custom-hover text-white w-200px button-bg-purple'}
                    onClick={pagePublish}
                  >
                    Yayınla
                  </KrcButton>
                </div>
              </div>
            )}
          </div>
        </div>
        {createLayoutModalVisible && <CreateLayoutModal />}
        {copyLayoutModalVisible && <CopyLayoutModal />}
      </div>
    </>
  )
}
