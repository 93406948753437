import {DeleteOutlined} from '@ant-design/icons'
import {Popconfirm, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {createContext, useEffect, useRef, useState} from 'react'
import {Column} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {KrcRangePicker} from '../../components/atoms/forms/KrcRangePicker'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {
  excludedProductType,
  prefixType,
  tableFilterExludedProductParamsType,
} from '../../components/models/campaign_and_coupon/ExcludedProductModel'
import {errorMessage} from '../../helpers/ErrorHelper'
import {
  addExcludedCouponProductRequest,
  fetchExcludedCouponProductsRequest,
  getCouponPrefixesRequest,
  removeExcludedCouponProductRequest,
} from '../../requests/campaign_and_coupon/CouponPrefixRequests'
import {requestDateFormat} from '../../service/DateService'
import {categoryProductsProcessTableDataType} from '../category/products/CategoryProductsProcessProvider'

export type productsProcessTableDataType = {
  id: number
  name: string | null
  model: string
}

interface IExcludeCouponContext {
  isShowCreateExcludeCouponDrawer: boolean
  setIsShowCreateExcludeCouponDrawer: (isShowCreateExcludeCouponDrawer: boolean) => void
  addExcludedCouponProduct: () => void
  selectedProducts: productsProcessTableDataType[]
  setSelectedProducts: any
  willBeExcludedCouponPrefixId: number | null
  setWillBeExcludedCouponPrefixId: React.Dispatch<React.SetStateAction<number | null>>
  columns: Column<any>[]
  handleOnFilter: (data: any, key: string | number) => void
  couponPrefixOptions: prefixType[]
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  excludedProductsData: excludedProductType[]
  setExcludedProductsData: (data: excludedProductType[]) => void
  fetchExcludedCouponProducts: (params?: any) => void
}

export const ExcludeCouponContext = createContext<IExcludeCouponContext>({
  isShowCreateExcludeCouponDrawer: false,
  setIsShowCreateExcludeCouponDrawer: () => {},
  addExcludedCouponProduct: () => {},
  selectedProducts: [],
  setSelectedProducts: () => {},
  columns: [],
  handleOnFilter: () => {},
  couponPrefixOptions: [],
  meta: {limit: 10, page: 1},
  setMeta: () => {},
  excludedProductsData: [],
  setExcludedProductsData: () => {},
  fetchExcludedCouponProducts: () => {},
  willBeExcludedCouponPrefixId: null,
  setWillBeExcludedCouponPrefixId: () => {},
})

export const ExcludeCouponProvider = ({children}: {children: React.ReactNode}) => {
  const [isShowCreateExcludeCouponDrawer, setIsShowCreateExcludeCouponDrawer] =
    useState<boolean>(false)
  const [selectedProducts, setSelectedProducts] = useState<categoryProductsProcessTableDataType[]>(
    []
  )
  const [filterExcludeCouponProductSearchData, setFilterExcludeCouponProductSearchData] =
    useState<tableFilterExludedProductParamsType>({
      product_name: undefined,
      product_model: undefined,
      product_id: undefined,
      coupon_prefix_name: undefined,
      added_by: undefined,
      added_at: undefined,
    })

  const [couponPrefixOptions, setCouponPrefixOptions] = useState<prefixType[]>([])
  const [meta, setMeta] = useState<paginationMetaType>({limit: 10, page: 1})
  const [excludedProductsData, setExcludedProductsData] = useState<excludedProductType[]>([])
  const initialRef = useRef(0)
  const [willBeExcludedCouponPrefixId, setWillBeExcludedCouponPrefixId] = useState<number | null>(
    null
  )

  useEffect(() => {
    fetchCouponPrefixOptions()
  }, [])

  useEffect(() => {
    setSelectedProducts([])
  }, [isShowCreateExcludeCouponDrawer])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }
    fetchExcludedCouponProducts()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    fetchExcludedCouponProducts()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }

    fetchExcludedCouponProducts()
  }, [filterExcludeCouponProductSearchData])

  useEffect(() => {
    initialRef.current = 1

    fetchExcludedCouponProducts()
  }, [])

  const fetchCouponPrefixOptions = () => {
    getCouponPrefixesRequest({}, {page: 1, limit: 10}, 1)
      .then((response) => {
        setCouponPrefixOptions(response.data.data)
      })
      .catch((error) => {
        setCouponPrefixOptions([])
        errorMessage(error)
        message.error(error)
      })
  }

  const fetchExcludedCouponProducts = (params = {}) => {
    fetchExcludedCouponProductsRequest({
      limit: meta.limit,
      page: meta.page,
      ...filterExcludeCouponProductSearchData,
    })
      .then((response) => {
        setExcludedProductsData(response.data.data)
        const _meta = response.data.meta
        setMeta({..._meta, limit: _meta.per_page, page: _meta.current_page})
      })
      .catch((error) => {
        errorMessage(error)
        message.error(error)
      })
  }

  const addExcludedCouponProduct = () => {
    if (selectedProducts.length === 0) {
      message.error('Lütfen ürün seçiniz')
      return
    }
    if (!willBeExcludedCouponPrefixId) {
      message.error('Lütfen bir prefix seçiniz')
      return
    }
    const requestBody = {
      coupon_prefix_id: willBeExcludedCouponPrefixId,
      product_ids: selectedProducts.map((product) => product.id),
    }
    addExcludedCouponProductRequest(requestBody)
      .then((response) => {
        message.success('Ürünler başarıyla eklendi')
        setIsShowCreateExcludeCouponDrawer(false)
        fetchExcludedCouponProducts()
      })
      .catch((error) => {
        errorMessage(error)
        message.error('Ürünler eklenirken bir hata oluştu.')
      })
  }

  const removeExcludedCouponProduct = (excludedId: number) => {
    removeExcludedCouponProductRequest(excludedId)
      .then((response) => {
        message.success('Ürün başarıyla kaldırıldı.')
        fetchExcludedCouponProducts()
      })
      .catch((error) => {
        errorMessage(error)
        message.error('Ürünü kaldırırken bir hata oluştu.')
      })
  }

  const handleOnFilter = React.useMemo(() => {
    const filter = (value: any, id: string | number) => {
      setFilterExcludeCouponProductSearchData((data) => ({...data, [id]: value}))
    }

    return debounce(filter, 500)
  }, [filterExcludeCouponProductSearchData])

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'excluded_id',
        Filter: KrcEmptyFilter,
        manualWidth: 50,
        maxWidth: 50,
      },
      {
        Header: 'Prefix',
        accessor: 'coupon_prefix_name',
        manualWidth: 120,
        maxWidth: 120,
      },
      {
        Header: 'Ürün ID',
        accessor: 'product_id',
        manualWidth: 130,
        maxWidth: 130,
      },
      {
        Header: 'Ürün Model',
        accessor: 'product_model',
        manualWidth: 200,
        maxWidth: 200,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'product_name',
        manualWidth: 300,
        maxWidth: 300,
      },
      {
        Header: 'Ekleyen Kullanıcı',
        accessor: 'added_by',
        manualWidth: 160,
        maxWidth: 160,
      },
      {
        Header: 'İşlem Tarihi',
        accessor: 'added_at',
        maxWidth: 200,
        manualWidth: 200,
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                style={{minWidth: 100}}
                onSelected={(data) => {
                  let startDate = requestDateFormat(data ? data[0] : null, 'YYYY-MM-DD HH:mm')
                  let endDate = requestDateFormat(data ? data[1] : null, 'YYYY-MM-DD HH:mm')
                  let value: (string | null)[] | undefined = undefined
                  if (startDate || endDate) {
                    value = [startDate, endDate]
                  }
                  setFilterExcludeCouponProductSearchData(
                    (filterPendingApprovalCampaignHistorySearchData) => {
                      return {
                        ...filterPendingApprovalCampaignHistorySearchData,
                        added_at: value,
                      }
                    }
                  )
                }}
                size={'middle'}
                showTime
                format={'DD.MM.YYYY HH:mm'}
              />
            </>
          )
        },
      },
      {
        Header: 'İşlem',
        accessor: 'action',
        Filter: KrcEmptyFilter,
        manualWidth: 60,
        maxWidth: 60,
        Cell: ({row}: any) => {
          return (
            <Tooltip title={'Sil'}>
              <Popconfirm
                title='Bu ürünü kaldırmak istediğinden emin misin ?'
                placement={'left'}
                onConfirm={() => removeExcludedCouponProduct(row.original.excluded_id)}
                okText='Evet'
                cancelText='Hayır'
              >
                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                  <DeleteOutlined />
                </div>
              </Popconfirm>
            </Tooltip>
          )
        },
      },
    ]
  }, [])

  return (
    <ExcludeCouponContext.Provider
      value={{
        isShowCreateExcludeCouponDrawer,
        setIsShowCreateExcludeCouponDrawer,
        addExcludedCouponProduct,
        selectedProducts,
        setSelectedProducts,
        columns,
        handleOnFilter,
        couponPrefixOptions,
        meta,
        setMeta,
        excludedProductsData,
        setExcludedProductsData,
        fetchExcludedCouponProducts,
        willBeExcludedCouponPrefixId,
        setWillBeExcludedCouponPrefixId,
      }}
    >
      {children}
    </ExcludeCouponContext.Provider>
  )
}
