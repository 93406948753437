import axios from 'axios'
import * as querystring from 'query-string'

const API_URL = process.env.REACT_APP_API_URL || ''
export const URL_ALIAS_URL = `${API_URL}/urls/aliases`

export const exportUrlAliasesRequest = (params: object) => {
  return axios.get(`${URL_ALIAS_URL}/export`, {
    params: params,
  })
}

export const checkUrlAliasesExportRequest = ($fileName: string) => {
  return axios.get(`${URL_ALIAS_URL}/check-export`, {
    params: {
      file_name: $fileName,
    },
  })
}

export const getUrlAliasesExportFrontendUrl = (filter?: any) => {
  return `/url/aliases/export?${querystring.stringify(
    {...filter},
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}
