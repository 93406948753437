import React, {FC} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {FaqDetailList} from './FaqDetailList'

const ToolBar: FC = () => {
  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Konular & Sorular</PageTitle>
    </>
  )
}
export const FaqDetailListWrapper: FC = () => {
  return (
    <>
      <ToolBar />
      <FaqDetailList />
    </>
  )
}
