import {ExclamationCircleOutlined} from '@ant-design/icons'
import {List, message} from 'antd'
import confirm from 'antd/es/modal/confirm'
import React, {FC, useContext} from 'react'

import {BulkPriceTriggerContext} from '../../../../providers/products/BulkPriceTriggerProvider'
import {updateSpecialProductCart} from '../../../../requests/products/special_cart/ProductSpecialCartUpdate'
import {ProductSelection} from '../../../layout/filters/ProductSelection'

export const UpdateBulkPriceProductSelectionWrapper: FC = () => {
  const {willBeUpdateProducts, setWillBeUpdateProducts, willBeTriggeredStores} =
    useContext(BulkPriceTriggerContext)
  return (
    <>
      <div className={'row d-flex justify-content-center'}>
        <div className={'col-10'} style={{paddingRight: 50, paddingLeft: 50}}>
          <ProductSelection
            onSelectedItems={(data) => {
              setWillBeUpdateProducts(data ?? [])
            }}
            transferProps={{
              button: {
                title: '',
                isActive: false,
              },
            }}
          />
        </div>
        <div className={'d-flex justify-content-end w-full'}>
          <div
            className={'btn btn-primary mt-3'}
            role={'button'}
            onClick={() => {
              if (!willBeTriggeredStores) {
                message.error('Lütfen bir mağaza seçiniz.', 3)
                return
              }
              if (willBeUpdateProducts.length === 0) {
                message.error('Lütfen en az bir ürün seçiniz.', 3)
                return
              } else if (willBeUpdateProducts.length > 100) {
                confirm({
                  title: 'Uyarı',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Lütfen 100 adetten fazla ürün seçmeyiniz.',
                  okText: 'Tamam',
                  cancelText: 'İptal',
                })
                return
              } else {
                confirm({
                  title: `Yapılan tüm değişiklikler kaydedilecek emin misin ?`,
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <>
                      {willBeUpdateProducts && willBeUpdateProducts?.length > 0 ? (
                        <div>
                          <b>
                            Aşağıda listelenen {willBeUpdateProducts?.length} ürünün fiyatı
                            güncellenecek.{' '}
                          </b>
                          <List
                            size='small'
                            header={
                              <div>
                                <b>Ürün Adları</b>
                              </div>
                            }
                            bordered
                            dataSource={willBeUpdateProducts}
                            renderItem={(item) => <List.Item>{item.name}</List.Item>}
                          />
                        </div>
                      ) : null}
                      <span>
                        Yapılan tüm değişikliklerin uygulanmasını onaylıyor musunuz? Bu işlem geri
                        alınamaz.
                      </span>
                    </>
                  ),
                  okText: 'Tamam',
                  cancelText: 'İptal',
                  onOk() {
                    const productIds = willBeUpdateProducts.map((product) => product.id)
                    updateSpecialProductCart(productIds, willBeTriggeredStores)
                      .then((res) => {
                        message.success('Ürünler güncellenmek üzere kuyruğa gönderildi.')
                      })
                      .catch((err) => {
                        message.error(
                          'Ürünleri güncellerken sorun oluştu: ' + err?.response?.data?.message ||
                            err.message
                        )
                      })
                  },
                })
              }
            }}
          >
            Seçilen {willBeUpdateProducts.length} Ürünün Fiyatını Güncelle
          </div>
        </div>
      </div>
    </>
  )
}
