import {
  CheckCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons'
import {Avatar, Badge, Image, Modal, Tooltip, message} from 'antd'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import moment from 'moment'
import React, {FC, createContext, useEffect, useMemo, useRef, useState} from 'react'
import {Cell, Column} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {KrcNumberInput} from '../../components/atoms/forms/KrcNumberInput'
import {KrcRangePicker} from '../../components/atoms/forms/KrcRangePicker'
import {KrcSelect} from '../../components/atoms/forms/KrcSelect'
import {initialMetaData} from '../../components/hooks/sorting/useSortingWeight'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {
  reviewDataType,
  reviewDrawerDataType,
  reviewFileModelType,
  reviewFilterDataType,
  reviewModelType,
} from '../../components/models/reviews/ReviewModel'
import {DrawerEnum} from '../../enums/DrawerEnum'
import {REVIEW_STATUS_ENUM} from '../../enums/ReviewEnum'
import {ReviewStatusType} from '../../enums/ReviewStatusType'
import {errorMessage} from '../../helpers/ErrorHelper'
import {
  deleteReviewsRequest,
  getReviewsRequest,
  updateStatusReviewsRequest,
} from '../../requests/reviews/ReviewRequest'
import {viewDateFormat} from '../../service/DateService'

const {confirm} = Modal

interface IReviewListContext {
  columns: Column<any>[]
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  meta: paginationMetaType
  handleOnFilter: (value: any, rowId: string | number) => void
  reviews: reviewDataType[]
  selectedReviews: reviewDataType[]
  setSelectedReviews: React.Dispatch<React.SetStateAction<reviewDataType[]>>
  approveReviews: () => void
  archiveReviews: () => void
  deleteReviews: () => void
  getReviews: () => void
  isDrawerTypeAndVisible: DrawerEnum | null
  setIsDrawerTypeAndVisible: React.Dispatch<React.SetStateAction<DrawerEnum | null>>
  drawerData: reviewDrawerDataType | null
  setDrawerData: React.Dispatch<React.SetStateAction<reviewDrawerDataType | null>>
  setUnSelectAllItems: React.Dispatch<React.SetStateAction<number>>
  unSelectAllItems: number
}

export const ReviewListContext = createContext<IReviewListContext>({
  columns: [],
  reviews: [],
  selectedReviews: [],
  meta: initialMetaData,
  isDrawerTypeAndVisible: null,
  drawerData: null,
  unSelectAllItems: 0,
  setIsDrawerTypeAndVisible: () => {},
  setDrawerData: () => {},
  setMeta: () => {},
  handleOnFilter: () => {},
  setSelectedReviews: () => {},
  approveReviews: () => {},
  archiveReviews: () => {},
  deleteReviews: () => {},
  setUnSelectAllItems: () => {},
  getReviews: () => {},
})

export const ReviewListProvider: FC = ({children}) => {
  const [reviews, setReviews] = useState<reviewDataType[]>([])
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [filter, setFilter] = useState<reviewFilterDataType>({})
  const initialRef = useRef(0)
  const [selectedReviews, setSelectedReviews] = useState<reviewDataType[]>([])
  const [isDrawerTypeAndVisible, setIsDrawerTypeAndVisible] = useState<DrawerEnum | null>(null)
  const [drawerData, setDrawerData] = useState<reviewDrawerDataType | null>(null)
  const [unSelectAllItems, setUnSelectAllItems] = useState<number>(0)

  const columns = useMemo(() => {
    return [
      {
        Header: 'Yorum ID',
        accessor: 'id',
        maxWidth: 90,
        manualWidth: 90,
        Filter: () => {
          return (
            <KrcNumberInput
              className={'w-100'}
              placeholder={'ID'}
              min={0}
              onChange={(data) => {
                handleOnFilter(data, 'id')
              }}
            />
          )
        },
      },
      {
        Header: 'Ürün Adı',
        accessor: 'product_name',
        maxWidth: 180,
        manualWidth: 180,
        cellClassName: 'text-break',
      },
      {
        Header: 'Yorum',
        accessor: 'review',
        maxWidth: 250,
        manualWidth: 250,
        Cell: ({row}: any) => {
          return (
            <div className={'text-break'} style={{maxWidth: 200}}>
              {row.original.review}
            </div>
          )
        },
      },
      {
        Header: 'Image',
        accessor: 'images',
        maxWidth: 150,
        manualWidth: 150,
        Filter: KrcEmptyFilter,
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.images.length > 0 && (
                <div className={'position-relative'}>
                  <div className={'d-flex gap-1 w-150px'}>
                    <Image.PreviewGroup>
                      {row.original.images.map((image: reviewFileModelType, key: number) => {
                        return (
                          <Image
                            key={`image-${image.review_file_id}`}
                            width={key > 1 ? 0 : 75}
                            height={key > 1 ? 0 : 50}
                            preview={{visible: false, maskClassName: clsx({'d-none': key > 1})}}
                            src={image.review_full_image_url}
                            className={clsx({'d-none': key > 1})}
                          />
                        )
                      })}
                    </Image.PreviewGroup>
                    {row.original.images.length > 2 && (
                      <Avatar
                        style={{
                          backgroundColor: '#f1416c',
                          verticalAlign: 'middle',
                          right: 0,
                          top: -5,
                        }}
                        className={'position-absolute'}
                        size='small'
                      >
                        +<span>{row.original.images.length - 2}</span>
                      </Avatar>
                    )}
                  </div>
                </div>
              )}
            </>
          )
        },
      },
      {
        Header: 'Ekleyen',
        accessor: 'fullname',
        cellClassName: 'text-break',
        maxWidth: 130,
        manualWidth: 130,
      },
      {
        Header: 'Oylama',
        accessor: 'rating',
        maxWidth: 80,
        manualWidth: 80,
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Puan'}
              options={[
                {value: 1, label: '1'},
                {value: 2, label: '2'},
                {value: 3, label: '3'},
                {value: 4, label: '4'},
                {value: 5, label: '5'},
              ]}
              maxTagCount={'responsive'}
              style={{width: '100%'}}
              className={'ant-selector-border'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }

                handleOnFilter(data, 'rating')
              }}
            />
          )
        },
      },
      {
        Header: 'Durumu',
        accessor: 'status',
        maxWidth: 220,
        manualWidth: 220,
        Cell: ({row}: any) => {
          let title = 'Onay Bekliyor'
          let color = 'gray'

          switch (row.original.status) {
            case REVIEW_STATUS_ENUM.APPROVED:
              title = 'Onaylandı'
              color = 'green'
              break
            case REVIEW_STATUS_ENUM.ARCHIVED:
              title = 'Arşivlendi'
              color = 'blue'
              break
          }

          return (
            <>
              {title} <Badge className={'ml-1'} color={color} />
            </>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Durum'}
              options={[
                {value: null, label: 'Tüm durumlar'},
                {value: REVIEW_STATUS_ENUM.PENDING, label: 'Onay Bekliyor'},
                {value: REVIEW_STATUS_ENUM.APPROVED, label: 'Onaylandı'},
                {value: REVIEW_STATUS_ENUM.ARCHIVED, label: 'Arşivlendi'},
              ]}
              maxTagCount={'responsive'}
              className={'ant-selector-border w-100'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }

                handleOnFilter(data, 'status')
              }}
            />
          )
        },
      },
      {
        Header: 'Yorum Tarihi',
        accessor: 'date_added',
        maxWidth: 155,
        manualWidth: 155,
        Cell: ({row}: any) => viewDateFormat(row.original.date_added),
        Filter: () => {
          return (
            <KrcRangePicker
              size={'middle'}
              style={{borderRadius: 5}}
              onSelected={(value) => {
                if (!value) {
                  handleOnFilter(undefined, 'date_added')
                  return
                }

                handleOnFilter(
                  [value[0].format('YYYY-MM-DD'), value[1].format('YYYY-MM-DD')],
                  'date_added'
                )
              }}
              showTime
              format={'DD.MM.YYYY'}
            />
          )
        },
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        maxWidth: 130,
        manualWidth: 130,
        Filter: <KrcEmptyFilter />,
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row gap-1'>
              <Tooltip title={'Düzenle'}>
                <div
                  onClick={() => {
                    setDrawerData({
                      review_id: row.original.id,
                      customer: {
                        label: row.original.customer.customer_name,
                        value: row.original.customer.customer_id,
                      },
                      product_name: row.original.product_name,
                      review: row.original.review,
                      rating: row.original.rating,
                      status: row.original.status,
                      images: row.original.images ?? [],
                    })
                    setIsDrawerTypeAndVisible(DrawerEnum.UPDATE)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  <EditOutlined />
                </div>
              </Tooltip>
              {row.original.status === REVIEW_STATUS_ENUM.PENDING && (
                <>
                  <Tooltip title={'Onayla'}>
                    <div
                      onClick={() => {
                        approveReview(row.original.id)
                      }}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <CheckCircleOutlined className={'text-success'} />
                    </div>
                  </Tooltip>
                  <Tooltip title={'Arşivle'}>
                    <div
                      onClick={() => {
                        archiveReview(row.original.id)
                      }}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <InboxOutlined className={'text-primary'} />
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
          </>
        ),
      },
    ]
  }, [])

  const handleOnFilter = useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  const approveReviews = () => {
    if (selectedReviews.length === 0) {
      message.warning('En az bir yorum seçiniz')
      return
    }

    confirm({
      title: `Yorum onaylamak istediğinizden emin misiniz?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Yorum onaylamak istediğinizden emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        const reviewIds = selectedReviews.map((review) => review.id)
        updateStatusReviews(reviewIds, ReviewStatusType.APPROVED, true)
      },
    })
  }

  const approveReview = (reviewId: number) => {
    confirm({
      title: `Yorum onaylamak istediğinizden emin misiniz?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Yorum onaylamak istediğinizden emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        updateStatusReviews([reviewId], ReviewStatusType.APPROVED)
      },
    })
  }

  const archiveReviews = () => {
    if (selectedReviews.length === 0) {
      message.warning('En az bir yorum seçiniz')
      return
    }

    confirm({
      title: `Yorum arşivlemek istediğinizden emin misiniz?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Yorum arşivlemek istediğinizden emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        const reviewIds = selectedReviews.map((review) => review.id)
        updateStatusReviews(reviewIds, ReviewStatusType.ARCHIVE, true)
      },
    })
  }

  const archiveReview = (reviewId: number) => {
    confirm({
      title: `Yorum arşivlemek istediğinizden emin misiniz?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Yorum arşivlemek istediğinizden emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        updateStatusReviews([reviewId], ReviewStatusType.ARCHIVE)
      },
    })
  }

  const updateStatusReviews = (reviewIds: number[], status: 1 | 2, isSelectable = false) => {
    const key = 'update-status-reviews'

    const statusText = status === 1 ? 'onaylandı' : 'arşivlendi'
    message.loading({content: `Yorum ${statusText} güncelleniyor...`, key})

    updateStatusReviewsRequest(reviewIds, status)
      .then(() => {
        message.success({content: `Yorum ${statusText} güncellendi.`, key})
        getReviews()

        if (isSelectable) {
          setSelectedReviews([])
          setUnSelectAllItems((i) => i + 1)
        }
      })
      .catch((e) => {
        errorMessage(e)
        message.error({content: `Yorum ${statusText} güncellenemedi.`, key})
      })
  }

  const deleteReviews = () => {
    if (selectedReviews.length === 0) {
      message.warning('En az bir yorum seçiniz')
      return
    }

    confirm({
      title: `Yorumlar silenecek.`,
      icon: <ExclamationCircleOutlined />,
      content: 'Silme işlemi geri alınamaz emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        deleteReviewByReviewIds(
          selectedReviews.map((review) => review.id),
          true
        )
      },
    })
  }

  const deleteReviewByReviewIds = (reviewIds: number[], isSelectable = false) => {
    const key = 'delete-reviews'

    message.loading({content: 'Yorum siliniyor...', key})

    deleteReviewsRequest(reviewIds)
      .then(() => {
        message.success({content: 'Yorum silindi.', key})
        getReviews()

        if (isSelectable) {
          setSelectedReviews([])
          setUnSelectAllItems((i) => i + 1)
        }
      })
      .catch((e) => {
        errorMessage(e)
        message.error({content: 'Yorum silinemedi.', key})
      })
  }

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getReviews()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getReviews()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }

    getReviews()
  }, [filter])

  useEffect(() => {
    initialRef.current = 1

    getReviews()
  }, [])

  const getReviews = () => {
    const requestFilter = {
      fullname: filter.fullname,
      product_name: filter.product_name,
      status: filter.status,
      rating: filter.rating,
      text: filter.review,
      date_added: filter.date_added,
      review_id: filter.id,
    }

    getReviewsRequest(requestFilter, meta).then((res) => {
      const _reviews = res.data.data.map((review: reviewModelType): reviewDataType => {
        return {
          id: review.review_id,
          product_name: `${review.product_description.product_id} - ${review.product_description.name}`,
          review: review.text,
          rating: review.rating.toString(),
          fullname: review.customer?.customer_name ?? 'Kullanıcı bulunamadı.',
          customer: review.customer,
          status: review.status.toString(),
          date_added: moment(review.date_added),
          images: review.images,
        }
      })

      const resMeta = res.data.meta
      setMeta({limit: resMeta.per_page, page: resMeta.current_page, ...resMeta})
      setReviews(_reviews)
    })
  }

  return (
    <>
      <ReviewListContext.Provider
        value={{
          columns,
          meta,
          reviews,
          selectedReviews,
          drawerData,
          isDrawerTypeAndVisible,
          unSelectAllItems,
          setUnSelectAllItems,
          setSelectedReviews,
          setMeta,
          handleOnFilter,
          approveReviews,
          archiveReviews,
          deleteReviews,
          setDrawerData,
          setIsDrawerTypeAndVisible,
          getReviews,
        }}
      >
        {children}
      </ReviewListContext.Provider>
    </>
  )
}
