import React, {FC, useState} from 'react'
import {useParams} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {getIBMCampaignSpecialGroupProductsExportFrontendUrl} from '../../../../requests/ibm/IBMCampaignSpecialGroupRequest'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {useIBMCampaignSpecialGroupProducts} from '../../../hooks/IBM/useIBMCampaignSpecialGroupProducts'
import {IBMSpecialGroupProductsSortModal} from '../../../modals/ibm/sort/IBMSpecialGroupProductsSortModal'
import {paginationMetaType} from '../../../models/GeneralModel'
import {KrcTable} from '../../../organisms/tables/KrcTable'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'

export type IBMCampaignSpecialGroupProductsURlType = {
  campaignId: string
}

export const IBMCampaignSpecialGroupsProducts: FC = () => {
  const {
    campaignSpecialGroupProducts,
    specialGroupProductsColumn,
    specialGroupProductsMeta,
    setSpecialGroupProductsMeta,
    isCampaignTypePacket,
    setCategorySpecialGroupProducts,
  } = useIBMCampaignSpecialGroupProducts()

  const {campaignId} = useParams<IBMCampaignSpecialGroupProductsURlType>()
  const [isShowSortingSpecialGroupProductsModal, setIsShowSortingSpecialGroupProductsModal] =
    useState(false)

  return (
    <div>
      <Toolbar
        rightField={
          <div className='d-flex align-items-center py-1'>
            <div>
              {isCampaignTypePacket && campaignSpecialGroupProducts.length > 1 && (
                <KrcButton
                  size={'middle'}
                  className={'me-4'}
                  type={'default'}
                  onClick={() => {
                    setIsShowSortingSpecialGroupProductsModal(true)
                  }}
                  id='kt_toolbar_primary_button'
                >
                  {'Ürünleri Sırala'}
                </KrcButton>
              )}
            </div>
            <a
              type={'button'}
              className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder mr-3'
              href={getIBMCampaignSpecialGroupProductsExportFrontendUrl(Number(campaignId))}
              target={'_blank'}
              rel='noreferrer'
            >
              Ürünleri Dışarı Aktar
            </a>
          </div>
        }
      ></Toolbar>
      <PageTitle>Special Grup Ürünleri</PageTitle>
      <KrcTable
        columns={specialGroupProductsColumn}
        data={campaignSpecialGroupProducts}
        paginationProps={{
          isPagination: true,
          page: specialGroupProductsMeta.page,
          limit: specialGroupProductsMeta.limit,
          paginationData: specialGroupProductsMeta,
          onChangePage: (page) => {
            setSpecialGroupProductsMeta((data: paginationMetaType) => ({...data, page}))
          },
          onChangeLimit: (limit) => {
            setSpecialGroupProductsMeta((data: paginationMetaType) => ({...data, limit}))
          },
        }}
      />
      {isShowSortingSpecialGroupProductsModal && (
        <IBMSpecialGroupProductsSortModal
          onHide={() => setIsShowSortingSpecialGroupProductsModal(false)}
          onSortComplete={setCategorySpecialGroupProducts}
        />
      )}
    </div>
  )
}
