import {Route, Routes} from 'react-router-dom'

import {UserListWrapper} from '../components/pages/users/UserListWrapper'

export const UserRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<UserListWrapper />} />
      </Routes>
    </>
  )
}
