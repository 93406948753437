import React, {FC, useContext} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {UserContext} from '../../../../providers/user/UserProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {IUserRoleManagementModalProps} from '../../../models/user/UserModel'
import {RoleFilter} from '../../../molecules/filters/RoleFilter'
import {KrcTable} from '../../../organisms/tables/KrcTable'
import {KrcModal} from '../../KrcModal'

export const UserRoleManagementModal: FC<IUserRoleManagementModalProps> = ({onHide}) => {
  const {
    UserRoleData,
    roleColumns,
    willBeAddedRoleIds,
    setWillBeAddedRoleIds,
    assignSelectedRolesToUser,
  } = useContext(UserContext)

  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
        }}
        modalHeaderProps={{
          title: 'Kullanıcı Rollerini Yönet',
          onHide,
        }}
      >
        <Modal.Body className='py-lg-5 px-lg-10'>
          <div className={'d-flex flex-wrap col-12 w-100'}>
            <h4 className={'col-12'}>Kullanıcıya Rol Tanımla</h4>
            <div className={'col-6'}>
              <RoleFilter
                value={willBeAddedRoleIds}
                className={'select-filter-custom'}
                style={{maxWidth: 402}}
                dropdownMatchSelectWidth={true}
                showSearch={true}
                mode={'multiple'}
                dataType={'value'}
                allowClear={true}
                onClear={() => {
                  setWillBeAddedRoleIds([])
                }}
                onSelected={(data) => {
                  setWillBeAddedRoleIds(data as number[])
                }}
                placeholder={'Lütfen seçiniz'}
                dropdownStyle={{zIndex: 99999}}
              />
            </div>
            <div className={'col-3 ml-auto'}>
              <KrcButton size={'middle'} className={'ml-5'} onClick={assignSelectedRolesToUser}>
                Yeni Rol Tanımla
              </KrcButton>
            </div>
          </div>
          <KrcTable columns={roleColumns} data={UserRoleData} />
        </Modal.Body>
      </KrcModal>
    </>
  )
}
