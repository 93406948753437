import {Select} from 'antd'
import {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {INFORMATION_STORES} from '../../../../enums/InformationStoresTypeEnum'
import {
  InformationContext,
  InformationProvider,
} from '../../../../providers/information/InformationProvider'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {InformationList} from './InformationList'

const ToolBar: FC = () => {
  const {selectedStoreId, setSelectedStoreId} = useContext(InformationContext)
  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <span className={'fs-5 mr-3 text-dark fw-bolder'}>Mağazalar:</span>
          {selectedStoreId && (
            <>
              <Select
                className={'ant-selector-border custom-ant-select-selection-placeholder w-full'}
                defaultValue={selectedStoreId.toString()}
                value={selectedStoreId.toString()}
                style={{width: 120}}
                onChange={(data) => setSelectedStoreId(data)}
                options={INFORMATION_STORES.map((store) => {
                  return {value: store.id.toString(), label: store.store_name}
                })}
              />
            </>
          )}
        </div>
      </Toolbar>
      <PageTitle>Bilgi Sayfaları</PageTitle>
      <InformationList />
    </>
  )
}
export const InformationListWrapper: FC = () => {
  return (
    <>
      <InformationProvider>
        <ToolBar />
      </InformationProvider>
    </>
  )
}
