import {PopoverProps} from 'antd/lib/popover'
import React, {ChangeEvent, FC, useState} from 'react'

import {KrcButton} from '../../atoms/buttons/KrcButton'
import {KrcInput, KrcInputType} from '../../atoms/forms/KrcInput'
import {KrcPopover} from '../../atoms/popover/KrcPopover'
import {useKrcProp} from '../../hooks/components/useKrcProp'

interface IKrcPopoverWithInput {
  onSave?: (bannerName: string) => void
  popoverProps?: PopoverProps
  krcInputProps?: KrcInputType
  buttonTitle?: string
}

const popoverConfig = {
  default: {
    trigger: 'click',
    title: 'Default',
  } as PopoverProps,
}

export const KrcPopoverWithInput: FC<IKrcPopoverWithInput> = ({
  children,
  onSave,
  popoverProps,
  krcInputProps,
  buttonTitle = 'Kaydet',
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [closePopover, setClosePopover] = useState<number>(0)

  const currentPopoverProps = useKrcProp({
    config: popoverConfig.default,
    props: popoverProps,
  }).getCurrentProps()

  const handleOnClick = () => {
    if (!onSave) {
      return
    }

    onSave(inputValue)
    setInputValue('')
    setClosePopover(closePopover + 1)
  }

  return (
    <>
      <KrcPopover
        {...currentPopoverProps}
        close={closePopover}
        content={
          <div className={'d-flex flex-column'}>
            <KrcInput
              {...krcInputProps}
              value={inputValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value)
              }}
              className={'form-control'}
            />
            <KrcButton className={'mt-2'} type={'primary'} onClick={handleOnClick}>
              {buttonTitle}
            </KrcButton>
          </div>
        }
      >
        {children}
      </KrcPopover>
    </>
  )
}
