import React, {FC, useContext, useEffect} from 'react'

import {FooterModulesContext} from '../../../../../providers/FooterModulesProvider'
import {KrcTabs} from '../../../../molecules/KrcTabs'
import {Loader} from '../../../../molecules/Loader'
import {FooterModuleInformationTab} from './FooterModuleInformationTab'
import {FooterSubModuleTab} from './FooterSubModuleTab'

interface ModuleProps {
  module: {
    module_id: number
    module_name: string
    module_url: string | null
    module_store: number
    module_status: number
    module_sort: number
  }
}

export const FooterSingleModuleTabWrapper: FC<ModuleProps> = ({module}) => {
  const {getSelectedModuleInformation, isSubModuleTabsLoading} = useContext(FooterModulesContext)

  useEffect(() => {
    getSelectedModuleInformation(module.module_id)
  }, [])

  return (
    <>
      {!isSubModuleTabsLoading ? (
        <KrcTabs
          isActiveKey={false}
          key={module.module_name}
          tabsClassName={'mb-1 mt-2'}
          tabsProps={{defaultActiveKey: 'information'}}
          tabProps={[
            {
              eventKey: 'information',
              title: 'Modül Bilgileri',
              children: <FooterModuleInformationTab moduleInformation={module} />,
              key: 'information',
            },
            {
              eventKey: 'sub-moduls',
              title: 'Başlıklar',
              children: <FooterSubModuleTab selectedModuleId={module.module_id} />,
              key: 'sub-moduls',
            },
          ]}
        />
      ) : (
        <Loader />
      )}
    </>
  )
}
