import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import * as Yup from 'yup'

import {UrlAliasContext} from '../../../../providers/UrlAliasProvider'
import {IUpdateUrlAliasModal} from '../../../models/url_alias/UrlAliasModel'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'
import {KrcModal} from '../../KrcModal'

const schema = Yup.object({
  keyword: Yup.string().required('keyword zorunlu alan'),
  query: Yup.string().required('query zorunlu alan'),
  status: Yup.number().nullable(),
  redirect: Yup.string().nullable(),
  noindex: Yup.string().required('noindex zorunlu alan'),
  page_type: Yup.string().nullable(),
})

const initialValues = {noindex: false}

export const UrlAliasUpdateModal: FC<IUpdateUrlAliasModal> = ({urlAlias, onSubmit, onHide}) => {
  const [initValues, setInitValues] = useState<any>(initialValues)
  const {updateUrlAlias} = useContext(UrlAliasContext)

  useEffect(() => {
    setInitValues({
      keyword: urlAlias.keyword,
      query: urlAlias.query,
      status: Number(urlAlias.status),
      redirect: urlAlias.redirect,
      noindex: Number(urlAlias.noindex),
      page_type: urlAlias.page_type,
    })
  }, [urlAlias])

  const handleOnSubmit = (data: FormikValues) => {
    updateUrlAlias(urlAlias.id, {
      keyword: data.keyword,
      query: data.query,
      status: Number(data.status),
      redirect: data.redirect,
      noindex: data.noindex,
      page_type: data.page_type,
    }).then(() => {
      onSubmit()
    })
  }

  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
        }}
        modalHeaderProps={{
          title: 'Url Alias Güncelle',
          onHide,
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10'>
          <Formik
            validationSchema={schema}
            initialValues={initValues}
            onSubmit={handleOnSubmit}
            validate={setInitValues}
            enableReinitialize
          >
            {() => (
              <Form className='form row' noValidate>
                <KrcFormikField
                  className={'mb-2 col-md-6'}
                  name={'query'}
                  labelTitle={'Query'}
                  required
                >
                  <Field type='text' name='query' className='form-control' autoComplete='off' />
                </KrcFormikField>

                <KrcFormikField
                  className={'mb-2 col-md-6'}
                  name={'keyword'}
                  labelTitle={'Keyword'}
                  required
                >
                  <Field type='text' name='keyword' className='form-control' autoComplete='off' />
                </KrcFormikField>

                <KrcFormikField className={'mb-2 col-md-6'} name={'status'} labelTitle={'Status'}>
                  <Field type='number' name='status' className='form-control' autoComplete='off' />
                </KrcFormikField>

                <KrcFormikField
                  className={'mb-2 col-md-6'}
                  name={'redirect'}
                  labelTitle={'Redirect (301 Seçili ise çalışacaktır.)'}
                >
                  <Field type='text' name='redirect' className='form-control' autoComplete='off' />
                </KrcFormikField>

                <KrcFormikField
                  className={'mb-2 col-md-6'}
                  name={'noindex'}
                  labelTitle={'No Index'}
                  required
                >
                  <Field as='select' name='noindex' className='form-select'>
                    <option value='1'>Evet</option>
                    <option value='0'>Hayır</option>
                  </Field>
                </KrcFormikField>

                <KrcFormikField
                  className={'mb-2 col-md-6'}
                  name={'page_type'}
                  labelTitle={'Page Type'}
                >
                  <Field type='text' name='page_type' className='form-control' autoComplete='off' />
                </KrcFormikField>

                <div className={'d-flex justify-content-end mt-7'}>
                  <button type='submit' className='btn btn-success' style={{marginLeft: 10}}>
                    Güncelle
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
