import React, {FC} from 'react'

import {MANUAL_SORTING, PARAMETRIC_SORTING} from '../../../../../enums/CategorySortingPageTypeEnum'
import {KrcTabs} from '../../../../molecules/KrcTabs'
import {CategoryProductManualSorting} from './editors_choice/method/CategoryProductManualSorting'
import {CategoryProductParametricSorting} from './editors_choice/method/CategoryProductParametricSorting'

export const CategoryProductEditorsChoiceSorting: FC = () => {
  return (
    <>
      <CategorySortingTabs />
    </>
  )
}

const CategorySortingTabs: FC = () => {
  return (
    <KrcTabs
      isActiveKey={false}
      tabsClassName={'mb-1'}
      tabsProps={{defaultActiveKey: 'manual-sorting'}}
      tabProps={[
        {
          eventKey: 'manual-sorting',
          title: 'Manuel Sıralama',
          children: <CategoryProductManualSorting pageType={MANUAL_SORTING} />,
        },
        {
          eventKey: 'parametric-sorting',
          title: 'Parametrik Sıralama',
          children: <CategoryProductParametricSorting pageType={PARAMETRIC_SORTING} />,
        },
      ]}
    />
  )
}
