import {CheckCircleFilled} from '@ant-design/icons'
import {captureException} from '@sentry/react'
import {message} from 'antd'
import _ from 'lodash'
import {OptionData} from 'rc-select/lib/interface'
import React, {FC, createContext, useEffect, useRef, useState} from 'react'

import {krcSelectOptions} from '../../components/models/components/KrcSelectModel'
import {
  layoutComponentButtonDataType,
  layoutComponentButtonType,
  layoutComponentDataType,
  layoutComponentDetailDataType,
  layoutComponentType,
  layoutPageDataType,
  layoutUpdateDataType,
} from '../../components/models/layouts/LayoutModel'
import {ComponentAttributeEnum} from '../../enums/ComponentAttributeEnum'
import {errorMessage} from '../../helpers/ErrorHelper'
import {
  addComponentInLayoutRequest,
  deleteComponentsRequest,
  getComponentsRequest,
  sortComponentsRequest,
  updateComponentsVisibleRequest,
} from '../../requests/layouts/ComponentRequest'
import {
  copyPageRequest,
  createPageRequest,
  getPageRequest,
  getPagesRequest,
  updatePageRequest,
} from '../../requests/layouts/PageRequest'

interface ILayoutContext {
  currentStore: OptionData
  pages: krcSelectOptions[]
  currentPage: krcSelectOptions
  layoutComponents: layoutComponentDetailDataType[]
  addComponent: (componentType: layoutComponentType) => void
  changeCurrentStore: (store: OptionData) => void
  changeCurrentLayout: (store: number) => void
  createLayoutModalVisible: boolean
  updateCreateLayoutModalVisible: (visible: boolean) => void
  updateCopyLayoutModalVisible: (visible: boolean) => void
  copyLayoutModalVisible: boolean
  setLayoutComponents: React.Dispatch<React.SetStateAction<layoutComponentDetailDataType[]>>
  createPage: (name: string) => void
  copyPage: (name: string) => void
  pagePublish: () => void
  deleteComponent: (componentId: number) => void
  components: layoutComponentButtonType[]
  currentLayoutPage?: layoutPageDataType
  isDefaultPage: boolean
  setIsDefaultPage: React.Dispatch<React.SetStateAction<boolean>>
  sortComponents: () => void
  updateVisibleLayoutComponent: (componentId: number) => void
  disabledSortButton: boolean
}

export const LayoutContext = createContext<ILayoutContext>({
  currentStore: {value: 1, label: 'Karaca'},
  pages: [{value: 0, label: 'Varsayılan'}],
  currentPage: {value: 0, label: 'Varsayılan'},
  layoutComponents: [],
  components: [],
  createLayoutModalVisible: false,
  copyLayoutModalVisible: false,
  isDefaultPage: false,
  disabledSortButton: false,
  addComponent: () => {},
  changeCurrentStore: () => {},
  changeCurrentLayout: () => {},
  updateCreateLayoutModalVisible: () => {},
  setLayoutComponents: () => {},
  createPage: () => {},
  pagePublish: () => {},
  deleteComponent: () => {},
  setIsDefaultPage: () => {},
  sortComponents: () => {},
  updateVisibleLayoutComponent: () => {},
  updateCopyLayoutModalVisible: () => {},
  copyPage: () => {},
})
export const LayoutProvider: FC = ({children}) => {
  const [addedLayoutComponents, setAddedLayoutComponents] = useState<number[]>([])
  const [deletedComponentIds, setDeletedComponentIds] = useState<number[]>([])
  const [layoutComponents, setLayoutComponents] = useState<layoutComponentDetailDataType[]>([])
  const [currentStore, setCurrentStore] = useState<OptionData>({value: 2, label: 'Karaca'})
  const [currentPage, setCurrentPage] = useState<krcSelectOptions>({value: 0, label: 'Varsayılan'})
  const [pages, setPages] = useState<krcSelectOptions[]>([])
  const [createLayoutModalVisible, setCreateLayoutModalVisible] = useState<boolean>(false)
  const [copyLayoutModalVisible, setCopyLayoutModalVisible] = useState<boolean>(false)
  const [components, setComponents] = useState<layoutComponentButtonType[]>([])
  const [layoutPages, setLayoutPages] = useState<layoutPageDataType[]>([])
  const [currentLayoutPage, setCurrentLayoutPage] = useState<layoutPageDataType>()
  const [isDefaultPage, setIsDefaultPage] = useState<boolean>(false)
  const [disabledSortButton, setDisabledSortButton] = useState<boolean>(false)

  const addComponentRef = useRef(0)
  const deleteComponentRef = useRef(0)

  const updateCreateLayoutModalVisible = (visible: boolean) => {
    setCreateLayoutModalVisible(visible)
  }

  const updateCopyLayoutModalVisible = (visible: boolean) => {
    setCopyLayoutModalVisible(visible)
  }

  const addComponent = (componentType: layoutComponentType) => {
    const tempComponent = getComponentByType(componentType)

    if (!tempComponent) {
      return
    }

    setAddedLayoutComponents([...addedLayoutComponents, tempComponent.id])
    setLayoutComponents([...layoutComponents, tempComponent])
  }

  const getComponentByType = (componentType: layoutComponentType) => {
    const component = _.find(components, (component) => component.type === componentType)
    if (!component) {
      return null
    }

    return {
      id: component.id,
      reactKey: `${Math.floor(Math.random() * 1000000 + 1)}-${componentType}`,
      component_name: component.name,
      component_type: {value: component.id, label: component.name},
      component_banner: null,
      page: null,
      status: '0' as '0' | '1',
      is_created: false,
    }
  }

  const changeCurrentStore = (store: OptionData) => {
    setCurrentStore(store)
  }

  const changeCurrentLayout = (layoutId: number) => {
    const layout = _.find(pages, (layout) => layout.value === layoutId)

    if (layout === undefined) {
      return
    }

    setCurrentLayoutPage(_.find(layoutPages, (page) => page.id === layoutId))
    setCurrentPage(layout)
  }

  const getPages = (storeId: number) => {
    setCurrentPage({value: 0, label: pageOptionLabel('Varsayılan', false)})

    getPagesRequest(storeId).then((res) => {
      const pages = res.data.data as layoutPageDataType[]

      if (!pages) {
        return
      }

      const _currentPage = _.find(pages, (page) => page.is_active)

      if (_currentPage) {
        setCurrentPage({
          value: _currentPage.id,
          label: pageOptionLabel(_currentPage.name, _currentPage.is_active),
        })
        setCurrentLayoutPage(_currentPage)
      } else {
        setCurrentPage({
          value: pages[0].id,
          label: pageOptionLabel(pages[0].name, pages[0].is_active),
        })
      }

      setLayoutPages(pages)
      setPages(
        pages
          .sort((page) => (page.is_active ? -1 : 1))
          .map((page) => ({
            value: page.id,
            label: pageOptionLabel(page.name, page.is_active),
          }))
      )
    })
  }

  const createPage = (name: string) => {
    createPageRequest({name, store_id: currentStore.value as number}).then(() => {
      getPages(currentStore.value as number)
      updateCreateLayoutModalVisible(false)
    })
  }

  const copyPage = (name: string) => {
    copyPageRequest(Number(currentPage.value), {name}).then(() => {
      getPages(currentStore.value as number)
      updateCopyLayoutModalVisible(false)
    })
  }

  const getPage = () => {
    if (!currentPage.value) {
      return
    }

    getPageRequest(currentPage.value as number).then((res) => {
      if (!res.data.data?.components) {
        return
      }

      const _layoutComponents = res.data.data.components.map(
        (component: layoutComponentDataType) => {
          const componentName = _.find(
            component.component_eavs,
            (eav) => eav.attribute === ComponentAttributeEnum.COMPONENT_NAME
          )?.value

          return {
            id: component.id,
            reactKey: `${component.component?.type}-${component.id}`,
            component_name: componentName,
            component_type: component.component?.type,
            status: component.is_active ? '1' : '0',
            is_created: true,
          }
        }
      ) as layoutComponentDetailDataType[]

      setLayoutComponents(_layoutComponents)
    })
  }

  const pagePublish = () => {
    const key = 'layout-publish'
    if (
      deletedComponentIds.length !== 0 ||
      addedLayoutComponents.length !== 0 ||
      (isDefaultPage && currentLayoutPage?.is_active === false)
    ) {
      message.loading({content: 'Layout Yayına alınıyor', key})
    }

    if (deletedComponentIds.length !== 0) {
      deleteComponentRef.current += 1
      const deleteCurrentRef = deleteComponentRef.current

      deleteComponentsRequest(deletedComponentIds).then(() => {
        if (deleteCurrentRef !== deleteComponentRef.current) {
          return
        }

        setDeletedComponentIds([])

        if (addedLayoutComponents.length === 0) {
          getPage()
          message.success({content: 'Layout yayına alındı', key})
        }
      })
    }

    if (addedLayoutComponents.length !== 0) {
      addComponentRef.current += 1
      const addCurrentRef = addComponentRef.current

      addComponentInLayoutRequest({
        component_ids: addedLayoutComponents,
        page_id: currentPage.value as number,
      }).then(() => {
        if (addCurrentRef !== addComponentRef.current) {
          return
        }

        setAddedLayoutComponents([])
        getPage()
        message.success({content: 'Layout yayına alındı', key})
      })
    }

    if (isDefaultPage && currentLayoutPage?.is_active === false) {
      updatePage({is_active: isDefaultPage})
    }

    if (layoutComponents.some((layoutComponent) => layoutComponent.is_created)) {
      const componentsVisible = layoutComponents
        .filter((layoutComponent) => layoutComponent.is_created)
        .map((layoutComponent) => ({
          page_component_id: layoutComponent.id,
          is_active: layoutComponent.status,
        }))

      updateComponentsVisibleRequest(componentsVisible).then(() => {
        message.success({content: 'Layout yayına alındı', key})
      })
    }
  }

  const getComponents = () => {
    getComponentsRequest().then((res) => {
      if (!res.data.data) {
        return
      }

      const componentButtons = res.data.data.map(
        ({id, name, type}: layoutComponentButtonDataType) => {
          return {
            id,
            name,
            type,
          }
        }
      )

      setComponents(componentButtons)
    })
  }

  const updatePage = (body: layoutUpdateDataType) => {
    updatePageRequest(currentPage.value as number, body).then(() => {
      getPages(currentStore.value as number)
    })
  }

  const sortComponents = () => {
    if (layoutComponents.some((component) => !component.is_created)) {
      message.error('Yayına alındıktan sonra sıralanabilir.')
      return
    }

    const componentIds = layoutComponents.map((component) => component.id)

    const key = 'sort-components'

    message.loading({content: 'Sıralanıyor...', key})

    sortComponentsRequest(componentIds)
      .then(() => {
        message.success({content: 'Sıralama tamamlandı', key})
      })
      .catch((e) => {
        captureException(e)
        message.error({content: 'Sıralama tamamlanamadı', key})
        errorMessage(e)
      })
  }

  useEffect(() => {
    setIsDefaultPage(currentLayoutPage?.is_active ?? false)
  }, [currentLayoutPage])

  const pageOptionLabel = (name: string, isActive: boolean) => {
    return (
      <div key={name} className={'d-flex justify-content-between align-items-center'}>
        <div>{name}</div>
        <div className={'d-flex align-items-center'} style={{color: isActive ? '#52c41a' : ''}}>
          {isActive ? <CheckCircleFilled /> : null}
        </div>
      </div>
    )
  }

  const deleteComponent = (componentId: number) => {
    setDeletedComponentIds((componentIds) => [...componentIds, componentId])
    setLayoutComponents((layoutComponents) => {
      return layoutComponents.filter((layoutComponent) => {
        return layoutComponent.id !== componentId
      })
    })
  }

  const updateVisibleLayoutComponent = (componentId: number) => {
    setLayoutComponents((layoutComponents) => {
      return layoutComponents.map((layoutComponent) => {
        if (layoutComponent.id === componentId) {
          return {
            ...layoutComponent,
            status: layoutComponent.status === '1' ? '0' : '1',
          }
        }
        return layoutComponent
      })
    })
  }

  useEffect(() => {
    getComponents()
  }, [])

  useEffect(() => {
    getPages(currentStore.value as number)
  }, [currentStore])

  useEffect(() => {
    getPage()
  }, [currentPage])

  useEffect(() => {
    if (addedLayoutComponents.length !== 0 || deletedComponentIds.length !== 0) {
      setDisabledSortButton(true)
      return
    }

    setDisabledSortButton(false)
  }, [addedLayoutComponents, deletedComponentIds])

  return (
    <>
      <LayoutContext.Provider
        value={{
          pages,
          layoutComponents,
          currentStore,
          currentPage,
          createLayoutModalVisible,
          copyLayoutModalVisible,
          components,
          currentLayoutPage,
          isDefaultPage,
          disabledSortButton,
          setIsDefaultPage,
          deleteComponent,
          setLayoutComponents,
          addComponent,
          updateCreateLayoutModalVisible,
          updateCopyLayoutModalVisible,
          copyPage,
          updateVisibleLayoutComponent,
          changeCurrentStore,
          changeCurrentLayout,
          createPage,
          pagePublish,
          sortComponents,
        }}
      >
        {children}
      </LayoutContext.Provider>
    </>
  )
}
