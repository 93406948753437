import {
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  MenuOutlined,
} from '@ant-design/icons'
import {Image, Popconfirm, Tooltip} from 'antd'
import clsx from 'clsx'
import React, {FC} from 'react'

import {useKrcDragAndDrop} from '../../hooks/dnd/useKrcDragAndDrop'

interface IDndItemEditable {
  id: string | number
  title: string
  subTitle?: string | null
  image?: string | null
  mobileImage?: string | null
  index: number
  status?: boolean
  setState: React.SetStateAction<React.Dispatch<any>>
  onRemove?: (id: string | number, index: number) => void
  isEditable?: boolean
  targetType: string
  onChangeVisible?: (id: string | number, visible: boolean, index: number) => void
  onClickEdit?: (id: string | number) => void
  isVisibleButton?: boolean
}

export const DndItemEditable: FC<IDndItemEditable> = ({
  id,
  title,
  index,
  image,
  status,
  setState,
  subTitle,
  onRemove,
  isEditable = true,
  targetType,
  onChangeVisible,
  onClickEdit,
  isVisibleButton = true,
  mobileImage,
}) => {
  const {drag, drop, isDragging, ref, handlerId} = useKrcDragAndDrop({
    id,
    index,
    setState,
    targetType,
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <>
      <div
        className={'d-flex flex-column rounded border px-6 py-3 w-100'}
        ref={ref}
        style={{opacity}}
        data-handler-id={handlerId}
      >
        <div className={'d-flex justify-content-between align-items-center'}>
          <div className={'d-flex align-items-center'}>
            <MenuOutlined style={{fontSize: 18}} />
            <div className={'ml-5 mw-500px'}>
              <span style={{fontSize: 16}}>
                <b>{title}</b>
              </span>
            </div>
          </div>
          {subTitle ? (
            <div className={'border rounded p-3 mr-10'} style={{minWidth: 200, minHeight: 42}}>
              <span>
                <b>{subTitle}</b>
              </span>
            </div>
          ) : null}
          <div className={'d-flex gap-4'}>
            {isVisibleButton && (
              <div>
                {!status ? (
                  <EyeInvisibleOutlined
                    style={{fontSize: 18}}
                    className={'cursor-pointer primary-hover'}
                    onClick={(e) => {
                      if (!onChangeVisible) {
                        return
                      }

                      onChangeVisible(id, false, index)
                    }}
                  />
                ) : (
                  <EyeOutlined
                    style={{fontSize: 18}}
                    className={'cursor-pointer primary-hover'}
                    onClick={(e) => {
                      if (!onChangeVisible) {
                        return
                      }

                      onChangeVisible(id, true, index)
                    }}
                  />
                )}
              </div>
            )}
            <div>
              <Tooltip
                title={clsx({
                  'Taslak olarak kaydettikten sonra güncellenebilir.': !isEditable,
                })}
              >
                <EditOutlined
                  style={{fontSize: 18}}
                  className={clsx(
                    {'cursor-pointer primary-hover': isEditable},
                    {'custom-disabled': !isEditable}
                  )}
                  onClick={() => {
                    if (!onClickEdit || !isEditable) {
                      return
                    }

                    onClickEdit(id)
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Popconfirm
                title='Silmek istediğinden emin misiniz?'
                onConfirm={() => {
                  if (!onRemove) {
                    return
                  }

                  onRemove(id, index)
                }}
                okText='Evet'
                cancelText='Hayır'
              >
                <DeleteOutlined style={{fontSize: 18}} className={'cursor-pointer primary-hover'} />
              </Popconfirm>
            </div>
          </div>
        </div>
        <div className={'d-flex'}>
          {image && (
            <div className={'mt-5'}>
              <Image width={500} height={100} src={image} style={{objectFit: 'scale-down'}} />
            </div>
          )}
          {mobileImage && (
            <div className={'mt-5'}>
              <Image width={500} height={100} src={mobileImage} style={{objectFit: 'scale-down'}} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
