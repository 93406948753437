import {useMemo, useState} from 'react'

import {
  DEEPLINK_HEADER,
  EXTERNAL_HEADER,
  SCREEN_HEADER,
  WEBVIEW_HEADER,
} from '../../enums/DeepLinkInformationEnum'

export const useDeepLinkInformationModal = () => {
  const [showDeepLinkInformationModal, setShowDeepLinkInformationModal] = useState<boolean>(false)

  const columns = useMemo(() => {
    return [
      {
        Header: SCREEN_HEADER,
        accessor: 'screen',
      },
      {
        Header: DEEPLINK_HEADER,
        accessor: 'deepLink',
      },
      {
        Header: WEBVIEW_HEADER,
        accessor: 'webview',
      },
      {
        Header: EXTERNAL_HEADER,
        accessor: 'external',
      },
    ]
  }, [])

  return {
    showDeepLinkInformationModal,
    setShowDeepLinkInformationModal,
    columns,
  }
}
