import _ from 'lodash'
import moment from 'moment'

interface IMergeAndPush {
  [key: string]: any
}

//Objeler için merge diğer tüm değerler için push uygulanır.
export const mergeAndPushObjectProperties = (object: IMergeAndPush, source: IMergeAndPush) => {
  const _source = {...source}
  const _object = {...object}

  Object.keys(_object).forEach((key) => {
    if (
      typeof _object[key] === 'object' &&
      !Array.isArray(_object[key]) &&
      _object[key] !== null &&
      !(_object[key] instanceof moment)
    ) {
      _source[key] = {..._source[key], ..._object[key]}
    } else {
      _source[key] = _object[key]
    }
  })

  return _source
}

export const objectIsUndefined = (object?: object) => {
  if (!object) {
    return true
  }

  return _.every(object, (prop) => {
    return _.isUndefined(prop)
  })
}

export const objectIsEmpty = (object?: object) => {
  if (!object || Object.keys(object).length === 0) {
    return true
  }

  return _.some(object, (prop: any) => {
    if (_.isArray(prop)) {
      prop = prop as any[]
      return prop.length === 0
    }

    return _.isUndefined(prop) || _.isNull(prop) || _.isNaN(prop)
  })
}

export const objectIsEveryEmpty = (object?: object) => {
  if (!object || Object.keys(object).length === 0) {
    return true
  }

  return _.every(object, (prop: any) => {
    if (_.isArray(prop)) {
      prop = prop as any[]
      return prop.length === 0
    }
    return _.isUndefined(prop) || _.isNull(prop) || _.isNaN(prop)
  })
}

export const removeEmptyItemInObject = (data: object) => {
  return Object.fromEntries(
    Object.entries(data).filter(([_, v]) => v !== null && v !== '' && v !== undefined)
  )
}

export const fillAllNullValueInObject = (data: {[item: string]: any}) => {
  return Object.keys(data).forEach((key) => {
    if (_.isNull(data[key]) || data[key] === undefined) {
      data[key] = ''
    }
  })
}

export const addObjectToObject = (
  currentObject: object,
  object: object,
  isUndefinedDelete: boolean = false
) => {
  if (isUndefinedDelete) {
    Object.keys(object).forEach((key) => {
      if (object[key as keyof object] === undefined) {
        delete currentObject[key as keyof object]
        delete object[key as keyof object]
      }
    })
  }

  return {...currentObject, ...object}
}

export function deleteFromObject(keyPart: string, obj: object) {
  for (let k in obj) {
    // Loop through the object
    if (~k.indexOf(keyPart)) {
      // If the current key contains the string we're looking for
      // @ts-ignore
      delete obj[k] // Delete obj[key];
    }
  }
}
