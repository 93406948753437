import {DrawerEnum} from '../../../enums/DrawerEnum'
import {storeDataType} from '../stores/StoreModel'

export enum BadgeListTableTypeEnum {
  PRODUCT = 1,
  PRODUCT_NAME = 'Ürün',
  CATEGORY = 2,
  CATEGORY_NAME = 'Kategori',
}

export type BadgeListTableType = BadgeListTableTypeEnum.PRODUCT | BadgeListTableTypeEnum.CATEGORY

export const TABLE_TYPE_NAMES = {
  [BadgeListTableTypeEnum.PRODUCT]: BadgeListTableTypeEnum.PRODUCT_NAME,
  [BadgeListTableTypeEnum.CATEGORY]: BadgeListTableTypeEnum.CATEGORY_NAME,
}

export type badgeDataType = {
  badge_id: number
  badge_type: string
  table_id?: number
  table_type?: number
  name: string
  panel_name: string
  location: string
  location_letter: string
  priority: number
  amount?: string
  priceOperation: string
  fontSize?: string
  fontColor?: string
  fontBackgroundColor?: string
  status: string
  status_text: string
  style?: string
  price: number | null
  percentage: string | null
  badge_url: string
  badge_url_app: string
  badge_url_app_type: string
  badge_url_app_type_id: number
  image: string
  full_image: string
  badge_description: string
  stores?: storeDataType[]
}

export type badgeListDataType = {
  badge_id: number
  badge_list_id: number
  date_added: string
  date_updated: string
  end_date: string
  start_date: string
  table_id: number
  table_type: number
  badge?: badgeDataType
}

export interface IBadgeDrawer {
  visible: boolean
  onHide?: () => void
  type: DrawerEnum
  badgeData?: badgeDataType | null
  onSave?: () => void
}

export interface IUseBadgeDrawer {
  type: DrawerEnum
  badgeData?: badgeDataType | null
  onSave?: () => void
}

export type badgeDrawerInitValuesType = {
  badge_id: number
  badge_type: string
  name: string
  panel_name: string
  location: string
  location_letter: string
  priority: number
  amount?: string
  priceOperation: string
  fontSize?: string
  fontColor?: string
  fontBackgroundColor?: string
  status: string
  status_text: string
  image: string | null
  full_image: string | null
  badge_description: string
  badgeUrl: string
  badge_url_app: string
  badge_url_app_type: string
  badge_url_app_type_id: number
  badgeStore: number[]
  plp_status: string
  pdp_status: string
}

export interface IBadge {
  data: any
  badge_id: number
  badge_type: number | string
  name: string
  location: number | string
  location_letter: string
  priority: number
  price?: number
  percentage?: number
  style?: string
  status: string
  status_text: string
}

export type badgeFilterType = {
  name?: string
  badge_id?: number
  location_letter?: number[]
  priority?: number | null | string
}

export type drawerDataWithoutFormikType = {
  badgeStore?: number[]
  image?: string | null
  description?: string
}

export type badgeUrlAppDataType = {
  badge_url_app: string
  badge_url_app_type: string
  badge_url_app_type_id: number
}

export interface IBadgeListProductAndCategoryDrawer {
  badge?: badgeDataType
  onClose?: () => void
}

export type minimalBadgeDataType = {
  badge_id: number
  name: string
  full_image: string
}
