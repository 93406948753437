import {FC, useContext} from 'react'

import {ReviewListContext} from '../../../providers/reviews/ReviewListProvider'
import {paginationMetaType} from '../../models/GeneralModel'
import {KrcTable} from '../../organisms/tables/KrcTable'

export const ReviewList: FC = () => {
  const {columns, reviews, meta, setMeta, unSelectAllItems, handleOnFilter, setSelectedReviews} =
    useContext(ReviewListContext)

  return (
    <>
      <KrcTable
        columns={columns}
        data={reviews}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            setMeta((data: paginationMetaType) => ({...data, page}))
          },
          onChangeLimit: (limit) => {
            setMeta((data: paginationMetaType) => ({...data, limit}))
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: (search, id) => {
            handleOnFilter(search, id)
          },
        }}
        selectableProps={{
          isSelectable: true,
          uniqueKey: 'id',
          unSelectAllItems: unSelectAllItems,
          onSelectedItems: (data) => {
            setSelectedReviews(data)
          },
        }}
      />
    </>
  )
}
