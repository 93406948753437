import {paginationMetaType} from '../../components/models/GeneralModel'
import {
  categoryDataType,
  minimalCategoryType,
} from '../../components/models/categories/CategoryModel'
import {
  ADD_CATEGORY,
  ADD_SELECTED_CATEGORY,
  DELETE_CATEGORY,
  DELETE_SELECTED_CATEGORY,
  SET_ADDED_PRODUCTS,
  SET_CATEGORIES,
  SET_CATEGORIES_META,
  SET_CATEGORY,
  SET_DELETED_PRODUCT_IDS,
  SET_SELECTED_CATEGORIES,
  UPDATE_CATEGORY,
} from './CategoryActionType'

export const setCategories = (categories: minimalCategoryType[]) => {
  return {
    type: SET_CATEGORIES,
    payload: categories,
  }
}

export const setCategoriesMeta = (categoriesMeta: paginationMetaType) => {
  return {
    type: SET_CATEGORIES_META,
    payload: categoriesMeta,
  }
}

export const setCategory = (category: categoryDataType | null) => {
  return {
    type: SET_CATEGORY,
    payload: category,
  }
}

export const setSelectCategories = (categories: minimalCategoryType[]) => {
  return {
    type: SET_SELECTED_CATEGORIES,
    payload: categories,
  }
}

export const addSelectedCategory = (category: minimalCategoryType) => {
  return {
    type: ADD_SELECTED_CATEGORY,
    payload: category,
  }
}

export const deleteSelectedCategory = (categoryId: number) => {
  return {
    type: DELETE_SELECTED_CATEGORY,
    payload: categoryId,
  }
}

export const addCategory = (category: minimalCategoryType | null) => {
  return {
    type: ADD_CATEGORY,
    payload: category,
  }
}

export const updateCategory = (categoryId: number, category: categoryDataType | null) => {
  return {
    type: UPDATE_CATEGORY,
    payload: {category, categoryId},
  }
}

export const deleteCategory = (categoryId: number) => {
  return {
    type: DELETE_CATEGORY,
    payload: categoryId,
  }
}

export const setAddedProducts = (products: object[]) => {
  return {
    type: SET_ADDED_PRODUCTS,
    payload: products,
  }
}

export const setDeletedProductIds = (productIds: number[]) => {
  return {
    type: SET_DELETED_PRODUCT_IDS,
    payload: productIds,
  }
}
