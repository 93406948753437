import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {Toolbar} from '../../../../../../_metronic/layout/components/toolbar/Toolbar'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {useProductListFilter} from '../../../../hooks/products/useProductListFilter'
import {ExcelExport} from '../../../../layout/excel/ExcelExportWrapper'

export const ProductListExportWrapper: FC = () => {
  const {downloadExcelUrl, exportProductFilterList} = useProductListFilter()

  return (
    <>
      <Toolbar name={'Pasif Ürünleri Dışarı Aktar'} toolbar='empty' />
      <PageTitle breadcrumbs={[]}>Ürünleri Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport
              handleExportData={exportProductFilterList}
              downloadExcelUrl={downloadExcelUrl}
            />
          }
        ></Route>
      </Routes>
    </>
  )
}
