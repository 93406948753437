import cn from 'classnames'
import React, {FC} from 'react'
import {Link} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {DealListProvider} from '../../../../providers/DealListProvider'
import {getDealsExportFrontendUrl} from '../../../../requests/deals/DealExportRequest'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {DealsList} from './DealsList'

const DealsListWrapperWithoutProvider: FC = () => {
  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <a
            type={'button'}
            className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-3'
            href={getDealsExportFrontendUrl()}
            target={'_blank'}
            rel='noreferrer'
          >
            Dışarı Aktar
          </a>
          <Link
            to='/deals/create'
            className={cn({'btn btn-sm btn-primary': true})}
            id='kt_toolbar_primary_button'
          >
            Fırsat Oluştur
          </Link>
        </div>
      </Toolbar>
      <PageTitle breadcrumbs={[]}>Fırsatlar</PageTitle>
      <DealsList />
    </>
  )
}

export const DealsListWrapper: FC = () => {
  return (
    <>
      <DealListProvider>
        <DealsListWrapperWithoutProvider />
      </DealListProvider>
    </>
  )
}
