import React, {FC} from 'react'
import {Link, LinkProps} from 'react-router-dom'

import {useKrcProp} from '../../hooks/components/useKrcProp'

const configLink = {
  default: {
    className: 'text-primary',
  },
}

interface IConfigLink {
  config?: 'default'
}

export const KrcLink: FC<IConfigLink & LinkProps> = ({config = 'default', children, ...props}) => {
  const currentProps = useKrcProp({config: configLink[config], props}).getCurrentProps()

  return (
    <>
      <Link {...currentProps}>{children}</Link>
    </>
  )
}
