import React, {FC, useContext} from 'react'
import {Link} from 'react-router-dom'

import {PageTitle} from '../../../../_metronic/layout/core'
import {UserContext, UserProvider} from '../../../providers/user/UserProvider'
import {UserPermissionManagementModal} from '../../modals/users/permissions/UserPermissionManagementModal'
import {UserRoleManagementModal} from '../../modals/users/roles/UserRoleManagementModal'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {UserList} from './UserList'

const UserListWrapperWithoutProvider: FC = () => {
  const {
    RoleManagementTargetUser,
    PermissionManagementTargetUser,
    hideUserRoleModal,
    hideUserPermissionModal,
  } = useContext(UserContext)
  return (
    <>
      <Toolbar
        rightField={
          <div className='d-flex align-items-center py-1'>
            <Link
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-3'
              to={'/roles'}
            >
              Rolleri Görüntüle
            </Link>
            <Link
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-3'
              to={'/permissions'}
            >
              İzinleri Görüntüle
            </Link>
          </div>
        }
      ></Toolbar>
      <PageTitle>Kullanıcılar</PageTitle>
      <UserList />
      {RoleManagementTargetUser && <UserRoleManagementModal onHide={hideUserRoleModal} />}
      {PermissionManagementTargetUser && (
        <UserPermissionManagementModal onHide={hideUserPermissionModal} />
      )}
    </>
  )
}

export const UserListWrapper: FC = () => {
  return (
    <>
      <UserProvider>
        <UserListWrapperWithoutProvider />
      </UserProvider>
    </>
  )
}
