import {message} from 'antd'
import React, {createContext, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'

import {StoreDataType} from '../components/models/footer/StoreModel'
import {
  IUpdateFooterModuleSubModule,
  StoreModuleDataType,
  StoreSubModuleDataType,
  createNewSubModuleDataType,
  updateOrCreateModuleWithoutSubModulesDataType,
} from '../components/models/footer/StoreModulesModel'
import {FOOTER_STORES} from '../enums/FooterModuleStoresTypeEnum'
import {
  createFooterModuleByStoreIdRequest,
  createFooterSubModuleToParentModuleRequest,
  deleteFooterModuleByModuleIdRequest,
  getFooterModuleInformationByModuleIdRequest,
  getFooterModuleSubModulesByModuleIdRequest,
  getFooterModulesByStoreIdRequest,
  saveFooterModuleSubModulesRequest,
  updateFooterModuleInformationByModuleIdRequest,
} from '../requests/footer/FooterRequests'

interface IFooterModulesContext {
  currentStore: StoreDataType
  setCurrentStore: (store: StoreDataType) => void
  selectedStoreModules: StoreModuleDataType[]
  selectedStoreSubModules: StoreSubModuleDataType[]
  setSelectedStoreSubModules: React.Dispatch<React.SetStateAction<StoreSubModuleDataType[]>>
  currentModuleId: number
  setCurrentModuleId: (moduleId: number) => void
  selectedModuleInformation: StoreModuleDataType | null
  selectedModuleSubModules: StoreSubModuleDataType[] | null
  getSelectedModuleInformation: (moduleId: number) => void
  getSelectedModuleSubModules: (moduleId: number) => void
  updateOrCreateModuleSchema: object
  setUpdateOrCreateModuleSchema: React.Dispatch<React.SetStateAction<object>>
  createNewSubModuleSchema: object
  setCreateNewSubModuleSchema: React.Dispatch<React.SetStateAction<object>>
  updateModuleInformation: (
    moduleId: number,
    data: updateOrCreateModuleWithoutSubModulesDataType
  ) => void
  visibleCreateModal: boolean
  setVisibleCreateModal: React.Dispatch<React.SetStateAction<boolean>>
  visibleCreateSubModal: boolean
  setVisibleCreateSubModal: React.Dispatch<React.SetStateAction<boolean>>
  createNewModuleToStore: (data: updateOrCreateModuleWithoutSubModulesDataType) => void
  createNewSubModuleToParentModule: (data: createNewSubModuleDataType, moduleId: number) => void
  getSelectedStoreModules: (selectedStore: StoreDataType) => void
  deleteModuleFromStore: (moduleId: number) => void
  saveSubModules: (moduleId: number, data: IUpdateFooterModuleSubModule) => void
  isSubModuleTabsLoading: boolean
  setIsSubModuleTabsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const FooterModulesContext = createContext<IFooterModulesContext>({
  currentStore: FOOTER_STORES[2],
  setCurrentStore: () => {},
  selectedStoreModules: [],
  selectedStoreSubModules: [],
  currentModuleId: 1,
  setCurrentModuleId: () => {},
  selectedModuleInformation: {} as StoreModuleDataType,
  getSelectedModuleInformation: () => {},
  getSelectedModuleSubModules: () => {},
  updateOrCreateModuleSchema: Yup.object({
    module_name: Yup.string().required('Modül adı zorunludur'),
    module_url: Yup.string().nullable(),
    module_status: Yup.number().required('Modül durumu zorunludur'),
    module_sort: Yup.number().required('Modül sırası zorunludur'),
  }),
  setUpdateOrCreateModuleSchema: () => {},
  createNewSubModuleSchema: Yup.object({
    sub_module_title: Yup.string().required('Başlık zorunludur'),
    sub_module_url: Yup.string().nullable(),
    sub_module_target_blank: Yup.number().required('Başlık durumu zorunludur'),
    sub_module_sort: Yup.number().required('Başlık sırası zorunludur'),
  }),
  setCreateNewSubModuleSchema: () => {},
  updateModuleInformation: () => {},
  visibleCreateModal: false,
  setVisibleCreateModal: () => {},
  visibleCreateSubModal: false,
  setVisibleCreateSubModal: () => {},
  createNewModuleToStore: () => () => {},
  createNewSubModuleToParentModule: () => () => {},
  getSelectedStoreModules: () => {},
  deleteModuleFromStore: () => {},
  saveSubModules: () => {},
  setSelectedStoreSubModules: () => {},
  selectedModuleSubModules: [],
  isSubModuleTabsLoading: false,
  setIsSubModuleTabsLoading: () => {},
})

export const FooterModulesProvider = ({children}: any) => {
  const [currentStore, setCurrentStore] = useState<StoreDataType>(FOOTER_STORES[2])
  const [selectedStoreModules, setSelectedStoreModules] = useState<StoreModuleDataType[]>([])
  const [currentModuleId, setCurrentModuleId] = useState<number>(1)
  const [selectedModuleInformation, setSelectedModuleInformation] =
    useState<StoreModuleDataType | null>(null)
  const [selectedModuleSubModules, setSelectedModuleSubModules] = useState<
    StoreSubModuleDataType[] | null
  >(null)
  const [selectedStoreSubModules, setSelectedStoreSubModules] = useState<StoreSubModuleDataType[]>(
    []
  )
  const fetchRef = useRef(0)
  const [updateOrCreateModuleSchema, setUpdateOrCreateModuleSchema] = useState<object>(
    Yup.object({
      module_name: Yup.string().required('Modül adı zorunludur'),
      module_url: Yup.string().nullable(),
      module_status: Yup.number().required('Modül durumu zorunludur'),
      module_sort: Yup.number()
        .required('Modül sırası zorunludur')
        .min(0, 'Sıra 0 dan küçük olamaz'),
    })
  )
  const [createNewSubModuleSchema, setCreateNewSubModuleSchema] = useState<object>(
    Yup.object({
      sub_module_title: Yup.string().required('Başlık zorunludur'),
      sub_module_url: Yup.string().nullable(),
      sub_module_target_blank: Yup.number().required('Başlık durumu zorunludur'),
      sub_module_sort: Yup.number().required('Başlık sırası zorunludur'),
    })
  )
  const [visibleCreateModal, setVisibleCreateModal] = useState<boolean>(false)
  const [visibleCreateSubModal, setVisibleCreateSubModal] = useState<boolean>(false)
  const [isSubModuleTabsLoading, setIsSubModuleTabsLoading] = useState<boolean>(false)

  useEffect(() => {
    getSelectedStoreModules(currentStore)
  }, [currentStore])

  const getSelectedStoreModules = (selectedStore: StoreDataType) => {
    if (currentStore.id === null) {
      return
    }
    fetchRef.current += 1
    const fetchId = fetchRef.current
    getFooterModulesByStoreIdRequest(selectedStore.id).then((response) => {
      if (fetchId !== fetchRef.current) {
        return
      }
      setSelectedStoreModules(response.data.data)
      setSelectedModuleInformation(response.data.data[0])
      if (response.data.data.length > 0) {
        setCurrentModuleId(response.data.data[0].module_id)
      }
    })
  }

  const getSelectedModuleInformation = (moduleId: number) => {
    if (currentStore.id === null || !currentModuleId) {
      return
    }
    setIsSubModuleTabsLoading(true)
    fetchRef.current += 1
    const fetchId = fetchRef.current
    getFooterModuleInformationByModuleIdRequest(moduleId).then((response) => {
      if (fetchId !== fetchRef.current) {
        return
      }
      setSelectedModuleInformation(response.data.data)
      setIsSubModuleTabsLoading(false)
      getSelectedModuleSubModules(moduleId)
    })
  }

  const getSelectedModuleSubModules = (moduleId: number) => {
    setIsSubModuleTabsLoading(true)
    if (currentStore.id === null || !currentModuleId) {
      return
    }
    fetchRef.current += 1
    const fetchId = fetchRef.current
    getFooterModuleSubModulesByModuleIdRequest(moduleId).then((response) => {
      if (fetchId !== fetchRef.current) {
        return
      }
      setSelectedModuleSubModules(response.data.data)
      setIsSubModuleTabsLoading(false)
    })
  }

  const updateModuleInformation = async (
    moduleId: number,
    data: updateOrCreateModuleWithoutSubModulesDataType
  ) => {
    if (currentStore.id === null || !currentModuleId) {
      return
    }
    message.success('Modül bilgileri güncelleniyor...', 1)
    await updateFooterModuleInformationByModuleIdRequest(moduleId, data)
      .then(() => {
        message.success('Modül bilgileri güncellendi')
        getSelectedStoreModules(currentStore)
        getSelectedModuleInformation(moduleId)
      })
      .catch((error) => {
        message.error('Modül bilgileri güncellenirken bir hata oluştu.')
        message.error(error.message)
      })
  }

  const createNewModuleToStore = async (data: updateOrCreateModuleWithoutSubModulesDataType) => {
    if (currentStore.id === null) {
      return
    }
    message.success('Modül oluşturuluyor...', 1)
    await createFooterModuleByStoreIdRequest(currentStore.id, data)
      .then((response) => {
        message.success('Modül başarıyla oluşturuldu.')
        setVisibleCreateModal(false)
        getSelectedStoreModules(currentStore)
      })
      .catch((error) => {
        setVisibleCreateModal(false)
        message.error('Modül oluşturulurken bir hata oluştu.')
        message.error(error.message)
      })
  }

  const createNewSubModuleToParentModule = async (
    data: createNewSubModuleDataType,
    moduleId: number
  ) => {
    if (currentStore.id === null || !currentModuleId) {
      return
    }
    message.success('Başlık oluşturuluyor...', 1)
    const willBeAddedSubModules: createNewSubModuleDataType[] = []
    willBeAddedSubModules.push(data)
    await createFooterSubModuleToParentModuleRequest(moduleId, {
      will_be_added_sub_modules: willBeAddedSubModules,
      will_be_removed_sub_modules_ids: [],
      will_be_updated_sub_modules: [],
    })
      .then((response) => {
        message.success('Başlık başarıyla oluşturuldu.')
        setVisibleCreateSubModal(false)
        getSelectedStoreModules(currentStore)
        getSelectedModuleInformation(moduleId)
        getSelectedModuleSubModules(moduleId)
      })
      .catch((error) => {
        setVisibleCreateSubModal(false)
        message.error('Başlık oluşturulurken bir hata oluştu.')
        message.error(error.message)
      })
  }

  const deleteModuleFromStore = async (moduleId: number) => {
    if (currentStore.id === null) {
      return
    }
    message.success('Modül siliniyor...', 1)
    await deleteFooterModuleByModuleIdRequest(moduleId)
      .then((response) => {
        message.success('Modül başarıyla silindi.')
        getSelectedStoreModules(currentStore)
      })
      .catch((error) => {
        message.error('Modül silinirken bir hata oluştu.')
        message.error(error.message)
      })
  }

  const saveSubModules = async (moduleId: number, data: IUpdateFooterModuleSubModule) => {
    if (currentStore.id === null) {
      return
    }
    message.success('Başlıklar kaydediliyor...', 1)
    await saveFooterModuleSubModulesRequest(moduleId, data)
      .then((response) => {
        if (response.status === 200) {
          message.success('Başlıklar kaydedildi.')
          getSelectedStoreModules(currentStore)
          getSelectedModuleInformation(moduleId)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          message.error('Lütfen gerekli alanları doldurduğunuzdan emin olunuz.')
        } else {
          message.error('Bir hata oluştu:', error.message)
        }
      })
  }

  return (
    <>
      <FooterModulesContext.Provider
        value={{
          currentStore,
          setCurrentStore,
          selectedStoreModules,
          selectedStoreSubModules,
          currentModuleId,
          setCurrentModuleId,
          selectedModuleInformation,
          getSelectedModuleInformation,
          updateOrCreateModuleSchema,
          updateModuleInformation,
          visibleCreateModal,
          setVisibleCreateModal,
          visibleCreateSubModal,
          setVisibleCreateSubModal,
          createNewModuleToStore,
          getSelectedStoreModules,
          deleteModuleFromStore,
          saveSubModules,
          createNewSubModuleSchema,
          createNewSubModuleToParentModule,
          setSelectedStoreSubModules,
          setUpdateOrCreateModuleSchema,
          setCreateNewSubModuleSchema,
          selectedModuleSubModules,
          getSelectedModuleSubModules,
          isSubModuleTabsLoading,
          setIsSubModuleTabsLoading,
        }}
      >
        {children}
      </FooterModulesContext.Provider>
    </>
  )
}
