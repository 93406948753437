import {FC, useContext} from 'react'

import {ManufacturerContext} from '../../../providers/ManufacturerProvider'
import {paginationMetaType} from '../../models/GeneralModel'
import {KrcTable} from '../../organisms/tables/KrcTable'

export const ManufacturerList: FC = () => {
  const {columns, manufacturers, meta, setMeta, handleOnFilter} = useContext(ManufacturerContext)
  return (
    <>
      {
        <KrcTable
          columns={columns}
          data={manufacturers}
          paginationProps={{
            isPagination: true,
            limit: meta.limit,
            page: meta.page,
            paginationData: meta,
            onChangePage: (page) => {
              setMeta((data: paginationMetaType) => ({...data, page}))
            },
            onChangeLimit: (limit) => {
              setMeta((data: paginationMetaType) => ({...data, limit}))
            },
          }}
          searchableProps={{
            isSearchable: true,
            onSearch: (search, id) => {
              handleOnFilter(search, id)
            },
          }}
        />
      }
    </>
  )
}
