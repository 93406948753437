import axios from 'axios'

import {paginationMetaType} from '../../../components/models/GeneralModel'
import {
  addBadgesToCategoriesRequestType,
  categoryBadgeLogFilterType,
  deleteBadgesToCategoriesRequestType,
} from '../../../components/models/categories/CategoryBadgeModel'

const API_URL = process.env.REACT_APP_API_URL || ''
export const CATEGORY_BADGE_URL = `${API_URL}/categories/badges`

export function addBadgesToCategoriesRequest(data: addBadgesToCategoriesRequestType) {
  return axios.post(`${CATEGORY_BADGE_URL}/add`, data)
}

export function deleteBadgeToCategoriesRequest(data: deleteBadgesToCategoriesRequestType) {
  return axios.post(`${CATEGORY_BADGE_URL}/delete`, data)
}

export function getCategoryBadgeLogsRequest(
  filters: categoryBadgeLogFilterType,
  meta: paginationMetaType
) {
  return axios.get(`${CATEGORY_BADGE_URL}/logs`, {
    params: {
      ...filters,
      limit: meta.limit,
      page: meta.page,
    },
  })
}
