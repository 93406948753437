import {BookOutlined} from '@ant-design/icons'
import {Avatar, Tooltip} from 'antd'
import debounce from 'lodash/debounce'
import moment from 'moment'
import React, {FC, createContext, useEffect, useMemo, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Cell, Column, Row} from 'react-table'

import {paginationMetaType} from '../../components/models/GeneralModel'
import {badgeListDataType} from '../../components/models/badges/BadgeModel'
import {categoryUrlType} from '../../components/models/categories/CategoryProductModel'
import {minimalProductType} from '../../components/models/products/MinimalProductModel'
import {productVerticalAdvancedFilterDataType} from '../../components/models/products/ProductListFilterModel'
import {
  ProductSelectionType,
  productTextAreaFilterDataType,
} from '../../components/models/products/ProductSelectionModel'
import {ProductSelectionEnum} from '../../enums/ProductSelectionEnum'
import {productSelectionFilterRequest} from '../../requests/products/ProductRequest'

interface IProductSelectionContext {
  data: any[]
  meta: paginationMetaType
  columns: Column<any>[]
  transferData: any[]
  type: ProductSelectionType
  excludedTempProductIds?: number[]
  setExcludedTempProductIds?: (data: number[]) => void
  setType?: (data: ProductSelectionType) => void
  tableFilter?: any
  transferIds?: number[]
  updateTransferData?: (data: any[]) => any
  updateMeta?: (data: object) => any
  updateData?: (data: any[]) => void
  getProducts?: () => void
  updateFilters?: (
    filters: productVerticalAdvancedFilterDataType | productTextAreaFilterDataType
  ) => void
  updateExcludedProductIds?: (data: number[]) => void
  updateTableFilter?: (data: any) => void
  selectedItems?: any[]
  productBadges: badgeListDataType[] | null
  setProductBadges?: (data: badgeListDataType[] | null) => void
  setSelectedItems?: (data: any[]) => void
}

export const ProductSelectionContext = createContext<IProductSelectionContext>({
  type: ProductSelectionEnum.TEXT,
  data: [],
  meta: {limit: 10, page: 1},
  transferData: [],
  transferIds: undefined,
  columns: [],
  productBadges: null,
})

export const initialMetaData = {limit: 10, page: 1}

interface IProductSelectionProvider {
  includedProductIds?: number[]
  withFullPrice?: boolean
  withBadge?: boolean
  withTotalQuantity?: boolean
  withImage?: boolean
  withAvailable?: boolean
  onFilterChange?: (
    filters: productVerticalAdvancedFilterDataType | productTextAreaFilterDataType
  ) => any
  onFilterTypeChange?: (type: ProductSelectionType) => any
  onMetaChange?: (meta: paginationMetaType) => any
  filterCategoryIds?: number[]
  disableExcludeCategoryIdFromUrl?: boolean
}

export const ProductSelectionProvider: FC<IProductSelectionProvider> = ({
  children,
  includedProductIds,
  withFullPrice = false,
  withBadge = false,
  withTotalQuantity = false,
  withImage = false,
  withAvailable = false,
  onFilterChange,
  onFilterTypeChange,
  onMetaChange,
  filterCategoryIds,
  disableExcludeCategoryIdFromUrl = false,
}) => {
  const [data, setData] = useState<any[]>([])
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const {categoryId} = useParams<categoryUrlType>()
  const fetchRef = useRef(0)
  const initialRef = useRef(0)
  const [transferData, setTransferData] = useState<any[]>([])
  const [transferIds, setTransferIds] = useState<number[]>()
  const [filters, setFilters] = useState<
    productVerticalAdvancedFilterDataType | productTextAreaFilterDataType
  >()
  const [excludedProductIds, setExcludedProductIds] = useState<number[]>([])
  const [excludedTempProductIds, setExcludedTempProductIds] = useState<number[]>()
  const [tableFilter, setTableFilter] = useState<any>()
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const [type, setType] = useState<ProductSelectionType>(ProductSelectionEnum.DEFAULT)
  const [productBadges, setProductBadges] = useState<badgeListDataType[] | null>(null)

  const getProducts = () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current

    let categoryIdsFilter = (filters as productVerticalAdvancedFilterDataType)?.categoryIds

    if (filterCategoryIds) {
      if (categoryIdsFilter) {
        categoryIdsFilter = [...categoryIdsFilter, ...filterCategoryIds]
      } else {
        categoryIdsFilter = filterCategoryIds
      }
    }

    let excludedProductIdsFilter = []

    if (!disableExcludeCategoryIdFromUrl) {
      excludedProductIdsFilter.push(categoryId)
    }
    const currentFilters = {
      ...filters,
      excludedCategoryIds: excludedProductIdsFilter,
      includedProductIds,
      categoryIds: categoryIdsFilter,
    }
    const _excludedProductIds = [...excludedProductIds, ...(excludedTempProductIds ?? [])].filter(
      (eProductId) => {
        if (!includedProductIds) {
          return true
        }

        return !includedProductIds.some((productId) => productId === eProductId)
      }
    )

    let relations = ['description']

    if (withBadge) {
      relations.push('badgeList')
    }

    productSelectionFilterRequest(
      currentFilters,
      meta,
      _excludedProductIds,
      tableFilter,
      relations,
      withFullPrice
    ).then((res) => {
      if (fetchId !== fetchRef.current) {
        return
      }

      const products = res.data.data.map((product: minimalProductType) => {
        return {
          id: product.product_id,
          model: product.model,
          name: product.details?.name ?? '',
          price: product.prices?.price,
          price_kmiy: product.prices?.price_kmiy,
          special_price: product.prices?.special_price,
          price_cart_discount: product.prices?.price_cart_discount,
          badges: product.badges,
          image: product.cdn_image,
          total_quantity: product.total_quantity,
          dateAdded: moment(product.date_added).format('DD.MM.y'),
          dateAvailable: moment(product.date_available).format('DD.MM.y'),
        }
      })

      updateData(products)
      setMeta({limit: res.data.meta.per_page, page: res.data.meta.current_page, ...res.data.meta})
    })
  }

  const columns = React.useMemo(() => {
    let currentColumns = [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 120,
      },
      {
        Header: 'Model',
        accessor: 'model',
        maxWidth: 150,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
      },
      {
        Header: 'Eklenme tarihi',
        accessor: 'dateAdded',
        maxWidth: 120,
        Filter: () => {
          return <></>
        },
      },
    ]

    if (withFullPrice) {
      currentColumns.splice(3, 0, {
        Header: 'Sepet İndirimi',
        accessor: 'price_cart_discount',
        maxWidth: 120,
        Filter: () => {
          return <></>
        },
      })
    }

    if (withTotalQuantity) {
      currentColumns.splice(3, 0, {
        Header: 'Stok',
        accessor: 'total_quantity',
        maxWidth: 120,
        Filter: () => {
          return <></>
        },
      })
    }

    if (withBadge) {
      currentColumns.push({
        Header: 'Aksiyonlar',
        accessor: '',
        Filter: () => {
          return <></>
        },
        // @ts-ignore
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row justify-content-between'>
              <div></div>
              <Tooltip placement='top' title={'Badge Listesi'}>
                <div
                  onClick={(e) => {
                    e.preventDefault()
                    setProductBadges(row.original.badges)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                >
                  <BookOutlined style={{fontSize: 16}} />
                </div>
              </Tooltip>
            </div>
          </>
        ),
      })
    }

    if (withImage) {
      currentColumns.splice(0, 0, {
        Header: 'Görsel',
        accessor: 'image',
        maxWidth: 52,
        // @ts-ignore
        manualWidth: 52,
        Cell: ({row}: Cell<Row>) => (
          <Avatar size={52} src={row.values.image} style={{minWidth: 52}} />
        ),
        Filter: () => <></>,
      })
    }

    if (withAvailable) {
      currentColumns.splice(4, 0, {
        Header: 'Geçerlilik Tarihi',
        accessor: 'dateAvailable',
        maxWidth: 120,
        Filter: () => {
          return <></>
        },
      })
    }

    return currentColumns
  }, [withFullPrice])

  useEffect(() => {
    if (!includedProductIds || includedProductIds.length === 0) {
      return
    }

    setTransferData((data) => {
      const _data = data.filter((item) => {
        return !includedProductIds.some((id: number) => id === item.id)
      })

      setTransferIds(
        _data.map((item) => {
          return item.id
        })
      )

      return _data
    })
  }, [includedProductIds])

  useEffect(() => {
    if (initialRef.current < 2) {
      initialRef.current += 1
      return
    }
  }, [excludedTempProductIds])

  useEffect(() => {
    if (!tableFilter || Object.keys(tableFilter).length === 0) {
      return
    }

    setExcludedTempProductIds([])
    getProducts()
  }, [tableFilter])

  useEffect(() => {
    if (fetchRef.current === 0) {
      return
    }

    getProducts()
  }, [meta.page])

  useEffect(() => {
    if (fetchRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
    }

    getProducts()
  }, [meta.limit])

  useEffect(() => {
    if (!filters || Object.keys(filters).length === 0) {
      return
    }

    onFilterChange && onFilterChange(filters)

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      setExcludedTempProductIds([])
      return
    }

    getProducts()
    setExcludedTempProductIds([])
  }, [filters])

  useEffect(() => {
    onFilterTypeChange && onFilterTypeChange(type)
  }, [type])

  useEffect(() => {
    onMetaChange && onMetaChange(meta)
  }, [meta])

  const updateData = (payload: any[]) => {
    setData(payload)
  }

  const updateMeta = (data: object) => {
    setMeta((meta) => ({...meta, ...data}))
  }

  const updateTransferData = (data: any[]) => {
    setTransferData(data)
  }

  const updateFilters = (
    data: productVerticalAdvancedFilterDataType | productTextAreaFilterDataType
  ) => {
    setFilters(data)
  }

  const updateExcludedProductIds = (data: number[]) => {
    setExcludedProductIds(data)
  }

  const updateTableFilter = useMemo(() => {
    const update = (data: any) => {
      setTableFilter((filter: any) => ({...filter, ...data}))
    }

    return debounce(update, 1000)
  }, [])

  useEffect(() => {
    if (transferData.length === 0) {
      return
    }

    getProducts()
  }, [excludedProductIds])

  return (
    <ProductSelectionContext.Provider
      value={{
        type,
        data,
        meta,
        columns,
        tableFilter,
        transferIds,
        transferData,
        selectedItems,
        productBadges,
        excludedTempProductIds,
        setExcludedTempProductIds,
        setType,
        setSelectedItems,
        updateMeta,
        getProducts,
        updateFilters,
        updateTableFilter,
        updateTransferData,
        updateExcludedProductIds,
        updateData,
        setProductBadges,
      }}
    >
      {children}
    </ProductSelectionContext.Provider>
  )
}
