import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {useIBMCampaignSpecialGroupProductsExport} from '../../../hooks/IBM/useIBMCampaignSpecialGroupProductsExport'
import {ExcelExport} from '../../../layout/excel/ExcelExportWrapper'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'

export const IBMCampaignSpecialGroupProductsExportWrapper: FC = () => {
  const {downloadExcelUrl, exportIBMCampaignSpecialGroupProducts} =
    useIBMCampaignSpecialGroupProductsExport()
  return (
    <>
      <Toolbar />
      <PageTitle>IBM Özel Grupların Ürünlerini Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport
              handleExportData={exportIBMCampaignSpecialGroupProducts}
              downloadExcelUrl={downloadExcelUrl}
            />
          }
        />
      </Routes>
    </>
  )
}
