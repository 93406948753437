import {DeleteOutlined, InfoCircleOutlined} from '@ant-design/icons'
import {Button, Popconfirm, Tooltip} from 'antd'
import React, {useContext, useEffect, useState} from 'react'

import {FooterModulesContext} from '../../../../../providers/FooterModulesProvider'
import {
  IUpdateFooterModuleSubModule,
  StoreSubModuleDataType,
} from '../../../../models/footer/StoreModulesModel'
import {KrcTable} from '../../../../organisms/tables/KrcTable'

interface FooterModuleSubModuleProps {
  data: any[]
  onSave: (subModuleData: IUpdateFooterModuleSubModule) => void
}

export const FooterModuleSubModuleTable: React.FC<FooterModuleSubModuleProps> = ({
  data,
  onSave,
}) => {
  const [tableData, setTableData] = useState(data)
  const [willBeRemovedSubModules, setWillBeRemovedSubModules] = useState<any[]>([])
  const {selectedModuleInformation} = useContext(FooterModulesContext)
  const [shouldUseSaveButtonText, setShouldUseSaveButtonText] = useState<boolean>(false)
  const [changedData, setChangedData] = useState<StoreSubModuleDataType[]>([])

  const handleSave = () => {
    const willBeRemovedSubModuleIds = willBeRemovedSubModules.filter(
      (id: string | number) => !id.toString()?.includes('new_')
    )
    const willBeAddedSubModules = tableData.filter((d) =>
      d?.sub_module_id.toString()?.includes('new_')
    )
    const willBeUpdatedSubModules = changedData.filter(
      (item) => !willBeRemovedSubModuleIds.includes(item.sub_module_id)
    )

    const cleanedWillBeAddedSubModules = willBeAddedSubModules.map((d) => {
      return {
        parent_module_id: d.parent_module_id as number,
        sub_module_sort: d.sub_module_sort as number,
        sub_module_target_blank: d.sub_module_target_blank as number,
        sub_module_title: d.sub_module_title as string,
        sub_module_url: d.sub_module_url as string,
      }
    })

    onSave({
      will_be_added_sub_modules: cleanedWillBeAddedSubModules,
      will_be_updated_sub_modules: willBeUpdatedSubModules,
      will_be_removed_sub_modules_ids: willBeRemovedSubModuleIds,
    })
  }

  const handleRemoveSubModule = (subModuleId: number) => {
    setWillBeRemovedSubModules((prevData) => [...prevData, subModuleId])
    setShouldUseSaveButtonText(true)
  }

  useEffect(() => {
    // Filter out the removed submodules from tableData once willBeRemovedSubModules is updated
    const updatedTableData = tableData.filter(
      (record) => !willBeRemovedSubModules.includes(record.sub_module_id)
    )
    setTableData(updatedTableData)
  }, [willBeRemovedSubModules])

  const handleAddSubModule = () => {
    const newSubModuleId = `new_${Date.now()}`
    const newSubModule = {
      sub_module_id: newSubModuleId,
      parent_module_id: selectedModuleInformation!.module_id,
      sub_module_sort: tableData.length + 1,
      sub_module_target_blank: 0,
      sub_module_title: '',
      sub_module_url: '',
    }
    setTableData((prevData) => [...prevData, newSubModule])
  }

  const handleCellChange = (rowIndex: number, dataIndex: string, newValue: string) => {
    setChangedData((prevChangedData) => {
      const updatedChangedData = prevChangedData.map((d) => {
        if (d?.sub_module_id === tableData[rowIndex]?.sub_module_id) {
          return {...d, [dataIndex]: newValue}
        }
        return d
      })

      const currentItem = tableData[rowIndex]
      if (currentItem) {
        if (!updatedChangedData.some((d) => d?.sub_module_id === currentItem?.sub_module_id)) {
          const newItem = {...currentItem, [dataIndex]: newValue}
          updatedChangedData.push(newItem)
        }
      }
      return updatedChangedData
    })

    setTableData((prevData) => {
      const newData = prevData.map((row, i) =>
        i === rowIndex ? {...row, [dataIndex]: newValue} : row
      )
      return newData
    })

    setShouldUseSaveButtonText(true)
  }

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Başlık',
        accessor: 'sub_module_title',
        key: 'sub_module_title',
        Cell: ({row, column}: any) => {
          return (
            <input
              className={'form-control'}
              value={row.original.sub_module_title}
              //onChange={(e) => handleCellChange(row.index, column.id, e.target.value)}
              onChange={(e) => handleCellChange(row.index, column.id, e.target.value)}
            />
          )
        },
      },
      {
        Header: () => {
          return (
            <div className={'d-flex align-items-center'}>
              URL
              <span className={'text-danger text-sm fw-light fst-italic ml-2'}>
                (Url girerken başına / koymayınız.)
              </span>
            </div>
          )
        },
        accessor: 'sub_module_url',
        key: 'sub_module_url',
        Cell: ({row, column}: any) => {
          return (
            <div className={'d-flex align-items-center'}>
              <input
                className={'form-control'}
                value={row.original.sub_module_url}
                onChange={(e) => handleCellChange(row.index, column.id, e.target.value)}
              />
              <span className={'text-sm text-danger fw-light fst-italic ml-2'}></span>
              <Tooltip title='Url girerken başına / koymayınız.'>
                <InfoCircleOutlined style={{color: 'red'}} />
              </Tooltip>
            </div>
          )
        },
      },
      {
        Header: 'Target Blank',
        accessor: 'sub_module_target_blank',
        key: 'sub_module_target_blank',
        Cell: ({row, column}: any) => {
          return (
            <select
              className='form-select'
              value={row.original.sub_module_target_blank}
              onChange={(e) => handleCellChange(row.index, column.id, e.target.value)}
              aria-label='Default select example'
            >
              <option value={0}>Hayır</option>
              <option value={1}>Evet</option>
            </select>
          )
        },
      },
      {
        Header: 'Sıra',
        accessor: 'sub_module_sort',
        key: 'sub_module_sort',
        Cell: ({row, column}: any) => {
          return (
            <input
              className={'form-control w-100'}
              value={row.original.sub_module_sort}
              onChange={(e) => handleCellChange(row.index, column.id, e.target.value)}
            />
          )
        },
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        key: 'actions',
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex justify-content-center'}>
              <Popconfirm
                title='Bu başlığı silmek istediğinden emin misiniz?'
                onConfirm={() => handleRemoveSubModule(row.original.sub_module_id)}
                okText='Evet'
                cancelText='Hayır'
              >
                <Button icon={<DeleteOutlined />} type={'default'} />
              </Popconfirm>
            </div>
          )
        },
      },
    ]
  }, [])

  return (
    <>
      <KrcTable columns={columns} data={tableData} />
      <div className={'d-flex flex-column '}>
        <Button onClick={handleAddSubModule}>+ Yeni Başlık Ekle</Button>
      </div>
      {shouldUseSaveButtonText && (
        <div className={'d-flex justify-content-end mt-4'}>
          <p className={'text-danger text-sm fw-light fst-italic mr-2 mb-n2'}>
            (Değişiklikleri uygulamak için başlıkları kaydedin)
          </p>
        </div>
      )}
      <div className={'d-flex justify-content-end mt-4'}>
        <Button type={'primary'} className={'ml-auto'} onClick={handleSave}>
          Başlıkları Kaydet
        </Button>
      </div>
    </>
  )
}
