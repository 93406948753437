import {DeleteOutlined} from '@ant-design/icons'
import {Checkbox, List, Popconfirm, Tooltip, message} from 'antd'
import {CheckboxChangeEvent} from 'antd/es/checkbox'
import {Field, Form, Formik, FormikValues} from 'formik'
import {OptionData} from 'rc-select/lib/interface'
import React, {FC, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'

import {COUPON_STORES} from '../../../../enums/CouponStoresTypeEnum'
import {FIXED, FIXED_LABEL, PERCENTAGE, PERCENTAGE_LABEL} from '../../../../enums/CouponTypeEnum'
import {createCouponRequest} from '../../../../requests/campaign_and_coupon/CouponRequest'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcDatePicker} from '../../../atoms/forms/KrcDatePicker'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {ProductSelection} from '../../../layout/filters/ProductSelection'
import {CouponProductsType} from '../../../models/campaign_and_coupon/CouponPrefixFilterModel'
import {CouponPrefixFilter} from '../../../molecules/filters/CouponPrefixFilter'
import {IBMCampaignSpecialFilter} from '../../../molecules/filters/IBMCampaignSpecialFilter'
import {ManufacturerFilter} from '../../../molecules/filters/ManufacturerFilter'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

const formikInitialValues = {
  name: '',
  code: '',
  type: FIXED,
  discount: '',
  customer: 0,
  date_start: '',
  date_end: '',
  status: 0,
  total: '',
  max_amount: '',
  min_quantity: 1,
  combined_deactivate: '',
  uses_total: '',
  uses_customer: '',
  special_group: '',
  manufacturer: '',
  logged: 0,
  shipping: 0,
}

const schema = Yup.object({
  name: Yup.string().required('Kupon adı zorunludur.'),
  code: Yup.string().required('Kupon kodu zorunludur.'),
  type: Yup.string().required('İndirim türü zorunludur.'),
  discount: Yup.string().required('İndirim zorunludur.'),
  customer: Yup.string().required('Müşteri bilgisi zorunludur.'),
  status: Yup.string().required('Durum zorunludur.'),
  uses_total: Yup.string().required('Kullanım sayısı zorunludur.'),
  uses_customer: Yup.string().required('Müşteri başına kullanım zorunludur.'),
  total: Yup.number().required('Minimum sepet tutarı zorunludur.'),
  logged: Yup.string().required('Giriş alanı seçinmi zorunludur.'),
  shipping: Yup.string().required('Kargo alanı seçinmi zorunludur.'),
})

export const CouponCreateForm: FC = () => {
  const [manufacturers, setManufacturers] = useState<number[]>([])
  const [prefix, setPrefix] = useState<OptionData[]>([])
  const [specialGroups, setSpecialGroups] = useState<OptionData[]>([])
  const [startDate, setStartDate] = useState<string | null>('')
  const [startTime, setStartTime] = useState<string | null>('')
  const [endDate, setEndDate] = useState<string | null>('')
  const [endTime, setEndTime] = useState<string | null>('')
  const [combinedDeactivated, setCombinedDeactivated] = useState<boolean>(false)
  const [products, setProducts] = useState<CouponProductsType[]>([])
  const [willBeAddedProducts, setWillBeAddedProducts] = useState<CouponProductsType[]>([])
  const [storeIds, setStoreIds] = useState<number[]>([])

  const navigate = useNavigate()

  const handleOnSubmit = (values: FormikValues, {resetForm}: any) => {
    message.loading('Kupon oluşturuluyor...', 1)
    if (!startDate || !startTime || !endDate || !endTime) {
      message.error('Başlangıç ve bitiş tarihleri zorunludur.')
      return
    }

    if (startDate > endDate) {
      message.error('Başlangıç tarihi bitiş tarihinden büyük olamaz.')
      return
    }

    if (!prefix || prefix.length === 0) {
      message.error('Prefix seçimi zorunludur.')
      return
    }

    if (willBeAddedProducts.length > 10) {
      message.error('Bir kupon en fazla 10 ürün ile ilişkilendirilebilir.')
      return
    }

    const formattedData = {
      ...values,
      customer_id: values.customer,
      manufacturer: manufacturers[0] ? manufacturers[0] : null,
      code: prefix[0].label + values.code,
      prefix: prefix[0].value,
      special_group: specialGroups[0] ? specialGroups[0].value : null,
      date_start: `${startDate} ${startTime}`,
      date_end: `${endDate} ${endTime}`,
      product_ids: willBeAddedProducts ? willBeAddedProducts.map((p) => p.id) : [],
      store_ids: storeIds,
      is_combining_disable: combinedDeactivated,
    }

    createCouponRequest(formattedData)
      .then(() => {
        message.success('Kupon başarıyla oluşturuldu.')
        setTimeout(() => {
          navigate('/coupon')
        }, 800)
      })
      .catch((error) => {
        message.error('Kupon oluşturulurken bir hata oluştu.')
        message.error(error.response.data.message)
      })
  }

  const handleOnChangeCheckbox = (e: CheckboxChangeEvent) => {
    setCombinedDeactivated(e.target.checked)
  }

  return (
    <>
      <Formik
        initialValues={formikInitialValues}
        onSubmit={handleOnSubmit}
        validationSchema={schema}
        enableReinitialize={true}
      >
        {(formik) => {
          return (
            <Form className={'form p-3 rounded-2 me-10'}>
              <div className={'row d-flex justify-content-between'}>
                <div className={'col-7'}>
                  <h3 className={'mb-4'}>Kupon Bilgileri</h3>
                  <div className={'col-11'}>
                    <div className={'col-9'}>
                      <KrcFormikField
                        className={'mb-5'}
                        type={'text'}
                        name={'name'}
                        required
                        labelTitle={'Kupon Adı'}
                      />
                    </div>
                  </div>
                  <div className={'col-11'}>
                    <div className={'row'}>
                      <div className={'col-3'}>
                        <KrcFormikField
                          className={'mb-3'}
                          type={'text'}
                          name={'prefix'}
                          required
                          labelTitle={'Prefix Seçimi'}
                        >
                          <CouponPrefixFilter
                            size={'large'}
                            initialLoad={true}
                            placeholder={'Lütfen seçiniz'}
                            className={'form-control rounded-5'}
                            value={prefix}
                            onSelected={(data) => {
                              if (Array.isArray(data)) {
                                if (data.length > 1) {
                                  message.error('Sadece bir adet prefix seçebilirsiniz.')
                                  return
                                }
                              }
                              setPrefix(data as OptionData[])
                            }}
                          />
                        </KrcFormikField>
                      </div>
                      <div className={'col-6'}>
                        <KrcFormikField
                          className={'mb-5'}
                          type={'text'}
                          name={'code'}
                          required
                          labelTitle={'Kupon Kodu'}
                        />
                      </div>
                      {/*<div className={'col-3 d-flex align-items-center mt-3'}>*/}
                      {/*    <Button size={'large'} icon={<PlusCircleOutlined />} className={'d-flex justify-content-around align-items-center rounded-2 px-8'} onClick={() => console.log('CLICKED')}>*/}
                      {/*        Bulk*/}
                      {/*    </Button>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                  <div className={'col-11'}>
                    <div className={'row'}>
                      <div className={'col-3'}>
                        <KrcFormikField
                          className={'mb-5'}
                          name={'type'}
                          labelTitle={'İndirim Türü'}
                          required
                        >
                          <Field as='select' name='type' className='form-select'>
                            <option value={undefined} disabled={true} selected={true}>
                              Seçiniz
                            </option>
                            <option value={PERCENTAGE}>{PERCENTAGE_LABEL}</option>
                            <option value={FIXED}>{FIXED_LABEL}</option>
                          </Field>
                        </KrcFormikField>
                      </div>
                      <div className={'col-6'}>
                        <KrcFormikField
                          className={'mb-5'}
                          type={'number'}
                          name={'discount'}
                          required
                          labelTitle={'İndirim'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={'col-10'}>
                    <KrcFormikField
                      className={'mb-5'}
                      type={'text'}
                      name={'customer'}
                      labelTitle={'Müşteri Bilgileri'}
                    />
                  </div>
                </div>
                <div className={'col-4'}>
                  <h3 className={'mb-4'}>Başlangıç ve Bitiş Tarihi / Saati</h3>
                  <div className={'col-9 d-flex'}>
                    <div className={'col-7'}>
                      <KrcFormikField
                        className={'mb-5'}
                        type={'text'}
                        name={'date_start'}
                        required
                        labelTitle={'Başlangıç Tarihi'}
                      >
                        <KrcDatePicker
                          className={'form-control rounded-2'}
                          placeholder={'Başlangıç Tarihi'}
                          format={'DD.MM.YYYY'}
                          size={'large'}
                          allowClear
                          onChanged={(date) => {
                            if (!date) {
                              setStartDate(null)
                              return
                            }
                            setStartDate(date.date)
                          }}
                        />
                      </KrcFormikField>
                    </div>
                    <div className={'col-5'}>
                      <KrcFormikField className={'ms-8'} name={'time'} required labelTitle={'Saat'}>
                        <KrcDatePicker
                          size={'large'}
                          className={'rounded-2 form-control'}
                          picker={'time'}
                          placeholder={'Saat'}
                          allowClear={true}
                          showNow={false}
                          format={'HH:mm'}
                          onChanged={(data) => {
                            if (!data) {
                              setStartTime(null)
                              return
                            }
                            setStartTime(data.time)
                          }}
                        />
                      </KrcFormikField>
                    </div>
                  </div>
                  <div className={'col-9 d-flex'}>
                    <div className={'col-7'}>
                      <KrcFormikField
                        className={'mb-5'}
                        type={'text'}
                        name={'date_end'}
                        required
                        labelTitle={'Bitiş Tarihi'}
                      >
                        <KrcDatePicker
                          className={'form-control rounded-2'}
                          placeholder={'Bitiş Tarihi'}
                          format={'DD.MM.YYYY'}
                          size={'large'}
                          allowClear
                          onChanged={(date) => {
                            if (!date) {
                              setEndDate(null)
                              return
                            }
                            setEndDate(date.date)
                          }}
                        />
                      </KrcFormikField>
                    </div>
                    <div className={'col-5'}>
                      <KrcFormikField className={'ms-8'} name={'time'} required labelTitle={'Saat'}>
                        <KrcDatePicker
                          size={'large'}
                          className={'rounded-2 form-control'}
                          picker={'time'}
                          placeholder={'Saat'}
                          allowClear={true}
                          showNow={false}
                          format={'HH:mm'}
                          onChanged={(data) => {
                            if (!data) {
                              setEndTime(null)
                              return
                            }
                            setEndTime(data.time)
                          }}
                        />
                      </KrcFormikField>
                    </div>
                  </div>
                  <div className={'col-4'}>
                    <KrcFormikField
                      className={'mb-5'}
                      name={'status'}
                      labelTitle={'Durum'}
                      required
                    >
                      <Field as='select' name='status' className='form-select'>
                        <option value={undefined} disabled={true} selected>
                          Seçiniz
                        </option>
                        <option value={0}>Pasif</option>
                        <option value={1}>Aktif</option>
                      </Field>
                    </KrcFormikField>
                  </div>
                </div>
                <div className={'col-1'} />
              </div>
              <div className={'row mb-5'}>
                <h3 className={'mb-4'}>Koşullar</h3>
                <div className={'col-2'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'number'}
                    name={'total'}
                    labelTitle={'Minimum Sepet Tutarı'}
                    required
                    tooltipProps={{
                      status: true,
                      text: 'Toplam sipariş tutarı belirtilen rakam üzerindeyse indirim kuponunu kullanılsın.',
                    }}
                  />
                </div>
                <div className={'col-2'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'number'}
                    name={'max_amount'}
                    labelTitle={'Maksimum Sepet Tutarı'}
                    tooltipProps={{
                      status: true,
                      text: 'Kupon kodunun kullanılabileceği maksimum sepet tutarı. (Sınırsız : 0)',
                    }}
                  />
                </div>
                <div className={'col-2'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'number'}
                    name={'min_quantity'}
                    required
                    labelTitle={'Minimum Adet'}
                    tooltipProps={{
                      status: true,
                      text: 'Kupon kodunun kullanılabileceği maksimum sepet tutarı. (Sınırsız : 0)',
                    }}
                  />
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={
                      'mb-3 d-flex flex-column justify-content-center align-items-start ms-2'
                    }
                    type={'text'}
                    name={'is_combining_disable'}
                    required
                    labelTitle={'Birleştirme Kapalı'}
                    tooltipProps={{
                      status: true,
                      text: 'Kuponun diğer kampanyalarla birleşime açık olmasını sağlar.',
                    }}
                  >
                    <Checkbox value={combinedDeactivated} onChange={handleOnChangeCheckbox}>
                      Birleşime Kapalı
                    </Checkbox>
                  </KrcFormikField>
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2 z-index-9999'}
                    name={'store_ids'}
                    labelTitle={'Mağazalar'}
                  >
                    <KrcSelect
                      maxTagCount='responsive'
                      mode={'multiple'}
                      placeholder={'Seçiniz'}
                      size={'large'}
                      dropdownStyle={{zIndex: 9999}}
                      className={
                        'ant-selector-border custom-ant-select-selection-placeholder w-full d-block'
                      }
                      options={COUPON_STORES.map((store) => {
                        return {
                          value: store.value,
                          label: store.label,
                        }
                      })}
                      onChange={(storeId) => {
                        if (!storeId) {
                          setStoreIds([])
                          return
                        }
                        setStoreIds(storeId)
                      }}
                      style={{width: '100%'}}
                    />
                  </KrcFormikField>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'number'}
                    name={'uses_total'}
                    required
                    labelTitle={'Kupon Başına Kullanım'}
                    tooltipProps={{
                      status: true,
                      text: 'Tüm müşterilerin bu kuponu kaç kez kullanabileceğini belirleyin. Sınırsız için boş bırakın.',
                    }}
                  />
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'number'}
                    name={'uses_customer'}
                    required
                    labelTitle={'Müşteri Başına Kullanım'}
                    tooltipProps={{
                      status: true,
                      text: 'Tek müşterinin bu kuponu kaç kez kullanabileceğini belirleyin. Sınırsız için boş bırakın.',
                    }}
                  />
                </div>

                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'text'}
                    name={'special_group'}
                    labelTitle={'Özel Grup Seçimi'}
                  >
                    <IBMCampaignSpecialFilter
                      size={'large'}
                      placeholder={'Özel Grup seçiniz'}
                      className={'form-control rounded-5'}
                      value={specialGroups}
                      onSelected={(data) => {
                        if (data.length > 1) {
                          message.error('Sadece bir özel grup seçebilirsiniz.')
                          return
                        }
                        setSpecialGroups(data)
                      }}
                    />
                  </KrcFormikField>
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-5'}
                    type={'text'}
                    name={'manufacturer'}
                    labelTitle={'Marka Seçimi'}
                  >
                    <ManufacturerFilter
                      size={'large'}
                      placeholder={'Marka seçiniz'}
                      className={'form-control rounded-5'}
                      value={manufacturers}
                      onSelected={(data) => {
                        if (data.length > 1) {
                          message.error('Sadece bir marka seçebilirsiniz.')
                          return
                        }
                        setManufacturers(data as number[])
                      }}
                    />
                  </KrcFormikField>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-5'}
                    name={'shipping'}
                    labelTitle={'Ücretsiz Kargo'}
                    required
                  >
                    <Field as='select' name='shipping' className='form-select'>
                      <option value={undefined} disabled={true} selected>
                        Seçiniz
                      </option>
                      <option value={0}>Hayır</option>
                      <option value={1}>Evet</option>
                    </Field>
                  </KrcFormikField>
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-5'}
                    name={'logged'}
                    labelTitle={'Müşteri Girişi'}
                    required
                    tooltipProps={{
                      status: true,
                      text: 'Müşteri kuponu kullanabilmesi için giriş yapmasını gerektirir.',
                    }}
                  >
                    <Field as='select' name='logged' className='form-select'>
                      <option value={undefined} disabled={true} selected>
                        Seçiniz
                      </option>
                      <option value={0}>Hayır</option>
                      <option value={1}>Evet</option>
                    </Field>
                  </KrcFormikField>
                </div>
              </div>
              <div className={'row'}>
                <KrcFormikField
                  className={'mb-3 d-flex flex-row justify-content-between'}
                  type={'text'}
                  name={'products'}
                >
                  <div className={'col-6'}>
                    <ProductSelection
                      onTransfer={(data) => {
                        if (data.length === 0) {
                          return
                        }
                        setWillBeAddedProducts((prevState) => {
                          const mergeUniqueById = (
                            prevState: CouponProductsType[],
                            data: CouponProductsType[]
                          ) => {
                            const combinedArray = [...prevState, ...data]

                            const uniqueArray = combinedArray.reduce((acc, current) => {
                              // Eğer acc array'inde current.id ile eşleşen bir id yoksa current'i ekle
                              if (!acc.some((item: CouponProductsType) => item.id === current.id)) {
                                // @ts-ignore
                                acc.push(current)
                              }
                              return acc
                            }, [])

                            return uniqueArray
                          }
                          return [...mergeUniqueById(prevState, data)]
                        })
                        message.info('Eklenen ürünler listelendi.')
                      }}
                      onSelectedItems={(data) => {
                        setProducts(data ?? [])
                      }}
                      includedProductIds={products.map((p) => p.id)}
                      headerProps={{
                        titleLevel: 5,
                      }}
                      transferProps={{
                        button: {
                          title: 'Ürünleri Ekle >>',
                          isActive: true,
                        },
                        config: {
                          confirmActive: true,
                          confirm: {
                            title: `Seçilen ${products.length} ürün kupon ile ilişkilendirilecek.`,
                            icon: false,
                            width: 600,
                            content: (
                              <List
                                size='small'
                                header={
                                  <div>
                                    <b>Eklenecek Ürünler</b>
                                  </div>
                                }
                                bordered
                                dataSource={products}
                                renderItem={(item) => {
                                  return (
                                    <List.Item>
                                      <div
                                        className={
                                          'd-flex flex-row justify-content-around w-100 align-items-center'
                                        }
                                      >
                                        <div>{item.id}</div>
                                        <div>{item.model}</div>
                                        <div>{item.name}</div>
                                      </div>
                                    </List.Item>
                                  )
                                }}
                              />
                            ),
                            okText: 'Tamam',
                            cancelText: 'İptal',
                          },
                        },
                      }}
                    />
                  </div>
                  <div className={'col-5 pt-10'}>
                    <List
                      size='small'
                      header={
                        <div>
                          <b>Eklenecek Ürünler</b>
                        </div>
                      }
                      bordered
                      dataSource={willBeAddedProducts}
                      renderItem={(item) => {
                        return (
                          <List.Item>
                            <div
                              className={
                                'd-flex flex-row justify-content-between w-100 align-items-center'
                              }
                            >
                              <div className={'col-3'} style={{fontSize: '1rem'}}>
                                {item.id}
                              </div>
                              <div className={'col-4'} style={{fontSize: '1rem'}}>
                                {item.model}
                              </div>
                              <div className={'col-4'} style={{fontSize: '1rem'}}>
                                {item.name}
                              </div>
                              <div className={'d-flex justify-content-end'}>
                                <Tooltip title={'Sil'}>
                                  <Popconfirm
                                    title={`${item.name} ürününü silmek istediğinize emin misiniz?`}
                                    onConfirm={() => {
                                      setWillBeAddedProducts((prevState) => {
                                        return prevState.filter((p) => p.id !== item.id)
                                      })
                                      message.info(
                                        'Ürün, eklenecek ürünler listesinden kaldırıldı.'
                                      )
                                    }}
                                    okText='Evet'
                                    cancelText='Hayır'
                                  >
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                                      <DeleteOutlined />
                                    </div>
                                  </Popconfirm>
                                </Tooltip>
                              </div>
                            </div>
                          </List.Item>
                        )
                      }}
                    />
                  </div>
                </KrcFormikField>
              </div>
              <div className={'d-flex justify-content-end mt-7'}>
                <KrcButton
                  size={'large'}
                  id='kt_toolbar_primary_button'
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                >
                  Kaydet
                </KrcButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
