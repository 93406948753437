import React, {FC} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {OrderQuestioningProvider} from '../../../providers/orders/OrderQuestioningProvider'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {OrderQuestioning} from './OrderQuestioning'

export const OrderQuestioningWrapperWithoutProvider: FC = () => {
  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Sipariş Sorgulama</PageTitle>
      <OrderQuestioning />
    </>
  )
}

export const OrderQuestioningWrapper: FC = () => {
  return (
    <OrderQuestioningProvider>
      <OrderQuestioningWrapperWithoutProvider />
    </OrderQuestioningProvider>
  )
}
