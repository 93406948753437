import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {useMenuMap} from '../../../hooks/menus/useMenuMap'
import {ExcelExport} from '../../../layout/excel/ExcelExportWrapper'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'

export const MenuExportWrapper: FC = () => {
  const {exportMenu, downloadExcelUrl} = useMenuMap()

  return (
    <>
      <Toolbar />
      <PageTitle>Menu Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport handleExportData={exportMenu} downloadExcelUrl={downloadExcelUrl} />
          }
        />
      </Routes>
    </>
  )
}
