import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {DrawerEnum} from '../../../enums/DrawerEnum'
import {ReviewListContext, ReviewListProvider} from '../../../providers/reviews/ReviewListProvider'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {ReviewDrawer} from '../../drawers/reviews/ReviewDrawer'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {ReviewList} from './ReviewList'

const ReviewListWrapperWithoutProvider: FC = () => {
  const {
    approveReviews,
    archiveReviews,
    deleteReviews,
    setIsDrawerTypeAndVisible,
    isDrawerTypeAndVisible,
    drawerData,
    setDrawerData,
    getReviews,
  } = useContext(ReviewListContext)

  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1 gap-2'>
          <KrcButton className={'custom-hover text-white button-bg-green'} onClick={approveReviews}>
            Yorumları Onayla
          </KrcButton>
          <KrcButton className={'custom-hover text-white button-bg-gray'} onClick={archiveReviews}>
            Yorumları Arşivle
          </KrcButton>
          <KrcButton
            className={'custom-hover text-white'}
            onClick={() => {
              setIsDrawerTypeAndVisible(DrawerEnum.CREATE)
            }}
          >
            Yorum Ekle
          </KrcButton>
          <KrcButton danger className={'btn-red'} onClick={deleteReviews}>
            Yorum Sil
          </KrcButton>
        </div>
      </Toolbar>
      <PageTitle>Yorum Listesi</PageTitle>
      <ReviewList />

      {isDrawerTypeAndVisible && (
        <ReviewDrawer
          data={drawerData}
          type={isDrawerTypeAndVisible}
          isDrawerVisible={!!isDrawerTypeAndVisible}
          onHide={() => {
            setDrawerData(null)
            setIsDrawerTypeAndVisible(null)
          }}
          onSubmit={() => {
            getReviews()
            setDrawerData(null)
            setIsDrawerTypeAndVisible(null)
          }}
        />
      )}
    </>
  )
}

export const ReviewListWrapper: FC = () => {
  return (
    <>
      <ReviewListProvider>
        <ReviewListWrapperWithoutProvider />
      </ReviewListProvider>
    </>
  )
}
