import {useState} from 'react'

import {IMinMaxDataType} from '../../../modules/partials/components/filter/models/FilterModel'

export const useMinMaxFilter = (minMaxDefaultValue: IMinMaxDataType) => {
  const [inputPrice, setInputPrice] = useState<IMinMaxDataType>(minMaxDefaultValue)
  const handleOnChanged = (value: any, type: string) => {
    setInputPrice((data) => {
      if (type === 'min') {
        return {...data, min: value}
      }

      return {...data, max: value}
    })
  }

  const minChangeHandler = (value: any) => {
    setInputPrice((data) => {
      return {...data, min: value}
    })
  }

  const maxChangeHandler = (value: any) => {
    setInputPrice((data) => {
      return {...data, max: value}
    })
  }

  return {inputPrice, minChangeHandler, maxChangeHandler, handleOnChanged}
}
