import {message} from 'antd'
import {FormikValues} from 'formik'
import debounce from 'lodash/debounce'
import React, {FC, createContext, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {
  editorType,
  landingDataType,
  landingFormikDataType,
} from '../components/models/landings/LandingModel'
import {landingUrlType} from '../components/models/products/passives/PassiveProductModel'
import {errorMessage} from '../helpers/ErrorHelper'
import {removeEmptyItemInObject} from '../helpers/ObjectHelper'
import {
  createLandingRequest,
  getLandingRequest,
  updateLandingRequest,
} from '../requests/landings/LandingRequest'

type landingContextType = {
  landing?: landingDataType
  onChangeValidate: (data: landingFormikDataType) => void
  isValid: boolean
  setIsValid: (data: boolean) => void
  getLanding: (data: number) => void
  updateLanding: () => void
  createLanding: () => void
  handleOnChangeElement: (data: editorType) => void
  updateOrCreateData: {
    landing: landingFormikDataType
    elements: editorType[]
  }
}

export const LandingContext = createContext<landingContextType>({
  isValid: true,
  setIsValid: () => {},
  onChangeValidate: () => {},
  getLanding: () => {},
  updateLanding: () => {},
  createLanding: () => {},
  handleOnChangeElement: () => {},
  updateOrCreateData: {
    landing: {
      name: '',
      meta_description: '',
      meta_keywords: '',
      landing_page_url: '',
      page_type: 'webview',
      status: '',
      redirect_url: '',
    },
    elements: [],
  },
})

export const LandingProvider: FC = ({children}) => {
  const {landingId} = useParams<landingUrlType>()
  const [landing, setLanding] = useState<landingDataType>()
  const [isValid, setIsValid] = useState<boolean>(true)
  const [updateOrCreateData, setUpdateOrCreateData] = useState<{
    landing: landingFormikDataType
    elements: editorType[]
  }>({
    landing: {
      name: '',
      meta_description: '',
      meta_keywords: '',
      landing_page_url: '',
      page_type: 'webview',
      status: '',
      redirect_url: '',
    },
    elements: [],
  })
  const createFetchId = useRef(0)
  const updateFetchId = useRef(0)
  const navigate = useNavigate()

  useEffect(() => {
    if (!landingId) {
      return
    }

    getLanding(Number(landingId))
  }, [landingId])

  const getLanding = (landingId: number) => {
    getLandingRequest(landingId, {with: ['elements'], url_alias: true})
      .then((res) => {
        setLanding(res.data.data)
        setUpdateOrCreateData((data) => ({
          ...data,
          landing: {
            name: res.data.data?.name ?? '',
            meta_description: res.data.data?.meta_description || '',
            meta_keywords: res.data.data?.meta_keywords || '',
            landing_page_url: res.data.data?.url_alias?.keyword || '',
            page_type: res.data.data?.page_type || 'webview',
            status:
              (res.data.data?.status !== undefined && Number(res.data.data?.status).toString()) ||
              '',
            redirect_url: res.data.data?.url_alias?.redirect || '',
          },
        }))
      })
      .catch(() => {
        message.error('Landing detayları yüklenemedi. Lütfen tekrar deneyiniz.')
      })
  }

  const updateLanding = () => {
    if (!landing) {
      return
    }

    const messageKey = 'update-landing'

    if (!updateOrCreateData) {
      message.loading({content: 'Landing güncellenecek veri bulunamadı', key: messageKey})
      return
    }

    updateFetchId.current += 1
    const fetchId = updateFetchId

    updateLandingRequest(landing.landing_id, updateOrCreateData)
      .then(() => {
        if (fetchId !== updateFetchId) {
          return
        }

        message.success({
          content: 'Landing başarılı bir şekilde güncellendi.',
          key: messageKey,
          duration: 2,
        })
      })
      .catch((e) => {
        errorMessage(e)
      })
  }

  const createLanding = () => {
    const messageKey = 'create-landing'

    if (!updateOrCreateData) {
      message.loading({content: 'Landing oluşturulacak veri bulunamadı', key: messageKey})
      return
    }

    const _data = removeEmptyItemInObject(updateOrCreateData)

    createFetchId.current += 1
    const fetchId = createFetchId.current

    message.loading({content: 'Landing oluşturuluyor', key: messageKey})

    return new Promise((resolve, reject) => {
      createLandingRequest(_data)
        .then((res) => {
          if (fetchId !== createFetchId.current) {
            return
          }

          message.success({
            content:
              'Başarılı bir şekilde landing oluşturuldu. Landings sayfasına yönlendiriliyorusunuz',
            key: messageKey,
            duration: 2,
          })
          resolve(true)

          setTimeout(() => {
            navigate('/landings')
          }, 500)
        })
        .catch((e) => {
          errorMessage(e)
          message.destroy(messageKey)
          reject(true)
        })
    })
  }

  const onChangeValidate = React.useMemo(() => {
    const loadChangeValidate = (values: FormikValues) => {
      setUpdateOrCreateData((data) => {
        return {...data, landing: {...data.landing, ...values}}
      })
    }

    return debounce(loadChangeValidate, 100)
  }, [])

  const handleOnChangeElement = (changedElement: editorType) => {
    setUpdateOrCreateData((data) => {
      const isUpdate = data.elements.some((element) => {
        return element.editor_id === changedElement.editor_id
      })

      if (!isUpdate) {
        return {...data, elements: [...data.elements, changedElement]}
      }

      return {
        ...data,
        elements: data.elements.map((element) => {
          if (element.editor_id !== changedElement.editor_id) {
            return element
          }
          return changedElement
        }),
      }
    })
  }

  return (
    <LandingContext.Provider
      value={{
        landing,
        isValid,
        setIsValid,
        getLanding,
        updateLanding,
        createLanding,
        handleOnChangeElement,
        onChangeValidate,
        updateOrCreateData,
      }}
    >
      {children}
    </LandingContext.Provider>
  )
}
