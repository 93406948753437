import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {useUrlAlias} from '../../../hooks/url/useUrlAlias'
import {ExcelExport} from '../../../layout/excel/ExcelExportWrapper'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'

export const UrlAliasListExportWrapper: FC = () => {
  const {exportUrlAliases, downloadExcelUrl} = useUrlAlias()

  return (
    <>
      <Toolbar />
      <PageTitle>URL Alias Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport
              handleExportData={exportUrlAliases}
              downloadExcelUrl={downloadExcelUrl ?? ''}
            />
          }
        />
      </Routes>
    </>
  )
}
