import {message, notification} from 'antd'
import {UploadChangeParam} from 'antd/lib/upload/interface'
import React, {useContext, useState} from 'react'

import {ExcelImportStatusEnum} from '../../../../../enums/ExcelImportStatusEnum'
import {ImportContext} from '../../../../../providers/category/campaigns/CampaignWaitingApprovalImportProvider'
import {checkExcelImportStatus} from '../../../../../requests/categories/campaigns/import/CampaignWaitingApprovalImportRequest'
import {KrcEmptyFilter} from '../../../../atoms/KrcEmptyFilter'

export const useCampaignWaitingApprovalImport = () => {
  const [importModalVisible, setImportModalVisible] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  const {setImportLink, setImportId} = useContext(ImportContext)
  const [excelImportStatus, setExcelImportStatus] = useState<ExcelImportStatusEnum | null>(null)

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Onay ID',
        accessor: 'onay_bekleyen_kampanya_urun_id',
        maxWidth: 150,
      },
      {
        Header: 'Kategori ID',
        accessor: 'kategori_id',
        maxWidth: 150,
      },
      {
        Header: 'Kategori Adı',
        accessor: 'kategori_adi',
        maxWidth: 150,
      },
      {
        Header: 'Model',
        accessor: 'urun_model_kodu',
        maxWidth: 150,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'urun_adi',
      },
      {
        Header: 'Onay Türü',
        accessor: 'onay_turu',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Aksiyon',
        accessor: 'aksiyon_text',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Not',
        accessor: 'not',
        Filter: KrcEmptyFilter,
      },
    ]
  }, [])

  /*
  Upload için kullanılıyor.
   */
  const handleOnChange = (info: UploadChangeParam) => {
    const {status} = info.file
    const key = `upload-file`
    if (status === 'uploading') {
      message.loading({content: 'Yükleniyor', key, duration: 0})
      setImportId(undefined)
      setImportLink(undefined)
    }

    if (status === 'done') {
      setImportId(info.file.response.data.id)
      setImportLink(info.file.response.data.import_link)
      setData(info.file.response.data.excel_rows)
      message.success(`${info.file.name} file uploaded successfully.`)
      setImportModalVisible(false)
      message.destroy(key)
    } else if (status === 'error') {
      let responseError: any[] = []
      let errorMessage: JSX.Element[] = [<></>]

      if (info.file?.response.errors?.excel_heading) {
        responseError = responseError.concat(info.file.response.errors.excel_heading)
      }

      if (info.file?.response.errors?.excel_data) {
        responseError = responseError.concat(info.file.response.errors.excel_data)
      }

      if ([403, 500].includes(info.file?.error?.status) && info.file?.response?.message) {
        responseError.push(info.file.response.message)
      }

      if (responseError) {
        errorMessage = responseError.map((errorMessage: string, index: number) => (
          <div
            key={`product-import-${index}`}
            className={'pb-2 pt-2'}
            style={{borderBottom: '1px solid #3333'}}
            dangerouslySetInnerHTML={{__html: errorMessage}}
          ></div>
        ))
      }

      notification.error({
        className: 'custom-notification',
        style: {width: 500},
        message: `${info.file.name} excel verilerinde hata var. Kontrol edip tekrar dene.`,
        description: errorMessage,
        duration: 0,
        placement: 'top',
      })
      setImportModalVisible(false)
      message.destroy(key)
    }
  }

  // Import durumunu kontrol eder
  const checkImportStatus = (excelImportId: number) => {
    checkExcelImportStatus(excelImportId).then((response) => {
      const excelImportData = response.data.data
      const status = excelImportData.status
      const statusText = excelImportData.status_text
      const errorMessages = excelImportData.error_message

      setExcelImportStatus(status)

      if (status === ExcelImportStatusEnum.STATUS_ERROR) {
        let errorMessage: JSX.Element | null = null

        Object.keys(errorMessages).forEach((key, index) => {
          let messages = errorMessages[key]

          if (typeof messages === 'string') {
            messages = [messages]
          }

          errorMessage = messages.map((errorMessage: string, index: number) => (
            <div
              key={`excel-import-${index}`}
              className={'pb-2 pt-2'}
              style={{borderBottom: '1px solid #3333'}}
              dangerouslySetInnerHTML={{__html: errorMessage}}
            ></div>
          ))
        })

        notification.error({
          className: 'custom-notification',
          style: {width: 500},
          message: `Hata Kodu: ${statusText}`,
          description: errorMessage ?? null,
          duration: 0,
          placement: 'top',
        })
      } else if (status === ExcelImportStatusEnum.STATUS_VALIDATION_ERROR) {
        let responseError = []
        let errorMessage = <></>

        if (errorMessages?.excel_heading) {
          responseError = errorMessages.excel_heading
        }

        if (errorMessages?.excel_data) {
          responseError = errorMessages.excel_data
        }

        if (responseError) {
          errorMessage = responseError.map((errorMessage: string, index: number) => (
            <div
              key={`excel-import-${index}`}
              className={'pb-2 pt-2'}
              style={{borderBottom: '1px solid #3333'}}
              dangerouslySetInnerHTML={{__html: errorMessage}}
            ></div>
          ))
        }

        notification.error({
          className: 'custom-notification',
          style: {width: 500},
          message: `Excel verilerinde hata var. Kontrol edip tekrar dene.`,
          description: errorMessage,
          duration: 0,
          placement: 'top',
        })
      } else if (status === ExcelImportStatusEnum.STATUS_AUTHORIZATION_ERROR) {
        let responseError = []
        let errorMessage = <></>

        if (errorMessages?.authorization) {
          responseError = errorMessages.authorization
        }

        if (responseError) {
          errorMessage = responseError.map((errorMessage: string, index: number) => (
            <div
              key={`excel-import-${index}`}
              className={'pb-2 pt-2'}
              style={{borderBottom: '1px solid #3333'}}
              dangerouslySetInnerHTML={{__html: errorMessage}}
            ></div>
          ))
        }

        notification.error({
          className: 'custom-notification',
          style: {width: 500},
          message: `Yetkisiz Kampanyalarda İşlem Yapılamaz.`,
          description: errorMessage,
          duration: 0,
          placement: 'top',
        })
      } else if (status === ExcelImportStatusEnum.STATUS_COMPLETED) {
        notification.success({
          className: 'custom-notification',
          style: {width: 500},
          message: `Excel Import başarıyla tamamlandı.`,
          duration: 0,
          placement: 'top',
        })
      } else {
        setTimeout(() => {
          checkImportStatus(excelImportId)
        }, 5000)
      }
    })
  }

  return {
    importModalVisible,
    setImportModalVisible,
    data,
    columns,
    excelImportStatus,
    handleOnChange,
    checkImportStatus,
  }
}
