import {KrcEditorOnChangeType} from '../../models/components/KrcEditorModel'

export const useEditor = () => {
  const handleOnChange = (value: any, callback: (data: KrcEditorOnChangeType) => any) => {
    if (!callback) {
      return
    }

    callback(value)
  }

  return {
    handleOnChange,
  }
}
