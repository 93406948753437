import {Spin} from 'antd'
import {SelectProps} from 'antd/lib/select'
import React, {FC, useEffect} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useProductAttributeFilter} from '../../hooks/products/useProductAttributeFilter'
import {IProductAttributeFilter} from '../../models/products/ProductAttributeFilterModel'

const productAttributeFilterConfig = {
  default: {
    mode: 'multiple',
    size: 'middle',
    placeholder: 'Lütfen Seçiniz',
    allowClear: false,
  },
}

const spinConfig = {
  default: {
    style: {position: 'absolute', right: 10, top: 17},
  },
}

const containerConfig = {
  default: {
    style: {position: 'relative'},
  },
}

export const ProductAttributeFilter: FC<IProductAttributeFilter & SelectProps> = ({
  spinProps,
  containerProps,
  attributeId,
  onChanged,
  initialLoad = false,
  ...props
}) => {
  const currentProductAttributeProps = useKrcProp({
    config: productAttributeFilterConfig.default,
    props: props,
  }).getCurrentProps()

  const currentSpinProps = useKrcProp({
    config: spinConfig.default,
    props: spinProps,
  }).getCurrentProps()

  const currentContainerProps = useKrcProp({
    config: containerConfig.default,
    props: containerProps,
  }).getCurrentProps()

  const {handleOnChange, handleOnSearch, options, spinning, selectedItems} =
    useProductAttributeFilter({
      attributeId,
      initialLoad,
    })

  useEffect(() => {
    if (!onChanged) {
      return
    }

    onChanged(selectedItems)
  }, [selectedItems])

  return (
    <>
      <div {...currentContainerProps}>
        <KrcSelect
          {...currentProductAttributeProps}
          options={options}
          showArrow
          labelInValue
          allowClear={false}
          filterOption={false}
          onChange={handleOnChange}
          onSearch={handleOnSearch}
        />
        <Spin spinning={spinning} {...currentSpinProps} />
      </div>
    </>
  )
}
