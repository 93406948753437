import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {ManufacturerContext, ManufacturerProvider} from '../../../providers/ManufacturerProvider'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {ManufacturerCreateModal} from '../../modals/manufacturer/ManufacturerCreateModal'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {ManufacturerList} from './ManufacturerList'

const ManufacturerListWrapperWithoutProvider: FC = () => {
  const {visibleCreateModal, setVisibleCreateModal} = useContext(ManufacturerContext)
  return (
    <>
      <PageTitle>Üretici Markalar</PageTitle>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <KrcButton
            size={'middle'}
            onClick={() => {
              setVisibleCreateModal(true)
            }}
            className={'mr-3'}
            id='kt_toolbar_primary_button'
          >
            Yeni Üretici Marka Ekle
          </KrcButton>
        </div>
      </Toolbar>
      <ManufacturerList />
      {visibleCreateModal && (
        <ManufacturerCreateModal
          onHide={() => {
            setVisibleCreateModal(false)
          }}
        />
      )}
    </>
  )
}

export const ManufacturerListWrapper: FC = () => {
  return (
    <ManufacturerProvider>
      <ManufacturerListWrapperWithoutProvider />
    </ManufacturerProvider>
  )
}
