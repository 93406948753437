import {message} from 'antd'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'

import {getCouponHistoryRequest} from '../../../requests/campaign_and_coupon/CouponRequest'
import {paginationMetaType} from '../../models/GeneralModel'

type CouponUrlParams = {
  couponId: string
}
export const useCouponHistoryList = () => {
  const [data, setData] = useState([])
  const [meta, setMeta] = React.useState<paginationMetaType>({limit: 10, page: 1})
  const initialRef = useRef(0)

  const {couponId} = useParams<CouponUrlParams>()

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getCouponHistory()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    getCouponHistory()
  }, [meta.page])

  useEffect(() => {
    initialRef.current = 1
    getCouponHistory()
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Kupon ID',
        accessor: 'coupon_id',
      },
      {
        Header: 'Sipariş ID',
        accessor: 'order_id',
      },
      {
        Header: 'Müşteri Adı',
        accessor: 'customer.customer_name',
      },
      {
        Header: 'Müşteri Email',
        accessor: 'customer.customer_email',
      },
      {
        Header: 'Miktar',
        accessor: 'amount',
      },
      {
        Header: 'Kullanılma Tarihi',
        accessor: 'date_added',
        Cell: ({row}: any) => {
          return new Date(row.original.date_added).toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        },
      },
    ]
  }, [])

  const getCouponHistory = () => [
    getCouponHistoryRequest(Number(couponId), meta)
      .then((response) => {
        console.log(response.data.data)
        setData(response.data.data)
      })
      .catch((error) => {
        message.error('Kupon geçmişi getirilirken bir hata oluştu.')
        message.error(error.response.data.message)
      }),
  ]

  return {
    data,
    getCouponHistory,
    columns,
    meta,
    setMeta,
  }
}
