import {message} from 'antd'
import moment from 'moment'
import {FC, createContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {bannerImageManagerType} from '../../components/models/banners/BannerImageModel'
import {bannerImageDataType} from '../../components/models/banners/BannerModel'
import {krcSelectOptions} from '../../components/models/components/KrcSelectModel'
import {errorMessage} from '../../helpers/ErrorHelper'
import {
  getBannerImageRequest,
  getBannerImageTypesRequest,
  updateBannerImageRequest,
} from '../../requests/banner/BannerImageRequest'
import {requestDateFormat} from '../../service/DateService'

interface IImageManagerContext {
  bannerImage: bannerImageManagerType
  updateBannerImage: (value: any) => void
  handleOnSubmit: (data: bannerImageManagerType) => void
  bannerImageTypes: krcSelectOptions[]
}

export const BannerImageManagerContext = createContext<IImageManagerContext>({
  bannerImage: {
    title: '',
    content: '',
    web_link: '',
    mobile_link_type: null,
    mobile_link_id: 0,
    deeplink: '',
    component_banner_group: null,
    start_time: null,
    end_time: null,
    web_image: null,
    full_web_image: null,
    mobile_image: null,
    full_mobile_image: null,
    column: 0,
  },
  updateBannerImage: () => {},
  handleOnSubmit: () => {},
  bannerImageTypes: [],
})
export const BannerImageManagerProvider: FC = ({children}) => {
  const [bannerImage, setBannerImage] = useState<bannerImageManagerType>({
    title: '',
    content: '',
    web_link: '',
    mobile_link_type: null,
    mobile_link_id: 0,
    deeplink: '',
    component_banner_group: null,
    start_time: null,
    end_time: null,
    web_image: null,
    full_web_image: null,
    mobile_image: null,
    full_mobile_image: null,
    column: 0,
  })
  const [bannerImageTypes, setBannerImageTypes] = useState<krcSelectOptions[]>([])
  const {imageId} = useParams()

  const getBannerImage = () => {
    if (!imageId) {
      return
    }

    getBannerImageRequest(Number(imageId)).then((res) => {
      const data = res.data.data as bannerImageDataType

      updateBannerImageData({
        title: data.descriptions[0].title ?? '',
        content: data.descriptions[0].content ?? '',
        web_link: data.link ?? '',
        mobile_link_type: data.mobile_link_type
          ? {value: `${data.mobile_link_type}-999`, label: data.mobile_link_type}
          : null,
        mobile_link_id: data.mobile_link_id ?? 0,
        deeplink: data.deeplink ?? '',
        start_time: data.start_time ? moment(data.start_time) : null,
        end_time: data.end_time ? moment(data.end_time) : null,
        web_image: data.image ?? null,
        full_web_image: data.full_image ?? null,
        mobile_image: data.mobile_image ?? null,
        full_mobile_image: data.full_mobile_image ?? null,
        column: data.column ?? 0,
      })
    })
  }

  const updateBannerImageData = (value: any) => {
    setBannerImage((prev) => ({...prev, ...value}))
  }

  const handleOnSubmit = (data: bannerImageManagerType) => {
    const descriptionData = {
      title: data.title,
      content: data.content,
    }

    const imageData = {
      link: data.web_link,
      mobile_link_type: data.mobile_link_type?.label ?? data.mobile_link_type,
      mobile_link_id: data.mobile_link_id !== 0 ? data.mobile_link_id : null,
      deeplink: data.deeplink,
      image: data.web_image,
      mobile_image: data.mobile_image,
      column: data.column,
      start_time: requestDateFormat(data.start_time),
      end_time: requestDateFormat(data.end_time),
    }

    const key = 'update-badge'
    message.loading({content: 'Banner Güncelleniyor', key, duration: 1})

    updateBannerImageRequest(Number(imageId), {description: descriptionData, image: imageData})
      .then(() => {
        message.success({content: 'Banner güncellendi', key, duration: 1})
      })
      .catch((e) => {
        errorMessage(e)
        message.error({
          content: 'Banner güncellenemedi.',
          key,
        })
      })
  }

  const getBannerImageTypes = () => {
    getBannerImageTypesRequest().then((res) => {
      const imageTypes = res.data.data.map((v: {name: string}, i: number) => ({
        value: `${v.name}-${i}`,
        label: v.name,
      }))

      setBannerImageTypes(imageTypes)
    })
  }

  useEffect(() => {
    getBannerImage()
    getBannerImageTypes()
  }, [])

  return (
    <BannerImageManagerContext.Provider
      value={{
        bannerImage,
        bannerImageTypes,
        updateBannerImage: updateBannerImageData,
        handleOnSubmit,
      }}
    >
      {children}
    </BannerImageManagerContext.Provider>
  )
}
