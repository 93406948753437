import cn from 'classnames'
import {Form, Formik, useFormik} from 'formik'
import React, {FC, useContext, useEffect} from 'react'
import * as Yup from 'yup'

import {SortingWeightContext} from '../../../providers/sorting/SortingWeightProvider'
import {InfoTooltip} from '../../atoms/InfoTooltip'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {Loader} from '../../molecules/Loader'

const schema = Yup.object({
  order_type: Yup.string(),
  weight_type: Yup.string(),
  weight_value: Yup.number().required('Ağırlık değeri zorunludur'),
})

const initialValues = {
  order_type: '',
  weight_type: '',
  weight_value: 0,
}

export const SortingWeightUpdateForm: FC = () => {
  const {orderTypes, weightTypes, updateSortingWeight, sortingWeightList} =
    useContext(SortingWeightContext)
  const [selectedOrderType, setSelectedOrderType] = React.useState<string>(orderTypes[0].value)
  const [selectedWeightType, setSelectedWeightType] = React.useState<string>(weightTypes[0].value)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [weightValue, setWeightValue] = React.useState<number>(0)

  useEffect(() => {
    if (orderTypes.length > 1 && weightTypes.length > 1) {
      sortingWeightList.forEach((item) => {
        if (item.SortingType === selectedOrderType) {
          setWeightValue(item[selectedWeightType])
        }
      })
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [orderTypes, weightTypes, sortingWeightList, selectedOrderType, selectedWeightType])

  const formik = useFormik({
    initialValues: {
      weight_value: weightValue,
    },
    onSubmit: (values) => {
      setLoading(true)
      updateSortingWeight(selectedOrderType, selectedWeightType, weightValue)
      setLoading(false)
    },
    validationSchema: schema,
  })

  // Bu useEffect Formik'in içinde
  useEffect(() => {
    // Her iki değer değiştikçe Formik formundaki değeri güncelle
    formik.setFieldValue('weight_value', weightValue)
  }, [selectedOrderType, selectedWeightType, weightValue])

  return (
    <>
      {loading && sortingWeightList.length === 0 ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{
            ...initialValues,
            weight_value: weightValue,
          }}
          onSubmit={(values) => {
            updateSortingWeight(selectedOrderType, selectedWeightType, weightValue)
          }}
          validationSchema={schema}
        >
          {(formik) => {
            return (
              <Form>
                <div className={'row'}>
                  <div className={'col-4'}>
                    <KrcSelect
                      required={true}
                      placeholder={`Örn: Deneme`}
                      size={'large'}
                      defaultValue={orderTypes[0].value}
                      options={orderTypes.map((item) => {
                        return {
                          value: item.value,
                          label: item.label,
                        }
                      })}
                      maxTagCount={'responsive'}
                      onChange={(value) => {
                        setSelectedOrderType(value)
                      }}
                      style={{width: '100%'}}
                      className={'ant-selector-border mb-2'}
                      labelTitle={'Sıralama Tipi'}
                      tooltipProps={{
                        status: true,
                        text: 'Sıralama tipi seçiniz.',
                      }}
                    />
                  </div>
                  <div className={'col-4'}>
                    <KrcSelect
                      required={true}
                      placeholder={`Örn: Weight`}
                      size={'large'}
                      defaultValue={weightTypes[0].value}
                      options={weightTypes.map((item) => {
                        return {
                          value: item.value,
                          label: item.label,
                        }
                      })}
                      maxTagCount={'responsive'}
                      onChange={(value) => {
                        setSelectedWeightType(value)
                      }}
                      style={{width: '100%'}}
                      className={'ant-selector-border mb-2'}
                      labelTitle={'Ağırlık Tipi'}
                      tooltipProps={{
                        status: true,
                        text: 'Ağırlık tipi seçiniz.',
                      }}
                    />
                  </div>
                  <div className={'col-4'}>
                    <label className='form-label'>
                      <div className={'d-flex align-items-center'}>
                        <span className={cn({required: true}, 'mr-1')}>Ağırlık</span>
                        <InfoTooltip status={true} text={'Ağırlık değerini giriniz.'}></InfoTooltip>
                      </div>
                    </label>
                    <input
                      className={'form-control w-100'}
                      value={weightValue}
                      onChange={(e) => setWeightValue(Number(e.target.value))}
                    />
                  </div>
                </div>
                <div className={'d-flex justify-content-end mt-7'}>
                  <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                    Güncelle
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}
