import React from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {ScoreBoardProvider} from '../../../../providers/games/ScoreBoardProvider'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {GameScoreBoardList} from './GameScoreBoardList'

export const ScoreBoardWrapperWithoutProvider = () => {
  return (
    <>
      <Toolbar />
      <PageTitle>Karaca Oyun Lider Tablosu</PageTitle>
      <GameScoreBoardList />
    </>
  )
}

export const ScoreBoardWrapper = () => {
  return (
    <>
      <ScoreBoardProvider>
        <ScoreBoardWrapperWithoutProvider />
      </ScoreBoardProvider>
    </>
  )
}
