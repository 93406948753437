import debounce from 'lodash/debounce'
import {Moment} from 'moment/moment'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'

import {CategoryBadgeLogType} from '../../../../enums/CategoryBadgeLogType'
import {CategoryBadgeContext} from '../../../../providers/category/badges/CategoryBadgeProvider'
import {initialMetaData} from '../../../../providers/tables/CategorySelectionProvider'
import {getCategoryBadgeLogsRequest} from '../../../../requests/categories/badges/CategoryBadge'
import {viewDateFormat} from '../../../../service/DateService'
import {KrcAlert} from '../../../atoms/KrcAlert'
import {KrcRangePicker} from '../../../atoms/forms/KrcRangePicker'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {paginationMetaType} from '../../../models/GeneralModel'
import {
  categoryBadgeLogFilterType,
  categoryBadgeLogType,
} from '../../../models/categories/CategoryBadgeModel'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const CategoryBadgeActivity: FC = () => {
  const [data, setData] = useState<any[]>([])
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [filter, setFilter] = useState<categoryBadgeLogFilterType>({})
  const fetchRequestRef = useRef(0)
  const initialRef = useRef(0)
  const {setActivityFilter} = useContext(CategoryBadgeContext)

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Ürün Adı',
        accessor: 'category_name',
        maxWidth: 340,
        manualWidth: 340,
      },
      {
        Header: 'Badge Adı',
        accessor: 'badge_name',
        maxWidth: 150,
        manualWidth: 150,
      },
      {
        Header: 'İşlemi Gerçekleşiren \n Kullanıcı Adı',
        accessor: 'user_name',
        maxWidth: 150,
        manualWidth: 150,
      },
      {
        Header: 'Durum',
        accessor: 'type',
        maxWidth: 130,
        manualWidth: 130,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={undefined}
                placeholder={'Seçiniz'}
                options={[
                  {value: 'success', label: 'Başarılı'},
                  {value: 'error', label: 'Başarısız'},
                ]}
                style={{width: 120}}
                className={'ant-selector-border'}
                onChange={(data) => {
                  handleOnFilter(data, 'type')
                }}
              />
            </>
          )
        },
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                {row.original.type === CategoryBadgeLogType.SUCCESS ? (
                  <KrcAlert message='Başarılı' type='success' className={'py-1'} />
                ) : (
                  <></>
                )}

                {row.original.type === CategoryBadgeLogType.ERROR ? (
                  <KrcAlert message='Başarısız' type='error' className={'py-1'} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Açıklama',
        accessor: 'message',
        Cell: ({row}: any) => <div dangerouslySetInnerHTML={{__html: row.original.message}}></div>,
        maxWidth: 250,
        Filter: <></>,
        // manualWidth: 250,
      },
      {
        Header: 'Gerçekleşme Tarihi',
        accessor: 'created_at',
        maxWidth: 150,
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                onSelected={handleOnSelectedRangePicker}
                size={'middle'}
                showTime={true}
                format={'DD.MM.YYYY HH:mm'}
              />
            </>
          )
        },
      },
    ]
  }, [])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    setActivityFilter(filter)

    getCategoryBadgeLogs()
  }, [filter])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getCategoryBadgeLogs()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getCategoryBadgeLogs()
  }, [meta.page])

  useEffect(() => {
    initialRef.current = 1

    getCategoryBadgeLogs()
  }, [])

  const getCategoryBadgeLogs = () => {
    fetchRequestRef.current += 1
    const fetchId = fetchRequestRef.current

    getCategoryBadgeLogsRequest(filter, meta).then((res) => {
      if (fetchRequestRef.current !== fetchId) {
        return
      }

      const _data = res.data.data.map((item: categoryBadgeLogType) => {
        return {
          category_name: item.category.minimal_description?.name,
          badge_name: item.badge.name,
          user_name: item.user?.fullname,
          type: item.type,
          message: item.message,
          created_at: viewDateFormat(item.created_at),
        }
      })

      setData(_data)
      const resMeta = res.data.meta
      setMeta({limit: resMeta.per_page, page: resMeta.current_page, ...resMeta})
    })
  }

  const handleOnSelectedRangePicker = (date: Moment[] | null) => {
    if (!date) {
      setFilter((data) => {
        return {...data, created_at_range: undefined}
      })
      return
    }

    setFilter((data) => {
      return {
        ...data,
        created_at_range: [date[0].toISOString(), date[1].toISOString()],
      }
    })
  }

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data?: categoryBadgeLogFilterType) => {
        if (!data) {
          return {[rowId]: value}
        }

        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  return (
    <>
      <KrcTable
        columns={columns}
        data={data}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            setMeta((data) => ({...data, page}))
          },
          onChangeLimit: (limit) => {
            setMeta((data) => ({...data, limit}))
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: handleOnFilter,
        }}
      />
    </>
  )
}
