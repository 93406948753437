import {message} from 'antd'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {AVAILABLE_INFORMATION_STORES} from '../../../enums/InformationStoresTypeEnum'
import {
  fetchInformationSubTopicDetailRequest,
  updateInformationSubTopicDetailRequest,
} from '../../../requests/information/InformationRequest'

type InformationUrlParams = {
  topicId: string
  informationId: string
}

export const useInformationSubTopicUpdateForm = () => {
  const {topicId, informationId} = useParams<InformationUrlParams>()
  const [isLoading, setIsLoading] = useState(false)
  const [informationSubTopic, setInformationSubTopic] = useState<any>({})

  const search = window.location.search
  const params = new URLSearchParams(search)
  const selectedStoreId = params.get('store') || ''

  useEffect(() => {
    getSpecificInformationSubTopic()
  }, [])

  const getSpecificInformationSubTopic = () => {
    setIsLoading(true)
    fetchInformationSubTopicDetailRequest(
      Number(topicId),
      Number(informationId),
      Number(selectedStoreId)
    )
      .then((response) => {
        const responseStores = response.data.data.stores
        const availableStores = responseStores.filter((store: number) =>
          AVAILABLE_INFORMATION_STORES.includes(store)
        )
        setInformationSubTopic({
          ...response.data.data,
          topic_id: response.data.data.topic_id.toString(),
          bottom: response.data.data.bottom ? 1 : 0,
          status: response.data.data.status ? 1 : 0,
          title: response.data.data.description.title,
          description: response.data.data.description.description,
          meta_title: response.data.data.description.meta_title,
          meta_description: response.data.data.description.meta_description,
          stores: availableStores,
        })
      })
      .catch((error) => {
        message.error('Bilgilendirme Başlığı getirilirken bir hata oluştu.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const updateSpecificInformationSubTopic = (data: any) => {
    message.info('Bilgi Sayfası Güncelleniyor...')
    updateInformationSubTopicDetailRequest(
      Number(topicId),
      Number(informationId),
      Number(selectedStoreId),
      data
    )
      .then(() => {
        message.success('Bilgi Sayfası Güncellendi.')
      })
      .catch((error) => {
        message.error('Bilgi Sayfası Güncellenirken bir hata oluştu.')
      })
  }

  return {
    isLoading,
    informationSubTopic,
    updateSpecificInformationSubTopic,
  }
}
