import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {useLanding} from '../../../../hooks/landings/useLanding'
import {ExcelExport} from '../../../../layout/excel/ExcelExportWrapper'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'

export const LandingListExportWrapper: FC = () => {
  const {exportLandingList, downloadExcelUrl} = useLanding()

  return (
    <>
      <Toolbar />
      <PageTitle>Landing Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport handleExportData={exportLandingList} downloadExcelUrl={downloadExcelUrl} />
          }
        />
      </Routes>
    </>
  )
}
