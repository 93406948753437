import {IBadge} from '../../components/models/badges/BadgeModel'
import {
  ADD_BADGE,
  CHANGE_VISIBLE_BADGE_MODAL,
  DELETE_BADGE,
  SET_BADGE,
  SET_BADGES,
  SET_SELECTED_BADGES,
  UPDATE_BADGE,
} from './BadgeActionType'

export const setBadges = (badges: IBadge[]) => {
  return {
    type: SET_BADGES,
    payload: badges,
  }
}

export const setBadge = (badge: IBadge | null) => {
  return {
    type: SET_BADGE,
    payload: badge,
  }
}

export const addBadge = (badge: IBadge | null) => {
  return {
    type: ADD_BADGE,
    payload: badge,
  }
}

export const updateBadge = (badgeId: number, badge: IBadge | null) => {
  return {
    type: UPDATE_BADGE,
    payload: {badge, badgeId},
  }
}

export const deleteBadge = (badgeId: number) => {
  return {
    type: DELETE_BADGE,
    payload: badgeId,
  }
}

export const changeVisibleBadgeModal = (visible: boolean) => {
  return {
    type: CHANGE_VISIBLE_BADGE_MODAL,
    payload: visible,
  }
}

export const selectBadges = (badges: IBadge[]) => {
  return {
    type: SET_SELECTED_BADGES,
    payload: badges,
  }
}
