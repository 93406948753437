import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {FaqCreateFormWrapper} from '../components/pages/faq/FaqCreateFormWrapper'
import {FaqDetailListWrapper} from '../components/pages/faq/FaqDetailListWrapper'
import {FaqListWrapper} from '../components/pages/faq/FaqListWrapper'
import {FaqUpdateFormWrapper} from '../components/pages/faq/FaqUpdateFormWrapper'

export const FaqRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<FaqListWrapper />} />
        <Route path={'/create'} element={<FaqCreateFormWrapper />} />
        <Route path={'/update/:faqId'} element={<FaqUpdateFormWrapper />} />
        <Route path={'/:faqId'} element={<FaqDetailListWrapper />} />
      </Routes>
    </>
  )
}
