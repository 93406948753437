import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {Badge, Popconfirm, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {createContext, useEffect, useMemo, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Cell, Column} from 'react-table'

import {KrcEmptyFilter} from '../components/atoms/KrcEmptyFilter'
import {KrcSelect} from '../components/atoms/forms/KrcSelect'
import {paginationMetaType} from '../components/models/GeneralModel'
import {IManufacturerListType} from '../components/models/manufacturers/ManufacturerFilterModel'
import {removeEmptyItemInObject} from '../helpers/ObjectHelper'
import {
  deleteManufacturerRequest,
  getManufacturersRequest,
  storeManufacturerRequest,
  updateManufacturerRequest,
} from '../requests/manufacturers/ManufacturerRequest'
import {initialMetaData} from './tables/ProductSelectionProvider'

interface IManufacturerContext {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  columns: Column<any>[]
  manufacturers: IManufacturerListType[]
  filter: any
  setFilter: React.Dispatch<React.SetStateAction<any>>
  handleOnFilter: (data: any, key: string | number) => void
  visibleCreateModal: boolean
  setVisibleCreateModal: React.Dispatch<React.SetStateAction<boolean>>
  storeManufacturer: (data: any) => void
  updateManufacturer: (manufacturerId: number, data: any) => void
}

export const ManufacturerContext = createContext<IManufacturerContext>({
  isLoading: false,
  setIsLoading: () => {},
  meta: initialMetaData,
  columns: [],
  manufacturers: [],
  filter: {},
  setFilter: () => {},
  setMeta: () => {},
  handleOnFilter: () => {},
  visibleCreateModal: false,
  setVisibleCreateModal: () => {},
  storeManufacturer: () => {},
  updateManufacturer: () => {},
})

export const ManufacturerProvider = ({children}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [manufacturers, setManufacturers] = useState<IManufacturerListType[]>([])
  const [visibleCreateModal, setVisibleCreateModal] = useState<boolean>(false)
  const [filter, setFilter] = useState<any>({})
  const fetchRef = useRef(0)
  const navigate = useNavigate()
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'manufacturer_id',
        manualWidth: 150,
        maxWidth: 150,
      },
      {
        Header: 'İsim',
        accessor: 'name',
        manualWidth: 600,
        maxWidth: 600,
      },
      {
        Header: 'Yayın Durumu',
        accessor: 'status',
        manualWidth: 200,
        maxWidth: 200,
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.status === '1' && (
                <div>
                  Aktif <Badge className={'ml-2'} color='green' />
                </div>
              )}
              {row.original.status === '0' && (
                <div>
                  Pasif <Badge className={'ml-2'} color='red' />
                </div>
              )}
            </>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Yayın Durumu'}
              options={[
                {value: 1, label: 'Aktif'},
                {value: 2, label: 'Pasif'},
              ]}
              maxTagCount={'responsive'}
              style={{width: '100%'}}
              className={'ant-selector-border'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }

                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'status')
              }}
            />
          )
        },
      },
      {
        Header: 'Sıralama',
        accessor: 'sort_order',
        manualWidth: 200,
        maxWidth: 200,
      },
      {
        Header: 'Aksiyon',
        Filter: <KrcEmptyFilter />,
        manualWidth: 140,
        maxWidth: 140,
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row startaround'>
              <Tooltip title={'Güncelle'}>
                <Link
                  to={`/manufacturer/${row.original.manufacturer_id}/update`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 badge-update'
                  style={{color: 'unset'}}
                >
                  <EditOutlined />
                </Link>
              </Tooltip>
              <Tooltip title={'Sil'}>
                <Popconfirm
                  title='Bu markayı silmek istediğinden emin misin ?'
                  onConfirm={(e) => destroyManufacturer(row.values.manufacturer_id)}
                  okText='Evet'
                  cancelText='Hayır'
                >
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <DeleteOutlined />
                  </div>
                </Popconfirm>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    []
  )
  const initialRef = useRef(0)

  useEffect(() => {
    if (!setMeta) {
      return
    }

    setMeta(meta)
  }, [meta.to, meta.from, meta.last_page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (!setMeta) {
      return
    }

    setMeta(meta)

    getManufacturers()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getManufacturers()
  }, [meta.limit])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  useEffect(() => {
    if (!meta) {
      return
    }

    setMeta(meta)
  }, [meta])

  useEffect(() => {
    if (!manufacturers) {
      return
    }

    setManufacturers(manufacturers)
  }, [manufacturers])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getManufacturers()
  }, [filter])

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  const getManufacturers = (page: null | number = null) => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    let _meta = meta
    const _filter = removeEmptyItemInObject(filter)
    if (page) {
      _meta.page = page
    }
    setIsLoading(true)
    getManufacturersRequest(_filter, _meta)
      .then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }
        const metaData = res.data.meta
        setManufacturers(res.data.data)
        setMeta({limit: metaData.per_page, page: metaData.current_page, ...metaData})
      })
      .catch((err) => {
        message.error('Markaları getirirken bir hata oluştu.')
        message.error('Hata: ' + err.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const storeManufacturer = (data: any) => {
    setIsLoading(true)
    message.info('Marka ekleniyor...')
    storeManufacturerRequest(data)
      .then((res) => {
        message.success('Marka başarıyla eklendi.')
        getManufacturers()
      })
      .catch((err) => {
        message.error('Marka eklenirken bir hata oluştu.')
        message.error('Hata: ' + err.response.data.message)
      })
      .finally(() => {
        setVisibleCreateModal(false)
        setIsLoading(false)
      })
  }

  const updateManufacturer = (manufacturerId: number, data: any) => {
    setIsLoading(true)
    message.info('Marka güncelleniyor...')
    updateManufacturerRequest(manufacturerId, data)
      .then((res) => {
        message.success('Marka başarıyla güncellendi.')
        getManufacturers()
        setIsLoading(false)
        navigate(-1)
      })
      .catch((err) => {
        message.error('Marka güncellenirken bir hata oluştu.')
        message.error('Hata: ' + err.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const destroyManufacturer = (manufacturerId: number) => {
    setIsLoading(true)
    message.info('Marka siliniyor...')
    deleteManufacturerRequest(manufacturerId)
      .then((res) => {
        message.success('Marka başarıyla silindi.')
        getManufacturers()
      })
      .catch((err) => {
        message.error('Marka silinirken bir hata oluştu.')
        message.error('Hata: ' + err.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <ManufacturerContext.Provider
      value={{
        isLoading,
        setIsLoading,
        meta,
        columns,
        manufacturers,
        filter,
        setFilter,
        setMeta,
        handleOnFilter,
        visibleCreateModal,
        setVisibleCreateModal,
        storeManufacturer,
        updateManufacturer,
      }}
    >
      {children}
    </ManufacturerContext.Provider>
  )
}
