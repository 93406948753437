import {message} from 'antd'
import {AxiosStatic} from 'axios'

import {errorMessage} from '../../app/helpers/ErrorHelper'

export default function setupAxios(axios: AxiosStatic, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    (error: any) => {
      if (error.response) {
        let response = error.response
        let statusCode = response.status

        if (401 === statusCode && window.location.pathname !== '/auth/login') {
          message.error('Oturumunuz sonlanmıştır. Sayfayı yenileyip tekrar deneyiniz.')
        }

        if (500 === statusCode) {
          message.error(response.data.message)
        }

        if (403 === statusCode) {
          message.error('Yetkiniz bulunmamaktadır.')
        }

        errorMessage(error)
      }

      return Promise.reject(error)
    }
  )
}
