export const ComponentAttributeEnum = {
  API_BASE_URL: 'api_base_url',
  API_URL: 'api_url',
  API_URL_BACKUP: 'api_url_backup',
  BANNER_ID: 'banner_id',
  BORDER: 'border',
  CHILD_COMPONENT: 'child_component',
  COLOR_PATH: 'color_path',
  COMPONENT_NAME: 'component_name',
  DEEPLINK_URL: 'deeplink_url',
  ELEMENT_PATH: 'element_path',
  GENERAL_PATH: 'general_path',
  HEADER_PATH: 'header_path',
  INFORMATION_ROUTE: 'information_route',
  INTEGRATION_NAME: 'integration_name',
  LOAD_DESCRIPTION: 'load_description',
  LOAD_RELATIONS: 'load_relations',
  MAIN_PATH: 'main_path',
  PAGE: 'page',
  PAGE_COMPONENT: 'page_component',
  PAGINATION_PATH: 'pagination_path',
  ROUTE: 'route',
  SHOW_ALL: 'show_all',
  SHOW_ALL_ROW: 'show_all_row',
  SHOW_MOBILE_ICON: 'show_mobile_icon',
  SIZE: 'size',
  STORE_ID: 'store_id',
  TITLE: 'title',
  TYPE: 'type',
  URL: 'url',
  WIDGET_ID: 'widget_id',
}
