import {FC, useEffect, useRef} from 'react'

import {KrcInput} from '../../atoms/forms/KrcInput'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useInputConstraintFilter} from '../../hooks/components/useInputConstraintFilter'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {IInputConstraintFilter} from '../../models/components/InputConstraintModel'

const selectConfig = {
  default: {
    className: 'product-special-select',
  },
}

const krcInputConfig = {
  default: {
    type: 'text',
    size: 'middle',
    style: {width: 200, borderRadius: 5},
  },
}

export const InputConstraintFilter: FC<IInputConstraintFilter> = ({
  onChanged,
  containerClassName = 'd-flex gap-3',
  placeholderList,
  selectProps,
  krcInputProps,
  defaultPlaceholder,
  clear,
}) => {
  const currentSelectProps = useKrcProp({
    config: selectConfig.default,
    props: selectProps,
  }).getCurrentProps()
  const currentKrcInputProps = useKrcProp({
    config: krcInputConfig.default,
    props: krcInputProps,
  }).getCurrentProps()
  const {handleSelectOnChange, handleInputOnChange, data, setData, placeholder} =
    useInputConstraintFilter({
      placeholderList,
      defaultPlaceholder,
    })
  const initialRef = useRef(0)

  useEffect(() => {
    if (!onChanged) {
      return
    }

    onChanged(data)
  }, [data])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    setData({key: 'model', value: null})
  }, [clear])

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current++
      return
    }
  }, [])

  return (
    <>
      <div className={containerClassName} style={{width: 250}}>
        <KrcSelect
          onChange={handleSelectOnChange}
          allowClear={false}
          {...currentSelectProps}
          style={{width: 110}}
        />
        <KrcInput
          value={data.value}
          placeholder={placeholder}
          allowClear
          onChange={handleInputOnChange}
          {...currentKrcInputProps}
          style={{width: 130}}
        />
      </div>
    </>
  )
}
