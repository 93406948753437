import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const MANUFACTURER_SEARCH_URL = `${API_URL}/manufacturers/search`
const BASE_MANUFACTURER_URL = `${API_URL}/manufacturers`

export function getManufacturersRequest(filter: any, {page, limit}: paginationMetaType) {
  return axios.get(BASE_MANUFACTURER_URL, {
    params: {
      page,
      limit,
      ...filter,
    },
  })
}

export function storeManufacturerRequest(data: any) {
  return axios.post(BASE_MANUFACTURER_URL, data)
}

export function updateManufacturerRequest(id: number, data: any) {
  return axios.put(`${BASE_MANUFACTURER_URL}/${id}`, data)
}

export function deleteManufacturerRequest(id: number) {
  return axios.delete(`${BASE_MANUFACTURER_URL}/${id}`)
}

export function getManufacturerStoreDetailsRequest(manufacturerId: number, storeId: number) {
  return axios.get(`${BASE_MANUFACTURER_URL}/${manufacturerId}/store/${storeId}`)
}

export function updateManufacturerStoreDetailsRequest(
  manufacturerId: number,
  storeId: number,
  data: any
) {
  return axios.put(`${BASE_MANUFACTURER_URL}/${manufacturerId}/store/${storeId}`, data)
}

export function manufacturerSearchRequest(search: string) {
  return axios.get(`${MANUFACTURER_SEARCH_URL}?name=${search}`)
}

export function getManufacturerByIdRequest(id: number) {
  return axios.get(`${BASE_MANUFACTURER_URL}/${id}`)
}
