import {Modal} from 'antd'
import _ from 'lodash'
import {useState} from 'react'

const {confirm} = Modal

export const useTransferTable = ({data, currentTransferProps}: any) => {
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const [currentData, setCurrentData] = useState<any[]>(data)
  const [transferredItemIds, setTransferredItemIds] = useState<number[]>([])
  const [unSelectAllItems, setUnSelectAllItems] = useState<number>(0)
  const [tempExcludeIds, setTempExcludeIds] = useState<number[]>([])

  const handleTransferData = () => {
    if (!currentTransferProps?.onTransfer) {
      return
    }

    currentTransferProps.onTransfer(selectedItems)
    const selectedIds = getSelectedItemIds()

    setTransferredItemIds((data) => {
      return Array.from(new Set([...data, ...selectedIds]))
    })

    setSelectedItems([])
    removeItem()
  }

  const onHandleTransfer = () => {
    if (!currentTransferProps?.config?.confirmActive) {
      handleTransferData()
      return
    }

    confirm({
      ...currentTransferProps?.config?.confirm,
      onOk() {
        handleTransferData()
      },
    })
  }

  const removeItem = () => {
    const selectedIds = getSelectedItemIds()

    setCurrentData((currentData) => {
      return currentData.filter((currentItem) => {
        return !selectedIds.some((selectedId) => selectedId === currentItem.id)
      })
    })

    setTempExcludeIds((excludeIds) => _.uniq([...excludeIds, ...selectedIds]))

    setUnSelectAllItems((item) => item + 1)
  }

  const getSelectedItemIds = () => {
    return selectedItems.map((item) => {
      return item.id
    })
  }

  return {
    currentData,
    selectedItems,
    unSelectAllItems,
    transferredItemIds,
    tempExcludeIds,
    removeItem,
    setCurrentData,
    onHandleTransfer,
    setSelectedItems,
    setTransferredItemIds,
    setTempExcludeIds,
    setUnSelectAllItems,
  }
}
