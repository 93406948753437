import {message} from 'antd'
import {Form, Formik} from 'formik'
import React, {FC, useContext} from 'react'

import {STORES} from '../../../../../../../enums/CategoryDefaultSortStoresEnum'
import {CategoryProductSortingContext} from '../../../../../../../providers/category/sorting/CategoryProductSortingProvider'
import {KrcSelect} from '../../../../../../atoms/forms/KrcSelect'
import {KrcTable} from '../../../../../../organisms/tables/KrcTable'

const _initialValues = {
  store_id: '',
  default_sorting_type: '',
}

export const CategoryProductDefaultSuggestedSorting: FC<any> = () => {
  const {
    sortingTypes,
    categoryDefaultSortingTypes,
    changeCategoryDefaultSortingTypeByStore,
    categorySuggestedSortingDefaultSortingTypesColumns,
  } = useContext(CategoryProductSortingContext)

  const [selectedSortingType, setSelectedSortingType] = React.useState<string>(
    sortingTypes[0].value
  )

  const [selectedStoreId, setSelectedStoreId] = React.useState<null | string>(null)

  const handleOnSubmit = (values: any) => {
    if (!selectedSortingType) {
      message.error('Sıralama tipi seçiniz.')

      return
    }

    if (!selectedStoreId && selectedStoreId !== '0') {
      message.error('Store seçiniz.')

      return
    }

    const updateDefaultSortingTypeRequestData = {
      store_id: Number(selectedStoreId),
      default_sorting_type: selectedSortingType,
    }

    changeCategoryDefaultSortingTypeByStore(updateDefaultSortingTypeRequestData)
  }

  return (
    <>
      <div className={'flex col-12'}>
        <Formik enableReinitialize initialValues={_initialValues} onSubmit={handleOnSubmit}>
          {(formik) => {
            return (
              <Form className='form my-6 space-between' noValidate>
                <div className={'row'}>
                  <div className={'col-3'}>
                    <KrcSelect
                      required={true}
                      placeholder={`Örn: Emsan`}
                      size={'large'}
                      defaultValue={null}
                      options={STORES.map((item) => {
                        return {
                          value: item.store_id.toString(),
                          label: item.store_id_text,
                        }
                      })}
                      maxTagCount={'responsive'}
                      onChange={(value) => {
                        setSelectedStoreId(value)
                      }}
                      style={{width: '100%'}}
                      className={'ant-selector-border mb-2'}
                      labelTitle={'Store'}
                      tooltipProps={{
                        status: true,
                        text: 'Store seçiniz.',
                      }}
                    />
                  </div>
                  <div className={'col-3'}>
                    <KrcSelect
                      required={true}
                      placeholder={`Örn: Default`}
                      size={'large'}
                      options={sortingTypes.map((item) => {
                        return {
                          value: item.value,
                          label: item.label,
                        }
                      })}
                      maxTagCount={'responsive'}
                      onChange={(value) => {
                        setSelectedSortingType(value)
                      }}
                      style={{width: '100%'}}
                      className={'ant-selector-border mb-2'}
                      labelTitle={'Sıralama Tipi'}
                      tooltipProps={{
                        status: true,
                        text: 'Sıralama tipi seçiniz.',
                      }}
                    />
                  </div>
                  <div className={'col-12'}>
                    <button type='submit' className='btn btn-primary mb-2'>
                      Kaydet
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      <KrcTable
        columns={categorySuggestedSortingDefaultSortingTypesColumns}
        data={categoryDefaultSortingTypes}
        outerHeight={280}
      />
    </>
  )
}
