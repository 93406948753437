import {FC, useContext} from 'react'

import {ShoppingListContext} from '../../../providers/shopping-lists/ShoppingListProvider'
import {paginationMetaType} from '../../models/GeneralModel'
import {KrcTable} from '../../organisms/tables/KrcTable'

export const ShoppingList: FC = () => {
  const {columns, shoppingListData, meta, setMeta, handleOnFilter, isDataEmptyAfterFilter} =
    useContext(ShoppingListContext)

  return (
    <>
      <KrcTable
        columns={columns}
        data={shoppingListData}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            setMeta((data: paginationMetaType) => ({...data, page}))
          },
          onChangeLimit: (limit) => {
            setMeta((data: paginationMetaType) => ({...data, limit}))
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: (search, id) => {
            handleOnFilter(search, id)
          },
          isDataEmptyAfterFilter: isDataEmptyAfterFilter,
        }}
      />
    </>
  )
}
