import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
export const EXPORT_URL = `${API_URL}/exports`

export const checkExportRequest = (fileName: string) => {
  return axios.get(`${EXPORT_URL}/check`, {
    params: {
      file_name: fileName,
    },
  })
}
