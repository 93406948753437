import {Checkbox, Col, Row} from 'antd'
import React, {FC, useEffect} from 'react'

import {KrcInput} from '../../atoms/forms/KrcInput'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useProductListFilter} from '../../hooks/products/useProductListFilter'
import {IProductAdvancedFilter} from '../../models/products/ProductListFilterModel'
import {DateConstraint} from '../../molecules/date_pickers/DateConstraint'
import {BadgeFilter} from '../../molecules/filters/BadgeFilter'
import {CategoryFilter} from '../../molecules/filters/CategoryFilter'
import {ManufacturerFilter} from '../../molecules/filters/ManufacturerFilter'
import {MinMaxFilter} from '../../molecules/filters/MinMaxFilter'

const defaultSelectValue = 'date_added'

const dateConstraintOptions = [
  {
    value: 'date_added',
    label: 'Eklenme Tarihi',
  },
  {
    value: 'date_modified',
    label: 'Son Güncellenme Tarihi',
  },
  {
    value: 'date_stockup',
    label: 'Stok Yenilenme Tarihi',
  },
  {
    value: 'date_priceup',
    label: 'Fiyat Güncellenme Tarihi',
  },
  {
    value: 'last_search_update',
    label: 'Elasticsearch Güncellenme Tarihi',
  },
]

export const ProductAdvancedFilter: FC<IProductAdvancedFilter> = ({
  onChanged,
  className,
  clear,
  clearFilter,
  onClearFilter,
  handleOnChangeKeywordValue,
  keywordValue,
}) => {
  const {
    updateFilter,
    setCheckboxData,
    searchPriority,
    imageOptions,
    dataAreaOptions,
    packetTypeOptions,
    kmiyOptions,
    checkboxData,
    filters,
  } = useProductListFilter()

  useEffect(() => {
    if (!onChanged) {
      return
    }
    onChanged(filters)
  }, [filters])

  useEffect(() => {
    if (clearFilter) {
      setCheckboxData([])
      if (handleOnChangeKeywordValue) {
        handleOnChangeKeywordValue('')
      }
      if (onClearFilter) {
        onClearFilter()
      }
    }
  }, [clearFilter, onClearFilter])

  return (
    <>
      <form className={className} id={'advanced-filter'}>
        <div className={'d-flex gap-4'}>
          <div className={'d-flex flex-column'}>
            <label className={'mb-1'}>
              <span>Fiyat Aralığı</span>
            </label>
            <MinMaxFilter
              clear={clear}
              containerClassName={'d-flex gap-3'}
              onChanged={(data) => {
                updateFilter({min_price: data.min, max_price: data.max})
              }}
              size={'middle'}
              style={{borderRadius: 5, minWidth: 120}}
              minPlaceholder={'Min Fiyat'}
              maxPlaceholder={'Max Fiyat'}
            />
          </div>
          <div className={'d-flex flex-column'}>
            <label className={'mb-1'}>
              <span>Kategori</span>
            </label>
            <CategoryFilter
              clear={clear}
              onSelected={(categoryIds) => {
                updateFilter({category_ids: categoryIds})
              }}
              style={{width: 200}}
            />
          </div>
          <div className={'d-flex flex-column'}>
            <label className={'mb-1'}>
              <span>Marka</span>
            </label>
            <ManufacturerFilter
              clear={clear}
              onSelected={(manufacturerIds) => {
                updateFilter({manufacturer_ids: manufacturerIds})
              }}
              style={{width: 200}}
            />
          </div>
          <div className={'d-flex flex-column'}>
            <label className={'mb-1'}>
              <span>Arama Önceliği</span>
            </label>
            <KrcSelect
              clear={clear}
              className={'select-filter-custom'}
              options={searchPriority}
              onChange={(data) => {
                updateFilter({search_priority: data ? parseInt(data) : null})
              }}
              placeholder={'Lütfen seçiniz'}
            />
          </div>
          <div className={'d-flex flex-column'}>
            <label className={'mb-1'}>
              <span>Görsel</span>
            </label>
            <KrcSelect
              className={'select-filter-custom'}
              options={imageOptions}
              onChange={(data) => {
                updateFilter({has_image: data ? parseInt(data) : null})
              }}
              clear={clear}
              placeholder={'Lütfen seçiniz'}
            />
          </div>
          <div className={'d-flex flex-column'}>
            <label className={'mb-1'}>
              <span>Keyword</span>
            </label>
            <KrcInput
              placeholder={'Keyword'}
              value={keywordValue ? keywordValue : ''}
              onChange={(data) => {
                if (handleOnChangeKeywordValue) {
                  handleOnChangeKeywordValue(data.target.value)
                }
                updateFilter({keyword: data.target.value})
              }}
            ></KrcInput>
          </div>
        </div>

        <div className={'d-flex gap-7 mt-7'}>
          <div>
            <label className={'mb-1'}>
              <span>Maliyet Aralığı</span>
            </label>
            <MinMaxFilter
              clear={clear}
              containerClassName={'d-flex gap-3'}
              onChanged={(data) => {
                updateFilter({min_cost: data.min, max_cost: data.max})
              }}
              size={'middle'}
              style={{borderRadius: 5, minWidth: 120}}
              minPlaceholder={'Min Fiyat'}
              maxPlaceholder={'Max Fiyat'}
            />
          </div>
          <div className={'d-flex flex-column'}>
            <label className={'mb-1'}>
              <span>Kaynak Şirket</span>
            </label>
            <KrcSelect
              clear={clear}
              options={dataAreaOptions}
              onChange={(data) => {
                updateFilter({data_area_id: data ? data : null})
              }}
              placeholder={'Lütfen seçiniz'}
              className={'select-filter-custom'}
            />
          </div>
          <div className={'d-flex flex-column'}>
            <label className={'mb-1'}>
              <span>Paket Tipi</span>
            </label>
            <KrcSelect
              clear={clear}
              options={packetTypeOptions}
              onChange={(data) => {
                updateFilter({packet_type: data ? data : null})
              }}
              placeholder={'Lütfen seçiniz'}
              className={'select-filter-custom'}
            />
          </div>
          <div className={'d-flex flex-column'}>
            <label className={'mb-1'}>
              <span>Kasada Manuel İndirim</span>
            </label>
            <KrcSelect
              clear={clear}
              options={kmiyOptions}
              onChange={(data) => {
                updateFilter({kmiy: data ? parseInt(data) : null})
              }}
              placeholder={'Lütfen seçiniz'}
              className={'select-filter-custom'}
            />
          </div>
          <div className={'d-flex flex-column'}>
            <label className={'mb-1'}>
              <span>Badge</span>
            </label>
            <BadgeFilter
              clear={clear}
              className={'select-filter-custom'}
              options={searchPriority}
              style={{width: 250}}
              onSelected={(data) => {
                if (Array.isArray(data) && data.length === 0) {
                  updateFilter({badge_ids: null})
                  return
                }
                updateFilter({badge_ids: data})
              }}
              placeholder={'Lütfen seçiniz'}
            />
          </div>
        </div>

        <div className={'d-flex align-items-end gap-7 mt-7'}>
          <div className={'d-flex flex-column'}>
            <label className={'mb-1'}>
              <span>Stok Aralığı</span>
            </label>
            <MinMaxFilter
              clear={clear}
              containerClassName={'d-flex gap-3'}
              onChanged={(data) => {
                updateFilter({min_quantity: data.min, max_quantity: data.max})
              }}
              size={'middle'}
              style={{borderRadius: 5, minWidth: 120}}
              minPlaceholder={'Min Stok'}
              maxPlaceholder={'Max Stok'}
            />
          </div>
          <div className={'d-flex flex-column'}>
            <label className={'mb-1'}>
              <span>Tarihi Kısıt</span>
            </label>
            <DateConstraint
              onChange={(data) => {
                updateFilter({
                  date_constraint: data.key,
                  date_added_start: data.value?.start_date ?? null,
                  date_added_end: data.value?.end_date ?? null,
                })
              }}
              selectProps={{
                options: dateConstraintOptions,
                defaultValue: defaultSelectValue,
                style: {width: 200},
              }}
              leftDatePickerProps={{format: 'DD.MM.YYYY'}}
              rightDatePickerProps={{format: 'DD.MM.YYYY'}}
            />
          </div>
          <div className={'ml-6'} style={{width: 460}}>
            <Checkbox.Group
              value={checkboxData}
              style={{width: '100%'}}
              onChange={(data) => {
                setCheckboxData(data)
                let _data = {
                  in_stock: null,
                  can_gift_wrap: null,
                  // has_image: null,
                  d_required: null,
                  has_cart_discount_products: null,
                }

                Object.keys(_data).forEach((key) => {
                  if (data.some((item) => item === key)) {
                    // @ts-ignore
                    _data[key] = 1
                  } else {
                    // @ts-ignore
                    _data[key] = null
                  }
                })
                updateFilter(_data)
              }}
            >
              <Row style={{rowGap: 4}}>
                <Col span={9}>
                  <Checkbox value={'in_stock'}>Stoklu Ürünler</Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox value={'can_gift_wrap'}>Hediye Paketi Yapılabilir</Checkbox>
                </Col>
                <Col span={9}>
                  <Checkbox value={'d_required'} disabled={true}>
                    3D Zorunlu Ürünler
                  </Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox value={'has_cart_discount_products'}>Sepet İndirimli Ürünler</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
        </div>
      </form>
    </>
  )
}
