import * as querystring from 'query-string'
import React, {FC, useState} from 'react'
import {Route, Routes, useNavigate} from 'react-router-dom'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {
  checkProductBadgeLogRequest,
  exportProductBadgeLogRequest,
} from '../../../../../requests/products/ProductRequest'
import {ExcelExport} from '../../../../layout/excel/ExcelExportWrapper'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'

export const ProductBadgeLogsExportWrapper: FC = () => {
  const [downloadExcelUrl, setDownloadExcelUrl] = useState<string>('')
  const navigate = useNavigate()

  const exportProductBadgeLogs = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: true,
    })

    exportProductBadgeLogRequest(params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkProductBadgeLogsExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  const checkProductBadgeLogsExport = (filename: string) => {
    checkProductBadgeLogRequest(filename).then((res) => {
      if (!res.data.data.is_exists) {
        return setTimeout(() => {
          return checkProductBadgeLogsExport(filename)
        }, 10000)
      }

      setDownloadExcelUrl(res.data.data.download_excel_url)
    })
  }

  return (
    <>
      <Toolbar />
      <PageTitle>Ürünlere Toplu Badge Log Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport
              handleExportData={exportProductBadgeLogs}
              downloadExcelUrl={downloadExcelUrl}
            />
          }
        ></Route>
      </Routes>
    </>
  )
}
