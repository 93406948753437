import {FC, useContext} from 'react'

import {ProductListContext} from '../../../../providers/products/ProductListProvider'
import {ProductMultiStoreDrawer} from '../../../drawers/products/ProductMultiStoreDrawer'
import {paginationMetaType} from '../../../models/GeneralModel'
import {ProductListFilter} from '../../../organisms/filters/ProductListFilter'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const ProductList: FC = () => {
  const {
    data,
    setData,
    meta,
    setMeta,
    columns,
    willBeFilteredProductIds,
    setWillBeFilteredProductIds,
    isShowStoreDrawer,
    setIsShowStoreDrawer,
  } = useContext(ProductListContext)

  return (
    <>
      <ProductListFilter
        onChange={setData}
        updateMetaData={meta}
        onChangeMetaData={setMeta}
        willBeFilteredProductIds={willBeFilteredProductIds}
      />
      <KrcTable
        outerHeight={200}
        columns={columns}
        data={data}
        paginationProps={{
          isPagination: true,
          page: meta.page,
          limit: meta.limit,
          paginationData: meta,
          onChangePage: (page) => {
            setMeta((data: paginationMetaType) => ({...data, page}))
          },
          onChangeLimit: (limit) => {
            setMeta((data: paginationMetaType) => ({...data, limit}))
          },
        }}
        selectableProps={{
          isSelectable: true,
          uniqueKey: 'model',
          onSelectedItems: (data) => {
            setWillBeFilteredProductIds(data.map((item: any) => item.id))
          },
        }}
      />
      <ProductMultiStoreDrawer
        visible={isShowStoreDrawer}
        onClose={() => setIsShowStoreDrawer(false)}
      />
    </>
  )
}
