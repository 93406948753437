export const INFORM = 0
export const CONTRACT = 1
export const KVKK = 2

export const INFORMATION_MAIN_TOPICS = [
  {
    id: INFORM,
    topic_name: 'Bilgilendirme',
  },
  {
    id: CONTRACT,
    topic_name: 'Sözleşmeler',
  },
  {
    id: KVKK,
    topic_name: 'KVKK',
  },
]
