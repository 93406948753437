import {DeleteOutlined, EditOutlined, PictureOutlined} from '@ant-design/icons'
import {Avatar, Empty, Image, List, Popconfirm, Radio, Tooltip, message} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'

import {SHOPPING_LIST_THEMES} from '../../../../enums/ShoppingListThemTypeEnum'
import {createShoppingListRequest} from '../../../../requests/shopping-lists/ShoppingListRequest'
import {FileManagerModal} from '../../../../service/FileManager/FileManagerModal'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {ProductSelection} from '../../../layout/filters/ProductSelection'
import {ShoppingListProductsType} from '../../../models/shopping-lists/ShoppingListModel'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

const formikInitialValues = {
  list_name: '',
  list_description: '',
  list_theme: null,
  list_cover_image: '',
  list_share_status: 0,
  status: 0,
  customer_email: '',
  product_ids: [],
}

const schema = Yup.object({
  list_name: Yup.string().required('Liste adı zorunludur'),
  list_description: Yup.string().nullable(),
  list_cover_image: Yup.string().nullable(),
  list_share_status: Yup.number().required('Liste paylaşım durumu zorunludur'),
  status: Yup.number().required('Durum zorunludur'),
  customer_email: Yup.string().required('Müşteri e-posta adresi zorunludur'),
})

export const ShoppingListCreateForm: FC = () => {
  const [products, setProducts] = useState<ShoppingListProductsType[]>([])
  const [willBeAddedProducts, setWillBeAddedProducts] = useState<ShoppingListProductsType[]>([])
  const [coverImage, setCoverImage] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [listTheme, setListTheme] = useState<number | null>(null)
  const [openWebImage, setOpenWebImage] = useState<number>(0)

  const navigate = useNavigate()

  const DEFAULT_IMAGE_PATH = 'https://cdn.karaca.com/image/'
  const handleOnSubmit = (values: FormikValues) => {
    const shoppingListCreateData = {
      ...values,
      list_share_status: values.list_share_status.toString(),
      status: values.status.toString(),
      list_theme: listTheme,
      list_cover_image: coverImage,
      list_description: description,
      product_ids: willBeAddedProducts.map((p) => p.id),
    }
    if (!listTheme) {
      message.error('Liste teması seçmelisiniz.')
      return
    }

    createShoppingListRequest(shoppingListCreateData)
      .then((response) => {
        message.success('Alışveriş listesi başarıyla oluşturuldu.')
        setTimeout(() => {
          navigate('/shopping-lists')
        }, 1000)
      })
      .catch((error) => {
        message.error('Alışveriş listesi oluşturulurken bir hata oluştu.')
        message.error(error.response.data.message)
      })
  }

  return (
    <>
      <Formik
        initialValues={formikInitialValues}
        onSubmit={handleOnSubmit}
        validationSchema={schema}
        enableReinitialize={true}
      >
        {(formik) => {
          return (
            <Form className={'form p-3 rounded-2 me-10'}>
              <div className={'row'}>
                <div className={'col-6'}>
                  <div className={'col-9'}>
                    <KrcFormikField
                      className={'mb-5'}
                      type={'text'}
                      name={'list_name'}
                      required
                      labelTitle={'Liste Adı'}
                    />
                    <div className={'row'}>
                      <div className={'col-6'}>
                        <KrcFormikField
                          required
                          className={'mb-5'}
                          name={'list_share_status'}
                          labelTitle={'Liste Paylaşım Durumu'}
                        >
                          <Field as='select' name='list_share_status' className='form-select'>
                            <option value={undefined} disabled={true}>
                              Seçiniz
                            </option>
                            <option value={1}>Aktif</option>
                            <option value={0}>Pasif</option>
                          </Field>
                        </KrcFormikField>
                      </div>
                      <div className={'col-6'}>
                        <KrcFormikField
                          required
                          className={'mb-5'}
                          name={'status'}
                          labelTitle={'Liste Durumu'}
                        >
                          <Field as='select' name='status' className='form-select'>
                            <option value={undefined} disabled={true}>
                              Seçiniz
                            </option>
                            <option value={1}>Aktif</option>
                            <option value={0}>Pasif</option>
                          </Field>
                        </KrcFormikField>
                      </div>
                    </div>
                    <div className={'row'}>
                      <KrcFormikField
                        className={'mb-5'}
                        type={'text'}
                        name={'customer_email'}
                        required
                        labelTitle={'Kullanıcı Emaili'}
                      />
                    </div>
                    <div className={'row'}>
                      <div className={'col-12'}>
                        <KrcFormikField
                          className={'mb-5'}
                          name={'list_theme'}
                          required
                          labelTitle={'Alışveriş Teması'}
                        >
                          <Radio.Group
                            size='large'
                            buttonStyle='outline'
                            className={'mt-4 d-block'}
                          >
                            <div className='row'>
                              {SHOPPING_LIST_THEMES.map((option) => (
                                <div className='col-4' key={option.value}>
                                  <Radio
                                    value={option.value}
                                    onClick={() => setListTheme(option.value)}
                                  >
                                    <Avatar
                                      size={70}
                                      src={DEFAULT_IMAGE_PATH + option.url}
                                      icon={<PictureOutlined />}
                                    />
                                    <div className='text-center mt-2'>{option.label}</div>
                                  </Radio>
                                </div>
                              ))}
                            </div>
                          </Radio.Group>
                        </KrcFormikField>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'col-6'}>
                  <div className={'col-9'}>
                    <div className={'d-flex flex-column'}>
                      <div
                        className={'d-flex flex-column gap-2 px-6 py-2 rounded w-500px'}
                        style={{backgroundColor: '#F3F3F3'}}
                      >
                        <div className={'d-flex justify-content-between align-items-center'}>
                          <span className={'lh-0'}>
                            <b>Liste Görseli</b>
                          </span>
                          <FileManagerModal
                            tempUploadDir={'/image/2024/shopping_lists'}
                            onSelected={(link) => {
                              setCoverImage(link ? link : '')
                            }}
                            defaultImage={DEFAULT_IMAGE_PATH + coverImage}
                            open={openWebImage}
                            showImage={false}
                          >
                            <KrcButton
                              type={'text'}
                              className={'custom-hover'}
                              icon={<EditOutlined />}
                              onClick={() => {
                                setOpenWebImage((open) => open + 1)
                              }}
                            />
                            <KrcButton
                              type={'text'}
                              className={'custom-hover'}
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                setCoverImage('')
                              }}
                            />
                          </FileManagerModal>
                        </div>
                        {coverImage ? (
                          <Image
                            height={140}
                            className={'rounded-2'}
                            style={{objectFit: 'contain'}}
                            src={DEFAULT_IMAGE_PATH + coverImage}
                            preview={{
                              maskClassName: 'rounded-2',
                            }}
                          />
                        ) : (
                          <>
                            <Empty
                              image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                              imageStyle={{
                                height: 70,
                              }}
                              description={<span>Liste Görseli Bulunamadı.</span>}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={'col-10 mt-4'}>
                    <KrcFormikField
                      className={'mb-2'}
                      type={'text'}
                      name={'list_description'}
                      labelTitle={'Liste Açıklaması'}
                    >
                      <TextArea
                        rows={5}
                        placeholder={'Açıklama giriniz...'}
                        value={description}
                        style={{borderRadius: '7px'}}
                        onChange={(e) => {
                          setDescription(e.target.value)
                        }}
                      />
                    </KrcFormikField>
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <KrcFormikField
                  className={'mb-3 d-flex flex-row justify-content-between'}
                  type={'text'}
                  name={'products'}
                >
                  <div className={'col-6'}>
                    <ProductSelection
                      onTransfer={(data) => {
                        if (data.length === 0) {
                          return
                        }
                        setWillBeAddedProducts((prevState) => {
                          const mergeUniqueById = (
                            prevState: ShoppingListProductsType[],
                            data: ShoppingListProductsType[]
                          ) => {
                            const combinedArray = [...prevState, ...data]

                            const uniqueArray = combinedArray.reduce((acc, current) => {
                              // Eğer acc array'inde current.id ile eşleşen bir id yoksa current'i ekle
                              if (
                                !acc.some(
                                  (item: ShoppingListProductsType) => item.id === current.id
                                )
                              ) {
                                // @ts-ignore
                                acc.push(current)
                              }
                              return acc
                            }, [])

                            return uniqueArray
                          }
                          return [...mergeUniqueById(prevState, data)]
                        })
                        message.info('Eklenen ürünler listelendi.')
                      }}
                      onSelectedItems={(data) => {
                        setProducts(data ?? [])
                      }}
                      includedProductIds={products.map((p) => p.id)}
                      headerProps={{
                        titleLevel: 5,
                      }}
                      transferProps={{
                        button: {
                          title: 'Ürünleri Ekle >>',
                          isActive: true,
                        },
                        config: {
                          confirmActive: true,
                          confirm: {
                            title: `Seçilen ${products.length} ürün kupon ile ilişkilendirilecek.`,
                            icon: false,
                            width: 600,
                            content: (
                              <List
                                size='small'
                                header={
                                  <div>
                                    <b>Eklenecek Ürünler</b>
                                  </div>
                                }
                                bordered
                                dataSource={products}
                                renderItem={(item) => {
                                  return (
                                    <List.Item>
                                      <div
                                        className={
                                          'd-flex flex-row justify-content-around w-100 align-items-center'
                                        }
                                      >
                                        <div>{item.id}</div>
                                        <div>{item.model}</div>
                                        <div>{item.name}</div>
                                      </div>
                                    </List.Item>
                                  )
                                }}
                              />
                            ),
                            okText: 'Tamam',
                            cancelText: 'İptal',
                          },
                        },
                      }}
                    />
                  </div>
                  <div className={'col-5 pt-10'}>
                    <List
                      size='small'
                      header={
                        <div>
                          <b>Eklenecek Ürünler</b>
                        </div>
                      }
                      bordered
                      dataSource={willBeAddedProducts}
                      renderItem={(item) => {
                        return (
                          <List.Item>
                            <div
                              className={
                                'd-flex flex-row justify-content-between w-100 align-items-center'
                              }
                            >
                              <div className={'col-3'} style={{fontSize: '1rem'}}>
                                {item.id}
                              </div>
                              <div className={'col-7'} style={{fontSize: '1rem'}}>
                                {item.name}
                              </div>
                              <div className={'d-flex justify-content-end'}>
                                <Tooltip title={'Sil'}>
                                  <Popconfirm
                                    title={`${item.name} ürününü silmek istediğinize emin misiniz?`}
                                    onConfirm={() => {
                                      setWillBeAddedProducts((prevState) => {
                                        return prevState.filter((p) => p.id !== item.id)
                                      })
                                      message.info(
                                        'Ürün, eklenecek ürünler listesinden kaldırıldı.'
                                      )
                                    }}
                                    okText='Evet'
                                    cancelText='Hayır'
                                  >
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                                      <DeleteOutlined />
                                    </div>
                                  </Popconfirm>
                                </Tooltip>
                              </div>
                            </div>
                          </List.Item>
                        )
                      }}
                    />
                  </div>
                </KrcFormikField>
              </div>
              <div className={'d-flex justify-content-end mt-7'}>
                <KrcButton
                  size={'large'}
                  id='kt_toolbar_primary_button'
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                >
                  Oluştur
                </KrcButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
