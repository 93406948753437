import React, {FC, useContext, useEffect} from 'react'

import {CategorySelectionEnum} from '../../../../enums/CategorySelectionEnum'
import {
  CategorySelectionContext,
  CategorySelectionProvider,
} from '../../../../providers/tables/CategorySelectionProvider'
import {useKrcProp} from '../../../hooks/components/useKrcProp'
import {ICategorySelection} from '../../../models/categories/CategorySelectionModel'
import {CategorySelectionHeader} from '../../../molecules/headers/filters/CategorySelectionHeader'
import {CategorySelectionFilter} from './CategorySelectionFilter'
import {CategorySelectionList} from './CategorySelectionList'

const initialCategorySelectionProps = {
  default: {
    type: CategorySelectionEnum.TEXT,
  },
}

const CategorySelectionWithProvider: FC<ICategorySelection> = ({
  headerProps,
  onTransfer,
  removeAllExcluded = 0,
  transferProps,
  onSelectedItems,
  textAreaProps,
  clear,
}) => {
  const currentHeaderProps = useKrcProp({
    config: initialCategorySelectionProps.default,
    props: headerProps,
  }).getCurrentProps()

  const {transferData, updateFilters, selectedItems} = useContext(CategorySelectionContext)

  useEffect(() => {
    if (!onTransfer) {
      return
    }

    onTransfer(transferData)
  }, [transferData])

  useEffect(() => {
    onSelectedItems && onSelectedItems(selectedItems)
  }, [selectedItems])

  return (
    <>
      <CategorySelectionHeader {...currentHeaderProps} />
      <CategorySelectionFilter
        onFilter={(data) => {
          if (!updateFilters) {
            return
          }

          updateFilters(data)
        }}
        textAreaProps={textAreaProps}
      />
      <CategorySelectionList
        removeAllExcluded={removeAllExcluded}
        transferProps={{...transferProps}}
        clear={clear}
      />
    </>
  )
}

export const CategorySelection: FC<ICategorySelection> = ({
  includedCategoryIds,
  clear,
  ...props
}) => {
  return (
    <>
      <CategorySelectionProvider
        includedCategoryIds={includedCategoryIds}
        onFilterChange={props.onFilterChange}
        onFilterTypeChange={props.onFilterTypeChange}
        onMetaChange={props.onMetaChange}
      >
        <CategorySelectionWithProvider {...props} clear={clear}></CategorySelectionWithProvider>
      </CategorySelectionProvider>
    </>
  )
}
