import {DeleteOutlined, EllipsisOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import {Dropdown, Menu, Modal, Tooltip, message} from 'antd'
import React, {FC, createContext, useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Cell, Column} from 'react-table'

import {KrcEmptyFilter} from '../../../components/atoms/KrcEmptyFilter'
import {paginationMetaType} from '../../../components/models/GeneralModel'
import {
  CategoryTypeEnum,
  categoryDataType,
} from '../../../components/models/categories/CategoryModel'
import {categoryUrlType} from '../../../components/models/categories/CategoryProductModel'
import {
  categoryDefaultSuggestedSortingTypeType,
  categoryDeleteDefaultSortingTypeRequestBody,
  categoryUpdateDefaultSortingTypeRequestBody,
} from '../../../components/models/categories/CategorySortingModel'
import {minimalProductType} from '../../../components/models/products/MinimalProductModel'
import {MANUAL_SORTING} from '../../../enums/CategorySortingPageTypeEnum'
import {errorMessage} from '../../../helpers/ErrorHelper'
import {getCategoryRequest} from '../../../requests/categories/CategoryRequest'
import {
  deleteActiveCategoryProductSortRequest,
  destroyCategoryDefaultSortingTypeRequest,
  getActiveCategoryEditorsChoiceLogsRequest,
  getActiveCategoryProductSortMethodRequest,
  getCategoryDefaultSortingTypeLogsRequest,
  getCategoryDefaultSortingTypesRequest,
  getCategoryProductEditorChoiceManualSortingRequest,
  updateCategoryDefaultSortingTypeRequest,
  updateCategoryProductMostSellerSortingRequest,
  updateCategoryProductsEditorChoiceSortingRequest,
} from '../../../requests/categories/sorting/CategorySortingRequest'
import {getSortingTypesRequest} from '../../../requests/sorting/SortingWeightRequest'
import {viewDateFormat} from '../../../service/DateService'
import {categoryProductsProcessTableDataType} from '../products/CategoryProductsProcessProvider'

export const initialMetaData = {limit: 10, page: 1}

type sortingWeightOrderType = {
  label: string
  value: string
}

const initialOrderTypes: sortingWeightOrderType[] = [
  {
    label: '',
    value: '',
  },
]

export type categorySortingRequestBody = {
  first_line_count?: string | number
  product_name?: string
  product_group?: string[]
  discount_rate?: string | number
  product_manufacturer?: string[]
  product_color?: string[]
  product_number_of_people?: string[]
  product_number_of_piece?: string[]
}

interface ICategorySortingContext {
  category?: categoryDataType
  visibleAddProductToCategorySortingDrawer: boolean
  categorySortedEditorChoiceProducts: categoryProductsProcessTableDataType[]
  categorySortingEditorsChoiceColumns: Column<any>[]
  categorySuggestedSortingDefaultSortingTypesColumns: Column<any>[]
  unSelectAllItems: number
  categorySortingEditorsChoiceSelectedItems: any[]
  setVisibleAddProductToCategorySortingDrawer: React.Dispatch<React.SetStateAction<boolean>>
  setCategorySortedEditorChoiceProducts: React.Dispatch<
    React.SetStateAction<categoryProductsProcessTableDataType[]>
  >
  addCategorySortedProducts: (data: categoryProductsProcessTableDataType[]) => void
  setCategorySortingEditorsChoiceSelectedItems: React.Dispatch<React.SetStateAction<any>>
  handleOnSaveCategorySortingEditorsChoice: () => void
  deleteSelectedCategorySortingProducts: () => void
  categoryId?: string
  updateCategoryProductMostSellerSorting: (data: categorySortingRequestBody) => void
  pageType: string
  setPageType: React.Dispatch<React.SetStateAction<string>>
  activeSortMethod: string | null
  categoryDefaultSortingTypes: categoryDefaultSuggestedSortingTypeType[]
  sortingTypes: sortingWeightOrderType[]
  setActiveSortMethod: React.Dispatch<React.SetStateAction<string | null>>
  getActiveCategoryProductSortMethod: () => void
  getCategorySortingEditorChoice: () => void
  getCategoryDefaultSortingTypes: () => void
  changeCategoryDefaultSortingTypeByStore: (
    data: categoryUpdateDefaultSortingTypeRequestBody
  ) => void
  deleteCategoryDefaultSortingTypeByStore: (
    data: categoryDeleteDefaultSortingTypeRequestBody
  ) => void
  deleteActiveCategoryProductSortMethod: () => void
  setCategoryEditorsChoiceLogsData: () => void
  setCategoryDefaultSortLogsData: () => void
  categoryEditorsChoiceLogsColumns: Column<any>[]
  categoryDefaultSortLogsColumns: Column<any>[]
  categoryEditorsChoiceLogs: any[]
  categoryDefaultSortLogs: any[]
  categoryEditorsChoiceLogsMeta: paginationMetaType
  categoryDefaultSortLogsMeta: paginationMetaType
  setCategoryEditorsChoiceLogsMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  setCategoryDefaultSortLogsMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
}

export const CategoryProductSortingContext = createContext<ICategorySortingContext>({
  category: undefined,
  visibleAddProductToCategorySortingDrawer: false,
  categorySortedEditorChoiceProducts: [],
  categorySortingEditorsChoiceColumns: [],
  categorySuggestedSortingDefaultSortingTypesColumns: [],
  unSelectAllItems: 0,
  categorySortingEditorsChoiceSelectedItems: [],
  setVisibleAddProductToCategorySortingDrawer: () => {},
  setCategorySortedEditorChoiceProducts: () => {},
  addCategorySortedProducts: () => {},
  setCategorySortingEditorsChoiceSelectedItems: () => {},
  handleOnSaveCategorySortingEditorsChoice: () => {},
  deleteSelectedCategorySortingProducts: () => {},
  updateCategoryProductMostSellerSorting: () => {},
  pageType: MANUAL_SORTING,
  setPageType: () => {},
  activeSortMethod: null,
  categoryDefaultSortingTypes: [],
  sortingTypes: [],
  setActiveSortMethod: () => {},
  getActiveCategoryProductSortMethod: () => {},
  getCategorySortingEditorChoice: () => {},
  getCategoryDefaultSortingTypes: () => {},
  changeCategoryDefaultSortingTypeByStore: () => {},
  deleteCategoryDefaultSortingTypeByStore: () => {},
  deleteActiveCategoryProductSortMethod: () => {},
  setCategoryEditorsChoiceLogsData: () => {},
  setCategoryDefaultSortLogsData: () => {},
  categoryEditorsChoiceLogsColumns: [],
  categoryDefaultSortLogsColumns: [],
  categoryEditorsChoiceLogs: [],
  categoryDefaultSortLogs: [],
  categoryEditorsChoiceLogsMeta: initialMetaData,
  categoryDefaultSortLogsMeta: initialMetaData,
  setCategoryEditorsChoiceLogsMeta: () => {},
  setCategoryDefaultSortLogsMeta: () => {},
})

export const CategoryProductSortingProvider: FC = ({children}) => {
  const {categoryId} = useParams<categoryUrlType>()
  const [category, setCategory] = useState<categoryDataType>()
  const [categorySortingEditorsChoiceSelectedItems, setCategorySortingEditorsChoiceSelectedItems] =
    useState<any[]>([])
  const [unSelectAllItems, setUnSelectAllItems] = useState<number>(0)
  const [visibleAddProductToCategorySortingDrawer, setVisibleAddProductToCategorySortingDrawer] =
    useState<boolean>(false)
  const [categorySortedEditorChoiceProducts, setCategorySortedEditorChoiceProducts] = useState<
    categoryProductsProcessTableDataType[]
  >([])

  const [pageType, setPageType] = useState<string>(MANUAL_SORTING)
  const [activeSortMethod, setActiveSortMethod] = useState<string | null>(null)

  const [sortingTypes, setSortingTypes] = useState<sortingWeightOrderType[]>(initialOrderTypes)
  const [categoryDefaultSortingTypes, setCategoryDefaultSortingTypes] = useState<
    categoryDefaultSuggestedSortingTypeType[]
  >([])

  const [categoryEditorsChoiceLogs, setCategoryEditorsChoiceLogs] = useState<any[]>([])
  const [categoryEditorsChoiceLogsMeta, setCategoryEditorsChoiceLogsMeta] =
    useState<paginationMetaType>(initialMetaData)

  const [categoryDefaultSortLogs, setCategoryDefaultSortLogs] = useState<any[]>([])
  const [categoryDefaultSortLogsMeta, setCategoryDefaultSortLogsMeta] =
    useState<paginationMetaType>(initialMetaData)

  const initialRef = useRef(0)

  const categorySortingManualSortingColumns = React.useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 100,
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Model',
        accessor: 'model',
        maxWidth: 150,
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Eklenme tarihi',
        accessor: 'dateAdded',
        maxWidth: 150,
        Filter: KrcEmptyFilter,
      },
    ]
  }, [])

  const categorySuggestedSortingDefaultSortingTypesColumns = React.useMemo(() => {
    return [
      {
        Header: 'Store',
        accessor: 'store_id_text',
        maxWidth: 100,
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Sıralama Türü',
        accessor: 'default_sorting_type',
        maxWidth: 150,
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Aksiyonlar',
        accessor: '',
        Cell: ({row}: Cell<any>) => {
          const {confirm} = Modal

          const handleOnRemoveCategoryProductDefaultSort = () => {
            confirm({
              title: 'Uyarı',
              icon: <ExclamationCircleOutlined />,
              content: 'Kaydı silmek istediğinize emin misiniz?',
              okText: 'Evet',
              okType: 'primary',
              cancelText: 'Vazgeç',
              onOk() {
                deleteCategoryDefaultSortingTypeByStore({
                  store_id: row.original.store_id,
                } as categoryDeleteDefaultSortingTypeRequestBody)
              },
            })
          }

          const productAddActionsDropdownMenu = (
            <Menu>
              <Menu.Item
                title={'Kaldır'}
                onClick={handleOnRemoveCategoryProductDefaultSort}
                icon={<DeleteOutlined />}
                key={'removeCategoryProductDefaultSort'}
                danger={true}
              >
                Listeden Kaldır
              </Menu.Item>
            </Menu>
          )

          return (
            <div className={'d-flex flex-row justify-content-start'}>
              <Dropdown overlay={productAddActionsDropdownMenu} trigger={['click']}>
                <Tooltip title={'Seçenekler'}>
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 category-default-sort-options'>
                    <EllipsisOutlined />
                  </div>
                </Tooltip>
              </Dropdown>
            </div>
          )
        },
        Filter: KrcEmptyFilter,
      },
    ]
  }, [])

  const categoryEditorsChoiceLogsColumns = React.useMemo(() => {
    return [
      {
        Header: 'Sıralama Türü',
        accessor: 'sorting_type',
        maxWidth: 100,
        manualWidth: 150,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'İşlem Türü',
        accessor: 'action_text',
        maxWidth: 100,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'İşlemi Gerçekleşiren \n Kullanıcı Adı',
        accessor: 'user_name',
        maxWidth: 150,
        manualWidth: 150,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Açıklama',
        accessor: 'message',
        Cell: ({row}: any) => <div dangerouslySetInnerHTML={{__html: row.original.message}}></div>,
        maxWidth: 250,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Gerçekleşme Tarihi',
        accessor: 'created_at',
        maxWidth: 100,
        Cell: ({row}: any) => viewDateFormat(row.original.created_at),
        Filter: <KrcEmptyFilter />,
      },
    ]
  }, [])

  const categoryDefaultSortLogsColumns = React.useMemo(() => {
    return [
      {
        Header: 'Sıralama Türü',
        accessor: 'default_sorting_type',
        maxWidth: 100,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Store',
        accessor: 'store_text',
        maxWidth: 100,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'İşlem Türü',
        accessor: 'action_text',
        maxWidth: 100,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'İşlemi Gerçekleşiren \n Kullanıcı Adı',
        accessor: 'user_name',
        maxWidth: 150,
        manualWidth: 150,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Açıklama',
        accessor: 'message',
        Cell: ({row}: any) => <div dangerouslySetInnerHTML={{__html: row.original.message}}></div>,
        maxWidth: 250,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Gerçekleşme Tarihi',
        accessor: 'created_at',
        maxWidth: 100,
        Cell: ({row}: any) => viewDateFormat(row.original.created_at),
        Filter: <KrcEmptyFilter />,
      },
    ]
  }, [])

  useEffect(() => {
    if (!categoryId) {
      setCategory(undefined)
      return
    }

    setCategoryInfo()
  }, [categoryId])

  useEffect(() => {
    getActiveCategoryProductSortMethod()
    getCategoryDefaultSortingTypes()
    getSortingTypes()
  }, [])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (categoryEditorsChoiceLogsMeta.page !== 1) {
      setCategoryDefaultSortLogsMeta((data) => ({...data, page: 1}))
      return
    }
    setCategoryEditorsChoiceLogsData()
  }, [categoryEditorsChoiceLogsMeta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    setCategoryEditorsChoiceLogsData()
  }, [categoryEditorsChoiceLogsMeta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (categoryDefaultSortLogsMeta.page !== 1) {
      setCategoryDefaultSortLogsMeta((data) => ({...data, page: 1}))
      return
    }
    setCategoryDefaultSortLogsData()
  }, [categoryDefaultSortLogsMeta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    setCategoryDefaultSortLogsData()
  }, [categoryDefaultSortLogsMeta.page])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  const setCategoryInfo = () => {
    if (!categoryId) {
      throw new Error(`setCategoryInfo cannot be called without a categoryId`)
    }

    getCategoryRequest(Number(categoryId), ['description']).then((res) => {
      const newCategoryData = res.data.data

      if (newCategoryData) {
        setCategory(newCategoryData)
      }
    })
  }

  const addCategorySortedProducts = (
    data: categoryProductsProcessTableDataType[],
    position: 'end' | 'start' = 'start'
  ) => {
    setCategorySortedEditorChoiceProducts((products) => {
      const _data = data.filter((item) => {
        return !products.some((product) => {
          return product.id === item.id
        })
      })

      if (position === 'end') {
        return [...products, ..._data]
      }

      return [..._data, ...products]
    })
  }

  const handleOnSaveCategorySortingEditorsChoice = () => {
    const key = 'saveCategorySortingEditorsChoice'

    let sortedProductIds = categorySortedEditorChoiceProducts.map((item) => item.id)

    message.loading({
      content: 'Sıralama kaydediliyor. Biraz uzun sürebilir. Lütfen bekleyiniz...',
      key,
    })

    updateCategoryProductsEditorChoiceSortingRequest(Number(categoryId), sortedProductIds)
      .then((_res) => {
        message.success({content: 'Sıralama kaydedildi', key})
        setUnSelectAllItems((prev) => prev + 1)
        getActiveCategoryProductSortMethod()
      })
      .catch((err) => {
        errorMessage(err)
        message.destroy(key)
      })
  }

  const deleteSelectedCategorySortingProducts = () => {
    const key = 'deleteSelectedCategorySortingProducts'
    if (categorySortingEditorsChoiceSelectedItems.length === 0) {
      message.error({content: 'Silmek için önce seçmelisiniz', key, duration: 1})
      return
    }

    setCategorySortedEditorChoiceProducts((prev) => {
      return prev.filter((item) => {
        return !categorySortingEditorsChoiceSelectedItems.some((selectedItem) => {
          return selectedItem.id === item.id
        })
      })
    })

    message.success({content: 'Seçilen ürünler silindi', key, duration: 2})
    setCategorySortingEditorsChoiceSelectedItems([])
  }

  const getCategorySortingEditorChoice = () => {
    if (!categoryId) {
      return
    }

    getCategoryProductEditorChoiceManualSortingRequest(categoryId).then((res) => {
      if (!res.data.data) {
        return
      }

      const data = res.data.data.map((item: minimalProductType) => {
        return {
          id: item.product_id,
          name: item.minimal_details?.name,
          model: item.model,
          dateAdded: viewDateFormat(item.date_added),
        }
      })
      getActiveCategoryProductSortMethod()

      setCategorySortedEditorChoiceProducts(data)
    })
  }

  // editors choice parametric sorting
  const updateCategoryProductMostSellerSorting = (data: categorySortingRequestBody) => {
    if (!categoryId) {
      return
    }
    const key = 'update-category-product-most-seller-sorting'

    message.loading({
      content: 'Sıralama kaydediliyor. Biraz uzun sürebilir. Lütfen bekleyiniz...',
      key,
    })

    updateCategoryProductMostSellerSortingRequest(Number(categoryId), data)
      .then(() => {
        message.success({content: 'Sıralama kaydedildi', key})
        getActiveCategoryProductSortMethod()
      })
      .catch((err) => {
        errorMessage(err)
        message.destroy(key)
      })
  }

  const getActiveCategoryProductSortMethod = () => {
    if (!categoryId) {
      return
    }

    if (category?.category_type === CategoryTypeEnum.BOUTIQUE) {
      return
    }

    getActiveCategoryProductSortMethodRequest(Number(categoryId)).then((res) => {
      setActiveSortMethod(res.data.active_sort_method)
    })
  }

  const deleteActiveCategoryProductSortMethod = () => {
    if (!categoryId) {
      return
    }

    const key = 'delete-active-category-product-sort-method'

    message.loading({
      content: 'Aktif sıralama kaldırılıyor. Lütfen bekleyiniz...',
      key,
    })

    deleteActiveCategoryProductSortRequest(Number(categoryId)).then(() => {
      document.location.reload()
    })
  }

  const getCategoryDefaultSortingTypes = () => {
    getCategoryDefaultSortingTypesRequest(Number(categoryId)).then((res) => {
      setCategoryDefaultSortingTypes(res.data?.data || [])
    })
  }

  const changeCategoryDefaultSortingTypeByStore = (
    data: categoryUpdateDefaultSortingTypeRequestBody
  ) => {
    if (!categoryId) {
      return
    }
    const key = 'update-category-default-sorting-type'

    message.loading({
      content: 'Varsayılan Sıralama kaydediliyor. Lütfen bekleyiniz...',
      key,
    })

    const storeId = data.store_id === -1 ? null : data.store_id
    const defaultSortingType = data.default_sorting_type

    updateCategoryDefaultSortingTypeRequest(Number(categoryId), storeId, defaultSortingType)
      .then((res) => {
        message.success({content: 'Varsayılan Sıralama kaydedildi.', key})
        getCategoryDefaultSortingTypes()
      })
      .catch((err) => {
        errorMessage(err)
        message.destroy(key)
      })
  }

  const deleteCategoryDefaultSortingTypeByStore = (
    data: categoryDeleteDefaultSortingTypeRequestBody
  ) => {
    if (!categoryId) {
      return
    }
    const key = 'delete-category-default-sorting-type'

    message.loading({
      content: 'Varsayılan Sıralama Siliniyor. Lütfen bekleyiniz...',
      key,
    })

    const storeId = data.store_id === -1 ? null : data.store_id

    destroyCategoryDefaultSortingTypeRequest(Number(categoryId), storeId)
      .then((res) => {
        message.success({content: 'Varsayılan Sıralama silindi.', key})
        getCategoryDefaultSortingTypes()
      })
      .catch((err) => {
        errorMessage(err)
        message.destroy(key)
      })
  }

  const getSortingTypes = () => {
    getSortingTypesRequest()
      .then((res) => {
        if (!res.data) {
          return
        }
        setSortingTypes(res.data.data)
      })
      .catch((err) => {
        message.error('Sıralama tipleri getirilirken bir hata oluştu')
        message.error(err.response.data.message)
      })
  }

  const setCategoryEditorsChoiceLogsData = () => {
    getActiveCategoryEditorsChoiceLogsRequest(
      Number(categoryId),
      categoryEditorsChoiceLogsMeta
    ).then((res) => {
      setCategoryEditorsChoiceLogs(res.data.data)
      const _meta = res.data.meta
      setCategoryEditorsChoiceLogsMeta({..._meta, limit: _meta.per_page, page: _meta.current_page})
    })
  }

  const setCategoryDefaultSortLogsData = () => {
    getCategoryDefaultSortingTypeLogsRequest(Number(categoryId), categoryDefaultSortLogsMeta).then(
      (res) => {
        setCategoryDefaultSortLogs(res.data.data)
        const _meta = res.data.meta
        setCategoryDefaultSortLogsMeta({..._meta, limit: _meta.per_page, page: _meta.current_page})
      }
    )
  }

  return (
    <>
      <CategoryProductSortingContext.Provider
        value={{
          category,
          categoryId,
          visibleAddProductToCategorySortingDrawer,
          categorySortedEditorChoiceProducts,
          categorySortingEditorsChoiceColumns: categorySortingManualSortingColumns,
          categorySuggestedSortingDefaultSortingTypesColumns,
          unSelectAllItems,
          categorySortingEditorsChoiceSelectedItems,
          pageType,
          activeSortMethod,
          categoryDefaultSortingTypes,
          sortingTypes,
          categoryEditorsChoiceLogsColumns,
          categoryDefaultSortLogsColumns,
          categoryEditorsChoiceLogs,
          categoryDefaultSortLogs,
          categoryEditorsChoiceLogsMeta,
          categoryDefaultSortLogsMeta,
          setActiveSortMethod,
          setPageType,
          setVisibleAddProductToCategorySortingDrawer,
          setCategorySortedEditorChoiceProducts,
          addCategorySortedProducts,
          setCategorySortingEditorsChoiceSelectedItems,
          handleOnSaveCategorySortingEditorsChoice,
          deleteSelectedCategorySortingProducts,
          updateCategoryProductMostSellerSorting,
          getActiveCategoryProductSortMethod,
          getCategorySortingEditorChoice,
          getCategoryDefaultSortingTypes,
          changeCategoryDefaultSortingTypeByStore,
          deleteCategoryDefaultSortingTypeByStore,
          deleteActiveCategoryProductSortMethod,
          setCategoryEditorsChoiceLogsData,
          setCategoryDefaultSortLogsData,
          setCategoryDefaultSortLogsMeta,
          setCategoryEditorsChoiceLogsMeta,
        }}
      >
        {children}
      </CategoryProductSortingContext.Provider>
    </>
  )
}
