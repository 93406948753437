export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_CATEGORIES_META = 'SET_CATEGORIES_META'
export const SET_SELECTED_CATEGORIES = 'SET_SELECTED_CATEGORIES'
export const ADD_SELECTED_CATEGORY = 'ADD_SELECTED_CATEGORY'
export const DELETE_SELECTED_CATEGORY = 'DELETE_SELECTED_CATEGORY'
export const SET_CATEGORY = 'SET_CATEGORY'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const SET_ADDED_PRODUCTS = 'SET_ADDED_PRODUCTS'
export const SET_DELETED_PRODUCT_IDS = 'SET_DELETED_PRODUCT_IDS'
