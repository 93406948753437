import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Checkbox, Drawer, Modal, Space, message} from 'antd'
import {CheckboxValueType} from 'antd/lib/checkbox/Group'
import {Form, Formik} from 'formik'
import React, {FC, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'

import {STORES} from '../../../enums/ProductStoresEnum'
import {categoryProductsProcessTableDataType} from '../../../providers/category/products/CategoryProductsProcessProvider'
import {
  attachMultipleProductToStoreRequest,
  detachMultipleProductToStoreRequest,
} from '../../../requests/products/ProductRequest'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {ProductSelection} from '../../layout/filters/ProductSelection'
import {MultiStoreDrawerFormInitialValuesType} from '../../models/categories/CategoryProductModel'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'

export enum multiStoreProcessTypeEnum {
  ADD = 1,
  REMOVE = 2,
}

const {confirm} = Modal
export interface IMultiStoreProcessDrawer {
  visible: boolean
  onClose: () => void
}

const schema = Yup.object({
  process_type: Yup.string().nullable(false),
  stores: Yup.array().nullable(false),
})

export const _initialValue = {
  process_type: '',
  stores: [] as number[],
}
export const ProductMultiStoreDrawer: FC<IMultiStoreProcessDrawer> = ({
  visible = false,
  onClose,
}) => {
  const submitRef = useRef<HTMLButtonElement | null>(null)

  const [selectedProducts, setSelectedProducts] = useState<categoryProductsProcessTableDataType[]>(
    []
  )
  const [initialValue, setInitialValue] =
    useState<MultiStoreDrawerFormInitialValuesType>(_initialValue)
  const [multiStoreActionTitle, setMultiStoreActionTitle] = useState('Ekle/Çıkar')

  useEffect(() => {
    if (initialValue.process_type === multiStoreProcessTypeEnum.ADD) {
      setMultiStoreActionTitle('Ekle')
      return
    }
    if (initialValue.process_type === multiStoreProcessTypeEnum.REMOVE) {
      setMultiStoreActionTitle('Çıkar')
      return
    }
    setMultiStoreActionTitle('Ekle/Çıkar')
  }, [initialValue.process_type])

  const handleOnOk = () => {
    if (selectedProducts?.length === 0) {
      message.warn('Ürün eklemek/çıkarılmak için ürün seçilmedi. Lütfen ürün(leri) seçin.')
      return
    }
    if (!initialValue.process_type) {
      message.warn('Store toplu işlem türü seçilmedi. Lütfen store toplu işlem türü seçin.')
      return
    }
    if (initialValue.stores.length === 0) {
      message.warn('Lütfen en az bir store seçiniz.')
      return
    }

    confirm({
      title: `${selectedProducts?.length} ürün eklenecek/çıkarılacak.`,
      icon: <ExclamationCircleOutlined />,
      content:
        'Toplu ürün ekleme/çıkarma işlemi kaydet buttonuna basılana kadar gerçekleşmeyecektir.',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        saveMultiStoreOperationHandler(selectedProducts, initialValue)
        onClose()
      },
    })
  }

  const onChangeStoresCheckBoxes = (checkedValues: CheckboxValueType[]) => {
    setInitialValue((initialValue) => ({
      ...initialValue,
      stores: checkedValues as number[],
    }))
  }

  const saveMultiStoreOperationHandler = (
    products: categoryProductsProcessTableDataType[],
    formInputValues: MultiStoreDrawerFormInitialValuesType
  ) => {
    if (formInputValues.process_type === multiStoreProcessTypeEnum.ADD) {
      attachMultipleProductToStoreRequest({
        product_ids: selectedProducts.map((item) => item.id),
        store_ids: formInputValues?.stores,
      })
        .then((res) => {
          message.success('Ürünler başarıyla eklendi.')
        })
        .catch((error) => {
          message.error('Ürünleri eklerken bir sorun oluştu: ' + error.message)
        })
    }
    if (formInputValues.process_type === 2) {
      detachMultipleProductToStoreRequest({
        product_ids: selectedProducts.map((item) => item.id),
        store_ids: formInputValues?.stores,
      })
        .then((res) => {
          message.success('Ürünler başarıyla çıkarıldı.')
        })
        .catch((error) => {
          message.error('Ürünleri çıkarmada bir sorun oluştu: ' + error.message)
        })
    }
  }

  useEffect(() => {
    if (!visible) {
      setSelectedProducts([])
    }
  }, [visible])

  return (
    <>
      <Drawer
        title={'Toplu Store İşlemleri'}
        placement='right'
        size={'large'}
        onClose={onClose}
        keyboard={false}
        visible={visible}
        extra={
          <Space>
            <KrcButton type={'default'} size={'large'} onClick={onClose}>
              İptal
            </KrcButton>
            <KrcButton size={'large'} onClick={handleOnOk}>
              Seçilen Ürünleri {multiStoreActionTitle}
            </KrcButton>
          </Space>
        }
      >
        {visible && (
          <div>
            <>
              <Formik
                validationSchema={schema}
                initialValues={initialValue}
                onSubmit={handleOnOk}
                enableReinitialize
              >
                {() => (
                  <Form className='form row' noValidate>
                    <KrcFormikField
                      className={'row'}
                      name={'process_type'}
                      labelTitle={'Store Toplu İşlem Türü'}
                    >
                      <KrcSelect
                        placeholder={'Store Toplu İşlem Seçiniz'}
                        options={[
                          {value: multiStoreProcessTypeEnum.ADD, label: 'Toplu Store Ekleme'},
                          {value: multiStoreProcessTypeEnum.REMOVE, label: 'Toplu Store Çıkarma'},
                        ]}
                        maxTagCount={'responsive'}
                        style={{width: 200}}
                        className={'ant-selector-border ml-2'}
                        showArrow={true}
                        onChange={(data) => {
                          setInitialValue((initialValue) => ({
                            ...initialValue,
                            process_type: data,
                          }))
                        }}
                      />
                    </KrcFormikField>
                    <KrcFormikField
                      className={'row mt-3'}
                      name={'store_names'}
                      labelTitle={'Mağaza Seçimi'}
                    >
                      <Checkbox.Group onChange={onChangeStoresCheckBoxes}>
                        <div className={'row ml-2'}>
                          {STORES.map((store) => (
                            <div className={'col-3 mb-2'} key={store.store_id}>
                              <Checkbox value={store.store_id}>{store.store_id_text}</Checkbox>
                            </div>
                          ))}
                        </div>
                      </Checkbox.Group>
                    </KrcFormikField>
                    <button
                      type='submit'
                      ref={submitRef}
                      className='d-none'
                      style={{marginLeft: 10}}
                    />
                  </Form>
                )}
              </Formik>
            </>

            <ProductSelection
              onSelectedItems={(data) => {
                setSelectedProducts(data ?? [])
              }}
              withFullPrice={true}
              transferProps={{
                button: {
                  isActive: false,
                },
                config: {
                  confirmActive: false,
                },
              }}
            />
          </div>
        )}
      </Drawer>
    </>
  )
}
