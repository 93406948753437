import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {
  SortingWeightContext,
  SortingWeightProvider,
} from '../../../providers/sorting/SortingWeightProvider'
import {getSortingWeightExportFrontendUrl} from '../../../requests/sorting/SortingWeightRequest'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {CreateSortingWeightModal} from '../../modals/sortings/CreateSortingWeightModal'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {SortingWeightUpdateForm} from './SortingWeightUpdateForm'

const SortingWeightWrapperRightBar: FC = () => {
  const {setVisibleCreateSortingWeightModal} = useContext(SortingWeightContext)
  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <a
            type={'button'}
            className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-3'
            href={getSortingWeightExportFrontendUrl()}
            target={'_blank'}
            rel='noreferrer'
          >
            Güncel Ağırlık Değerleri
          </a>
          <KrcButton
            size={'middle'}
            onClick={() => setVisibleCreateSortingWeightModal(true)}
            className={'mr-3'}
            id='kt_toolbar_primary_button'
          >
            Yeni Sıralama Ağırlığı Ekle
          </KrcButton>
        </div>
      </Toolbar>
      <PageTitle>Sıralama Ağırlıklarını Güncelleme</PageTitle>
    </>
  )
}

const SortingWeightWrapperWithoutProvider: FC = () => {
  const {visibleCreateSortingWeightModal} = useContext(SortingWeightContext)
  return (
    <>
      <SortingWeightWrapperRightBar />
      <SortingWeightUpdateForm />
      {visibleCreateSortingWeightModal && <CreateSortingWeightModal />}
    </>
  )
}

export const SortingWeightWrapper: FC = () => {
  return (
    <>
      <SortingWeightProvider>
        <SortingWeightWrapperWithoutProvider />
      </SortingWeightProvider>
    </>
  )
}
