import {Select, SelectProps, Spin, message} from 'antd'
import {Field, Form, Formik, FormikValues} from 'formik'
import {isEmpty} from 'lodash'
import React, {FC, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {IBM_CAMPAIGN_SEO_URL_TYPES} from '../../../../enums/IBMCampaignSeoUrlTypeEnum'
import {IBM_CAMPAIGN_STORES} from '../../../../enums/IBMCampaignStoresTypeEnum'
import {
  CAMPAIGN_COMPANIES,
  CAMPAIGN_TAGS,
  CAMPAIGN_TYPES,
} from '../../../../enums/IBMCampaignUpdateTypeEnum'
import {storeIBMCampaign} from '../../../../requests/ibm/IBMCampaignRequest'
import {fetchIBMGroupsRequest} from '../../../../requests/ibm/IBMGroupRequest'
import {FileManagerModal} from '../../../../service/FileManager/FileManagerModal'
import {KrcInput} from '../../../atoms/forms/KrcInput'
import {KrcRangePicker} from '../../../atoms/forms/KrcRangePicker'
import {KrcRichTextEditorWithTable} from '../../../atoms/forms/KrcRichTextEditorWithTable'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {useSeoUrl} from '../../../hooks/url/useSeoUrl'
import {IBmCampaignUrlType, PacketDiscountType} from '../../../models/ibm/IBMCampaignModel'
import {BadgeFilter} from '../../../molecules/filters/BadgeFilter'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'

const formikInitialValues = {
  campaign_name: '',
  badge_id: null,
  campaign_cart_title: '',
  campaign_company: null,
  campaign_description: '',
  campaign_group_id: null,
  campaign_margin: null,
  campaign_page_status: 0,
  campaign_pdp_status: 0,
  campaign_pdp_title: '',
  campaign_special_price: null,
  campaign_sort: 0,
  campaign_status: 0,
  campaign_tag: null,
  date_start: '',
  date_end: '',
  campaign_title: '',
  campaign_type: null,
  campaigns_page_image: '',
  campaigns_page_title: '',
  url_alias_campaign_seo_url: '',
  campaign_keywords: [],
  campaign_conditions: '',
  campaign_start_date: '',
  campaign_end_date: '',
  campaign_store_ids: [],
  cta_button_text: '',
  campaign_seo_url_web: '',
  campaign_seo_url_app: '',
  campaign_price: null,
}

interface SelectType {
  value: number
  label: string
}

export const IBMCampaignCreateWrapper: FC = () => {
  const navigate = useNavigate()
  const {groupId} = useParams<IBmCampaignUrlType>()
  const DEFAULT_IMAGE_PATH = 'https://cdn.karaca.com/image/'
  const [campaignDescription, setCampaignDescription] = useState('')
  const [campaignBadge, setCampaignBadge] = useState<SelectType | null>(null)
  const [startDate, setStartDate] = useState<string | null>('')
  const [endDate, setEndDate] = useState<string | null>('')
  const [campaignGroupId, setCampaignGroupId] = useState<number>(groupId ? Number(groupId) : 0)
  const [campaignTag, setCampaignTag] = useState<SelectType | null>(null)
  const [campaignCompany, setCampaignCompany] = useState<SelectType | null>(null)
  const [campaignType, setCampaignType] = useState<SelectType | null>(null)
  const [campaignPageImage, setCampaignPageImage] = useState<string>('')
  const [campaignKeywords, setCampaignKeywords] = useState<SelectProps['options']>([])
  const [campaignConditions, setCampaignConditions] = useState<string>('')
  const initialRef = useRef(0)
  const [IBMGroupOptions, setIBMGroupOptions] = useState<any[]>([])
  const {type, typeId, handleSearch, isLoading: loadingHook} = useSeoUrl()
  const [aliasTypeId, setAliasTypeId] = useState<any>(typeId)
  const [aliasType, setAliasType] = useState<any>(type)
  const [seoUrlApp, setSeoUrlApp] = useState<string>('')
  const [campaignStoreIds, setCampaignStoreIds] = useState<number[]>([])
  const [campaignStartDate, setCampaignStartDate] = useState<string | null | undefined>(undefined)
  const [campaignEndDate, setCampaignEndDate] = useState<string | null | undefined>(undefined)

  useEffect(() => {
    if (initialRef.current === 0) {
      fetchIBMGroupsRequest({}, {page: 1, limit: 10}, 1).then((res) => {
        const _data = res.data.data
        const _options = _data.map((item: any) => {
          return {value: item.ibm_group_id, label: item.ibm_group_title}
        })
        setIBMGroupOptions(_options)
      })
      initialRef.current++
    }
  }, [])

  useEffect(() => {
    setAliasType(type)
    setAliasTypeId(typeId)
  }, [type, typeId, loadingHook])

  const handleOnSubmit = (values: FormikValues) => {
    if (campaignBadge) {
      if (isEmpty(campaignBadge)) {
        values.badge_id = formikInitialValues.badge_id
      } else {
        values.badge_id = campaignBadge?.value
      }
    } else {
      values.badge_id = formikInitialValues.badge_id
    }

    if (campaignTag !== formikInitialValues.campaign_tag) {
      values.campaign_tag = campaignTag
    } else {
      values.campaign_tag = formikInitialValues.campaign_tag
    }

    if (campaignCompany !== formikInitialValues.campaign_company) {
      values.campaign_company = campaignCompany
    } else {
      values.campaign_company = formikInitialValues.campaign_company
    }

    if (campaignType !== formikInitialValues.campaign_type) {
      values.campaign_type = campaignType
    } else {
      values.campaign_type = formikInitialValues.campaign_type
    }

    if (startDate !== formikInitialValues.date_start) {
      values.date_start = startDate
    } else {
      values.date_start = formikInitialValues.date_start
    }

    if (endDate !== formikInitialValues.date_end) {
      values.date_end = endDate
    } else {
      values.date_end = formikInitialValues.date_end
    }

    if (campaignGroupId !== formikInitialValues.campaign_group_id) {
      values.campaign_group_id = campaignGroupId
    } else {
      values.campaign_group_id = formikInitialValues.campaign_group_id
    }

    if (campaignPageImage !== formikInitialValues.campaigns_page_image) {
      values.campaigns_page_image = campaignPageImage
    } else {
      values.campaigns_page_image = formikInitialValues.campaigns_page_image
    }

    if (campaignKeywords !== formikInitialValues.campaign_keywords) {
      values.campaign_keywords = campaignKeywords
    } else {
      values.campaign_keywords = formikInitialValues.campaign_keywords
    }

    if (!values.campaign_group_id || values.campaign_group_id.length === 0) {
      message.error('Kampanya Grubu Seçmelisiniz.')
      return
    }

    if (!values.campaign_name) {
      message.error('Kampanya Adı boş bırakılamaz.')
      return
    }

    if (!campaignConditions) {
      message.error('Kampanya Koşulları boş bırakılamaz.')
      return
    }

    if (!values.campaign_keywords || values.campaign_keywords.length === 0) {
      message.error('Anahtar Kelimeler boş bırakılamaz.')
      return
    }

    if (!values.campaigns_page_title) {
      message.error('Kampanyalar Sayfası Başlığı boş bırakılamaz.')
      return
    }

    if (!values.date_start || !values.date_end) {
      message.error('Başlangıç ve Bitiş Tarihi seçmek zorunludur.')
      return
    }

    const requestBody = {
      ...values,
      campaign_description: campaignDescription,
      campaign_conditions: campaignConditions,
      campaign_start_date: campaignStartDate ? campaignStartDate : null,
      campaign_end_date: campaignEndDate ? campaignEndDate : null,
      campaign_first_price:
        values.discount_type === PacketDiscountType.PERCENT
          ? null
          : values.campaign_first_price
          ? values.campaign_first_price
          : null,
      campaign_url_alias_type_id: aliasTypeId,
      campaign_url_alias_type: aliasType,
      campaign_seo_url_app: seoUrlApp,
      campaign_seo_url_web: values.campaign_seo_url_web ? values.campaign_seo_url_web : null,
      campaign_store_ids: campaignStoreIds ? campaignStoreIds : [],
    }

    message.info('Kampanya oluşturuluyor... Lütfen bekleyiniz.')
    storeIBMCampaign(Number(groupId), requestBody)
      .then((res) => {
        message.success('Kampanya oluşturuldu.')
        setTimeout(() => {
          navigate(`/ibm/group/${groupId}`)
        }, 1000)
      })
      .catch((err) => {
        message.error('Kampanya oluşturulurken bir hata oluştu.')
        message.error(err.response.data.message)
      })
  }

  const handleChangeKeyword = (value: any) => {
    setCampaignKeywords(
      value.map((item: string, index: number) => {
        return {
          value: index,
          label: item,
        }
      })
    )
  }

  return (
    <>
      <Toolbar />
      <PageTitle>IBM Kampanya Oluştur</PageTitle>
      <Formik initialValues={formikInitialValues} onSubmit={handleOnSubmit}>
        {(formik) => {
          return (
            <Form className={'form'}>
              <div className={'row'}>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'text'}
                    name={'campaigns_page_title'}
                    required
                    labelTitle={'Kampanyalar Sayfası Başlığı'}
                  />
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'w-100'}
                    name={'campaign_page_status'}
                    labelTitle={'Sayfa Görüntülenme Durumu'}
                  >
                    <Field as='select' name='campaign_page_status' className='form-select'>
                      <option value={undefined} disabled={true}>
                        Seçiniz
                      </option>
                      <option value={1}>Görünür</option>
                      <option value={0}>Görünmez</option>
                    </Field>
                  </KrcFormikField>
                </div>
                <div className={'col-6'}>
                  <KrcFormikField
                    name={'date'}
                    required={false}
                    labelTitle={'Kampanyalar Sayfası Görsel Başlangıç - Bitiş Tarihi'}
                  >
                    <KrcRangePicker
                      style={{width: '200%', borderRadius: 5}}
                      showTime
                      allowClear
                      placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
                      format={'DD.MM.YYYY HH:mm'}
                      onSelected={(value) => {
                        if (!value) {
                          setCampaignStartDate(null)
                          setCampaignEndDate(null)
                          return
                        }
                        setCampaignStartDate(value[0].format('YYYY-MM-DD HH:mm'))
                        setCampaignEndDate(value[1].format('YYYY-MM-DD HH:mm'))
                      }}
                    ></KrcRangePicker>
                  </KrcFormikField>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'number'}
                    name={'campaign_group_id'}
                    labelTitle={'Kampanya Grubu'}
                  >
                    <KrcSelect
                      mode={undefined}
                      placeholder={'Kampanya Grubu Seçiniz'}
                      size={'large'}
                      options={IBMGroupOptions}
                      maxTagCount={'responsive'}
                      style={{width: '100%'}}
                      className={'ant-selector-border'}
                      showArrow={true}
                      defaultValue={campaignGroupId}
                      onChange={(data) => {
                        setCampaignGroupId(data)
                      }}
                    />
                  </KrcFormikField>
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'text'}
                    name={'campaign_seo_url_web'}
                    labelTitle={'SEO URL (WEB)'}
                  />
                </div>
                <div className={'col-6'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'text'}
                    name={'campaign_seo_url_app'}
                    labelTitle={'SEO URL (APP)'}
                  >
                    <div className={'d-flex gap-4'}>
                      <div className={'col-4'}>
                        <KrcInput
                          size={'large'}
                          className={'mb-3'}
                          type={'text'}
                          name={'campaign_seo_url_app'}
                          defaultValue={seoUrlApp}
                          value={seoUrlApp}
                          onChange={(e) => {
                            if (e.target.value === '') {
                              setAliasType('')
                              setAliasTypeId(0)
                            }
                            handleSearch(e.target.value)
                            setSeoUrlApp(e.target.value)
                          }}
                        />
                      </div>
                      <div className={'col-2'}>
                        {loadingHook ? (
                          <div className={'bg-white px-5 py-2 mb-3'}>
                            <Spin />
                          </div>
                        ) : (
                          <KrcInput
                            size={'large'}
                            className={'mb-3'}
                            type={'text'}
                            name={'campaign_seo_url_app'}
                            defaultValue={aliasTypeId}
                            value={aliasTypeId}
                            onChange={(e) => {
                              setAliasTypeId(e.target.value)
                            }}
                          />
                        )}
                      </div>
                      <div className={'col-5'}>
                        {loadingHook ? (
                          <div className={'bg-white px-5 py-2 mb-3'}>
                            <Spin />
                          </div>
                        ) : (
                          <KrcSelect
                            size={'large'}
                            placeholder={'Seçiniz'}
                            defaultValue={aliasType}
                            className={
                              'ant-selector-border custom-ant-select-selection-placeholder'
                            }
                            value={aliasType}
                            style={{width: '100%'}}
                            options={[
                              {value: '', label: 'Seçiniz', disabled: true},
                              ...IBM_CAMPAIGN_SEO_URL_TYPES,
                            ]}
                            onChange={(value) => {
                              setAliasType(value)
                            }}
                          ></KrcSelect>
                        )}
                      </div>
                    </div>
                  </KrcFormikField>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'number'}
                    name={'campaign_sort'}
                    labelTitle={'Kampanya Sıralama'}
                  />
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2 z-index-9999'}
                    name={'store_ids'}
                    labelTitle={'Mağazalar'}
                  >
                    <KrcSelect
                      maxTagCount='responsive'
                      mode={'multiple'}
                      placeholder={'Seçiniz'}
                      size={'large'}
                      dropdownStyle={{zIndex: 9999}}
                      value={campaignStoreIds}
                      className={
                        'ant-selector-border custom-ant-select-selection-placeholder w-full d-block'
                      }
                      options={IBM_CAMPAIGN_STORES.map((store) => {
                        return {
                          value: store.value,
                          label: store.label,
                        }
                      })}
                      onChange={(storeId) => {
                        if (!storeId) {
                          return
                        }

                        setCampaignStoreIds(storeId)
                      }}
                      style={{width: '100%'}}
                    />
                  </KrcFormikField>
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'text'}
                    name={'campaign_keywords'}
                    required
                    labelTitle={'Anahtar Kelimeler'}
                  >
                    <Select
                      size={'large'}
                      mode='tags'
                      style={{width: '100%'}}
                      placeholder='Etiket Giriniz'
                      onChange={handleChangeKeyword}
                      value={campaignKeywords?.map((item) => {
                        return {
                          value: item.label,
                          label: item.label,
                        }
                      })}
                      options={campaignKeywords?.map((item) => {
                        return {
                          value: item.label,
                          label: item.label,
                        }
                      })}
                    />
                  </KrcFormikField>
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2 mt-2'}
                    name={'campaign_page_image'}
                    labelTitle={'Kampanya Sayfa Görseli'}
                  >
                    <FileManagerModal
                      onSelected={(link) => {
                        setCampaignPageImage(link ? link : '')
                      }}
                      defaultImage={
                        campaignPageImage ? DEFAULT_IMAGE_PATH + campaignPageImage : undefined
                      }
                      tempUploadDir={'/image/2024/ibm/page_image'}
                    />
                  </KrcFormikField>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-3'}>
                  <KrcFormikField
                    type={'text'}
                    className={'w-100'}
                    name={'campaign_name'}
                    readonly={false}
                    required
                    labelTitle={'Kampanya Adı'}
                  />
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'text'}
                    name={'campaign_title'}
                    labelTitle={'Kampanya Başlığı'}
                  />
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'text'}
                    name={'cta_button_text'}
                    labelTitle={'CTA Buton Metni'}
                  />
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2'}
                    name={'campaign_status'}
                    labelTitle={'Aktiflik Durumu'}
                  >
                    <Field as='select' name='campaign_status' className='form-select'>
                      <option value={undefined} disabled={true}>
                        Seçiniz
                      </option>
                      <option value={1}>Aktif</option>
                      <option value={0}>Pasif</option>
                    </Field>
                  </KrcFormikField>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-6 mt-1'}>
                  <KrcFormikField
                    className={'mb-2'}
                    name={'campaign_conditions'}
                    required
                    labelTitle={'Kampanya Koşulları'}
                  >
                    <div className={'form-group'}>
                      <KrcRichTextEditorWithTable
                        className={'mt-2 pb-4'}
                        height={250}
                        value={campaignConditions}
                        onChange={(value) =>
                          value ? setCampaignConditions(value) : setCampaignConditions('')
                        }
                      />
                    </div>
                  </KrcFormikField>
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'w-100 mb-2'}
                    type={'text'}
                    name={'campaign_pdp_title'}
                    labelTitle={'PDP Başlığı'}
                  />
                  <KrcFormikField
                    className={'mb-2'}
                    name={'campaign_pdp_status'}
                    labelTitle={'PDP Durumu'}
                  >
                    <Field as='select' name='campaign_pdp_status' className='form-select'>
                      <option value={undefined} disabled={true}>
                        Seçiniz
                      </option>
                      <option value={1}>Aktif</option>
                      <option value={0}>Pasif</option>
                    </Field>
                  </KrcFormikField>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'text'}
                    name={'campaign_cart_title'}
                    labelTitle={'Kampanya Sepet Başlığı'}
                  />
                </div>
                <div className={'col-3'}>
                  <div className={'form-group'}>
                    <KrcFormikField
                      className={'mb-2'}
                      name={'campaign_description'}
                      labelTitle={'Kampanya Açıklaması'}
                    >
                      <textarea
                        className={'form-control'}
                        rows={4}
                        onChange={(e) => setCampaignDescription(e.target.value)}
                        name={'campaign_description'}
                        defaultValue={campaignDescription}
                        value={campaignDescription}
                      />
                    </KrcFormikField>
                    <KrcFormikField
                      className={'mt-6'}
                      type={'text'}
                      name={'campaign_tag'}
                      labelTitle={'Kampanya Tag '}
                    >
                      <KrcSelect
                        placeholder={'Kampanya Tag'}
                        size={'large'}
                        options={CAMPAIGN_TAGS}
                        maxTagCount={'responsive'}
                        style={{width: '100%'}}
                        className={'ant-selector-border'}
                        showArrow={true}
                        value={campaignTag}
                        onChange={(data) => {
                          setCampaignTag(data)
                        }}
                      />
                    </KrcFormikField>
                  </div>
                </div>
              </div>
              <div className={'row mt-7'}>
                <div className={'col-3'}>
                  <KrcFormikField name={'badge_id'} required={false} labelTitle={'Badge'}>
                    <BadgeFilter
                      mode={undefined}
                      showSearch={true}
                      size={'large'}
                      style={{width: '200%'}}
                      allowClear={true}
                      onClear={() => {
                        setCampaignBadge(null)
                      }}
                      value={campaignBadge}
                      defaultValue={campaignBadge}
                      className={'select-filter-custom'}
                      dataType={'item'}
                      onSelected={(data) => {
                        //@ts-ignore
                        setCampaignBadge(data)
                      }}
                      dropdownStyle={{zIndex: 99999}}
                      placeholder={'Badge Ara'}
                    />
                  </KrcFormikField>
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'number'}
                    name={'campaign_margin'}
                    labelTitle={'Belirlenen Marj'}
                  />
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    type={'text'}
                    name={'campaign_company'}
                    labelTitle={'Kampanya Şirketi'}
                  >
                    <KrcSelect
                      placeholder={'Kampanya Şirketi'}
                      size={'large'}
                      options={CAMPAIGN_COMPANIES}
                      maxTagCount={'responsive'}
                      style={{width: '200%'}}
                      className={'ant-selector-border'}
                      showArrow={true}
                      value={campaignCompany}
                      onChange={(data) => {
                        setCampaignCompany(data)
                      }}
                    />
                  </KrcFormikField>
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    type={'number'}
                    name={'campaign_type'}
                    labelTitle={'Kampanya Türü'}
                  >
                    <KrcSelect
                      placeholder={'Tür Filtresi'}
                      size={'large'}
                      options={CAMPAIGN_TYPES}
                      maxTagCount={'responsive'}
                      style={{width: '200%'}}
                      className={'ant-selector-border'}
                      showArrow={true}
                      defaultValue={campaignType?.value}
                      value={campaignType}
                      onChange={(data) => {
                        setCampaignType(data)
                      }}
                    />
                  </KrcFormikField>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'text'}
                    name={'url_alias_campaign_seo_url'}
                    labelTitle={'Kampanya URL'}
                  />
                </div>
                <div className={'col-3'}>
                  <KrcFormikField
                    className={'w-full'}
                    type={'text'}
                    name={'campaign_price'}
                    labelTitle={'Kampanya Fiyatı'}
                  />
                </div>
              </div>
              <div className={'row mt-2'}>
                <div className={'col-6'}>
                  <KrcFormikField
                    name={'date'}
                    required
                    labelTitle={'Kampanya Başlangıç - Bitiş Tarihi'}
                  >
                    <KrcRangePicker
                      style={{width: '200%', borderRadius: 5}}
                      showTime
                      allowClear
                      placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
                      format={'DD.MM.YYYY HH:mm'}
                      onSelected={(value) => {
                        if (!value) {
                          setStartDate(null)
                          setEndDate(null)
                          return
                        }
                        setStartDate(value[0].format('YYYY-MM-DD HH:mm'))
                        setEndDate(value[1].format('YYYY-MM-DD HH:mm'))
                      }}
                    ></KrcRangePicker>
                  </KrcFormikField>
                </div>
              </div>
              <div className={'row d-flex justify-content-end mb-3'}>
                <button
                  type='submit'
                  className={`btn btn-primary w-25 mt-4`}
                  style={{marginLeft: 10}}
                >
                  Kaydet
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
