import {useState} from 'react'

import {productVerticalAdvancedFilterDataType} from '../../models/products/ProductListFilterModel'

export const useProductAdvancedFilter = () => {
  const [filters, setFilters] = useState<productVerticalAdvancedFilterDataType>()

  const updateFilters = (payload: any) => {
    setFilters((data) => {
      return {...data, ...payload}
    })
  }

  return {filters, updateFilters}
}
