import {Empty} from 'antd'
import React, {FC} from 'react'

import store from '../../../../../../setup/redux/Store'
import {PRODUCT_IMPORT_UPLOAD_URL} from '../../../../../requests/categories/campaigns/import/CampaignWaitingApprovalImportRequest'
import {KrcButton} from '../../../../atoms/buttons/KrcButton'
import {useCampaignWaitingApprovalImport} from '../../../../hooks/categories/campaigns/import/useCampaignWaitingApprovalImport'
import {UploadModal} from '../../../../modals/UploadModal'
import {KrcTable} from '../../../../organisms/tables/KrcTable'

export const CampaignWaitingApprovalImport: FC = () => {
  const {importModalVisible, setImportModalVisible, data, columns, handleOnChange} =
    useCampaignWaitingApprovalImport()
  const {
    auth: {accessToken},
  } = store.getState()

  return (
    <>
      {data.length ? (
        <>
          <KrcTable
            columns={columns}
            data={data}
            searchableProps={{
              isSearchable: true,
            }}
          />
        </>
      ) : (
        <Empty
          image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
          imageStyle={{
            height: 60,
          }}
          description={
            <span>
              Onay bekleyen kampanya ürünlerini toplu şekilde onaylamak/reddetmek için
              kullanabileceğiniz bir alandır.
            </span>
          }
        >
          <KrcButton
            onClick={() => {
              setImportModalVisible(true)
            }}
          >
            Toplu Kampanya Ürünü Onayla/Reddet
          </KrcButton>
        </Empty>
      )}

      {importModalVisible && (
        <UploadModal
          visible={importModalVisible}
          onHide={() => {
            setImportModalVisible(false)
          }}
          upload={{
            name: 'excel_file',
            action: `${PRODUCT_IMPORT_UPLOAD_URL}`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            showUploadList: false,
            onChange: handleOnChange,
          }}
        />
      )}
    </>
  )
}
