import React, {FC, useContext} from 'react'
import {useDispatch} from 'react-redux'

import {BadgeContext} from '../../../providers/BadgeProvider'
import {selectBadges, setBadge} from '../../../store/Badge/BadgeAction'
import {BadgeDrawer} from '../../drawers/BadgeDrawer'
import {BadgeListProductAndCategoryDrawer} from '../../drawers/BadgeListProductAndCategoryDrawer'
import {IBadge} from '../../models/badges/BadgeModel'
import {KrcTable} from '../../organisms/tables/KrcTable'

export const BadgeList: FC = () => {
  const {
    columns,
    badges,
    badge,
    handleOnFilter,
    visibleDrawer,
    updateVisibleDrawer,
    drawerType,
    selectedTiedBadge,
    setSelectedTiedBadge,
    getBadges,
    meta,
    changePage,
    changeLimit,
  } = useContext(BadgeContext)

  const dispatch = useDispatch()

  return (
    <>
      <KrcTable
        stylesProps={{className: ['badge-list']}}
        columns={columns}
        data={badges}
        outerHeight={160}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            changePage(page)
          },
          onChangeLimit: (limit) => {
            changeLimit(limit)
          },
        }}
        selectableProps={{
          isSelectable: true,
          selectableAlign: 'left',
          uniqueKey: 'badge_id',
          onSelectedItems: (badges: IBadge[]) => {
            dispatch(selectBadges(badges))
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: (search, id) => {
            handleOnFilter(search, id)
          },
          style: {maxWidth: 250},
        }}
      />
      <BadgeDrawer
        visible={visibleDrawer}
        type={drawerType}
        badgeData={badge}
        onHide={() => {
          updateVisibleDrawer(false)
          dispatch(setBadge(null))
        }}
        onSave={() => {
          getBadges()
        }}
      />

      <BadgeListProductAndCategoryDrawer
        badge={selectedTiedBadge}
        onClose={() => {
          setSelectedTiedBadge(undefined)
        }}
      />
    </>
  )
}
