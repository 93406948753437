import {useState} from 'react'

import {DateConstraintDataType} from '../../../modules/partials/components/filter/models/FilterModel'
import {ProductDatePickerOnChangeDataType} from '../../models/components/KrcDatePickerModel'

export const useDateConstraint = () => {
  const [data, setData] = useState<DateConstraintDataType>({
    key: '',
    value: null,
  })

  const handleSelectOnChange = (value: string) => {
    setData((data) => ({...data, key: value}))
  }

  const handleStartDateOnChange = (value: ProductDatePickerOnChangeDataType) => {
    setData((data) => ({
      ...data,
      value: {
        ...data.value,
        start_date: value.date,
        raw_start_date: value.raw_date,
        start_formatted_date: value.date_formatted,
      },
    }))
  }

  const handleEndDateOnChange = (value: ProductDatePickerOnChangeDataType) => {
    setData((data) => ({
      ...data,
      value: {
        ...data.value,
        end_date: value.date,
        raw_end_date: value.raw_date,
        end_formatted_date: value.date_formatted,
      },
    }))
  }

  return {handleSelectOnChange, handleStartDateOnChange, handleEndDateOnChange, data}
}
