import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {Tooltip, message} from 'antd'
import _ from 'lodash'
import {OptionData} from 'rc-select/lib/interface'
import React, {FC, createContext, useEffect, useState} from 'react'
import {Column} from 'react-table'

import {
  menuMapDataType,
  willBeRemovedMenuDataType,
} from '../../components/models/menus/MenuMapModel'
import {menuDataType, menuModalConfig} from '../../components/models/menus/MenuModel'
import {storeDataType} from '../../components/models/stores/StoreModel'
import {MAX_DEEP_LIMIT} from '../../enums/MenuMapEnum'
import {ModalEnum} from '../../enums/ModalEnum'
import {errorMessage} from '../../helpers/ErrorHelper'
import {
  createMenuRequest,
  deleteMenuRequest,
  getMenusRequest,
} from '../../requests/menus/MenuRequest'
import {getStoresRequest} from '../../requests/stores/StoreRequest'

interface IMenuMapContext {
  columns: Column<any>[]
  menuMaps: {[key: string]: menuMapDataType[]}
  groupTitle: {[key: string]: string}
  selectedMenu: {[key: string]: menuDataType | null}
  stores?: storeDataType[]
  currentStore?: storeDataType
  options?: any
  setMenuMapsData?: (data: menuDataType[], deep: number) => void
  updateMenuData: (data: menuMapDataType, deep: number) => void
  updateGroupTitle?: (data: string, deep: number) => void
  updateSelectedMenu?: (data: menuDataType, deep: number) => void
  getStores?: () => void
  selectCurrentStore?: (storeId: number) => void
  getMenus?: (deep: number, menuParentId?: number) => void
  willBeUpdateData: menuDataType | null
  setWillBeUpdateData: (data: menuDataType | null) => void
  deleteMenu: (menuId: number, deep: number, data: menuMapDataType, removeTop: boolean) => void
  createMainMenu: () => void
  visibleCreateModal: boolean
  setVisibleCreateModal: (data: boolean) => void
  openMenuModal: (
    type: ModalEnum.CREATE | ModalEnum.UPDATE,
    deep: number,
    data: menuDataType
  ) => void
  modalConfig: menuModalConfig | null
  setModalConfig: (data: menuModalConfig | null) => void
  getParentIdByDeep: (deep: number) => number | undefined
  isLoading: boolean
  isShowDeleteModal: boolean
  setIsShowDeleteModal: (data: boolean) => void
  willBeRemovedMenuData: willBeRemovedMenuDataType
  setWillBeRemovedMenuData: (data: willBeRemovedMenuDataType) => void
  activeTabKey: string
  setActiveTabKey: (data: string) => void
}

export const MenuMapContext = createContext<IMenuMapContext>({
  menuMaps: {},
  groupTitle: {},
  columns: [],
  selectedMenu: {},
  willBeUpdateData: null,
  setWillBeUpdateData: () => {},
  updateMenuData: () => {},
  deleteMenu: () => {},
  createMainMenu: () => {},
  visibleCreateModal: false,
  setVisibleCreateModal: () => {},
  openMenuModal: () => {},
  modalConfig: null,
  setModalConfig: () => {},
  getParentIdByDeep: () => {
    return undefined
  },
  isLoading: false,
  isShowDeleteModal: false,
  setIsShowDeleteModal: () => {},
  willBeRemovedMenuData: {
    menuId: 0,
    deep: 0,
    data: {} as menuMapDataType,
  },
  setWillBeRemovedMenuData: (data: willBeRemovedMenuDataType) => {},
  activeTabKey: '',
  setActiveTabKey: () => {},
})

export const MenuMapProvider: FC = ({children}) => {
  const [menuMaps, setMenuMaps] = useState<{[key: string]: menuMapDataType[]}>({})
  const [groupTitle, setGroupTitle] = useState({})
  const [selectedMenu, setSelectedMenu] = useState({})
  const [stores, setStores] = useState<storeDataType[]>([])
  const [currentStore, setCurrentStore] = useState<storeDataType>()
  const [options, setOptions] = useState<OptionData[]>([])
  const [willBeUpdateData, setWillBeUpdateData] = useState<menuDataType | null>(null)
  const [visibleCreateModal, setVisibleCreateModal] = useState<boolean>(false)
  const [modalConfig, setModalConfig] = useState<menuModalConfig | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false)
  const [willBeRemovedMenuData, setWillBeRemovedMenuData] = useState({
    menuId: 0,
    deep: 0,
    data: {} as menuMapDataType,
  })
  const [activeTabKey, setActiveTabKey] = useState<string>('menu-management')

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'name',
      },
      {
        Header: '',
        accessor: 'count',
        maxWidth: 60,
        minWidth: 60,
        manualWidth: 60,
      },
      {
        Header: '',
        maxWidth: 70,
        minWidth: 50,
        manualWidth: 50,
        accessor: 'col5',
        id: 'click-me-button',
        Cell: ({row}: any) => {
          return (
            <div id={'table-action-column'} className='text-end d-flex mr-1'>
              <Tooltip title={'Güncelle'}>
                <div
                  onClick={() => {
                    openMenuModal(ModalEnum.UPDATE, row.original.deep, row.original.data)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                >
                  <EditOutlined />
                </div>
              </Tooltip>

              <Tooltip title={'Sil'}>
                <div
                  onClick={() => {
                    const data = {
                      menuId: row.original.id,
                      deep: row.original.deep,
                      data: row.original,
                    }
                    setWillBeRemovedMenuData(data)
                    setIsShowDeleteModal(true)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  <DeleteOutlined />
                </div>
              </Tooltip>
            </div>
          )
        },
      },
    ],
    [menuMaps]
  )

  const openMenuModal = (
    type: ModalEnum.CREATE | ModalEnum.UPDATE,
    deep: number,
    data: menuDataType
  ) => {
    let parentId = null
    if (data && data?.menu_id) {
      parentId = data?.menu_id
    }

    setModalConfig({
      deep,
      parentId,
    })

    if (type === ModalEnum.UPDATE) {
      if (!data) {
        throw Error('data.data cannot be null or undefined, has to be object')
      }
      setVisibleCreateModal(true)
      setWillBeUpdateData(data)
    } else {
      setVisibleCreateModal(true)
    }
  }

  const getMenus = (deep: number, menuParentId?: number) => {
    if (!currentStore?.store_id) {
      return
    }

    if (deep > MAX_DEEP_LIMIT) {
      console.error(`deep: ${deep}, maxDeepLimit: ${MAX_DEEP_LIMIT}. Limit exceeded.`)
      return
    }
    setIsLoading(true)
    getMenusRequest(currentStore?.store_id, menuParentId, ['subMenus']).then((res) => {
      const results = res.data.data
        .map((data: menuDataType) => {
          return {
            id: data.menu_id,
            name: data.title,
            count: data.sub_menus_count ?? 0,
            position: data.position,
            show_top_menu: data.show_top_menu,
            deep,
            data: {...data, parent_id: menuParentId},
          } as menuMapDataType
        })
        .filter((menuData: menuMapDataType) => menuData.position !== 'root')

      setMenuMapsData(results, deep)

      for (let i = deep + 1; i <= MAX_DEEP_LIMIT; i++) {
        setMenuMapsData([], i)
        updateGroupTitle('', i)
      }
      setIsLoading(false)
    })
  }

  const getStores = () => {
    setIsLoading(true)
    getStoresRequest(['mainMenu'], ['menus']).then((res) => {
      const results = res.data.data.map((data: storeDataType) => {
        return {value: data.store_id, label: data.name, count: data.menus_count}
      })

      setCurrentStore(res.data.data[0])
      updateGroupTitle(res.data.data[0]?.main_menu?.title ?? '', 0)
      setStores(res.data.data)
      setOptions(results)
    })
    setIsLoading(false)
  }

  const selectCurrentStore = (storeId: number) => {
    if (stores.length === 0) {
      return
    }

    const filteredData = _.findLast(stores, (store: storeDataType) => {
      return store.store_id === storeId
    })
    updateGroupTitle(filteredData?.main_menu?.title ?? '', 0)

    setCurrentStore(filteredData)
  }

  const setMenuMapsData = (data: menuDataType[], deep: number) => {
    if (deep > MAX_DEEP_LIMIT) {
      console.error(`deep: ${deep}, maxDeepLimit: ${MAX_DEEP_LIMIT}. Limit exceeded.`)
    }

    setMenuMaps((menuMaps) => ({...menuMaps, [deep]: data}))
  }

  const getParentIdByDeep = (deep: number) => {
    return menuMaps[deep][0]?.data?.parent_id ?? undefined
  }

  const createMainMenu = () => {
    const data = {
      title: `${currentStore?.name} Menüleri`,
      status: 1,
      store_id: currentStore?.store_id,
      top_id: 0,
      position: 'root',
      seourl: '-',
      top: 0,
      show_top_menu: 1,
    }

    createMenuRequest(data, [], ['store.mainMenu']).then((res) => {
      setStores((stores) => {
        Object.keys(stores).forEach((key) => {
          if (stores[Number(key)].store_id === res.data.data.store.store_id) {
            stores[Number(key)] = res.data.data.store
            return
          }
        })
        return stores
      })

      selectCurrentStore(res.data.data.store.store_id)
    })
  }

  const updateMenuData = (data: any, deep: number) => {
    const _data = {
      id: data.menu_id,
      name: data.title,
      count: data.sub_menus_count ?? 0,
      position: data.position,
      show_top_menu: data.show_top_menu,
      deep,
      data,
    } as menuMapDataType

    setMenuMaps((menuMaps) => {
      const newMenuMap = menuMaps[deep].map((item) => {
        if (item.id === _data.id) {
          return _data
        }

        return item
      })

      return {...menuMaps, [deep]: newMenuMap}
    })
  }

  const deleteMenu = (menuId: number, deep: number, data: menuMapDataType, removeTop: boolean) => {
    const messageKey = 'delete-menu'
    message.loading({content: 'Menü siliniyor...', key: messageKey})

    deleteMenuRequest(menuId, removeTop)
      .then(() => {
        let parentId = undefined
        if (data && data?.data?.parent_id) {
          parentId = data.data.parent_id
        }

        if (deep - 1 >= 0) {
          getMenus && getMenus(deep - 1, getParentIdByDeep(deep - 1))
        }

        getMenus && getMenus(deep, parentId)

        message.success({content: 'Menü başarıyla silindi', key: messageKey, duration: 2})
      })
      .catch((e) => {
        errorMessage(e)
        message.error({
          content: 'Menü silinemedi. Bir hata oluştu lütfen tekrar deneyiniz',
          key: messageKey,
          duration: 2,
        })
        message.error({content: e.response.data.message, key: messageKey, duration: 4})
      })
      .finally(() => {
        setIsShowDeleteModal(false)
      })
  }

  const updateGroupTitle = (data: string, deep: number) => {
    setGroupTitle((groupTitle) => ({...groupTitle, [deep]: data}))
  }

  const updateSelectedMenu = (data: menuDataType, deep: number) => {
    setSelectedMenu((selectedMenu) => ({...selectedMenu, [deep]: data}))
  }

  useEffect(() => {
    getStores()
  }, [])

  useEffect(() => {
    getMenus(0)
  }, [currentStore])

  return (
    <MenuMapContext.Provider
      value={{
        stores,
        options,
        visibleCreateModal,
        columns,
        menuMaps,
        groupTitle,
        selectedMenu,
        currentStore,
        willBeUpdateData,
        modalConfig,
        getMenus,
        getStores,
        deleteMenu,
        createMainMenu,
        updateMenuData,
        setMenuMapsData,
        updateGroupTitle,
        updateSelectedMenu,
        setWillBeUpdateData,
        selectCurrentStore,
        setVisibleCreateModal,
        openMenuModal,
        setModalConfig,
        getParentIdByDeep,
        isLoading,
        isShowDeleteModal,
        setIsShowDeleteModal,
        willBeRemovedMenuData,
        setWillBeRemovedMenuData,
        activeTabKey,
        setActiveTabKey,
      }}
    >
      {children}
    </MenuMapContext.Provider>
  )
}
