import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {
  checkIBMCampaignsExportRequest,
  exportIBMCampaignsRequest,
} from '../../../requests/ibm/IBMCampaignRequest'

export const useIBMCampaignExport = () => {
  const navigate = useNavigate()
  const [downloadExcelUrl, setDownloadExcelUrl] = useState<any>(null)
  const urlParams = window.location.search.substring(1)
  const ibmGroupId = urlParams.split('group_id=')[1].split('&')[0]
  const exportIBMCampaigns = () => {
    exportIBMCampaignsRequest(Number(ibmGroupId))
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkIBMCampaignExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  const checkIBMCampaignExport = (filename: string) => {
    const urlParams = window.location.search.substring(1)
    const ibmGroupId = urlParams.split('group_id=')[1]
    checkIBMCampaignsExportRequest(Number(ibmGroupId), filename).then((response) => {
      if (!response.data.data.is_exists) {
        setTimeout(() => {
          checkIBMCampaignExport(filename)
        }, 1000)
        return
      }
      setDownloadExcelUrl(response.data.data.download_excel_url)
    })
  }

  return {
    exportIBMCampaigns,
    downloadExcelUrl,
  }
}
