import {message} from 'antd'
import * as querystring from 'query-string'
import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {
  exportSortingWeightListRequest,
  getSortingTypesRequest,
  getSortingWeightLogsRequest,
} from '../../../requests/sorting/SortingWeightRequest'
import {viewDateFormat} from '../../../service/DateService'
import {KrcEmptyFilter} from '../../atoms/KrcEmptyFilter'
import {paginationMetaType} from '../../models/GeneralModel'
import {sortingWeightOrderType} from '../../models/Sorting/SortingModel'
import {useExport} from '../exports/useExport'

const initialOrderTypes: sortingWeightOrderType[] = [
  {
    label: '',
    value: '',
  },
]

export const initialMetaData = {limit: 10, page: 1}

export const useSortingWeight = () => {
  const navigate = useNavigate()
  const {checkExport, downloadExcelUrl} = useExport()
  const [orderTypes, setOrderTypes] = useState<sortingWeightOrderType[]>(initialOrderTypes)

  const [sortingWeightLogs, setSortingWeightLogs] = useState<any[]>([])
  const [sortingWeightLogsMeta, setSortingWeightLogsMeta] =
    useState<paginationMetaType>(initialMetaData)

  const initialRef = useRef(0)

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (sortingWeightLogsMeta.page !== 1) {
      setSortingWeightLogsMeta((data) => ({...data, page: 1}))
      return
    }
    setSortingWeightLogsData()
  }, [sortingWeightLogsMeta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    setSortingWeightLogsData()
  }, [sortingWeightLogsMeta.page])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  const exportSortingWeightList = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: true,
    })
    exportSortingWeightListRequest(params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }
        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  const getOrderTypes = () => {
    getSortingTypesRequest()
      .then((res) => {
        if (!res.data) {
          return
        }
        setOrderTypes(res.data.data)
      })
      .catch((err) => {
        message.error('Sıralama tipleri getirilirken bir hata oluştu')
        message.error(err.response.data.message)
      })
  }

  const setSortingWeightLogsData = () => {
    getSortingWeightLogsRequest(sortingWeightLogsMeta)
      .then((res) => {
        setSortingWeightLogs(res.data.data)
        const _meta = res.data.meta
        setSortingWeightLogsMeta({..._meta, limit: _meta.per_page, page: _meta.current_page})
      })
      .catch((err) => {
        message.error('Sıralama ağırlıkları getirilirken bir hata oluştu')
        message.error(err.response.data.message)
      })
  }

  const logsColumns = React.useMemo(() => {
    return [
      {
        Header: 'Sıralama Türü',
        accessor: 'sorting_type',
        maxWidth: 100,
        manualWidth: 150,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'İşlem Türü',
        accessor: 'action_text',
        maxWidth: 100,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Değişiklikler',
        accessor: 'data_text',
        maxWidth: 150,
        manualWidth: 150,
        Cell: ({row}: any) => (
          <div dangerouslySetInnerHTML={{__html: row.original.data_text}}></div>
        ),
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'İşlemi Gerçekleşiren \n Kullanıcı Adı',
        accessor: 'user_name',
        maxWidth: 150,
        manualWidth: 150,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Açıklama',
        accessor: 'message',
        Cell: ({row}: any) => <div dangerouslySetInnerHTML={{__html: row.original.message}}></div>,
        maxWidth: 250,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Gerçekleşme Tarihi',
        accessor: 'created_at',
        maxWidth: 100,
        Cell: ({row}: any) => viewDateFormat(row.original.created_at),
        Filter: <KrcEmptyFilter />,
      },
    ]
  }, [])

  return {
    exportSortingWeightList,
    downloadExcelUrl,
    orderTypes,
    sortingWeightLogs,
    sortingWeightLogsMeta,
    logsColumns,
    getOrderTypes,
    setSortingWeightLogsData,
    setSortingWeightLogsMeta,
  }
}
