import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {GameContext, GameProvider} from '../../../providers/games/GameProvider'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {GameList} from './GameList'

const GameListWrapperToolbar: FC = () => {
  const {setGameDrawerVisible} = useContext(GameContext)

  return (
    <>
      <div className='d-flex align-items-center py-1'>
        <KrcButton
          size={'middle'}
          onClick={() => {
            setGameDrawerVisible(true)
          }}
          id='kt_toolbar_primary_button'
        >
          {'Oyun Oluştur'}
        </KrcButton>
      </div>
    </>
  )
}

export const GameListWrapperWithoutProvider = () => {
  return (
    <>
      <Toolbar rightField={<GameListWrapperToolbar />}></Toolbar>
      <PageTitle>Karaca Oyun Listesi</PageTitle>
      <GameList />
    </>
  )
}

export const GameListWrapper = () => {
  return (
    <>
      <GameProvider>
        <GameListWrapperWithoutProvider />
      </GameProvider>
    </>
  )
}
