import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, Select} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import _ from 'lodash'
import React, {FC, useContext, useEffect, useState} from 'react'

import {ADDABLE_STORES, ALL} from '../../../../../enums/PassiveProductStoreIdEnum'
import {PassiveProductContext} from '../../../../../providers/PassiveProductProvider'
import {KrcButton} from '../../../../atoms/buttons/KrcButton'
import {KrcSelect} from '../../../../atoms/forms/KrcSelect'
import {useAddProductToPassiveProduct} from '../../../../hooks/products/useAddProductToPassiveProduct'
import {ProductSelection} from '../../../../layout/filters/ProductSelection'
import {PassiveProductReceivingProductsTable} from '../../../../layout/products/passive/PassiveProductReceivingProductsTable'
import {
  IPassiveProductConfig,
  passiveProductModalDataType,
  passiveProductTableDataType,
  willBePassiveProductType,
} from '../../../../models/products/passives/PassiveProductModel'

const {confirm} = Modal
const {Option} = Select
const passiveProductStoreIdsOptions = ADDABLE_STORES.map((store) => {
  return {
    label: store.store_id_text,
    value: store.store_id,
  }
})

const PassiveProductConfig: FC<IPassiveProductConfig> = ({
  updatePassiveProduct,
  passiveProducts,
}) => {
  const [storeIds, setStoreIds] = useState([ALL])
  const [tempPassiveProducts, setTempPassiveProducts] = useState<passiveProductModalDataType[]>()
  const [comment, setComment] = useState<string>()
  const [selectedItems, setSelectedItems] = useState<any[]>()

  useEffect(() => {
    if (!passiveProducts) {
      return
    }

    const _passiveProduct = passiveProducts.map((item: passiveProductTableDataType) => {
      return {
        ...item,
        comment: '',
        selectedStoreIds: [ALL],
      }
    })
    setTempPassiveProducts(_passiveProduct)
    setSelectedItems(_passiveProduct)
  }, [passiveProducts])

  useEffect(() => {
    if (!selectedItems || selectedItems.length > 0) {
    }
    const comments = _.uniq(selectedItems?.map((item: passiveProductModalDataType) => item.comment))
    const storeIds = _.uniq(
      selectedItems?.map((item: passiveProductModalDataType) => item.selectedStoreIds)
    )
    let _storeIds = storeIds.length === 1 ? storeIds[0] : [ALL]
    let _comment = comments.length === 1 ? comments[0] : ''

    setComment(_comment)
    setStoreIds(_storeIds)
  }, [selectedItems])

  useEffect(() => {
    if (!tempPassiveProducts) {
      return
    }

    updatePassiveProduct(tempPassiveProducts)
  }, [tempPassiveProducts])

  const updateTempPassiveProduct = () => {
    if (!tempPassiveProducts) {
      return
    }

    setTempPassiveProducts((data) => {
      if (!data) {
        return data
      }

      return data?.map((item: passiveProductModalDataType) => {
        const isMatch = selectedItems?.some(
          (selectedItem: passiveProductModalDataType) => selectedItem.id === item.id
        )

        return isMatch ? {...item, comment: comment, selectedStoreIds: storeIds} : item
      })
    })

    setSelectedItems([])
    setComment('')
  }

  return (
    <>
      {tempPassiveProducts && tempPassiveProducts.length > 0 && (
        <Select
          value={
            selectedItems ? selectedItems?.map((item: willBePassiveProductType) => item.id) : []
          }
          mode={'multiple'}
          style={{width: '100%'}}
          className={'mb-3'}
          labelInValue={false}
          onChange={(data: number[]) => {
            if (!tempPassiveProducts) {
              return
            }
            setSelectedItems(
              tempPassiveProducts.filter((product: passiveProductModalDataType) => {
                return data.some((item) => item === product.id)
              })
            )
          }}
          allowClear={true}
        >
          {tempPassiveProducts.map((item, index: number) => {
            return (
              <Option value={item.id} label={item.name} key={`passive-product-${index}`}>
                <div className={'d-flex justify-content-between align-items-center'}>
                  <div>{item.name}</div>
                  {item.comment && (
                    <i
                      className={`fa fa-comment ${
                        selectedItems?.some((selectedItem) => selectedItem.id === item.id)
                          ? 'mr-3'
                          : 'mr-5'
                      } ml-1`}
                    />
                  )}
                </div>
              </Option>
            )
          })}
        </Select>
      )}
      <TextArea
        value={comment}
        rows={3}
        placeholder={'Pasif ürün olarak ekleyeceğiniz ürünler için yorum girmek ister misiniz?'}
        onChange={(e) => {
          setComment(e.target.value)
        }}
      />
      <div className={'d-flex flex-column mt-3'}>
        <label className={'mb-1'}>
          <span className={'required'}>Pasife Alınacak Mağazalar</span>
        </label>
        <KrcSelect
          value={storeIds}
          mode={'multiple'}
          size={'middle'}
          placeholder={'STORE ID'}
          filterOption={false}
          defaultValue={[ALL]}
          onChange={(data) => {
            if (data.length > 0) {
              setStoreIds(data)
              return
            }
            setStoreIds([ALL])
          }}
          options={passiveProductStoreIdsOptions}
          showArrow={true}
          style={{maxWidth: '50%'}}
          dropdownStyle={{zIndex: 9999}}
          allowClear={false}
        />
      </div>
      <div className={'d-flex justify-content-end mt-3'}>
        <KrcButton onClick={updateTempPassiveProduct}>Kaydet</KrcButton>
      </div>
    </>
  )
}

export const AddProductsToPassiveProductsWrapper: FC = () => {
  const {
    save,
    tempTransferData,
    includedProductIds,
    setSave,
    setTempTransferData,
    updatePassiveProduct,
    handleOnSavePassiveProduct,
    setIncludedProductIds,
    dataNotYetTransferred,
    setDataNotYetTransferred,
  } = useAddProductToPassiveProduct()

  useEffect(() => {
    if (!tempTransferData) {
      return
    }

    handleOnSavePassiveProduct()
  }, [tempTransferData])

  const {willBeActiveProducts, willBePassiveProducts} = useContext(PassiveProductContext)

  return (
    <div className={'row'}>
      <div className={'col-7'} style={{paddingLeft: 50, paddingRight: 50}}>
        <PassiveProductReceivingProductsTable
          onActive={(data: any) => {
            setIncludedProductIds((item) => [...item, ...data.map((_item: any) => _item.productId)])
          }}
        />
      </div>
      <div className={'col-5'} style={{paddingRight: 50, paddingLeft: 50}}>
        <ProductSelection
          onTransfer={(data) => {
            setDataNotYetTransferred([])
            if (data.length === 0) {
              return
            }
            setTempTransferData(data)
          }}
          onSelectedItems={(data) => {
            setDataNotYetTransferred(data)
          }}
          includedProductIds={includedProductIds}
          removeAllExcluded={save}
          transferProps={{
            button: {
              isActive: true,
              title: '<< Pasif Ürün Olarak Ekle',
            },
            config: {
              confirmActive: true,
              confirm: {
                title: `Seçilen ürünler pasif olarak eklenecek.`,
                icon: false,
                width: 600,
                content: (
                  <PassiveProductConfig
                    updatePassiveProduct={updatePassiveProduct}
                    passiveProducts={dataNotYetTransferred}
                  />
                ),
                okText: 'Tamam',
                cancelText: 'İptal',
              },
            },
          }}
        />
      </div>
      <div className={'d-flex justify-content-end w-full'}>
        <div
          className={'btn btn-primary mt-3'}
          role={'button'}
          onClick={() => {
            confirm({
              title: `Yapılan tüm değişiklikler kaydedilecek emin misin ?`,
              icon: <ExclamationCircleOutlined />,
              content: (
                <>
                  {willBePassiveProducts && willBePassiveProducts?.length > 0 ? (
                    <div>
                      <b>{willBePassiveProducts?.length} Ürün pasif hale getirilecek</b>
                    </div>
                  ) : null}
                  {willBeActiveProducts && willBeActiveProducts?.length > 0 ? (
                    <div>
                      <b>{willBeActiveProducts?.length} Ürün aktif hale getirilecek</b>
                    </div>
                  ) : null}
                  <span>
                    Yapılan tüm değişiklikler kayıt edilip yayınalacaktır geri dönüşü olmayabilir
                    emin misiniz?
                  </span>
                </>
              ),
              okText: 'Tamam',
              cancelText: 'İptal',
              onOk() {
                setSave((data) => data + 1)
              },
            })
          }}
        >
          Kaydet ve Yayınla
        </div>
      </div>
    </div>
  )
}
