import {FC} from 'react'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {useInformationSubTopicUpdateForm} from '../../../../hooks/information/useInformationSubTopicUpdateForm'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'
import {InformationSubTopicUpdateForm} from './InformationSubTopicUpdateForm'

export const InformationSubTopicUpdateFormWrapper: FC = () => {
  const {informationSubTopic} = useInformationSubTopicUpdateForm()
  return (
    <>
      <Toolbar />
      {informationSubTopic.title && (
        <>
          <PageTitle>Güncelle - {informationSubTopic.title} </PageTitle>
        </>
      )}
      <InformationSubTopicUpdateForm />
    </>
  )
}
