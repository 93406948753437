import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {Badge, Popconfirm, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {createContext, useEffect, useRef, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {Column} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {KrcInput} from '../../components/atoms/forms/KrcInput'
import {KrcSelect} from '../../components/atoms/forms/KrcSelect'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {removeEmptyItemInObject} from '../../helpers/ObjectHelper'
import {
  fetchInformationSubTopicRequest,
  removeInformationSubTopicRequest,
} from '../../requests/information/InformationRequest'

export const initialMetaData = {limit: 10, page: 1}

type InformationUrlParams = {
  informationId: string
}
interface IInformationSubTopicContext {
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  selectedSubTopicTitle: string
  setSelectedSubTopicTitle: (title: string) => void
  columns: Column<any>[]
  topics: any[]
  setTopics: (topics: any[]) => void
  filter: any
  setFilter: React.Dispatch<React.SetStateAction<any>>
  handleOnFilter: (data: any, key: string | number) => void
  selectedStoreId: string
  informationId: string | undefined
}

export const InformationSubTopicContext = createContext<IInformationSubTopicContext>({
  meta: initialMetaData,
  setMeta: () => {},
  selectedSubTopicTitle: '',
  setSelectedSubTopicTitle: () => {},
  columns: [],
  topics: [],
  setTopics: () => {},
  filter: {},
  setFilter: () => {},
  handleOnFilter: () => {},
  selectedStoreId: '',
  informationId: '',
})

export const InformationSubTopicProvider = ({children}: {children: React.ReactNode}) => {
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [topics, setTopics] = useState<any[]>([])
  const [filter, setFilter] = useState<any>({})
  const [selectedSubTopicTitle, setSelectedSubTopicTitle] = useState<string>('')
  const fetchRef = useRef(0)
  const initialRef = useRef(0)

  const {informationId} = useParams<InformationUrlParams>()

  const search = window.location.search
  const params = new URLSearchParams(search)
  const selectedStoreId = params.get('store') || ''

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'information_id',
        manualWidth: 60,
        maxWidth: 60,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Bilgi Başlığı',
        accessor: 'description.title',
        manualWidth: 800,
        maxWidth: 800,
        Filter: () => {
          return (
            <KrcInput
              placeholder={'Arama'}
              onChange={(e) => {
                if (initialRef.current === 0) {
                  return
                }
                handleOnFilter(e.target.value, 'title')
              }}
            />
          )
        },
      },
      {
        Header: 'Durum',
        accessor: 'status',
        manualWidth: 200,
        maxWidth: 200,
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.status ? (
                <div>
                  Aktif <Badge className={'ml-2'} color='green' />
                </div>
              ) : (
                <div>
                  Pasif <Badge className={'ml-2'} color='red' />
                </div>
              )}
            </>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Seçiniz'}
              options={[
                {value: 1, label: 'Aktif'},
                {value: 2, label: 'Pasif'},
              ]}
              maxTagCount={'responsive'}
              className={'ant-selector-border w-100'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }
                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'status')
              }}
            />
          )
        },
      },
      {
        Header: 'Sıra',
        accessor: 'sort_order',
        manualWidth: 130,
        maxWidth: 130,
        Filter: () => {
          return (
            <KrcInput
              type={'number'}
              placeholder={'Sıra'}
              min={0}
              onChange={(e) => {
                if (initialRef.current === 0) {
                  return
                }
                handleOnFilter(e.target.value, 'sort_order')
              }}
            />
          )
        },
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        manualWidth: 200,
        maxWidth: 200,
        Filter: <KrcEmptyFilter />,
        Cell: ({row}: any) => {
          return (
            <div className='d-flex justify-content-start gap-2'>
              <div>
                <Tooltip title={'Güncelle'}>
                  <Link
                    to={`/information/${informationId}/update/${row.original.information_id}?store=${selectedStoreId}`}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 d-flex justify-content-center align-items-center'
                    style={{color: 'unset'}}
                  >
                    <EditOutlined />
                  </Link>
                </Tooltip>
              </div>
              <div>
                <Tooltip title={'Sil'}>
                  <Popconfirm
                    title='Bu konuyu silmek istediğinden emin misiniz?'
                    onConfirm={() => removeSpecificInformationSubTopic(row.original.information_id)}
                    okText='Evet'
                    cancelText='Hayır'
                  >
                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                      <DeleteOutlined />
                    </div>
                  </Popconfirm>
                </Tooltip>
              </div>
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    if (!setMeta) {
      return
    }

    setMeta(meta)
  }, [meta.to, meta.from, meta.last_page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (!setMeta) {
      return
    }

    setMeta(meta)

    getInformationSubTopics()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getInformationSubTopics()
  }, [meta.limit])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  useEffect(() => {
    if (!meta) {
      return
    }

    setMeta(meta)
  }, [meta])

  useEffect(() => {
    if (!topics) {
      return
    }

    setTopics(topics)
  }, [topics])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getInformationSubTopics()
  }, [filter])

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  const getInformationSubTopics = (page: null | number = null) => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    let _meta = meta
    const _filter = removeEmptyItemInObject(filter)
    if (page) {
      _meta.page = page
    }
    fetchInformationSubTopicRequest(Number(informationId), Number(selectedStoreId), _filter, _meta)
      .then((response) => {
        if (fetchId !== fetchRef.current) {
          return
        }
        setTopics(response.data.data)
        if (response.data.data.length !== 0) {
          setSelectedSubTopicTitle(response.data.data[0].parent_title || '')
        }
        const metaData = response.data.meta
        setMeta({limit: metaData.per_page, page: metaData.current_page, ...metaData})
      })
      .catch((error) => {
        setTopics([])
        message.error('Bilgi başlıkları getirilirken bir hata oluştu')
      })
  }

  const removeSpecificInformationSubTopic = (subTopicId: number) => {
    removeInformationSubTopicRequest(
      Number(informationId),
      Number(subTopicId),
      Number(selectedStoreId)
    )
      .then(() => {
        message.success('Bilgi Sayfası Silindi.')
        getInformationSubTopics()
      })
      .catch((error) => {
        message.error('Bilgi Sayfası Silinirken bir hata oluştu.')
      })
  }

  return (
    <InformationSubTopicContext.Provider
      value={{
        meta,
        setMeta,
        columns,
        topics,
        setTopics,
        filter,
        setFilter,
        handleOnFilter,
        selectedSubTopicTitle,
        setSelectedSubTopicTitle,
        selectedStoreId,
        informationId,
      }}
    >
      {children}
    </InformationSubTopicContext.Provider>
  )
}
