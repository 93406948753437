import * as querystring from 'query-string'
import {useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {
  campaignSalesProductsExportRequest,
  categoryDetailPendingApprovalProductExportRequest,
  categoryHistoryListApprovalProductExportRequest,
  categoryListApprovalProductExportRequest,
} from '../../../../requests/categories/products/exports/CategoryProductExportRequest'
import {categoryUrlType} from '../../../models/categories/CategoryProductModel'
import {useExport} from '../../exports/useExport'

export const useCampaign = () => {
  const navigate = useNavigate()
  const {categoryId} = useParams<categoryUrlType>()
  const [exportErrorMessage, setExportErrorMessage] = useState<string>()
  const {checkExport, downloadExcelUrl} = useExport()

  const exportCategoryDetailPendingApprovalProducts = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: true,
    })
    categoryDetailPendingApprovalProductExportRequest(Number(categoryId), params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
        if (err.response.status === 500) {
          setExportErrorMessage(err.response.data.message)
        }
      })
  }

  const exportCategoryHistoryListApprovalProducts = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: true,
    })

    categoryHistoryListApprovalProductExportRequest(params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  const exportCategoryListApprovalProducts = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: true,
    })

    categoryListApprovalProductExportRequest(params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  const exportCampaignSalesProducts = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: true,
    })

    campaignSalesProductsExportRequest(params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  return {
    exportCategoryDetailPendingApprovalProducts,
    exportCategoryHistoryListApprovalProducts,
    exportCategoryListApprovalProducts,
    exportCampaignSalesProducts,
    downloadExcelUrl,
    exportErrorMessage,
  }
}
