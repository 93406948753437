import React, {FC, useEffect} from 'react'

import {KrcDatePicker} from '../../atoms/forms/KrcDatePicker'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useDateConstraint} from '../../hooks/components/useDateConstraint'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {IDateConstraint} from '../../models/components/DateConstraintModel'

const selectConfig = {
  default: {
    placeholder: 'Tarih Kısıtı',
    style: {borderRadius: 5},
    options: [],
    className: 'date-constraint-select',
  },
}

const leftDatePickerConfig = {
  default: {
    placeholder: 'Başlangıç Tarihi',
    className: 'date-constraint-date',
    style: {width: 200},
    format: 'DD.MM.YYYY dddd',
  },
}

const rightDatePickerConfig = {
  default: {
    placeholder: 'Bitiş Tarihi',
    className: 'date-constraint-date',
    style: {width: 200},
    format: 'DD.MM.YYYY dddd',
  },
}

export const DateConstraint: FC<IDateConstraint> = ({
  onChange,
  className = 'd-flex gap-3',
  isSelect = true,
  selectProps,
  leftDatePickerProps,
  rightDatePickerProps,
  clear,
}) => {
  const {handleSelectOnChange, handleStartDateOnChange, handleEndDateOnChange, data} =
    useDateConstraint()
  const currentSelectProps = useKrcProp({
    config: selectConfig.default,
    props: selectProps,
  }).getCurrentProps()
  const currentLeftDatePicker = useKrcProp({
    config: leftDatePickerConfig.default,
    props: leftDatePickerProps,
  }).getCurrentProps()
  const currentRightDatePicker = useKrcProp({
    config: rightDatePickerConfig.default,
    props: rightDatePickerProps,
  }).getCurrentProps()

  useEffect(() => {
    if (!onChange) {
      return
    }

    onChange(data)
  }, [data])

  return (
    <>
      <div className={className}>
        {isSelect && (
          <KrcSelect clear={clear} {...currentSelectProps} onChange={handleSelectOnChange} />
        )}
        <KrcDatePicker
          clear={clear}
          {...currentLeftDatePicker}
          onChanged={handleStartDateOnChange}
        />
        <KrcDatePicker
          clear={clear}
          {...currentRightDatePicker}
          onChanged={handleEndDateOnChange}
        />
      </div>
    </>
  )
}
