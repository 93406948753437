import {message} from 'antd'
import {useParams} from 'react-router-dom'

import {createInformationSubTopicRequest} from '../../../requests/information/InformationRequest'

type InformationUrlParams = {
  topicId: string
}

export const useInformationSubTopicCreateForm = () => {
  const {topicId} = useParams<InformationUrlParams>()
  const search = window.location.search
  const params = new URLSearchParams(search)
  const selectedStoreId = params.get('store') || ''

  const createInformationSubTopic = (data: any) => {
    createInformationSubTopicRequest(Number(topicId), data)
      .then(() => {
        message.success('Başlık başarıyla oluşturuldu.')
      })
      .catch((error) => {
        message.error('Başlık oluşturulurken hata oluştu.')
      })
  }

  return {
    createInformationSubTopic,
    topicId,
    selectedStoreId,
  }
}
