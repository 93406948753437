import {message} from 'antd'
import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {FC, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'

import {otpVerification} from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'

const loginSchema = Yup.object().shape({
  code: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(6, 'Maximum 6 symbols')
    .required('otp is required'),
})
const initialValues = {
  code: '',
}
export const OtpVerification: FC = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const {email} = useSelector((state: any) => state.auth)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        otpVerification(email, values.code)
          .then(({data: {access_token}}) => {
            setLoading(false)
            dispatch(auth.actions.login(access_token))
          })
          .catch((err) => {
            let errorMessage = err.response?.data?.error?.message
            if (!errorMessage) {
              errorMessage = 'Bir sorun oluştu. Lütfen bilgilerinizi doğru girdiğinizden emin olun.'
            }
            message.error(errorMessage)
            setLoading(false)
            setSubmitting(false)
            setStatus('The otp detail is incorrect')
          })
      }, 1000)
    },
  })
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>OTP KOD</label>
        <input
          placeholder='Code'
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.code && formik.errors.code},
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
          type='text'
          name='code'
          autoComplete='off'
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.code}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
