import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''

export const FAQ_URL = `${API_URL}/faq/topics`

export function getFaqTopicsRequest(
  filter: any,
  {page, limit}: paginationMetaType,
  topicId: number,
  storeId: number
) {
  return axios.get(`${FAQ_URL}/${topicId}`, {
    params: {
      store_id: storeId,
      page,
      limit,
      ...filter,
    },
  })
}

export function deleteFaqTopicRequest(topicId: number, storeId: number) {
  return axios.delete(`${FAQ_URL}/${topicId}`, {
    params: {
      store_id: storeId,
    },
  })
}
