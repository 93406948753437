import React, {FC, useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  CategoryDetailContext,
  CategoryDetailProvider,
} from '../../../../providers/category/detail/CategoryDetailProvider'
import {CategoryTypeEnum} from '../../../models/categories/CategoryModel'
import {categoryUrlType} from '../../../models/categories/CategoryProductModel'
import {KrcTabs} from '../../../molecules/KrcTabs'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {CategoryBannerListWrapper} from './CategoryBannerListWrapper'
import {CategoryDesc} from './CategoryDesc'
import {CategoryInformation} from './CategoryInformation'

interface ICategoryDetail {
  type: 'create' | 'update'
}

const CategoryDetailWithProvider: FC<ICategoryDetail> = ({type}) => {
  const {informationIsChanged, information, descIsChanged} = useContext(CategoryDetailContext)
  const {categoryId} = useParams<categoryUrlType>()
  const [pageTitle, setPageTitle] = useState('Kategori')

  useEffect(() => {
    if (information.category_type?.value === CategoryTypeEnum.GHOST) {
      if (categoryId) {
        setPageTitle(`Ghost Kategori - ${information.name}`)
        return
      }

      setPageTitle('Ghost Kategori')
      return
    }

    if (categoryId) {
      setPageTitle(`Kategori - ${information.name}`)
    }
  }, [information.category_type])

  return (
    <>
      <PageTitle> {pageTitle} </PageTitle>
      <Toolbar></Toolbar>
      <KrcTabs
        isActiveKey={true}
        tabsProps={{defaultActiveKey: 'information'}}
        tabProps={[
          {
            eventKey: 'information',
            title: 'Genel Bilgiler',
            isChanged: informationIsChanged,
            children: <CategoryInformation />,
          },
          {
            eventKey: 'description',
            title: 'Açıklama',
            isChanged: descIsChanged,
            disabled: type === 'create',
            children: <CategoryDesc />,
          },
          {
            eventKey: 'banner',
            title: 'Banner',
            isChanged: false,
            disabled: false,
            children: <CategoryBannerListWrapper pageTitle={pageTitle} />,
          },
          // {
          //   eventKey: 'category_banner',
          //   title: 'Kategori Banner',
          //   // isChanged: false,
          //   disabled: type === 'create',
          //   children: <CategoryGhostBanner />,
          // },
        ]}
      />
    </>
  )
}

export const CategoryDetailWrapper: FC<ICategoryDetail> = ({type}) => {
  return (
    <>
      <CategoryDetailProvider>
        <CategoryDetailWithProvider type={type} />
      </CategoryDetailProvider>
    </>
  )
}
