import {Spin} from 'antd'
import React, {FC, useEffect} from 'react'

import {KrcAutoComplete} from '../../atoms/forms/KrcAutoComplete'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useProductFilter} from '../../hooks/products/useProductFilter'
import {IProductFilter} from '../../models/products/ProductFilterModel'

const productFilterConfig = {
  default: {
    className: 'form-control',
    size: 'middle',
    style: {width: '100%'},
  },
}

const spinConfig = {
  default: {
    style: {position: 'absolute', right: 10, top: 17},
  },
}

const containerConfig = {
  default: {
    style: {position: 'relative'},
  },
}

export const ProductFilter: FC<IProductFilter> = ({
  autoCompleteProps,
  spinProps,
  containerProps,
  searchType = 'name',
  label = 'default',
  onChanged,
  initialName,
}) => {
  const currentAutoCompleteProps = useKrcProp({
    config: productFilterConfig.default,
    props: autoCompleteProps,
  }).getCurrentProps()
  const currentSpinProps = useKrcProp({
    config: spinConfig.default,
    props: spinProps,
  }).getCurrentProps()
  const currentContainerProps = useKrcProp({
    config: containerConfig.default,
    props: containerProps,
  }).getCurrentProps()
  const {handleOnChange, handleOnSearch, options, spinning, value} = useProductFilter({
    searchType,
    label,
    initialName,
  })

  useEffect(() => {
    if (!onChanged) {
      return
    }

    onChanged(value)
  }, [value])

  return (
    <>
      <div {...currentContainerProps}>
        <KrcAutoComplete
          {...currentAutoCompleteProps}
          onSearch={handleOnSearch}
          onChange={handleOnChange}
          options={options}
          value={value}
          allowClear
        />
        <Spin spinning={spinning} {...currentSpinProps} />
      </div>
    </>
  )
}
