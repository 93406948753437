import {Alert, Button, Empty} from 'antd'
import React, {FC, memo, useContext} from 'react'

import {MAX_DEEP_LIMIT} from '../../../../enums/MenuMapEnum'
import {ModalEnum} from '../../../../enums/ModalEnum'
import {MenuMapContext} from '../../../../providers/tables/MenuMapProvider'
import {MenuMap} from '../../../layout/menus/MenuMap'
import {DeleteMenuModal} from '../../../modals/menus/DeleteMenuModal'
import {MenuModal} from '../../../modals/menus/MenuModal'
import {Loader} from '../../../molecules/Loader'

const MenuModalMemo = memo(MenuModal)

export const MenuListing: FC = () => {
  const {
    menuMaps,
    groupTitle,
    updateGroupTitle,
    getMenus,
    updateSelectedMenu,
    createMainMenu,
    currentStore,
    selectedMenu,
    willBeUpdateData,
    setWillBeUpdateData,
    visibleCreateModal,
    setVisibleCreateModal,
    modalConfig,
    isLoading,
    setModalConfig,
    isShowDeleteModal,
  } = useContext(MenuMapContext)

  return (
    <>
      {isLoading ? (
        <div className={'d-flex flex-column justify-content-center align-items-center'}>
          <Loader />
          <Alert message='Menüler getiriliyor, lütfen bekleyiniz..' type='info' showIcon />
        </div>
      ) : (
        <div className={'row'}>
          {!currentStore?.main_menu && (
            <>
              <Empty
                image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                imageStyle={{
                  height: 60,
                }}
                description={<span>Mağaza altında bir menü bulunmamaktadır.</span>}
              >
                <Button type='primary' onClick={createMainMenu}>
                  Şimdi Oluştur
                </Button>
              </Empty>
            </>
          )}
          {Object.keys(menuMaps).map((key) => {
            const deep = parseInt(key)

            if (groupTitle[deep] === '') {
              return null
            }

            return (
              <div className={'col-4'} key={`menu-map-${key}`}>
                <MenuMap
                  headerProps={{
                    title: deep === 0 ? 'Menü' : 'Alt Menü',
                    subTitle: {key: 'Üst menü', value: groupTitle[key]},
                  }}
                  data={menuMaps[deep]}
                  parentData={deep - 1 < 0 ? currentStore?.main_menu : selectedMenu[deep - 1]}
                  deep={deep}
                  onSelect={(data: any) => {
                    if (
                      !updateGroupTitle ||
                      !getMenus ||
                      deep + 1 > MAX_DEEP_LIMIT ||
                      !updateSelectedMenu
                    ) {
                      return
                    }

                    getMenus(deep + 1, data.original.data.menu_id)
                    updateGroupTitle(data.original.name, deep + 1)
                  }}
                />
              </div>
            )
          })}
          {visibleCreateModal && modalConfig && (
            <MenuModalMemo
              onHide={() => {
                setModalConfig(null)
                setVisibleCreateModal(false)
              }}
              type={ModalEnum.CREATE}
              deep={modalConfig.deep}
              menuData={
                modalConfig.deep - 1 < 0 && currentStore?.main_menu
                  ? {
                      ...currentStore?.main_menu,
                      top: 1,
                    }
                  : selectedMenu[modalConfig.deep - 1]
              }
            />
          )}
          {willBeUpdateData && modalConfig && (
            <MenuModalMemo
              onHide={() => {
                setModalConfig(null)
                setWillBeUpdateData(null)
              }}
              type={ModalEnum.UPDATE}
              menuData={willBeUpdateData}
              deep={modalConfig.deep}
            />
          )}
        </div>
      )}
      {isShowDeleteModal && <DeleteMenuModal />}
    </>
  )
}
