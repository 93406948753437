export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string, priority: number) {
  let currentUrl = getCurrentUrl(pathname)

  let current = ''

  for (let i = 1; i <= priority; i++) {
    current += `/${currentUrl.split('/')[i]}`
  }

  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}
