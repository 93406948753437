import cn from 'classnames'
import React, {FC, useContext} from 'react'

import {UrlAliasContext} from '../../../providers/UrlAliasProvider'
import {UrlAliasCreateModal} from '../../modals/urls/aliases/UrlAliasCreateModal'
import {UrlAliasUpdateModal} from '../../modals/urls/aliases/UrlAliasUpdateModal'
import {ProductTextAreaFilter} from '../../molecules/filters/ProductTextAreaFilter'
import {TableHeader} from '../../molecules/headers/TableHeader'
import {KrcTable} from '../../organisms/tables/KrcTable'

export const UrlAliasList: FC = () => {
  const {
    columns,
    data,
    handleOnFilter,
    meta,
    changeLimit,
    changePage,
    visibleTextFilter,
    visibleCreateModal,
    setVisibleTextFilter,
    setVisibleCreateModal,
    setFilter,
    willBeUpdatedUrlAlias,
    setWillBeUpdatedUrlAlias,
    getUrlAlias,
  } = useContext(UrlAliasContext)

  return (
    <>
      <TableHeader title={'Url Alias Ürünleri'}>
        <div className={'d-flex'}>
          <div
            className={'text-primary'}
            role={'button'}
            onClick={() => {
              setVisibleTextFilter((data: boolean) => !data)
            }}
          >
            Metin ile filtrele
          </div>
        </div>
      </TableHeader>

      <div className={cn({'d-block': visibleTextFilter}, {'d-none': !visibleTextFilter})}>
        <ProductTextAreaFilter
          onFilter={(data) => {
            setFilter((filter: object) => {
              return {
                ...filter,
                queries: [...(data.extraText ?? []), ...(data?.productIds ?? [])],
                models: data.models,
              }
            })
          }}
          withExtraText={true}
          textAreaProps={{
            placeholder:
              '"Ürün Model numarası" veya "Query" ile toplu arama yapılabilir. \nÖrneğin: \n7126119 \n153.03.01.1091 \ncommon/sepet-firsati',
          }}
        />
      </div>
      <KrcTable
        columns={columns}
        data={data}
        searchableProps={{
          isSearchable: true,
          onSearch: handleOnFilter,
        }}
        paginationProps={{
          isPagination: true,
          page: meta.page,
          limit: meta.limit,
          paginationData: meta,
          onChangePage: changePage,
          onChangeLimit: changeLimit,
        }}
      />
      {willBeUpdatedUrlAlias && (
        <UrlAliasUpdateModal
          urlAlias={willBeUpdatedUrlAlias}
          onSubmit={() => {
            setWillBeUpdatedUrlAlias(undefined)
            getUrlAlias()
          }}
          onHide={() => {
            setWillBeUpdatedUrlAlias(undefined)
          }}
        />
      )}
      {visibleCreateModal && (
        <UrlAliasCreateModal
          onHide={() => {
            setVisibleCreateModal(false)
          }}
          onSubmit={() => {
            setVisibleCreateModal(false)
            getUrlAlias()
          }}
        />
      )}
    </>
  )
}
