import {InboxOutlined} from '@ant-design/icons'
import {Upload} from 'antd'
import type {UploadProps} from 'antd'
import React, {FC} from 'react'

import {useKrcProp} from '../hooks/components/useKrcProp'
import {KrcModal} from './KrcModal'

const {Dragger} = Upload

const config = {
  multiple: false,
}

export const UploadModal: FC<{
  upload?: UploadProps
  title?: string
  desc?: string
  onHide?: (data: boolean) => any
  visible?: boolean
}> = ({
  upload: props,
  title = 'Yüklemek istediğiniz dosyayı seçin veya bu alana sürükleyin',
  desc,
  onHide,
  visible = false,
}) => {
  const currentProps = useKrcProp({config, props}).getCurrentProps()

  return (
    <>
      <KrcModal
        modalProps={{
          backdropClassName: 'm-0',
          show: visible,
          onHide: () => {
            onHide && onHide(false)
          },
        }}
        modalHeaderProps={{
          title: 'Dosya Yükleme',
          className: 'py-3 pb-3 pt-0',
          onHide: () => {
            onHide && onHide(false)
          },
        }}
      >
        <Dragger {...currentProps}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>{title}</p>
          <p className='ant-upload-hint'>{desc}</p>
        </Dragger>
      </KrcModal>
    </>
  )
}
