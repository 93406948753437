import {ExclamationCircleOutlined} from '@ant-design/icons'
import {SyncOutlined} from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'
import React, {FC, useContext, useEffect, useState} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  ProductNewPriceContext,
  ProductNewPriceProvider,
} from '../../../../providers/ProductNewPriceProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcDatePicker} from '../../../atoms/forms/KrcDatePicker'
import {KrcNumberInput} from '../../../atoms/forms/KrcNumberInput'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {ProductSelection} from '../../../layout/filters/ProductSelection'
import {ProductsNewPriceTable} from '../../../layout/products/price/ProductsNewPriceTable'
import {krcSelectOptions} from '../../../models/components/KrcSelectModel'
import {LoadingInTheMiddle} from '../../../molecules/LoadingInTheMiddle'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'

const ToolbarRightField: FC = () => {
  const {
    save,
    setSave,
    getApiQueues,
    willBeUpdatedQueue,
    updateWillBeUpdatedQueue,
    apiQueues,
    apiQueue,
    setApiQueue,
    setApiQueueWithApiQueueId,
  } = useContext(ProductNewPriceContext)
  const [apiQueueOptions, setApiQueueOptions] = useState<krcSelectOptions[]>([])

  useEffect(() => {
    getApiQueues()
  }, [])

  useEffect(() => {
    setApiQueueOptions(
      apiQueues.map((apiQueue) => {
        return {
          value: apiQueue.id,
          label: apiQueue.id.toString(),
          style: {
            color: apiQueue.name === 'Hybris_getPriceProductUpdate_Priority_High' ? 'red' : 'black',
          },
        }
      })
    )
  }, [apiQueues])

  return (
    <>
      <div className='d-flex align-items-center py-1'>
        {apiQueueOptions?.length > 0 ? (
          <div className={'mr-3'}>
            <span className={'mr-1'}>
              <b>Kuyruk:</b>
            </span>
            <KrcSelect
              size={'middle'}
              value={apiQueue?.id}
              options={apiQueueOptions}
              defaultValue={apiQueueOptions[0].value}
              placeholder={'Kuyruk'}
              allowClear={false}
              onChange={(data) => {
                setApiQueue(null)
                setSave(0)
                setTimeout(() => {
                  setApiQueueWithApiQueueId(data)
                }, 100)
              }}
              className={'select-filter-custom'}
            />
          </div>
        ) : undefined}
        <div className={'mr-3'}>
          <span className={'mr-1'}>
            <b>Çalışma Zamanı:</b>
          </span>
          <KrcDatePicker
            value={willBeUpdatedQueue.nextjobtime ? moment(willBeUpdatedQueue.nextjobtime) : null}
            format={'DD.MM.YYYY HH:mm:ss'}
            showNow={true}
            allowClear={false}
            showTime={true}
            onChange={(date) => {
              if (!date) {
                updateWillBeUpdatedQueue({nextjobtime: null})
                return
              }

              updateWillBeUpdatedQueue({
                nextjobtime: date.format('YYYY-MM-DD HH:mm:ss').toString() as string,
              })
            }}
          ></KrcDatePicker>
        </div>
        <div className={'mr-3'}>
          <span className={'mr-1'}>
            <b>Tekrarlanma Zamanı</b> (saniye)<b>:</b>
          </span>
          <KrcNumberInput
            value={willBeUpdatedQueue.jobinterval ?? ''}
            onChange={(jobinterval) => {
              updateWillBeUpdatedQueue({jobinterval: jobinterval as number})
            }}
          />
        </div>
        <KrcButton
          className='d-flex align-items-center'
          size={'middle'}
          onClick={() => {
            setSave(save + 1)
          }}
          icon={<SyncOutlined />}
          id='kt_toolbar_primary_button'
        >
          {'Güncelle'}
        </KrcButton>
      </div>
    </>
  )
}

const ProductNewPriceWrapperWithProvider: FC = () => {
  const {save, apiQueue} = useContext(ProductNewPriceContext)
  const [transferData, setTransferData] = useState<any[]>([])
  const [includedProductIds, setIncludedProductIds] = useState<any[]>([])

  return (
    <>
      {apiQueue ? (
        <>
          <PageTitle>{apiQueue.description}</PageTitle>
          <div className={'mt-2'}>
            <div className={'row'}>
              <div className={'col-6'}>
                <ProductsNewPriceTable
                  transferData={transferData}
                  onDelete={(ids: number[]) => {
                    setIncludedProductIds((data) => _.uniq([...data, ...ids]))
                  }}
                  save={save}
                />
              </div>
              <div className={'col-6'}>
                <ProductSelection
                  onTransfer={(data) => {
                    setTransferData(data)
                  }}
                  includedProductIds={includedProductIds}
                  removeAllExcluded={save}
                  withFullPrice={true}
                  transferProps={{
                    button: {
                      isActive: true,
                      title: '<< Ürünleri Aktar',
                    },
                    config: {
                      confirmActive: true,
                      confirm: {
                        title: `Seçilen ürün(ler) eklenecek emin misiniz?.`,
                        icon: <ExclamationCircleOutlined />,
                        content:
                          'Ekleme işlemi kaydet buttonuna basılana kadar gerçekleşmeyecektir.',
                        okText: 'Tamam',
                        cancelText: 'İptal',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoadingInTheMiddle />
      )}
    </>
  )
}

export const ProductNewPriceWrapper: FC = () => {
  return (
    <>
      <ProductNewPriceProvider>
        <Toolbar rightField={<ToolbarRightField />}></Toolbar>
        <ProductNewPriceWrapperWithProvider />
      </ProductNewPriceProvider>
    </>
  )
}
