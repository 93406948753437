import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
const IBM_CAMPAIGN_URL = `${API_URL}/ibm/campaigns`

export function getIBMCampaignSpecialGroupsExportFrontendUrl(ibmCampaignId: number) {
  return `/ibm/campaign/special-group/export?campaign_id=${ibmCampaignId}`
}

export function exportIBMCampaignSpecialGroupRequest(ibmCampaignId: number) {
  return axios.get(`${IBM_CAMPAIGN_URL}/${ibmCampaignId}/special-groups/export`)
}

export function checkIBMCampaignSpecialGroupExportRequest(ibmCampaignId: number, fileName: string) {
  return axios.get(`${IBM_CAMPAIGN_URL}/${ibmCampaignId}/special-groups/check-export`, {
    params: {
      file_name: fileName,
    },
  })
}

export function getIBMCampaignSpecialGroupProductsExportFrontendUrl(ibmSpecialGroupId: number) {
  return `/ibm/campaign/special-group/products/export?special_group_id=${ibmSpecialGroupId}`
}

export function exportIBMCampaignSpecialGroupProductsRequest(ibmSpecialGroupId: number) {
  return axios.get(`${IBM_CAMPAIGN_URL}/special-groups/${ibmSpecialGroupId}/products/export`)
}

export function checkIBMCampaignSpecialGroupProductsExportRequest(
  ibmSpecialGroupId: number,
  fileName: string
) {
  return axios.get(
    `${IBM_CAMPAIGN_URL}/special-groups/${ibmSpecialGroupId}/products/check-export`,
    {
      params: {
        file_name: fileName,
      },
    }
  )
}
