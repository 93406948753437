import {Tag} from 'antd'
import moment from 'moment/moment'
import {useEffect, useMemo, useState} from 'react'

import {IOrderModel} from '../../../../components/models/order/OrderModel'
import {getProperty} from '../../../../helpers/ArrayHelper'
import {orderSubBlockModel} from '../../../models/order/OrderSubBlockModel'
import {KrcTable} from '../../../organisms/tables/KrcTable'

const Summary: React.FC<IOrderModel> = (props) => {
  const [data, setData] = useState<orderSubBlockModel[]>([])

  useEffect(() => {
    setData([
      {
        key: 1,
        field: 'Sipariş Numarası',
        value: (
          <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>
            {' '}
            #{props.order.orderId}{' '}
          </span>
        ),
      },
      {
        key: 2,
        field: 'Sipariş Durumu',
        value: (
          <Tag
            className='order-status'
            style={{color: 'black'}}
            color={props.order.generalStatus.generalLightColor}
          >
            {props.order.generalStatus.text}
          </Tag>
        ),
      },
      {
        key: 3,
        field: 'Sipariş Tarihi',
        value: moment(props.order.orderDate).format('DD/MM/YYYY, HH:mm'),
      },
      {
        key: 4,
        field: 'Kargo Tarihi',
        value: moment(
          getProperty(props.order.shippingCustomer.customProperties, 'DeliverDate')
        ).format('DD/MM/YYYY'),
      },
      {
        key: 5,
        field: 'Mağaza',
        value: (
          <a href={props.order.orderLocation} target='_blank' rel='noreferrer'>
            {' '}
            {props.order.orderLocation}{' '}
          </a>
        ),
      },
    ])
  }, [props.order])

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'field',
        width: '40%',
      },
      {
        Header: '',
        accessor: 'value',
      },
    ]
  }, [])

  return (
    <div className='block-wrapper radius'>
      <KrcTable
        columns={columns}
        data={data}
        showHeader={false}
        cellClassName='font-weight-normal'
        stylesProps={{style: {margin: 0, padding: 0}, className: ['order-block-table']}}
      />
    </div>
  )
}

export default Summary
