import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {FooterModulesContext} from '../../../providers/FooterModulesProvider'
import {ICreateFooterModuleModal} from '../../models/footer/StoreModulesModel'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'
import {KrcModal} from '../KrcModal'

const initValues = {
  sub_module_title: '',
  sub_module_url: '',
  sub_module_target_blank: 0,
  sub_module_sort: '',
}

export const FooterModuleNewSubModuleModal: FC<ICreateFooterModuleModal> = ({onSubmit, onHide}) => {
  const {createNewSubModuleSchema, createNewSubModuleToParentModule, selectedModuleInformation} =
    useContext(FooterModulesContext)
  const [initialValues, setInitialValues] = useState<any>(initValues)

  useEffect(() => {
    setInitialValues(initValues)
  }, [])

  const handleOnSubmit = (data: FormikValues) => {
    if (!selectedModuleInformation) return
    createNewSubModuleToParentModule(
      {
        sub_module_title: data.sub_module_title,
        parent_module_id: selectedModuleInformation?.module_id,
        sub_module_url: data.sub_module_url,
        sub_module_target_blank: data.sub_module_target_blank,
        sub_module_sort: data.sub_module_sort,
      },
      selectedModuleInformation?.module_id
    )
  }
  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
        }}
        modalHeaderProps={{
          title: `Yeni Başlık Oluştur (${selectedModuleInformation?.module_name})`,
          onHide,
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10'>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={createNewSubModuleSchema}
            validateOnMount={true}
          >
            {() => {
              return (
                <Form className='form mt-6 space-between'>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'sub_module_title'}
                        labelTitle={'Başlık Adı'}
                        required
                        tooltipProps={{
                          status: true,
                          text: 'Başlığın adını giriniz.',
                        }}
                      />
                    </div>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'sub_module_url'}
                        labelTitle={'Başlık Url'}
                        tooltipProps={{
                          status: true,
                          text: 'Başlığın url adresini giriniz.',
                        }}
                      />
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'number'}
                        name={'sub_module_sort'}
                        labelTitle={'Başlık Sıralaması'}
                        required
                        tooltipProps={{
                          status: true,
                          text: 'Başlığın sıralamasını giriniz.',
                        }}
                      />
                    </div>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'w-100'}
                        name={'sub_module_target_blank'}
                        labelTitle={'Target Blank'}
                        required
                        tooltipProps={{
                          status: true,
                          text: 'Modülün durumunu seçiniz.',
                        }}
                      >
                        <Field as='select' name='sub_module_target_blank' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Evet</option>
                          <option value={0}>Hayır</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                  </div>
                  <div className={'d-flex justify-content-end mt-7'}>
                    <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                      Kaydet
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
