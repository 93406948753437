import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {
  checkIBMGroupExportRequest,
  exportIBMGroupRequest,
} from '../../../requests/ibm/IBMGroupRequest'

export const useIBMGroupExport = () => {
  const navigate = useNavigate()
  const [downloadExcelUrl, setDownloadExcelUrl] = useState<any>(null)

  const exportIBMGroups = () => {
    exportIBMGroupRequest()
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkIBMGroupExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  const checkIBMGroupExport = (filename: string) => {
    checkIBMGroupExportRequest(filename).then((response) => {
      if (!response.data.data.is_exists) {
        setTimeout(() => {
          checkIBMGroupExport(filename)
        }, 1000)
        return
      }
      setDownloadExcelUrl(response.data.data.download_excel_url)
    })
  }

  return {
    exportIBMGroups,
    downloadExcelUrl,
  }
}
