import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'
import {menuLogFilterType} from '../../components/models/menus/MenuModel'

const API_URL = process.env.REACT_APP_API_URL || ''
export const MENU_URL = `${API_URL}/menus`

export const getMenusRequest = (storeId?: number, parentId?: number, relationCount?: string[]) => {
  return axios.get(`${MENU_URL}`, {
    params: {
      store_id: storeId,
      parent_id: parentId,
      with_count: relationCount,
    },
  })
}

export const createMenuRequest = (data: any, relationsCount?: string[], relations?: string[]) => {
  return axios.post(`${MENU_URL}`, data, {
    params: {
      with_count: relationsCount,
      with: relations,
    },
  })
}

export const updateMenuRequest = (menuId: number, data: any, relationCount?: string[]) => {
  return axios.put(`${MENU_URL}/${menuId}`, data, {
    params: {
      with_count: relationCount,
    },
  })
}

export const deleteMenuRequest = (menuId: number, removeTop: boolean) => {
  const numericRemoveTop = removeTop ? 1 : 0
  return axios.delete(`${MENU_URL}/${menuId}`, {
    params: {
      remove_top: numericRemoveTop,
    },
  })
}

export const getMenuCategoryLandingsRequest = (menuId: number, storeId: number) => {
  return axios.get(`${MENU_URL}/${menuId}/landings`, {
    params: {
      store_id: storeId,
    },
  })
}

export function getMenuLogsRequest(filters: menuLogFilterType, meta: paginationMetaType) {
  return axios.get(`${MENU_URL}/logs`, {
    params: {
      ...filters,
      limit: meta.limit,
      page: meta.page,
    },
  })
}
