import {Avatar} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'

import {initialMetaData} from '../../../../../providers/category/sorting/CategoryProductSortingProvider'
import {getCategoryProductsRequest} from '../../../../../requests/categories/sorting/CategorySortingRequest'
import {paginationMetaType} from '../../../../models/GeneralModel'
import {categoryUrlType} from '../../../../models/categories/CategoryProductModel'
import {minimalProductType} from '../../../../models/products/MinimalProductModel'

export const useCategoryProductSorting = () => {
  const {categoryId} = useParams<categoryUrlType>()
  const [categorySortingViewData, setCategorySortingViewData] = useState<any[]>([])
  const [categorySortingViewMeta, setCategorySortingViewMeta] =
    useState<paginationMetaType>(initialMetaData)
  const initialRef = useRef(0)
  const fetchRef = React.useRef(0)
  const categorySortingViewColumns = React.useMemo(() => {
    return [
      {
        Header: 'Görsel',
        accessor: 'image',
        Cell: ({row}: any) => <Avatar size={52} src={row.original.image} style={{minWidth: 52}} />,
        maxWidth: 52,
        manualWidth: 52,
      },
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'model',
        accessor: 'model',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
        maxWidth: 150,
      },
      {
        Header: 'Durum',
        accessor: 'status',
        Cell: ({row}: any) => <div>{row.original.status ? 'Aktif' : 'Pasif'}</div>,
      },
    ]
  }, [])

  useEffect(() => {
    setCategorySortingViewDataList()
  }, [])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    setCategorySortingViewDataList()
  }, [categorySortingViewMeta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (categorySortingViewMeta.page !== 1) {
      setCategorySortingViewMeta({...categorySortingViewMeta, page: 1})
      return
    }

    setCategorySortingViewDataList()
  }, [categorySortingViewMeta.limit])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  const setCategorySortingViewDataList = () => {
    if (!categoryId) {
      return
    }
    fetchRef.current += 1
    const fetchId = fetchRef.current

    getCategoryProductsRequest(categoryId, categorySortingViewMeta).then((res) => {
      if (fetchId !== fetchRef.current) {
        return
      }

      const products = res.data.data.map((product: minimalProductType) => {
        return {
          id: product.product_id,
          name: product.minimal_details?.name,
          image: product.cdn_image,
          model: product.model,
          price: product.price,
          status: product.status,
        }
      })

      const _meta = res.data.meta

      setCategorySortingViewMeta({..._meta, limit: _meta.per_page, page: _meta.current_page})

      setCategorySortingViewData(products)
    })
  }

  return {
    categorySortingViewData,
    setCategorySortingViewData,
    categorySortingViewMeta,
    setCategorySortingViewMeta,
    categorySortingViewColumns,
    setCategorySortingViewDataList,
  }
}
