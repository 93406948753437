import React, {FC} from 'react'

import {KrcTabs} from '../../../../molecules/KrcTabs'
import {CategoryProductDefaultSuggestedSorting} from './suggested_sorting/method/CategoryProductDefaultSuggestedSorting'

export const CategoryProductSuggestedSorting: FC = () => {
  return (
    <>
      <CategorySortingTabs />
    </>
  )
}

const CategorySortingTabs: FC = () => {
  return (
    <KrcTabs
      isActiveKey={false}
      tabsClassName={'mb-1'}
      tabsProps={{defaultActiveKey: 'default-sorting'}}
      tabProps={[
        {
          eventKey: 'default-sorting',
          title: 'Varsayılan Sıralama',
          children: <CategoryProductDefaultSuggestedSorting />,
        },
      ]}
    />
  )
}
