import React, {FC, useContext, useEffect} from 'react'

import {CategoryProductSortingContext} from '../../../../../../providers/category/sorting/CategoryProductSortingProvider'
import {KrcTable} from '../../../../../organisms/tables/KrcTable'

export const CategoryEditorsChoiceActivityList: FC<any> = () => {
  const {
    setCategoryEditorsChoiceLogsData,
    setCategoryEditorsChoiceLogsMeta,
    categoryEditorsChoiceLogsMeta,
    categoryEditorsChoiceLogsColumns,
    categoryEditorsChoiceLogs,
  } = useContext(CategoryProductSortingContext)

  useEffect(() => {
    setCategoryEditorsChoiceLogsData()
  }, [])

  return (
    <>
      <KrcTable
        columns={categoryEditorsChoiceLogsColumns}
        data={categoryEditorsChoiceLogs}
        searchableProps={{
          isSearchable: true,
        }}
        paginationProps={{
          isPagination: true,
          paginationData: categoryEditorsChoiceLogsMeta,
          page: categoryEditorsChoiceLogsMeta.page,
          limit: categoryEditorsChoiceLogsMeta.limit,
          onChangePage: (page: number) => {
            setCategoryEditorsChoiceLogsMeta({...categoryEditorsChoiceLogsMeta, page})
          },
          onChangeLimit: (limit: number) => {
            setCategoryEditorsChoiceLogsMeta({...categoryEditorsChoiceLogsMeta, limit})
          },
        }}
      />
    </>
  )
}
