import {EditOutlined, EyeOutlined} from '@ant-design/icons'
import {Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import moment from 'moment/moment'
import React, {createContext, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Column} from 'react-table'

import {KrcDatePicker} from '../../components/atoms/forms/KrcDatePicker'
import {KrcNumberInput} from '../../components/atoms/forms/KrcNumberInput'
import {KrcSelect} from '../../components/atoms/forms/KrcSelect'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {OrderType} from '../../components/models/order/OrderTypeModel'
import {CustomerGroupTypeFilter} from '../../components/molecules/filters/CustomerGroupTypeFilter'
import {OrderStatusTypeFilter} from '../../components/molecules/filters/OrderStatusTypeFilter'
import {removeEmptyItemInObject} from '../../helpers/ObjectHelper'
import {fetchOrder, fetchOrders} from '../../requests/orders/OrderRequest'
import {initialMetaData} from '../tables/ProductSelectionProvider'

interface IOrderContext {
  isLoading: boolean
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  columns: Column<any>[]
  order: OrderType | null
  getOrder: React.Dispatch<React.SetStateAction<any>>
  orders: any[]
  getOrders: () => void
  filter: any
  setFilter: React.Dispatch<React.SetStateAction<any>>
  handleOnFilter: (data: any, key: string | number) => void
}

export const OrderContext = createContext<IOrderContext>({
  isLoading: false,
  meta: initialMetaData,
  setMeta: () => {},
  columns: [],
  order: null,
  getOrder: () => {},
  orders: [],
  getOrders: () => {},
  filter: {},
  setFilter: () => {},
  handleOnFilter: () => {},
})

export const OrderProvider = ({children}: any) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [filter, setFilter] = useState<any>({})
  const [order, setOrder] = useState<OrderType | null>(null)
  const [orders, setOrders] = useState<any[]>([])
  const fetchRef = useRef(0)
  const initialRef = useRef(0)

  const columns = React.useMemo(
    () => [
      {
        Header: 'Sipariş No',
        accessor: 'order_id',
        Filter: () => {
          return (
            <>
              <KrcNumberInput
                style={{width: 120}}
                placeholder={'Sipariş No'}
                onChange={(data) => {
                  handleOnFilter(data, 'order_id')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Mağaza',
        accessor: 'store_name',
      },
      {
        Header: 'Müşteri ID',
        accessor: 'customer_id',
        Filter: () => {
          return (
            <>
              <KrcNumberInput
                style={{width: 120}}
                placeholder={'Müşteri ID'}
                onChange={(data) => {
                  handleOnFilter(data, 'customer_id')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Müşteri Grubu',
        accessor: 'customer_group.name',
        Filter: () => {
          return (
            <CustomerGroupTypeFilter
              placeholder={'Müşteri Grubu'}
              onClear={() => {
                handleOnFilter(undefined, 'customer_group_id')
              }}
              onSelected={(customerGroupTypeId) => {
                handleOnFilter(customerGroupTypeId, 'customer_group_id')
              }}
            />
          )
        },
      },
      {
        Header: 'Müşteri Adı',
        accessor: 'customer_full_name',
      },
      {
        Header: 'Durum',
        accessor: 'order_status.name',
        Filter: () => {
          return (
            <OrderStatusTypeFilter
              placeholder={'Sipariş Durumu'}
              onClear={() => {
                handleOnFilter(undefined, 'order_status_id')
              }}
              onSelected={(orderStatusTypeId) => {
                handleOnFilter(orderStatusTypeId, 'order_status_id')
              }}
            />
          )
        },
      },
      {
        Header: 'Toplam',
        accessor: 'total',
        Filter: () => {
          return (
            <>
              <KrcNumberInput
                placeholder={'Toplam Tutar'}
                onChange={(data) => {
                  handleOnFilter(data, 'total')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Eklenme Tarihi',
        accessor: 'date_added',
        Cell: ({row}: any) => {
          return (
            <div>
              {row.original.date_added ? (
                <div>{new Date(row.original.date_added).toLocaleDateString('tr-TR')}</div>
              ) : (
                ''
              )}
            </div>
          )
        },
        Filter: () => {
          return (
            <KrcDatePicker
              size={'middle'}
              style={{borderRadius: 5}}
              onChanged={(value) => {
                if (initialRef.current === 0) {
                  return
                }

                if (!value.date) {
                  handleOnFilter(undefined, 'date_added')
                  return
                }

                const formattedDate = moment(value.date, 'YYYY:MM:DD').format('YYYY-MM-DD')
                handleOnFilter(formattedDate, 'date_added')
                return
              }}
            />
          )
        },
      },
      {
        Header: 'Ödeme Türü',
        accessor: 'payment_method',
        Cell: ({row}: any) => {
          const paymentMethod = row.original.payment_method.split(';')
          const paymentMethodString = paymentMethod[0] + ':' + paymentMethod[1]
          return <div>{row.original.payment_method ? paymentMethodString : ''}</div>
        },
      },
      {
        Header: 'İptal Talebi',
        accessor: 'cancel_status_id',
        Cell: ({row}: any) => {
          return <div>{row.original.cancel_status_id ? 'Talep Var' : 'Talep Yok'}</div>
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'İptal Talebi'}
              options={[
                {value: 1, label: 'Var'},
                {value: 2, label: 'Yok'},
              ]}
              maxTagCount={'responsive'}
              style={{width: 120}}
              className={'ant-selector-border'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }

                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'cancel_status_id')
              }}
            />
          )
        },
      },
      {
        Header: 'Aksiyon',
        Cell: ({row}: any) => (
          <span className='text-end d-flex'>
            <>
              <Tooltip title={'Göster'}>
                <Link
                  to={`/orders/${row.values.order_id}/details`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  style={{color: 'unset'}}
                >
                  <EyeOutlined />
                </Link>
              </Tooltip>

              <Tooltip title={'Düzenle'}>
                <Link
                  to={`/orders/${row.values.order_id}/edit`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  style={{color: 'unset'}}
                >
                  <EditOutlined />
                </Link>
              </Tooltip>
            </>
          </span>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    if (!setMeta) {
      return
    }

    setMeta(meta)
  }, [meta.to, meta.from, meta.last_page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (!setMeta) {
      return
    }

    setMeta(meta)

    getOrders()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getOrders()
  }, [meta.limit])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  useEffect(() => {
    if (!meta) {
      return
    }

    setMeta(meta)
  }, [meta])

  useEffect(() => {
    if (!order) {
      return
    }

    setOrder(order)
  }, [order])

  useEffect(() => {
    if (!orders) {
      return
    }

    setOrders(orders)
  }, [orders])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getOrders()
  }, [filter])

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  const getOrder = (orderId: number) => {
    fetchOrder(orderId)
      .then((res) => {
        if (res.data.success === false) {
          message.error('Sipariş getirilirken bir hata oluştu.')
        }

        setOrder(res.data.data)
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getOrders = (page: null | number = null) => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    let _meta = meta
    const _filter = removeEmptyItemInObject(filter)
    if (page) {
      _meta.page = page
    }
    setIsLoading(true)
    fetchOrders(_filter, _meta)
      .then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }
        const metaData = res.data.meta
        setOrders(res.data.data)
        setMeta({limit: metaData.per_page, page: metaData.current_page, ...metaData})
      })
      .catch((err) => {
        message.error('Siparişler getirilirken bir hata oluştu')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <OrderContext.Provider
      value={{
        isLoading,
        order: order,
        getOrder,
        orders: orders,
        getOrders: () => {},
        filter,
        setFilter,
        handleOnFilter,
        meta,
        setMeta,
        columns,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}
