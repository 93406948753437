import {FC, useContext} from 'react'

import {UserContext} from '../../../providers/user/UserProvider'
import {paginationMetaType} from '../../models/GeneralModel'
import {KrcTable} from '../../organisms/tables/KrcTable'

export const UserList: FC = () => {
  const {columns, UserData, meta, setMeta, handleOnFilter} = useContext(UserContext)
  return (
    <>
      <KrcTable
        columns={columns}
        data={UserData}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            setMeta((data: paginationMetaType) => ({...data, page}))
          },
          onChangeLimit: (limit) => {
            setMeta((data: paginationMetaType) => ({...data, limit}))
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: (search, id) => {
            handleOnFilter(search, id)
          },
        }}
      />
    </>
  )
}
