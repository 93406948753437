import React, {FC, useContext} from 'react'
import {useSelector} from 'react-redux'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  CouponPrefixContext,
  CouponPrefixProvider,
} from '../../../../providers/campaign_and_coupon/CouponPrefixProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {CreateCouponPrefixModal} from '../../../modals/coupon/prefix/CreateCouponPrefixModal'
import {UpdateCouponPrefixModal} from '../../../modals/coupon/prefix/UpdateCouponPrefixModal'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {CouponPrefixList} from './CouponPrefixList'

export const CouponPrefixWrapperWithoutProvider: FC = () => {
  const {isShowCreateModal, setIsShowCreateModal, isShowUpdateModal, setIsShowUpdateModal} =
    useContext(CouponPrefixContext)
  const {user} = useSelector((state: any) => state.auth)
  return (
    <>
      <Toolbar
        rightField={
          <div className={'d-flex align-items-center py-1'}>
            {user?.data?.can_create_coupon_prefix && (
              <KrcButton
                size={'middle'}
                onClick={() => {
                  setIsShowCreateModal(true)
                }}
                id='kt_toolbar_primary_button'
              >
                {'Kupon Prefix Oluştur'}
              </KrcButton>
            )}
          </div>
        }
      ></Toolbar>
      <PageTitle>Kupon Prefix</PageTitle>
      <CouponPrefixList />
      {isShowUpdateModal && <UpdateCouponPrefixModal onHide={() => setIsShowUpdateModal(false)} />}
      {isShowCreateModal && <CreateCouponPrefixModal onHide={() => setIsShowCreateModal(false)} />}
    </>
  )
}

export const CouponPrefixWrapper = () => {
  return (
    <CouponPrefixProvider>
      <CouponPrefixWrapperWithoutProvider />
    </CouponPrefixProvider>
  )
}
