import {QuestionCircleFilled} from '@ant-design/icons'
import {Tooltip} from 'antd'
import React, {FC} from 'react'

import {useKrcProp} from '../hooks/components/useKrcProp'
import {IInfoTooltipModel} from '../models/InfoTooltipModel'

const currentTooltipProps = {
  status: false,
  text: 'Tooltip',
  icon: <QuestionCircleFilled className={'ml-1'} />,
  placement: 'topLeft',
}

export const InfoTooltip: FC<IInfoTooltipModel> = ({...props}) => {
  const currentProps = useKrcProp({config: currentTooltipProps, props}).getCurrentProps()

  return (
    <>
      {currentProps.status && (
        <Tooltip placement={currentProps.placement} title={currentProps.text}>
          {currentProps.icon}
        </Tooltip>
      )}
    </>
  )
}
