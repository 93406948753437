import React from 'react'

import {useCategoryProductSorting} from '../../../../../hooks/categories/products/sorts/useCategoryProductSorting'
import {paginationMetaType} from '../../../../../models/GeneralModel'
import {KrcTable} from '../../../../../organisms/tables/KrcTable'

export const CategoryProductSortingListView = () => {
  const {
    categorySortingViewData,
    categorySortingViewMeta,
    setCategorySortingViewMeta,
    categorySortingViewColumns,
  } = useCategoryProductSorting()

  return (
    <KrcTable
      outerHeight={200}
      columns={categorySortingViewColumns}
      data={categorySortingViewData}
      paginationProps={{
        isPagination: true,
        page: categorySortingViewMeta.page,
        limit: categorySortingViewMeta.limit,
        paginationData: categorySortingViewMeta,
        onChangePage: (page) => {
          setCategorySortingViewMeta((data: paginationMetaType) => ({...data, page}))
        },
        onChangeLimit: (limit) => {
          setCategorySortingViewMeta((data: paginationMetaType) => ({...data, limit}))
        },
      }}
    />
  )
}
