import {Field, Form, Formik} from 'formik'
import React, {FC, useContext} from 'react'
import * as Yup from 'yup'

import {DealContext} from '../../../../providers/DealProvider'
import {FileManagerModal} from '../../../../service/FileManager/FileManagerModal'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

const schema = Yup.object({
  name: Yup.string().required('Fırsat adı zorunlu alan'),
  status: Yup.string().required('Fırsat durumu zorunlu alan'),
})

export const DealDetail: FC = () => {
  const {
    dealId,
    updateOrCreateData,
    onChangeValidate,
    updateIsValid,
    deal,
    dealDetailSubmitRef,
    setImageURL,
    setRecommendBannerImageURL,
    createDeal,
    updateDeal,
  } = useContext(DealContext)

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={updateOrCreateData.deal}
        onSubmit={() => {
          if (dealId) {
            updateDeal()
            return
          }

          createDeal()
        }}
        validate={onChangeValidate}
        validateOnMount={true}
      >
        {(formik) => {
          setTimeout(() => {
            updateIsValid(formik.isValid)
          })

          return (
            <Form className='form row mt-5' noValidate>
              <KrcFormikField
                className={'mb-2 col-md-10'}
                name={'name'}
                labelTitle={'Fırsat Adı'}
                required={true}
              />

              <KrcFormikField
                className={'mb-2 col-md-10'}
                name={'meta_description'}
                labelTitle={'Meta Açıklama'}
              >
                <Field
                  component='textarea'
                  name='meta_description'
                  className='form-control'
                  style={{height: 100}}
                />
              </KrcFormikField>

              <KrcFormikField
                className={'mb-2 col-md-10'}
                name={'meta_description'}
                labelTitle={'Meta Keyword'}
              >
                <Field
                  component='textarea'
                  name='meta_keyword'
                  className='form-control'
                  style={{height: 100}}
                />
              </KrcFormikField>

              <KrcFormikField
                className={'mb-2 col-md-10'}
                name={'keyword'}
                labelTitle={'Fırsat Sayfa URL'}
              />

              <KrcFormikField
                className={'mb-2 mt-2 col-md-3'}
                name={'image'}
                labelTitle={'Arkaplan Resmi'}
              >
                <FileManagerModal
                  onSelected={(link) => {
                    setImageURL(link)
                  }}
                  defaultImage={deal?.image}
                  tempUploadDir={'image/banner/2024/deals'}
                />
              </KrcFormikField>

              <KrcFormikField
                className={'mb-2 mt-2 col-md-3'}
                name={'bg_top'}
                labelTitle={'Arkaplan Yerleşimi'}
              >
                <Field as='select' name='bg_top' className='form-select' aria-label='Seçiniz'>
                  <option value=''>Seçiniz</option>
                  <option value='top'>Üstte</option>
                  <option value='fill'>Sayfayı Kapla</option>
                </Field>
              </KrcFormikField>

              <KrcFormikField
                className={'mb-2 mt-2 col-md-3'}
                type={'number'}
                name={'top_padding'}
                labelTitle={'Üst Boşluk'}
              />

              <KrcFormikField
                type={'color'}
                className={'mb-2 mt-2 col-md-2'}
                name={'bg_color'}
                labelTitle={'Arkaplan Rengi'}
              />

              <KrcFormikField
                className={'mb-2 mt-2 col-md-6'}
                name={'redirect'}
                labelTitle={'301 Yönlendirme URL'}
              ></KrcFormikField>

              <KrcFormikField
                className={'mb-2 mt-2 col-md-3'}
                name={'status'}
                required={true}
                labelTitle={'Durum'}
              >
                <Field
                  as='select'
                  name='status'
                  className='form-select'
                  aria-label='Select example'
                >
                  <option value=''>Seçiniz</option>
                  <option value='1'>Aktif</option>
                  <option value='0'>Pasif</option>
                </Field>
              </KrcFormikField>

              <div className={'row col-md-12 mt-2 mb-2'}>
                <KrcFormikField
                  className={'d-flex flex-column align-items-start col-md-1 col-sm-2'}
                  name={'recommend'}
                  labelTitle={'Recommend'}
                  tooltipProps={{
                    status: true,
                    text: 'Fırsatın anasayfada görünmesi için checkbox tikli olması gerekmektedir.',
                  }}
                >
                  <Field type={'checkbox'} name={'recommend'}></Field>
                </KrcFormikField>
                <KrcFormikField
                  className={'col-md-3'}
                  name={'recommend_banner_url'}
                  labelTitle={'Recommend Banner URL'}
                />
                <KrcFormikField
                  className={'d-flex flex-column align-items-start col-md-3'}
                  name={'recommend_banner'}
                  labelTitle={'Recommend Banner Resmi (Ratio: 9/16)'}
                >
                  <FileManagerModal
                    onSelected={(link) => {
                      setRecommendBannerImageURL(link)
                    }}
                    defaultImage={deal?.full_recommend_banner_image}
                    className={'file-manager-full-width w-100'}
                    tempUploadDir={'image/banner/2024/deals'}
                  />
                </KrcFormikField>
              </div>
              <button
                type='submit'
                ref={dealDetailSubmitRef}
                className='d-none'
                style={{marginLeft: 10}}
              />
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
