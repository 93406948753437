import axios from 'axios'
import * as querystring from 'query-string'

const API_URL = process.env.REACT_APP_API_URL || ''
export const LANDING_URL = `${API_URL}/landings`

export const exportLandingListRequest = (params: object) => {
  return axios.get(`${LANDING_URL}/export`, {
    params: params,
  })
}

export const checkLandingListExportRequest = ($fileName: string) => {
  return axios.get(`${LANDING_URL}/check-export`, {
    params: {
      file_name: $fileName,
    },
  })
}

export const getLandingListExportFrontendUrl = (filter?: object) => {
  return `/landings/export?${querystring.stringify(
    {...filter},
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}
