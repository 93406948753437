import {message} from 'antd'
import {Form, Formik} from 'formik'
import {Moment} from 'moment'
import React, {useContext, useEffect, useState} from 'react'

import {MANUEL} from '../../../enums/GameTypeEnum'
import {ScoreBoardContext} from '../../../providers/games/ScoreBoardProvider'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {DateConstraint} from '../../molecules/date_pickers/DateConstraint'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'

export const GameScoreListFilter = () => {
  const {
    gameOptions,
    gamePeriotOptions,
    defaultStartDate,
    defaultEndDate,
    filterCoupon,
    filterFormData,
    schema,
    setFilterFormData,
  } = useContext(ScoreBoardContext)
  const [showDateConstraint, setShowDateConstraint] = useState<boolean>(false)

  useEffect(() => {
    setShowDateConstraint(filterFormData.type === MANUEL)
    if (filterFormData.type !== MANUEL) {
      setFilterFormData({...filterFormData, startDate: defaultStartDate, endDate: defaultEndDate})
    }
  }, [filterFormData.type])

  const handleDateChange = (data: object) => {
    setFilterFormData({
      ...filterFormData,
      startDate: data['value']?.start_date,
      endDate: data['value']?.end_date,
    })
  }

  const handleSubmit = (e: any) => {
    if (filterFormData.type === MANUEL) {
      if (filterFormData.startDate == null || filterFormData.endDate == null) {
        message.error('Tarih seçimi yapınız')
        return
      }
    }

    filterCoupon(filterFormData)
  }

  return (
    <>
      <div className={'mb-5'}>
        <Formik
          validationSchema={schema}
          initialValues={filterFormData}
          onSubmit={handleSubmit}
          validate={setFilterFormData}
          enableReinitialize
        >
          {() => {
            return (
              <Form>
                <div className={'d-flex'}>
                  <KrcFormikField
                    name='gameId'
                    labelTitle={'Oyunlar'}
                    className={'col-md-2 mt-2'}
                    required
                  >
                    <KrcSelect
                      placeholder={'Oyun seçiniz'}
                      onChange={(data) => {
                        setFilterFormData({...filterFormData, gameId: data})
                      }}
                      options={gameOptions}
                      className={'select-filter-custom d-block'}
                    />
                  </KrcFormikField>
                  <KrcFormikField
                    name='type'
                    labelTitle={'Score Tablo Tipleri'}
                    type={'select'}
                    className={'col-md-3 mt-2'}
                    required
                  >
                    <KrcSelect
                      placeholder={'Tip seçiniz'}
                      onChange={(data) => {
                        setFilterFormData({...filterFormData, type: data})
                      }}
                      options={gamePeriotOptions}
                      className={'select-filter-custom d-block'}
                    />
                  </KrcFormikField>

                  {showDateConstraint && (
                    <>
                      <KrcFormikField
                        name={'date'}
                        labelTitle={'Tarihi Kısıtla'}
                        className={'col-md-4 mb-2'}
                      >
                        <DateConstraint
                          isSelect={false}
                          className='date-constraint'
                          onChange={handleDateChange}
                          leftDatePickerProps={{
                            format: 'DD.MM.YYYY',
                            placement: 'bottomLeft',
                            className: 'date-constraint-date',
                            disabledDate: (current: Moment | null) => {
                              if (filterFormData.endDate && current) {
                                return current.isAfter(filterFormData.endDate)
                              }
                              return false
                            },
                            style: {
                              '--ant-picker-cell-disabled-color':
                                'var(--ant-picker-cell-disabled-color-solid)',
                            } as React.CSSProperties,
                          }}
                          rightDatePickerProps={{
                            format: 'DD.MM.YYYY',
                            disabledDate: (current: Moment | null) => {
                              if (filterFormData.startDate && current) {
                                return current.isBefore(filterFormData.startDate)
                              }
                              return false
                            },
                            style: {
                              '--ant-picker-cell-disabled-color':
                                'var(--ant-picker-cell-disabled-color-solid)',
                            } as React.CSSProperties,
                          }}
                        />
                      </KrcFormikField>
                    </>
                  )}
                  <div className={'col-md-3 mt-5'}>
                    <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                      Filtrele
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}
