import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
const USER_URL = `${API_URL}/users`

export function fetchUserRolesRequest(userId: number) {
  return axios.get(`${USER_URL}/${userId}/roles`)
}

export function addRolesToUserRequest(userId: number, roleIds: number[]) {
  return axios.post(`${USER_URL}/${userId}/roles`, {
    role_ids: roleIds,
  })
}

export function deleteRoleFromUserRequest(userId: number, roleId: number) {
  return axios.delete(`${USER_URL}/${userId}/roles/${roleId}`)
}
