import {message, notification} from 'antd'
import {UploadChangeParam} from 'antd/lib/upload/interface'
import React, {useContext, useMemo, useState} from 'react'

import {ExcelImportStatusEnum} from '../../../../enums/ExcelImportStatusEnum'
import {ProductUpdateImportContext} from '../../../../providers/products/ProductUpdateImportProvider'
import {checkProductExcelUpdateImportStatus} from '../../../../requests/products/import-update/ProductUpdateImportRequest'
import {KrcEmptyFilter} from '../../../atoms/KrcEmptyFilter'

export const useProductsUpdateImport = () => {
  const [importModalVisible, setImportModalVisible] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  const {setImportLink, setProductUpdateImportId} = useContext(ProductUpdateImportContext)
  const [productExcelImportStatus, setProductExcelImportStatus] =
    useState<ExcelImportStatusEnum | null>(null)

  const columns = useMemo(() => {
    return [
      {
        Header: 'Model',
        accessor: 'madde_kodu',
        maxWidth: 150,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'urun_ismi',
      },
      {
        Header: 'Meta Açıklama',
        accessor: 'meta_aciklamasi',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Meta Keyword',
        accessor: 'meta_kelimeleri',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'E-Ticaret Açıklama',
        accessor: 'e_ticaret_aciklamasi',
        Filter: KrcEmptyFilter,
      },
    ]
  }, [])

  /*
   * Upload modal
   */
  const handleOnChange = (info: UploadChangeParam) => {
    const {status} = info.file
    const key = `upload-file`
    if (status === 'uploading') {
      message.loading({content: 'Yükleniyor', key})
      setProductUpdateImportId(undefined)
      setImportLink(undefined)
    }

    if (status === 'done') {
      setProductUpdateImportId(info.file.response.data.id)
      setImportLink(info.file.response.data.import_link)
      setData(info.file.response.data.excel_rows)
      message.success(`${info.file.name} file uploaded successfully.`)
      setImportModalVisible(false)
      message.destroy(key)
    } else if (status === 'error') {
      let responseError = []
      let errorMessage = <></>

      if (info.file?.response.errors?.excel_heading) {
        responseError = info.file.response.errors.excel_heading
      }

      if (info.file?.response.errors?.excel_data) {
        responseError = info.file.response.errors.excel_data
      }

      if (responseError) {
        errorMessage = responseError.map((errorMessage: string, index: number) => (
          <div
            key={`product-import-${index}`}
            className={'pb-2 pt-2'}
            style={{borderBottom: '1px solid #3333'}}
            dangerouslySetInnerHTML={{__html: errorMessage}}
          ></div>
        ))
      }

      notification.error({
        className: 'custom-notification',
        style: {width: 500},
        message: `${info.file.name} excel verilerinde hata var. Kontrol edip tekrar dene.`,
        description: errorMessage,
        duration: 0,
        placement: 'top',
      })
      setImportModalVisible(false)
      message.destroy(key)
    }
  }

  /*
   * Check import status
   */

  const checkImportStatus = (productExcelUpdateImportId: number) => {
    checkProductExcelUpdateImportStatus(productExcelUpdateImportId)
      .then((response) => {
        const productExcelImportData = response.data.data
        const status = productExcelImportData.status
        const statusText = productExcelImportData.status_text
        const errorMessages = productExcelImportData.error_message

        setProductExcelImportStatus(status)

        if (status === ExcelImportStatusEnum.STATUS_ERROR) {
          let errorMessage: JSX.Element | null = null

          Object.keys(errorMessages).forEach((key, index) => {
            let messages = errorMessages[key]

            if (typeof messages === 'string') {
              messages = [messages]
            }

            errorMessage = messages.map((errorMessage: string, index: number) => (
              <div
                key={`product-update-import-${index}`}
                className={'pb-2 pt-2'}
                style={{borderBottom: '1px solid #3333'}}
                dangerouslySetInnerHTML={{__html: errorMessage}}
              ></div>
            ))
          })

          notification.error({
            className: 'custom-notification',
            style: {width: 500},
            message: `Hata Kodu: ${statusText}`,
            description: errorMessage ?? null,
            duration: 0,
            placement: 'top',
          })
        } else if (status === ExcelImportStatusEnum.STATUS_VALIDATION_ERROR) {
          let responseError = []
          let errorMessage = <></>

          if (errorMessages?.excel_heading) {
            responseError = errorMessages.excel_heading
          }

          if (errorMessages?.excel_data) {
            responseError = errorMessages.excel_data
          }

          if (responseError) {
            errorMessage = responseError.map((errorMessage: string, index: number) => (
              <div
                key={`product-update-import-${index}`}
                className={'pb-2 pt-2'}
                style={{borderBottom: '1px solid #3333'}}
                dangerouslySetInnerHTML={{__html: errorMessage}}
              ></div>
            ))
          }

          notification.error({
            className: 'custom-notification',
            style: {width: 500},
            message: `Excel verilerinde hata var. Kontrol edip tekrar dene.`,
            description: errorMessage,
            duration: 0,
            placement: 'top',
          })
        } else if (status === ExcelImportStatusEnum.STATUS_COMPLETED) {
          notification.success({
            className: 'custom-notification',
            style: {width: 500},
            message: `Excel Import başarıyla tamamlandı.`,
            duration: 0,
            placement: 'top',
          })
        } else {
          setTimeout(() => {
            checkImportStatus(productExcelUpdateImportId)
          }, 5000)
        }
      })
      .catch((error) => {
        message.error(error.message)
      })
  }

  return {
    importModalVisible,
    setImportModalVisible,
    data,
    columns,
    productExcelImportStatus,
    handleOnChange,
    checkImportStatus,
  }
}
