import React, {FC} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {ShoppingListCreateForm} from './forms/ShoppingListCreateForm'

export const ShoppingListCreateFormWrapper: FC = () => {
  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Alışveriş Listesi Oluştur</PageTitle>
      <ShoppingListCreateForm />
    </>
  )
}
