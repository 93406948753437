import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
export const GAMES_URL = `${API_URL}/games`

export const getGameCardsRequest = (gameId: number) => {
  return axios.get(`${GAMES_URL}/${gameId}/cards`)
}

export const createGameCardRequest = (gameId: number, data: any) => {
  return axios.post(`${GAMES_URL}/${gameId}/cards`, data)
}

export const deleteGameCardRequest = (gameId: number, gameCardId: number) => {
  return axios.delete(`${GAMES_URL}/${gameId}/cards/${gameCardId}`)
}
