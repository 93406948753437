import {Image} from 'antd'
import clsx from 'clsx'
import {Field, Form, Formik} from 'formik'
import {OptionData} from 'rc-select/lib/interface'
import React, {FC, useContext} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'

import {LayoutComponentDetailContext} from '../../../../providers/layouts/LayoutComponentDetailProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {krcSelectOptions} from '../../../models/components/KrcSelectModel'
import {BannerFilter} from '../../../molecules/filters/BannerFilter'
import {StoreFilter} from '../../../molecules/filters/StoreFilter'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

const schema = Yup.object({
  name: Yup.string().required('Component Adı Zorunlu'),
  component_type: Yup.object().required('Component Type Zorunlu'),
  page: Yup.object().required('Layout Zorunlu'),
  component_banner: Yup.object().nullable(),
  banner_name: Yup.string(),
  store: Yup.object().required('Store Zorunlu'),
  status: Yup.boolean().required('Durum Zorunlu'),
})

export const LayoutComponentDetail: FC = () => {
  const {
    images,
    component,
    componentTypes,
    isBannerLoading,
    handleChangeComponent,
    updateComponentData,
    deleteComponent,
  } = useContext(LayoutComponentDetailContext)
  const {componentId} = useParams()
  const navigator = useNavigate()

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={component}
        onSubmit={handleChangeComponent}
        validate={updateComponentData}
        validateOnMount={true}
      >
        {(formik) => {
          return (
            <Form className='form row ' noValidate>
              <div className={'d-flex gap-8 w-content'}>
                <div className={'d-flex flex-column col-md-5 gap-2 w-500px'}>
                  <KrcFormikField
                    className={'col-md-10 w-450px'}
                    name={'name'}
                    labelTitle={'Component Adı'}
                    required={true}
                  />
                  <KrcFormikField
                    className={'w-250px'}
                    name={'component_type'}
                    labelTitle={'Component Type'}
                    required={true}
                  >
                    <KrcSelect
                      value={component.component_type}
                      className={'ant-selector-border w-100'}
                      placeholder={'Component Type'}
                      options={componentTypes}
                      showArrow={true}
                      mode={undefined}
                      labelInValue
                      allowClear={false}
                      disabled={true}
                    />
                  </KrcFormikField>

                  <KrcFormikField
                    className={'w-250px'}
                    name={'layout'}
                    labelTitle={'Component Sayfası'}
                    required={true}
                  >
                    <KrcSelect
                      className={'ant-selector-border w-250px'}
                      placeholder={'Component Sayfası'}
                      disabled={true}
                      value={component.page}
                      showArrow={true}
                      mode={undefined}
                    />
                  </KrcFormikField>

                  <div className={'d-flex align-items-end justify-content-between w-450px'}>
                    <KrcFormikField
                      className={'w-250px'}
                      name={'component_banner'}
                      labelTitle={'Component Banners'}
                    >
                      <BannerFilter
                        value={component.component_banner}
                        dataType={'item'}
                        mode={undefined}
                        optionFilterProp='children'
                        showSearch={true}
                        filterOption={(input, option) => {
                          const label = ((option?.label ?? '') as string).toUpperCase()

                          return label.includes(input.toUpperCase())
                        }}
                        onSelected={(data) => {
                          if (!data) {
                            return
                          }

                          const banner = data as krcSelectOptions
                          updateComponentData({
                            component_banner: {value: banner.value, label: banner.label},
                          })
                        }}
                      />
                    </KrcFormikField>
                    <KrcButton
                      className={clsx({disabled: !isBannerLoading})}
                      onClick={() => {
                        navigator(
                          `/banners/${component.component_banner?.value}?page_component_id=${componentId}`,
                          {
                            state: {page_component_id: componentId, store: component.store},
                          }
                        )
                      }}
                      type={'default'}
                    >
                      Düzenle
                    </KrcButton>
                    <KrcButton
                      onClick={() => {
                        navigator(`/banners?page_component_id=${componentId}`, {
                          state: {page_component_id: componentId, store: component.store},
                        })
                      }}
                      type={'default'}
                    >
                      Yeni Ekle
                    </KrcButton>
                  </div>

                  <KrcFormikField name={'store'} labelTitle={'Component Domain'} required={true}>
                    <StoreFilter
                      value={component.store}
                      className={'select-filter-custom w-250px'}
                      placeholder={'Lütfen seçiniz'}
                      showSearch={false}
                      dataType={'item'}
                      mode={undefined}
                      storeType={'company'}
                      disabled={true}
                      onSelected={(stores) => {
                        if (!stores) {
                          return
                        }

                        const selectedStore = stores[0] as OptionData

                        updateComponentData({
                          store: {
                            value: selectedStore.value,
                            label: selectedStore.label,
                          },
                        })
                      }}
                    />
                  </KrcFormikField>

                  <KrcFormikField
                    className={'mb-2 col-md-2'}
                    name={'status'}
                    labelTitle={'Durumu'}
                    required={true}
                  >
                    <Field
                      as='select'
                      name='status'
                      className='form-select'
                      aria-label='Select example'
                    >
                      <option value=''>Seçiniz</option>
                      <option value='1'>Aktif</option>
                      <option value='0'>Pasif</option>
                    </Field>
                  </KrcFormikField>
                </div>
                <div className={'d-flex flex-column gap-5'}>
                  <span>
                    <b>Banner Görselleri</b>
                  </span>
                  <div className={'d-flex flex-column gap-3 h-400px overflow-scroll w-content'}>
                    {images.length > 0 && (
                      <Image.PreviewGroup>
                        {images.map((image, key) => (
                          <Image key={`image-${key}`} width={450} height={200} src={image} />
                        ))}
                      </Image.PreviewGroup>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  'd-flex align-items-center justify-content-end gap-5 col-md-8 mb-5 pr-10 mt-5'
                }
              >
                <KrcButton
                  onClick={deleteComponent}
                  type={'primary'}
                  danger
                  size={'large'}
                  className={'w-200px'}
                >
                  Sil
                </KrcButton>
                <KrcButton
                  type={'text'}
                  size={'large'}
                  className={'custom-hover w-200px text-white button-bg-purple'}
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                >
                  Kaydet
                </KrcButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
