import clsx from 'clsx'
import React, {FC} from 'react'

import {DefaultTitle} from '../../../../_metronic/layout/components/header/page-title/DefaultTitle'
import {useLayout} from '../../../../_metronic/layout/core'
import {useKrcProp} from '../../hooks/components/useKrcProp'

const toolbarConfig = {
  default: {},
}

const toolbarJustifyClasses = {
  between: 'justify-content-between',
  center: 'justify-content-center',
}

interface IToolbarModel {
  config?: 'default'
  rightField?: JSX.Element | string
  toolbarJustify?: 'between' | 'center' // sonradan buraya lazım olursa ekleme yapılır
}

export const Toolbar: FC<IToolbarModel> = ({
  config = 'default',
  toolbarJustify = 'between',
  rightField,
  children,
  ...props
}) => {
  const currentProps = useKrcProp({config: toolbarConfig[config], props}).getCurrentProps()
  const {classes} = useLayout()

  return (
    <div className='toolbar' id='kt_toolbar' {...currentProps}>
      <div
        id='kt_toolbar_container'
        className={clsx(
          classes.toolbarContainer.join(' '),
          'd-flex align-items-center',
          toolbarJustifyClasses[toolbarJustify]
        )}
      >
        <DefaultTitle />
        {rightField}
        {children}
      </div>
    </div>
  )
}
