import React, {FC} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {LayoutProvider} from '../../../providers/layouts/LayoutProvider'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {LayoutManagement} from './LayoutManagement'

export const LayoutWrapperWithProvider: FC = () => {
  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Layout</PageTitle>
      <LayoutManagement />
    </>
  )
}

export const LayoutWrapper: FC = () => {
  return (
    <>
      <LayoutProvider>
        <LayoutWrapperWithProvider />
      </LayoutProvider>
    </>
  )
}
