import {message} from 'antd'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'

import {CREATE_CATEGORY_BANNER_KEY} from '../../../../enums/category/banner/CategoryBannerMessageKeyEnum'
import {errorMessage} from '../../../../helpers/ErrorHelper'
import {
  getCategoryBannerDescriptionRequest,
  updateCategoryBannerDescriptionRequest,
} from '../../../../requests/categories/banner/CategoryBannerDescriptionRequest'
import {categoryUrlType} from '../../../models/categories/CategoryProductModel'

export interface ICategoryBannerDescriptionDrawerProps {
  visible: boolean
  onClose: () => void
  onSave: (values: any) => void
}

const validationSchema = Yup.object({
  status: Yup.string().required('Durum zorunlu alandır.').oneOf(['1', '0']),
})

export const useCategoryBannerDescriptionDrawer = () => {
  const {categoryId} = useParams<categoryUrlType>()
  const [categoryDescription, setCategoryDescription] = useState<any>({
    description: '',
    status: '',
  })

  useEffect(() => {
    if (categoryId) {
      getCategoryBannerDescriptionRequest(Number(categoryId)).then((res) => {
        setCategoryDescription(res.data.data)
      })
    }
  }, [categoryId])

  const handleOnSubmit = async (values: any) => {
    message.loading({content: 'Açıklama kaydediliyor.', CREATE_CATEGORY_BANNER_KEY, duration: 1})

    try {
      await updateCategoryBannerDescriptionRequest(Number(categoryId), values)
      message.success({content: 'Açıklama kaydedildi.', CREATE_CATEGORY_BANNER_KEY, duration: 2})
    } catch (error) {
      errorMessage(error)
      message.destroy(CREATE_CATEGORY_BANNER_KEY)
    }
  }

  return {
    categoryDescription,
    handleOnSubmit,
    validationSchema,
  }
}
