import {Field, Form, Formik} from 'formik'
import moment from 'moment'
import React, {FC, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {DealContext} from '../../../providers/DealProvider'
import {FileManagerModal} from '../../../service/FileManager/FileManagerModal'
import {KrcAlert} from '../../atoms/KrcAlert'
import {KrcRangePicker} from '../../atoms/forms/KrcRangePicker'
import {CategoryTypeEnum} from '../../models/categories/CategoryModel'
import {BadgeFilter} from '../../molecules/filters/BadgeFilter'
import {CategoryFilter} from '../../molecules/filters/CategoryFilter'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'
import {KrcModal} from '../KrcModal'

export const DealProductModal: FC = () => {
  const {
    setVisibleDealProductModal,
    willBeUpdatedProduct,
    updateDealProduct,
    setWillBeUpdatedProduct,
    campaignAlertMessage,
    removeDealProductApprovalCampaign,
  } = useContext(DealContext)

  const [willBeUpdatedProductAnotherImage, setWillBeUpdatedProductAnotherImage] = useState<
    string | null
  >('')

  useEffect(() => {
    setWillBeUpdatedProductAnotherImage(willBeUpdatedProduct?.anotherImage)
  }, [willBeUpdatedProduct])

  return (
    <>
      <KrcModal
        modalProps={{
          onHide: () => {
            setVisibleDealProductModal(false)
          },
        }}
        modalHeaderProps={{
          title: 'Fırsat Ürünü Güncelle',
          onHide: () => {
            setVisibleDealProductModal(false)
          },
        }}
      >
        <div className={'mt-2 mr-6 ml-6'}>
          {campaignAlertMessage.map((campaignAlert) => {
            if (willBeUpdatedProduct.id !== campaignAlert.deal_product_id) {
              return null
            }
            return (
              <KrcAlert
                message={
                  <>
                    <div className={'d-flex justify-content-between'}>
                      <div>{campaignAlert.message}</div>
                      {campaignAlert.type === 'warning' ? (
                        <div
                          onClick={() => {
                            removeDealProductApprovalCampaign(
                              campaignAlert.deal_product_id,
                              campaignAlert.product_to_category_approval_id
                            )
                          }}
                          className={'cursor-pointer'}
                          style={{color: 'blue'}}
                        >
                          İptal et
                        </div>
                      ) : null}
                    </div>
                  </>
                }
                type={campaignAlert.type}
                className={'mb-1'}
                style={{padding: '3px 15px', fontSize: 12}}
              />
            )
          })}
        </div>
        <Modal.Body className='py-lg-10 px-lg-10 row justify-content-center'>
          {willBeUpdatedProduct && (
            <Formik
              initialValues={willBeUpdatedProduct}
              enableReinitialize
              onSubmit={(values) => {
                values.anotherImage = willBeUpdatedProductAnotherImage
                updateDealProduct(values)
              }}
            >
              {() => (
                <Form className='form row' noValidate>
                  <KrcFormikField
                    name={'date'}
                    required={true}
                    labelTitle={'Başlangıç ve Bitiş Tarihi'}
                  >
                    <KrcRangePicker
                      onSelected={(value) => {
                        if (!value) {
                          return
                        }

                        setWillBeUpdatedProduct &&
                          setWillBeUpdatedProduct((data: any) => {
                            return {
                              ...data,
                              date_start: value[0].format('YYYY-MM-DD HH:mm'),
                              date_end: value[1].format('YYYY-MM-DD HH:mm'),
                            }
                          })
                      }}
                      showTime
                      allowClear={false}
                      defaultValue={[
                        moment(willBeUpdatedProduct.rawStartDate),
                        moment(willBeUpdatedProduct.rawEndDate),
                      ]}
                      format={'YYYY.MM.DD HH:mm'}
                    ></KrcRangePicker>
                  </KrcFormikField>
                  <KrcFormikField name={'badge_list'} required={false} labelTitle={'Badge'}>
                    <BadgeFilter
                      value={willBeUpdatedProduct.badgeList}
                      defaultValue={willBeUpdatedProduct.badgeList}
                      className={'select-filter-custom'}
                      dataType={'item'}
                      onSelected={(data) => {
                        if (!setWillBeUpdatedProduct) {
                          return
                        }

                        setWillBeUpdatedProduct((productData: any) => {
                          return {
                            ...productData,
                            badgeList: data,
                          }
                        })
                      }}
                      dropdownStyle={{zIndex: 99999}}
                      placeholder={'Lütfen seçiniz'}
                    />
                  </KrcFormikField>

                  <KrcFormikField name={'campaigns'} required={false} labelTitle={'Kampanya'}>
                    <CategoryFilter
                      value={willBeUpdatedProduct.campaigns}
                      defaultValue={willBeUpdatedProduct.campaigns}
                      className={'select-filter-custom'}
                      placeholder={'Lütfen seçiniz'}
                      categoryType={CategoryTypeEnum.CAMPAIGN}
                      dataType={'item'}
                      dropdownStyle={{zIndex: 99999}}
                      onSelected={(data) => {
                        if (!setWillBeUpdatedProduct) {
                          return
                        }

                        setWillBeUpdatedProduct((productData: any) => {
                          return {
                            ...productData,
                            campaigns: data,
                          }
                        })
                      }}
                    />
                  </KrcFormikField>

                  <KrcFormikField
                    type={'number'}
                    name={'quantity'}
                    labelTitle={'Kampanya Stok'}
                    tooltipProps={{
                      status: true,
                      text: 'Butik Yönlendirmesi işaretli olduğunda işlevli hale gelir.',
                    }}
                  />

                  <KrcFormikField
                    name={'anotherLink'}
                    labelTitle={'Alternatif URL'}
                    tooltipProps={{
                      status: true,
                      text: 'Butik Yönlendirmesi işaretli olduğunda işlevli hale gelir.',
                    }}
                  />

                  <KrcFormikField
                    name={'anotherTitle'}
                    labelTitle={'Alternatif Başlık'}
                    tooltipProps={{
                      status: true,
                      text: 'Butik Yönlendirmesi işaretli olduğunda işlevli hale gelir.',
                    }}
                  />

                  <KrcFormikField
                    name={'anotherButtonText'}
                    labelTitle={'Alternatif Buton Metni'}
                    tooltipProps={{
                      status: true,
                      text: 'Butik Yönlendirmesi işaretli olduğunda işlevli hale gelir.',
                    }}
                  />

                  <KrcFormikField
                    name={'behaveLikeBoutique'}
                    labelTitle={'Butik Yönlendirmesi'}
                    tooltipProps={{
                      status: true,
                      text: 'Bir ürünün butik gibi davranması ve alternatif linke yönlendirilmesi isteniyorsa işaretlenmelidir.',
                    }}
                  >
                    <Field
                      className={'d-flex flex-column align-items-start'}
                      type={'checkbox'}
                      name={'behaveLikeBoutique'}
                    ></Field>
                  </KrcFormikField>

                  <KrcFormikField
                    className={'mb-2 mt-2 col-md-3'}
                    name={'image'}
                    labelTitle={'Alternatif Resim'}
                  >
                    <FileManagerModal
                      onSelected={(link) => {
                        setWillBeUpdatedProductAnotherImage(link)
                      }}
                      defaultImage={willBeUpdatedProduct?.fullAnotherImage}
                    />
                  </KrcFormikField>

                  <div className='col-md-12 d-flex justify-content-end mt-5'>
                    <button
                      type='submit'
                      className='w-25 btn btn-secondary mr-3'
                      onClick={() => {
                        setVisibleDealProductModal(false)
                      }}
                    >
                      İptal
                    </button>
                    <button type='submit' className='w-25 btn btn-primary'>
                      Güncelle
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </KrcModal>
    </>
  )
}
