import React, {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {PageTitle} from '../../../../_metronic/layout/core'
import {ManufacturerProvider} from '../../../providers/ManufacturerProvider'
import {getManufacturerByIdRequest} from '../../../requests/manufacturers/ManufacturerRequest'
import {
  manufacturerTabDataType,
  manufacturerUrlType,
} from '../../models/manufacturers/ManufacturerModel'
import {Loader} from '../../molecules/Loader'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {ManufacturerTabView} from './ManufacturerTabView'

export const ManufacturerUpdateWrapperWithoutProvider: FC = () => {
  const {manufacturerId} = useParams<manufacturerUrlType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [manufacturerGeneralInformation, setManufacturerGeneralInformation] =
    useState<manufacturerTabDataType>()

  useEffect(() => {
    setIsLoading(true)
    getManufacturerByIdRequest(Number(manufacturerId)).then((response) => {
      setManufacturerGeneralInformation({
        ...response.data.data,
        meta_title: response.data.data.meta_title ? response.data.data.meta_title : '',
        meta_description: response.data.data.meta_description
          ? response.data.data.meta_description
          : '',
        description: response.data.data.description ? response.data.data.description : '',
        seo_text: response.data.data.seo_text ? response.data.data.seo_text : '',
        keyword: response.data.data.keyword ? response.data.data.keyword : '',
        display_seo_text: response.data.data.display_seo_text.toString(),
        sort_order: response.data.data.sort_order.toString(),
        status: response.data.data.status.toString(),
        is_followable: response.data.data.is_followable.toString(),
        image: response.data.data.image ? response.data.data.image : '',
      })
    })
    setIsLoading(false)
  }, [])

  return (
    <>
      {!isLoading && manufacturerGeneralInformation ? (
        <>
          <Toolbar></Toolbar>
          <PageTitle>Üretici Firma (Manufacturer): {manufacturerGeneralInformation.name}</PageTitle>
          <ManufacturerTabView manufacturerGeneralInformation={manufacturerGeneralInformation} />
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

export const ManufacturerUpdateWrapper: FC = () => {
  return (
    <ManufacturerProvider>
      <ManufacturerUpdateWrapperWithoutProvider />
    </ManufacturerProvider>
  )
}
