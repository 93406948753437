import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, message} from 'antd'
import React, {useState} from 'react'

import {addBadgesToCategoriesRequest} from '../../../../requests/categories/badges/CategoryBadge'
import {dateRangeDataType} from '../../../models/components/RangePickerModel'

const {confirm} = Modal
export const useAddCategoriesBadge = () => {
  const [willBeAddedBadgeIds, setWillBeAddedBadgeIds] = useState<number[]>([])
  const [willBeAddedBadgeDateRange, setWillBeAddedBadgeDateRange] = useState<dateRangeDataType>({
    date_start: null,
    date_end: null,
  })
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([])
  const [clear, setClear] = useState<number>(0)

  const addBadgeToCategories = () => {
    if (willBeAddedBadgeIds.length === 0) {
      message.warning(
        'Kategorilere badge ekleyebilmek için Badge alanında badge seçilmek zorundadır.'
      )
      return
    }

    if (selectedCategoryIds.length === 0) {
      message.warning('Badge ekleyebilmek için en az 1 kategori seçilmeli')
      return
    }

    const key = 'categories-badges'

    confirm({
      title: `Kategorilere badge ekle`,
      icon: <ExclamationCircleOutlined />,
      content: 'Seçilen kategorilere badge(ler) eklenecek, emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        message.loading({
          content: 'Kategorilere badge(ler) ekleniyor..',
          key,
        })

        addBadgesToCategoriesRequest({
          category_ids: selectedCategoryIds,
          badge_ids: willBeAddedBadgeIds,
          date_range: willBeAddedBadgeDateRange,
        })
          .then((res) => {
            if (res.data.status) {
              message.success({
                content: 'Kategorilere badge(ler) eklendi.',
                key,
              })
              setClear((data) => data + 1)
              setWillBeAddedBadgeIds([])
              setSelectedCategoryIds([])
            } else {
              message.destroy(key)
            }
          })
          .catch((e) => {
            message.error({
              content: 'Kategorilere badge(ler) eklenemedi. Lütfen tekrar deneyin.',
              key,
            })
          })
      },
    })
  }

  return {
    setSelectedCategoryIds,
    setWillBeAddedBadgeIds,
    clear,
    willBeAddedBadgeIds,
    setWillBeAddedBadgeDateRange,
    addBadgeToCategories,
  }
}
