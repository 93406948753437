import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {DealWrapper} from '../components/pages/deals/details/DealWrapper'
import {DealProductExportWrapper} from '../components/pages/deals/details/products/exports/DealProductExportWrapper'
import {DealsListWrapper} from '../components/pages/deals/lists/DealsListWrapper'
import {DealListExportWrapper} from '../components/pages/deals/lists/exports/DealListExportWrapper'

export const DealRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/:dealId/products/export'} element={<DealProductExportWrapper />} />
        <Route path={'/export'} element={<DealListExportWrapper />} />
        <Route path={'/create'} element={<DealWrapper />} />
        <Route path={'/:dealId/update'} element={<DealWrapper />} />
        <Route path={'/'} element={<DealsListWrapper />} />
      </Routes>
    </>
  )
}
