import {Select} from 'antd'
import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {FOOTER_STORES} from '../../../../enums/FooterModuleStoresTypeEnum'
import {
  FooterModulesContext,
  FooterModulesProvider,
} from '../../../../providers/FooterModulesProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {FooterModuleCreateModal} from '../../../modals/footer/FooterModuleCreateModal'
import {StoreDataType} from '../../../models/footer/StoreModel'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {FooterModuleTabView} from './FooterModuleTabView'

const FooterModuleListWrapperWithoutProvider: FC = () => {
  const {currentStore, setCurrentStore, setVisibleCreateModal, visibleCreateModal} =
    useContext(FooterModulesContext)
  const handleStoreChange = (value: number) => {
    const selectedStore = FOOTER_STORES.find((store) => store.id === value) as StoreDataType
    setCurrentStore(selectedStore)
  }
  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <KrcButton
            size={'middle'}
            onClick={() => {
              setVisibleCreateModal(true)
            }}
            className={'mr-3'}
            id='kt_toolbar_primary_button'
          >
            {currentStore.store_name} Mağazasına Modül Ekle
          </KrcButton>
          <span className={'fs-5 mr-3 text-dark fw-bolder'}>Mağazalar:</span>
          {currentStore && (
            <>
              <Select
                defaultValue={currentStore.id}
                style={{width: 120}}
                onChange={(data) => handleStoreChange(data)}
                options={FOOTER_STORES.map((store) => {
                  return {value: store.id, label: store.store_name}
                })}
              />
            </>
          )}
        </div>
      </Toolbar>
      <PageTitle>Footer Modules</PageTitle>
      <FooterModuleTabView />
      {visibleCreateModal && (
        <FooterModuleCreateModal
          onHide={() => {
            setVisibleCreateModal(false)
          }}
          onSubmit={() => {
            setVisibleCreateModal(false)
          }}
        />
      )}
    </>
  )
}

export const FooterModuleListWrapper: FC = () => {
  return (
    <FooterModulesProvider>
      <FooterModuleListWrapperWithoutProvider />
    </FooterModulesProvider>
  )
}
