export const CategoryBannerPageTypeEnum = {
  Product: 'Product',
  Manufacturer: 'Manufacturer',
  Category: 'Category',
  Free: 'Free',
  Packet: 'Packet',
  Listing: 'Listing',
  Video: 'Video',
  CampaignPage: 'Campaign Page',
  HelpPage: 'Help Page',
  KirikParcaPage: 'Kırık Parça Page',
  CategoryPage: 'Category Page',
  GiftPage: 'Gift Page',
  StorePage: 'Store Page',
  CartPage: 'Cart Page',
  FavoritePage: 'Favorite Page',
  ManufacturerPage: 'Manufacturer Page',
  Youtube: 'Youtube',
  Iframe: 'Iframe',
  Shows: 'Shows',
  Deals: 'Deals',
  Web: 'Web',
  GiftAssistant: 'Gift Assistant',
}
