import {combineReducers} from 'redux'
import {all} from 'redux-saga/effects'

import * as auth from '../../app/modules/auth'
import badgeReducer from '../../app/store/Badge/BadgeReducer'
import categoryBannerReducer from '../../app/store/Category/Banner/CategoryBannerReducer'
import categoryReducer from '../../app/store/Category/CategoryReducer'
import dealReducer from '../../app/store/Deal/DealReducer'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  badge: badgeReducer,
  category: categoryReducer,
  categoryBanner: categoryBannerReducer,
  deal: dealReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
