import {ExclamationCircleOutlined} from '@ant-design/icons'
import {captureException} from '@sentry/react'
import {Modal, message} from 'antd'
import React, {FC, createContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {
  minimalProductType,
  productVariantTableType,
} from '../../../components/models/products/MinimalProductModel'
import {
  deleteProductVariantsType,
  productUrlType,
} from '../../../components/models/products/ProductModel'
import {
  deleteProductVariantsRequest,
  getProductRequest,
  getProductVariantsRequest,
} from '../../../requests/products/ProductRequest'

const {confirm} = Modal

interface IProductUpdateContext {
  deleteProductVariants: () => void
  selectedProductVariants: deleteProductVariantsType[]
  setSelectedProductVariants: React.Dispatch<React.SetStateAction<deleteProductVariantsType[]>>
  productVariants: productVariantTableType[]
  setProductVariants: React.Dispatch<React.SetStateAction<productVariantTableType[]>>
  productVariantDrawerVisible: boolean
  setProductVariantDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  getProductVariants: () => void
  product?: minimalProductType
}

export const ProductUpdateContext = createContext<IProductUpdateContext>({
  deleteProductVariants: () => {},
  selectedProductVariants: [],
  setSelectedProductVariants: () => {},
  productVariants: [],
  setProductVariants: () => {},
  productVariantDrawerVisible: false,
  setProductVariantDrawerVisible: () => {},
  getProductVariants: () => {},
})
export const ProductUpdateProvider: FC = ({children}) => {
  const [productVariants, setProductVariants] = useState<productVariantTableType[]>([])
  const [selectedProductVariants, setSelectedProductVariants] = useState<
    deleteProductVariantsType[]
  >([])
  const [productVariantDrawerVisible, setProductVariantDrawerVisible] = useState<boolean>(false)
  const [product, setProduct] = useState<minimalProductType>()
  const {productId} = useParams<productUrlType>()

  useEffect(() => {
    getProduct()
  }, [productId])

  const getProduct = () => {
    if (!productId) {
      return
    }

    getProductRequest(productId, ['description']).then((res) => {
      setProduct(res.data.data)
    })
  }

  const getProductVariants = () => {
    if (!productId) {
      return
    }

    getProductVariantsRequest(productId).then((res) => {
      const formattedProductVariants = res.data.data.map((productVariant: minimalProductType) => {
        return {
          id: productVariant.product_id,
          model: productVariant.model,
          name: productVariant.minimal_details?.name,
          type: productVariant.variant?.type,
          sort_order: productVariant.variant?.sort_order,
        }
      })

      setProductVariants(formattedProductVariants)
    })
  }
  const deleteProductVariants = () => {
    if (!productId) {
      return
    }

    if (selectedProductVariants.length === 0) {
      message.warn('Toplu ürün varyant silebilmek için lütfen ürün seçiniz.')
      return
    }

    confirm({
      title: `${selectedProductVariants?.length} varyant silenecek.`,
      icon: <ExclamationCircleOutlined />,
      content: 'Ürün varyant silmek istediğinizden emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        const key = 'delete-product-variants'

        message.loading({content: 'Üründen Varyant(lar) siliniyor...', key})

        deleteProductVariantsRequest(productId, selectedProductVariants)
          .then(() => {
            getProductVariants()
            setSelectedProductVariants([])

            message.success({content: 'Üründen Varyant(lar) başarıyla silindi', key})
          })
          .catch((e) => {
            captureException(e)
            message.error({content: 'Üründen Varyant(lar) silinemedi.', key})
          })
      },
    })
  }

  return (
    <>
      <ProductUpdateContext.Provider
        value={{
          setSelectedProductVariants,
          selectedProductVariants,
          deleteProductVariants,
          productVariants,
          setProductVariants,
          setProductVariantDrawerVisible,
          productVariantDrawerVisible,
          getProductVariants,
          product,
        }}
      >
        {children}
      </ProductUpdateContext.Provider>
    </>
  )
}
