import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
export const URL_ALIAS_URL = `${API_URL}/urls/aliases`

export const getUrlAliasRequest = (filter: any, meta: paginationMetaType) => {
  return axios.get(`${URL_ALIAS_URL}`, {params: {limit: meta.limit, page: meta.page, ...filter}})
}

export const getUrlAliasByKeywordRequest = (keyword: string) => {
  return axios.get(`${API_URL}/urls/seorul`, {params: {query: keyword}})
}

export const updateUrlAliasRequest = (urlAliasId: number, data: any) => {
  return axios.put(`${URL_ALIAS_URL}/${urlAliasId}`, data)
}

export const createUrlAliasRequest = (data: any) => {
  return axios.post(`${URL_ALIAS_URL}`, data)
}
