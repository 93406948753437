import {useEffect, useRef, useState} from 'react'

import {paginationMetaType} from '../../models/GeneralModel'

interface IUsePagination {
  callback: (...args: any) => void
  filter?: any // Herhangi bir şey filtrelemek için değildir. Filtre değiştiğinde pagination sıfırlanacak.
  defaultMeta?: paginationMetaType
  onChangeMeta?: (data: paginationMetaType) => void
  enableReinitialize?: boolean
}

export const usePagination = (
  {callback, defaultMeta, onChangeMeta, enableReinitialize, filter}: IUsePagination,
  params?: any
) => {
  const [meta, setMeta] = useState<paginationMetaType>({limit: 10, page: 1})
  const initialRef = useRef(0)

  const changePage = (page: number) => {
    setMeta({...meta, page})
  }

  const changeLimit = (limit: number) => {
    setMeta({...meta, limit})
  }

  const updateMetaData = (data: paginationMetaType) => {
    setMeta(data)
  }

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      changePage(1)
      return
    }

    if (params) {
      callback(...params)
      return
    }

    callback()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (params) {
      callback(...params)
      return
    }

    callback()
  }, [meta.page])

  useEffect(() => {
    if (!onChangeMeta) {
      return
    }

    onChangeMeta(meta)
  }, [meta.to, meta.from, meta.last_page, meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      changePage(1)
      return
    }

    if (params) {
      callback(...params)
      return
    }

    callback()
  }, [filter])

  useEffect(() => {
    initialRef.current = 1

    if (defaultMeta) {
      setMeta(defaultMeta)
    }
  }, [])

  useEffect(() => {
    if (!enableReinitialize || !defaultMeta) {
      return
    }

    setMeta(defaultMeta)
  }, [defaultMeta])

  return {meta, setMeta, changePage, changeLimit, updateMetaData}
}
