import React, {FC, useContext} from 'react'
import {useSelector} from 'react-redux'

import {PAGE_TYPE} from '../../../../../../enums/CampaignTypeEnum'
import {CampaignProductContext} from '../../../../../../providers/tables/CampaignProductProvider'
import {KrcTable} from '../../../../../organisms/tables/KrcTable'

export const CampaignProductList: FC = () => {
  const {
    campaignProducts,
    columns,
    pageType,
    updateSelectedCampaignProductIds,
    historyProductMeta,
    currentProductMeta,
    setHistoryProductMeta,
    setCurrentProductMeta,
    getCategoryHistoryPendingApproval,
    getCategoryPendingApproval,
    handleOnFilter,
    handleOnCurrentFilter,
  } = useContext(CampaignProductContext)

  const {user} = useSelector((state: any) => state.auth)

  return (
    <>
      {pageType && (
        <KrcTable
          key={pageType}
          outerHeight={PAGE_TYPE.CURRENT === pageType ? 220 : 160}
          columns={columns}
          data={campaignProducts}
          searchableProps={{
            isSearchable: true,
            onSearch: (search, id) => {
              if (pageType === PAGE_TYPE.CURRENT) {
                handleOnCurrentFilter(search, id)
              } else {
                handleOnFilter(search, id)
              }
            },
          }}
          selectableProps={{
            uniqueKey: 'product_id',
            isSelectable: user?.data?.can_approval_campaign && PAGE_TYPE.CURRENT === pageType,
            onSelectedItems: (data) => {
              updateSelectedCampaignProductIds(data)
            },
          }}
          paginationProps={{
            isPagination: true,
            page:
              pageType === PAGE_TYPE.CURRENT ? currentProductMeta.page : historyProductMeta.page,
            limit:
              pageType === PAGE_TYPE.CURRENT ? currentProductMeta.limit : historyProductMeta.limit,
            paginationData:
              pageType === PAGE_TYPE.CURRENT ? currentProductMeta : historyProductMeta,
            onChangePage: (page) => {
              if (pageType === PAGE_TYPE.CURRENT) {
                setCurrentProductMeta((meta: any) => ({...meta, page}))
                getCategoryPendingApproval({page})
              } else {
                setHistoryProductMeta((meta: any) => ({...meta, page}))
                getCategoryHistoryPendingApproval({page})
              }
            },
            onChangeLimit: (limit) => {
              if (pageType === PAGE_TYPE.CURRENT) {
                setCurrentProductMeta((meta: any) => ({...meta, limit}))
                getCategoryPendingApproval({limit, page: 1})
              } else {
                setHistoryProductMeta((meta: any) => ({...meta, limit}))
                getCategoryHistoryPendingApproval({limit, page: 1})
              }
            },
          }}
        />
      )}
    </>
  )
}
