import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''

export const CATEGORY_BANNER_URL = `${API_URL}/categories`

export function getCategoryBannerRequest() {
  return axios.get(CATEGORY_BANNER_URL)
}

export function getCategoryBannersRequest(categoryId: number) {
  return axios.get(`${CATEGORY_BANNER_URL}/${categoryId}/banners`)
}

export function createCategoryBannerRequest(categoryId: number, body: any) {
  return axios.post(`${CATEGORY_BANNER_URL}/${categoryId}/banners`, body)
}

export function updateCategoryBannerRequest(categoryId: number, bannerId: number, body: any) {
  return axios.put(`${CATEGORY_BANNER_URL}/${categoryId}/banners/${bannerId}`, body)
}

export function deleteCategoryBannerRequest(categoryId: number, bannerId: number) {
  return axios.delete(`${CATEGORY_BANNER_URL}/${categoryId}/banners/${bannerId}`)
}
