import debounce from 'lodash/debounce'
import {OptionData} from 'rc-select/lib/interface'
import React, {useEffect, useRef, useState} from 'react'

import {customerSearchRequest} from '../../../requests/customer/CustomerRequest'
import {minimalCustomerModelType} from '../../models/customers/CustomerModel'

interface IUseCustomerFilter {
  debounceTime?: number
}

export const useCustomerFilter = ({debounceTime = 500}: IUseCustomerFilter) => {
  const [options, setOptions] = useState<OptionData[]>()
  const [fetching, setFetching] = useState<boolean>(false)
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<number[]>([])
  const [selectedItems, setSelectedItems] = useState<OptionData[]>([])
  const fetchRef = React.useRef(0)
  const initialRef = useRef(0)
  const [initialOptions, setInitialOptions] = useState<OptionData[]>([])
  const [search, setSearch] = useState<string>('')

  const handleSearch = React.useMemo(() => {
    const loadOptions = (name: string, initial: boolean = false) => {
      setSearch(name)
      if (name.length < 2 && !initial) {
        return
      }

      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      customerSearchRequest(name).then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }

        const customers: OptionData[] = res.data.data.map((customer: minimalCustomerModelType) => {
          return {
            value: customer.customer_id,
            label: customer.customer_name,
          }
        })

        if (initial) {
          setInitialOptions(customers)
        }

        setOptions(customers)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTime)
  }, [])

  const handleOnChange = (payload: Array<OptionData> | OptionData) => {
    if (!payload) {
      return
    }

    if (Array.isArray(payload)) {
      setSelectedItems(payload)
      const customerIds = payload.map((data: OptionData) => data.value) as number[]
      setSelectedCustomerIds(customerIds)

      return
    }

    setSelectedItems([payload] as Array<OptionData>)
    setSelectedCustomerIds([payload.value as number])
  }

  useEffect(() => {
    handleSearch('', true)
  }, [])

  useEffect(() => {
    if (initialRef.current !== 0 && search.length !== 0) {
      return
    }

    setOptions(initialOptions)
  }, [search])

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current += 1
    }
  }, [])

  return {
    fetching,
    options,
    handleSearch,
    handleOnChange,
    selectedCustomerIds,
    selectedItems,
  }
}
