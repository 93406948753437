import React, {FC, useContext, useEffect} from 'react'

import {CategoryProductSortingContext} from '../../../../../../providers/category/sorting/CategoryProductSortingProvider'
import {KrcTable} from '../../../../../organisms/tables/KrcTable'

export const CategoryDefaultSortActivityList: FC<any> = () => {
  const {
    setCategoryDefaultSortLogsData,
    setCategoryDefaultSortLogsMeta,
    categoryDefaultSortLogsMeta,
    categoryDefaultSortLogsColumns,
    categoryDefaultSortLogs,
  } = useContext(CategoryProductSortingContext)

  useEffect(() => {
    setCategoryDefaultSortLogsData()
  }, [])

  return (
    <>
      <KrcTable
        columns={categoryDefaultSortLogsColumns}
        data={categoryDefaultSortLogs}
        searchableProps={{
          isSearchable: true,
        }}
        paginationProps={{
          isPagination: true,
          paginationData: categoryDefaultSortLogsMeta,
          page: categoryDefaultSortLogsMeta.page,
          limit: categoryDefaultSortLogsMeta.limit,
          onChangePage: (page: number) => {
            setCategoryDefaultSortLogsMeta({...categoryDefaultSortLogsMeta, page})
          },
          onChangeLimit: (limit: number) => {
            setCategoryDefaultSortLogsMeta({...categoryDefaultSortLogsMeta, limit})
          },
        }}
      />
    </>
  )
}
