import {PlusSquareOutlined} from '@ant-design/icons'
import {Form, Formik} from 'formik'
import {OptionData} from 'rc-select/lib/interface'
import React, {FC, useContext} from 'react'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'

import {BannerManagerContext} from '../../../../providers/banners/BannerManagerProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcTabs} from '../../../molecules/KrcTabs'
import {StoreFilter} from '../../../molecules/filters/StoreFilter'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

const schema = Yup.object({
  banner_name: Yup.string().required('Banner Adı Zorunlu'),
  store: Yup.object().required('Store Zorunlu'),
})

export const BannerManager: FC = () => {
  const {banner, isStoreDisabled, tabProps, handleOnSubmit, updateBanner, addBannerImage} =
    useContext(BannerManagerContext)
  const {bannerId} = useParams()

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={banner}
        onSubmit={handleOnSubmit}
        validate={updateBanner}
        validateOnMount={true}
      >
        {(formik) => {
          return (
            <Form className='form row' noValidate>
              <div className={'d-flex gap-5 w-80'}>
                <div className={'d-flex col-md-5 gap-2 justify-content-between w-100'}>
                  <KrcFormikField
                    className={'col-md-10 w-450px'}
                    name={'banner_name'}
                    labelTitle={'Banner Adı'}
                    required={true}
                    formikProps={{className: 'h-40px'}}
                  />
                  <KrcFormikField
                    className={'w-250px'}
                    name={'store'}
                    labelTitle={'Domain'}
                    required={true}
                  >
                    <StoreFilter
                      value={banner.store}
                      className={'select-filter-custom w-250px'}
                      placeholder={'Lütfen seçiniz'}
                      showSearch={false}
                      dataType={'item'}
                      mode={undefined}
                      size={'large'}
                      storeType={'company'}
                      disabled={isStoreDisabled}
                      onSelected={(stores) => {
                        if (!stores) {
                          return
                        }
                        const store = stores[0] as OptionData
                        updateBanner({store: {value: store.value, label: store.label}})
                      }}
                    />
                  </KrcFormikField>
                  <div></div>
                  <div></div>
                  <div className={'d-flex gap-5 align-items-end mb-2'}>
                    <KrcButton
                      onClick={addBannerImage}
                      type={'default'}
                      size={'large'}
                      icon={<PlusSquareOutlined />}
                    />
                  </div>
                </div>
              </div>
              {!bannerId && (
                <div className={'d-flex align-items-center justify-content-end gap-5 w-80 mt-5'}>
                  <KrcButton
                    type={'text'}
                    size={'large'}
                    className={'custom-hover w-200px text-white button-bg-purple'}
                    onClick={() => {
                      formik.handleSubmit()
                    }}
                  >
                    Oluştur
                  </KrcButton>
                </div>
              )}
              <div className={'w-80 mt-7'}>
                {bannerId ? (
                  <KrcTabs
                    tabsClassName={'mt-1'}
                    isActiveKey={true}
                    tabsProps={{defaultActiveKey: 'default'}}
                    tabProps={tabProps}
                  />
                ) : null}
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
