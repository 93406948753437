import {Empty} from 'antd'
import React, {FC} from 'react'

import store from '../../../../../setup/redux/Store'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {useProductsImport} from '../../../hooks/products/import/useProductsImport'
import {UploadModal} from '../../../modals/UploadModal'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const ProductsImport: FC = () => {
  const {importModalVisible, setImportModalVisible, data, columns, handleOnChange} =
    useProductsImport()
  const {
    auth: {accessToken},
  } = store.getState()

  return (
    <>
      {data.length ? (
        <>
          <KrcTable
            columns={columns}
            data={data}
            searchableProps={{
              isSearchable: true,
            }}
          />
        </>
      ) : (
        <Empty
          image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
          imageStyle={{
            height: 60,
          }}
          description={
            <span>
              Ürünlerin toplu bir biçimde url oluşturulması ve durumlarının açılması için
              kullanılacak alandır.
            </span>
          }
        >
          <KrcButton
            onClick={() => {
              setImportModalVisible(true)
            }}
          >
            Toplu Ürün Import
          </KrcButton>
        </Empty>
      )}

      {importModalVisible && (
        <UploadModal
          visible={importModalVisible}
          onHide={() => {
            setImportModalVisible(false)
          }}
          upload={{
            name: 'excel_file',
            action: `${process.env.REACT_APP_API_URL}/products/import/upload`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            showUploadList: false,
            onChange: handleOnChange,
          }}
        />
      )}
    </>
  )
}
