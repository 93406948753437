import {Field} from 'formik'
import {FieldConfig} from 'formik/dist/Field'
import {GenericFieldHTMLAttributes} from 'formik/dist/types'
import React, {FC} from 'react'

import {useKrcProp} from '../../hooks/components/useKrcProp'

const formikFieldConfig = {
  default: {
    type: 'text',
    className: 'form-control',
  } as GenericFieldHTMLAttributes & FieldConfig,
}

interface IFormikFieldConfig {
  config?: 'default'
}

export const KrcFormikInput: FC<GenericFieldHTMLAttributes & FieldConfig & IFormikFieldConfig> = ({
  config = 'default',
  ...props
}) => {
  const currentProps = useKrcProp({config: formikFieldConfig[config], props}).getCurrentProps()

  return (
    <>
      <Field {...currentProps} />
    </>
  )
}
