import {SelectProps, Spin} from 'antd'
import React, {FC, useEffect, useRef} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useManufacturerFilter} from '../../hooks/manufacturers/useManufacturerFilter'
import {IManufacturerFilter} from '../../models/manufacturers/ManufacturerFilterModel'

const manufacturerFilterConfig = {
  default: {
    style: {width: '100%'},
    mode: 'multiple',
    size: 'middle',
    placeholder: 'Marka Filtre Listesi',
  },
}

export const ManufacturerFilter: FC<IManufacturerFilter & SelectProps> = ({
  onSelected,
  children,
  clear,
  dataType = 'value',
  ...props
}) => {
  const initialRef = useRef(0)
  const currentProps = useKrcProp({
    config: manufacturerFilterConfig.default,
    props,
  }).getCurrentProps()
  const {handleSearch, handleOnChange, options, fetching, manufacturerIds, manufacturers} =
    useManufacturerFilter(500, dataType)

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current = 1

      return
    }

    if (dataType === 'item') {
      onSelected(manufacturers)
      return
    }

    onSelected(manufacturerIds)
  }, [manufacturerIds])

  return (
    <>
      <KrcSelect
        clear={clear}
        {...currentProps}
        showArrow
        filterOption={false}
        allowClear={false}
        labelInValue
        onSearch={handleSearch}
        onChange={handleOnChange}
        options={options}
        notFoundContent={fetching ? <Spin size='small' /> : null}
      >
        {children}
      </KrcSelect>
    </>
  )
}
