import {Empty, message} from 'antd'
import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {
  fetchIBMCampaignsSpecialGroupProducts,
  updateIBMCampaignSpecialGroupProductsSort,
} from '../../../../requests/ibm/IBMCampaignRequest'
import {useIBMCampaignSpecialGroupProducts} from '../../../hooks/IBM/useIBMCampaignSpecialGroupProducts'
import {Loader} from '../../../molecules/Loader'
import {KrcTable} from '../../../organisms/tables/KrcTable'
import {KrcModal} from '../../KrcModal'

interface IIBMCampaignSortModalProps {
  onHide: () => void
  onSortComplete: () => void
}
export const IBMSpecialGroupProductsSortModal: FC<IIBMCampaignSortModalProps> = ({
  onHide,
  onSortComplete,
}) => {
  const {
    campaignId,
    setSpecialGroupSortedProducts,
    specialGroupProductsSortColumn,
    specialGroupSortedProducts,
  } = useIBMCampaignSpecialGroupProducts()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [specialGroupProducts, setSpecialGroupProducts] = useState<any[]>([])

  useEffect(() => {
    setIsLoading(true)
    fetchIBMCampaignsSpecialGroupProducts(Number(campaignId), {page: 1, limit: 10}, 1)
      .then((res) => {
        setSpecialGroupProducts(res.data.data)
      })
      .catch((err) => {
        message.error('Ürünler getirilirken bir sorun oluştu.')
        message.error(err.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleOnSaveIBMSpecialGroupProductSorting = () => {
    let sortedProducts = specialGroupSortedProducts.map((product) => product.product_id)
    message.info('Kampanyalar sıralanıyor... Bu işlem biraz uzun sürebilir. Lütfe bekleyiniz.')
    updateIBMCampaignSpecialGroupProductsSort(Number(campaignId), sortedProducts)
      .then(() => {
        message.success('Ürünler başarıyla sıralandı.')
      })
      .catch((err) => {
        message.error('Ürünler sıralanırken bir hata oluştu.')
        message.error(err.response.data.message)
      })
      .finally(() => {
        onSortComplete()
        onHide()
      })
  }

  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
          onHide,
        }}
        modalHeaderProps={{
          title: 'IBM Özel Grup Ürünlerini Sırala',
          onHide,
        }}
      >
        <Modal.Body className={'py-lg-10 px-lg-10'}>
          {specialGroupProducts.length > 0 ? (
            <KrcTable
              columns={specialGroupProductsSortColumn}
              data={specialGroupProducts}
              outerHeight={280}
              sortableProps={{
                isSortable: true,
                onSortChange: (products) => {
                  setSpecialGroupSortedProducts(() => products)
                },
              }}
            />
          ) : isLoading ? (
            <Loader />
          ) : (
            <Empty
              image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
              imageStyle={{
                height: 60,
              }}
              description={<span>Grup altında durumu görünür olan kampanya bulunamadı.</span>}
            ></Empty>
          )}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={onHide}
              style={{marginLeft: 10}}
            >
              İptal Et
            </button>
            {specialGroupProducts.length !== 0 && (
              <button
                type='button'
                onClick={() => handleOnSaveIBMSpecialGroupProductSorting()}
                className='btn btn-primary'
                style={{marginLeft: 10}}
              >
                Kaydet
              </button>
            )}
          </div>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
