import {AutoComplete} from 'antd'
import {AutoCompleteProps} from 'antd/lib/auto-complete'
import React, {FC} from 'react'

import {useKrcProp} from '../../hooks/components/useKrcProp'
import {IKrcAutoCompleteModel} from '../../models/components/KrcAutoCompleteModel'

const autoCompleteConfig = {
  default: {
    style: {width: '100%'},
    size: 'middle',
    className: 'form-control',
  },
}

export const KrcAutoComplete: FC<AutoCompleteProps & IKrcAutoCompleteModel> = ({
  config = 'default',
  ...props
}) => {
  const currentProps = useKrcProp({
    config: autoCompleteConfig[config],
    props,
  }).getCurrentProps()

  return (
    <>
      <AutoComplete {...currentProps} />
    </>
  )
}
