import React, {useContext} from 'react'

import {LandingListContext} from '../../../../providers/LandingListProvider'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export function LandingList() {
  const {columns, landings} = useContext(LandingListContext)

  return (
    <>
      {landings.length === 0 ? (
        <p>Loading Please wait...</p>
      ) : (
        <>
          <div className={`card landing-list`}>
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                <KrcTable columns={columns} data={landings} outerHeight={180} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
