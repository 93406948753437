import {SelectProps} from 'antd'
import React, {FC, useEffect} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useCategoryTypeFilter} from '../../hooks/categories/useCategoryTypeFilter'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {ICategoryTypeFilter} from '../../models/categories/CategoryTypeFilter'

const categoryTypeFilterConfig = {
  default: {
    style: {width: '100%'},
    mode: 'multiple',
    size: 'middle',
    placeholder: 'Kategori Tipi',
  },
}

export const CategoryTypeFilter: FC<ICategoryTypeFilter & SelectProps> = ({
  onSelected,
  children,
  clear,
  ...props
}) => {
  const currentProps = useKrcProp({
    config: categoryTypeFilterConfig.default,
    props,
  }).getCurrentProps()
  const {options, handleOnChange, selectedItems} = useCategoryTypeFilter()
  const initialRef = React.useRef(0)

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current = 1

      return
    }

    if (!onSelected) {
      return
    }

    onSelected(selectedItems)
  }, [selectedItems])
  return (
    <>
      <KrcSelect
        clear={clear}
        {...currentProps}
        showArrow
        filterOption={false}
        allowClear={false}
        labelInValue
        onChange={handleOnChange}
        options={options}
      >
        {children}
      </KrcSelect>
    </>
  )
}
