import {FC, useEffect} from 'react'

type excelExportType = {
  handleExportData: () => any
  downloadExcelUrl: string | null
  errorMessage?: string
}

export const ExcelExport: FC<excelExportType> = ({
  handleExportData,
  downloadExcelUrl,
  errorMessage,
}) => {
  useEffect(() => {
    handleExportData()
  }, [])

  const checkingExportBox = () => {
    return (
      <>
        <div className='d-flex justify-content-center w-100 fs-4'>
          Excel oluşturuluyor. Lütfen bekleyiniz...
        </div>
      </>
    )
  }

  return (
    <>
      {errorMessage ? (
        <div className='d-flex justify-content-center w-100 fs-4'>{errorMessage}</div>
      ) : downloadExcelUrl ? (
        <div className='d-flex justify-content-center w-100 fs-4'>
          Excel oluşturuldu. Eğer otomatik olarak inmediyse&nbsp;{' '}
          <a href={downloadExcelUrl} target={'_blank'} rel='noreferrer'>
            indirmek için tıklayınız.
          </a>
        </div>
      ) : (
        checkingExportBox()
      )}
    </>
  )
}
