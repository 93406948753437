import {Moment} from 'moment'
import {useState} from 'react'

import {ProductDatePickerOnChangeDataType} from '../../models/components/KrcDatePickerModel'

export const useDatePicker = () => {
  const [value, setValue] = useState<Moment | null>(null)
  const handleOnChange = (
    value: any,
    callback: (data: ProductDatePickerOnChangeDataType) => any
  ) => {
    if (!callback) {
      return
    }

    const {date, time} = dateTimeFormat(value)

    callback({
      date,
      time,
      date_formatted: value ? value.format('DD.MM.YYYY HH:mm') : null,
      raw_date: value,
    })
  }

  const dateTimeFormat = (value: Moment | null) => {
    if (!value) {
      return {date: null, time: null}
    }

    const _value = value as Moment
    const date = _value.format('YYYY-MM-DD')
    const time = _value.format('HH:mm')

    return {date, time}
  }

  return {
    dateTimeFormat,
    handleOnChange,
    value,
    setValue,
  }
}
