import {message, notification} from 'antd'
import {UploadChangeParam} from 'antd/lib/upload/interface'
import React, {useContext, useState} from 'react'
import {Row} from 'react-table'

import {ExcelImportStatusEnum} from '../../../../enums/ExcelImportStatusEnum'
import {ProductImportContext} from '../../../../providers/products/ProductImportProvider'
import {checkProductExcelImportStatus} from '../../../../requests/products/import/ProductImportRequest'
import {KrcEmptyFilter} from '../../../atoms/KrcEmptyFilter'

export const useProductsImport = () => {
  const [importModalVisible, setImportModalVisible] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  const {setImportLink, setProductImportId} = useContext(ProductImportContext)
  const [productExcelImportStatus, setProductExcelImportStatus] =
    useState<ExcelImportStatusEnum | null>(null)

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Model',
        accessor: 'madde_kodu',
        maxWidth: 150,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'urun_ismi',
      },
      {
        Header: 'Meta Açıklama',
        accessor: 'meta_aciklamasi',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Meta Keyword',
        accessor: 'meta_kelimeleri',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Store (Mağazalar)',
        accessor: 'store',
        Filter: KrcEmptyFilter,
        Cell: ({row}: {row: Row<any>}) => (
          <div className='text-start'>{Array.from(row.original.store).join(',')}</div>
        ),
      },
      {
        Header: 'KKAC Alt Kategori',
        accessor: 'karacakaraca_appcookplus_sub_kategori_id',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Karaca Home Alt Kategori',
        accessor: 'karaca_home_sub_kategori_id',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Emsan Alt Kategori',
        accessor: 'emsan_sub_kategori_id',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Homend Alt Kategori',
        accessor: 'homend_sub_kategori_id',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Pera Bulvari Alt Kategori',
        accessor: 'pera_bulvari_sub_kategori_id',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Kaşmir Halı Alt Kategori',
        accessor: 'kasmir_hali_sub_kategori_id',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Butik',
        accessor: 'butik_id',
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Seo URL Durumu',
        accessor: 'link',
        maxWidth: 120,
        manuelWidth: 120,
        Filter: KrcEmptyFilter,
        Cell: ({row}: {row: Row<any>}) => (
          <div className='text-start'>
            {row.original.product_has_seo_url ? 'Zaten var' : 'Yeni Oluşturulacak'}
          </div>
        ),
      },
    ]
  }, [])

  /*
  Upload için kullanılıyor.
   */
  const handleOnChange = (info: UploadChangeParam) => {
    const {status} = info.file
    const key = `upload-file`
    if (status === 'uploading') {
      message.loading({content: 'Yükleniyor', key})
      setProductImportId(undefined)
      setImportLink(undefined)
    }

    if (status === 'done') {
      setProductImportId(info.file.response.data.id)
      setImportLink(info.file.response.data.import_link)
      setData(info.file.response.data.excel_rows)
      message.success(`${info.file.name} file uploaded successfully.`)
      setImportModalVisible(false)
      message.destroy(key)
    } else if (status === 'error') {
      let responseError = []
      let errorMessage = <></>

      if (info.file?.response.errors?.excel_heading) {
        responseError = info.file.response.errors.excel_heading
      }

      if (info.file?.response.errors?.excel_data) {
        responseError = info.file.response.errors.excel_data
      }

      if (responseError) {
        errorMessage = responseError.map((errorMessage: string, index: number) => (
          <div
            key={`product-import-${index}`}
            className={'pb-2 pt-2'}
            style={{borderBottom: '1px solid #3333'}}
            dangerouslySetInnerHTML={{__html: errorMessage}}
          ></div>
        ))
      }

      notification.error({
        className: 'custom-notification',
        style: {width: 500},
        message: `${info.file.name} excel verilerinde hata var. Kontrol edip tekrar dene.`,
        description: errorMessage,
        duration: 0,
        placement: 'top',
      })
      setImportModalVisible(false)
      message.destroy(key)
    }
  }

  // Import durumunu kontrol eder
  const checkImportStatus = (productExcelImportId: number) => {
    checkProductExcelImportStatus(productExcelImportId).then((response) => {
      const productExcelImportData = response.data.data
      const status = productExcelImportData.status
      const statusText = productExcelImportData.status_text
      const errorMessages = productExcelImportData.error_message

      setProductExcelImportStatus(status)

      if (status === ExcelImportStatusEnum.STATUS_ERROR) {
        let errorMessage: JSX.Element | null = null

        Object.keys(errorMessages).forEach((key, index) => {
          let messages = errorMessages[key]

          if (typeof messages === 'string') {
            messages = [messages]
          }

          errorMessage = messages.map((errorMessage: string, index: number) => (
            <div
              key={`product-import-${index}`}
              className={'pb-2 pt-2'}
              style={{borderBottom: '1px solid #3333'}}
              dangerouslySetInnerHTML={{__html: errorMessage}}
            ></div>
          ))
        })

        notification.error({
          className: 'custom-notification',
          style: {width: 500},
          message: `Hata Kodu: ${statusText}`,
          description: errorMessage ?? null,
          duration: 0,
          placement: 'top',
        })
      } else if (status === ExcelImportStatusEnum.STATUS_VALIDATION_ERROR) {
        let responseError = []
        let errorMessage = <></>

        if (errorMessages?.excel_heading) {
          responseError = errorMessages.excel_heading
        }

        if (errorMessages?.excel_data) {
          responseError = errorMessages.excel_data
        }

        if (responseError) {
          errorMessage = responseError.map((errorMessage: string, index: number) => (
            <div
              key={`product-import-${index}`}
              className={'pb-2 pt-2'}
              style={{borderBottom: '1px solid #3333'}}
              dangerouslySetInnerHTML={{__html: errorMessage}}
            ></div>
          ))
        }

        notification.error({
          className: 'custom-notification',
          style: {width: 500},
          message: `Excel verilerinde hata var. Kontrol edip tekrar dene.`,
          description: errorMessage,
          duration: 0,
          placement: 'top',
        })
      } else if (status === ExcelImportStatusEnum.STATUS_COMPLETED) {
        notification.success({
          className: 'custom-notification',
          style: {width: 500},
          message: `Excel Import başarıyla tamamlandı.`,
          duration: 0,
          placement: 'top',
        })
      } else {
        setTimeout(() => {
          checkImportStatus(productExcelImportId)
        }, 5000)
      }
    })
  }

  return {
    importModalVisible,
    setImportModalVisible,
    data,
    columns,
    productExcelImportStatus,
    handleOnChange,
    checkImportStatus,
  }
}
