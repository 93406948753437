import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {
  PermissionContext,
  PermissionProvider,
} from '../../../providers/permissions/PermissionProvider'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {CreatePermissionModal} from '../../modals/permissions/CreatePermissionModal'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {PermissionList} from './PermissionList'

const PermissionListWrapperWithoutProvider: FC = () => {
  const {showCreateModal, setShowCreateModal} = useContext(PermissionContext)
  return (
    <>
      <Toolbar
        rightField={
          <div className='d-flex align-items-center py-1'>
            <KrcButton
              size={'middle'}
              onClick={() => {
                setShowCreateModal(true)
              }}
            >
              {'Yeni İzin Oluştur'}
            </KrcButton>
          </div>
        }
      ></Toolbar>
      <PageTitle>Tüm İzinler</PageTitle>
      <PermissionList />
      {showCreateModal && <CreatePermissionModal onHide={() => setShowCreateModal(false)} />}
    </>
  )
}

export const PermissionListWrapper: FC = () => {
  return (
    <>
      <PermissionProvider>
        <PermissionListWrapperWithoutProvider />
      </PermissionProvider>
    </>
  )
}
