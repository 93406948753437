import {message} from 'antd'
import {Field, Form, Formik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'

import {INFORMATION_MAIN_TOPICS} from '../../../../../enums/InformationMainTopicsTypeEnum'
import {INFORMATION_STORES} from '../../../../../enums/InformationStoresTypeEnum'
import {KrcRichTextEditorWithTable} from '../../../../atoms/forms/KrcRichTextEditorWithTable'
import {KrcSelect} from '../../../../atoms/forms/KrcSelect'
import {useInformationSubTopicUpdateForm} from '../../../../hooks/information/useInformationSubTopicUpdateForm'
import {Loader} from '../../../../molecules/Loader'
import {KrcFormikField} from '../../../../molecules/forms/KrcFormikField'

const schema = Yup.object({
  title: Yup.string().required('Bilgi Başlığı zorunlu alan'),
  status: Yup.string().required('Durum zorunlu alan'),
  sort_order: Yup.number().required('Sıra zorunlu alan'),
  stores: Yup.array().required('Mağaza zorunlu alan'),
  bottom: Yup.string().required('Alt zorunlu alan'),
})

export const InformationSubTopicUpdateForm: FC = () => {
  const {isLoading, informationSubTopic, updateSpecificInformationSubTopic} =
    useInformationSubTopicUpdateForm()
  const [stores, setStores] = useState<number[]>([])
  const [parentTopic, setParentTopic] = useState<string>()
  const [metaDescription, setMetaDescription] = useState<string>('')
  const [metaTitle, setMetaTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  useEffect(() => {
    if (!informationSubTopic) return
    if (informationSubTopic.stores) {
      setStores(informationSubTopic.stores)
    }
    if (informationSubTopic.topic_id) {
      setParentTopic(informationSubTopic.topic_id)
    }
    setMetaDescription(informationSubTopic.meta_description)
    setDescription(informationSubTopic.description)
    setMetaTitle(informationSubTopic.meta_title)
  }, [informationSubTopic])

  const handleOnSubmit = (values: any) => {
    const updateData = {
      ...values,
      stores: stores,
      topic_id: Number(parentTopic),
      meta_description: metaDescription,
      meta_title: metaTitle,
      description: description,
    }

    if (updateData.stores.length === 0) {
      message.error('En az bir mağaza seçmelisiniz.')
      return
    }

    if (!updateData.meta_title) {
      message.error('Meta başlığı alanı zorunludur.')
      return
    }

    if (!updateData.meta_description) {
      message.error('Meta açıklaması alanı zorunludur.')
      return
    }

    updateSpecificInformationSubTopic(updateData)
  }

  return (
    <>
      {isLoading || !informationSubTopic.title ? (
        <Loader />
      ) : (
        <div>
          <Formik
            initialValues={informationSubTopic}
            onSubmit={handleOnSubmit}
            validationSchema={schema}
          >
            {() => {
              return (
                <Form className={'form mt-6 space-between'}>
                  <div className={'row'}>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'title'}
                        required
                        labelTitle={'Bilgi Başlığı'}
                      />
                    </div>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'seo_url_keyword'}
                        labelTitle={'Seo Kelimesi'}
                      />
                    </div>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2'}
                        name={'status'}
                        labelTitle={'Durum'}
                        required
                      >
                        <Field as='select' name='status' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Aktif</option>
                          <option value={0}>Pasif</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'number'}
                        name={'sort_order'}
                        labelTitle={'Sıra'}
                      />
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2 z-index-9999'}
                        name={'stores'}
                        required
                        labelTitle={'Mağazalar'}
                      >
                        <KrcSelect
                          size={'large'}
                          mode={'multiple'}
                          maxTagCount='responsive'
                          className={
                            'ant-selector-border custom-ant-select-selection-placeholder w-full'
                          }
                          defaultValue={informationSubTopic.stores}
                          value={stores}
                          style={{width: '100%'}}
                          onChange={(data) => setStores(data)}
                          options={INFORMATION_STORES.map((store) => {
                            return {value: store.id, label: store.store_name}
                          })}
                        />
                      </KrcFormikField>
                    </div>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2'}
                        name={'bottom'}
                        labelTitle={'Alt'}
                        required
                      >
                        <Field as='select' name='bottom' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Evet</option>
                          <option value={0}>Hayır</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2 z-index-9999'}
                        name={'parent'}
                        labelTitle={'Grup'}
                        required
                      >
                        <KrcSelect
                          size={'large'}
                          maxTagCount='responsive'
                          aria-readonly={true}
                          className={
                            'ant-selector-border custom-ant-select-selection-placeholder w-full'
                          }
                          defaultValue={informationSubTopic.topic_id}
                          value={parentTopic}
                          style={{width: '100%'}}
                          onChange={(data) => setParentTopic(data)}
                          options={INFORMATION_MAIN_TOPICS.map((information: any) => {
                            return {value: information.id.toString(), label: information.topic_name}
                          })}
                        />
                      </KrcFormikField>
                    </div>
                  </div>
                  <div className={'row mt-2'}>
                    <div className={'col-6'}>
                      <div className={'form-group'}>
                        <label className={'mb-2'}>
                          Meta Başlığı<span className={'ms-1 text-danger'}>*</span>
                        </label>
                        <textarea
                          className={'form-control'}
                          rows={5}
                          onChange={(e) => {
                            setMetaTitle(e.target.value)
                          }}
                          name={'meta_title'}
                          defaultValue={metaTitle}
                          value={metaTitle}
                        />
                      </div>
                    </div>
                    <div className={'col-6'}>
                      <div className={'form-group'}>
                        <label className={'mb-2'}>
                          Meta Açıklaması<span className={'ms-1 text-danger'}>*</span>
                        </label>
                        <textarea
                          className={'form-control'}
                          rows={5}
                          onChange={(e) => {
                            setMetaDescription(e.target.value)
                          }}
                          name={'meta_description'}
                          defaultValue={metaDescription}
                          value={metaDescription}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'form-group mt-3'}>
                      <label className={'mb-1'}>
                        Açıklama<span className={'text-danger ms-1'}>*</span>
                      </label>
                      <KrcRichTextEditorWithTable
                        className={'mt-4'}
                        height={300}
                        value={description}
                        onChange={(value) => {
                          if (value) {
                            setDescription(value)
                          } else {
                            setDescription(value)
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={'d-flex justify-content-end mt-7'}>
                    <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                      Kaydet
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      )}
    </>
  )
}
