import React, {FC, useContext} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {UserContext} from '../../../../providers/user/UserProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {IUserPermissionManagementModalProps} from '../../../models/user/UserModel'
import {PermissionFilter} from '../../../molecules/filters/PermissionFilter'
import {KrcTable} from '../../../organisms/tables/KrcTable'
import {KrcModal} from '../../KrcModal'

export const UserPermissionManagementModal: FC<IUserPermissionManagementModalProps> = ({
  onHide,
}) => {
  const {
    UserPermissionData,
    permissionColumns,
    willBeAddedPermissionIds,
    setWillBeAddedPermissionIds,
    assignSelectedPermissionsToUser,
  } = useContext(UserContext)

  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
        }}
        modalHeaderProps={{
          title: 'Kullanıcı İzinlerini Yönet',
          onHide,
        }}
      >
        <Modal.Body className='py-lg-5 px-lg-10'>
          <div className={'d-flex flex-wrap col-12 w-100'}>
            <h4 className={'col-12'}>Kullanıcıya İzin Tanımla</h4>
            <div className={'col-6'}>
              <PermissionFilter
                value={willBeAddedPermissionIds}
                className={'select-filter-custom'}
                style={{maxWidth: 402}}
                dropdownMatchSelectWidth={true}
                showSearch={true}
                mode={'multiple'}
                dataType={'value'}
                allowClear={true}
                onClear={() => {
                  setWillBeAddedPermissionIds([])
                }}
                onSelected={(data) => {
                  setWillBeAddedPermissionIds(data as number[])
                }}
                placeholder={'Lütfen seçiniz'}
                dropdownStyle={{zIndex: 99999}}
              />
            </div>
            <div className={'col-3 ml-auto'}>
              <KrcButton
                size={'middle'}
                className={'ml-5'}
                onClick={assignSelectedPermissionsToUser}
              >
                Yeni İzin Tanımla
              </KrcButton>
            </div>
          </div>
          <KrcTable columns={permissionColumns} data={UserPermissionData} />
        </Modal.Body>
      </KrcModal>
    </>
  )
}
