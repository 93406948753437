import {FC, useEffect} from 'react'

import {useReceivingTable} from '../../hooks/components/useReceivingTable'
import {IReceivingTable} from '../../models/components/ReceivingTableModel'
import {IKrcTable} from '../../models/components/TableModel'
import {KrcTable} from '../../organisms/tables/KrcTable'

export const ReceivingTable: FC<IReceivingTable & IKrcTable> = ({
  data,
  columns,
  meta,
  unSelectAllItems,
  onChangeLimit,
  onChangePage,
  onSelectedItems,
  onChangeTableFilter,
  ...props
}) => {
  const {selectedItems, tableFilter, updateSelectedItems, handleOnSearch} = useReceivingTable()

  useEffect(() => {
    if (!onSelectedItems) {
      return
    }

    onSelectedItems(selectedItems)
  }, [selectedItems])

  useEffect(() => {
    if (!onChangeTableFilter) {
      return
    }

    onChangeTableFilter(tableFilter)
  }, [tableFilter])

  return (
    <>
      <KrcTable
        columns={columns}
        data={data}
        searchableProps={{
          isSearchable: true,
          onSearch: handleOnSearch,
        }}
        selectableProps={{
          isSelectable: true,
          onSelectedItems: updateSelectedItems,
          unSelectAllItems: unSelectAllItems,
        }}
        paginationProps={{
          isPagination: meta && true,
          page: meta?.page || 1,
          limit: meta?.limit || 10,
          paginationData: meta,
          onChangeLimit: (limit) => {
            if (!onChangeLimit) {
              return
            }

            onChangeLimit(limit)
          },
          onChangePage: (page) => {
            if (!onChangePage) {
              return
            }

            onChangePage(page)
          },
        }}
        {...props}
      />
    </>
  )
}
