export const COOKPLUS = 0
export const KARACA_HOME = 1
export const KARACA = 2
export const EMSAN = 3
export const PERA_BULVARI = 13
export const HOMEND = 18
export const KASMIR = 22

export const FOOTER_STORES = [
  {
    id: COOKPLUS,
    store_name: 'Cookplus',
  },
  {
    id: KARACA_HOME,
    store_name: 'Karaca Home',
  },
  {
    id: KARACA,
    store_name: 'Karaca',
  },
  {
    id: EMSAN,
    store_name: 'Emsan',
  },
  {
    id: PERA_BULVARI,
    store_name: 'Pera Bulvarı',
  },
  {
    id: HOMEND,
    store_name: 'Homend',
  },
  {
    id: KASMIR,
    store_name: 'Kaşmir',
  },
]
