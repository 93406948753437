import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, message} from 'antd'
import {getUUID} from 'rc-select/es/utils/commonUtil'
import React, {useState} from 'react'

import {deleteBadgeToProductsRequest} from '../../../../requests/products/ProductRequest'

const {confirm} = Modal

export const useDeleteProductsBadge = () => {
  const [clear, setClear] = useState<number>(0)
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([])
  const [willBeDeletedBadgeId, setWillBeDeletedBadgeId] = useState<number | null>()
  const [deleteBadgeKey, setDeleteBadgeKey] = useState<string>('delete-products-badge-filter')

  const deleteBadgeToProduct = () => {
    if (!willBeDeletedBadgeId) {
      message.warning(
        "Ürün(lerin) badge'i silebilmek için Badge alanında badge seçilmek zorundadır."
      )
      return
    }

    if (selectedProductIds.length === 0) {
      message.warning('Badge silebilmek için ürün seçilmeli.')
      return
    }

    confirm({
      title: `Ürün(lerden) badge silinecek`,
      icon: <ExclamationCircleOutlined />,
      content: 'Ürün(lerden) badge silinecek emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        const key = 'products-badges'
        message.loading({
          content: 'Ürünlerden badge siliniyor..',
          key,
        })
        deleteBadgeToProductsRequest({
          product_ids: selectedProductIds,
          badge_id: willBeDeletedBadgeId,
        })
          .then(() => {
            message.success({
              content: 'Ürünlerden badge silindi.',
              key,
            })
            setClear((data) => data + 1)
            setDeleteBadgeKey((value) => `${value}-${getUUID()}`)
            setWillBeDeletedBadgeId(null)
            setSelectedProductIds([])
          })
          .catch(() => {
            message.error({
              content: 'Ürünlerden badge silinemedi. Lütfen tekrar deneyin.',
              key,
            })
          })
      },
    })
  }

  return {
    willBeDeletedBadgeId,
    clear,
    deleteBadgeKey,
    setSelectedProductIds,
    setWillBeDeletedBadgeId,
    deleteBadgeToProduct,
  }
}
