import {useNavigate} from 'react-router-dom'

import {exportIBMGroupsAndCampaignsRequest} from '../../../requests/ibm/IBMGroupRequest'
import {useExport} from '../exports/useExport'

export const useIBMGroupsAndCampaignsExport = () => {
  const navigate = useNavigate()
  const {checkExport, downloadExcelUrl} = useExport()

  const exportIBMGroupsAndCampaigns = () => {
    exportIBMGroupsAndCampaignsRequest()
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  return {
    exportIBMGroupsAndCampaigns,
    downloadExcelUrl,
  }
}
