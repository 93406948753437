import React, {FC} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {LayoutComponentDetailProvider} from '../../../../providers/layouts/LayoutComponentDetailProvider'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {LayoutComponentDetail} from './LayoutComponentDetail'

const LayoutComponentDetailWrapperProvider: FC = () => {
  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Component Management</PageTitle>
      <LayoutComponentDetail />
    </>
  )
}

export const LayoutComponentDetailWrapper: FC = () => {
  return (
    <>
      <LayoutComponentDetailProvider>
        <LayoutComponentDetailWrapperProvider />
      </LayoutComponentDetailProvider>
    </>
  )
}
