import debounce from 'lodash/debounce'
import {OptionData} from 'rc-select/lib/interface'
import React, {useEffect, useRef, useState} from 'react'

import {productSearchRequest} from '../../../requests/products/ProductRequest'
import {minimalProductDetailType} from '../../models/products/MinimalProductModel'
import {IProductFilter} from '../../models/products/ProductFilterModel'

export const useProductFilter = ({searchType, label, initialName}: IProductFilter) => {
  const [value, setValue] = useState<string>('')
  const [spinning, setSpinning] = useState<boolean>(false)
  const [options, setOptions] = useState<OptionData[]>([])
  const fetchRef = React.useRef(0)
  const initialRef = useRef(0)

  useEffect(() => {
    if (!initialName || initialRef.current !== 0) {
      return
    }

    initialRef.current += 1
    setValue(initialName)
  }, [initialName])

  const handleOnSearch = React.useMemo(() => {
    const loadOption = (searchText: string) => {
      if (searchText.length === 0) {
        setOptions([])
        return
      }

      setSpinning(true)
      fetchRef.current += 1
      const fetchId = fetchRef.current

      let params = {}
      if (searchType === 'name') {
        params = {name: searchText}
      } else if (searchType === 'nameWithModel') {
        params = {name_or_model: searchText}
      }

      productSearchRequest(params)
        .then((res) => {
          if (fetchId !== fetchRef.current) {
            return
          }

          const productNames = res.data.data.map((product: minimalProductDetailType) => {
            if (label === 'withId') {
              return {value: `${product.product_id} - ${product.name}`}
            }

            return {value: product.name}
          })

          setOptions(productNames)
          setSpinning(false)
        })
        .catch(() => {
          setOptions([])
          setSpinning(false)
        })
    }

    return debounce(loadOption, 300)
  }, [])

  const handleOnChange = (data: string) => {
    setValue(data)
  }

  return {
    handleOnChange,
    handleOnSearch,
    options,
    spinning,
    value,
  }
}
