import {FC} from 'react'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'
import {InformationSubTopicCreateForm} from './InformationSubTopicCreateForm'

const ToolBar: FC = () => {
  return (
    <>
      <Toolbar />
      <PageTitle>Bilgilendirme Sayfa Başlıkları - Yeni Başlık Oluştur</PageTitle>
    </>
  )
}

export const InformationSubTopicCreateFormWrapper: FC = () => {
  return (
    <>
      <ToolBar />
      <InformationSubTopicCreateForm />
    </>
  )
}
