import {Button, Result} from 'antd'
import React from 'react'

interface IProps {
  title?: string
  icon?: string
  buttonText?: string
  buttonType?: 'primary' | 'default' | 'ghost' | 'dashed' | 'link' | undefined
  buttonKey?: React.Key
}

export const Results: React.FC<IProps> = ({title, icon, buttonText, buttonType, buttonKey}) => (
  <Result
    title={title}
    extra={
      buttonText ? (
        <Button type={buttonType} key={buttonKey}>
          {buttonText}
        </Button>
      ) : null
    }
  />
)
