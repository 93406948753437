import React, {FC, createContext, useState} from 'react'

import {fetchCustomerGroups, fetchCustomers} from '../requests/customer/CustomerRequest'

interface ICustomerContext {
  customer: any
  customers: Array<any> | null
  getCustomers: (customerId?: number) => Promise<void>
  customerGroups: Array<any> | null
  getCustomerGroups: (customerId?: number) => Promise<void>
}

export const CustomerContext = createContext<ICustomerContext>({
  customer: null,
  customers: null,
  getCustomers: () => Promise.reject(new Error('Customers not loaded.')),
  customerGroups: null,
  getCustomerGroups: () => Promise.reject(new Error('Customers not loaded.')),
})

export const CustomerProvider: FC = ({children}) => {
  const [customer, setCustomer] = useState<any | null>(null)
  const [customers, setCustomers] = useState<Array<any> | null>(null)
  const [customerGroups, setCustomerGroups] = useState<Array<any> | null>(null)

  const getCustomers = async (customerId?: number) => {
    const res = await fetchCustomers(customerId)
    customerId ? setCustomer(res.data.data) : setCustomers(res.data.data)
  }

  const getCustomerGroups = async () => {
    const res = await fetchCustomerGroups()
    setCustomerGroups(res.data.data)
  }

  return (
    <CustomerContext.Provider
      value={{customer, customers, getCustomers, customerGroups, getCustomerGroups}}
    >
      {children}
    </CustomerContext.Provider>
  )
}
