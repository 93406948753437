import {Alert} from 'antd'
import cn from 'classnames'
import React, {ChangeEvent, FC, useEffect, useState} from 'react'
import {Cell, HeaderGroup, Row} from 'react-table'

import useWindowDimensions from '../../../service/SizeHelper'
import {KrcInput} from '../../atoms/forms/KrcInput'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {calcWidth, useKrcTable} from '../../hooks/components/useKrcTable'
import {
  IHeaderGroup,
  IKrcHeaderTable,
  IKrcTable,
  IKrcTableBody,
  IKrcTableBodyWithDragAndDrop,
  IKrcTableColGroup,
  paginationPropsType,
  searchablePropsType,
  selectablePropsType,
  sortablePropsType,
} from '../../models/components/TableModel'
import {Pagination} from '../../molecules/Pagination'

const KrcGlobalFilter: FC<any> = ({preGlobalFilteredRows, globalFilter, setGlobalFilter}) => {
  const count = preGlobalFilteredRows?.length || 0
  const [value, setValue] = useState(globalFilter)

  useEffect(() => {
    setGlobalFilter(value)
  }, [value])

  return (
    <div className={'mr-4 ml-3'}>
      <KrcInput
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value)
        }}
        className={'form-control'}
        placeholder={`${count} kayıt bulundu`}
      />
    </div>
  )
}

const KrcTableHeader: FC<IKrcHeaderTable> = ({
  headerGroups,
  isSortable,
  isGlobalFilter,
  visibleColumns,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  return (
    <>
      <thead>
        {headerGroups.map((headerGroup: HeaderGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className='fw-bolder text-muted'>
            {isSortable ? <th>&nbsp;</th> : null}
            {headerGroup.headers.map((column: HeaderGroup & IHeaderGroup) => (
              <th
                {...column.getHeaderProps({
                  style: {...calcWidth(column), zIndex: 9},
                })}
              >
                {column.render('Header')}
                <div>{column.canFilter ? column.render('Filter') : null}</div>
              </th>
            ))}
          </tr>
        ))}
        {isGlobalFilter && (
          <tr>
            <th style={{zIndex: 9}} colSpan={visibleColumns.length}>
              <KrcGlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
        )}
      </thead>
    </>
  )
}

const KrcTableBodyWithDragAndDrop: FC<IKrcTableBodyWithDragAndDrop> = ({
  rows,
  DragAndDropRow,
  prepareRow,
  moveRow,
  onSortChange,
  records,
}) => {
  return (
    <>
      {rows.map((row, index) => {
        prepareRow(row)
        return (
          <DragAndDropRow
            row={row}
            index={index}
            moveRow={moveRow}
            {...row.getRowProps()}
            onSortChange={onSortChange}
            records={records}
            key={`tbody_row_component_key_${row.id}`}
          />
        )
      })}
    </>
  )
}

const KrcTableBody: FC<IKrcTableBody> = ({
  rows,
  prepareRow,
  cellClassName,
  onClick,
  chosenItem,
}) => {
  const handleOnClick = (row: Row, cell: Cell) => {
    if (cell.column.id === 'click-me-button' || !onClick) {
      return
    }

    onClick(row)
  }

  return (
    <>
      {rows.map((row: any) => {
        prepareRow(row)
        return (
          <tr
            {...row.getRowProps()}
            className={'active'}
            style={row.id === chosenItem?.id ? {background: '#e9ecef'} : null}
          >
            {row.cells.map((cell: Cell & {column: {cellClassName?: string}}) => {
              const specialClassName = cell.column?.cellClassName ?? ''

              return (
                <td
                  {...cell.getCellProps()}
                  className={`text-dark fw-bolder text-hover-primary fs-6 ${cellClassName} ${specialClassName}`}
                  onClick={() => {
                    handleOnClick(row, cell)
                  }}
                >
                  {cell.render('Cell')}
                </td>
              )
            })}
          </tr>
        )
      })}
    </>
  )
}

const KrcTableColGroup: FC<IKrcTableColGroup> = ({columns}) => {
  return (
    <colgroup>
      {columns.map((column: any, key: number) => {
        return <col key={key} style={{width: column.width}} />
      })}
    </colgroup>
  )
}

const defaultSelectableProps = {
  default: {
    isSelectable: false,
    selectableAlign: 'right',
    uniqueKey: 'id',
  } as selectablePropsType,
}

const defaultSearchableProps = {
  default: {
    isSearchable: false,
    isDataEmptyAfterFilter: false,
  } as searchablePropsType,
}

const defaultPaginationProps = {
  default: {
    isPagination: false,
    page: 1,
    limit: 10,
    paginationData: {last_page: 1, from: 1, to: 1, total: 0},
  } as paginationPropsType,
}

const defaultSortableProps = {
  default: {
    isSortable: false,
  },
}

const defaultStyleProps = {
  default: {
    style: {
      marginBottom: 10,
      // maxHeight: 600,
    },
    className: [],
  },
}

export const KrcTable: FC<IKrcTable> = ({
  columns,
  data,
  cellClassName,
  initialRowIdsOptions,
  stylesProps,
  selectableProps,
  searchableProps,
  paginationProps,
  sortableProps,
  outerHeight = 160,
  isGlobalFilter,
  onChoose,
  showHeader = true,
}) => {
  const {height} = useWindowDimensions()
  const [maxHeight, setMaxHeight] = useState(600)

  useEffect(() => {
    setMaxHeight(height - outerHeight)
  }, [height, outerHeight])

  const currentSelectableProps = useKrcProp({
    config: defaultSelectableProps.default,
    props: selectableProps,
  }).getCurrentProps() as selectablePropsType

  const currentSearchableProps = useKrcProp({
    config: defaultSearchableProps.default,
    props: searchableProps,
  }).getCurrentProps() as searchablePropsType

  const currentPaginationProps = useKrcProp({
    config: defaultPaginationProps.default,
    props: paginationProps,
  }).getCurrentProps() as paginationPropsType

  const currentSortableProps = useKrcProp({
    config: defaultSortableProps.default,
    props: sortableProps,
  }).getCurrentProps() as sortablePropsType

  const currentStylesProps = useKrcProp({
    config: defaultStyleProps.default,
    props: stylesProps,
  }).getCurrentProps()

  const {
    DragAndDropRow,
    Styles,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    moveRow,
    records,
    Provider,
    ProviderProps,
    globalFilter,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    chosenItem,
    setChosenItem,
  } = useKrcTable({
    columns,
    data,
    initialRowIdsOptions,
    selectableProps: currentSelectableProps,
    searchableProps: currentSearchableProps,
    sortableProps: currentSortableProps,
  })

  useEffect(() => {
    if (!onChoose || !chosenItem) {
      return
    }

    onChoose(chosenItem)
  }, [chosenItem])

  return (
    <>
      {/*// @ts-ignore*/}
      <Provider {...ProviderProps}>
        <Styles
          style={{maxHeight, ...currentStylesProps.style}}
          className={cn(currentStylesProps.className)}
        >
          {currentSearchableProps.isDataEmptyAfterFilter && (
            <Alert
              message='Filtreleme sonucunda herhangi bir veri bulunamadı! Farklı filtreler ile tekrar deneyiniz.'
              type='info'
              className={'rounded-3'}
              showIcon
            />
          )}
          <table
            {...getTableProps()}
            className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-3'
          >
            {showHeader && (
              <KrcTableHeader
                headerGroups={headerGroups}
                isSortable={currentSortableProps?.isSortable}
                visibleColumns={visibleColumns}
                preGlobalFilteredRows={preGlobalFilteredRows}
                setGlobalFilter={setGlobalFilter}
                globalFilter={globalFilter}
                isGlobalFilter={isGlobalFilter}
              />
            )}
            <KrcTableColGroup columns={columns} />
            <tbody {...getTableBodyProps()}>
              {currentSortableProps?.isSortable ? (
                <KrcTableBodyWithDragAndDrop
                  records={records}
                  DragAndDropRow={DragAndDropRow}
                  prepareRow={prepareRow}
                  rows={rows}
                  moveRow={moveRow}
                  onSortChange={currentSortableProps?.onSortChange}
                />
              ) : (
                <KrcTableBody
                  prepareRow={prepareRow}
                  rows={rows}
                  cellClassName={cellClassName}
                  onClick={setChosenItem}
                  chosenItem={chosenItem}
                />
              )}
            </tbody>
          </table>
          {currentPaginationProps.isPagination ? <Pagination {...currentPaginationProps} /> : null}
        </Styles>
      </Provider>
    </>
  )
}
