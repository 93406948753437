import {PictureOutlined} from '@ant-design/icons'
import {Alert, Avatar, Image, message} from 'antd'
import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useEffect, useState} from 'react'

import {KARACA_APP} from '../../../../enums/ManufacturerStoresEnum'
import {
  WEB_APP_IMAGE_UPLOAD_PATH,
  WEB_IMAGE_UPLOAD_PATH,
} from '../../../../enums/ManufacturerUploadImagePathEnum'
import {
  getManufacturerStoreDetailsRequest,
  updateManufacturerStoreDetailsRequest,
} from '../../../../requests/manufacturers/ManufacturerRequest'
import {FileManagerModal} from '../../../../service/FileManager/FileManagerModal'
import {manufacturerTabDataType} from '../../../models/manufacturers/ManufacturerModel'
import {Loader} from '../../../molecules/Loader'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

interface IManufacturerStoreImageInformationProps {
  storeId: number
  manufacturerGeneralInformation: manufacturerTabDataType
}

type initValuesType = {
  name: string
  manufacturer_description: string
  sort: number
  display_in_list: number
  show_in_manufacturer_page: number
  image_w: string
  image_wb: string
  image_a: string
}

const initValues = {
  name: '',
  manufacturer_description: '',
  sort: 0,
  display_in_list: 0,
  show_in_manufacturer_page: 0,
  image_w: '',
  image_wb: '',
  image_a: '',
}

export const ManufacturerStoreImageInformation: FC<IManufacturerStoreImageInformationProps> = ({
  storeId,
  manufacturerGeneralInformation,
}) => {
  const [manufacturerStoreImageInformation, setManufacturerStoreImageInformation] =
    useState<initValuesType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<initValuesType>(initValues)
  const [webImage, setWebImage] = useState<string>('')
  const [webAppImage, setWebAppImage] = useState<string>('')
  const [manufacturerDescription, setManufacturerDescription] = useState<string>('')
  const defaultImagePath = 'https://cdn.karaca.com/image/'
  const [isChanged, setIsChanged] = useState<boolean>(false)
  const [isSubmit, setIsSubmit] = useState<boolean>(false)

  useEffect(() => {
    fetchManufacturerStoreImageInformation()
  }, [])

  const fetchManufacturerStoreImageInformation = () => {
    setIsLoading(true)
    getManufacturerStoreDetailsRequest(
      manufacturerGeneralInformation.manufacturer_id,
      Number(storeId)
    )
      .then((response) => {
        if (response.data.data.length === 0) {
          const manufacturerName = manufacturerGeneralInformation.name
          setInitialValues({
            name: manufacturerName,
            manufacturer_description: '',
            sort: 0,
            display_in_list: 0,
            show_in_manufacturer_page: 0,
            image_w: '',
            image_wb: '',
            image_a: '',
          })
          return
        }
        setManufacturerStoreImageInformation(response.data.data)
        setInitialValues({
          name: manufacturerGeneralInformation.name,
          manufacturer_description: response.data.data.manufacturer_description
            ? response.data.data.manufacturer_description
            : '',
          sort: response.data.data.sort.toString(),
          display_in_list: response.data.data.display_in_list.toString(),
          show_in_manufacturer_page: response.data.data.show_in_manufacturer_page.toString(),
          image_w: response.data.data.image_w ? response.data.data.image_w : '',
          image_wb: response.data.data.image_wb ? response.data.data.image_wb : '',
          image_a: response.data.data.image_a ? response.data.data.image_a : '',
        })
        setManufacturerDescription(response.data.data.manufacturer_description)
        setWebImage(response.data.data.image_w)
        setWebAppImage(response.data.data.image_wb)
      })
      .catch((err) => {
        message.error('Manufacturer bilgileri getirilirken bir sorun oluştu.')
        message.error(err.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleOnSubmit = (values: FormikValues, {resetForm}: any) => {
    if (manufacturerStoreImageInformation?.image_w !== webImage) {
      values.image_w = webImage
    }
    if (manufacturerStoreImageInformation?.image_wb !== webAppImage) {
      values.image_wb = webAppImage
    }

    if (Number(storeId) === KARACA_APP) {
      values.image_wb = null
    }

    values.manufacturer_description = manufacturerDescription
    values.image_a = null
    message.success('Marka bilgileri güncelleniyor...')
    updateManufacturerStoreDetailsRequest(
      manufacturerGeneralInformation.manufacturer_id,
      Number(storeId),
      values
    )
      .then((res) => {
        resetForm()
        setIsSubmit(true)
        setIsChanged(false)
        message.success('Marka bilgileri başarıyla güncellendi.')
        setTimeout(() => {
          setIsSubmit(false)
        }, 500)
      })
      .catch((err) => {
        message.error('Marka bilgileri güncellenirken bir sorun oluştu.')
        message.error(err.message)
      })
  }

  return (
    <>
      {isLoading && initialValues ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div>
          <div className={'row mt-3'}>
            <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
              {(dirty) => {
                return (
                  <Form className={'form col-md-12'}>
                    {(dirty.dirty || isChanged) && !isSubmit ? (
                      <Alert
                        message='Değişiklik yaptığınızı fark ettik. Sağ alttan değişiklikleri kaydetmeyi unutma!'
                        type='warning'
                        showIcon
                        closable
                      />
                    ) : null}
                    <div className={'row'}>
                      <div className={'col-5'}>
                        <div className={'row'}>
                          <div className={'form-group'}>
                            <KrcFormikField
                              className={'mb-2'}
                              type={'text'}
                              name={'name'}
                              readonly={true}
                              labelTitle={'Marka Adı'}
                              tooltipProps={{
                                status: true,
                                text: 'Marka adı görünmektedir. Düzenlenemez',
                              }}
                            />
                          </div>
                        </div>
                        <div className={'row'}>
                          <div className={'form-group'}>
                            <label>Marka Açıklaması</label>
                            <textarea
                              className={'form-control'}
                              rows={6}
                              onChange={(e) => {
                                if (initialValues.manufacturer_description !== e.target.value) {
                                  setIsChanged(true)
                                } else {
                                  setIsChanged(false)
                                }
                                setManufacturerDescription(e.target.value)
                              }}
                              name={'manufacturer_description'}
                              defaultValue={initialValues.manufacturer_description}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={'col-4'}>
                        <div className={'row'}>
                          <KrcFormikField
                            className={'mb-2'}
                            type={'text'}
                            name={'sort'}
                            labelTitle={'Sıralama'}
                            tooltipProps={{
                              status: true,
                              text: 'Sıralama değerini giriniz.',
                            }}
                          />
                        </div>
                        <div className={'row'}>
                          <KrcFormikField
                            className={'mb-2'}
                            name={'display_in_list'}
                            labelTitle={'Listelemede Görünme Durumu'}
                            tooltipProps={{
                              status: true,
                              text: 'Listelemede görünme durumu seçiniz.',
                            }}
                          >
                            <Field as='select' name='display_in_list' className='form-select'>
                              <option value={''} disabled={true}>
                                Seçiniz
                              </option>
                              <option value={1}>Evet</option>
                              <option value={0}>Hayır</option>
                            </Field>
                          </KrcFormikField>
                        </div>
                        <div className={'row'}>
                          <KrcFormikField
                            className={'mb-2'}
                            name={'show_in_manufacturer_page'}
                            labelTitle={'Marka Sayfasında Görünme Durumu'}
                            tooltipProps={{
                              status: true,
                              text: 'Marka sayfasında görünme durumu seçiniz.',
                            }}
                          >
                            <Field
                              as='select'
                              name='show_in_manufacturer_page'
                              className='form-select'
                            >
                              <option value={''} disabled={true}>
                                Seçiniz
                              </option>
                              <option value={1}>Evet</option>
                              <option value={0}>Hayır</option>
                            </Field>
                          </KrcFormikField>
                        </div>
                      </div>
                    </div>
                    <div className={'row w-100 mt-5 justify-content-between'}>
                      <div className={'col border-end'}>
                        <div className={'d-flex flex-column align-items-center'}>
                          <p>Web Görsel</p>
                          {initialValues.image_w ? (
                            <div>
                              <Image
                                preview={{
                                  maskClassName: 'rounded-circle',
                                }}
                                width={80}
                                height={80}
                                className={'rounded-circle'}
                                src={defaultImagePath + initialValues.image_w}
                              />
                            </div>
                          ) : (
                            <div>
                              <Avatar
                                size={80}
                                icon={<PictureOutlined />}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              />
                            </div>
                          )}
                          <div className={'mt-2'}>
                            <FileManagerModal
                              onSelected={(link) => {
                                if (initialValues.image_w !== link) {
                                  setIsChanged(true)
                                } else {
                                  setIsChanged(false)
                                }
                                setWebImage(link ?? '')
                              }}
                              defaultImage={
                                initialValues.image_w
                                  ? defaultImagePath + initialValues.image_w
                                  : undefined
                              }
                              tempUploadDir={WEB_IMAGE_UPLOAD_PATH}
                            />
                          </div>
                        </div>
                      </div>
                      {Number(storeId) !== KARACA_APP && (
                        <div className={'col border-end'}>
                          <div className={'d-flex flex-column align-items-center'}>
                            <p>Web Mobil Görsel</p>
                            {initialValues.image_wb ? (
                              <div>
                                <Image
                                  preview={{
                                    maskClassName: 'rounded-circle',
                                  }}
                                  width={80}
                                  height={80}
                                  className={'rounded-circle'}
                                  src={defaultImagePath + initialValues.image_wb}
                                />
                              </div>
                            ) : (
                              <div>
                                <Avatar
                                  size={80}
                                  icon={<PictureOutlined />}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                />
                              </div>
                            )}
                            <div className={'mt-2'}>
                              <FileManagerModal
                                onSelected={(link) => {
                                  if (initialValues.image_wb !== link) {
                                    setIsChanged(true)
                                  } else {
                                    setIsChanged(false)
                                  }
                                  setWebAppImage(link ?? '')
                                }}
                                defaultImage={
                                  initialValues.image_wb
                                    ? defaultImagePath + initialValues.image_wb
                                    : undefined
                                }
                                tempUploadDir={WEB_APP_IMAGE_UPLOAD_PATH}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={'mt-5'}>
                      <button
                        type='submit'
                        className={`btn btn-primary  w-100 mt-4 ${
                          (dirty.dirty || isChanged) && !isSubmit
                            ? 'border border-2 border-danger'
                            : ''
                        }`}
                        style={{marginLeft: 10}}
                      >
                        Güncelle
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      )}
    </>
  )
}
