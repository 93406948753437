import React, {FC, useEffect} from 'react'

import {useSortingWeight} from '../../hooks/sorting/useSortingWeight'
import {KrcTable} from '../../organisms/tables/KrcTable'

export const SortingWeightActivityList: FC<any> = () => {
  const {
    setSortingWeightLogsData,
    setSortingWeightLogsMeta,
    sortingWeightLogs,
    sortingWeightLogsMeta,
    logsColumns,
  } = useSortingWeight()

  useEffect(() => {
    setSortingWeightLogsData()
  }, [])

  return (
    <>
      <KrcTable
        columns={logsColumns}
        data={sortingWeightLogs}
        searchableProps={{
          isSearchable: true,
          onSearch: (data, id) => {},
        }}
        paginationProps={{
          isPagination: true,
          paginationData: sortingWeightLogsMeta,
          page: sortingWeightLogsMeta.page,
          limit: sortingWeightLogsMeta.limit,
          onChangePage: (page: number) => {
            setSortingWeightLogsMeta({...sortingWeightLogsMeta, page})
          },
          onChangeLimit: (limit: number) => {
            setSortingWeightLogsMeta({...sortingWeightLogsMeta, limit})
          },
        }}
      />
    </>
  )
}
