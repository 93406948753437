import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, message, notification} from 'antd'
import React, {useState} from 'react'

import {addBadgesToProductsRequest} from '../../../../requests/products/ProductRequest'
import {dateRangeDataType} from '../../../models/components/RangePickerModel'

const {confirm} = Modal
export const useAddProductsBadge = () => {
  const [willBeAddedBadgeIds, setWillBeAddedBadgeIds] = useState<number[]>([])
  const [willBeAddedBadgeDateRange, setWillBeAddedBadgeDateRange] = useState<dateRangeDataType>({
    date_start: null,
    date_end: null,
  })
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([])
  const [clear, setClear] = useState<number>(0)

  const addBadgeToProducts = () => {
    if (willBeAddedBadgeIds.length === 0) {
      message.warning(
        'Ürün(lere) badge ekleyebilmek için Badge alanında badge seçilmek zorundadır.'
      )
      return
    }

    if (selectedProductIds.length === 0) {
      message.warning('Badge ekleyebilmek için ürün seçilmeli')
      return
    }

    const key = 'products-badges'

    confirm({
      title: `Ürün(lere) badge ekle`,
      icon: <ExclamationCircleOutlined />,
      content: 'Ürün(lere) badge eklenecek emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        message.loading({
          content: 'Ürünlere badge(ler) ekleniyor..',
          key,
        })

        addBadgesToProductsRequest({
          product_ids: selectedProductIds,
          badge_ids: willBeAddedBadgeIds,
          date_range: willBeAddedBadgeDateRange,
        })
          .then((res) => {
            if (res.data.message !== null && res.data.message && res.data.message.length > 0) {
              notification.warning({
                className: 'custom-notification',
                style: {width: 500},
                message: `Badge eklenemeyen ürünler`,
                description: res.data.message.map(
                  (errorMessage: {product_id: number; message: string}) => (
                    <div
                      className={'pb-2 pt-2'}
                      style={{borderBottom: '1px solid #3333'}}
                      dangerouslySetInnerHTML={{__html: errorMessage.message}}
                    ></div>
                  )
                ),
                duration: 0,
                placement: 'top',
              })
            }

            if (res.data.status) {
              message.success({
                content: 'Ürünlere badge(ler) eklendi.',
                key,
              })
              setClear((data) => data + 1)
              setWillBeAddedBadgeIds([])
              setSelectedProductIds([])
            } else {
              message.destroy(key)
            }
          })
          .catch((e) => {
            message.error({
              content: 'Ürünlere badge(ler) eklenemedi. Lütfen tekrar deneyin.',
              key,
            })
          })
      },
    })
  }

  return {
    setSelectedProductIds,
    setWillBeAddedBadgeIds,
    clear,
    willBeAddedBadgeIds,
    setWillBeAddedBadgeDateRange,
    addBadgeToProducts,
  }
}
