import {ConfigProvider, DatePicker} from 'antd'
import locale from 'antd/es/locale/tr_TR'
import {PickerProps} from 'antd/lib/date-picker/generatePicker'
import {Moment} from 'moment'
import 'moment/locale/tr'
import React, {FC, useEffect} from 'react'

import {useDatePicker} from '../../hooks/components/useDatePicker'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {IDatePicker, IDatePickerConfig} from '../../models/components/KrcDatePickerModel'

const configDatePicker = {
  default: {
    size: 'middle',
    format: 'DD.MM.YYYY HH:mm',
    showTime: false,
  } as PickerProps<Moment>,
}

export const KrcDatePicker: FC<IDatePickerConfig & PickerProps<Moment> & IDatePicker> = ({
  config = 'default',
  onChanged,
  clear,
  ...props
}) => {
  const currentProps = useKrcProp({config: configDatePicker[config], props}).getCurrentProps()
  const {handleOnChange, value, setValue} = useDatePicker()

  useEffect(() => {
    if (clear) {
      setValue(null)
      if (onChanged) {
        handleOnChange(null, onChanged)
      }
    }
  }, [clear])

  return (
    <>
      <ConfigProvider locale={locale}>
        <DatePicker
          value={value}
          onClear={() => {
            setValue(null)
            if (!onChanged) {
              return
            }
            handleOnChange(undefined, onChanged)
          }}
          onChange={(data) => {
            setValue(data)

            if (!onChanged) {
              return
            }
            handleOnChange(data, onChanged)
          }}
          {...currentProps}
        />
      </ConfigProvider>
    </>
  )
}
