import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, message} from 'antd'
import _ from 'lodash'
import React, {createContext, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {krcSelectOptions} from '../../components/models/components/KrcSelectModel'
import {
  layoutComponentButtonDataType,
  layoutComponentDataType,
  layoutComponentDetailDataType,
} from '../../components/models/layouts/LayoutModel'
import {ComponentAttributeEnum} from '../../enums/ComponentAttributeEnum'
import {errorMessage} from '../../helpers/ErrorHelper'
import {
  deleteComponentRequest,
  getComponentRequest,
  getComponentsRequest,
  updateComponentRequest,
} from '../../requests/layouts/ComponentRequest'

const {confirm} = Modal

interface ILayoutComponentDetailContext {
  component: layoutComponentDetailDataType
  handleChangeComponent: (component: layoutComponentDetailDataType) => void
  componentTypes: krcSelectOptions[]
  updateComponentData: (value: any) => void
  deleteComponent: () => void
  images: string[]
  isBannerLoading: boolean
}

export const LayoutComponentDetailContext = createContext<ILayoutComponentDetailContext>({
  component: {
    id: 0,
    reactKey: 'default',
    name: '',
    component_type: {value: 0, label: 'Varsayılan'},
    page: null,
    component_banner: null,
    banner_name: '',
    store: {value: 2, label: 'Karaca'},
    image: '',
    component_name: '',
    status: '0',
  },
  handleChangeComponent: () => {},
  updateComponentData: () => {},
  deleteComponent: () => {},
  componentTypes: [],
  images: [],
  isBannerLoading: false,
})

export const LayoutComponentDetailProvider = ({children}: any) => {
  const [component, setComponent] = useState<layoutComponentDetailDataType>({
    id: 0,
    reactKey: 'default',
    name: '',
    component_type: {value: 0, label: 'Varsayılan'},
    page: null,
    component_banner: null,
    banner_name: '',
    store: {value: 2, label: 'Karaca'},
    image: '',
    component_name: '',
    status: '0',
  })
  const {componentId} = useParams()
  const [componentTypes, setComponentTypes] = useState<krcSelectOptions[]>([])
  const updateRef = useRef(0)
  const [images, setImages] = useState<string[]>([])
  const [isBannerLoading, setIsBannerLoading] = useState<boolean>(false)
  const navigator = useNavigate()

  const handleChangeComponent = (component: layoutComponentDetailDataType) => {
    updateComponentData(component)
    updateComponent()
  }

  const updateComponent = () => {
    const updateData = {
      name: component.name,
      type: component.component_type?.value,
      page_id: component.page?.value,
      store_id: component.store?.value,
      is_active: component.status === '1',
      banner_id: component.component_banner?.value,
    }
    updateRef.current += 1
    const ref = updateRef.current

    const key = 'update-component'
    message.loading({content: 'Güncelleniyor', key})

    // TODO: Button kapatılabilir. kullanıcı deneyimi incelenecek.
    updateComponentRequest(Number(componentId), updateData)
      .then(() => {
        if (ref !== updateRef.current) {
          return
        }
        message.success({content: 'Component güncellendi', key})
        getComponent()
      })
      .catch((e) => {
        message.destroy(key)
        errorMessage(e)
      })
  }

  const getComponent = () => {
    if (!componentId) {
      return
    }

    getComponentRequest(Number(componentId)).then((res) => {
      const component = res.data.data as layoutComponentDataType
      const componentName = _.find(
        component.component_eavs,
        (eav) => eav.attribute === ComponentAttributeEnum.COMPONENT_NAME
      )?.value as string | null
      let componentBanner = null

      if (component.banner) {
        if (component.banner.images) {
          setImages(
            component.banner.images
              .filter((image) => image.full_image)
              .map((image) => image.full_image)
          )
        }
        setIsBannerLoading(true)
        componentBanner = {value: component.banner.banner_id, label: component.banner.name}
      }

      setComponent({
        id: component.id,
        reactKey: `${Math.floor(Math.random() * 1000000 + 1)}-${component.component?.type}`,
        name: componentName ?? '',
        component_type: {value: component.component?.id ?? 0, label: component.component?.name},
        page: {value: component.page?.id ?? 0, label: component.page?.name},
        component_banner: componentBanner,
        component_name: componentName ?? '',
        store: {value: component.page?.store?.store_id ?? 0, label: component.page?.store?.name},
        status: component.is_active ? '1' : '0',
        banner_name: '',
      })
    })
  }

  const getComponentTypes = () => {
    getComponentsRequest().then((res) => {
      if (!res.data.data) {
        return
      }

      const componentTypes = res.data.data.map(({id, name}: layoutComponentButtonDataType) => {
        return {
          value: id,
          label: name,
        }
      }) as krcSelectOptions[]

      setComponentTypes(componentTypes)
    })
  }

  const updateComponentData = (value: any) => {
    setComponent((item) => ({...item, ...value}))
  }

  const deleteComponent = () => {
    confirm({
      title: `Component silenecek.`,
      icon: <ExclamationCircleOutlined />,
      content: 'Silme işlemi geri alınamaz emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        deleteComponentRequest(Number(componentId)).then(() => {
          navigator('/layouts')
        })
      },
    })
  }

  useEffect(() => {
    getComponent()
    getComponentTypes()
  }, [])

  return (
    <>
      <LayoutComponentDetailContext.Provider
        value={{
          images,
          isBannerLoading,
          component,
          componentTypes,
          deleteComponent,
          handleChangeComponent,
          updateComponentData,
        }}
      >
        {children}
      </LayoutComponentDetailContext.Provider>
    </>
  )
}
