import {Badge} from 'antd'
import React, {FC} from 'react'

type StatusCellProps = {
  status: boolean
}

export const StatusCell: FC<StatusCellProps> = ({status}) => {
  return (
    <>
      {status && (
        <div>
          Aktif <Badge className={'ml-2'} color='green' />
        </div>
      )}
      {!status && (
        <div>
          Pasif <Badge className={'ml-2'} color='red' />
        </div>
      )}
    </>
  )
}
