import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const IBM_CAMPAIGN_URL = `${API_URL}/information`

export function fetchMainInformationRequest(storeId: number) {
  return axios.get(`${IBM_CAMPAIGN_URL}`, {
    params: {
      store_id: storeId,
    },
  })
}

export function fetchInformationSubTopicRequest(
  topicId: number,
  storeId: number,
  filter: any,
  {page, limit}: paginationMetaType
) {
  return axios.get(`${IBM_CAMPAIGN_URL}/${topicId}/subtopics`, {
    params: {
      store_id: storeId,
      page,
      limit,
      ...filter,
    },
  })
}

export function createInformationSubTopicRequest(topicId: number, data: any) {
  return axios.post(`${IBM_CAMPAIGN_URL}/${topicId}/subtopics`, data)
}

export function fetchInformationSubTopicDetailRequest(
  topicId: number,
  subTopicId: number,
  storeId: number
) {
  return axios.get(`${IBM_CAMPAIGN_URL}/${topicId}/subtopics/${subTopicId}`, {
    params: {
      store_id: storeId,
    },
  })
}

export function updateInformationSubTopicDetailRequest(
  topicId: number,
  subTopicId: number,
  storeId: number,
  data: any
) {
  return axios.put(`${IBM_CAMPAIGN_URL}/${topicId}/subtopics/${subTopicId}`, data, {
    params: {
      store_id: storeId,
    },
  })
}

export function removeInformationSubTopicRequest(
  topicId: number,
  subTopicId: number,
  storeId: number
) {
  return axios.delete(`${IBM_CAMPAIGN_URL}/${topicId}/subtopics/${subTopicId}`, {
    params: {
      store_id: storeId,
    },
  })
}
