import React, {FC} from 'react'

import {
  DEAL_PRODUCT_ACTIVE,
  DEAL_PRODUCT_CAMPAIGN_EXPIRED,
  DEAL_PRODUCT_EXPIRED,
  DEAL_PRODUCT_PENDING_APPROVAL,
  DEAL_PRODUCT_REJECTED,
  DEAL_PRODUCT_WILL_BE_ACTIVE,
} from '../../../../../enums/DealProductEnum'
import {KrcTabs} from '../../../../molecules/KrcTabs'
import {DealProduct} from './DealProduct'

export const DealProductWrapper: FC = () => {
  return (
    <div className={''}>
      <KrcTabs
        isActiveKey={false}
        tabsProps={{defaultActiveKey: 'active'}}
        tabProps={[
          {
            eventKey: 'active',
            title: 'Aktif Ürünler',
            children: <DealProduct pageType={DEAL_PRODUCT_ACTIVE} />,
          },
          {
            eventKey: 'will-be-active',
            title: 'Aktif Olmayı Bekleyen Ürünler',
            children: <DealProduct pageType={DEAL_PRODUCT_WILL_BE_ACTIVE} />,
          },
          {
            eventKey: 'pending-approval',
            title: 'Onay Bekleyen Ürünler',
            children: <DealProduct pageType={DEAL_PRODUCT_PENDING_APPROVAL} />,
          },
          {
            eventKey: 'expired',
            title: 'Fırsatı Biten Ürünler',
            children: <DealProduct pageType={DEAL_PRODUCT_EXPIRED} />,
          },
          {
            eventKey: 'campaign-expired',
            title: 'Kampanya Süresi Bitmiş Ürünler',
            children: <DealProduct pageType={DEAL_PRODUCT_CAMPAIGN_EXPIRED} />,
          },
          {
            eventKey: 'rejected',
            title: 'Kampanyası Reddedilen Ürünler',
            children: <DealProduct pageType={DEAL_PRODUCT_REJECTED} />,
          },
        ]}
      />
    </div>
  )
}
