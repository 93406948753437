import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const IBM_CAMPAIGN_URL = `${API_URL}/ibm/campaigns`

export function fetchIBMCampaignGeneralInformation(
  campaignId: number,
  isDetail: number = 0,
  isSpecialGroup: number = 0,
  isPDP: number = 0
) {
  return axios.get(`${IBM_CAMPAIGN_URL}/${campaignId}`, {
    params: {
      is_detail: isDetail,
      is_special_group: isSpecialGroup,
      is_pdp: isPDP,
    },
  })
}

export function updateIBMCampaignGeneralInformation(
  campaignId: number,
  data: any,
  isDetail: number = 0,
  isSpecialGroup: number = 0,
  isPDP: number = 0
) {
  return axios.put(`${IBM_CAMPAIGN_URL}/${campaignId}`, {
    ...data,
    is_detail: isDetail,
    is_special_group: isSpecialGroup,
    is_pdp: isPDP,
  })
}

export function fetchIBMCampaignsSpecialGroup(
  campaignId: number,
  {page, limit}: paginationMetaType,
  all_data: number = 0
) {
  return axios.get(`${IBM_CAMPAIGN_URL}/${campaignId}/special-groups`, {
    params: {
      page,
      limit,
      all_data,
    },
  })
}

export function storeIBMCampaign(groupId: number, data: any) {
  return axios.post(`${IBM_CAMPAIGN_URL}/${groupId}`, {
    ...data,
    is_manual: 1,
  })
}

export function updateSpecialGroup(campaignId: number, specialGroupId: number, data: any) {
  return axios.put(`${IBM_CAMPAIGN_URL}/${campaignId}/special-groups/${specialGroupId}`, data)
}

export function fetchIBMCampaignsSpecialGroupProducts(
  specialGroupId: number,
  {page, limit}: paginationMetaType,
  all_data: number = 0
) {
  return axios.get(`${IBM_CAMPAIGN_URL}/special-groups/${specialGroupId}/products`, {
    params: {
      page,
      limit,
      all_data,
    },
  })
}

export function updateIBMCampaignSpecialGroupProductsSort(specialGroupId: number, data: number[]) {
  return axios.put(`${IBM_CAMPAIGN_URL}/special-groups/${specialGroupId}/products/sort`, {
    product_ids: data,
  })
}

export function updateIBMCampaignSort(specialGroupId: number, data: number[]) {
  return axios.put(`${IBM_CAMPAIGN_URL}/${specialGroupId}/campaigns/sort`, {
    ibm_campaign_ids: data,
  })
}

export function updateIBMCampaignSpecialGroupsSort(specialGroupId: number, data: number[]) {
  return axios.put(`${IBM_CAMPAIGN_URL}/special-groups/${specialGroupId}/sort`, {
    special_group_ids: data,
  })
}

export function getIBMCampaignsExportFrontendUrl(ibmGroupId: number) {
  return `/ibm/campaign/export?group_id=${ibmGroupId}`
}

export function exportIBMCampaignsRequest(ibmGroupId: number) {
  return axios.get(`${IBM_CAMPAIGN_URL}/${ibmGroupId}/export`)
}

export function checkIBMCampaignsExportRequest(ibmGroupId: number, fileName: string) {
  return axios.get(`${IBM_CAMPAIGN_URL}/${ibmGroupId}/check-export`, {
    params: {
      file_name: fileName,
    },
  })
}
