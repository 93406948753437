import {Field, Form, Formik} from 'formik'
import React, {FC, useContext} from 'react'
import * as Yup from 'yup'

import {LandingContext} from '../../../../providers/LandingProvider'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

const schema = Yup.object({
  name: Yup.string().required('Landing ismi zorunlu alan'),
  status: Yup.string().required('Landing durumu zorunlu alan'),
  page_type: Yup.string().required('Landing sayfa tipi zorunlu alan'),
})

export const LandingDetail: FC = () => {
  const {updateOrCreateData, onChangeValidate, setIsValid} = useContext(LandingContext)

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={updateOrCreateData.landing}
        onSubmit={() => {}}
        validate={onChangeValidate}
        validateOnMount={true}
      >
        {(formik) => {
          setTimeout(() => {
            setIsValid(formik.isValid)
          }, 0)

          return (
            <Form className='form row mt-10' noValidate>
              <KrcFormikField
                className={'mb-2 col-md-10'}
                name={'name'}
                labelTitle={'Sayfa ismi'}
                required={true}
              />
              <KrcFormikField
                className={'mb-2 col-md-10'}
                name={'meta_description'}
                labelTitle={'Açıklama'}
              >
                <Field
                  component='textarea'
                  name='meta_description'
                  className='form-control'
                  style={{height: 100}}
                />
              </KrcFormikField>
              <KrcFormikField
                className={'mb-2 col-md-10'}
                name={'meta_keywords'}
                labelTitle={'Meta Keyword'}
              >
                <Field
                  component='textarea'
                  name='meta_keywords'
                  className='form-control'
                  style={{height: 100}}
                />
              </KrcFormikField>
              <KrcFormikField
                className={'mb-2 col-md-6'}
                name={'landing_page_url'}
                labelTitle={'Sayfa URL'}
              />

              <div className={'mb-2 col-md-6'}>
                <div className={'form-label mb-2 col-md-12 required'}>Sayfa Tipi</div>
                <div className={'d-flex  align-items-center'} style={{height: 43}}>
                  <KrcFormikField name={'page_type'}>
                    <div className={'col-md-6 col-sm-6 d-flex align-items-center'}>
                      <Field
                        className='form-label mr-2'
                        style={{width: 18, height: 18}}
                        type='radio'
                        name='page_type'
                        value={'webview'}
                        checked={true}
                        placeholder='Landing Page URL'
                      />
                      <span>Webview</span>
                    </div>
                  </KrcFormikField>
                </div>
              </div>

              <KrcFormikField name={'redirect_url'} labelTitle={'301 Yönlendirme URL'} />

              <KrcFormikField
                className={'mb-2 col-md-2'}
                name={'status'}
                labelTitle={'Durumu'}
                required={true}
              >
                <Field
                  as='select'
                  name='status'
                  className='form-select'
                  aria-label='Select example'
                >
                  <option value=''>Seçiniz</option>
                  <option value='1'>Aktif</option>
                  <option value='0'>Pasif</option>
                </Field>
              </KrcFormikField>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
