import {FC} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {CouponUpdateTabView} from './CouponUpdateTabView'

export const CouponUpdateFormWrapper: FC = () => {
  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Kupon Detay</PageTitle>
      <CouponUpdateTabView />
    </>
  )
}
