import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  ProductUpdateImportContext,
  ProductUpdateImportProvider,
} from '../../../../providers/products/ProductUpdateImportProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {ProductsUpdateImport} from './ProductsUpdateImport'

const ProductUpdateImportToolbarRightField: FC = () => {
  const {productUpdateImportId, approveProductUpdateImport, redirectToDownloadExampleFile} =
    useContext(ProductUpdateImportContext)
  return (
    <>
      {productUpdateImportId ? (
        <div className='d-flex align-items-center py-1'>
          <KrcButton
            size={'middle'}
            onClick={approveProductUpdateImport}
            id='kt_toolbar_primary_button'
          >
            {'Onayla'}
          </KrcButton>
        </div>
      ) : (
        <div className='d-flex align-items-center py-1'>
          <KrcButton
            size={'middle'}
            onClick={redirectToDownloadExampleFile}
            id='kt_toolbar_primary_button'
          >
            {'Örnek Excel İndir'}
          </KrcButton>
        </div>
      )}
    </>
  )
}

const ProductsUpdateImportWrapperWithoutProvider: FC = () => {
  return (
    <>
      <Toolbar rightField={<ProductUpdateImportToolbarRightField />}></Toolbar>
      <PageTitle>Toplu Ürün Güncelleme</PageTitle>
      <ProductsUpdateImport />
    </>
  )
}

export const ProductsUpdateImportWrapper: FC = () => {
  return (
    <>
      <ProductUpdateImportProvider>
        <ProductsUpdateImportWrapperWithoutProvider />
      </ProductUpdateImportProvider>
    </>
  )
}
