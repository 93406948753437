import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const PERMISSION_URL = `${API_URL}/permissions`

export function fetchPermissionsRequest(filter: any, {page, limit}: paginationMetaType) {
  return axios.get(`${PERMISSION_URL}`, {
    params: {
      page,
      limit,
      ...filter,
    },
  })
}

export function createPermissionRequest(data: any) {
  return axios.post(`${PERMISSION_URL}`, data)
}

export function deletePermissionRequest(permissionId: number) {
  return axios.delete(`${PERMISSION_URL}/${permissionId}`)
}
