import {ICategoryBanner} from '../../../components/models/categories/CategoryBannerModel'
import {
  ADD_CATEGORY_BANNER,
  CHANGE_VISIBLE_CATEGORY_BANNER_MODAL,
  DELETE_CATEGORY_BANNER,
  SET_CATEGORY_BANNER,
  SET_CATEGORY_BANNERS,
  SET_SELECTED_CATEGORY_BANNERS,
  UPDATE_CATEGORY_BANNER,
} from './CategoryBannerActionType'

export const setBanners = (banners: ICategoryBanner[]) => {
  return {
    type: SET_CATEGORY_BANNERS,
    payload: banners,
  }
}

export const setBanner = (banner: ICategoryBanner | null) => {
  return {
    type: SET_CATEGORY_BANNER,
    payload: banner,
  }
}

export const addBanner = (banner: ICategoryBanner | null) => {
  return {
    type: ADD_CATEGORY_BANNER,
    payload: banner,
  }
}

export const updateBanner = (bannerId: number, banner: ICategoryBanner | null) => {
  return {
    type: UPDATE_CATEGORY_BANNER,
    payload: {banner, bannerId},
  }
}

export const deleteBanner = (bannerId: number) => {
  return {
    type: DELETE_CATEGORY_BANNER,
    payload: bannerId,
  }
}

export const changeVisibleBannerModal = (visible: boolean) => {
  return {
    type: CHANGE_VISIBLE_CATEGORY_BANNER_MODAL,
    payload: visible,
  }
}

export const selectBanners = (banners: ICategoryBanner[]) => {
  return {
    type: SET_SELECTED_CATEGORY_BANNERS,
    payload: banners,
  }
}
