import axios from 'axios'
import * as querystring from 'query-string'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
export const SORTING_TYPES_URL = `${API_URL}/sorting-types`
export const WEIGHT_TYPES_URL = `${API_URL}/sorting-weight-types`
export const SORTING_WEIGHT_URL = `${API_URL}/sorting-weights`

export function getSortingTypesRequest() {
  return axios.get(`${SORTING_TYPES_URL}`)
}

export function getWeightTypesRequest() {
  return axios.get(`${WEIGHT_TYPES_URL}`)
}

export function updateSortingWeightRequest(sortingType: string, weightType: string, value: number) {
  return axios.put(`${SORTING_WEIGHT_URL}/${sortingType}/${weightType}`, {
    value,
  })
}

export function getSortingWeightRequest() {
  return axios.get(`${SORTING_WEIGHT_URL}`)
}

export function getSortingWeightLogsRequest({limit, page}: paginationMetaType) {
  return axios.get(`${SORTING_WEIGHT_URL}/logs`, {
    params: {
      limit,
      page,
    },
  })
}

export const createSortingWeightRequest = (sortingType: string) => {
  return axios.post(`${SORTING_WEIGHT_URL}`, {
    sorting_type: sortingType,
  })
}

export function sortingWeightExportRequest() {
  return axios.get(`${SORTING_WEIGHT_URL}/export`)
}

export const exportSortingWeightListRequest = (params: object) => {
  return axios.get(`${SORTING_WEIGHT_URL}/export`, {
    params: params,
  })
}

export const checkSortingWeightListExportRequest = ($fileName: string) => {
  return axios.get(`${API_URL}/exports/check`, {
    params: {
      file_name: $fileName,
    },
  })
}

export const getSortingWeightExportFrontendUrl = () => {
  return `/categories/sorting-weights/export?${querystring.stringify(
    {},
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}
