import debounce from 'lodash/debounce'
import React, {useState} from 'react'

export const useReceivingTable = () => {
  const [tableFilter, setTableFilter] = useState<any>()
  const [selectedItems, setSelectedItems] = useState<any[]>([])

  const handleOnSearch = React.useMemo(() => {
    const loadTableFilterSearch = (value: string, rowId: any) => {
      if (value !== undefined && value.length < 2) {
        return
      }

      setTableFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilterSearch, 250)
  }, [])

  const updateSelectedItems = (data: any[]) => {
    setSelectedItems(data)
  }

  return {handleOnSearch, tableFilter, selectedItems, updateSelectedItems}
}
