import React, {FC, useContext} from 'react'

import {CategoryLandingContext} from '../../../../../providers/category/landings/CategoryLandingProvider'
import {IMenuLandingMap} from '../../../../models/menus/MenuModel'
import {MappingTableBody} from '../../../../organisms/mapping_table/MappingTableBody'
import {MappingTableHeader} from '../../../../organisms/mapping_table/MappingTableHeader'

export const MenuLandingMap: FC<IMenuLandingMap> = ({headerProps}) => {
  const {menus, menuColumns, setSelectedMenuId} = useContext(CategoryLandingContext)
  return (
    <>
      <MappingTableHeader {...headerProps}></MappingTableHeader>

      <MappingTableBody
        data={menus}
        columns={menuColumns}
        onSelect={(item: any) => {
          setSelectedMenuId(item.original.id)
        }}
      />
    </>
  )
}
