import {BarsOutlined, EditOutlined} from '@ant-design/icons'
import {Badge, Tooltip, message} from 'antd'
import React, {createContext, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {Column} from 'react-table'

import {usePagination} from '../../components/hooks/pagination/usePagination'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {
  IBmCampaignSpecialGroupSortType,
  IBmCampaignSpecialGroupType,
} from '../../components/models/ibm/IBMCampaignSpecialGroupModel'
import {GENERAL_INFO} from '../../enums/IBMCampaignTabTypeEnums'
import {
  fetchIBMCampaignsSpecialGroup,
  updateIBMCampaignSpecialGroupsSort,
} from '../../requests/ibm/IBMCampaignRequest'
import {initialMetaData} from '../tables/ProductSelectionProvider'

interface IIBMCampaignSpecialGroupContext {
  meta: paginationMetaType
  selectedCampaignId: number
  setSelectedCampaignId: React.Dispatch<React.SetStateAction<number>>
  willBeUpdateSpecialGroup: IBmCampaignSpecialGroupType
  setWillBeUpdateSpecialGroup: React.Dispatch<React.SetStateAction<IBmCampaignSpecialGroupType>>
  showSpecialGroupUpdateModal: boolean
  setShowSpecialGroupUpdateModal: React.Dispatch<React.SetStateAction<boolean>>
  isShowSortingCampaignSpecialGroupModal: boolean
  setIsShowSortingCampaignSpecialGroupModal: React.Dispatch<React.SetStateAction<boolean>>
  IBMSortedCampaignSpecialGroups: IBmCampaignSpecialGroupSortType[]
  setIBMSortedCampaignSpecialGroup: React.Dispatch<
    React.SetStateAction<IBmCampaignSpecialGroupSortType[]>
  >
  handleOnSaveIBMCampaignSpecialGroupSorting: () => void
  columns: Column<any>[]
  sortColumns: Column<any>[]
  IBMCampaignSpecialGroups: IBmCampaignSpecialGroupType[]
  setIBMCampaignSpecialGroupData: React.Dispatch<
    React.SetStateAction<IBmCampaignSpecialGroupType[]>
  >
  getIBMCampaignsSpecialGroup: (page: number | null, campaignId: number) => void
  pageType: string
  setPageType: React.Dispatch<React.SetStateAction<string>>
  changePage: (page: number) => void
  changeLimit: (limit: number) => void
}

export type UrlParamsType = {
  campaignId: string
}

export const IBMCampaignSpecialGroupContext = createContext<IIBMCampaignSpecialGroupContext>({
  meta: initialMetaData,
  selectedCampaignId: 0,
  setSelectedCampaignId: () => {},
  willBeUpdateSpecialGroup: {} as IBmCampaignSpecialGroupType,
  setWillBeUpdateSpecialGroup: () => {},
  showSpecialGroupUpdateModal: false,
  setShowSpecialGroupUpdateModal: () => {},
  isShowSortingCampaignSpecialGroupModal: false,
  setIsShowSortingCampaignSpecialGroupModal: () => {},
  IBMSortedCampaignSpecialGroups: [],
  setIBMSortedCampaignSpecialGroup: () => {},
  handleOnSaveIBMCampaignSpecialGroupSorting: () => {},
  columns: [],
  sortColumns: [],
  IBMCampaignSpecialGroups: [],
  setIBMCampaignSpecialGroupData: () => {},
  getIBMCampaignsSpecialGroup: () => {},
  pageType: GENERAL_INFO,
  setPageType: () => {},
  changePage: () => {},
  changeLimit: () => {},
})

export const IBMCampaignSpecialGroupProvider = ({children}: any) => {
  const [selectedCampaignId, setSelectedCampaignId] = React.useState<number>(0)
  const initialRef = React.useRef(0)
  const [IBMCampaignSpecialGroups, setIBMCampaignSpecialGroupData] = React.useState<
    IBmCampaignSpecialGroupType[]
  >([])
  const [willBeUpdateSpecialGroup, setWillBeUpdateSpecialGroup] =
    React.useState<IBmCampaignSpecialGroupType>({} as IBmCampaignSpecialGroupType)
  const [showSpecialGroupUpdateModal, setShowSpecialGroupUpdateModal] =
    React.useState<boolean>(false)
  const [isShowSortingCampaignSpecialGroupModal, setIsShowSortingCampaignSpecialGroupModal] =
    useState<boolean>(false)
  const [IBMSortedCampaignSpecialGroups, setIBMSortedCampaignSpecialGroup] = useState<
    IBmCampaignSpecialGroupSortType[]
  >([])

  const [pageType, setPageType] = useState<string>(GENERAL_INFO)
  const {campaignId} = useParams<UrlParamsType>()

  const columns = React.useMemo(
    () => [
      {
        Header: 'Özel Grup ID',
        accessor: 'id',
      },
      {
        Header: 'Özel Grup Adı',
        accessor: 'group_name',
      },
      {
        Header: 'Özel Grup Başlığı',
        accessor: 'group_title',
      },
      {
        Header: 'IBM Param Kodu',
        accessor: 'ibm_param_code',
      },
      {
        Header: 'IBM Param Value',
        accessor: 'ibm_param_value',
      },
      {
        Header: 'Zorunlu Alan',
        accessor: 'required',
        Cell: ({row}: any) => (
          <>
            {row.original.required ? (
              <div>
                Zorunlu <Badge className={'ml-2'} color='green' />
              </div>
            ) : (
              <div>
                Zorunlu Değil <Badge className={'ml-2'} color='red' />
              </div>
            )}
          </>
        ),
      },
      {
        Header: 'Sıralama',
        accessor: 'ibm_special_group_sort',
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        Cell: ({row}: any) => (
          <>
            <div className='d-flex flex-row justify-content-around'>
              <Tooltip title={'Başlığı Düzenle'}>
                <div
                  onClick={() => {
                    setWillBeUpdateSpecialGroup(row.original)
                    setShowSpecialGroupUpdateModal(true)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  <EditOutlined />
                </div>
              </Tooltip>
              <Tooltip title={'Ürünler'}>
                <Link
                  to={`/ibm/campaign/${row.original.id}/products?campaignId=${campaignId}`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                  style={{color: 'unset'}}
                >
                  <BarsOutlined />
                </Link>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    []
  )

  const sortColumns = React.useMemo(
    () => [
      {
        Header: 'Özel Grup ID',
        accessor: 'id',
      },
      {
        Header: 'Özel Grup Adı',
        accessor: 'group_name',
      },
      {
        Header: 'Özel Grup Başlığı',
        accessor: 'group_title',
      },
    ],
    []
  )

  useEffect(() => {
    initialRef.current = 1
  }, [selectedCampaignId])

  useEffect(() => {
    if (!IBMCampaignSpecialGroups) {
      return
    }
    setIBMCampaignSpecialGroupData(IBMCampaignSpecialGroups)
  }, [IBMCampaignSpecialGroups, selectedCampaignId])

  const getIBMCampaignsSpecialGroup = async (page: number | null, campaignId: number) => {
    fetchIBMCampaignsSpecialGroup(campaignId, meta)
      .then((res) => {
        setIBMCampaignSpecialGroupData(res.data.data)

        const metaData = res.data.meta

        updateMetaData({limit: metaData.per_page, page: metaData.current_page, ...metaData})
      })
      .catch((err) => {
        message.error('Özel gruplar getirilirken bir hata oluştu.')
        message.error(err.response.data.message)
      })
  }

  const {meta, updateMetaData, changePage, changeLimit} = usePagination({
    callback: getIBMCampaignsSpecialGroup,
  })

  const handleOnSaveIBMCampaignSpecialGroupSorting = async () => {
    let sortedCampaignSpecialGroups = IBMSortedCampaignSpecialGroups.map((item) => item.id)
    message.info('Özel gruplar sıralanıyor... Bu işlem biraz uzun sürebilir. Lütfe bekleyiniz.')
    await updateIBMCampaignSpecialGroupsSort(
      Number(selectedCampaignId),
      sortedCampaignSpecialGroups
    )
      .then((res) => {
        message.success('Özel gruplar sıralandı.')
        setIsShowSortingCampaignSpecialGroupModal(false)
      })
      .catch((err) => {
        message.error('Özel gruplar sıralanırken bir hata oluştu.')
        message.error(err.response.data.message)
      })
      .finally(() => {
        getIBMCampaignsSpecialGroup(null, selectedCampaignId)
        setIsShowSortingCampaignSpecialGroupModal(false)
      })
  }

  return (
    <IBMCampaignSpecialGroupContext.Provider
      value={{
        meta,
        changePage,
        changeLimit,
        selectedCampaignId,
        setSelectedCampaignId,
        willBeUpdateSpecialGroup,
        setWillBeUpdateSpecialGroup,
        showSpecialGroupUpdateModal,
        setShowSpecialGroupUpdateModal,
        isShowSortingCampaignSpecialGroupModal,
        setIsShowSortingCampaignSpecialGroupModal,
        IBMSortedCampaignSpecialGroups,
        setIBMSortedCampaignSpecialGroup,
        handleOnSaveIBMCampaignSpecialGroupSorting,
        sortColumns,
        IBMCampaignSpecialGroups,
        setIBMCampaignSpecialGroupData,
        columns,
        getIBMCampaignsSpecialGroup,
        pageType,
        setPageType,
      }}
    >
      {children}
    </IBMCampaignSpecialGroupContext.Provider>
  )
}
