import {PlusSquareOutlined} from '@ant-design/icons'
import {message} from 'antd'
import _ from 'lodash'
import * as querystring from 'querystring'
import React, {FC, createContext, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {useLocation} from 'react-router'
import {useNavigate, useParams} from 'react-router-dom'

import {KrcButton} from '../../components/atoms/buttons/KrcButton'
import {
  bannerDataType,
  bannerGroupDataType,
  bannerImageDataType,
  bannerImageType,
  bannerType,
} from '../../components/models/banners/BannerModel'
import {krcSelectOptions} from '../../components/models/components/KrcSelectModel'
import {DEFAULT_STORE} from '../../components/models/stores/StoreModel'
import {KrcPopoverWithInput} from '../../components/molecules/popover/KrcPopoverWithInput'
import {DndItemEditable} from '../../components/organisms/dnd/DndItemEditable'
import {errorMessage} from '../../helpers/ErrorHelper'
import {
  addBannerImagesRequest,
  multipleDeleteBannerImagesRequest,
  sortBannerImagesRequest,
} from '../../requests/banner/BannerImageRequest'
import {
  createBannerGroupRequest,
  getBannerGroupRequest,
  getBannerRequest,
  updateBannerRequest,
} from '../../requests/banner/BannerRequest'
import {createComponentBannerRequest} from '../../requests/layouts/ComponentRequest'

const LayoutComponentBannerImages: FC<{image_group_id?: number}> = ({image_group_id}) => {
  const {
    bannerImages,
    setBannerImages,
    publishBannerImage,
    getBannerGroupImages,
    addDeletedBannerImageIds,
    deleteBannerImageIndex,
    setDefaultImages,
    sortBannerImages,
    setSelectedBannerGroupId,
  } = useContext(BannerManagerContext)
  const {bannerId} = useParams()
  const navigator = useNavigate()

  useEffect(() => {
    if (!image_group_id) {
      setDefaultImages()
      setSelectedBannerGroupId(null)
      return
    }

    setSelectedBannerGroupId(image_group_id)
    getBannerGroupImages(image_group_id)
  }, [image_group_id])

  const renderComponent = useCallback(
    (banner: bannerImageType, index: number) => {
      return (
        <DndItemEditable
          index={index}
          id={banner.id}
          key={`banner-image-${index}`}
          image={banner.image}
          mobileImage={banner.mobile_image}
          setState={setBannerImages}
          targetType={'banner-images'}
          title={banner.title}
          isEditable={!!banner.id}
          isVisibleButton={false}
          onClickEdit={handleOnClickEdit}
          onRemove={(bannerId, index) => {
            if (bannerId) {
              addDeletedBannerImageIds(bannerId as number)
              return
            }

            deleteBannerImageIndex(index)
          }}
          onChangeVisible={() => {}}
        />
      )
    },
    [bannerImages]
  )

  const handleOnClickEdit = (id: string | number) => {
    navigator(`/banners/${bannerId}/images/${id}`)
  }

  return (
    <>
      <div className={'d-flex flex-column gap-5 mt-5'}>
        <DndProvider backend={HTML5Backend}>
          {bannerImages.map((image, index) => {
            return renderComponent(image, index)
          })}
        </DndProvider>
      </div>
      {bannerId ? (
        <>
          <div className={'d-flex align-items-center justify-content-end gap-5 mt-10 mb-2'}>
            <KrcButton
              type={'default'}
              size={'large'}
              style={{width: 200}}
              onClick={sortBannerImages}
            >
              Sıralamayı Kaydet
            </KrcButton>
            <KrcButton
              type={'text'}
              size={'large'}
              onClick={publishBannerImage}
              className={'custom-hover w-200px text-white button-bg-purple'}
            >
              Yayınla
            </KrcButton>
          </div>
        </>
      ) : null}
    </>
  )
}

interface IBannerManagerContext {
  bannerImages: bannerImageType[]
  banner: bannerDataType
  setBannerImages: React.Dispatch<React.SetStateAction<bannerImageType[]>>
  setSelectedBannerGroupId: React.Dispatch<React.SetStateAction<number | null>>
  isStoreDisabled: boolean
  handleOnSubmit: (data: bannerDataType) => void
  updateBanner: (data: any) => void
  addBannerImage: () => void
  publishBannerImage: () => void
  setDefaultImages: () => void
  addDeletedBannerImageIds: (data: number) => void
  deleteBannerImageIndex: (index: number) => void
  tabProps: any[]
  getBannerGroupImages: (groupId: number) => void
  selectedBannerGroupId: number | null
  sortBannerImages: () => void
}

export const BannerManagerContext = createContext<IBannerManagerContext>({
  isStoreDisabled: false,
  bannerImages: [],
  tabProps: [],
  selectedBannerGroupId: 0,
  banner: {
    banner_name: '',
    store: DEFAULT_STORE,
  },
  setBannerImages: () => {},
  handleOnSubmit: () => {},
  updateBanner: () => {},
  addBannerImage: () => {},
  publishBannerImage: () => {},
  addDeletedBannerImageIds: () => {},
  deleteBannerImageIndex: () => {},
  getBannerGroupImages: () => {},
  setDefaultImages: () => {},
  setSelectedBannerGroupId: () => {},
  sortBannerImages: () => {},
})
export const BannerManagerProvider: FC = ({children}) => {
  const [bannerImages, setBannerImages] = useState<bannerImageType[]>([])
  const [deletedBannerImageIds, setDeletedBannerImageIds] = useState<number[]>([])
  const [addedBannerImageTitles, setAddedBannerImageTitles] = useState<
    {name: string; key: string}[]
  >([])
  const [banner, setBanner] = useState<bannerDataType>({
    banner_name: '',
    store: DEFAULT_STORE,
  })
  const [isStoreDisabled, setIsStoreDisabled] = useState(false)
  const location = useLocation()
  const state = location.state as {
    page_component_id?: number
    store?: krcSelectOptions | null
  }
  const [defaultBannerImages, setDefaultBannerImages] = useState<bannerImageType[]>([])
  const [selectedBannerGroupId, setSelectedBannerGroupId] = useState<number | null>(null)
  const createBannerGroupRef = useRef(0)
  const navigator = useNavigate()
  const {bannerId} = useParams()
  const [tabProps, setTabProps] = useState([
    {
      eventKey: 'default',
      title: 'Varsayılan',
      image_group_id: 0,
      children: <LayoutComponentBannerImages />,
    },
    {
      title: (
        <KrcPopoverWithInput
          popoverProps={{
            title: 'Yeni Banner Grup',
          }}
          krcInputProps={{
            placeholder: 'Yeni Banner Grup',
          }}
          onSave={(title) => {
            createBannerGroup(title)
          }}
        >
          <KrcButton
            className={'rounded-bottom-0 bg-secondary m-0'}
            type={'default'}
            style={{height: 33}}
            icon={<PlusSquareOutlined />}
          />
        </KrcPopoverWithInput>
      ),
      image_group_id: 0,
      children: <></>,
    },
  ])

  useEffect(() => {
    if (state?.store) {
      setIsStoreDisabled(true)
    }
  }, [])

  const updateBanner = (value: any) => {
    setBanner((prev) => ({...prev, ...value}))
  }

  const addDeletedBannerImageIds = (id: number) => {
    setDeletedBannerImageIds((prev) => [...prev, id])
    setBannerImages((prev) => prev.filter((image) => image.id !== id))
  }

  const deleteBannerImageIndex = (index: number) => {
    const banner = _.find(bannerImages, (_, i) => i === index)

    setAddedBannerImageTitles((prev) => prev.filter((image) => image.key !== banner?.key))
    setBannerImages((prev) => prev.filter((_, i) => i !== index))
  }

  const handleOnSubmit = (banner: bannerDataType) => {
    if (state?.page_component_id) {
      createComponentBannerRequest(state.page_component_id, {
        name: banner.banner_name,
        store_id: banner.store.value,
        status: true,
      }).then((res) => {
        const _banner = res.data.data as bannerType
        setBanner({
          banner_name: _banner.name,
          store: {
            value: _banner.store?.store_id ?? 0,
            label: _banner.store?.name,
          },
        })

        navigator(
          `/banners/${_banner.banner_id}?${querystring.stringify({
            page_component_id: state.page_component_id,
          })}`
        )
      })
    }
  }

  const getBanner = () => {
    if (!bannerId) {
      return
    }

    getBannerRequest(Number(bannerId)).then((res) => {
      const _banner = res.data.data as bannerType
      setBanner({
        banner_name: _banner.name,
        store: {
          value: _banner?.store?.store_id ?? 0,
          label: _banner?.store?.name ?? 'Store Yok',
        },
      })

      _banner.groups.forEach((group) => {
        return addTabProps(group.group_name, group.group_id)
      })

      const formattedBannerImages = formatBannerImages(
        _banner.images.filter(
          (image) =>
            image.banner_group_id === selectedBannerGroupId ||
            (selectedBannerGroupId === null && image.banner_group_id === 0)
        )
      )
      setDefaultBannerImages(formattedBannerImages)
      setBannerImages(formattedBannerImages)
    })
  }

  const formatBannerImages = (images: bannerImageDataType[]) => {
    return images.map((image, key) => {
      return {
        id: image.banner_image_id,
        key: `banner-${image.banner_image_id}`,
        title: image.descriptions[0]?.title || `Banner Görsel ${key + 1}`,
        image: image.full_image,
        mobile_image: image.full_mobile_image,
        group_id: image.banner_group_id,
      }
    }) as bannerImageType[]
  }

  const addBannerImage = () => {
    message.success('Banner eklendi. Kaydedilmesi için yayınla buttonuna basın.')

    const defaultName = `Banner Görsel ${bannerImages.length + 1}`
    const key = `banner-without-added-${Math.floor(Math.random() * 1000000 + 1)}`
    setAddedBannerImageTitles((addBannerImages) => [...addBannerImages, {name: defaultName, key}])
    setBannerImages((bannerImages) => [
      ...bannerImages,
      {id: 0, key, title: defaultName, image: '', status: false, group_id: selectedBannerGroupId},
    ])
  }

  const publishBannerImage = () => {
    const key = 'publish-banner-image'
    if (addedBannerImageTitles.length !== 0 || deletedBannerImageIds.length !== 0) {
      message.loading({content: 'Banner Güncelleniyor.', key})
    }

    if (addedBannerImageTitles.length !== 0) {
      const bannerImageData = addedBannerImageTitles.map((i) => {
        return {
          name: i.name,
          banner_group_id: selectedBannerGroupId,
        }
      })

      addBannerImagesRequest(Number(bannerId), bannerImageData).then(() => {
        setAddedBannerImageTitles([])
        if (deletedBannerImageIds.length === 0) {
          getBanner()
          message.success({content: 'Banner güncellendi.', key})
        }
      })
    }

    if (deletedBannerImageIds.length !== 0) {
      multipleDeleteBannerImagesRequest(Number(bannerId), deletedBannerImageIds).then(() => {
        setDeletedBannerImageIds([])
        getBanner()
        message.success({content: 'Banner güncellendi.', key})
      })
    }

    const storeId = banner.store.value === 0 ? -1 : banner.store.value
    updateBannerRequest(Number(bannerId), {name: banner.banner_name, store_id: storeId as number})
  }

  const getBannerGroupImages = (bannerGroupId: number) => {
    setBannerImages([])

    getBannerGroupRequest(Number(bannerId), bannerGroupId).then((res) => {
      const bannerGroup = res.data.data as bannerGroupDataType
      const images = formatBannerImages(bannerGroup.images)

      setBannerImages(images)
    })
  }

  useEffect(() => {
    if (!bannerId) {
      return
    }

    getBanner()
  }, [bannerId])

  const createBannerGroup = (title: string) => {
    const key = 'create-banner-group'
    message.loading({content: 'Banner Grubu Ekleniyor...', key})
    createBannerGroupRef.current += 1
    const fetchRef = createBannerGroupRef.current

    createBannerGroupRequest(Number(bannerId), {name: title})
      .then((res) => {
        const group = res.data.data as bannerGroupDataType

        if (createBannerGroupRef.current !== fetchRef) {
          return
        }

        const eventKey = _.kebabCase(title)
        addTabProps(title, group.group_id)
        navigator(`#${eventKey}`)
        setBannerImages([])
        message.success({content: 'Banner Grubu Eklendi.', key})
      })
      .catch((e) => {
        message.error({content: 'Banner Grubu Ekleme Hatası', key})
        errorMessage(e)
      })
  }

  const addTabProps = (title: string, imageGroupId: number) => {
    if (tabProps.some((tabProp) => tabProp.image_group_id === imageGroupId)) {
      return
    }

    setTabProps((tabProps) => {
      const insertIndex = tabProps.length - 1 // Dizinin sonundan bir önceki index
      const eventKey = _.kebabCase(title)

      tabProps.splice(insertIndex, 0, {
        eventKey,
        title,
        image_group_id: imageGroupId,
        children: <LayoutComponentBannerImages image_group_id={imageGroupId} />,
      })

      return tabProps
    })
  }

  const setDefaultImages = () => {
    setBannerImages(defaultBannerImages)
  }

  const sortBannerImages = () => {
    const key = 'sort-banner-image'
    message.loading({content: 'Banner Resimleri Sıralanıyor...', key})

    sortBannerImagesRequest(bannerImages.map((i) => i.id))
      .then(() => {
        message.success({content: 'Banner Resimleri Sıralandı.', key})
      })
      .catch((e) => {
        errorMessage(e)
        message.error({content: 'Banner Resimleri Sıralama Hatası', key})
      })
  }

  return (
    <>
      <BannerManagerContext.Provider
        value={{
          bannerImages,
          banner,
          isStoreDisabled,
          tabProps,
          selectedBannerGroupId,
          updateBanner,
          handleOnSubmit,
          addBannerImage,
          setBannerImages,
          sortBannerImages,
          setDefaultImages,
          publishBannerImage,
          getBannerGroupImages,
          deleteBannerImageIndex,
          setSelectedBannerGroupId,
          addDeletedBannerImageIds,
        }}
      >
        {children}
      </BannerManagerContext.Provider>
    </>
  )
}
