import {Form, Formik} from 'formik'
import React, {FC, useContext, useEffect} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {CategoryProductsProcessContext} from '../../../providers/category/products/CategoryProductsProcessProvider'
import {requestDateFormat} from '../../../service/DateService'
import {KrcDatePicker} from '../../atoms/forms/KrcDatePicker'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'
import {KrcModal} from '../KrcModal'

type CategoryBatchConstraintProductModalType = {
  categoryId: number
}

export const CategoryBatchConstraintProductModal: FC<CategoryBatchConstraintProductModalType> = ({
  categoryId,
}) => {
  const {
    setVisibleCategoryConstraintProductModal,
    setWillBeUpdatedBatchConstraintProducts,
    selectedConstraintProducts,
    willBeUpdatedBatchConstraintProducts,
    updateSelectedConstraintProducts,
  } = useContext(CategoryProductsProcessContext)

  const initialValues = {
    category_id: categoryId,
    constraint_ids: selectedConstraintProducts.map((item) => item.id),
    date_start: undefined,
    date_end: undefined,
    quantity: 0,
  }
  useEffect(() => {
    setWillBeUpdatedBatchConstraintProducts(initialValues)
  }, [])

  return (
    <>
      <KrcModal
        modalProps={{
          onHide: () => {
            setVisibleCategoryConstraintProductModal(false)
          },
        }}
        modalHeaderProps={{
          title: 'Toplu Kısıtlı Ürünleri Güncelle',
          onHide: () => {
            setVisibleCategoryConstraintProductModal(false)
          },
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10 row justify-content-center'>
          {selectedConstraintProducts.length > 0 && (
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values) => {
                updateSelectedConstraintProducts({
                  ...values,
                  date_start: requestDateFormat(willBeUpdatedBatchConstraintProducts?.date_start),
                  date_end: requestDateFormat(willBeUpdatedBatchConstraintProducts?.date_end),
                })
              }}
            >
              {() => (
                <Form className='form row' noValidate>
                  <KrcFormikField name={'date'} required={false} labelTitle={'Başlangıç Tarihi'}>
                    <KrcDatePicker
                      showTime
                      allowClear={false}
                      className={'ml-4'}
                      onChange={(date, dateString) => {
                        setWillBeUpdatedBatchConstraintProducts((data) => {
                          return data
                            ? {
                                ...data,
                                date_start: date ?? undefined,
                              }
                            : {
                                ...initialValues,
                                date_start: date ?? undefined,
                              }
                        })
                      }}
                    ></KrcDatePicker>
                  </KrcFormikField>

                  <KrcFormikField name={'date'} required={false} labelTitle={'Bitiş Tarihi'}>
                    <KrcDatePicker
                      showTime
                      allowClear={false}
                      className={'ml-4'}
                      onChange={(date, dateString) => {
                        setWillBeUpdatedBatchConstraintProducts((data) => {
                          return data
                            ? {
                                ...data,
                                date_end: date ?? undefined,
                              }
                            : {
                                ...initialValues,
                                date_end: date ?? undefined,
                              }
                        })
                      }}
                    ></KrcDatePicker>
                  </KrcFormikField>

                  <KrcFormikField type={'number'} name={'quantity'} labelTitle={'Kampanya Stok'} />

                  <div className='col-md-12 d-flex justify-content-end mt-5'>
                    <button
                      type='submit'
                      className='w-25 btn btn-secondary mr-3'
                      onClick={() => {
                        setVisibleCategoryConstraintProductModal(false)
                      }}
                    >
                      İptal
                    </button>
                    <button type='submit' className='w-25 btn btn-primary'>
                      Güncelle
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </KrcModal>
    </>
  )
}
