import {PlusSquareOutlined} from '@ant-design/icons'
import {ButtonProps} from 'antd'
import React, {FC} from 'react'

import {KrcButton} from '../../atoms/buttons/KrcButton'
import {useKrcProp} from '../../hooks/components/useKrcProp'

interface IComponentCreateButton {
  title: string
}

const currentButtonProps = {
  className: 'd-flex align-items-center',
}

export const ComponentCreateButton: FC<IComponentCreateButton & ButtonProps> = ({
  title,
  ...props
}) => {
  const currentProps = useKrcProp({config: currentButtonProps, props}).getCurrentProps()

  return (
    <>
      <KrcButton
        icon={<PlusSquareOutlined style={{lineHeight: 0, color: '#888888'}} />}
        style={{backgroundColor: '#F4F4F4', borderColor: '#969696'}}
        size={'large'}
        {...currentProps}
      >
        <span style={{color: '#888888'}}>{title}</span>
      </KrcButton>
    </>
  )
}
