import axios from 'axios'

import {
  getDealProductsType,
  updateDealProductSortType,
} from '../../../components/models/deals/DealModel'

const API_URL = process.env.REACT_APP_API_URL || ''
export const DEAL_PRODUCT_URL = `${API_URL}/deals`

export const getDealProductRequest = ({
  dealId,
  relations,
  page_type,
  meta,
  filter,
}: getDealProductsType) => {
  let url = `${DEAL_PRODUCT_URL}/${dealId}/products`

  return axios.get(url, {
    params: {
      with: relations,
      page_type,
      page: meta.page,
      limit: meta.limit,
      ...filter,
    },
  })
}

export function updateDealProductRequest(
  dealId: number,
  dealProductId: number,
  body: any,
  params: object = {}
) {
  return axios.put(`${DEAL_PRODUCT_URL}/${dealId}/products/${dealProductId}`, body, {params})
}

export function createDealProductRequest(dealId: number, body: any, params: object = {}) {
  return axios.post(`${DEAL_PRODUCT_URL}/${dealId}/products/`, body, {params})
}

export function deleteDealProductRequest(dealId: number, dealProductId: number) {
  return axios.delete(`${DEAL_PRODUCT_URL}/${dealId}/products/${dealProductId}`)
}

export function removeDealProductApprovalCampaignRequest(
  dealProductId: number,
  productToCategoryApprovalId: number
) {
  return axios.delete(
    `${DEAL_PRODUCT_URL}/products/${dealProductId}/approval-campaign/${productToCategoryApprovalId}`
  )
}

export const dealProductUpdateSortRequest = ({
  dealId,
  dealProductIds,
}: updateDealProductSortType) => {
  let url = `${DEAL_PRODUCT_URL}/${dealId}/products/update-sort`

  return axios.put(url, {
    sorted_deal_product_ids: dealProductIds,
  })
}
