import cn from 'classnames'
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {LandingListProvider} from '../../../../providers/LandingListProvider'
import {getLandingListExportFrontendUrl} from '../../../../requests/landings/LandingExportRequest'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {LandingList} from './LandingList'

const LandingListWrapperWithoutProvider: FC = () => {
  const intl = useIntl()

  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <a
            type={'button'}
            className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-3'
            href={getLandingListExportFrontendUrl()}
            target={'_blank'}
            rel='noreferrer'
          >
            Dışarı Aktar
          </a>
          <Link
            to='/landings/create'
            className={cn({'btn btn-sm btn-primary': true})}
            id='kt_toolbar_primary_button'
          >
            Landing Oluştur
          </Link>
        </div>
      </Toolbar>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.LANDING'})}</PageTitle>
      <LandingList />
    </>
  )
}

export const LandingListWrapper: FC = () => {
  return (
    <>
      <LandingListProvider>
        <LandingListWrapperWithoutProvider />
      </LandingListProvider>
    </>
  )
}
