import debounce from 'lodash/debounce'
import {OptionData} from 'rc-select/lib/interface'
import React, {useEffect, useState} from 'react'

import {searchStoresRequest} from '../../../requests/stores/StoreRequest'
import {storeSearchType, storeType} from '../../models/stores/StoreModel'

interface IUseStoreFilter {
  debounceTime?: number
  storeType?: storeType
}

export const useStoreFilter = ({debounceTime = 500, storeType}: IUseStoreFilter) => {
  const [options, setOptions] = useState<OptionData[]>()
  const [fetching, setFetching] = useState<boolean>(false)
  const [selectedStoreIds, setSelectedStoreIds] = useState<number[]>([])
  const [selectedItems, setSelectedItems] = useState<OptionData[]>([])
  const fetchRef = React.useRef(0)
  const [search, setSearch] = useState<string>('')

  const handleSearch = React.useMemo(() => {
    const loadOptions = (search: string) => {
      searchStores(search)
    }

    return debounce(loadOptions, debounceTime)
  }, [])

  const searchStores = (search: string) => {
    setSearch(search)
    fetchRef.current += 1
    const fetchId = fetchRef.current

    setOptions([])
    setFetching(true)

    searchStoresRequest(search, storeType).then((res) => {
      if (fetchId !== fetchRef.current) {
        return
      }

      const stores: OptionData[] = res.data.data.map((store: storeSearchType) => {
        return {value: store.id, label: store.name}
      })

      setOptions(stores)
      setFetching(false)
    })
  }

  const handleOnChange = (payload: Array<OptionData> | OptionData) => {
    if (!payload) {
      return
    }

    if (Array.isArray(payload)) {
      setSelectedItems(payload)
      const storeIds = payload.map((data: OptionData) => data.value) as number[]
      setSelectedStoreIds(storeIds)

      return
    }

    setSelectedItems([payload] as Array<OptionData>)
    setSelectedStoreIds([payload.value as number])
  }

  useEffect(() => {
    if (search) {
      searchStores('')
    }
  }, [selectedItems])

  useEffect(() => {
    searchStores('')
  }, [])

  return {
    fetching,
    options,
    handleSearch,
    handleOnChange,
    selectedStoreIds,
    selectedItems,
  }
}
