import React, {useState} from 'react'

import {categoryTextSearchType} from '../../models/categories/CategoryListFilterModel'

export const useCategoryTextAreaFilter = () => {
  const [value, setValue] = useState<any>()

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const getCategoryIdInText = (withExtraText: boolean): categoryTextSearchType => {
    if (!value) {
      return {categoryIds: []}
    }

    const numberRegex = new RegExp('^([0-9])*$')
    const data = value.split('\n')
    let categoryIds: string[] = []
    let extraText: string[] = []

    data.forEach((item: string) => {
      const text = item.trim()
      if (text.length <= 0) {
        return
      }
      if (numberRegex.exec(text)) {
        categoryIds.push(text)
      } else if (withExtraText) {
        extraText.push(text)
      }
    })

    if (withExtraText) {
      return {categoryIds, extraText}
    }

    return {categoryIds}
  }

  return {handleOnChange, getCategoryIdInText}
}
