import JoditEditor, {Jodit} from 'jodit-react'
import debounce from 'lodash/debounce'
import React, {useEffect, useMemo, useRef, useState} from 'react'

interface KrcRichTextEditorWithTableProps {
  value: string
  onChange: (value: string) => void
  height?: number
  className?: string
  labelTitle?: string
  hasTooltip?: boolean
  tooltipText?: string
  style?: React.CSSProperties
}

export const KrcRichTextEditorWithTable: React.FC<KrcRichTextEditorWithTableProps> = ({
  value,
  onChange,
  className,
  style,
  labelTitle,
  hasTooltip = false,
  tooltipText,
  height,
}) => {
  const [editorHtml, setEditorHtml] = useState<string>()
  const inputRef = useRef<Jodit>(null)
  const config = useMemo(
    () => ({
      readonly: false,
      width: '100%',
      height: height ? height : 400,
      iframe: true,
      editHTMLDocumentMode: false,
      language: 'tr',
      debugLanguage: false,
      useSplitMode: false,
      style: {
        fontFamily: 'Arial, sans-serif',
      },
    }),
    [height]
  )

  const handleChange = React.useMemo(() => {
    const loadOptions = (html: string) => {
      setEditorHtml(html)
      onChange(html)
    }

    return debounce(loadOptions, 600)
  }, [])

  useEffect(() => {
    setEditorHtml(value)
  }, [value])

  return (
    <>
      <JoditEditor
        ref={inputRef}
        value={editorHtml || ''}
        config={config}
        onChange={handleChange}
      />
    </>
  )
}
