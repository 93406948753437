import axios from 'axios'
import * as querystring from 'query-string'

const API_URL = process.env.REACT_APP_API_URL || ''

export const productFilterListExportRequest = (params: object) => {
  let url = `${API_URL}/products/filter/export`

  return axios.get(url, {
    params: params,
  })
}

export const productFilterListCheckExportRequest = (fileName: string) => {
  let url = `${API_URL}/products/filter/check-export`

  return axios.get(url, {
    params: {
      file_name: fileName,
    },
  })
}

export const getProductFilterListExportFrontendUrl = ({filterSearchData}: any) => {
  let url = `/products/export`

  return `${url}?${querystring.stringify(
    {
      ...filterSearchData,
    },
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}
