import {EditOutlined} from '@ant-design/icons'
import {Badge, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Column} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {KrcNumberInput} from '../../components/atoms/forms/KrcNumberInput'
import {KrcSelect} from '../../components/atoms/forms/KrcSelect'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {SHOPPING_LIST_THEMES} from '../../enums/ShoppingListThemTypeEnum'
import {removeEmptyItemInObject} from '../../helpers/ObjectHelper'
import {fetchShoppingListsRequest} from '../../requests/shopping-lists/ShoppingListRequest'
import {initialMetaData} from '../tables/ProductSelectionProvider'

interface IShoppingListContext {
  columns: Column<any>[]
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  filter: any
  setFilter: React.Dispatch<React.SetStateAction<any>>
  handleOnFilter: (data: any, key: string | number) => void
  shoppingListData: any[]
  setShoppingListData: React.Dispatch<React.SetStateAction<any[]>>
  isDataEmptyAfterFilter: boolean
  setIsDataEmptyAfterFilter: React.Dispatch<React.SetStateAction<boolean>>
}

export const ShoppingListContext = React.createContext<IShoppingListContext>({
  columns: [],
  meta: initialMetaData,
  setMeta: () => {},
  filter: {},
  setFilter: () => {},
  handleOnFilter: () => {},
  shoppingListData: [],
  setShoppingListData: () => {},
  isDataEmptyAfterFilter: false,
  setIsDataEmptyAfterFilter: () => {},
})

export const ShoppingListProvider = ({children}: any) => {
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const fetchRef = useRef(0)
  const initialRef = useRef(0)
  const [filter, setFilter] = useState<any>({})
  const [shoppingListData, setShoppingListData] = useState<any[]>([])
  const [isDataEmptyAfterFilter, setIsDataEmptyAfterFilter] = React.useState<boolean>(false)

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'list_id',
        manualWidth: 150,
        maxWidth: 150,
        Filter: () => {
          return (
            <KrcNumberInput
              className={'w-100'}
              min={0}
              placeholder={'ID'}
              addonBefore='ID'
              style={{borderRadius: '1rem'}}
              onChange={(data) => {
                handleOnFilter(data, 'list_id')
              }}
            />
          )
        },
      },
      {
        Header: 'Liste Adı',
        accessor: 'list_name',
      },
      {
        Header: 'Liste Paylaşma Durumu',
        accessor: 'list_share_status',
        manualWidth: 200,
        maxWidth: 200,
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.list_share_status && (
                <div>
                  <Badge className={'mr-2'} color='green' /> Paylaşılabilir
                </div>
              )}
              {!row.original.list_share_status && (
                <div>
                  <Badge className={'mr-2'} color='red' />
                  Paylaşılamaz
                </div>
              )}
            </>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Paylaşılma Durumu'}
              options={[
                {value: 1, label: 'Paylaşılabilir'},
                {value: 2, label: 'Paylaşılamaz'},
              ]}
              maxTagCount={'responsive'}
              style={{width: '100%'}}
              className={'ant-selector-border'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }
                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'list_share_status')
              }}
            />
          )
        },
      },
      {
        Header: 'Tema',
        accessor: 'list_theme.theme_name',
        manualWidth: 150,
        maxWidth: 150,
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Tema'}
              options={SHOPPING_LIST_THEMES}
              maxTagCount={'responsive'}
              style={{width: '100%'}}
              className={'ant-selector-border'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }
                handleOnFilter(data, 'list_theme')
              }}
            />
          )
        },
      },
      {
        Header: 'Ürün Sayısı',
        accessor: 'products_count',
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Kullanıcı',
        accessor: 'customer_name',
      },
      {
        Header: 'Durum',
        accessor: 'status',
        manualWidth: 170,
        maxWidth: 170,
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.status && (
                <div>
                  Aktif <Badge className={'ml-2'} color='green' />
                </div>
              )}
              {!row.original.status && (
                <div>
                  Pasif <Badge className={'ml-2'} color='red' />
                </div>
              )}
            </>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Yayın Durumu'}
              options={[
                {value: 1, label: 'Aktif'},
                {value: 2, label: 'Pasif'},
              ]}
              maxTagCount={'responsive'}
              style={{width: '100%'}}
              className={'ant-selector-border'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }
                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'status')
              }}
            />
          )
        },
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        Filter: <KrcEmptyFilter />,
        Cell: ({row}: any) => (
          <>
            <div className={'d-flex flex-row justify-content-around'}>
              <Tooltip title={'Düzenle'}>
                <Link
                  to={`/shopping-lists/${row.original.list_id}`}
                  state={{from: row.original.ibm_group_title}}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                  style={{color: 'unset'}}
                >
                  <EditOutlined />
                </Link>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    if (!setMeta) {
      return
    }

    setMeta(meta)
  }, [meta.to, meta.from, meta.last_page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (!setMeta) {
      return
    }

    setMeta(meta)

    getShoppingListData()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getShoppingListData()
  }, [meta.limit])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  useEffect(() => {
    if (!meta) {
      return
    }

    setMeta(meta)
  }, [meta])

  useEffect(() => {
    if (!shoppingListData) {
      return
    }

    setShoppingListData(shoppingListData)
  }, [shoppingListData])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getShoppingListData()
  }, [filter])

  const handleOnFilter = React.useMemo(() => {
    setIsDataEmptyAfterFilter(false)
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }
    return debounce(loadTableFilter, 250)
  }, [])

  const getShoppingListData = () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    const _filter = removeEmptyItemInObject(filter)
    fetchShoppingListsRequest(_filter, meta)
      .then((response) => {
        if (fetchId !== fetchRef.current) {
          return
        }
        const _meta = response.data.meta
        setMeta({limit: _meta.per_page, page: _meta.current_page, ..._meta})
        setShoppingListData(response.data.data)

        if (response.data.data.length === 0) {
          setIsDataEmptyAfterFilter(true)
        } else {
          setIsDataEmptyAfterFilter(false)
        }
      })
      .catch((error) => {
        message.error('Alışveriş listeleri getirilirken bir sorun oluştu!')
        message.error(error.response.data.message)
      })
  }

  return (
    <>
      <ShoppingListContext.Provider
        value={{
          columns,
          meta,
          setMeta,
          filter,
          setFilter,
          handleOnFilter,
          shoppingListData,
          setShoppingListData,
          isDataEmptyAfterFilter,
          setIsDataEmptyAfterFilter,
        }}
      >
        {children}
      </ShoppingListContext.Provider>
    </>
  )
}
