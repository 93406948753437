import React, {FC} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {BannerManagerProvider} from '../../../../providers/banners/BannerManagerProvider'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {BannerManager} from './BannerManager'

const BannerManagerWrapperProvider: FC = () => {
  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Banner Manager</PageTitle>
      <BannerManager />
    </>
  )
}

export const BannerManagerWrapper: FC = () => {
  return (
    <>
      <BannerManagerProvider>
        <BannerManagerWrapperProvider />
      </BannerManagerProvider>
    </>
  )
}
