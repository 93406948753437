import {ContainerOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {Popconfirm, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {FC, createContext, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Column, Row} from 'react-table'

import {KrcEmptyFilter} from '../components/atoms/KrcEmptyFilter'
import {KrcNumberInput} from '../components/atoms/forms/KrcNumberInput'
import {KrcSelect} from '../components/atoms/forms/KrcSelect'
import {usePagination} from '../components/hooks/pagination/usePagination'
import {paginationMetaType} from '../components/models/GeneralModel'
import {IBadge, badgeDataType, badgeFilterType} from '../components/models/badges/BadgeModel'
import {BADGE_LOCATIONS} from '../enums/BadgeLocationTypeEnum'
import {DrawerEnum} from '../enums/DrawerEnum'
import {removeEmptyItemInObject} from '../helpers/ObjectHelper'
import {deleteBadgeRequest, getBadgesRequest} from '../requests/badges/BadgeRequest'
import {deleteBadge, setBadge, setBadges} from '../store/Badge/BadgeAction'
import {initialMetaData} from './tables/ProductSelectionProvider'

interface IBadgeContext {
  visibleDrawer: boolean
  updateVisibleDrawer: (data: boolean) => void
  drawerType: DrawerEnum
  updateDrawerType: (data: DrawerEnum) => void
  columns: Column<any>[]
  selectedTiedBadge?: badgeDataType
  setSelectedTiedBadge: any
  badges: badgeDataType[]
  badge?: badgeDataType
  visibleBadgeModal: boolean
  sendDeleteBadge: (e: any, row: any) => void
  handleShow: (data: IBadge) => void
  handleOnFilter: (data: any, key: string | number) => void
  getBadges: () => void
  badgeFilter?: badgeFilterType
  isLoading: boolean
  setIsLoading: (data: boolean) => void
  meta: paginationMetaType
  changePage: (page: number) => void
  changeLimit: (limit: number) => void
}

export const BadgeContext = createContext<IBadgeContext>({
  visibleDrawer: false,
  updateVisibleDrawer: () => {},
  drawerType: DrawerEnum.CREATE,
  updateDrawerType: () => {},
  columns: [],
  visibleBadgeModal: false,
  setSelectedTiedBadge: () => {},
  sendDeleteBadge: () => {},
  handleShow: () => {},
  handleOnFilter: () => {},
  getBadges: () => {},
  badges: [],
  isLoading: false,
  setIsLoading: () => {},
  meta: initialMetaData,
  changePage: () => {},
  changeLimit: () => {},
})

export const BadgeProvider: FC = ({children}) => {
  const dispatch = useDispatch()
  const [badgeFilter, setBadgeFilter] = useState<badgeFilterType>()
  const [confirmLoading, setConfirmLoading] = React.useState(false)
  const {badges, visibleBadgeModal, badge} = useSelector((state: any) => state.badge)
  const [selectedTiedBadge, setSelectedTiedBadge] = useState<badgeDataType>()
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false)
  const [drawerType, setDrawerType] = useState<DrawerEnum>(DrawerEnum.CREATE)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const fetchRef = useRef(0)
  const initialRef = useRef(0)

  const updateVisibleDrawer = (visibility: boolean) => {
    setVisibleDrawer(visibility)
  }

  const updateDrawerType = (drawer: DrawerEnum) => {
    setDrawerType(drawer)
  }

  useEffect(() => {
    initialRef.current = 1

    getBadges()
  }, [])

  useEffect(() => {
    if (!badges) {
      return
    }

    dispatch(setBadges(badges))
  }, [badges])

  const handleOnFilter = React.useMemo(() => {
    const filter = (value: any, id: string | number) => {
      setBadgeFilter((data) => ({...data, [id]: value}))
    }

    return debounce(filter, 250)
  }, [badgeFilter])

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'badge_id',
        manualWidth: 120,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        Header: 'Ad',
        accessor: 'name', // accessor is the "key" in the data
        manualWidth: 950,
        maxWidth: 950,
      },
      {
        Header: 'Konum',
        accessor: 'location_letter',
        manualWidth: 180,
        maxWidth: 180,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={'multiple'}
                placeholder={'Konum Filtresi'}
                options={BADGE_LOCATIONS.map((location) => ({
                  label: location.badge_location_name,
                  value: location.badge_location_id,
                }))}
                showSearch={false}
                maxTagCount={'responsive'}
                style={{width: 120}}
                className={'ant-selector-border'}
                onChange={(data) => {
                  if (!Array.isArray(data)) {
                    return
                  }
                  handleOnFilter(data, 'location_letter')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Öncelik',
        accessor: 'priority',
        manualWidth: 180,
        maxWidth: 180,
        Filter: () => {
          return (
            <>
              <KrcNumberInput
                style={{width: 120}}
                placeholder={'Öncelik Filtresi'}
                onChange={(data) => {
                  handleOnFilter(data, 'priority')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Durum',
        accessor: 'status_text',
        Filter: KrcEmptyFilter,
        manualWidth: 120,
        maxWidth: 120,
      },
      {
        Header: () => <div style={{textAlign: 'right'}}>Aksiyon</div>,
        manualWidth: 190,
        maxWidth: 190,
        accessor: 'col5',
        id: 'click-me-button',
        Cell: ({row}: {row: Row<any>}) => (
          <div className='text-end'>
            <Tooltip title={'Badge Liste'}>
              <div
                onClick={() => setSelectedTiedBadge(row.original)}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1 badge-update'
              >
                <ContainerOutlined />
              </div>
            </Tooltip>

            <Tooltip title={'Güncelle'}>
              <div
                onClick={() => handleShow(row.original)}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1 badge-update'
              >
                <EditOutlined />
              </div>
            </Tooltip>

            <Tooltip title={'Sil'}>
              <Popconfirm
                title='Bu badge silmek istediğinden emin misin ?'
                onConfirm={(e) => sendDeleteBadge(e, row)}
                okButtonProps={{loading: confirmLoading}}
                okText='Evet'
                cancelText='Hayır'
              >
                <div className='btn btn-icon btn-bg-light btn-active-color-primary mb-1 btn-sm'>
                  <DeleteOutlined />
                </div>
              </Popconfirm>
            </Tooltip>
          </div>
        ),
        Filter: KrcEmptyFilter,
      },
    ],
    []
  )

  const handleShow = (badge: IBadge) => {
    updateDrawerType(DrawerEnum.UPDATE)
    updateVisibleDrawer(true)
    dispatch(setBadge(badge))
  }

  const sendDeleteBadge = (e: any, row: any) => {
    e.preventDefault()
    setConfirmLoading(true)
    const badgeId = row.original.badge_id

    deleteBadgeRequest(badgeId).then(() => {
      setConfirmLoading(false)
      dispatch(deleteBadge(badgeId))
    })
  }

  const getBadges = (page: null | number = null) => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    let _meta = meta
    const _filter = removeEmptyItemInObject(badgeFilter ?? {})
    if (page) {
      _meta.page = page
    }
    getBadgesRequest(_filter, _meta, ['stores'])
      .then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }

        const metaData = res.data.meta
        dispatch(setBadges(res.data.data))

        updateMetaData({limit: metaData.per_page, page: metaData.current_page, ...metaData})
      })
      .catch((e) => {
        message.error('Badgeler getirilirken bir hata oluştu.')
      })
  }

  const {meta, updateMetaData, changePage, changeLimit} = usePagination({
    callback: getBadges,
    filter: badgeFilter,
  })

  return (
    <>
      <BadgeContext.Provider
        value={{
          getBadges,
          visibleDrawer,
          updateVisibleDrawer,
          drawerType,
          updateDrawerType,
          columns,
          sendDeleteBadge,
          handleShow,
          handleOnFilter,
          badge,
          selectedTiedBadge,
          setSelectedTiedBadge,
          badges,
          visibleBadgeModal,
          badgeFilter,
          isLoading,
          setIsLoading,
          meta,
          changePage,
          changeLimit,
        }}
      >
        {children}
      </BadgeContext.Provider>
    </>
  )
}
