import {FC, useContext} from 'react'

import {RoleContext} from '../../../providers/role/RoleProvider'
import {paginationMetaType} from '../../models/GeneralModel'
import {KrcTable} from '../../organisms/tables/KrcTable'

export const RoleList: FC = () => {
  const {columns, RoleData, meta, setMeta, handleOnFilter} = useContext(RoleContext)
  return (
    <>
      <KrcTable
        columns={columns}
        data={RoleData}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            setMeta((data: paginationMetaType) => ({...data, page}))
          },
          onChangeLimit: (limit) => {
            setMeta((data: paginationMetaType) => ({...data, limit}))
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: (search, id) => {
            handleOnFilter(search, id)
          },
        }}
      />
    </>
  )
}
