import {ExclamationCircleOutlined} from '@ant-design/icons'
import {CaretDownFilled} from '@ant-design/icons/lib/icons'
import {Drawer, Dropdown, Menu, Modal, Space, message} from 'antd'
import {Form, Formik} from 'formik'
import {FormikProps} from 'formik/dist/types'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'

import {ProductSelectionEnum} from '../../../../enums/ProductSelectionEnum'
import {
  CategoryProductsProcessContext,
  categoryProductsProcessTableDataType,
} from '../../../../providers/category/products/CategoryProductsProcessProvider'
import {initialMetaData} from '../../../../providers/tables/ProductSelectionProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcFormikInput} from '../../../atoms/forms/KrcFormikInput'
import {ProductSelection} from '../../../layout/filters/ProductSelection'
import {paginationMetaType} from '../../../models/GeneralModel'
import {constraintProToCatInitValueType} from '../../../models/categories/CategoryProductModel'
import {productVerticalAdvancedFilterDataType} from '../../../models/products/ProductListFilterModel'
import {
  ProductSelectionType,
  productTextAreaFilterDataType,
} from '../../../models/products/ProductSelectionModel'
import {DateConstraint} from '../../../molecules/date_pickers/DateConstraint'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

const {confirm} = Modal

export interface IAddProductsToCategoryDrawer {
  visible: boolean
  onClose: () => void
  onOk: (
    products: categoryProductsProcessTableDataType[],
    constraint?: constraintProToCatInitValueType
  ) => void
  type: string
}

const schema = Yup.object({
  end_date: Yup.string().nullable(true),
  start_date: Yup.string().nullable(true),
  stock: Yup.number().nullable(true),
})

export const _initialValue = {
  start_date: null,
  end_date: null,
  stock: '',
}

export const AddProductsToCategoryDrawer: FC<IAddProductsToCategoryDrawer> = ({
  onClose,
  onOk,
  visible = false,
  type,
}) => {
  const [selectedProducts, setSelectedProducts] = useState<categoryProductsProcessTableDataType[]>(
    []
  )
  const [initialValue, setInitialValue] = useState<constraintProToCatInitValueType>(_initialValue)
  const submitRef = useRef<HTMLButtonElement | null>(null)
  const constraintFormRef = useRef<FormikProps<any> | null>(null)

  const [productSelectionMeta, setProductSelectionMeta] =
    useState<paginationMetaType>(initialMetaData)
  const [productSelectionFilters, setProductSelectionFilters] = useState<
    productVerticalAdvancedFilterDataType | productTextAreaFilterDataType
  >()
  const [productSelectionFilterCurrentType, setProductSelectionFilterCurrentType] =
    useState<ProductSelectionType>(ProductSelectionEnum.DEFAULT)

  const {addCategoryProductByFilteredProducts} = useContext(CategoryProductsProcessContext)

  const handleOnOk = () => {
    if (selectedProducts?.length === 0) {
      message.warn('Ürün ekleyebilmek için ürün seçilmedi. Lütfen ürün(leri) seçin.')
      return
    }

    confirm({
      title: `${selectedProducts?.length} ürün eklenecek.`,
      icon: <ExclamationCircleOutlined />,
      content: 'Ekleme işlemi kaydet buttonuna basılana kadar gerçekleşmeyecektir.',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        if (type === 'campaign') {
          submitRef?.current?.click()
        } else {
          onOk(selectedProducts)
          onClose()
        }
      },
    })
  }

  const handleOnSubmit = (value: constraintProToCatInitValueType) => {
    setInitialValue(value)

    if (selectedProducts?.length === 0) {
      message.warn('Ürün ekleyebilmek için ürün seçilmedi. Lütfen ürün(leri) seçin.')
      return
    }

    if (value.start_date && value.end_date && value.start_date.isAfter(value.end_date)) {
      message.error('Kısıt eklerken Başlangıç tarihi Bitiş tarihinden büyük olamaz')
      return
    }

    onOk(selectedProducts, value)
    onClose()
  }

  /**
   * Filtrelenen ürünleri direkt olarak işleyebilir miyiz gerekli koşullar sağlanıyor mu kontrol eder.
   */
  const checkFilteredAllProductsIsAvailableToHandle = () => {
    if (!productSelectionMeta?.total || productSelectionMeta?.total < 1) {
      message.warn('Filtrelenmiş ürün bulunamadı.')
      return false
    }

    if (productSelectionFilterCurrentType === ProductSelectionEnum.ADVANCED) {
      message.warn('Gelişmiş filtreleme ile ürün seçimi yapamazsınız.')
      return false
    }

    if (productSelectionFilters === undefined) {
      message.warn('Önce metin seçimi ile filtre yapmalısınız.')
      return false
    }

    if (
      (productSelectionFilters as productTextAreaFilterDataType)?.productIds.length === 0 &&
      (productSelectionFilters as productTextAreaFilterDataType)?.models.length === 0
    ) {
      message.warn('Önce metin seçimi ile filtre yapmalısınız.')
      return false
    }

    return true
  }

  const handleToAddFilteredAllProducts = () => {
    const constraintFormValues = constraintFormRef?.current?.values
    setInitialValue((initialValue) => constraintFormValues)

    if (!checkFilteredAllProductsIsAvailableToHandle()) {
      return
    }

    confirm({
      title: `DİKKAT!`,
      icon: <ExclamationCircleOutlined />,
      content: `${productSelectionMeta.total} ürünün tümünü kontrol etmeden ekleyip kaydetmek üzeresiniz. Ekleyip kaydetmek istediğinize emin misiniz?`,
      okText: 'Devam',
      cancelText: 'Vazgeç',
      onOk() {
        confirm({
          title: `${productSelectionMeta.total} adet ürün eklenecek`,
          icon: <ExclamationCircleOutlined />,
          content: `${productSelectionMeta.total} adet ürün kategoriye eklenecek ve kaydedilecek. Devam etmek istiyor musunuz?`,
          okText: 'Ekle ve Kaydet',
          cancelText: 'İptal',
          onOk() {
            if (type === 'campaign') {
              addCategoryProductByFilteredProducts(
                productSelectionFilters as productTextAreaFilterDataType,
                constraintFormValues
              )
            } else {
              addCategoryProductByFilteredProducts(
                productSelectionFilters as productTextAreaFilterDataType
              )
            }
            onClose()
          },
        })
      },
    })
  }

  const productAddActionsDropdownMenu = (
    <Menu>
      <Menu.Item
        disabled={false}
        title={'Sadece "Metin Seçimi" ile filtrelemede çalışır'}
        onClick={handleToAddFilteredAllProducts}
        key={'addFilteredAllProducts'}
      >
        Filtrelenen Ürünleri Ekle ve Kaydet
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    if (!visible) {
      setSelectedProducts([])
    }
  }, [visible])

  return (
    <>
      <Drawer
        title={'Ürün Ekleme'}
        placement='right'
        size={'large'}
        onClose={onClose}
        keyboard={false}
        visible={visible}
        extra={
          <Space>
            <KrcButton type={'default'} size={'large'} onClick={onClose}>
              İptal
            </KrcButton>
            <KrcButton size={'large'} onClick={handleOnOk}>
              Seçilen Ürünleri Ekle
            </KrcButton>
            <Dropdown overlay={productAddActionsDropdownMenu}>
              <KrcButton size={'large'} type='primary' icon={<CaretDownFilled />} />
            </Dropdown>
          </Space>
        }
      >
        {visible && (
          <div>
            {type === 'campaign' && (
              <>
                <Formik
                  innerRef={constraintFormRef}
                  validationSchema={schema}
                  initialValues={initialValue}
                  onSubmit={handleOnSubmit}
                  enableReinitialize
                >
                  {() => (
                    <Form className='form row' noValidate>
                      <KrcFormikField
                        name={'date'}
                        labelTitle={'Tarihi Kısıt'}
                        className={'col-md-10 mb-2'}
                      >
                        <DateConstraint
                          onChange={({value}) => {
                            setInitialValue((data) => ({
                              ...data,
                              start_date: value?.raw_start_date,
                              end_date: value?.raw_end_date,
                            }))
                          }}
                          isSelect={false}
                          leftDatePickerProps={{
                            format: 'DD.MM.YYYY HH:mm:ss',
                            showTime: true,
                          }}
                          rightDatePickerProps={{
                            format: 'DD.MM.YYYY HH:mm:ss',
                            showTime: true,
                            showNow: false,
                          }}
                        />
                      </KrcFormikField>
                      <KrcFormikField
                        name={'stock'}
                        labelTitle={'Stok'}
                        className={'mb-2 col-md-3'}
                      >
                        <KrcFormikInput
                          type={'number'}
                          name={'stock'}
                          className={'form-control'}
                          style={{height: 32}}
                          min={0}
                          onKeyUp={(e: any) => {
                            setInitialValue((data) => ({
                              ...data,
                              stock: e?.target?.value ? Number(e?.target?.value) : null,
                            }))
                          }}
                        />
                      </KrcFormikField>

                      <button
                        type='submit'
                        ref={submitRef}
                        className='d-none'
                        style={{marginLeft: 10}}
                      />
                    </Form>
                  )}
                </Formik>
              </>
            )}
            <ProductSelection
              onSelectedItems={(data) => {
                setSelectedProducts(data ?? [])
              }}
              withFullPrice={true}
              transferProps={{
                button: {
                  isActive: false,
                },
                config: {
                  confirmActive: false,
                },
              }}
              onFilterChange={(filters) => {
                setProductSelectionFilters(filters)
              }}
              onFilterTypeChange={(type) => {
                setProductSelectionFilterCurrentType(type)
              }}
              onMetaChange={(meta) => {
                setProductSelectionMeta(meta)
              }}
            />
          </div>
        )}
      </Drawer>
    </>
  )
}
