import axios from 'axios'
import * as querystring from 'query-string'

const API_URL = process.env.REACT_APP_API_URL || ''
const PASSIVE_PRODUCT_URL = `${API_URL}/products/passive`

export const getPassiveProductExportFrontendUrl = ({filterSearchData}: any) => {
  let url = `/products/passive/export`

  return `${url}?${querystring.stringify(
    {
      product_id: filterSearchData?.id,
      product_ids: filterSearchData?.ids,
      model: filterSearchData?.model,
      models: filterSearchData?.models,
      name: filterSearchData?.name,
      store_ids: filterSearchData?.store_ids,
      updated_ts: filterSearchData?.updated_ts,
    },
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}

export const passiveProductsExportRequest = (params: object) => {
  let url = `${PASSIVE_PRODUCT_URL}/export`

  return axios.get(url, {
    params: params,
  })
}

export const passiveProductsCheckExportRequest = (fileName: string) => {
  let url = `${PASSIVE_PRODUCT_URL}/check-export`

  return axios.get(url, {
    params: {
      file_name: fileName,
    },
  })
}
