import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const IBM_GROUP_URL = `${API_URL}/shopping-lists`

export function fetchShoppingListsRequest(filter: any, {page, limit}: paginationMetaType) {
  return axios.get(`${IBM_GROUP_URL}`, {
    params: {
      page,
      limit,
      ...filter,
    },
  })
}

export function showShoppingListRequest(id: number) {
  return axios.get(`${IBM_GROUP_URL}/${id}`)
}

export function createShoppingListRequest(data: any) {
  return axios.post(`${IBM_GROUP_URL}`, data)
}

export function updateShoppingListRequest(id: number, data: any) {
  return axios.put(`${IBM_GROUP_URL}/${id}`, data)
}
