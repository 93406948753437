import {OptionData} from 'rc-select/lib/interface'

import {menuDataType} from '../menus/MenuModel'

export type storeDataType = {
  store_id: number
  name: string
  url: string
  ssl: string
  menus?: menuDataType[]
  main_menu?: menuDataType | null
  menus_count?: number
}

export type storeSearchType = {
  id: number
  name: string
}

export type storeType = 'company' | 'marketplace' | 'test'

export interface IStoreFilter {
  onSelected?: (data: number[] | OptionData[]) => void
  clear?: number
  dataType?: 'value' | 'item'
  storeType?: storeType
}

export const DEFAULT_STORE = {value: 2, label: 'Karaca'}
