import {CopyOutlined} from '@ant-design/icons'
import {notification} from 'antd'
import {getUUID} from 'rc-select/es/utils/commonUtil'
import React from 'react'

import {IKrcWarningNotification} from '../models/KrcNotificationModel'

export const KrcWarningNotification = ({data, title, copyKey}: IKrcWarningNotification) => {
  const handleCopy = () => {
    if (!copyKey) {
      return
    }

    const dataToBeCopied = data.map((item: any) => {
      if (Array.isArray(copyKey)) {
        return copyKey
          .map((key) => {
            return item[key].replace(/(<([^>]+)>)/gi, '')
          })
          .join(' - ')
      }
      return item[copyKey]
    })

    navigator.clipboard.writeText(dataToBeCopied.join('\n'))
    notification.success({
      message: 'Kopyalama Başarılı',
      duration: 2,
      style: {width: 'max-content'},
    })
  }

  return notification.warning({
    className: 'custom-notification',
    style: {width: 500},
    message: (
      <div className='d-flex justify-content-between align-items-center'>
        <span>{title}</span>
        {copyKey ? <CopyOutlined onClick={handleCopy} className='cursor-pointer' /> : undefined}
      </div>
    ),
    description: data.map((errorMessage) => (
      <div
        key={getUUID()}
        className={'pb-2 pt-2'}
        style={{borderBottom: '1px solid #3333'}}
        dangerouslySetInnerHTML={{__html: errorMessage.message}}
      ></div>
    )),
    duration: 0,
    placement: 'top',
  })
}
