/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React, {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'

import {MasterInit} from '../../_metronic/layout/MasterInit'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {RootState} from '../../setup'
import {ErrorsPage} from '../components/pages/errors/ErrorsPage'
import {AuthPage, Logout} from '../modules/auth'
import {PrivateRoutes} from './PrivateRoutes'

const KrcRoutes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const location = useLocation()

  return (
    <>
      <Routes>
        {!isAuthorized ? (
          <Route path={'auth/*'} element={<AuthPage />} />
        ) : (
          <Route path={'auth/*'} element={<Navigate state={{from: location}} to={'/'} replace />} />
        )}

        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='/logout' element={<Logout />} />

        {!isAuthorized ? (
          <Route path={'*'} element={<Navigate to={'/auth/login'} replace />} />
        ) : (
          <>
            <Route
              path={'*'}
              element={
                <MasterLayout>
                  <PrivateRoutes />
                </MasterLayout>
              }
            >
              {/*<MasterLayout>*/}
              {/*  /!*  <PrivateRoutes />*!/*/}
              {/*</MasterLayout>*/}
            </Route>
          </>
        )}
      </Routes>
      <MasterInit />
    </>
  )
}

export {KrcRoutes}
