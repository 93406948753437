import moment from 'moment/moment'
import React, {FC, useEffect, useMemo, useState} from 'react'

import {categoryProductsProcessTableDataType} from '../../providers/category/products/CategoryProductsProcessProvider'
import {KrcAlert} from '../atoms/KrcAlert'
import {KrcSelect} from '../atoms/forms/KrcSelect'
import {KrcTable} from '../organisms/tables/KrcTable'

export const CategoryProductProcessSummaryTable: FC<{
  willBeAddedProducts: categoryProductsProcessTableDataType[]
  willBeDeletedProducts: categoryProductsProcessTableDataType[]
  type: string
}> = ({willBeAddedProducts, willBeDeletedProducts, type}) => {
  const [data, setData] = useState<any[]>([])
  const [filterType, setFilterType] = useState<string | null>(null)

  const columns = useMemo(() => {
    let data = [
      {
        Header: 'ID',
        accessor: 'id',
        manualWidth: 130,
        maxWidth: 130,
      },
      {
        Header: 'Ad',
        accessor: 'name',
        manualWidth: 500,
        maxWidth: 500,
      },
      {
        Header: 'Tür',
        accessor: 'type',
        manualWidth: 150,
        maxWidth: 150,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={undefined}
                placeholder={'Tür Filtresi'}
                options={[
                  {value: 'add', label: 'Eklenecek'},
                  {value: 'delete', label: 'Silinecek'},
                ]}
                maxTagCount={'responsive'}
                style={{width: '100%'}}
                className={'ant-selector-border'}
                showArrow={true}
                onChange={(data) => {
                  console.log(data)
                  setFilterType(data)
                  // updateTableFilter({type: data})
                }}
              />
            </>
          )
        },
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.type === 'add' && <KrcAlert message='Eklenecek' type='success' />}
              {row.original.type === 'delete' && <KrcAlert message='Silinecek' type='error' />}
            </>
          )
        },
      },
    ]

    if (type === 'campaign') {
      data.splice(2, 0, {
        // @ts-ignore
        Header: (
          <div className='d-flex row'>
            <span>Kısıtlar:</span>
            <span style={{fontSize: 11}}>Stok</span>
            <span style={{fontSize: 11}}>Başlangıç Tarihi</span>
            <span style={{fontSize: 11}}>Bitiş Tarihi</span>
          </div>
        ),
        maxWidth: 150,
        manualWidth: 150,
        accessor: 'constraint',
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex row'}>
              {row.original?.constraint?.stock ? (
                <div className={'text-muted'} style={{fontSize: 12}}>
                  {row.original.constraint.stock}
                </div>
              ) : (
                <div>-</div>
              )}
              {row.original?.constraint?.start_date ? (
                <div className={'text-muted'} style={{fontSize: 12}}>
                  {moment(row.original.constraint.start_date).format('DD.MM.YYYY HH:mm:ss')}
                </div>
              ) : (
                <div>-</div>
              )}
              {row.original?.constraint?.end_date ? (
                <div className={'text-muted'} style={{fontSize: 12}}>
                  {moment(row.original.constraint.end_date).format('DD.MM.YYYY HH:mm:ss')}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          )
        },
      })
    }

    return data
  }, [])

  useEffect(() => {
    const _willBeAddedProducts = willBeAddedProducts.map((item) => ({
      id: item.id,
      name: item.name,
      constraint: item.constraint,
      type: 'add',
    }))
    const _willBeDeletedProducts = willBeDeletedProducts.map((item) => ({
      id: item.id,
      name: item.name,
      constraint: item.constraint,
      type: 'delete',
    }))
    if (filterType === 'add') {
      setData(_willBeAddedProducts)
    } else if (filterType === 'delete') {
      setData(_willBeDeletedProducts)
    } else {
      setData([..._willBeAddedProducts, ..._willBeDeletedProducts])
    }
  }, [filterType])

  return (
    <>
      <KrcTable
        columns={columns}
        data={data}
        searchableProps={{isSearchable: true}}
        outerHeight={400}
      />
    </>
  )
}
