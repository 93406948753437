import {CalendarOutlined} from '@ant-design/icons'
import {Alert, Input, Select, SelectProps, message} from 'antd'
import {Field, Form, Formik} from 'formik'
import moment from 'moment'
import {OptionData} from 'rc-select/lib/interface'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'

import {CAMPAIGN_DETAIL} from '../../../../../enums/IBMCampaignTabTypeEnums'
import {
  CAMPAIGN_COMPANIES,
  CAMPAIGN_TAGS,
  CAMPAIGN_TYPES,
} from '../../../../../enums/IBMCampaignUpdateTypeEnum'
import {IBMCampaignSpecialGroupContext} from '../../../../../providers/ibm/IBMCampaignSpecialGroupProvider'
import {
  fetchIBMCampaignGeneralInformation,
  updateIBMCampaignGeneralInformation,
} from '../../../../../requests/ibm/IBMCampaignRequest'
import {InfoTooltip} from '../../../../atoms/InfoTooltip'
import {KrcButton} from '../../../../atoms/buttons/KrcButton'
import {KrcRichTextEditorWithTable} from '../../../../atoms/forms/KrcRichTextEditorWithTable'
import {KrcSelect} from '../../../../atoms/forms/KrcSelect'
import {IBMCampaignDetailInformationType} from '../../../../models/ibm/IBMCampaignDetail'
import {Loader} from '../../../../molecules/Loader'
import {BadgeFilter} from '../../../../molecules/filters/BadgeFilter'
import {KrcFormikField} from '../../../../molecules/forms/KrcFormikField'

interface CampaignDetailTabProps {
  activeCampaignId: number
}

interface SelectType {
  id: number
  name: string
}

const initialState = {
  campaign_id: 0,
  campaigns_page_image: null,
  campaign_detail_page_image: null,
  badge: null,
  date_added: '',
  date_start: '',
  date_end: '',
  date_updated: '',
  campaign_status: false,
  campaign_tag: null,
  campaign_company: null,
  campaign_type: null,
  campaign_cart_title: '',
  campaign_title: '',
  campaign_margin: null,
  campaign_description: '',
  campaigns_title: '',
  campaign_keywords: [],
  url_alias_campaign_seo_url: '',
  campaign_price: null,
}

export const CampaignDetailTab: FC<CampaignDetailTabProps> = ({activeCampaignId}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isChanged, setIsChanged] = useState<boolean>(false)
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [campaignGeneralInformation, setCampaignGeneralInformation] =
    useState<IBMCampaignDetailInformationType>(initialState)
  const [willBeUpdateCampaignType, setWillBeUpdateCampaignType] = useState<number | null>(null)
  const [willBeUpdateBadge, setWillBeUpdatedBadge] = useState<SelectType | null>(
    campaignGeneralInformation.badge
  )
  const [willBeUpdateCampaignTag, setWillBeUpdateCampaignTag] = useState<number | null>(null)
  const [willBeUpdateCampaignCompany, setWillBeUpdateCampaignCompany] = useState<number | null>(
    null
  )
  const [willBeUpdateCampaignDescription, setWillBeUpdateCampaignDescription] = useState(
    campaignGeneralInformation.campaign_description
  )
  const [willBeUpdateCampaignKeywords, setWillBeUpdateCampaignKeywords] = useState<
    SelectProps['options']
  >(campaignGeneralInformation.campaign_keywords)

  const initialRef = useRef(0)
  const {setPageType} = useContext(IBMCampaignSpecialGroupContext)

  useEffect(() => {
    setPageType(CAMPAIGN_DETAIL)
    if (initialRef.current === 0) {
      fetchIBMCampaignGeneralInformation(activeCampaignId, 1)
        .then((res) => {
          setCampaignGeneralInformation((prev) => {
            return {
              ...prev,
              ...res.data.data,
              campaign_price: res.data.data.campaign_price
                ? res.data.data.campaign_price.toString()
                : '',
              campaign_status: res.data.data.campaign_status.toString(),
              campaign_title: res.data.data.campaign_title
                ? res.data.data.campaign_title.toString()
                : '',
              campaign_margin: res.data.data.campaign_margin
                ? res.data.data.campaign_margin.toString()
                : '',
              campaign_cart_title: res.data.data.campaign_cart_title
                ? res.data.data.campaign_cart_title.toString()
                : '',
              date_added: moment(res.data.data.date_added).format('DD-MM-YYYY HH:mm'),
              date_start: moment(res.data.data.date_start).format('DD-MM-YYYY HH:mm'),
              date_end: moment(res.data.data.date_end).format('DD-MM-YYYY HH:mm'),
              date_updated: res.data.data.date_updated
                ? moment(res.data.data.date_updated).format('DD-MM-YYYY HH:mm')
                : null,
              url_alias_campaign_seo_url: res.data.data.url_alias_campaign_seo_url
                ? res.data.data.url_alias_campaign_seo_url
                : '',
            }
          })
          setWillBeUpdatedBadge(res.data.data.badge)
          setWillBeUpdateCampaignTag(
            Number(res.data.data.campaign_tag.id) ? Number(res.data.data.campaign_tag.id) : null
          )
          setWillBeUpdateCampaignCompany(
            Number(res.data.data.campaign_company.id)
              ? Number(res.data.data.campaign_company.id)
              : null
          )
          setWillBeUpdateCampaignType(
            Number(res.data.data.campaign_type.id) ? Number(res.data.data.campaign_type.id) : null
          )
          setWillBeUpdateCampaignDescription(res.data.data.campaign_description)
          setWillBeUpdateCampaignKeywords(res.data.data.campaign_keywords)
          initialRef.current = 1
          setIsLoading(false)
        })
        .catch((err) => {
          message.error('Kampanya bilgileri getirilirken bir hata oluştu.')
          message.error(err.response.data.message)
          initialRef.current = 1
          setIsLoading(false)
        })
    }
  }, [activeCampaignId])

  const handleSubmit = (values: any, {setSubmitting, resetForm}: any) => {
    if (willBeUpdateBadge !== campaignGeneralInformation.badge) {
      if (willBeUpdateBadge?.id) {
        values.badge_id = willBeUpdateBadge?.id
      } else {
        values.badge_id = null
      }
    } else if (campaignGeneralInformation.badge?.id) {
      values.badge_id = campaignGeneralInformation.badge?.id
    } else {
      values.badge_id = null
    }

    if (willBeUpdateCampaignDescription !== campaignGeneralInformation.campaign_description) {
      values.campaign_description = willBeUpdateCampaignDescription
    } else {
      values.campaign_description = campaignGeneralInformation.campaign_description
    }

    if (willBeUpdateCampaignKeywords !== campaignGeneralInformation.campaign_keywords) {
      values.campaign_keywords = willBeUpdateCampaignKeywords
    } else {
      values.campaign_keywords = campaignGeneralInformation.campaign_keywords
    }

    if (!values.campaign_keywords || values.campaign_keywords.length === 0) {
      message.error('Anahtar kelimeler alanı boş olamaz.')
      return
    }

    if (!values.url_alias_campaign_seo_url) {
      message.error('Kampanya URL alanı boş olamaz.')
      return
    }

    const formikValues = {
      ...values,
      campaign_type: willBeUpdateCampaignType,
      campaign_tag: willBeUpdateCampaignTag,
      campaign_company: willBeUpdateCampaignCompany,
    }

    message.info('Kampanya bilgileri güncelleniyor...')
    updateIBMCampaignGeneralInformation(activeCampaignId, formikValues, 1).then((res) => {
      setIsSubmit(true)
      setSubmitting(true)
      setIsChanged(false)
      message.success('Kampanya bilgileri başarıyla güncellendi.')
      setTimeout(() => {
        setIsSubmit(false)
        resetForm({values: {...formikValues}})
      }, 500)
    })
  }

  const handleChangeKeyword = (value: any) => {
    if (!value) {
      setIsChanged(false)
      return
    }

    if (!campaignGeneralInformation.campaign_keywords) {
      setIsChanged(false)
      return
    }

    if (value.length !== campaignGeneralInformation.campaign_keywords.length) {
      setIsChanged(true)
    } else {
      setIsChanged(false)
    }

    setWillBeUpdateCampaignKeywords(
      value.map((item: string, index: number) => {
        return {
          value: index,
          label: item,
        }
      })
    )
  }

  return (
    <>
      {isLoading || campaignGeneralInformation.campaign_id === 0 ? (
        <>
          <Loader />
        </>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={campaignGeneralInformation}
          onSubmit={handleSubmit}
        >
          {(dirty) => {
            return (
              <Form className={'form mt-6'}>
                {(dirty.dirty || isChanged) && !isSubmit ? (
                  <Alert
                    message='Değişiklik yaptığınızı fark ettik. Sağ alttan değişiklikleri kaydetmeyi unutma!'
                    type='warning'
                    showIcon
                    closable
                  />
                ) : null}
                <div className={'row mt-2'}>
                  <div className={'col-6'}>
                    <div className={'col-10'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'campaign_title'}
                        labelTitle={'Kampanya Başlığı'}
                      />
                    </div>
                  </div>
                  <div className={'col-6'}>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2'}
                        name={'campaign_status'}
                        labelTitle={'Aktiflik Durumu'}
                      >
                        <Field as='select' name='campaign_status' className='form-select'>
                          <option value={undefined} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Aktif</option>
                          <option value={0}>Pasif</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-6'}>
                    <div className={'col-10'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'campaign_cart_title'}
                        labelTitle={'Kampanya Sepet Başlığı'}
                      />
                    </div>
                  </div>
                  <div className={'col-6'}>
                    <div className={'col-8'}>
                      <div className={'row'}>
                        <div className={'col-6'}>
                          <label className={'mb-2 fs-6 d-flex align-items-center gap-1'}>
                            Eklenme Tarihi
                            <InfoTooltip
                              status={true}
                              text={'GG-AA-YYYY formatında görüntülenmektedir. Düzenlenemez.'}
                            />
                          </label>
                          <Input
                            disabled
                            suffix={<CalendarOutlined />}
                            size={'large'}
                            style={{borderRadius: '5px'}}
                            value={campaignGeneralInformation.date_added}
                          />
                        </div>
                        <div className={'col-6'}>
                          <label className={'mb-2 fs-6 d-flex align-items-center gap-1'}>
                            Güncellenme Tarihi
                            <InfoTooltip
                              status={true}
                              text={'GG-AA-YYYY formatında görüntülenmektedir. Düzenlenemez.'}
                            />
                          </label>
                          <Input
                            disabled
                            suffix={<CalendarOutlined />}
                            size={'large'}
                            style={{borderRadius: '5px'}}
                            value={campaignGeneralInformation.date_updated}
                            className={'d-flex align-items-center gap-1'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-6'}>
                    <div className={'col-10'}>
                      <div className={'row'}>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'mb-2'}
                            type={'text'}
                            name={'campaign_company'}
                            labelTitle={'Kampanya Şirketi'}
                          >
                            <KrcSelect
                              placeholder={'Kampanya Şirketi'}
                              size={'large'}
                              options={CAMPAIGN_COMPANIES}
                              maxTagCount={'responsive'}
                              style={{width: '100%'}}
                              className={'ant-selector-border'}
                              showArrow={true}
                              value={willBeUpdateCampaignCompany}
                              onChange={(data) => {
                                if (
                                  Number(campaignGeneralInformation.campaign_company?.id) !==
                                  Number(data)
                                ) {
                                  setIsChanged(true)
                                } else {
                                  setIsChanged(false)
                                }

                                if (typeof data === 'string' && data !== '') {
                                  setIsChanged(false)
                                }

                                if (!data) {
                                  setWillBeUpdateCampaignCompany(null)
                                  return
                                }

                                setWillBeUpdateCampaignCompany(data)
                              }}
                            />
                          </KrcFormikField>
                        </div>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'mb-2'}
                            type={'text'}
                            name={'campaign_tag'}
                            labelTitle={'Kampanya Tag '}
                          >
                            <KrcSelect
                              placeholder={'Kampanya Tag'}
                              size={'large'}
                              options={CAMPAIGN_TAGS}
                              maxTagCount={'responsive'}
                              style={{width: '100%'}}
                              className={'ant-selector-border'}
                              showArrow={true}
                              value={willBeUpdateCampaignTag}
                              onChange={(data) => {
                                if (
                                  Number(campaignGeneralInformation.campaign_tag?.id) !==
                                  Number(data)
                                ) {
                                  setIsChanged(true)
                                } else {
                                  setIsChanged(false)
                                }

                                if (typeof data === 'string' && data !== '') {
                                  setIsChanged(false)
                                }

                                if (!data) {
                                  setWillBeUpdateCampaignTag(null)
                                  return
                                }

                                setWillBeUpdateCampaignTag(data)
                              }}
                            />
                          </KrcFormikField>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'col-6'}>
                    <div className={'col-8'}>
                      <div className={'row'}>
                        <div className={'col-6'}>
                          <label className={'mb-2 fs-6 d-flex align-items-center gap-1'}>
                            Başlangıç Tarihi
                            <InfoTooltip
                              status={true}
                              text={'GG-AA-YYYY formatında görüntülenmektedir. Düzenlenemez.'}
                            />
                          </label>
                          <Input
                            disabled
                            value={campaignGeneralInformation.date_start}
                            size={'large'}
                            style={{borderRadius: '5px'}}
                            suffix={<CalendarOutlined />}
                          />
                        </div>
                        <div className={'col-6'}>
                          <label className={'mb-2 fs-6 d-flex align-items-center gap-1'}>
                            Bitiş Tarihi
                            <InfoTooltip
                              status={true}
                              text={'GG-AA-YYYY formatında görüntülenmektedir. Düzenlenemez.'}
                            />
                          </label>
                          <Input
                            disabled
                            value={campaignGeneralInformation.date_end}
                            size={'large'}
                            style={{borderRadius: '5px'}}
                            suffix={<CalendarOutlined />}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-6'}>
                    <div className={'col-10'}>
                      <div className={'row'}>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'mb-2'}
                            type={'text'}
                            name={'campaign_margin'}
                            labelTitle={'Belirlenen Marj'}
                          />
                        </div>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'mb-3'}
                            type={'number'}
                            name={'campaign_type'}
                            labelTitle={'Kampanya Türü'}
                          >
                            <KrcSelect
                              placeholder={'Kampanya Türü'}
                              size={'large'}
                              options={CAMPAIGN_TYPES}
                              maxTagCount={'responsive'}
                              style={{width: '100%'}}
                              className={'ant-selector-border'}
                              showArrow={true}
                              value={willBeUpdateCampaignType}
                              onChange={(data) => {
                                if (
                                  Number(campaignGeneralInformation.campaign_type?.id) !==
                                  Number(data)
                                ) {
                                  setIsChanged(true)
                                } else {
                                  setIsChanged(false)
                                }

                                if (typeof data === 'string' && data !== '') {
                                  setIsChanged(false)
                                }

                                if (!data) {
                                  setWillBeUpdateCampaignType(null)
                                  return
                                }

                                setWillBeUpdateCampaignType(data)
                              }}
                            />
                          </KrcFormikField>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-6'}>
                    <div className={'col-10'}>
                      <div className={'row'}>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'mb-3'}
                            type={'text'}
                            name={'url_alias_campaign_seo_url'}
                            required
                            labelTitle={'Kampanya URL'}
                          />
                        </div>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'mb-2'}
                            type={'text'}
                            name={'campaign_keywords'}
                            required
                            labelTitle={'Anahtar Kelimeler'}
                          >
                            <Select
                              size={'large'}
                              mode='tags'
                              className={'form-control'}
                              style={{width: '100%', borderRadius: '5px'}}
                              placeholder='Etiket Giriniz'
                              onChange={handleChangeKeyword}
                              value={willBeUpdateCampaignKeywords?.map((item) => {
                                return {
                                  value: item.label,
                                  label: item.label,
                                }
                              })}
                              options={willBeUpdateCampaignKeywords?.map((item) => {
                                return {
                                  value: item.label,
                                  label: item.label,
                                }
                              })}
                            />
                          </KrcFormikField>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'col-6'}>
                    <div className={'col-8'}>
                      <div className={'row'}>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'w-full'}
                            type={'text'}
                            name={'campaign_price'}
                            labelTitle={'Kampanya Fiyatı'}
                          />
                        </div>
                        <div className={'col-6'}>
                          <KrcFormikField name={'badge'} required={false} labelTitle={'Badge'}>
                            <BadgeFilter
                              mode={undefined}
                              showSearch={true}
                              size={'large'}
                              style={{width: '200%'}}
                              allowClear={true}
                              onClear={() => {
                                setIsChanged(true)
                                setWillBeUpdatedBadge(null)
                              }}
                              value={willBeUpdateBadge?.name}
                              className={'select-filter-custom'}
                              dataType={'item'}
                              onSelected={(data) => {
                                if (!data) {
                                  setIsChanged(false)
                                  return
                                }
                                if (initialRef.current === 0) {
                                  setIsChanged(false)
                                  return
                                }

                                let selectedBadge = data as OptionData
                                if (
                                  Number(campaignGeneralInformation.badge?.id) !==
                                  Number(selectedBadge.value)
                                ) {
                                  setIsChanged(true)
                                } else {
                                  setIsChanged(false)
                                }

                                setWillBeUpdatedBadge({
                                  //@ts-ignore
                                  id: selectedBadge.value,
                                  //@ts-ignore
                                  name: selectedBadge.label,
                                })
                              }}
                              dropdownStyle={{zIndex: 99999}}
                              placeholder={'Badge Ara'}
                            />
                          </KrcFormikField>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-11'}>
                    <div className={'form-group'}>
                      <KrcFormikField
                        className={'mb-3'}
                        type={'text'}
                        name={'campaign_description'}
                        labelTitle={'Kampanya Açıklaması'}
                      >
                        <KrcRichTextEditorWithTable
                          className={'mt-2'}
                          height={250}
                          value={willBeUpdateCampaignDescription}
                          onChange={(value) => {
                            if (
                              campaignGeneralInformation.campaign_description === null &&
                              value === ''
                            ) {
                              setIsChanged(false)
                              setWillBeUpdateCampaignDescription(value)
                            } else if (
                              campaignGeneralInformation.campaign_description !==
                              value.replace(/(<([^>]+)>)/gi, '')
                            ) {
                              setIsChanged(true)
                              setWillBeUpdateCampaignDescription(value)
                            } else {
                              setIsChanged(false)
                              setWillBeUpdateCampaignDescription(value)
                            }
                          }}
                        />
                      </KrcFormikField>
                    </div>
                  </div>
                </div>
                <div className={'d-flex justify-content-end mt-7'}>
                  <KrcButton
                    onClick={() => {
                      dirty.handleSubmit()
                    }}
                    type={'text'}
                    size={'large'}
                    className={`custom-hover w-200px text-white button-bg-purple 0 ${
                      (dirty.dirty || isChanged) && !isSubmit ? 'border border-2 border-danger' : ''
                    } `}
                  >
                    Kaydet
                  </KrcButton>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}
