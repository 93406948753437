import React, {FC, createContext, useState} from 'react'

import {productBadgeLogFilterType} from '../../../components/models/products/ProductModel'

interface IProductBadgeContext {
  activityFilter: productBadgeLogFilterType
  setActivityFilter: any
}

export const ProductBadgeContext = createContext<IProductBadgeContext>({
  activityFilter: {},
  setActivityFilter: () => {},
})

export const ProductBadgeProvider: FC = ({children}) => {
  const [activityFilter, setActivityFilter] = useState<productBadgeLogFilterType>({})

  return (
    <>
      <ProductBadgeContext.Provider
        value={{
          activityFilter,
          setActivityFilter,
        }}
      >
        {children}
      </ProductBadgeContext.Provider>
    </>
  )
}
