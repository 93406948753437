import {
  categoryDataType,
  minimalCategoryType,
} from '../../components/models/categories/CategoryModel'
import {ReduxAction} from '../../helpers/redux/ReduxAction'
import {
  ADD_SELECTED_CATEGORY,
  DELETE_CATEGORY,
  DELETE_SELECTED_CATEGORY,
  SET_ADDED_PRODUCTS,
  SET_CATEGORIES,
  SET_CATEGORIES_META,
  SET_CATEGORY,
  SET_DELETED_PRODUCT_IDS,
  SET_SELECTED_CATEGORIES,
} from './CategoryActionType'

const initialState = {
  categories: [],
  categoriesMeta: {limit: 10, page: 1},
  selectedCategories: [],
  category: null,
  addedProducts: [],
  addedProductIds: [],
  deletedProducts: [],
  deletedProductIds: [],
}

const categoryReducer = (state = initialState, {type, payload}: ReduxAction) => {
  switch (type) {
    case SET_CATEGORIES:
      return {...state, categories: payload}
    case SET_CATEGORY:
      return {...state, category: payload}
    case SET_CATEGORIES_META:
      return {...state, categoriesMeta: payload}
    case DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(
          (category: categoryDataType) => category.category_id !== payload
        ),
      }
    case SET_SELECTED_CATEGORIES:
      return {
        ...state,
        selectedCategories: payload,
      }
    case ADD_SELECTED_CATEGORY:
      return {...state, selectedCategories: [...state.selectedCategories, payload]}
    case DELETE_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategories: state.selectedCategories.filter(
          (category: minimalCategoryType) => category.category_id !== payload
        ),
      }
    case SET_ADDED_PRODUCTS:
      return {
        ...state,
        addedProducts: payload,
        addedProductIds: payload.map((product: any) =>
          product.product_id ? product.product_id : product.id
        ),
      }
    case SET_DELETED_PRODUCT_IDS:
      return {
        ...state,
        deletedProductIds: payload,
      }
    default:
      return state
  }
}

export default categoryReducer
