import React, {FC} from 'react'

import {KrcButton} from '../../atoms/buttons/KrcButton'
import {KrcDatePicker} from '../../atoms/forms/KrcDatePicker'
import {useProductAdvancedFilter} from '../../hooks/products/useProductAdvancedFilter'
import {IProductVerticalAdvancedFilter} from '../../models/products/ProductListFilterModel'
import {BadgeFilter} from '../../molecules/filters/BadgeFilter'
import {BadgeTypeFilter} from '../../molecules/filters/BadgeTypeFilter'
import {CategoryFilter} from '../../molecules/filters/CategoryFilter'
import {ManufacturerFilter} from '../../molecules/filters/ManufacturerFilter'
import {MinMaxFilter} from '../../molecules/filters/MinMaxFilter'
import {ProductFilter} from '../../molecules/filters/ProductFilter'

export const ProductVerticalAdvancedFilter: FC<IProductVerticalAdvancedFilter> = ({onFilter}) => {
  const {filters, updateFilters} = useProductAdvancedFilter()

  const handleOnClick = () => {
    if (!onFilter || !filters) {
      return
    }

    onFilter(filters)
  }

  return (
    <>
      <CategoryFilter
        onSelected={(categoryIds) => {
          updateFilters({categoryIds})
        }}
        size={'middle'}
      />
      <ManufacturerFilter
        className={'mt-2'}
        style={{width: '100%'}}
        onSelected={(manufacturerIds) => {
          updateFilters({manufacturerIds})
        }}
        size={'middle'}
      />
      <ProductFilter
        onChanged={(productName) => {
          updateFilters({productName})
        }}
        label={'default'}
        autoCompleteProps={{
          placeholder: 'Ürün Adı Filtre Listesi',
          size: 'middle',
          className: 'mt-2',
        }}
      />
      <BadgeFilter
        onSelected={(badgeIds) => {
          updateFilters({badgeIds})
        }}
        size={'middle'}
        className={'mt-2'}
      />
      <BadgeTypeFilter
        onSelected={(badgeTypeIds) => {
          updateFilters({badgeTypeIds})
        }}
        className={'mt-2'}
        size={'middle'}
      />
      <div className={'d-flex align-items-center mt-3 gap-5'}>
        <MinMaxFilter
          style={{width: 110}}
          size={'middle'}
          onChanged={(price) => {
            updateFilters({price})
          }}
          minPlaceholder={'Min Fiyat'}
          maxPlaceholder={'Max Fiyat'}
        />
        <div className={'d-flex gap-1 align-items-center'}>
          <KrcDatePicker
            placeholder={'Eklenme Başlangıç Tarihi'}
            format={'DD.MM.YYYY'}
            onChanged={(data) => {
              if (!data.date) {
                updateFilters({addedStart: ''})
                return
              }
              updateFilters({addedStart: data.date})
            }}
          />
          -
          <KrcDatePicker
            placeholder={'Ekleme Bitiş Tarihi'}
            format={'DD.MM.YYYY'}
            onChanged={(data) => {
              if (!data.date) {
                updateFilters({addedEnd: ''})
                return
              }
              updateFilters({addedEnd: data.date})
            }}
          />
        </div>
        {/*<KrcRangePicker*/}
        {/*  placeholder={['Eklenme Tarihi Başlangıç', 'Ekleme Tarihi Bitiş']}*/}
        {/*  format={'DD.MM.YYYY dddd'}*/}
        {/*  onSelected={(date) => {*/}
        {/*    if (!date) {*/}
        {/*      updateFilters({addedStart: ''})*/}
        {/*      updateFilters({addedEnd: ''})*/}
        {/*      return*/}
        {/*    }*/}

        {/*    updateFilters({addedStart: date[0].format('YYYY-MM-DD').toString()})*/}
        {/*    updateFilters({addedEnd: date[1].format('YYYY-MM-DD').toString()})*/}
        {/*  }}*/}
        {/*  size={'middle'}*/}
        {/*  style={{flex: 1}}*/}
        {/*/>*/}
      </div>

      <div className={'d-flex justify-content-end align-items-center mt-3'} style={{gap: 15}}>
        <KrcButton className={'w-25'} type={'primary'} onClick={handleOnClick} size={'large'}>
          Filtrele
        </KrcButton>
      </div>
    </>
  )
}
