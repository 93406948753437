import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {PageTitle} from '../../../../_metronic/layout/core'
import {useSortingWeight} from '../../hooks/sorting/useSortingWeight'
import {ExcelExport} from '../../layout/excel/ExcelExportWrapper'
import {Toolbar} from '../../organisms/toolbars/Toolbar'

export const SortingWeightExportWrapper: FC = () => {
  const {downloadExcelUrl, exportSortingWeightList} = useSortingWeight()

  return (
    <>
      <Toolbar />
      <PageTitle>Sıralama Ağırlıkları Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport
              handleExportData={exportSortingWeightList}
              downloadExcelUrl={downloadExcelUrl}
            />
          }
        />
      </Routes>
    </>
  )
}
