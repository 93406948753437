import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {RoleContext, RoleProvider} from '../../../providers/role/RoleProvider'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {CreateRoleModal} from '../../modals/roles/CreateRoleModal'
import {RolePermissionManagementModal} from '../../modals/roles/permissions/RolePermissionManagementModal'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {RoleList} from './RoleList'

const RoleListWrapperWithoutProvider: FC = () => {
  const {
    showCreateModal,
    setShowCreateModal,
    PermissionManagementTargetRole,
    hideRolePermissionModal,
  } = useContext(RoleContext)
  return (
    <>
      <Toolbar
        rightField={
          <div className='d-flex align-items-center py-1'>
            <KrcButton
              size={'middle'}
              onClick={() => {
                setShowCreateModal(true)
              }}
            >
              {'Yeni Rol Oluştur'}
            </KrcButton>
          </div>
        }
      ></Toolbar>
      <PageTitle>Tüm Roller</PageTitle>
      <RoleList />
      {showCreateModal && <CreateRoleModal onHide={() => setShowCreateModal(false)} />}
      {PermissionManagementTargetRole && (
        <RolePermissionManagementModal onHide={hideRolePermissionModal} />
      )}
    </>
  )
}

export const RoleListWrapper: FC = () => {
  return (
    <>
      <RoleProvider>
        <RoleListWrapperWithoutProvider />
      </RoleProvider>
    </>
  )
}
