import {Empty, message} from 'antd'
import React, {FC, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {IBMCampaignSpecialGroupContext} from '../../../../providers/ibm/IBMCampaignSpecialGroupProvider'
import {fetchIBMCampaignsSpecialGroup} from '../../../../requests/ibm/IBMCampaignRequest'
import {IBmCampaignSpecialGroupType} from '../../../models/ibm/IBMCampaignSpecialGroupModel'
import {Loader} from '../../../molecules/Loader'
import {KrcTable} from '../../../organisms/tables/KrcTable'
import {KrcModal} from '../../KrcModal'

interface IIBMSpecialGroupSortModalProps {
  onHide: () => void
}

export const IBMSpecialGroupSortModal: FC<IIBMSpecialGroupSortModalProps> = ({onHide}) => {
  const {
    selectedCampaignId,
    sortColumns,
    handleOnSaveIBMCampaignSpecialGroupSorting,
    setIBMSortedCampaignSpecialGroup,
  } = useContext(IBMCampaignSpecialGroupContext)
  const [specialGroups, setSpecialGroups] = useState<IBmCampaignSpecialGroupType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    fetchIBMCampaignsSpecialGroup(selectedCampaignId, {page: 1, limit: 10}, 1)
      .then((res) => {
        setSpecialGroups(res.data.data)
      })
      .catch((err) => {
        message.error('Özel gruplar getirilirken bir hata oluştu.')
        message.error(err.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
        }}
        modalHeaderProps={{
          title: 'IBM Özel Grupları Sırala',
          onHide,
        }}
      >
        <Modal.Body className={'py-lg-10 px-lg-10'}>
          {specialGroups.length > 0 ? (
            <KrcTable
              columns={sortColumns}
              data={specialGroups}
              outerHeight={280}
              sortableProps={{
                isSortable: true,
                onSortChange: (specialGroups) => {
                  setIBMSortedCampaignSpecialGroup(() => specialGroups)
                },
              }}
            />
          ) : isLoading ? (
            <Loader />
          ) : (
            <Empty
              image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
              imageStyle={{
                height: 60,
              }}
              description={<span>Kampanya altında herhangi bir özel grup bulunamadı.</span>}
            ></Empty>
          )}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={onHide}
              style={{marginLeft: 10}}
            >
              İptal Et
            </button>
            <button
              type='button'
              onClick={() => {
                handleOnSaveIBMCampaignSpecialGroupSorting()
                onHide()
              }}
              className='btn btn-primary'
              style={{marginLeft: 10}}
            >
              Kaydet
            </button>
          </div>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
