import React, {FC, useContext, useEffect} from 'react'

import {ProductSelectionEnum} from '../../../enums/ProductSelectionEnum'
import {
  ProductSelectionContext,
  ProductSelectionProvider,
} from '../../../providers/tables/ProductSelectionProvider'
import {BadgeListDrawer} from '../../drawers/BadgeListDrawer'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {IProductSelection} from '../../models/products/ProductSelectionModel'
import {ProductSelectionHeader} from '../../molecules/headers/filters/ProductSelectionHeader'
import {ProductSelectionFilter} from './ProductSelectionFilter'
import {ProductSelectionList} from './ProductSelectionList'

const initialProductSelectionProps = {
  default: {
    type: ProductSelectionEnum.TEXT,
  },
}

const ProductSelectionWithProvider: FC<IProductSelection> = ({
  headerProps,
  onTransfer,
  removeAllExcluded = 0,
  transferProps,
  onSelectedItems,
  textAreaProps,
  clear,
  withBadge,
}) => {
  const currentHeaderProps = useKrcProp({
    config: initialProductSelectionProps.default,
    props: headerProps,
  }).getCurrentProps()

  const {transferData, updateFilters, selectedItems, productBadges, setProductBadges} =
    useContext(ProductSelectionContext)

  useEffect(() => {
    if (!onTransfer) {
      return
    }

    onTransfer(transferData)
  }, [transferData])

  useEffect(() => {
    onSelectedItems && onSelectedItems(selectedItems)
  }, [selectedItems])

  return (
    <>
      <ProductSelectionHeader {...currentHeaderProps} />
      <ProductSelectionFilter
        onFilter={(data) => {
          if (!updateFilters) {
            return
          }

          updateFilters(data)
        }}
        textAreaProps={textAreaProps}
      />
      <ProductSelectionList
        removeAllExcluded={removeAllExcluded}
        transferProps={{...transferProps}}
        clear={clear}
      />
      {withBadge && (
        <BadgeListDrawer
          badges={productBadges}
          onClose={() => {
            if (!setProductBadges) {
              return
            }
            setProductBadges(null)
          }}
        />
      )}
    </>
  )
}

export const ProductSelection: FC<IProductSelection> = ({
  includedProductIds,
  withFullPrice,
  clear,
  ...props
}) => {
  return (
    <>
      <ProductSelectionProvider
        includedProductIds={includedProductIds}
        withFullPrice={withFullPrice}
        withBadge={props.withBadge}
        withTotalQuantity={props.withTotalQuantity}
        withImage={props.withImage}
        withAvailable={props.withAvailable}
        onFilterChange={props.onFilterChange}
        onFilterTypeChange={props.onFilterTypeChange}
        onMetaChange={props.onMetaChange}
        filterCategoryIds={props.filterCategoryIds}
        disableExcludeCategoryIdFromUrl={props.disableExcludeCategoryIdFromUrl}
      >
        <ProductSelectionWithProvider {...props} clear={clear}></ProductSelectionWithProvider>
      </ProductSelectionProvider>
    </>
  )
}
