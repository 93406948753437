import React, {FC, useEffect} from 'react'

import {useCategoryList} from '../../../hooks/categories/useCategoryList'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const CategoryList: FC<any> = ({onChangeFilterParams}) => {
  const {
    columns,
    categories,
    handleOnSearch,
    onSelectedCategoriesTest,
    getCategories,
    fetchParams,
    meta,
    changeLimit,
    changePage,
  } = useCategoryList()

  useEffect(() => {
    if (!onChangeFilterParams) {
      return
    }
    onChangeFilterParams(fetchParams)
  }, [fetchParams])

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <>
      <KrcTable
        columns={columns}
        data={categories}
        paginationProps={{
          isPagination: true,
          paginationData: meta,
          page: meta.page,
          limit: meta.limit,
          onChangePage: (page: number) => {
            changePage(page)
          },
          onChangeLimit: (limit: number) => {
            changeLimit(limit)
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: handleOnSearch,
          style: {maxWidth: 250},
        }}
        selectableProps={{
          isSelectable: true,
          onSelectedItems: onSelectedCategoriesTest,
        }}
      />
    </>
  )
}
