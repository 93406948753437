import {AppstoreOutlined, DeleteOutlined, EditOutlined, SettingOutlined} from '@ant-design/icons'
import {Avatar, Image, Popconfirm, Tooltip, message} from 'antd'
import React, {FC, createContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Cell, Column, Row} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {
  gameInitialValueType,
  gameSettingsType,
  gameType,
} from '../../components/models/games/GameModel'
import {errorMessage} from '../../helpers/ErrorHelper'
import {
  createGameRequest,
  deleteGameRequest,
  getGamesRequest,
  updateGameRequest,
  updateGameSettingsRequest,
  updateSortGameRequest,
} from '../../requests/games/GameRequest'

interface IGameContext {
  columns: Column<any>[]
  games: any
  setGames: React.Dispatch<React.SetStateAction<any>>
  gameDrawerVisible: boolean
  setGameDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  willBeUpdatedGame?: gameType | null
  setWillBeUpdatedGame: React.Dispatch<React.SetStateAction<gameType | null>>
  createGame: (willBeCreatedGame: any) => void
  updateGame: (willBeUpdatedGame: any) => void
  gameSettingDrawerVisible: boolean
  setGameSettingDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  getGames: () => void
  willBeUpdatedGameSettings: gameSettingsType[] | null
  setWillBeUpdatedGameSettings: React.Dispatch<React.SetStateAction<gameSettingsType[] | null>>
  setUpdatingGameId: React.Dispatch<React.SetStateAction<number | null>>
  updateGameSettings: (data: any) => void
  willBeSortedGameIds: number[]
  setWillBeSortedGameIds: React.Dispatch<React.SetStateAction<number[]>>
  updateSortGame: () => void
}

export const GameContext = createContext<IGameContext>({
  columns: [],
  games: [],
  setGames: () => {},
  gameDrawerVisible: false,
  setGameDrawerVisible: () => {},
  setWillBeUpdatedGame: () => {},
  createGame: () => {},
  updateGame: () => {},
  gameSettingDrawerVisible: false,
  setGameSettingDrawerVisible: () => {},
  getGames: () => {},
  willBeUpdatedGameSettings: null,
  setWillBeUpdatedGameSettings: () => {},
  updateGameSettings: () => {},
  setUpdatingGameId: () => {},
  setWillBeSortedGameIds: () => {},
  willBeSortedGameIds: [],
  updateSortGame: () => {},
})

export const GameProvider: FC = ({children}) => {
  const [games, setGames] = useState<gameType[]>([])
  const [gameDrawerVisible, setGameDrawerVisible] = useState<boolean>(false)
  const [gameSettingDrawerVisible, setGameSettingDrawerVisible] = useState<boolean>(false)
  const [willBeUpdatedGame, setWillBeUpdatedGame] = useState<gameType | null>(null)
  const [willBeUpdatedGameSettings, setWillBeUpdatedGameSettings] = useState<
    gameSettingsType[] | null
  >(null)
  const [updatingGameId, setUpdatingGameId] = useState<number | null>(null)
  const [willBeSortedGameIds, setWillBeSortedGameIds] = useState<number[]>([])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Görsel',
        accessor: 'game_image',
        Cell: ({row}: Cell<Row>) => (
          <Avatar size={52} src={row.values.game_image} style={{minWidth: 52}} />
        ),
        maxWidth: 52,
        manualWidth: 52,
      },
      {
        Header: 'ID',
        accessor: 'game_id',
      },
      {
        Header: 'Adı',
        accessor: 'game_name',
      },
      {
        Header: 'Ad Kısatlması',
        accessor: 'game_short_name',
      },
      {
        Header: 'Başlık',
        accessor: 'game_title',
      },
      {
        Header: 'Alt Başlık',
        accessor: 'game_subtitle',
      },
      {
        Header: 'Oyun Link',
        accessor: 'game_link',
        maxWidth: 200,
        manualWidth: 200,
        cellClassName: 'text-break',
      },
      {
        Header: 'Nasıl Oynanır Görsel',
        accessor: 'how_to_play_image',
        Cell: ({row}: Cell<Row>) => <Image width={75} src={row.values.how_to_play_image} />,
        maxWidth: 75,
        manualWidth: 75,
      },
      {
        Header: 'Durum',
        accessor: 'is_active',
        Cell: ({row}: Cell) => <div>{row.values.is_active ? 'Aktif' : 'Pasif'}</div>,
      },
      {
        Header: 'Çarpan Oranı',
        accessor: 'ratio',
        maxWidth: 75,
        manualWidth: 75,
      },
      {
        Header: () => <div style={{textAlign: 'right'}}>Aksiyon</div>,
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        accessor: 'col5',
        id: 'click-me-button',
        Cell: ({row}: Cell<Row>) => (
          <div className='d-flex flex-row text-end justify-content-end'>
            <Tooltip title={'Güncelle'}>
              <div
                onClick={() => {
                  setWillBeUpdatedGame(row.original as unknown as gameType)
                  setUpdatingGameId(row.values.game_id)
                  setGameDrawerVisible(true)
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
              >
                <EditOutlined />
              </div>
            </Tooltip>
            <Tooltip title={'Oyun Kartları'}>
              <Link
                to={`/games/${row.values.game_id}/cards`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                style={{color: 'unset'}}
              >
                <AppstoreOutlined />
              </Link>
            </Tooltip>
            <Tooltip title={'Ayarlar'}>
              <div
                onClick={() => {
                  // @ts-ignore
                  setWillBeUpdatedGameSettings(row.original.game_settings as gameSettingsType)
                  setUpdatingGameId(row.values.game_id)
                  setGameSettingDrawerVisible(true)
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
              >
                <SettingOutlined />
              </div>
            </Tooltip>
            <Tooltip title={'Sil'}>
              <Popconfirm
                title='Bu oyunu silmek istediğinden emin misin ?'
                onConfirm={(e) => deleteGame(row.values.game_id)}
                // okButtonProps={{loading: confirmLoading}}
                okText='Evet'
                cancelText='Hayır'
              >
                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                  <DeleteOutlined />
                </div>
              </Popconfirm>
            </Tooltip>
          </div>
        ),
        Filter: KrcEmptyFilter,
      },
    ],
    []
  )

  useEffect(() => {
    getGames()
  }, [])

  const getGames = () => {
    getGamesRequest().then((res) => {
      const resData = res.data.data ?? []

      setGames(resData)
    })
  }

  const createGame = (willBeCreatedGame: gameInitialValueType) => {
    const key = 'create-game'

    message.loading({content: 'Oyun oluşturuluyor...', key, duration: 0})

    createGameRequest(willBeCreatedGame)
      .then((res) => {
        message.success({content: 'Oyun Başarıyla oluşturuldu.', key})
        getGames()
        setGameDrawerVisible(false)
      })
      .catch(() => {
        message.error({content: 'Oyun Başarıyla oluşturulamadı.', key})
      })
  }

  const updateGame = (willBeUpdatingGame: gameInitialValueType) => {
    if (!willBeUpdatedGame) {
      return
    }

    const key = 'update-game'

    message.loading({content: 'Oyun güncelleniyor...', key, duration: 0})

    updateGameRequest(willBeUpdatedGame.game_id, willBeUpdatingGame)
      .then((res) => {
        message.success({content: 'Oyun Başarıyla güncellendi.', key})
        getGames()
        setWillBeUpdatedGame(null)
        setGameDrawerVisible(false)
        setUpdatingGameId(null)
      })
      .catch((e) => {
        errorMessage(e)
        message.destroy(key)
      })
  }

  const deleteGame = (gameId: number) => {
    const key = 'delete-game'

    message.loading({
      content: 'Oyunu siliniyor...',
      key,
    })
    deleteGameRequest(gameId)
      .then(() => {
        message.success({
          content: 'Oyun silindi.',
          key,
        })

        getGames()
      })
      .catch((e) => {
        errorMessage(e)
        message.destroy(key)
      })
  }

  const updateGameSettings = (willBeUpdatingGameSettings: any) => {
    if (!updatingGameId) {
      return
    }
    const key = 'update-game-settings'

    message.loading({content: 'Oyun Ayarları güncelleniyor...', key, duration: 0})

    updateGameSettingsRequest(updatingGameId, willBeUpdatingGameSettings)
      .then(() => {
        message.success({content: 'Oyun Ayarları Başarıyla güncellendi.', key})

        getGames()
        setGameSettingDrawerVisible(false)
        setWillBeUpdatedGameSettings(null)
        setUpdatingGameId(null)
      })
      .catch((e) => {
        errorMessage(e)
        message.destroy(key)
      })
  }

  const updateSortGame = () => {
    const key = 'update-sort-game'

    message.loading({content: 'Sıralama güncelleniyor...', key})

    updateSortGameRequest(willBeSortedGameIds)
      .then(() => {
        message.success({content: 'Sıralama başarıyla güncellendi.', key})
      })
      .catch(() => {
        message.error({content: 'Sıralama güncellenemdi.', key})
      })
  }

  return (
    <>
      <GameContext.Provider
        value={{
          columns,
          games,
          setGames,
          gameDrawerVisible,
          setGameDrawerVisible,
          willBeUpdatedGame,
          setWillBeUpdatedGame,
          createGame,
          updateGame,
          gameSettingDrawerVisible,
          setGameSettingDrawerVisible,
          getGames,
          willBeUpdatedGameSettings,
          setWillBeUpdatedGameSettings,
          updateGameSettings,
          setUpdatingGameId,
          setWillBeSortedGameIds,
          willBeSortedGameIds,
          updateSortGame,
        }}
      >
        {children}
      </GameContext.Provider>
    </>
  )
}
