import {Alert} from 'antd'
import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'

import {MANUFACTURER_STORES} from '../../../../enums/ManufacturerStoresEnum'
import {GENERAL_IMAGE_UPLOAD_PATH} from '../../../../enums/ManufacturerUploadImagePathEnum'
import {ManufacturerContext} from '../../../../providers/ManufacturerProvider'
import {FileManagerModal} from '../../../../service/FileManager/FileManagerModal'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {manufacturerTabDataType} from '../../../models/manufacturers/ManufacturerModel'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

interface IManufacturerGeneralInformationProps {
  manufacturerGeneralInformation: manufacturerTabDataType
}

const updateManufacturerGeneralInformationSchema = Yup.object({
  name: Yup.string().required('İsim alanı zorunludur'),
  meta_title: Yup.string().nullable(),
  meta_description: Yup.string().nullable(),
  description: Yup.string().nullable(),
  seo_text: Yup.string().nullable(),
  keyword: Yup.string().nullable(),
  display_seo_text: Yup.number().required('Seo metni görüntüleme alanı zorunludur'),
  store_ids: Yup.array().nullable(),
  sort_order: Yup.number().required('Sıralama alanı zorunludur'),
  status: Yup.number().required('Yayın durumu alanı zorunludur'),
  is_followable: Yup.number().required('Takip durumu alanı zorunludur'),
  followable_stores: Yup.array().nullable(),
  image: Yup.string().nullable(),
})

export const ManufacturerGeneralInformation: FC<IManufacturerGeneralInformationProps> = ({
  manufacturerGeneralInformation,
}) => {
  const {updateManufacturer} = useContext(ManufacturerContext)
  const [storeIds, setStoreIds] = useState<number[]>([])
  const [image, setImage] = useState<string>('')
  const [isChanged, setIsChanged] = useState<boolean>(false)

  const defaultImagePath = 'https://cdn.karaca.com/image/'

  useEffect(() => {
    if (manufacturerGeneralInformation.store_ids) {
      setStoreIds(manufacturerGeneralInformation.store_ids.map((store) => store.store_id))
    }
    if (manufacturerGeneralInformation.image) {
      setImage(manufacturerGeneralInformation.image)
    }
  }, [])

  const handleOnSubmit = (data: FormikValues) => {
    if (manufacturerGeneralInformation.image !== image) {
      data.image = image
    }
    if (manufacturerGeneralInformation.store_ids.map((store) => store.store_id) !== storeIds) {
      data.store_ids = storeIds
    }
    updateManufacturer(manufacturerGeneralInformation.manufacturer_id, data)
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={manufacturerGeneralInformation}
        onSubmit={handleOnSubmit}
        validationSchema={updateManufacturerGeneralInformationSchema}
        validateOnMount={true}
      >
        {(dirty) => {
          return (
            <Form className='form mt-6 space-between'>
              {dirty.dirty || isChanged ? (
                <Alert
                  message='Değişiklik yaptığınızı fark ettik. Sağ alttan değişiklikleri kaydetmeyi unutma!'
                  type='warning'
                  showIcon
                  closable
                />
              ) : null}
              <div className={'row mb-3'}>
                <div className={'col-4'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'text'}
                    name={'name'}
                    labelTitle={'Manufacturer Adı'}
                    required
                    tooltipProps={{
                      status: true,
                      text: 'Manufacturer isim değerini giriniz.',
                    }}
                  />
                </div>
                <div className={'col-4'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'text'}
                    name={'meta_title'}
                    labelTitle={'Meta Başlık'}
                    tooltipProps={{
                      status: true,
                      text: 'Meta başlık değerini giriniz.',
                    }}
                  />
                </div>
                <div className={'col-4'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'text'}
                    name={'meta_description'}
                    labelTitle={'Meta Açıklaması'}
                    tooltipProps={{
                      status: true,
                      text: 'Meta açıklama değerini giriniz.',
                    }}
                  />
                </div>
              </div>
              <div className={'row mb-3'}>
                <div className={'col-4'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'text'}
                    name={'description'}
                    labelTitle={'Açıklama'}
                    tooltipProps={{
                      status: true,
                      text: 'Açıklama değerini giriniz.',
                    }}
                  />
                </div>
                <div className={'col-4'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'text'}
                    name={'seo_text'}
                    labelTitle={'SEO Yazısı'}
                    tooltipProps={{
                      status: true,
                      text: 'SEO Yazısı değerini giriniz.',
                    }}
                  />
                </div>
                <div className={'col-4'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'text'}
                    name={'keyword'}
                    labelTitle={'SEO Kelimesi'}
                    tooltipProps={{
                      status: true,
                      text: 'SEO Kelimesi değerini giriniz.',
                    }}
                  />
                </div>
              </div>
              <div className={'row mb-3'}>
                <div className={'col-4'}>
                  <KrcFormikField
                    className={'mb-2'}
                    name={'display_seo_text'}
                    labelTitle={'SEO Yazısı Durumu'}
                    required
                    tooltipProps={{
                      status: true,
                      text: 'SEO Yazısı Durumunu Seçiniz.',
                    }}
                  >
                    <Field as='select' name='display_seo_text' className='form-select'>
                      <option value={''} disabled={true}>
                        Seçiniz
                      </option>
                      <option value={1}>Aktif</option>
                      <option value={0}>Pasif</option>
                    </Field>
                  </KrcFormikField>
                </div>
                <div className={'col-4'}>
                  <KrcFormikField
                    className={'mb-2'}
                    type={'text'}
                    name={'sort_order'}
                    labelTitle={'Sıralama'}
                    tooltipProps={{
                      status: true,
                      text: 'Sıralama değerini giriniz.',
                    }}
                  />
                </div>
                <div className={'col-4'}>
                  <KrcFormikField
                    className={'mb-2 z-index-9999'}
                    name={'store_ids'}
                    labelTitle={'İlgili Mağazalar'}
                    tooltipProps={{
                      status: true,
                      text: 'İlgili mağazaları seçin.',
                    }}
                  >
                    <KrcSelect
                      maxTagCount='responsive'
                      mode={'multiple'}
                      placeholder={'Seçiniz'}
                      size={'large'}
                      dropdownStyle={{zIndex: 9999}}
                      value={storeIds}
                      className={
                        'ant-selector-border custom-ant-select-selection-placeholder w-full d-block'
                      }
                      options={MANUFACTURER_STORES.map((store) => {
                        return {
                          value: store.value,
                          label: store.label,
                        }
                      })}
                      onChange={(storeId) => {
                        if (!storeId) {
                          return
                        }

                        if (
                          JSON.stringify(storeId) !==
                          JSON.stringify(
                            manufacturerGeneralInformation.store_ids.map((store) => store.store_id)
                          )
                        ) {
                          setIsChanged(true)
                        } else {
                          setIsChanged(false)
                        }
                        setStoreIds(storeId)
                      }}
                      style={{width: '100%'}}
                    />
                  </KrcFormikField>
                </div>
              </div>
              <div className={'row mb-3'}>
                <div className={'col-4'}>
                  <KrcFormikField
                    className={'mb-2'}
                    name={'status'}
                    labelTitle={'Durum'}
                    required
                    tooltipProps={{
                      status: true,
                      text: 'Durum Değerini Seçiniz',
                    }}
                  >
                    <Field as='select' name='status' className='form-select'>
                      <option value={''} disabled={true}>
                        Seçiniz
                      </option>
                      <option value={1}>Aktif</option>
                      <option value={0}>Pasif</option>
                    </Field>
                  </KrcFormikField>
                </div>
                <div className={'col-4'}>
                  <KrcFormikField
                    className={'mb-2'}
                    name={'is_followable'}
                    labelTitle={'Takip Edilebilir Mi?'}
                    required
                    tooltipProps={{
                      status: true,
                      text: 'SEO Yazısı Durumunu Seçiniz.',
                    }}
                  >
                    <Field as='select' name='is_followable' className='form-select'>
                      <option value={''} disabled={true}>
                        Seçiniz
                      </option>
                      <option value={1}>Aktif</option>
                      <option value={0}>Pasif</option>
                    </Field>
                  </KrcFormikField>
                </div>
                <div className={'col-4'}>
                  <KrcFormikField
                    className={'mb-2 mt-2'}
                    name={'image'}
                    labelTitle={'Görsel Seçiniz'}
                  >
                    <FileManagerModal
                      onSelected={(link) => {
                        if (link) {
                          setIsChanged(true)
                        }
                        setImage(link ? link : '')
                      }}
                      defaultImage={
                        manufacturerGeneralInformation.image
                          ? defaultImagePath + manufacturerGeneralInformation.image
                          : undefined
                      }
                      tempUploadDir={GENERAL_IMAGE_UPLOAD_PATH}
                    />
                  </KrcFormikField>
                </div>
              </div>
              <div className={'d-flex justify-content-end mt-7'}>
                <button
                  type='submit'
                  className={`btn btn-primary ${
                    dirty.dirty || isChanged ? 'border border-2 border-danger' : ''
                  }`}
                  style={{marginLeft: 10}}
                >
                  Kaydet
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
