import _ from 'lodash'
import {useEffect, useRef, useState} from 'react'

export const useDeepCompareEffect = (data: object, retryCopy?: number) => {
  const [copyData, setCopyData] = useState<any>({})
  const [isChanged, setIsChanged] = useState(false)
  const initialRef = useRef(0)

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    _.isEqual(data, copyData) ? setIsChanged(false) : setIsChanged(true)
  }, [data, copyData])

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current = 1
    }

    setCopyData(JSON.parse(JSON.stringify(data)))
  }, [retryCopy])

  return {isChanged}
}
