import {FC, createContext, useEffect, useState} from 'react'

import {
  CategoryMapTableType,
  CategoryMapType,
} from '../../components/models/categories/CategoryMapModel'
import {getCategoriesMapRequest} from '../../requests/categories/CategoryRequest'

interface ICategoryMapContext {
  categoryMaps: {[key: string]: CategoryMapTableType[]}
  groupTitle: {[key: string]: string}
  getCategoriesMap?: (parentId: number, deep: number, type?: string) => void
  categoryType?: {
    value: string
    label: string
  }[]
  updateGroupTitle?: (data: string, deep: number) => void
  fetchLoading?: boolean
}

export const CategoryMapContext = createContext<ICategoryMapContext>({
  categoryMaps: {0: [], 1: [], 2: [], 3: []},
  groupTitle: {0: 'Ana Kategori', 1: '', 2: '', 3: ''},
})

const categoryType = [
  {value: '*', label: 'Tümü'},
  {value: 'category', label: 'Kategori'},
  {value: 'boutique', label: 'Butik'},
  {value: 'campaign', label: 'Kampanya'},
]

export const CategoryMapProvider: FC = ({children}) => {
  const [categoryMaps, setCategoryMaps] = useState({0: [], 1: [], 2: [], 3: []})
  const [groupTitle, setGroupTitle] = useState({0: 'Ana Kategori', 1: '', 2: '', 3: ''})
  const [fetchLoading, setFetchLoading] = useState<boolean>(true)

  const getCategoriesMap = (parentId: number = 0, deep: number = 0, type?: string) => {
    setFetchLoading(true)
    getCategoriesMapRequest(parentId, type)
      .then((res) => {
        const categories = res.data.data.map((categoryMap: CategoryMapType) => {
          return {
            id: categoryMap.category_id,
            name: categoryMap.description?.name,
            count: categoryMap.relation_count,
          }
        }) as CategoryMapTableType[]

        updateCategoryMaps(categories, deep)

        if (deep === 0) {
          updateCategoryMaps([], 1)
          updateCategoryMaps([], 2)
          updateCategoryMaps([], 3)
        }

        if (deep === 1) {
          updateCategoryMaps([], 2)
          updateCategoryMaps([], 3)
        }

        if (deep === 2) {
          updateCategoryMaps([], 3)
        }

        setFetchLoading(false)
      })
      .catch(() => {
        setFetchLoading(false)
      })
  }

  const updateCategoryMaps = (data: CategoryMapTableType[], deep: number) => {
    setCategoryMaps((categoryMaps) => {
      return {...categoryMaps, [deep]: data}
    })
  }

  const updateGroupTitle = (data: string, deep: number) => {
    setGroupTitle((groupTitle) => {
      return {...groupTitle, [deep]: data}
    })
  }

  useEffect(() => {
    getCategoriesMap()
  }, [])

  return (
    <CategoryMapContext.Provider
      value={{
        groupTitle,
        categoryType,
        categoryMaps,
        fetchLoading,
        getCategoriesMap,
        updateGroupTitle,
      }}
    >
      {children}
    </CategoryMapContext.Provider>
  )
}
