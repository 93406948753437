import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {useCategoryList} from '../../../../hooks/categories/useCategoryList'
import {ExcelExport} from '../../../../layout/excel/ExcelExportWrapper'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'

export const CategoryListProductsExportWrapper: FC = () => {
  const {exportCategoryListProducts, downloadExcelUrl} = useCategoryList()

  return (
    <>
      <Toolbar />
      <PageTitle>Kategorilerin Ürünlerini Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport
              handleExportData={exportCategoryListProducts}
              downloadExcelUrl={downloadExcelUrl}
            />
          }
        />
      </Routes>
    </>
  )
}
