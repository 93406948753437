import {message} from 'antd'
import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext, useEffect} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {IBMCampaignSpecialGroupContext} from '../../../../providers/ibm/IBMCampaignSpecialGroupProvider'
import {updateSpecialGroup} from '../../../../requests/ibm/IBMCampaignRequest'
import {IBmCampaignSpecialGroupType} from '../../../models/ibm/IBMCampaignSpecialGroupModel'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'
import {KrcModal} from '../../KrcModal'

interface IIBMCampaignSpecialGroupProps {
  onHide: () => void
}

export const UpdateSpecialGroupModal: FC<IIBMCampaignSpecialGroupProps> = ({onHide}) => {
  const {willBeUpdateSpecialGroup, selectedCampaignId, getIBMCampaignsSpecialGroup} = useContext(
    IBMCampaignSpecialGroupContext
  )
  const [formikInitialValues, setFormikInitialValues] = React.useState<IBmCampaignSpecialGroupType>(
    {} as IBmCampaignSpecialGroupType
  )

  useEffect(() => {
    if (!willBeUpdateSpecialGroup) return
    setFormikInitialValues({
      id: willBeUpdateSpecialGroup?.id,
      created_at: willBeUpdateSpecialGroup?.created_at,
      updated_at: willBeUpdateSpecialGroup?.updated_at,
      group_id: willBeUpdateSpecialGroup?.group_id,
      group_name: willBeUpdateSpecialGroup?.group_name,
      group_title: willBeUpdateSpecialGroup?.group_title
        ? willBeUpdateSpecialGroup?.group_title
        : '',
      ibm_param_code: willBeUpdateSpecialGroup?.ibm_param_code,
      ibm_param_value: willBeUpdateSpecialGroup?.ibm_param_value,
      ibm_special_group_sort: willBeUpdateSpecialGroup?.ibm_special_group_sort,
      required: willBeUpdateSpecialGroup?.required ? 1 : 0,
      include_in_discount: willBeUpdateSpecialGroup?.include_in_discount ? 1 : 0,
      max_product: willBeUpdateSpecialGroup?.max_product,
      qty: willBeUpdateSpecialGroup?.qty,
    })
  }, [])

  const handleOnSubmit = (values: FormikValues) => {
    message.info('Özel grup güncelleniyor...')
    const req = {
      group_title: values.group_title,
      ibm_special_group_sort: values.ibm_special_group_sort,
      required: values.required,
      max_product: values.max_product,
      qty: values.qty,
      include_in_discount: values.include_in_discount,
    }
    updateSpecialGroup(selectedCampaignId, values.id, req)
      .then((res) => {
        message.success('Özel grup başarıyla güncellendi!')
      })
      .catch((err) => {
        message.error('Özel grup güncellenirken bir hata oluştu!')
        message.error(err.response.data.message)
      })
      .finally(() => {
        onHide()
        getIBMCampaignsSpecialGroup(null, selectedCampaignId)
      })
  }

  return (
    <>
      {formikInitialValues.group_name && (
        <KrcModal
          modalProps={{
            size: 'lg',
          }}
          modalHeaderProps={{
            title: `(${willBeUpdateSpecialGroup?.group_name}) Special Grubunu Düzenle`,
            onHide,
          }}
        >
          <Modal.Body className='py-lg-10 px-lg-10'>
            <Formik initialValues={formikInitialValues} onSubmit={handleOnSubmit}>
              {() => {
                return (
                  <Form className='form mt-6 space-between'>
                    <div className={'row'}>
                      <KrcFormikField
                        className={'mb-2 col-6'}
                        type={'text'}
                        name={'group_title'}
                        labelTitle={'Grup Başlığı'}
                      />
                      <KrcFormikField
                        className={'mb-2 col-6'}
                        type={'number'}
                        name={'ibm_special_group_sort'}
                        labelTitle={'Grup Sıralaması'}
                      />
                    </div>
                    <div className={'row mt-2'}>
                      <KrcFormikField name={'required'} labelTitle={'Zorunlu Alan'} required>
                        <Field as='select' name='required' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Zorunlu</option>
                          <option value={0}>Zorunlu Değil</option>
                        </Field>
                      </KrcFormikField>
                      <KrcFormikField
                        className={'mb-2 col-6'}
                        type={'number'}
                        name={'max_product'}
                        labelTitle={'Grup Mak. Ürün Sayısı'}
                        tooltipProps={{
                          status: true,
                          text: 'Sıfır girilirse maksimum seçim dikkate alınmaz.',
                        }}
                      />
                    </div>
                    <div className={'row mt-2'}>
                      <KrcFormikField
                        className={'mb-2 col-6'}
                        type={'number'}
                        name={'qty'}
                        labelTitle={'Bölüm Adedi'}
                      />
                      <KrcFormikField
                        name={'include_in_discount'}
                        labelTitle={'İndirime Dahil Et'}
                        required
                      >
                        <Field as='select' name='include_in_discount' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Dahil</option>
                          <option value={0}>Dahil Değil</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                    <div className={'d-flex justify-content-end mt-7'}>
                      <button
                        type='button'
                        onClick={onHide}
                        className='btn btn-secondary'
                        style={{marginLeft: 10}}
                      >
                        İptal
                      </button>
                      <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                        Kaydet
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </Modal.Body>
        </KrcModal>
      )}
    </>
  )
}
