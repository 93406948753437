import {ICategoryBanner} from '../../../components/models/categories/CategoryBannerModel'
import {ReduxAction} from '../../../helpers/redux/ReduxAction'
import {
  ADD_CATEGORY_BANNER,
  CHANGE_VISIBLE_CATEGORY_BANNER_MODAL,
  DELETE_CATEGORY_BANNER,
  SET_CATEGORY_BANNER,
  SET_CATEGORY_BANNERS,
  SET_SELECTED_CATEGORY_BANNERS,
  UPDATE_CATEGORY_BANNER,
} from './CategoryBannerActionType'

const initialState = {
  banners: [],
  selectedBanners: [],
  banner: null,
  visibleBannerModal: false,
}

const categoryBannerReducer = (state = initialState, {type, payload}: ReduxAction) => {
  switch (type) {
    case SET_CATEGORY_BANNERS:
      return {...state, banners: payload}
    case SET_CATEGORY_BANNER:
      return {...state, banner: payload}
    case CHANGE_VISIBLE_CATEGORY_BANNER_MODAL:
      return {...state, visibleBannerModal: payload}
    case ADD_CATEGORY_BANNER:
      return {...state, banners: [...state.banners, payload]}
    case UPDATE_CATEGORY_BANNER:
      return {
        ...state,
        banners: state.banners.map((banner: ICategoryBanner) => {
          if (banner.id !== payload.bannerId) {
            return banner
          }

          return {...banner, ...payload.banner}
        }),
      }
    case DELETE_CATEGORY_BANNER:
      return {
        ...state,
        banners: state.banners.filter((banner: ICategoryBanner) => banner.id !== payload.bannerId),
      }
    case SET_SELECTED_CATEGORY_BANNERS:
      return {
        ...state,
        selectedBanners: payload,
      }
    default:
      return state
  }
}

export default categoryBannerReducer
