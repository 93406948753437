import {CloseOutlined} from '@ant-design/icons'
import {Popconfirm, Tooltip} from 'antd'
import React, {FC, useContext, useEffect, useRef} from 'react'

import {
  CategoryGhostFilterContext,
  CategoryGhostFilterProvider,
} from '../../../../providers/category/ghosts/CategoryGhostFilterContext'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {CategoryGhostFilterType} from '../../../models/categories/CategoryGhostModel'
import {CategoryGhostFilter} from './CategoryGhostFilter'

interface ICategoryGhostFilters {
  defaultFilters?: CategoryGhostFilterType[]
  onChangeFilters?: (filters: CategoryGhostFilterType[]) => void
  deep?: number
}

const CategoryGhostFiltersWithProvider: FC<ICategoryGhostFilters> = ({
  defaultFilters,
  onChangeFilters,
  deep,
}) => {
  const {filters, options, addFilter, deleteFilter} = useContext(CategoryGhostFilterContext)

  const initialRef = useRef(0)

  const handleDefaultFilters = (defaultFilters?: CategoryGhostFilterType[]) => {
    if (defaultFilters) {
      defaultFilters.forEach((filter) => {
        addFilter(filter)
      })
    }
  }

  useEffect(() => {
    if (!onChangeFilters || initialRef.current === 0) {
      return
    }

    onChangeFilters(filters)
  }, [filters])

  useEffect(() => {
    if (initialRef.current === 1) {
      initialRef.current = 2

      handleDefaultFilters(defaultFilters)
    }
  }, [defaultFilters])

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current = 1
    }

    handleDefaultFilters(defaultFilters)
  }, [])

  return (
    <>
      <div>
        <div className={'d-flex justify-content-center'}>Filtre için seçim yapınız</div>
        {filters.map((filter, key) => {
          return (
            <div className={'d-flex align-items-center'} key={`ghost-filter-${deep}-${filter.key}`}>
              <CategoryGhostFilter type={filter.key} />
              {filters.length > 1 && (
                <Tooltip title={'Sil'}>
                  <Popconfirm
                    title='Bu filtreyi silmek istediğinden emin misin ?'
                    onConfirm={(e) => {
                      deleteFilter(filter.key)
                    }}
                    okText='Evet'
                    cancelText='Hayır'
                  >
                    <div className='btn btn-icon btn-active-color-primary btn-sm'>
                      <CloseOutlined />
                    </div>
                  </Popconfirm>
                </Tooltip>
              )}
            </div>
          )
        })}
        <div className={'d-flex justify-content-center mt-2'}>
          {options.length !== filters.length && (
            <KrcButton
              onClick={() => {
                addFilter({key: null, value: null})
              }}
            >
              Ekle
            </KrcButton>
          )}
        </div>
      </div>
    </>
  )
}

export const CategoryGhostFilters: FC<ICategoryGhostFilters> = ({
  defaultFilters,
  onChangeFilters,
  deep,
}) => {
  const handleOnChangeFilter = (filters: CategoryGhostFilterType[]) => {
    if (!onChangeFilters) {
      return
    }

    onChangeFilters(filters)
  }

  return (
    <>
      <CategoryGhostFilterProvider>
        <CategoryGhostFiltersWithProvider
          deep={deep}
          defaultFilters={defaultFilters}
          onChangeFilters={handleOnChangeFilter}
        />
      </CategoryGhostFilterProvider>
    </>
  )
}
