import * as querystring from 'query-string'
import {useNavigate} from 'react-router-dom'

import {
  exportBadgeRequest,
  exportBadgeTiedRequest,
} from '../../../requests/badges/BadgeExportRequest'
import {useExport} from '../exports/useExport'

export const useBadge = () => {
  const navigate = useNavigate()
  const {checkExport, downloadExcelUrl} = useExport()
  const exportBadges = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: true,
    })

    exportBadgeRequest(params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }
  const exportBadgeTies = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: true,
    })

    exportBadgeTiedRequest(params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  return {
    exportBadges,
    downloadExcelUrl,
    exportBadgeTies,
  }
}
