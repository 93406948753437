import {Button, ButtonProps} from 'antd'
import {FC} from 'react'
import * as React from 'react'

import {useKrcProp} from '../../hooks/components/useKrcProp'

const buttonConfig = {
  default: {
    type: 'primary',
    size: 'middle',
    style: {borderRadius: 5},
  } as ButtonProps,
}

interface IButtonConfig {
  config?: 'default'
}

export const KrcButton: FC<ButtonProps & IButtonConfig> = ({
  config = 'default',
  children,
  ...props
}) => {
  const currentProps = useKrcProp({config: buttonConfig[config], props}).getCurrentProps()

  return (
    <>
      <Button {...currentProps}>{children}</Button>
    </>
  )
}
