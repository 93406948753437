import {CaretDownFilled, CloseCircleOutlined} from '@ant-design/icons'
import {Dropdown, Menu, notification} from 'antd'
import React, {useEffect, useMemo, useState} from 'react'
import {Cell} from 'react-table'

import {CANCELED, COMPLETED} from '../../../../enums/OrderStatusEnum'
import {getProperty} from '../../../../helpers/ArrayHelper'
import {formatDate} from '../../../../helpers/DateHelper'
import {priceFormat} from '../../../../helpers/NumberHelper'
import {orderCancel} from '../../../../requests/orders/OrderRequest'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {IOrderModel} from '../../../models/order/OrderModel'
import {KrcTable} from '../../../organisms/tables/KrcTable'
import Totals from './Totals'

interface IOrderDetailGroupedList {
  key: number
  id: number
  name: string
  image: string
  model: string
  quantity: number
  price: number
  discount: number
  total: number
  currency: string
}

type GroupedItemType = {
  [key: string]: IOrderDetailGroupedList[]
}

const Items: React.FC<IOrderModel> = (props) => {
  const [grouped, setGrouped] = useState<GroupedItemType>({})

  const handleCancelOrder = (orderId: number, line: number) => {
    orderCancel(orderId, line).then((response) => {
      const {success, message} = response.data
      const notificationType = success ? 'success' : 'error'
      const notificationMessage = success ? 'İşlem başarılı' : 'İşlem başarısız'

      notification[notificationType]({
        className: 'custom-notification',
        style: {width: 500},
        message: notificationMessage,
        description: message,
        duration: 5,
        placement: 'top',
      })
    })
  }

  const actionButtons = (
    orderId: number,
    line: number,
    actions: {cancel?: boolean; otherAction?: boolean} = {
      cancel: false,
      otherAction: false,
    }
  ) => (
    <Menu className='order-cancel-dropdown'>
      <Menu.Item key='1' onClick={() => handleCancelOrder(orderId, line)} disabled={actions.cancel}>
        <CloseCircleOutlined className='mr-2' /> İptal Talebi
      </Menu.Item>
    </Menu>
  )

  const columns = useMemo(() => {
    return [
      {
        Header: 'Ürün Bilgisi',
        accessor: 'name',
        Cell: ({row}: Cell<any>) => (
          <div className='row'>
            <div className='col ant-col-4'>
              {row.original.image && (
                <img src={row.original.image} alt={row.original.name} style={{width: '100%'}} />
              )}
            </div>
            <div className='col ant-col-20'>
              <b>{row.original.name}</b> <br />
              {row.original.model}
            </div>
          </div>
        ),
        width: '60%',
      },
      {
        Header: 'Adet',
        accessor: 'quantity',
        Cell: ({row}: Cell<any>) => `${row.original.quantity} Adet`,
        width: '13.3%',
      },
      {
        Header: 'Fiyat',
        accessor: 'price',
        Cell: ({row}: Cell<any>) => (
          <>
            {row.original.discount ? (
              <>
                <span style={{textDecoration: 'line-through'}}>
                  {priceFormat(row.original.oldPrice) + ' ' + row.original.currency}
                </span>
                <br />
              </>
            ) : null}
            {priceFormat(row.original.price) + ' ' + row.original.currency}
          </>
        ),
        width: '13.3%',
      },
      {
        Header: 'Toplam',
        accessor: 'total',
        Cell: ({row}: Cell<any>) => (
          <b>{priceFormat(row.original.total) + ' ' + row.original.currency}</b>
        ),
        width: '13.3%',
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        Cell: ({row}: Cell<any>) => (
          <Dropdown
            overlay={actionButtons(props.order.orderId, row.original.id, {
              cancel:
                row.original.cancelRequested === false && row.original.isCancelable ? false : true,
            })}
          >
            <KrcButton size='small' type='primary' icon={<CaretDownFilled />} />
          </Dropdown>
        ),
        width: '13.3%',
      },
    ]
  }, [])

  const generateKey = (item: any, order: any) => {
    const fields = [
      item.statusProps.text,
      item.statusProps.color,
      item.statusProps.code,
      item.details?.[0]?.invoiceId || '',
      item.details?.[0]?.invoiceUrl || '',
      getProperty(item.customProperties, 'InventLocationId') ?? '',
      getProperty(item.customProperties, 'CarrierName') ?? '',
      formatDate(item.shippingDateRequested),
      formatDate(item.deliveredDate),
      formatDate(item.details?.[0]?.statusDate),
    ]

    return fields.join('___')
  }

  useEffect(() => {
    const tempGrouped: GroupedItemType = {}

    props.order.orderItems.forEach((item: any, index: any) => {
      const temp = [
        {
          key: index,
          id: item.orderItemId,
          name: item.productName,
          image: item.productImage,
          model: item.productCode,
          quantity: item.quantity,
          oldPrice: item.productPrice,
          price: item.unitPrice,
          discount: item.lineDiscount,
          total: item.lineTotalPrice,
          currency: getProperty(props.order.customProperties, 'Currency') ?? '',
          cancelRequested: item.cancelRequests.length ? true : false,
          isCancelable: item.isCancelable,
        },
      ]

      // Status.
      const itemKey = generateKey(item, props.order)

      if (!tempGrouped[itemKey]) {
        tempGrouped[itemKey] = []
      }

      tempGrouped[itemKey].push(...temp)

      return tempGrouped
    })

    setGrouped(tempGrouped)
  }, [props.order])

  return (
    <>
      {Object.keys(grouped).length > 0
        ? Object.keys(grouped).map((group: any, index) => {
            const fields = group.split('___')

            let title = fields[0]
            let color = fields[1]
            let code = parseInt(fields[2])

            let invoiceId = fields[3]
            let invoiceUrl = fields[4]

            let warehouse = fields[5]
            let carrier = fields[6]
            let estimatedDate = fields[7]
            let deliveredDate = fields[8]
            let statusDate = fields[9]

            let canceled = false

            if (code === CANCELED) {
              warehouse = 'Çıkış Deposu'
              carrier = ''
              invoiceId = ''
              canceled = true
            }

            return (
              <div key={group}>
                <div key={group} className='block-wrapper radius item-table'>
                  <div className='row'>
                    <div className='col'>
                      <p style={{color: color, fontWeight: 'bold'}}>{title}</p>
                      {statusDate}
                      <br />
                      <b>
                        <a
                          href={invoiceUrl}
                          target='_blank'
                          rel='noreferrer'
                          style={{color: 'black', textDecoration: 'underline'}}
                        >
                          {invoiceId}
                        </a>
                      </b>
                    </div>
                    <div className='col'>
                      <p style={{fontWeight: 'bold'}}>{warehouse}</p>
                    </div>
                    <div className='col'>
                      <p style={{fontWeight: 'bold'}}>Kargo Şirketi</p>
                      {carrier}
                    </div>
                    <div className='col'>
                      <p style={{fontWeight: 'bold'}}>
                        {deliveredDate || (!deliveredDate && code === COMPLETED)
                          ? 'Teslim Edildi'
                          : 'Tahmini Kargo Tarihi'}
                      </p>
                      {!canceled &&
                        (deliveredDate || (!deliveredDate && code === COMPLETED)
                          ? deliveredDate
                          : estimatedDate)}
                    </div>
                  </div>
                  <KrcTable
                    columns={columns}
                    data={grouped[group] ? grouped[group] : []}
                    cellClassName='font-weight-normal'
                    stylesProps={{style: {margin: 0, padding: 0}, className: ['order-block-table']}}
                  />
                </div>
                <br />
              </div>
            )
          })
        : null}

      <Totals order={props.order} />
    </>
  )
}

export default Items
