import {captureMessage} from '@sentry/react'
import {Drawer, Image, Space, message} from 'antd'
import {Field, Form, Formik} from 'formik'
import {OptionData} from 'rc-select/lib/interface'
import React, {FC, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'

import {DrawerEnum} from '../../../enums/DrawerEnum'
import {REVIEW_STATUS_ENUM} from '../../../enums/ReviewEnum'
import {errorMessage} from '../../../helpers/ErrorHelper'
import {createReviewsRequest, updateReviewRequest} from '../../../requests/reviews/ReviewRequest'
import {FileManagerModal} from '../../../service/FileManager/FileManagerModal'
import {
  createReviewType,
  initialReviewValueType,
  reviewDrawerDataType,
} from '../../models/reviews/ReviewModel'
import {CustomerFilter} from '../../molecules/filters/CustomerFilter'
import {ProductFilter} from '../../molecules/filters/ProductFilter'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'

const schema = Yup.object({
  customer: Yup.object().nullable().required('Ekleyen adı zorunlu alan'),
  product_name: Yup.string().required('Ürün zorunlu alan'),
  review: Yup.string().required('Yorum zorunlu alan'),
  rating: Yup.string().required('Puan zorunlu alan'),
  status: Yup.string().required('Durum zorunlu alan'),
})

interface IReviewDrawer {
  type: DrawerEnum
  onHide: () => void
  onSubmit?: () => void
  isDrawerVisible: boolean
  data?: reviewDrawerDataType | null
}

export const ReviewDrawer: FC<IReviewDrawer> = ({
  type,
  isDrawerVisible,
  onHide,
  data,
  onSubmit,
}) => {
  const [initValues, setInitValues] = useState<initialReviewValueType>({
    customer: null,
    product_name: '',
    review: '',
    rating: '0',
    status: '0',
    images: [],
  })
  const submitRef = useRef<HTMLButtonElement | null>(null)
  const [drawerTitle, setDrawerTitle] = useState<string>('')

  const handleOnSubmit = (item: initialReviewValueType) => {
    if (type === DrawerEnum.CREATE) {
      const splitProductName = item.product_name.split(' - ')
      createReview({
        customer_id: (item.customer?.value as number) ?? 0,
        product_id: parseInt(splitProductName[0]),
        text: item.review,
        rating: parseInt(item.rating),
        status: parseInt(item.status),
        images: item.images as string[],
      })
    }

    if (type === DrawerEnum.UPDATE) {
      if (!data) {
        message.error({content: 'Yorumu güncellenmeden önce bir hata oluştu.'})
        captureMessage(`Yorum güncellenemedi  - ${initValues.customer?.label}`)

        return
      }

      const splitProductName = item.product_name.split(' - ')

      const images = item.images.filter((image) => {
        return typeof image === 'string'
      }) as string[]

      updateReview(data.review_id, {
        customer_id: (item.customer?.value as number) ?? 0,
        product_id: parseInt(splitProductName[0]),
        text: item.review,
        rating: parseInt(item.rating),
        status: parseInt(item.status),
        images: images,
      })
    }
  }

  useEffect(() => {
    if (!data) {
      return
    }

    setInitValues(data)
  }, [data])

  useEffect(() => {
    let title = type === DrawerEnum.CREATE ? 'Yorum Oluştur' : 'Yorum Güncelle'

    if (data) {
      switch (data.status) {
        case REVIEW_STATUS_ENUM.PENDING:
          title += ' (Onay Bekliyor)'
          break
        case REVIEW_STATUS_ENUM.APPROVED:
          title += ' (Onaylandı)'
          break
        case REVIEW_STATUS_ENUM.ARCHIVED:
          title += ' (Arşivlendi)'
          break
      }
    }

    setDrawerTitle(title)
  }, [])

  const createReview = (data: createReviewType) => {
    const key = 'create-review'
    message.loading({content: 'Yorum Oluşturuluyor...', key})

    createReviewsRequest(data)
      .then((res) => {
        message.success({content: 'Yorum Oluşturuldu', key, duration: 2})
        if (onSubmit) {
          onSubmit()
        }
      })
      .catch((e) => {
        errorMessage(e)
        message.error({content: 'Yorum Oluşturulurken bir hata oluştu', key})
      })
  }

  const updateReview = (reviewId: number, updateData: createReviewType) => {
    const key = 'update-review'
    message.loading({content: 'Yorum Güncelleniyor...', key})
    updateReviewRequest(reviewId, updateData)
      .then((res) => {
        message.success({content: 'Yorum Güncellendi', key, duration: 2})
        if (onSubmit) {
          onSubmit()
        }
      })
      .catch((e) => {
        errorMessage(e)
        message.error({content: 'Yorum Güncellenirken bir hata oluştu', key})
      })
  }

  return (
    <>
      <Drawer
        title={drawerTitle}
        placement='right'
        size={'large'}
        onClose={onHide}
        keyboard={false}
        maskClosable={false}
        visible={isDrawerVisible}
        extra={
          <Space>
            <button className='btn btn-secondary' onClick={onHide}>
              İptal
            </button>
            <button
              onClick={() => {
                submitRef.current?.click()
              }}
              className='btn btn-success'
              style={{marginLeft: 10}}
            >
              Kaydet
            </button>
          </Space>
        }
      >
        <Formik
          validationSchema={schema}
          initialValues={initValues}
          onSubmit={handleOnSubmit}
          validate={setInitValues}
          enableReinitialize
        >
          {() => (
            <Form className='form row' noValidate>
              <KrcFormikField
                className={'mb-2 col-md-6'}
                name={'customer'}
                labelTitle={'Ekleyen Kullanıcı'}
                required
              >
                <CustomerFilter
                  value={initValues.customer}
                  className={'select-filter-custom w-100 mt-1'}
                  placeholder={'Lütfen seçiniz'}
                  dataType={'item'}
                  dropdownStyle={{zIndex: 99999}}
                  style={{width: 400}}
                  showSearch={true}
                  onSelected={(data) => {
                    const item = (data[0] ?? null) as OptionData | null

                    if (!item) {
                      return
                    }

                    setInitValues({...initValues, customer: {label: item.label, value: item.value}})
                  }}
                ></CustomerFilter>
              </KrcFormikField>

              <KrcFormikField name={'product_name'} labelTitle={'Ürün'} required>
                <ProductFilter
                  initialName={initValues.product_name}
                  onChanged={(productName) => {
                    setInitValues({...initValues, product_name: productName})
                  }}
                  label={'withId'}
                  autoCompleteProps={{
                    placeholder: 'Ürün Adı Filtre Listesi',
                    size: 'middle',
                    className: 'mt-1',
                  }}
                />
              </KrcFormikField>

              <KrcFormikField name={'review'} labelTitle={'Yorum'} required>
                <Field as='textarea' name='review' className='form-control' rows={5} />
              </KrcFormikField>

              <KrcFormikField name={'rating'} labelTitle={'Oylama'} required>
                <div className={'col-md-6 col-sm-6 d-flex align-items-center'}>
                  <span className={'mr-2'}>
                    <b>Kötü</b>
                  </span>
                  <Field
                    className='form-label mr-2'
                    style={{width: 18, height: 18}}
                    type='radio'
                    name='rating'
                    value={'1'}
                  />
                  <Field
                    className='form-label mr-2'
                    style={{width: 18, height: 18}}
                    type='radio'
                    name='rating'
                    value={'2'}
                  />
                  <Field
                    className='form-label mr-2'
                    style={{width: 18, height: 18}}
                    type='radio'
                    name='rating'
                    value={'3'}
                  />
                  <Field
                    className='form-label mr-2'
                    style={{width: 18, height: 18}}
                    type='radio'
                    name='rating'
                    value={'4'}
                  />
                  <Field
                    className='form-label mr-2'
                    style={{width: 18, height: 18}}
                    type='radio'
                    name='rating'
                    value={'5'}
                  />
                  <span>
                    <b>İyi</b>
                  </span>
                </div>
              </KrcFormikField>

              <KrcFormikField name={'status'} labelTitle={'Durum'} required>
                <Field as='select' name='status' className='form-select'>
                  <option value={REVIEW_STATUS_ENUM.PENDING}>Onay Bekliyor</option>
                  <option value={REVIEW_STATUS_ENUM.APPROVED}>Onayla</option>
                  <option value={REVIEW_STATUS_ENUM.ARCHIVED}>Arşivle</option>
                </Field>
              </KrcFormikField>

              <button type='submit' ref={submitRef} className='d-none' style={{marginLeft: 10}} />
              <KrcFormikField name={'images'} labelTitle={'Görsel'}>
                <FileManagerModal
                  onSelected={(link, fullUrl) => {
                    if (!fullUrl) {
                      return
                    }

                    setInitValues({...initValues, images: [...initValues.images, fullUrl]})
                  }}
                  showImage={false}
                  tempUploadDir={'review/2024'}
                />
                <div
                  className={
                    'd-flex flex-column mt-2 gap-6 align-items-center h-450px overflow-auto'
                  }
                >
                  {initValues.images.length > 0 && (
                    <Image.PreviewGroup>
                      {initValues.images.map((image, key) => {
                        if (typeof image === 'string') {
                          return (
                            <Image
                              key={`image-${key}`}
                              width={300}
                              height={200}
                              src={image}
                              className={'mt-2'}
                            />
                          )
                        }

                        return (
                          <Image
                            key={`image-${key}`}
                            width={300}
                            height={200}
                            src={image.review_full_image_url}
                            className={'mt-2'}
                          />
                        )
                      })}
                    </Image.PreviewGroup>
                  )}
                </div>
              </KrcFormikField>
            </Form>
          )}
        </Formik>
      </Drawer>
    </>
  )
}
