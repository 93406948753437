import React, {FC} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {CategoryBadgeProvider} from '../../../../providers/category/badges/CategoryBadgeProvider'
import {KrcTabs} from '../../../molecules/KrcTabs'
import {AddCategoriesBadge} from './AddCategoriesBadge'
import {CategoryBadgeActivity} from './CategoryBadgeActivity'
import {DeleteCategoriesBadge} from './DeleteCategoriesBadge'

const CategoriesBadgeWrapperWithoutProvider: FC = () => {
  return (
    <>
      <PageTitle>Kategorilere Toplu Badge İşlemleri</PageTitle>
      <KrcTabs
        isActiveKey={true}
        tabsProps={{defaultActiveKey: 'bulk-add'}}
        tabProps={[
          {
            eventKey: 'bulk-add',
            title: 'Toplu Badge Ekleme',
            children: <AddCategoriesBadge />,
          },
          {
            eventKey: 'bulk-delete',
            title: 'Toplu Badge Silme',
            children: <DeleteCategoriesBadge />,
          },
          {
            eventKey: 'activity',
            title: 'Aktiviteler',
            children: <CategoryBadgeActivity />,
          },
        ]}
      />
    </>
  )
}

export const CategoriesBadgeWrapper: FC = () => {
  return (
    <>
      <CategoryBadgeProvider>
        <CategoriesBadgeWrapperWithoutProvider />
      </CategoryBadgeProvider>
    </>
  )
}
