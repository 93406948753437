import {DeleteOutlined, EditOutlined, InfoCircleOutlined} from '@ant-design/icons'
import {Alert, Empty, Input, Spin, message} from 'antd'
import {Image} from 'antd'
import {Field, Form, Formik} from 'formik'
import moment from 'moment'
import React, {FC, useContext, useEffect, useState} from 'react'

import {IBM_CAMPAIGN_SEO_URL_TYPES} from '../../../../../enums/IBMCampaignSeoUrlTypeEnum'
import {IBM_CAMPAIGN_STORES} from '../../../../../enums/IBMCampaignStoresTypeEnum'
import {GENERAL_INFO} from '../../../../../enums/IBMCampaignTabTypeEnums'
import {IBMCampaignSpecialGroupContext} from '../../../../../providers/ibm/IBMCampaignSpecialGroupProvider'
import {
  fetchIBMCampaignGeneralInformation,
  updateIBMCampaignGeneralInformation,
} from '../../../../../requests/ibm/IBMCampaignRequest'
import {fetchIBMGroupsRequest} from '../../../../../requests/ibm/IBMGroupRequest'
import {FileManagerModal} from '../../../../../service/FileManager/FileManagerModal'
import {KrcButton} from '../../../../atoms/buttons/KrcButton'
import {KrcInput} from '../../../../atoms/forms/KrcInput'
import {KrcRangePicker} from '../../../../atoms/forms/KrcRangePicker'
import {KrcRichTextEditorWithTable} from '../../../../atoms/forms/KrcRichTextEditorWithTable'
import {KrcSelect} from '../../../../atoms/forms/KrcSelect'
import {useSeoUrl} from '../../../../hooks/url/useSeoUrl'
import {useDeepLinkInformationModal} from '../../../../hooks/useDeepLinkInformationModal'
import {DeepLinkInformationModal} from '../../../../modals/DeepLinkInformationModal'
import {IBMCampaignGeneralInformationType} from '../../../../models/ibm/IBMCampaignDetail'
import {Loader} from '../../../../molecules/Loader'
import {KrcFormikField} from '../../../../molecules/forms/KrcFormikField'

interface GeneralInformationTabProps {
  activeCampaignId: number
}

const initialState = {
  campaign_id: 0,
  campaign_name: '',
  campaigns_page_title: '',
  campaign_sort: 0,
  campaign_conditions: '',
  campaign_group_ids: [],
  campaign_page_status: false,
  campaigns_page_image: '',
  campaign_seo_url_app: '',
  campaign_seo_url_web: '',
  campaign_url_alias_type: '',
  campaign_url_alias_type_id: 0,
  campaign_start_date: '',
  campaign_end_date: '',
  campaign_store_ids: [],
  cta_button_text: '',
}
export const GeneralInformationTab: FC<GeneralInformationTabProps> = ({activeCampaignId}) => {
  const DEFAULT_IMAGE_PATH = 'https://cdn.karaca.com/image/'
  const {type, typeId, handleSearch, isLoading: loadingHook} = useSeoUrl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isChanged, setIsChanged] = useState<boolean>(false)
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [campaignGeneralInformation, setCampaignGeneralInformation] =
    useState<IBMCampaignGeneralInformationType>(initialState)

  const [willBeUpdateCampaignConditions, setWillBeUpdateCampaignConditions] = useState(
    campaignGeneralInformation.campaign_conditions
  )

  const [campaignGroupIds, setCampaignGroupIds] = useState<number[]>([])
  const [IBMGroupOptions, setIBMGroupOptions] = useState<any[]>([])
  const [willBeUpdateCampaignPageImage, setWillBeUpdateCampaignPageImage] = useState<string>('')
  const [aliasTypeId, setAliasTypeId] = useState<any>(typeId)
  const [aliasType, setAliasType] = useState<any>(type)
  const [seoUrlApp, setSeoUrlApp] = useState<string>('')
  const [campaignStartDate, setCampaignStartDate] = useState<string | null | undefined>(undefined)
  const [campaignEndDate, setCampaignEndDate] = useState<string | null | undefined>(undefined)
  const [campaignStoreIds, setCampaignStoreIds] = useState<number[]>([])
  const [openWebImage, setOpenWebImage] = useState<number>(0)
  const {setPageType} = useContext(IBMCampaignSpecialGroupContext)
  const {showDeepLinkInformationModal, setShowDeepLinkInformationModal} =
    useDeepLinkInformationModal()

  useEffect(() => {
    setPageType(GENERAL_INFO)
    setAliasType(type)
    setAliasTypeId(typeId)
  }, [type, typeId, loadingHook])

  useEffect(() => {
    fetchIBMGroupsRequest({}, {page: 1, limit: 200}).then((res) => {
      const _data = res.data.data
      const _options = _data.map((item: any) => {
        return {value: item.ibm_group_id, label: item.ibm_group_title}
      })
      setIBMGroupOptions(_options)
    })
  }, [])

  useEffect(() => {
    fetchIBMCampaignGeneralInformation(activeCampaignId, 0)
      .then((res) => {
        setCampaignGeneralInformation((prev) => {
          return {
            ...prev,
            ...res.data.data,
            campaign_page_status: res.data.data.campaign_page_status.toString(),
            campaigns_page_title: res.data.data.campaigns_page_title
              ? res.data.data.campaigns_page_title
              : '',
            campaign_seo_url_web: res.data.data.campaign_seo_url_web
              ? res.data.data.campaign_seo_url_web
              : '',
            campaign_seo_url_app: res.data.data.campaign_seo_url_app
              ? res.data.data.campaign_seo_url_app
              : '',
            campaigns_page_image: res.data.data.campaigns_page_image
              ? res.data.data.campaigns_page_image
              : '',
          }
        })
        setWillBeUpdateCampaignConditions(res.data.data.campaign_conditions)
        setCampaignGroupIds(res.data.data.campaign_group_ids)
        setCampaignStoreIds(res.data.data.campaign_store_ids)
        setWillBeUpdateCampaignPageImage(res.data.data.campaigns_page_image)
        setSeoUrlApp(res.data.data.campaign_seo_url_app)
        if (res.data.data.campaign_url_alias_type) {
          setAliasType(res.data.data.campaign_url_alias_type)
        }
        if (res.data.data.campaign_url_alias_type_id) {
          setAliasTypeId(res.data.data.campaign_url_alias_type_id)
        }
        setCampaignStartDate(res.data.data.campaign_start_date)
        setCampaignEndDate(res.data.data.campaign_end_date)
      })
      .catch((err) => {
        message.error('Kampanya bilgileri getirilirken bir hata oluştu.')
        message.error(err.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [activeCampaignId])

  const handleSubmit = (values: any, {setSubmitting, resetForm}: any) => {
    let campaignStartDateForReq = ''
    if (!campaignStartDate) {
      campaignStartDateForReq = ''
    } else {
      if (campaignStartDate === campaignGeneralInformation.campaign_start_date) {
        campaignStartDateForReq = moment(campaignGeneralInformation.campaign_start_date).format(
          'YYYY-MM-DD HH:mm'
        )
      } else {
        campaignStartDateForReq = campaignStartDate ? campaignStartDate : ''
      }
    }

    let campaignEndDateForReq = ''
    if (!campaignEndDate) {
      campaignEndDateForReq = ''
    } else {
      if (campaignEndDate === campaignGeneralInformation.campaign_end_date) {
        campaignEndDateForReq = moment(campaignGeneralInformation.campaign_end_date).format(
          'YYYY-MM-DD HH:mm'
        )
      } else {
        campaignEndDateForReq = campaignEndDate ? campaignEndDate : ''
      }
    }

    const formikValues = {
      ...values,
      campaign_conditions: willBeUpdateCampaignConditions,
      campaign_group_ids: campaignGroupIds,
      campaign_url_alias_type: aliasType,
      campaign_url_alias_type_id: aliasTypeId,
      campaign_seo_url_app: seoUrlApp,
      campaigns_page_image: willBeUpdateCampaignPageImage,
      campaign_start_date: campaignStartDateForReq,
      campaign_end_date: campaignEndDateForReq,
      campaign_store_ids: campaignStoreIds,
    }

    if (!formikValues.campaigns_page_title) {
      message.error('Kampanyalar Sayfası Başlığı alanı boş bırakılamaz.')
      return
    }

    if (campaignGroupIds.length === 0) {
      message.error('En az bir kampanya grubu seçmelisiniz.')
      return
    }

    message.info('Kampanya bilgileri güncelleniyor...')
    updateIBMCampaignGeneralInformation(activeCampaignId, formikValues, 0)
      .then((res) => {
        setIsSubmit(true)
        setSubmitting(true)
        setIsChanged(false)
        message.success('Kampanya bilgileri başarıyla güncellendi.')
        setTimeout(() => {
          setIsSubmit(false)
          resetForm({values: {...formikValues}})
        }, 500)
      })
      .catch((err) => {
        message.error('Kampanya bilgileri güncellenirken bir hata oluştu.')
        message.error(err.message)
      })
      .finally(() => {
        setIsSubmit(true)
      })
  }

  return (
    <>
      {isLoading ||
      campaignGeneralInformation.campaign_name.length === 0 ||
      IBMGroupOptions.length === 0 ? (
        <Loader />
      ) : (
        <Formik initialValues={campaignGeneralInformation} onSubmit={handleSubmit}>
          {(dirty) => {
            return (
              <Form className={'form mt-6 p-4'}>
                {(dirty.dirty || isChanged) && !isSubmit ? (
                  <Alert
                    message='Değişiklik yaptığınızı fark ettik. Sağ alttan değişiklikleri kaydetmeyi unutma!'
                    type='warning'
                    showIcon
                  />
                ) : null}
                <div className={'row mt-2 '}>
                  <div className={'col-6'}>
                    <div className={'col-10'}>
                      <KrcFormikField
                        className={'mb-4'}
                        type={'text'}
                        name={'campaign_name'}
                        labelTitle={'Kampanya Adı'}
                      >
                        <Input
                          disabled
                          value={campaignGeneralInformation.campaign_name}
                          style={{borderRadius: '7px'}}
                          className={'form-control-lg mb-3'}
                        />
                      </KrcFormikField>
                    </div>
                    <div className={'col-10'}>
                      <KrcFormikField
                        className={'mb-4'}
                        type={'text'}
                        name={'campaigns_page_title'}
                        required
                        labelTitle={'Kampanyalar Sayfası Başlığı'}
                      />
                    </div>
                  </div>
                  <div className={'col-6'}>
                    <div className={'col-9'}>
                      <div className={'d-flex flex-column'}>
                        <div
                          className={'d-flex flex-column gap-2 px-6 py-2 rounded w-500px'}
                          style={{backgroundColor: '#F3F3F3'}}
                        >
                          <div className={'d-flex justify-content-between align-items-center'}>
                            <span className={'lh-0'}>
                              <b>Kampanya Sayfa Görseli</b>
                            </span>
                            <FileManagerModal
                              tempUploadDir={'/image/2024/ibm/page_image'}
                              onSelected={(link) => {
                                if (link) {
                                  setIsChanged(true)
                                }
                                setWillBeUpdateCampaignPageImage(link ? link : '')
                              }}
                              defaultImage={DEFAULT_IMAGE_PATH + willBeUpdateCampaignPageImage}
                              open={openWebImage}
                              showImage={false}
                            >
                              <KrcButton
                                type={'text'}
                                className={'custom-hover'}
                                icon={<EditOutlined />}
                                onClick={() => {
                                  setOpenWebImage((open) => open + 1)
                                }}
                              />
                              <KrcButton
                                type={'text'}
                                className={'custom-hover'}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                  setIsChanged(true)
                                  setWillBeUpdateCampaignPageImage('')
                                }}
                              />
                            </FileManagerModal>
                          </div>
                          {willBeUpdateCampaignPageImage ? (
                            <Image
                              height={140}
                              className={'rounded-2'}
                              style={{objectFit: 'contain'}}
                              src={DEFAULT_IMAGE_PATH + willBeUpdateCampaignPageImage}
                              preview={{
                                maskClassName: 'rounded-2',
                              }}
                            />
                          ) : (
                            <>
                              <Empty
                                image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                                imageStyle={{
                                  height: 60,
                                }}
                                description={<span>Kampanya Sayfa Görseli Bulunamadı.</span>}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-6'}>
                    <div className={'col-10'}>
                      <div className={'row'}>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'mb-2 z-index-9999'}
                            name={'store_ids'}
                            labelTitle={'Mağazalar'}
                          >
                            <KrcSelect
                              maxTagCount='responsive'
                              mode={'multiple'}
                              placeholder={'Seçiniz'}
                              size={'large'}
                              dropdownStyle={{zIndex: 9999}}
                              value={campaignStoreIds}
                              className={
                                'ant-selector-border custom-ant-select-selection-placeholder w-full d-block'
                              }
                              options={IBM_CAMPAIGN_STORES.map((store) => {
                                return {
                                  value: store.value,
                                  label: store.label,
                                }
                              })}
                              onChange={(storeId) => {
                                if (
                                  JSON.stringify(storeId) !==
                                  JSON.stringify(campaignGeneralInformation.campaign_store_ids)
                                ) {
                                  setIsChanged(true)
                                } else {
                                  setIsChanged(false)
                                }
                                setCampaignStoreIds(storeId)
                              }}
                              style={{width: '100%'}}
                            />
                          </KrcFormikField>
                        </div>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'mb-3'}
                            type={'number'}
                            name={'campaign_group_ids'}
                            labelTitle={'Kampanya Grubu'}
                          >
                            <KrcSelect
                              mode={'multiple'}
                              placeholder={'Kampanya Grubu'}
                              size={'large'}
                              options={IBMGroupOptions}
                              maxTagCount={'responsive'}
                              style={{width: '100%'}}
                              className={'ant-selector-border'}
                              showArrow={true}
                              defaultValue={campaignGroupIds}
                              onChange={(data) => {
                                if (!Array.isArray(data)) {
                                  return
                                }
                                if (
                                  JSON.stringify(campaignGeneralInformation.campaign_group_ids) !==
                                  JSON.stringify(data)
                                ) {
                                  setIsChanged(true)
                                } else {
                                  setIsChanged(false)
                                }
                                setCampaignGroupIds(data)
                              }}
                            />
                          </KrcFormikField>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'col-6'}>
                    <div className={'col-9'}>
                      <KrcFormikField
                        name={'date'}
                        required={false}
                        labelTitle={'K. Sayfa Görsel Tarihleri'}
                      >
                        <KrcRangePicker
                          style={{width: '200%', borderRadius: 5}}
                          showTime
                          allowClear
                          placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
                          defaultValue={
                            !campaignGeneralInformation.campaign_start_date ||
                            !campaignGeneralInformation.campaign_end_date
                              ? null
                              : [
                                  moment(campaignGeneralInformation.campaign_start_date),
                                  moment(campaignGeneralInformation.campaign_end_date),
                                ]
                          }
                          format={'DD.MM.YYYY HH:mm'}
                          onSelected={(value) => {
                            if (!value) {
                              setCampaignStartDate(null)
                              setCampaignEndDate(null)
                              return
                            }
                            setCampaignStartDate(value[0].format('YYYY-MM-DD HH:mm'))
                            setCampaignEndDate(value[1].format('YYYY-MM-DD HH:mm'))
                          }}
                        ></KrcRangePicker>
                      </KrcFormikField>
                    </div>
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-6'}>
                    <div className={'col-10'}>
                      <div className={'row'}>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'mb-3'}
                            type={'text'}
                            name={'cta_button_text'}
                            labelTitle={'CTA Buton Metni'}
                          />
                        </div>
                        <div className={'col-6'}>
                          <KrcFormikField
                            className={'mb-3'}
                            type={'number'}
                            name={'campaign_sort'}
                            labelTitle={'Kampanya Sıralama'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'col-6'}>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-3'}
                        name={'campaign_page_status'}
                        labelTitle={'Sayfa Görüntülenme Durumu'}
                      >
                        <Field as='select' name='campaign_page_status' className='form-select'>
                          <option value={undefined} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Görünür</option>
                          <option value={0}>Görünmez</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-10'}>
                    <div className={'row'}>
                      <div className={'col-3'}>
                        <KrcFormikField
                          className={'mb-3'}
                          type={'text'}
                          name={'campaign_seo_url_web'}
                          labelTitle={'SEO URL (WEB)'}
                        />
                      </div>
                      <div className={'col-6'}>
                        <KrcFormikField
                          className={'mb-3'}
                          type={'text'}
                          name={'campaign_seo_url_app'}
                          labelTitle={'SEO URL (APP)'}
                          advancedTooltipProps={{
                            status: true,
                            icon: <InfoCircleOutlined />,
                            onClick: () => setShowDeepLinkInformationModal(true),
                          }}
                        >
                          <div className={'d-flex gap-4'}>
                            <div className={'col-4'}>
                              <KrcInput
                                size={'large'}
                                className={'mb-3'}
                                type={'text'}
                                name={'campaign_seo_url_app'}
                                defaultValue={seoUrlApp}
                                value={seoUrlApp}
                                onChange={(e) => {
                                  if (e.target.value === '') {
                                    setAliasType('')
                                    setAliasTypeId(0)
                                  }

                                  if (
                                    e.target.value ===
                                    campaignGeneralInformation.campaign_seo_url_app
                                  ) {
                                    setIsChanged(false)
                                  } else {
                                    setIsChanged(true)
                                  }
                                  handleSearch(e.target.value)
                                  setSeoUrlApp(e.target.value)
                                }}
                              />
                            </div>
                            <div className={'col-3'}>
                              {loadingHook ? (
                                <div className={'bg-white px-5 pt-1 pb-2 mb-3'}>
                                  <Spin />
                                </div>
                              ) : (
                                <KrcInput
                                  size={'large'}
                                  className={'mb-3'}
                                  type={'text'}
                                  name={'campaign_seo_url_app'}
                                  defaultValue={aliasTypeId}
                                  value={aliasTypeId}
                                  onChange={(e) => {
                                    setAliasTypeId(e.target.value)
                                  }}
                                />
                              )}
                            </div>
                            <div className={'col-5'}>
                              {loadingHook ? (
                                <div className={'bg-white px-5 py-2 mb-3'}>
                                  <Spin />
                                </div>
                              ) : (
                                <KrcSelect
                                  size={'large'}
                                  placeholder={'Seçiniz'}
                                  defaultValue={aliasType}
                                  className={
                                    'ant-selector-border custom-ant-select-selection-placeholder'
                                  }
                                  value={aliasType}
                                  style={{width: '100%'}}
                                  options={[
                                    {value: '', label: 'Seçiniz', disabled: true},
                                    ...IBM_CAMPAIGN_SEO_URL_TYPES,
                                  ]}
                                  onChange={(value) => {
                                    setAliasType(value)
                                  }}
                                ></KrcSelect>
                              )}
                            </div>
                          </div>
                        </KrcFormikField>
                        <DeepLinkInformationModal
                          show={showDeepLinkInformationModal}
                          onHide={() => setShowDeepLinkInformationModal(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-11'}>
                    <div className={'form-group'}>
                      <KrcFormikField
                        className={'mb-3'}
                        type={'text'}
                        name={'campaign_conditions'}
                        labelTitle={'Kampanya Koşulları'}
                      >
                        <KrcRichTextEditorWithTable
                          className={'mt-2'}
                          height={250}
                          value={willBeUpdateCampaignConditions}
                          onChange={(value) => {
                            if (
                              campaignGeneralInformation.campaign_conditions === null &&
                              value === ''
                            ) {
                              setIsChanged(false)
                              setWillBeUpdateCampaignConditions(value)
                            } else if (
                              campaignGeneralInformation.campaign_conditions !==
                              value.replace(/(<([^>]+)>)/gi, '')
                            ) {
                              setIsChanged(true)
                              setWillBeUpdateCampaignConditions(value)
                            } else {
                              setIsChanged(false)
                              setWillBeUpdateCampaignConditions(value)
                            }
                          }}
                        />
                      </KrcFormikField>
                    </div>
                  </div>
                </div>
                <div className={'d-flex justify-content-end mt-7'}>
                  <KrcButton
                    onClick={() => {
                      dirty.handleSubmit()
                    }}
                    type={'text'}
                    size={'large'}
                    className={`custom-hover w-200px text-white button-bg-purple 0 ${
                      (dirty.dirty || isChanged) && !isSubmit ? 'border border-2 border-danger' : ''
                    } `}
                  >
                    Kaydet
                  </KrcButton>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}
