import {FileImageOutlined} from '@ant-design/icons'
import {Avatar, Tooltip} from 'antd'
import _ from 'lodash'
import React, {FC, createContext, useEffect, useState} from 'react'
import {Cell, Column} from 'react-table'

import {
  CategoryMapTableType,
  CategoryMapType,
} from '../../../components/models/categories/CategoryMapModel'
import {willBeUpdatedCategoryType} from '../../../components/models/categories/CategoryModel'
import {krcSelectOptions} from '../../../components/models/components/KrcSelectModel'
import {menuMapDataType} from '../../../components/models/menus/MenuMapModel'
import {menuDataType} from '../../../components/models/menus/MenuModel'
import {storeDataType} from '../../../components/models/stores/StoreModel'
import {
  getCategoriesMapRequest,
  updateCategoryLandingRequest,
} from '../../../requests/categories/CategoryRequest'
import {getMenuCategoryLandingsRequest, getMenusRequest} from '../../../requests/menus/MenuRequest'
import {getStoresRequest} from '../../../requests/stores/StoreRequest'

interface ICategoryLandingContext {
  menus: menuMapDataType[]
  stores: storeDataType[]
  options: krcSelectOptions[]
  currentStore?: storeDataType
  setCurrentStore: React.Dispatch<React.SetStateAction<storeDataType | undefined>>
  menuColumns: Column<any>[]
  setSelectedMenuId: React.Dispatch<React.SetStateAction<number | undefined>>
  selectCurrentStore: (storeId: number) => void
  categoryLandingMaps: {[key: string]: CategoryMapTableType[]}
  setCategoryLandingMaps: React.Dispatch<
    React.SetStateAction<{[key: string]: CategoryMapTableType[]}>
  >
  categoryColumns: Column<any>[]
  getCategoryLandings: (parentId: number) => void
  willBeUpdatedCategory?: willBeUpdatedCategoryType
  setWillBeUpdatedCategory: React.Dispatch<
    React.SetStateAction<willBeUpdatedCategoryType | undefined>
  >
  updateCategoryLandingImage: () => void
  selectedCategoryId?: number
  setSelectedCategoryId: React.Dispatch<React.SetStateAction<number | undefined>>
  isLoading?: boolean
}

export const CategoryLandingContext = createContext<ICategoryLandingContext>({
  menus: [],
  stores: [],
  options: [],
  menuColumns: [],
  setCurrentStore: () => {},
  setSelectedMenuId: () => {},
  selectCurrentStore: () => {},
  categoryLandingMaps: {0: [], 1: []},
  setCategoryLandingMaps: () => {},
  categoryColumns: [],
  getCategoryLandings: () => {},
  willBeUpdatedCategory: {category_id: 0, image: null},
  setWillBeUpdatedCategory: () => {},
  updateCategoryLandingImage: () => {},
  selectedCategoryId: 0,
  setSelectedCategoryId: () => {},
  isLoading: false,
})

export const CategoryLandingProvider: FC = ({children}) => {
  const [menus, setMenus] = useState<menuMapDataType[]>([])
  const [currentStore, setCurrentStore] = useState<storeDataType>()
  const [stores, setStores] = useState<storeDataType[]>([])
  const [options, setOptions] = useState<krcSelectOptions[]>([])
  const [selectedMenuId, setSelectedMenuId] = useState<number>()
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>()
  const [categoryLandingMaps, setCategoryLandingMaps] = useState<{
    [key: string]: CategoryMapTableType[]
  }>({0: [], 1: []})
  const [willBeUpdatedCategory, setWillBeUpdatedCategory] = useState<willBeUpdatedCategoryType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const menuColumns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'name',
      },
      {
        Header: '',
        accessor: 'count',
        maxWidth: 60,
        minWidth: 60,
        manualWidth: 60,
      },
    ],
    [menus]
  )

  const categoryColumns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'image',
        Cell: ({row}: Cell<any>) => (
          <Avatar size={52} src={row.original.image} style={{minWidth: 52}} />
        ),
        maxWidth: 52,
        manualWidth: 52,
      },
      {
        Header: '',
        accessor: 'name',
      },
      {
        Header: '',
        accessor: 'count',
        maxWidth: 60,
        minWidth: 60,
        manualWidth: 60,
      },
      {
        Header: '',
        maxWidth: 70,
        minWidth: 50,
        manualWidth: 50,
        accessor: 'col5',
        id: 'click-me-button',
        Cell: ({row}: Cell<any>) => {
          return (
            <div id={'table-action-column'} className='text-end d-flex mr-1'>
              <Tooltip title={'Görsel güncelle'}>
                <div
                  onClick={() => {
                    setWillBeUpdatedCategory({
                      category_id: row.original.id as number,
                      image: row.original.image as string,
                    })
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                >
                  <FileImageOutlined />
                </div>
              </Tooltip>
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    if (!selectedMenuId) {
      updateCategoryLandingMaps(0, [])
      updateCategoryLandingMaps(1, [])
      return
    }

    updateCategoryLandingMaps(1, [])
    getMenuCategoryLandings(selectedMenuId)
    setSelectedCategoryId(undefined)
  }, [selectedMenuId])

  useEffect(() => {
    getStores()
  }, [])

  useEffect(() => {
    getMenus(0)
  }, [currentStore])

  const getMenuCategoryLandings = (selectedMenuId: number) => {
    if (!currentStore) {
      return
    }
    setIsLoading(true)
    const storeId = currentStore?.store_id
    getMenuCategoryLandingsRequest(selectedMenuId, storeId).then((res) => {
      const data = formatCategoryLanding(res.data.data)
      updateCategoryLandingMaps(0, data)
    })
    setIsLoading(false)
  }

  const getCategoryLandings = (parentId: number) => {
    setIsLoading(true)
    getCategoriesMapRequest(parentId, undefined, currentStore?.store_id).then((res) => {
      const data = formatCategoryLanding(res.data.data)
      updateCategoryLandingMaps(1, data)
    })
    setIsLoading(false)
  }

  const formatCategoryLanding = (categories: CategoryMapType[]): CategoryMapTableType[] => {
    return categories.map((item: CategoryMapType) => {
      return {
        id: item.category_id,
        name: item.minimal_description?.name ?? item.description?.name,
        count: item.relation_count,
        image: item.category_landing?.image,
      }
    }) as CategoryMapTableType[]
  }

  const updateCategoryLandingMaps = (deep: string | number, data: CategoryMapTableType[]) => {
    setIsLoading(true)
    setCategoryLandingMaps((categoryLandingMap) => {
      return {...categoryLandingMap, [deep]: data}
    })
    setIsLoading(false)
  }

  const selectCurrentStore = (storeId: number) => {
    const store = _.find(stores, (store) => store.store_id === storeId)
    if (!store) {
      return
    }

    setCurrentStore(store)
  }

  const getStores = () => {
    getStoresRequest(['mainMenu'], ['menus']).then((res) => {
      const results = res.data.data.map((data: storeDataType) => {
        return {value: data.store_id, label: data.name, count: data.menus_count}
      })

      setCurrentStore(res.data.data[1])
      // updateGroupTitle(res.data.data[0]?.main_menu?.title ?? '', 0)
      setStores(res.data.data)
      setOptions(results)
    })
  }

  const getMenus = (deep: number) => {
    if (!currentStore?.store_id) {
      return
    }
    setIsLoading(true)
    getMenusRequest(currentStore?.store_id, undefined, ['subMenus']).then((res) => {
      const results = res.data.data
        .map((data: menuDataType) => {
          return {
            id: data.menu_id,
            name: data.title,
            count: data.sub_categories_count ?? 0,
            position: data.position,
            show_top_menu: data.show_top_menu,
            deep,
            data,
          } as menuMapDataType
        })
        .filter((menuData: menuMapDataType) => menuData.position !== 'root')
      updateCategoryLandingMaps(0, [])
      updateCategoryLandingMaps(1, [])
      setMenus(results)
      setIsLoading(false)
    })
  }

  const updateCategoryLandingImage = () => {
    if (!willBeUpdatedCategory) {
      return
    }

    const data = {
      store_id: currentStore?.store_id,
      image: willBeUpdatedCategory?.image,
    }

    updateCategoryLandingRequest(willBeUpdatedCategory?.category_id, data).then(() => {
      selectedCategoryId && getCategoryLandings(selectedCategoryId)
      selectedMenuId && getMenuCategoryLandings(selectedMenuId)
      setWillBeUpdatedCategory(undefined)
    })
  }

  return (
    <>
      <CategoryLandingContext.Provider
        value={{
          options,
          stores,
          currentStore,
          menus,
          setCurrentStore,
          menuColumns,
          setSelectedMenuId,
          selectCurrentStore,
          categoryColumns,
          categoryLandingMaps,
          setCategoryLandingMaps,
          getCategoryLandings,
          willBeUpdatedCategory,
          setWillBeUpdatedCategory,
          updateCategoryLandingImage,
          selectedCategoryId,
          setSelectedCategoryId,
          isLoading,
        }}
      >
        {children}
      </CategoryLandingContext.Provider>
    </>
  )
}
