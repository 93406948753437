import Title from 'antd/lib/typography/Title'
import React, {FC} from 'react'

import {ITableHeader} from '../../models/components/TableHeaderModel'

export const TableHeader: FC<ITableHeader> = ({children, title, subTitle, selectedItemCount}) => {
  return (
    <>
      <div className={'d-flex align-items-center justify-content-between'}>
        <div className={'d-flex align-items-center'}>
          <Title level={4}>{title}</Title>
          <span className='h-20px border-gray-200 border-start ms-3 mx-2 mb-2'>{subTitle}</span>
          {selectedItemCount !== undefined && (
            <span className={'mb-2 ml-2 text-muted'}>Seçili ürün sayısı ({selectedItemCount})</span>
          )}
        </div>
        {children}
      </div>
    </>
  )
}
