import {Drawer, Space} from 'antd'
import {Form, Formik} from 'formik'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'

import {GameCardContext} from '../../../../providers/games/GameCardProvider'
import {FileManagerModal} from '../../../../service/FileManager/FileManagerModal'
import {gameCardInitialValueType} from '../../../models/games/GameCardModel'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

const schema = Yup.object({
  game_card_title: Yup.string().required('Oyun Kartı Başlığı/Açıklaması zorunlu alan'),
  game_card_deeplink: Yup.string().required('Oyun Kartı Deeplink zorunlu alan'),
})

const _initialValue = {
  game_card_title: '',
  game_card_deeplink: '',
} as gameCardInitialValueType

export const CreateGameCardDrawer: FC = () => {
  const [initialValue, setInitialValue] = useState<gameCardInitialValueType>(_initialValue)
  const submitRef = useRef<HTMLButtonElement | null>(null)
  const [image, setImage] = useState<string | null>()

  const {gameCardDrawerVisible, setGameCardDrawerVisible, createGameCard} =
    useContext(GameCardContext)

  useEffect(() => {
    setInitialValue(_initialValue)
  }, [])

  const handleOnHide = () => {
    setGameCardDrawerVisible(false)
    setInitialValue(_initialValue)
  }

  const handleOnSubmit = (value: any) => {
    const data = {
      ...value,
      game_card_image: image,
    }

    createGameCard(data)
  }

  return (
    <>
      <Drawer
        title={'Karaca Oyun Kartı'}
        placement='right'
        size={'large'}
        onClose={handleOnHide}
        keyboard={false}
        maskClosable={false}
        visible={gameCardDrawerVisible}
        extra={
          <Space>
            <button
              onClick={() => {
                submitRef.current?.click()
              }}
              className='btn btn-success'
              style={{marginLeft: 10}}
            >
              Kaydet
            </button>
          </Space>
        }
      >
        <Formik
          validationSchema={schema}
          initialValues={initialValue}
          onSubmit={handleOnSubmit}
          enableReinitialize
        >
          {() => (
            <Form className='form row' noValidate>
              <KrcFormikField
                name={'game_card_title'}
                labelTitle={'Oyun Kartı Başlığı/Açıklaması'}
                required
              />

              <KrcFormikField
                name={'game_card_deeplink'}
                labelTitle={'Oyun Kartı Deeplink'}
                className={'mb-2 col-md-4'}
                placeholder={'Örneğin: orders, register'}
                required
                tooltipProps={{
                  status: true,
                  text: 'Başına "karaca://" EKLEMEYİNİZ! Başına / koymayınız!',
                }}
              />

              <KrcFormikField
                className={'mb-2 mt-2 col-md-6'}
                name={'game_card_image'}
                labelTitle={'Oyun Kartı Görseli'}
                required
              >
                <FileManagerModal
                  onSelected={(link) => {
                    setImage(link)
                  }}
                />
              </KrcFormikField>

              <button type={'submit'} ref={submitRef} className='d-none' style={{marginLeft: 10}} />
            </Form>
          )}
        </Formik>
      </Drawer>
    </>
  )
}
