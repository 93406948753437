import {EditOutlined} from '@ant-design/icons'
import {Badge, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {createContext, useEffect, useMemo, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Cell, Column} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {KrcNumberInput} from '../../components/atoms/forms/KrcNumberInput'
import {KrcRangePicker} from '../../components/atoms/forms/KrcRangePicker'
import {KrcSelect} from '../../components/atoms/forms/KrcSelect'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {
  IBmCampaignListType,
  IBmCampaignSortListType,
} from '../../components/models/ibm/IBMCampaignModel'
import {removeEmptyItemInObject} from '../../helpers/ObjectHelper'
import {storeIBMCampaign, updateIBMCampaignSort} from '../../requests/ibm/IBMCampaignRequest'
import {
  fetchIBMGroupsRequest,
  getIBMGroupCampaignsRequest,
  getIBMGroupInformationByIdRequest,
} from '../../requests/ibm/IBMGroupRequest'
import {requestDateFormat, viewDateFormat} from '../../service/DateService'
import {initialMetaData} from '../tables/ProductSelectionProvider'

interface IIBMCampaignContext {
  isLoading: boolean
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  filter: any
  columns: Column<any>[]
  sortColumns: Column<any>[]
  setFilter: React.Dispatch<React.SetStateAction<any>>
  handleOnFilter: (data: any, key: string | number) => void
  selectedGroupId: number
  setSelectedGroupId: React.Dispatch<React.SetStateAction<number>>
  getIBMCampaigns: (page: null | number, campaignGroupId: number) => void
  IBMCampaigns: IBmCampaignListType[]
  getAllIBMGroups: () => void
  IBMGroupOptions: any[]
  isShowSortingCampaignModal: boolean
  setIsShowSortingCampaignModal: React.Dispatch<React.SetStateAction<boolean>>
  IBMSortedCampaigns: IBmCampaignSortListType[]
  setIBMSortedCampaigns: React.Dispatch<React.SetStateAction<IBmCampaignSortListType[]>>
  handleOnSaveIBMCampaignSorting: () => void
  isShowCreateCampaignDrawer: boolean
  setIsShowCreateCampaignDrawer: React.Dispatch<React.SetStateAction<boolean>>
  storeIBMCampaigns: (data: any) => void
  selectedGroupTitle: string
  setSelectedGroupTitle: React.Dispatch<React.SetStateAction<string>>
  getIBMGroupInformationById: (id: number) => void
  isDataEmptyAfterFilter: boolean
  setIsDataEmptyAfterFilter: React.Dispatch<React.SetStateAction<boolean>>
}

export const IBMCampaignContext = createContext<IIBMCampaignContext>({
  isLoading: false,
  meta: initialMetaData,
  setMeta: () => {},
  filter: {},
  setFilter: () => {},
  handleOnFilter: () => {},
  columns: [],
  sortColumns: [],
  selectedGroupId: 0,
  setSelectedGroupId: () => {},
  getIBMCampaigns: () => {},
  IBMCampaigns: [],
  getAllIBMGroups: () => {},
  IBMGroupOptions: [],
  isShowSortingCampaignModal: false,
  setIsShowSortingCampaignModal: () => {},
  IBMSortedCampaigns: [],
  setIBMSortedCampaigns: () => {},
  handleOnSaveIBMCampaignSorting: () => {},
  isShowCreateCampaignDrawer: false,
  setIsShowCreateCampaignDrawer: () => {},
  storeIBMCampaigns: () => {},
  selectedGroupTitle: '',
  setSelectedGroupTitle: () => {},
  getIBMGroupInformationById: () => {},
  isDataEmptyAfterFilter: false,
  setIsDataEmptyAfterFilter: () => {},
})

export const IBMCampaignProvider = ({children}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const initialRef = useRef(0)
  const [IBMCampaigns, setIBMCampaignData] = useState<IBmCampaignListType[]>([])
  const [filter, setFilter] = useState<any>({})
  const [selectedGroupId, setSelectedGroupId] = useState<number>(0)
  const [IBMGroupOptions, setIBMGroupOptions] = useState<any[]>([])
  const [isShowSortingCampaignModal, setIsShowSortingCampaignModal] = useState<boolean>(false)
  const [IBMSortedCampaigns, setIBMSortedCampaigns] = useState<IBmCampaignSortListType[]>([])
  const [isShowCreateCampaignDrawer, setIsShowCreateCampaignDrawer] = useState<boolean>(false)
  const [selectedGroupTitle, setSelectedGroupTitle] = useState<string>('')
  const [isDataEmptyAfterFilter, setIsDataEmptyAfterFilter] = useState<boolean>(false)

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'campaign_id',
        manualWidth: 90,
        maxWidth: 90,
        Filter: () => {
          return (
            <KrcNumberInput
              className={'w-100'}
              min={0}
              placeholder={'ID'}
              onChange={(data) => {
                handleOnFilter(data, 'campaign_id')
              }}
            />
          )
        },
      },
      {
        Header: 'Kampanya Başlığı',
        accessor: 'campaign_title',
        manualWidth: 230,
        maxWidth: 230,
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'date_start',
        manualWidth: 180,
        maxWidth: 180,
        Cell: ({row}: Cell<any>) => {
          return (
            <>{row.original.date_start && <div>{viewDateFormat(row.original.date_start)}</div>}</>
          )
        },
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                style={{width: '100%', borderRadius: 5}}
                allowClear={true}
                onCalendarChange={(data) => {
                  let startDate = requestDateFormat(data ? data[0] : null, 'YYYY-MM-DD HH:mm')
                  let endDate = requestDateFormat(data ? data[1] : null, 'YYYY-MM-DD HH:mm')
                  let value: (string | null)[] | undefined = undefined
                  if (startDate || endDate) {
                    value = [startDate, endDate]
                  }
                  if (!startDate || !endDate) {
                    handleOnFilter(null, 'date_start')
                    return
                  }
                  if (value) {
                    handleOnFilter(value, 'date_start')
                  }
                }}
                size={'middle'}
                showTime
                format={'DD.MM.YYYY HH:mm'}
              />
            </>
          )
        },
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'date_end',
        manualWidth: 180,
        maxWidth: 180,
        Cell: ({row}: Cell<any>) => {
          return <>{row.original.date_end && <div>{viewDateFormat(row.original.date_end)}</div>}</>
        },
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                style={{width: '100%', borderRadius: 5}}
                allowClear={true}
                onCalendarChange={(data) => {
                  let startDate = requestDateFormat(data ? data[0] : null, 'YYYY-MM-DD HH:mm')
                  let endDate = requestDateFormat(data ? data[1] : null, 'YYYY-MM-DD HH:mm')
                  let value: (string | null)[] | undefined = undefined
                  if (startDate || endDate) {
                    value = [startDate, endDate]
                  }
                  if (!startDate || !endDate) {
                    handleOnFilter(null, 'date_end')
                    return
                  }
                  if (value) {
                    handleOnFilter(value, 'date_end')
                  }
                }}
                size={'middle'}
                showTime
                format={'DD.MM.YYYY HH:mm'}
              />
            </>
          )
        },
      },
      {
        Header: 'K. Sayfası Görüntülenme Durumu',
        accessor: 'campaign_page_status',
        manualWidth: 170,
        maxWidth: 170,
        Cell: ({row}: any) => {
          return (
            <div>
              {row.original.campaign_page_status ? (
                <div>
                  Görünür <Badge className={'ml-2'} color='green' />
                </div>
              ) : (
                <div>
                  Görünmez <Badge className={'ml-2'} color='red' />
                </div>
              )}
            </div>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Seçiniz'}
              options={[
                {value: 1, label: 'Görünür'},
                {value: 2, label: 'Görünmez'},
              ]}
              maxTagCount={'responsive'}
              className={'ant-selector-border'}
              style={{width: '100%'}}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }
                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'campaign_page_status')
              }}
            />
          )
        },
      },
      {
        Header: 'Kampanya Durumu',
        accessor: 'campaign_status',
        manualWidth: 120,
        maxWidth: 120,
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.campaign_status ? (
                <div>
                  Aktif <Badge className={'ml-2'} color='green' />
                </div>
              ) : (
                <div>
                  Pasif <Badge className={'ml-2'} color='red' />
                </div>
              )}
            </>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Durum'}
              options={[
                {value: 1, label: 'Aktif'},
                {value: 2, label: 'Pasif'},
              ]}
              maxTagCount={'responsive'}
              style={{width: '100%'}}
              className={'ant-selector-border'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }
                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'campaign_status')
              }}
            />
          )
        },
      },
      {
        Header: 'Sıra',
        accessor: 'campaign_sort',
        manualWidth: 80,
        maxWidth: 80,
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        manualWidth: 70,
        maxWidth: 70,
        Filter: <KrcEmptyFilter />,
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row justify-content-around'>
              <Tooltip title={'Düzenle'}>
                <Link
                  to={`/ibm/campaign/${row.original.campaign_id}`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                  style={{color: 'unset'}}
                >
                  <EditOutlined />
                </Link>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    []
  )
  const sortColumns = useMemo(
    () => [
      {
        Header: 'Kampanya ID',
        accessor: 'campaign_id',
      },
      {
        Header: 'Kampanya Adı',
        accessor: 'campaign_name',
      },
      {
        Header: 'Kampanya Başlığı',
        accessor: 'campaign_title',
      },
    ],
    []
  )

  useEffect(() => {
    if (!setMeta) {
      return
    }
    setMeta(meta)
  }, [meta.to, meta.from, meta.last_page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (!setMeta) {
      return
    }
    setMeta(meta)
    if (selectedGroupId) {
      getIBMCampaigns(null, selectedGroupId)
    }
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }
    if (selectedGroupId) {
      getIBMCampaigns(null, selectedGroupId)
    }
  }, [meta.limit])

  useEffect(() => {
    initialRef.current = 1
  }, [selectedGroupId])

  useEffect(() => {
    if (!meta) {
      return
    }
    setMeta(meta)
  }, [meta])

  useEffect(() => {
    if (!IBMCampaigns) {
      return
    }
    setIBMCampaignData(IBMCampaigns)
  }, [IBMCampaigns, selectedGroupId])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    if (selectedGroupId) {
      getIBMCampaigns(null, selectedGroupId)
    }
  }, [filter])

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }
    return debounce(loadTableFilter, 250)
  }, [])

  const getAllIBMGroups = () => {
    fetchIBMGroupsRequest({}, {page: 1, limit: 10}, 1).then((response) => {
      const _data = response.data.data
      setIBMGroupOptions(
        _data.map((item: any) => {
          return {value: item.ibm_group_id, label: item.ibm_group_id}
        })
      )
    })
  }

  const getIBMGroupInformationById = (id: number) => {
    getIBMGroupInformationByIdRequest(id)
      .then((response) => {
        setSelectedGroupTitle(response.data.data.ibm_group_title)
      })
      .catch((err) => {
        message.error('IBM Grup bilgileri getirilirken bir hata oluştu.')
        message.error(err.response.data.message)
      })
  }

  const getIBMCampaigns = (page: null | number = null, campaignGroupId: number) => {
    const _filter = removeEmptyItemInObject(filter)
    setIsLoading(true)
    getIBMGroupCampaignsRequest(_filter, meta, campaignGroupId)
      .then((response) => {
        const _meta = response.data.meta
        setMeta({limit: _meta.per_page, page: _meta.current_page, ..._meta})
        setIBMCampaignData(response.data.data)
        setIsDataEmptyAfterFilter(response.data.data.length === 0)
      })
      .finally(() => setIsLoading(false))
  }

  const handleOnSaveIBMCampaignSorting = () => {
    let sortedCampaignIds = IBMSortedCampaigns.map((item) => item.campaign_id)
    message.info('Kampanyalar sıralanıyor... Bu işlem biraz uzun sürebilir. Lütfe bekleyiniz.')
    updateIBMCampaignSort(Number(selectedGroupId), sortedCampaignIds)
      .then((res) => {
        message.success('Kampanyalar sıralandı.')
      })
      .catch((err) => {
        message.error('Kampanyalar sıralanırken bir hata oluştu.')
        message.error(err.response.data.message)
      })
      .finally(() => {
        getIBMCampaigns(null, selectedGroupId)
        setIsShowSortingCampaignModal(false)
      })
  }

  const storeIBMCampaigns = (data: any) => {
    message.info('Kampanya oluşturuluyor... Lütfen bekleyiniz.')
    storeIBMCampaign(selectedGroupId, data)
      .then((res) => {
        message.success('Kampanya oluşturuldu.')
        getIBMCampaigns(null, selectedGroupId)
        setIsShowCreateCampaignDrawer(false)
      })
      .catch((err) => {
        message.error('Kampanya oluşturulurken bir hata oluştu.')
        message.error(err.response.data.message)
      })
  }

  return (
    <>
      <IBMCampaignContext.Provider
        value={{
          isLoading,
          meta,
          setMeta,
          filter,
          columns,
          setFilter,
          handleOnFilter,
          selectedGroupId,
          setSelectedGroupId,
          getIBMCampaigns,
          IBMCampaigns,
          getAllIBMGroups,
          IBMGroupOptions,
          isShowSortingCampaignModal,
          setIsShowSortingCampaignModal,
          sortColumns,
          IBMSortedCampaigns,
          setIBMSortedCampaigns,
          handleOnSaveIBMCampaignSorting,
          isShowCreateCampaignDrawer,
          setIsShowCreateCampaignDrawer,
          storeIBMCampaigns,
          selectedGroupTitle,
          setSelectedGroupTitle,
          getIBMGroupInformationById,
          isDataEmptyAfterFilter,
          setIsDataEmptyAfterFilter,
        }}
      >
        {children}
      </IBMCampaignContext.Provider>
    </>
  )
}
