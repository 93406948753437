import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {IBMContext, IBMProvider} from '../../../../providers/ibm/IBMProvider'
import {
  getIBMGroupExportFrontendUrl,
  getIBMGroupsAndCampaignsExportFrontendUrl,
} from '../../../../requests/ibm/IBMGroupRequest'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {CreateIBMGroupModal} from '../../../modals/ibm/group/CreateIBMGroupModal'
import {UpdateIBMGroupModal} from '../../../modals/ibm/group/UpdateIBMGroupModal'
import {IBMGroupSortModal} from '../../../modals/ibm/sort/IBMGroupSortModal'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {IBMGroupList} from './IBMGroupList'

const IBMGroupListWrapperWithoutProvider: FC = () => {
  const {
    showUpdateModal,
    setShowUpdateModal,
    showCreateModal,
    setShowCreateModal,
    isShowSortingGroupModal,
    setIsShowSortingGroupModal,
  } = useContext(IBMContext)
  return (
    <>
      <Toolbar
        rightField={
          <div className='d-flex align-items-center py-1'>
            <a
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-3'
              href={getIBMGroupsAndCampaignsExportFrontendUrl()}
              target={'_blank'}
              rel='noreferrer'
            >
              Grupları & Kampanayları Dışarı Aktar
            </a>
            <a
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-3'
              href={getIBMGroupExportFrontendUrl()}
              target={'_blank'}
              rel='noreferrer'
            >
              Grupları Dışarı Aktar
            </a>
            <KrcButton
              className={'me-4'}
              type={'default'}
              onClick={(e) => {
                setIsShowSortingGroupModal(true)
              }}
            >
              Grupları Sırala
            </KrcButton>
            <KrcButton
              size={'middle'}
              onClick={() => {
                setShowCreateModal(true)
              }}
              id='kt_toolbar_primary_button'
            >
              {'IBM Grup Oluştur'}
            </KrcButton>
          </div>
        }
      ></Toolbar>
      <PageTitle>IBM Kampanyaları - Grup Listesi</PageTitle>
      <IBMGroupList />
      {isShowSortingGroupModal && (
        <IBMGroupSortModal onHide={() => setIsShowSortingGroupModal(false)} />
      )}
      {showUpdateModal && <UpdateIBMGroupModal onHide={() => setShowUpdateModal(false)} />}
      {showCreateModal && <CreateIBMGroupModal onHide={() => setShowCreateModal(false)} />}
    </>
  )
}

export const IBMGroupListWrapper: FC = () => {
  return (
    <>
      <IBMProvider>
        <IBMGroupListWrapperWithoutProvider />
      </IBMProvider>
    </>
  )
}
