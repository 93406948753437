import React, {FC} from 'react'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {PassiveProductProvider} from '../../../../../providers/PassiveProductProvider'
import {KrcTabs} from '../../../../molecules/KrcTabs'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'
import {AddProductsToPassiveProductsWrapper} from './AddProductsToPassiveProducts'
import {PassiveProductActivity} from './PassiveProductActivity'

const ProductBody: FC = () => {
  return (
    <KrcTabs
      isActiveKey={true}
      tabsProps={{defaultActiveKey: 'home'}}
      tabProps={[
        {
          eventKey: 'home',
          title: 'Pasif Ürün Listesi',
          children: <AddProductsToPassiveProductsWrapper />,
        },
        {
          eventKey: 'passive-products-log',
          title: 'Pasif Ürün Aktiviteleri',
          children: <PassiveProductActivity />,
        },
      ]}
    />
  )
}

const PassiveProductListWrapperWithProvider: FC = () => {
  return (
    <>
      <Toolbar />
      <PageTitle>Pasif Ürün</PageTitle>
      <ProductBody />
    </>
  )
}

export const PassiveProductListWrapper: FC = () => {
  return (
    <>
      <PassiveProductProvider>
        <PassiveProductListWrapperWithProvider />
      </PassiveProductProvider>
    </>
  )
}
