import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const CAMPAIGN_AND_COUPON_URL = `${API_URL}/coupon`

export function getCouponFilterRequest(meta: paginationMetaType, filters: object = {}) {
  return axios.get(`${CAMPAIGN_AND_COUPON_URL}/`, {
    params: {
      limit: meta.limit,
      page: meta.page,
      ...filters,
    },
  })
}

export function getCouponHistoryRequest(
  id: number,
  {page, limit}: paginationMetaType = {page: 1, limit: 10}
) {
  return axios.get(`${CAMPAIGN_AND_COUPON_URL}/history/${id}`, {
    params: {
      page,
      limit,
    },
  })
}

export function showCouponRequest(id: number) {
  return axios.get(`${CAMPAIGN_AND_COUPON_URL}/${id}/`)
}

export function createCouponRequest(data: object) {
  return axios.post(`${CAMPAIGN_AND_COUPON_URL}/`, data)
}

export function updateCouponRequest(id: number, data: object) {
  return axios.put(`${CAMPAIGN_AND_COUPON_URL}/${id}/`, data)
}

export function deleteCouponRequest(id: number) {
  return axios.delete(`${CAMPAIGN_AND_COUPON_URL}/${id}/`)
}
