import {PictureOutlined} from '@ant-design/icons'
import {Avatar, Checkbox, Image} from 'antd'
import type {CheckboxChangeEvent} from 'antd/es/checkbox'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import React, {FC, useContext, useEffect} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {HIDE_ON_TOP_MENU, SHOW_ON_TOP_MENU} from '../../../enums/MenuMapEnum'
import {ModalEnum} from '../../../enums/ModalEnum'
import {MenuMapContext} from '../../../providers/tables/MenuMapProvider'
import {FileManagerModal} from '../../../service/FileManager/FileManagerModal'
import {useMenuModal} from '../../hooks/menus/useMenuModal'
import {IMenuModal} from '../../models/menus/MenuModel'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'
import {KrcModal} from '../KrcModal'

export const MenuModal: FC<IMenuModal> = ({onHide, type, menuData, deep}) => {
  const {initValues, schema, handleOnSubmit, setInitValues} = useMenuModal({
    type,
    menuData,
    deep,
  })
  const {visibleCreateModal} = useContext(MenuMapContext)
  const [isShowTopMenu, setIsShowTopMenu] = React.useState(initValues.show_top_menu === '1')

  useEffect(() => {
    if (initValues.show_top_menu === SHOW_ON_TOP_MENU) {
      setIsShowTopMenu(true)
    }
  }, [initValues])

  const handleOnHide = () => {
    onHide(deep)
  }

  const onToggleShowTopMenu = (e: CheckboxChangeEvent) => {
    setIsShowTopMenu(e.target.checked)
  }

  useEffect(() => {
    if (!visibleCreateModal) {
      handleOnHide()
    }
  }, [visibleCreateModal])

  return (
    <>
      <KrcModal
        modalProps={{
          onHide: () => {
            handleOnHide()
          },
        }}
        modalHeaderProps={{
          title: type === ModalEnum.CREATE ? 'Menü Oluştur' : 'Menü Güncelle',
          onHide: () => {
            handleOnHide()
          },
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10'>
          <Formik
            validationSchema={schema}
            initialValues={initValues}
            onSubmit={(values) => {
              values = {
                ...values,
                show_top_menu: isShowTopMenu ? SHOW_ON_TOP_MENU : HIDE_ON_TOP_MENU,
              }
              handleOnSubmit(values)
            }}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form className='form row' noValidate>
                <KrcFormikField name={'title'} labelTitle={'Menü Başlığı'} required={true} />
                <KrcFormikField
                  name={'seourl'}
                  labelTitle={'SEO URL'}
                  tooltipProps={{
                    status: true,
                    text: 'SEO URL alanı boş bırakılırsa başlıktan beslenecektir. Eğer mağazaya ait bir menüde belirtilen SEO URL mevcut ise kaydedilmeyecektir.',
                  }}
                />
                <KrcFormikField name={'link_type'} labelTitle={'Link Type'} />
                <KrcFormikField name={'link_id'} type={'number'} labelTitle={'Link ID'} />
                <KrcFormikField name={'icon'} labelTitle={'Icon'} />
                <KrcFormikField name={'sort'} type={'number'} labelTitle={'Sıralama'} />
                <KrcFormikField name={'image'} labelTitle={'Görsel'}>
                  <div
                    className={'d-flex flex-row justify-content-center gap-6 align-items-center'}
                  >
                    <div className={'d-flex justify-content-center mb-2 me-2'}>
                      {/* Burada yapılan kontrolün sebebi eski CDN formatına ait bir görsel geldiğinde bunun yerine no-image gösterilmesi amacıyla yapılmıştır. */}
                      {initValues.fullImage === '' ||
                      initValues.fullImage.includes('images/') ||
                      type === ModalEnum.CREATE ? (
                        <div className={'d-flex flex-column align-items-center'}>
                          <Avatar
                            size={80}
                            icon={<PictureOutlined />}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          />
                          {type !== ModalEnum.CREATE && (
                            <p className={'text-center mt-2 text-danger'}>
                              Eski CDN formatı olduğu için veya görsel atanmadığı için resim
                              görüntülenemiyor.
                            </p>
                          )}
                        </div>
                      ) : (
                        <Image
                          src={initValues.fullImage}
                          className={'rounded-circle'}
                          preview={{
                            maskClassName: 'rounded-circle',
                          }}
                          width={80}
                          height={80}
                        />
                      )}
                    </div>
                    <FileManagerModal
                      className={'w-50'}
                      onSelected={(link) => {
                        setInitValues((data) => {
                          return {...data, image: link ?? ''}
                        })
                      }}
                      defaultImage={initValues.fullImage}
                      tempUploadDir={'image/categoryMenu'}
                    />
                  </div>
                </KrcFormikField>
                <div className={'mb-2 col-md-6'}>
                  <div>
                    <label className='form-label'>
                      <span className={'required'}>Durum</span>
                    </label>
                    <Field
                      as='select'
                      name='status'
                      className='form-select'
                      aria-label='Select example'
                    >
                      <option value=''>Seçiniz</option>
                      <option value='1'>Aktif</option>
                      <option value='0'>Pasif</option>
                    </Field>
                    <div className='fv-plugins-message-container text-danger'>
                      <ErrorMessage name='status' />
                    </div>
                  </div>
                  <div className={'mt-4'}>
                    <Checkbox checked={isShowTopMenu} onChange={onToggleShowTopMenu}>
                      Üst Menüde Göster
                    </Checkbox>
                    {ModalEnum.UPDATE === type && (
                      <p className={'mt-2 text-danger'}>
                        Girilen SEO Url bir kategoriye ait değil ise bu alan güncellenmeyecektir.
                      </p>
                    )}
                    <div className='fv-plugins-message-container text-danger'>
                      <ErrorMessage name='status' />
                    </div>
                  </div>
                </div>

                <div className={'d-flex justify-content-end mt-7'}>
                  <div className='btn btn-secondary' onClick={handleOnHide}>
                    İptal
                  </div>
                  <button type='submit' className='btn btn-success' style={{marginLeft: 10}}>
                    Kaydet
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
