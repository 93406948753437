import React, {FC, useContext, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {Link} from 'react-router-dom'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {PAGE_MODE, PAGE_TYPE} from '../../../../../enums/CampaignTypeEnum'
import {CampaignContext, CampaignProvider} from '../../../../../providers/CampaignProvider'
import {
  getCategoryApprovalProductsExportFrontendUrl,
  getCategoryHistoryApprovalProductsExportFrontendUrl,
} from '../../../../../requests/categories/products/exports/CategoryProductExportRequest'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'
import {CampaignPendingApprovalList} from './CampaignPendingApprovalList'

const CampaignPendingApprovalWrapperWithProvider: FC = () => {
  const {pageType, selectedCampaignIds, pageMode} = useContext(CampaignContext)

  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <div className='me-4' style={{marginLeft: 'auto'}}>
            <Link
              to={
                pageType === PAGE_TYPE.HISTORY
                  ? '/categories/campaigns-waiting-approval'
                  : '/categories/campaigns-waiting-approval/history'
              }
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-2'
            >
              {pageType === PAGE_TYPE.HISTORY ? 'Güncel' : 'Geçmiş'}
            </Link>
            {pageType !== PAGE_TYPE.HISTORY ? (
              <a
                type={'button'}
                className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                href={getCategoryApprovalProductsExportFrontendUrl({
                  categoryIds: selectedCampaignIds,
                  hasMyPendingApprovalProductsRequests: pageMode === PAGE_MODE.MY_REQUESTS,
                })}
                target={'_blank'}
                rel='noreferrer'
              >
                Seçilenleri Dışarı Aktar
              </a>
            ) : (
              <a
                type={'button'}
                className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                href={getCategoryHistoryApprovalProductsExportFrontendUrl({
                  categoryIds: selectedCampaignIds,
                  hasMyHistoryApprovalProductsRequests: pageMode === PAGE_MODE.MY_REQUESTS,
                })}
                target={'_blank'}
                rel='noreferrer'
              >
                Seçilenleri Dışarı Aktar
              </a>
            )}
          </div>
        </div>
      </Toolbar>
      <PageTitle>
        {pageMode === PAGE_MODE.MY_REQUESTS
          ? pageType === PAGE_TYPE.HISTORY
            ? 'Onay Taleplerim - Geçmiş'
            : 'Onay Taleplerim'
          : pageType === PAGE_TYPE.HISTORY
          ? 'Onay Bekleyen Kampanyalar - Geçmiş'
          : 'Onay Bekleyen Kampanyalar'}
      </PageTitle>
      <CampaignPendingApprovalList />
    </>
  )
}

export const CampaignPendingApprovalListWrapper: FC = () => {
  const {user} = useSelector((state: any) => state.auth)
  const location = useLocation()

  const [pageMode, setPageMode] = React.useState<PAGE_MODE.NORMAL | PAGE_MODE.MY_REQUESTS>(
    user?.data?.can_approval_campaign ? PAGE_MODE.NORMAL : PAGE_MODE.MY_REQUESTS
  )

  useEffect(() => {
    if (user?.data?.can_approval_campaign) {
      setPageMode(PAGE_MODE.NORMAL)

      return
    }

    setPageMode(PAGE_MODE.MY_REQUESTS)
  }, [location, user])

  return (
    <CampaignProvider pageMode={pageMode}>
      <CampaignPendingApprovalWrapperWithProvider />
    </CampaignProvider>
  )
}
