import * as querystring from 'query-string'
import {useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {
  checkDealProductsExportRequest,
  exportDealProductsRequest,
} from '../../../requests/deals/DealExportRequest'
import {dealUrlType} from '../../models/deals/DealModel'

export const useDeal = () => {
  const navigate = useNavigate()
  const [downloadExcelUrl, setDownloadExcelUrl] = useState<any>(null)
  const {dealId} = useParams<dealUrlType>()

  const exportDealProducts = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: true,
    })

    exportDealProductsRequest(params, (dealId as string) ?? '')
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkDealProductsExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  const checkDealProductsExport = (fileName: string) => {
    checkDealProductsExportRequest(fileName).then((res) => {
      if (!res.data.data.is_exists) {
        return setTimeout(() => {
          return checkDealProductsExport(fileName)
        }, 5000)
      }

      setDownloadExcelUrl(res.data.data.download_excel_url)
    })
  }
  return {downloadExcelUrl, exportDealProducts}
}
