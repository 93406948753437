import {SelectProps, Spin} from 'antd'
import React, {FC, useEffect} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useCouponPrefixFilter} from '../../hooks/coupon/useCouponPrefixFilter'
import {ICouponPrefixFilter} from '../../models/campaign_and_coupon/CouponPrefixFilterModel'

const couponPrefixFilterConfig = {
  default: {
    style: {width: '100%'},
    mode: 'multiple',
    size: 'middle',
    placeholder: 'Kupon Prefix Filter',
    allowClear: false,
  },
}

export const CouponPrefixFilter: FC<ICouponPrefixFilter & SelectProps> = ({
  debounceTime = 500,
  onSelected,
  clear,
  initialLoad,
  ...props
}) => {
  const currentProps = useKrcProp({
    config: couponPrefixFilterConfig.default,
    props,
  }).getCurrentProps()
  const {fetching, handleOnChange, handleSearch, couponPrefixIds, options, selectedItems} =
    useCouponPrefixFilter({
      debounceTime,
      initialLoad,
    })

  useEffect(() => {
    if (!onSelected) {
      return
    }

    onSelected(selectedItems)
  }, [couponPrefixIds])

  return (
    <>
      <KrcSelect
        clear={clear}
        {...currentProps}
        showArrow
        filterOption={false}
        labelInValue
        onSearch={handleSearch}
        onChange={handleOnChange}
        options={options}
        notFoundContent={fetching ? <Spin size='small' /> : null}
      />
    </>
  )
}
