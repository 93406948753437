import React, {FC} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {CouponCreateForm} from './CouponCreateForm'

export const CouponCreateFormWrapper: FC = () => {
  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Kupon Oluştur</PageTitle>
      <CouponCreateForm />
    </>
  )
}
