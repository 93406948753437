import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, message, notification} from 'antd'
import {FormikValues} from 'formik'
import debounce from 'lodash/debounce'
import moment from 'moment'
import {OptionData} from 'rc-select/lib/interface'
import React, {FC, createContext, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {paginationMetaType} from '../components/models/GeneralModel'
import {
  dealDataType,
  dealFormikDataType,
  dealProductCampaignAlertMessage,
  dealProductPageType,
  dealProductTableFilterType,
  dealProductTableType,
  dealProductType,
  dealUpdateOrCreateType,
  dealUrlType,
  willBeAddedDealDateRangeType,
} from '../components/models/deals/DealModel'
import {productSelectionItemsType} from '../components/models/products/ProductSelectionModel'
import {KrcWarningNotification} from '../components/molecules/KrcWarningNotification'
import {DEAL_PRODUCT_ACTIVE} from '../enums/DealProductEnum'
import {errorMessage} from '../helpers/ErrorHelper'
import {objectIsEmpty} from '../helpers/ObjectHelper'
import {createDealRequest, getDealRequest, updateDealRequest} from '../requests/deals/DealRequest'
import {
  createDealProductRequest,
  dealProductUpdateSortRequest,
  deleteDealProductRequest,
  getDealProductRequest,
  removeDealProductApprovalCampaignRequest,
  updateDealProductRequest,
} from '../requests/deals/products/DealProductRequest'
import {initialMetaData} from './tables/ProductSelectionProvider'

const {confirm} = Modal

const initialDealData = {
  name: '',
  meta_description: '',
  meta_keyword: '',
  keyword: '',
  redirect: '',
  image: '',
  bg_top: '',
  top_padding: '',
  bg_color: '',
  status: '',
  recommend: false,
  recommend_banner_image: '',
} as dealFormikDataType

interface IDealContext {
  dealProducts: dealProductTableType[]
  deal?: dealDataType
  updateOrCreateData: dealUpdateOrCreateType
  isValid: boolean
  updateIsValid: (data: boolean) => void
  onChangeValidate: (values: FormikValues) => void
  createDeal: () => void
  updateDeal: () => void
  getDeal: (dealId: number | string) => void
  addDealProduct: () => void
  getDealProducts: (pageType: dealProductPageType) => void
  updateWillBeAddedDealProductIds: (data: productSelectionItemsType[]) => void
  dealId?: string
  clearText: number
  visibleDealProductModal: boolean
  setVisibleDealProductModal: (data: boolean) => void
  willBeUpdatedProduct?: any
  setWillBeUpdatedProduct?: (data: any) => void
  updateDealProduct: (data: any) => void
  removeDealProduct: (data: number) => void
  setSortedDealProductIds: (data: number[]) => void
  updateDealProductSort: () => void
  sortedDealProductIds: number[]
  visibleAddProductsDrawer: boolean
  setVisibleAddProductsDrawer: (data: boolean) => void
  willBeAddedDealDateRange: willBeAddedDealDateRangeType
  setWillBeAddedDealDateRange: (data: willBeAddedDealDateRangeType) => void
  willBeAddedDealProductBadge: number[]
  setWillBeAddedDealProductBadge: (data: number[]) => void
  willBeAddedDealProductCampaign: number[]
  setWillBeAddedDealProductCampaign: (data: number[]) => void
  campaignAlertMessage: dealProductCampaignAlertMessage[]
  removeDealProductApprovalCampaign: (
    dealProductId: number,
    productToCategoryApprovalId: number
  ) => void
  dealDetailSubmitRef: any
  imageURL: string | null
  recommendBannerImageURL: string | null
  setImageURL: (data: string | null) => void
  setRecommendBannerImageURL: (data: string | null) => void
  meta: paginationMetaType
  updateMeta: (data: object) => void
  pageType: dealProductPageType
  setPageType: any
  filter: dealProductTableFilterType
  setFilter: any
  updateTableFilter: any
}

export const DealContext = createContext<IDealContext>({
  clearText: 0,
  visibleDealProductModal: false,
  dealProducts: [],
  isValid: false,
  updateOrCreateData: {deal: initialDealData},
  sortedDealProductIds: [],
  visibleAddProductsDrawer: false,
  willBeAddedDealDateRange: {date_start: null, date_end: null},
  willBeAddedDealProductBadge: [],
  willBeAddedDealProductCampaign: [],
  campaignAlertMessage: [],
  setWillBeAddedDealProductBadge: () => {},
  setWillBeAddedDealDateRange: () => {},
  setVisibleAddProductsDrawer: () => {},
  updateIsValid: () => {},
  onChangeValidate: () => {},
  createDeal: () => {},
  updateDeal: () => {},
  getDeal: () => {},
  addDealProduct: () => {},
  updateWillBeAddedDealProductIds: () => {},
  getDealProducts: () => {},
  setVisibleDealProductModal: () => {},
  updateDealProduct: () => {},
  removeDealProduct: () => {},
  setSortedDealProductIds: () => {},
  updateDealProductSort: () => {},
  setWillBeAddedDealProductCampaign: () => {},
  removeDealProductApprovalCampaign: () => {},
  dealDetailSubmitRef: null,
  imageURL: '',
  recommendBannerImageURL: '',
  setImageURL: (data: string | null) => {},
  setRecommendBannerImageURL: (data: string | null) => {},
  meta: initialMetaData,
  updateMeta: () => {},
  pageType: DEAL_PRODUCT_ACTIVE,
  setPageType: () => {},
  filter: {},
  setFilter: () => {},
  updateTableFilter: () => {},
})

export const DealProvider: FC = ({children}) => {
  const navigate = useNavigate()
  const {dealId} = useParams<dealUrlType>()

  const [deal, setDeal] = useState<dealDataType>()
  const [isValid, setIsValid] = useState<boolean>(false)
  const [updateOrCreateData, setUpdateOrCreateData] = useState<dealUpdateOrCreateType>({
    deal: initialDealData,
  })
  const [dealProducts, setDealProducts] = useState<dealProductTableType[]>([])
  const [visibleDealProductModal, setVisibleDealProductModal] = useState<boolean>(false)
  const [willBeUpdatedProduct, setWillBeUpdatedProduct] = useState(null)
  const [willBeAddedDealProductIds, setWillBeAddedDealProductIds] = useState<number[]>()
  const [sortedDealProductIds, setSortedDealProductIds] = useState<number[]>([])
  const [clearText, setClearText] = useState<number>(0)
  const [visibleAddProductsDrawer, setVisibleAddProductsDrawer] = useState(false)
  const [willBeAddedDealDateRange, setWillBeAddedDealDateRange] =
    useState<willBeAddedDealDateRangeType>({date_start: null, date_end: null})
  const [willBeAddedDealProductBadge, setWillBeAddedDealProductBadge] = useState<number[]>([])
  const [willBeAddedDealProductCampaign, setWillBeAddedDealProductCampaign] = useState<number[]>([])
  const [campaignAlertMessage, setCampaignAlertMessage] = useState<
    dealProductCampaignAlertMessage[]
  >([])
  const [imageURL, setImageURL] = useState<string | null>('')
  const [recommendBannerImageURL, setRecommendBannerImageURL] = useState<string | null>('')
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [pageType, setPageType] = useState<dealProductPageType>(DEAL_PRODUCT_ACTIVE)
  const [filter, setFilter] = useState<dealProductTableFilterType>({})

  const getDealProductRef = React.useRef(0)
  const getDealRef = useRef(0)
  const dealDetailSubmitRef = useRef(null)

  const updateIsValid = (data: boolean) => {
    setIsValid(data)
  }

  useEffect(() => {
    if (!dealId) {
      return
    }

    getDeal()
  }, [])

  useEffect(() => {
    setWillBeAddedDealProductIds([])
    setWillBeAddedDealProductBadge([])
    setWillBeAddedDealProductCampaign([])
  }, [visibleAddProductsDrawer])

  const updateMeta = (data: object) => {
    setMeta((meta) => ({...meta, ...data}))
  }

  const getDeal = () => {
    getDealRef.current += 1
    const ref = getDealRef.current

    getDealRequest(Number(dealId), {url_alias: ''})
      .then((res) => {
        if (getDealRef.current !== ref) {
          return
        }

        setDeal(res.data.data)
        setUpdateOrCreateData({
          deal: {
            name: res.data.data.name,
            meta_description: res.data.data.meta_description ?? '',
            meta_keyword: res.data.data.meta_keyword ?? '',
            keyword: res.data.data.url_alias?.keyword ?? '',
            redirect: res.data.data.url_alias?.redirect ?? '',
            image: res.data.data.image,
            recommend: res.data.data.recommend,
            recommend_banner_image: res.data.data.recommend_banner_image,
            recommend_banner_url: res.data.data.recommend_banner_url,
            bg_top: res.data.data.bg_top ?? '',
            top_padding: res.data.data.top_padding ?? '',
            bg_color: res.data.data.bg_color ?? '',
            status: Number(res.data.data.status).toString(),
          },
        })
        setImageURL(res.data.data.image)
        setRecommendBannerImageURL(res.data.data.recommend_banner_image)
      })
      .catch(() => {
        message.error('Deal detayları yüklenemedi. Lütfen tekrar deneyiniz.')
      })
  }

  const updateDeal = () => {
    const messageKey = 'update-deal'

    message.loading({
      content: 'Fırsat güncelleniyor.',
      key: messageKey,
    })
    setUpdateOrCreateData((data) => ({
      ...data,
      image: imageURL,
      recommend_banner_image: recommendBannerImageURL,
    }))

    const updatedData = {
      ...updateOrCreateData.deal,
      image: imageURL,
      recommend_banner_image: recommendBannerImageURL,
    }

    updateDealRequest(Number(dealId), {deal: updatedData})
      .then(() => {
        message.success({
          content: 'Fırsat başarılı bir şekilde güncellendi.',
          key: messageKey,
          duration: 2,
        })
      })
      .catch((e) => {
        errorMessage(e)
        message.destroy(messageKey)
      })
  }

  const createDeal = () => {
    const messageKey = 'create-deal'

    message.loading({
      content: 'Fırsat oluşturuluyor...',
      key: messageKey,
    })
    setUpdateOrCreateData((data) => ({
      ...data,
      image: imageURL,
      recommend_banner_image: recommendBannerImageURL,
    }))
    createDealRequest({
      ...updateOrCreateData,
      image: imageURL,
      recommend_banner_image: recommendBannerImageURL,
    })
      .then(() => {
        message.success({
          content:
            'Fırsat başarılı bir şekilde oluşturuldu. Fırsatlar sayfasına yönlendiriliyorusunuz',
          key: messageKey,
          duration: 2,
        })

        setTimeout(() => {
          navigate('/deals')
        }, 1000)
      })
      .catch((e) => {
        errorMessage(e)
        message.destroy(messageKey)
      })
  }

  const onChangeValidate = React.useMemo(() => {
    const loadChangeValidate = (values: FormikValues) => {
      setUpdateOrCreateData((data) => {
        return {...data, deal: {...data.deal, ...values}}
      })
    }

    return debounce(loadChangeValidate, 100)
  }, [])

  const getDealProducts = (pageType: dealProductPageType) => {
    if (!dealId) {
      return
    }

    setDealProducts([])
    getDealProductRef.current += 1
    const fetchId = getDealProductRef.current

    const productParams = {
      dealId: Number(dealId),
      relations: ['product', 'product.description'] as string[],
      page_type: pageType,
      meta,
      filter,
    }

    getDealProductRequest(productParams)
      .then((res) => {
        if (fetchId !== getDealProductRef.current) {
          return
        }
        setCampaignAlertMessage(res.data.campaign_alert_message)

        setDealProducts(mappingProducts(res.data.data))
        if (res.data.meta) {
          const resMeta = res.data.meta

          setMeta({limit: resMeta.per_page, page: resMeta.current_page, ...resMeta})
        }
      })
      .catch(() => {})
  }

  const updateDealProductSort = () => {
    const params = {
      dealId: Number(dealId),
      dealProductIds: sortedDealProductIds,
    }

    const messageKey = 'update-deal-product-sort'

    message.loading({
      content: 'Ürün sıralaması güncelleniyor...',
      key: messageKey,
      duration: 2,
    })

    dealProductUpdateSortRequest(params)
      .then(() => {
        message.success({
          content: 'Sıralama başarılı bir şekilde güncellendi.',
          key: messageKey,
          duration: 2,
        })

        setSortedDealProductIds([])
        getDealProducts(DEAL_PRODUCT_ACTIVE)
      })
      .catch(() => {
        message.error({
          content: 'Bir Problem Oluştu. Lütfen daha sonra tekrar deneyiniz.',
          key: messageKey,
          duration: 2,
        })
      })
  }

  const updateDealProduct = (data: any) => {
    const messageKey = 'update-deal-product'

    const willBeUpdated = {
      product_id: data.product_id,
      deal_id: Number(dealId),
      deal_product_id: data.id,
      quantity: data.quantity,
      another_link: data.anotherLink,
      another_title: data.anotherTitle,
      another_image: data.anotherImage,
      another_button_text: data.anotherButtonText,
      behave_like_boutique: data.behaveLikeBoutique,
      date_start: data.date_start,
      date_end: data.date_end,
      badge_ids: data.badgeList.map((item: OptionData) => item.value),
      campaign_ids: data.campaigns.map((item: OptionData) => item.value),
    }

    if (willBeUpdated.behave_like_boutique && !String(willBeUpdated.another_link).trim()) {
      message.error({
        content: 'Butik Yönlendirmesine çevrilen ürüne Alternatif URL tanımlanmalıdır.',
        key: messageKey,
      })

      return
    }

    message.loading({
      content: 'Fırsat ürünü güncelleniyor...',
      key: messageKey,
    })

    updateDealProductRequest(Number(dealId), data.id, willBeUpdated)
      .then((res) => {
        message.success({
          content: 'Fırsat ürünü başarılı bir şekilde güncellendi.',
          key: messageKey,
          duration: 2,
        })

        getDealProducts(pageType)

        setVisibleDealProductModal(false)
      })
      .catch((e) => {
        if (e.response?.status === 409) {
          message.error({
            content: <span dangerouslySetInnerHTML={{__html: e.response?.data.message}}></span>,
            key: messageKey,
            duration: 2,
          })
          return
        }

        message.error({
          content:
            'Bir Problem Karşılaşıldı. Eksiksiz ve doğru şekilde doldurduğunuzdan emin olun.',
          key: messageKey,
          duration: 2,
        })
      })
  }

  const addDealProduct = () => {
    if (willBeAddedDealProductIds?.length === 0 || !willBeAddedDealProductIds) {
      message.warning('Ürün ekleyebilmek için Ürün seçim alanından ürün(ler) seçiniz.')
      return
    }

    if (objectIsEmpty(willBeAddedDealDateRange)) {
      message.warning('Ürün(leri) ekleyebilmek için tarih aralığı girilmeli.')
      return
    }

    let confirmContent = (
      <>
        <div>
          Fırsat ürünü olarak eklenecek ve yayınlanacak eklemek istediğinizden emin misiniz?
        </div>
        {willBeAddedDealProductCampaign.length > 0 ? (
          <div className={'mt-2'}>
            <b>Not:</b> Kampanya(lar) seçtiğiniz görülmektedir. Kampanya(lar) onaya gönderilecek ve
            ürüne eklenen kampanya(lar) onaylanmadan ürünler üzerinde görünmeyecektir.
          </div>
        ) : null}
      </>
    )

    confirm({
      title: 'Fırsat ürünü olarak ekleme',
      icon: <ExclamationCircleOutlined />,
      content: confirmContent,
      okText: 'Tamam',
      cancelText: 'İptal',
      style: {width: 550},
      onOk() {
        const messageKey = 'add-deal-product'

        message.loading({
          content: 'Ürün fırsata ekleniyor...',
          key: messageKey,
          duration: 0,
        })

        const willBeAddedProducts = willBeAddedDealProductIds.map((productId) => {
          return {
            product_id: productId,
            date_start: willBeAddedDealDateRange.date_start,
            date_end: willBeAddedDealDateRange.date_end,
            badge_ids: willBeAddedDealProductBadge,
            campaign_ids: willBeAddedDealProductCampaign,
          }
        })
        createDealProductRequest(Number(dealId), willBeAddedProducts)
          .then((res) => {
            if (
              res.data.error_message !== null &&
              res.data.error_message &&
              res.data.error_message.length > 0
            ) {
              KrcWarningNotification({
                data: res.data.error_message,
                copyKey: ['model', 'message'],
                title: 'Fırsata eklenemeyen ürünler',
              })
            }

            if (
              res.data.stock_error_message !== null &&
              res.data.stock_error_message &&
              res.data.stock_error_message.length > 0
            ) {
              KrcWarningNotification({
                data: res.data.stock_error_message,
                copyKey: ['model', 'message'],
                title: 'Fırsata eklenemeyen ürünler',
              })
            }

            if (
              res.data.price_error_message !== null &&
              res.data.price_error_message &&
              res.data.price_error_message.length > 0
            ) {
              notification.warning({
                className: 'custom-notification',
                style: {width: 500},
                message: `Fırsata eklenemeyen ürünler`,
                description: res.data.price_error_message.map(
                  (errorMessage: {product_id: number; message: string}) => (
                    <div
                      className={'pb-2 pt-2'}
                      style={{borderBottom: '1px solid #3333'}}
                      dangerouslySetInnerHTML={{__html: errorMessage.message}}
                    ></div>
                  )
                ),
                duration: 0,
                placement: 'top',
              })
            }

            if (
              res.data.passive_error_message !== null &&
              res.data.passive_error_message &&
              res.data.passive_error_message.length > 0
            ) {
              notification.warning({
                className: 'custom-notification',
                style: {width: 500},
                message: `Fırsata eklenemeyen ürünler`,
                description: res.data.passive_error_message.map(
                  (errorMessage: {product_id: number; message: string}) => (
                    <div
                      className={'pb-2 pt-2'}
                      style={{borderBottom: '1px solid #3333'}}
                      dangerouslySetInnerHTML={{__html: errorMessage.message}}
                    ></div>
                  )
                ),
                duration: 0,
                placement: 'top',
              })
            }

            if (
              res.data.image_error_message !== null &&
              res.data.image_error_message &&
              res.data.image_error_message.length > 0
            ) {
              notification.warning({
                className: 'custom-notification',
                style: {width: 500},
                message: `Fırsata eklenemeyen ürünler`,
                description: res.data.image_error_message.map(
                  (errorMessage: {product_id: number; message: string}) => (
                    <div
                      className={'pb-2 pt-2'}
                      style={{borderBottom: '1px solid #3333'}}
                      dangerouslySetInnerHTML={{__html: errorMessage.message}}
                    ></div>
                  )
                ),
                duration: 0,
                placement: 'top',
              })
            }

            if (res.data.data.length === 0) {
              message.destroy(messageKey)
              return
            }

            message.success({
              content: 'Fırsata ürün başarılı bir şekilde eklendi.',
              key: messageKey,
              duration: 2,
            })

            setClearText((data) => data + 1)
            getDealProducts(pageType)
            setVisibleAddProductsDrawer(false)
          })
          .catch((e) => {
            if (e.response && e.response.status === 409) {
              message.error({
                content: e.response.data.message,
                key: messageKey,
                duration: 2,
              })
              return
            }

            message.error({
              content: 'Bir Problem Oluştu. Eksiksiz ve doğru şekilde doldurduğunuzdan emin olun.',
              key: messageKey,
              duration: 2,
            })
          })
      },
    })
  }

  const removeDealProductApprovalCampaign = (
    dealProductId: number,
    productToCategoryApprovalId: number
  ) => {
    confirm({
      title: 'Fırsat ürünü onaylanacak kampanyayı iptal etme',
      icon: <ExclamationCircleOutlined />,
      content:
        'Ürüne bağlanmak için bekleyen kampanyayı iptal edeceksiniz emin misiniz? \n Not: Direkt olarak bu bağlantı kaldırılacak',
      okText: 'Tamam',
      cancelText: 'İptal',
      style: {width: 550},
      zIndex: 9999,
      onOk: () => {
        removeDealProductApprovalCampaignRequest(dealProductId, productToCategoryApprovalId).then(
          () => {
            setCampaignAlertMessage((data) => {
              return data.filter((item) => {
                return item.product_to_category_approval_id !== productToCategoryApprovalId
              })
            })
          }
        )
      },
    })
  }

  const removeDealProduct = (dealProductId: number) => {
    const messageKey = 'remove-deal-product'

    message.loading({
      content: 'Fırsat ürünü siliniyor...',
      key: messageKey,
    })

    deleteDealProductRequest(Number(dealId), dealProductId)
      .then(() => {
        message.success({
          content: 'Fırsat ürünü başarılı bir şekilde silindi.',
          key: messageKey,
          duration: 2,
        })

        setDealProducts((products) => {
          return products.filter((product) => {
            return product.id !== dealProductId
          })
        })
      })
      .catch(() => {
        message.error({
          content: 'Bir Problem Oluştu. Lütfen daha sonra tekrar deneyiniz.',
          key: messageKey,
          duration: 2,
        })
      })
  }

  const updateTableFilter = React.useMemo(() => {
    const loadSearch = (value: string | any[] | undefined, key: string | number) => {
      setFilter((data: any) => ({...data, [key]: value}))
    }

    return debounce(loadSearch, 500)
  }, [])

  const mappingProducts = (dealProducts: dealProductType[]) => {
    return dealProducts.map((dealProduct: dealProductType) => {
      return {
        id: dealProduct.deal_product_id,
        product_id: dealProduct.product_id,
        sort_order: dealProduct.sort_order,
        sku: dealProduct.product?.sku,
        model: dealProduct.product?.model,
        name: dealProduct?.product?.details?.name,
        price: dealProduct?.product?.prices?.price_kmiy,
        price_cart_discount: dealProduct?.product?.prices?.price_cart_discount,
        special_price: dealProduct?.product?.prices?.special_price,
        rawStartDate: dealProduct.date_start,
        rawEndDate: dealProduct.date_end,
        new_price: dealProduct?.product?.new_price || 0,
        dateStart: moment(dealProduct.date_start).format('DD.MM.y HH:mm:ss'),
        dateEnd: moment(dealProduct.date_end).format('DD.MM.y HH:mm:ss'),
        anotherLink: dealProduct?.another_link,
        anotherTitle: dealProduct?.another_title,
        anotherImage: dealProduct?.another_image,
        fullAnotherImage: dealProduct?.full_another_image,
        anotherButtonText: dealProduct?.another_button_text,
        behaveLikeBoutique: dealProduct?.behave_like_boutique,
        quantity: dealProduct?.product?.quantity,
        campaignQuantity: dealProduct.quantity,
        campaignSaleQuantity: dealProduct.sale_quantity,
        campaignRemainingQuantity: dealProduct.remaining_quantity,
        badgeList: dealProduct.badge_list,
        campaigns: dealProduct.campaigns,
      }
    }) as dealProductTableType[]
  }

  const updateWillBeAddedDealProductIds = (selectedProducts: productSelectionItemsType[]) => {
    if (!selectedProducts) {
      return
    }
    setWillBeAddedDealProductIds(selectedProducts.map((product) => product.id))
  }

  useEffect(() => {
    if (!willBeUpdatedProduct) {
      return
    }

    setVisibleDealProductModal(true)
  }, [willBeUpdatedProduct])

  useEffect(() => {
    if (!visibleDealProductModal) {
      setWillBeUpdatedProduct(null)
    }
  }, [visibleDealProductModal])

  return (
    <DealContext.Provider
      value={{
        dealId,
        dealProducts,
        deal,
        isValid,
        updateIsValid,
        onChangeValidate,
        createDeal,
        updateDeal,
        getDeal,
        updateOrCreateData,
        addDealProduct,
        updateWillBeAddedDealProductIds,
        getDealProducts,
        clearText,
        visibleDealProductModal,
        setVisibleDealProductModal,
        willBeUpdatedProduct,
        setWillBeUpdatedProduct,
        updateDealProduct,
        removeDealProduct,
        setSortedDealProductIds,
        updateDealProductSort,
        sortedDealProductIds,
        visibleAddProductsDrawer,
        setVisibleAddProductsDrawer,
        willBeAddedDealDateRange,
        setWillBeAddedDealDateRange,
        willBeAddedDealProductBadge,
        setWillBeAddedDealProductBadge,
        willBeAddedDealProductCampaign,
        setWillBeAddedDealProductCampaign,
        campaignAlertMessage,
        removeDealProductApprovalCampaign,
        dealDetailSubmitRef,
        imageURL,
        recommendBannerImageURL,
        setImageURL,
        setRecommendBannerImageURL,
        meta,
        updateMeta,
        pageType,
        setPageType,
        filter,
        setFilter,
        updateTableFilter,
      }}
    >
      {children}
    </DealContext.Provider>
  )
}
