import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''

export const getProductPriceWillBeUpdatedRequest = (
  apiQueueId: number,
  filter: any,
  page: number,
  limit: number,
  relation: string[] = ['description'],
  withPrices: boolean = true
) => {
  return axios.get(`${API_URL}/products/new/prices/${apiQueueId}`, {
    params: {
      ...filter,
      with: relation,
      with_prices: withPrices ? true : undefined,
      page,
      limit,
    },
  })
}

export const updateProductPriceWillBeUpdatedRequest = (apiQueueId: number, data: any) => {
  return axios.put(`${API_URL}/products/new/prices/${apiQueueId}`, data)
}
