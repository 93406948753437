export type IBmCampaignUrlType = {
  groupId: string
}

export type IBmCampaignListType = {
  campaign_id: number
  campaign_name: string
  campaign_title: string
  date_added: string
  date_end: string
  campaign_page_status: boolean
  campaign_status: boolean
  campaign_sort: number
}

export type IBmCampaignSortListType = {
  campaign_id: number
  campaign_name: string
  campaign_title: string
}

export enum PacketDiscountType {
  PRICE = 'price',
  PERCENT = 'percent',
}
