import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {IBMCampaignDetailWrapper} from '../components/pages/ibm/campaign-detail/IBMCampaignDetailWrapper'
import {IBMCampaignCreateWrapper} from '../components/pages/ibm/campaign/IBMCampaignCreateWrapper'
import {IBMCampaignExportWrapper} from '../components/pages/ibm/campaign/IBMCampaignExportWrapper'
import {IBMCampaignListWrapper} from '../components/pages/ibm/campaign/IBMCampaignListWrapper'
import {IBMCampaignSpecialGroupExportWrapper} from '../components/pages/ibm/campaign/IBMCampaignSpecialGroupExportWrapper'
import {IBMCampaignSpecialGroupProductsExportWrapper} from '../components/pages/ibm/campaign/IBMCampaignSpecialGroupProductsExportWrapper'
import {IBMGroupExportWrapper} from '../components/pages/ibm/group/IBMGroupExportWrapper'
import {IBMGroupListWrapper} from '../components/pages/ibm/group/IBMGroupListWrapper'
import {IBMGroupsAndCampaignsExportWrapper} from '../components/pages/ibm/group/IBMGroupsAndCampaignsExportWrapper'
import {IBMCampaignSpecialGroupsProducts} from '../components/pages/ibm/products/IBMCampaignSpecialGroupsProducts'

export const IBMRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/groups'} element={<IBMGroupListWrapper />} />
        <Route path={'/groups/export/*'} element={<IBMGroupExportWrapper />} />
        <Route path={'/groups/export-all/*'} element={<IBMGroupsAndCampaignsExportWrapper />} />
        <Route path={'/group/:groupId'} element={<IBMCampaignListWrapper />} />
        <Route path={'/campaign/:campaignId'} element={<IBMCampaignDetailWrapper />} />
        <Route path={'/campaign/create/:groupId'} element={<IBMCampaignCreateWrapper />} />
        <Route path={'/campaign/export/*'} element={<IBMCampaignExportWrapper />} />
        <Route
          path={'/campaign/special-group/export/*'}
          element={<IBMCampaignSpecialGroupExportWrapper />}
        />
        <Route
          path={'campaign/special-group/products/export/*'}
          element={<IBMCampaignSpecialGroupProductsExportWrapper />}
        />
        <Route
          path={'/campaign/:campaignId/products'}
          element={<IBMCampaignSpecialGroupsProducts />}
        />
      </Routes>
    </>
  )
}
