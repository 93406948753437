import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {GameListWrapper} from '../components/pages/games/GameListWrapper'
import {GameCardListWrapper} from '../components/pages/games/cards/GameCardListWrapper'

export const GameRoutes: FC = () => {
  return (
    <>
      <>
        <Routes>
          <Route path={'/'} element={<GameListWrapper />} />
          <Route path={'/:gameId/cards'} element={<GameCardListWrapper />} />
        </Routes>
      </>
    </>
  )
}
