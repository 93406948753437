import React, {FC, useContext, useEffect} from 'react'
import {useParams} from 'react-router-dom'

import '../../../../_metronic/assets/sass/order.css'
import {PageTitle} from '../../../../_metronic/layout/core'
import {OrderContext, OrderProvider} from '../../../providers/orders/OrderProvider'
import {Loader} from '../../molecules/Loader'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import Customer from './blocks/Customer'
import Header from './blocks/Header'
import Highlight from './blocks/Highlight'
import Items from './blocks/Items'
import Payment from './blocks/Payment'
import Summary from './blocks/Summary'

const Body: FC = () => {
  const {order, getOrder, isLoading} = useContext(OrderContext)
  const {orderId} = useParams()

  useEffect(() => {
    if (orderId) {
      getOrder(orderId)
    }

    return () => {}
  }, [orderId])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {order && (
            <div className='order-detail'>
              <div className='row'>
                <div className='col ant-col-16'>
                  <div className='row'>
                    <div className='col ant-col-24'>
                      <Items order={order} />
                    </div>
                  </div>
                </div>
                <div className='col ant-col-8'>
                  <div className='row gap-32'>
                    <div className='col ant-col-24'>
                      <Summary order={order} />
                    </div>
                    <div className='col ant-col-24'>
                      <Payment order={order} />
                    </div>
                    <div className='col ant-col-24'>
                      <Customer order={order} />
                    </div>
                    <div className='col ant-col-24'>
                      <Highlight order={order} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export const OrderDetailWrapperWithoutProvider: FC = () => {
  const {orderId} = useParams()

  return (
    <>
      <Toolbar rightField={<Header />}></Toolbar>
      <PageTitle>Sipariş #{orderId}</PageTitle>
      <Body />
    </>
  )
}

export const OrderDetailWrapper: FC = () => {
  return (
    <OrderProvider>
      <OrderDetailWrapperWithoutProvider />
    </OrderProvider>
  )
}
