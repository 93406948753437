import {message} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import * as Yup from 'yup'

import {
  DEEP_AMOUNT,
  DEEP_AMOUNT_TEXT,
  SUB_TOTAL,
  SUB_TOTAL_TEXT,
} from '../../../../enums/CouponPrefixTypeEnum'
import {CouponPrefixContext} from '../../../../providers/campaign_and_coupon/CouponPrefixProvider'
import {CouponPrefixModelType} from '../../../models/campaign_and_coupon/CouponPrefixModel'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'
import {KrcModal} from '../../KrcModal'

interface IUpdateCouponPrefixModalProps {
  onHide: () => void
}

const updateCouponPrefixSchema = Yup.object({
  prefix: Yup.string().required('Prefix Adı boş olamaz.'),
  is_show_in_cart: Yup.number().required('Sepette Gösterim Durumu boş olamaz.'),
  negative_margin: Yup.number().nullable(),
  is_excluding_negative_margin: Yup.number().required('Negatif Marjdan Hariç Mi? boş olamaz.'),
  total_check: Yup.number().required('Türü boş olamaz.'),
  status: Yup.number().required('Durum boş olamaz.'),
})

export const UpdateCouponPrefixModal: FC<IUpdateCouponPrefixModalProps> = ({onHide}) => {
  const {willBeUpdatePrefix, updateCouponPrefix} = useContext(CouponPrefixContext)
  const [formikInitialValues, setFormikInitialValues] = useState<CouponPrefixModelType>(
    {} as CouponPrefixModelType
  )
  const [prefixDescription, setPrefixDescription] = useState<string>('')

  useEffect(() => {
    if (!willBeUpdatePrefix) return
    setPrefixDescription(willBeUpdatePrefix.prefix_description || '')
    setFormikInitialValues({
      id: willBeUpdatePrefix?.id,
      prefix: willBeUpdatePrefix?.prefix,
      prefix_description: willBeUpdatePrefix?.prefix_description,
      negative_margin: willBeUpdatePrefix?.negative_margin,
      is_excluding_negative_margin: willBeUpdatePrefix?.is_excluding_negative_margin ? 1 : 0,
      is_show_in_cart: willBeUpdatePrefix?.is_show_in_cart ? 1 : 0,
      total_check: willBeUpdatePrefix?.total_check ? 1 : 0,
      status: willBeUpdatePrefix?.status ? 1 : 0,
    })
  }, [])

  const handleSubmit = (values: FormikValues) => {
    if (!prefixDescription) {
      message.error('Prefix Açıklaması boş olamaz.')
      return
    }
    updateCouponPrefix({
      ...values,
      prefix_description: prefixDescription,
    } as CouponPrefixModelType)
  }

  return (
    <>
      {formikInitialValues.prefix && (
        <KrcModal
          modalProps={{
            size: 'lg',
            onHide: () => {
              onHide()
            },
          }}
          modalHeaderProps={{
            title: `(${willBeUpdatePrefix?.prefix}) Prefixini Düzenle`,
            onHide,
          }}
        >
          <Modal.Body className='py-lg-10 px-lg-10'>
            <Formik
              initialValues={formikInitialValues}
              onSubmit={handleSubmit}
              validationSchema={updateCouponPrefixSchema}
            >
              {() => {
                return (
                  <Form className='form space-between'>
                    <div className={'row mb-2'}>
                      <div className={'col-6'}>
                        <KrcFormikField
                          className={'mb-2'}
                          type={'text'}
                          name={'prefix'}
                          labelTitle={'Prefix Adı'}
                          required
                        />
                      </div>
                      <div className={'col-6'}>
                        <KrcFormikField
                          className={'w-100'}
                          name={'is_show_in_cart'}
                          labelTitle={'Sepette Gösterim Durumu'}
                          required
                        >
                          <Field as='select' name='is_show_in_cart' className='form-select'>
                            <option value={''} disabled={true}>
                              Seçiniz
                            </option>
                            <option value={1}>Göster</option>
                            <option value={0}>Gösterme</option>
                          </Field>
                        </KrcFormikField>
                      </div>
                    </div>
                    <div className={'row mb-2'}>
                      <div className={'col-6'}>
                        <KrcFormikField
                          className={'mb-2'}
                          type={'number'}
                          name={'negative_margin'}
                          labelTitle={'Negatif Marj Oranı'}
                        />
                      </div>
                      <div className={'col-6'}>
                        <KrcFormikField
                          className={'w-100'}
                          name={'is_excluding_negative_margin'}
                          labelTitle={'Negatif Marjdan Hariç Mi?'}
                          required
                        >
                          <Field
                            as='select'
                            name='is_excluding_negative_margin'
                            className='form-select'
                          >
                            <option value={''} disabled={true}>
                              Seçiniz
                            </option>
                            <option value={1}>Evet</option>
                            <option value={0}>Hayır</option>
                          </Field>
                        </KrcFormikField>
                      </div>
                    </div>
                    <div className={'row mb-4'}>
                      <div className={'col-6'}>
                        <KrcFormikField
                          className={'w-100'}
                          name={'total_check'}
                          type={'number'}
                          labelTitle={'Türü'}
                          required
                        >
                          <Field as='select' name='total_check' className='form-select'>
                            <option value={''} disabled={true}>
                              Seçiniz
                            </option>
                            <option value={DEEP_AMOUNT}>{DEEP_AMOUNT_TEXT}</option>
                            <option value={SUB_TOTAL}>{SUB_TOTAL_TEXT}</option>
                          </Field>
                        </KrcFormikField>
                      </div>
                      <div className={'col-6'}>
                        <KrcFormikField
                          className={'w-100'}
                          name={'status'}
                          labelTitle={'Durum'}
                          required
                        >
                          <Field as='select' name='status' className='form-select'>
                            <option value={''} disabled={true}>
                              Seçiniz
                            </option>
                            <option value={1}>Aktif</option>
                            <option value={0}>Pasif</option>
                          </Field>
                        </KrcFormikField>
                      </div>
                    </div>
                    <div className={'row'}>
                      <div>
                        <KrcFormikField
                          className={'w-100'}
                          name={'prefix_description'}
                          labelTitle={'Prefix Açıklaması'}
                          required
                        >
                          <TextArea
                            value={prefixDescription}
                            rows={3}
                            placeholder={'Prefix Açıklaması Giriniz'}
                            onChange={(e) => {
                              setPrefixDescription(e.target.value)
                            }}
                          />
                        </KrcFormikField>
                      </div>
                    </div>
                    <div className={'d-flex justify-content-end mt-7'}>
                      <button
                        type='button'
                        onClick={onHide}
                        className='btn btn-secondary'
                        style={{marginLeft: 10}}
                      >
                        İptal
                      </button>
                      <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                        Kaydet
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </Modal.Body>
        </KrcModal>
      )}
    </>
  )
}
