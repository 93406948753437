import {Input, InputProps, InputRef} from 'antd'
import React, {FC} from 'react'

import {useKrcProp} from '../../hooks/components/useKrcProp'
import {IInputConfig} from '../../models/components/KrcInputModel'

const inputConfig = {
  default: {
    type: 'text',
    className: 'form-control',
    size: 'middle',
    style: {borderRadius: 5},
  } as InputProps & React.RefAttributes<InputRef>,
}

export type KrcInputType = InputProps & React.RefAttributes<InputRef> & IInputConfig

export const KrcInput: FC<KrcInputType> = ({config = 'default', ...props}) => {
  const currentProps = useKrcProp({config: inputConfig[config], props}).getCurrentProps()

  return (
    <>
      <Input {...currentProps}></Input>
    </>
  )
}
