import {FC, useContext} from 'react'

import {IBMCampaignContext} from '../../../../providers/ibm/IBMCampaignProvider'
import {paginationMetaType} from '../../../models/GeneralModel'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const IBMCampaignList: FC = () => {
  const {columns, IBMCampaigns, meta, setMeta, handleOnFilter, isDataEmptyAfterFilter} =
    useContext(IBMCampaignContext)
  return (
    <>
      <KrcTable
        columns={columns}
        data={IBMCampaigns}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            setMeta((data: paginationMetaType) => ({...data, page}))
          },
          onChangeLimit: (limit) => {
            setMeta((data: paginationMetaType) => ({...data, limit}))
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: (search, id) => {
            handleOnFilter(search, id)
          },
          isDataEmptyAfterFilter: isDataEmptyAfterFilter,
        }}
      />
    </>
  )
}
