import axios from 'axios'

import {storeType} from '../../components/models/stores/StoreModel'

const API_URL = process.env.REACT_APP_API_URL || ''
export const STORE_URL = `${API_URL}/stores`

export function getStoresRequest(
  relations?: string[],
  relationsCount?: string[],
  filter: object = {store_type: 'company'}
) {
  return axios.get(`${STORE_URL}`, {
    params: {
      with: relations,
      with_count: relationsCount,
      ...filter,
    },
  })
}

export function searchStoresRequest(search: string, storeType?: storeType) {
  return axios.get(`${STORE_URL}/search`, {
    params: {
      search,
      store_type: storeType,
    },
  })
}
