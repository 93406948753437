import {QuestionCircleOutlined} from '@ant-design/icons'
import {Drawer, Space, Tooltip} from 'antd'
import debounce from 'lodash/debounce'
import React, {FC, useEffect, useMemo, useRef, useState} from 'react'

import {initialMetaData} from '../../providers/tables/ProductSelectionProvider'
import {getBadgeTiedExportFrontendUrl} from '../../requests/badges/BadgeExportRequest'
import {getBadgeTiedListRequest} from '../../requests/badges/BadgeRequest'
import {KrcRangePicker} from '../atoms/forms/KrcRangePicker'
import {KrcSelect} from '../atoms/forms/KrcSelect'
import {paginationMetaType} from '../models/GeneralModel'
import {IBadgeListProductAndCategoryDrawer} from '../models/badges/BadgeModel'
import {KrcTable} from '../organisms/tables/KrcTable'

export const BadgeListProductAndCategoryDrawer: FC<IBadgeListProductAndCategoryDrawer> = ({
  badge,
  onClose,
}) => {
  const columns = React.useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'Ad',
        accessor: 'name',
        maxWidth: 250,
        manualWidth: 250,
      },
      {
        Header: 'Tür',
        accessor: 'type',
        maxWidth: 150,
        manualWidth: 150,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={'multiple'}
                placeholder={'Tür Filtresi'}
                options={[
                  {value: 1, label: 'Product'},
                  {value: 2, label: 'Category'},
                ]}
                maxTagCount={'responsive'}
                style={{width: '100%', borderRadius: 5}}
                className={'ant-selector-border'}
                showArrow={true}
                onChange={(data) => {
                  if (!Array.isArray(data)) {
                    return
                  }

                  updateTableFilter({type: data})
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'start_date',
        maxWidth: 230,
        manualWidth: 230,
        Filter: () => {
          return (
            <KrcRangePicker
              size={'middle'}
              style={{borderRadius: 5}}
              onSelected={(value) => {
                if (!value) {
                  updateTableFilter({
                    start_date: [],
                  })
                  return
                }

                updateTableFilter({
                  start_date: [
                    value[0].format('YYYY-MM-DD HH:mm'),
                    value[1].format('YYYY-MM-DD HH:mm'),
                  ],
                })
              }}
              showTime
              format={'YYYY.MM.DD HH:mm'}
            ></KrcRangePicker>
          )
        },
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'end_date',
        maxWidth: 230,
        manualWidth: 230,
        Filter: () => {
          return (
            <KrcRangePicker
              size={'middle'}
              style={{borderRadius: 5}}
              onSelected={(value) => {
                if (!value) {
                  updateTableFilter({
                    end_date: [],
                  })
                  return
                }

                updateTableFilter({
                  end_date: [
                    value[0].format('YYYY-MM-DD HH:mm'),
                    value[1].format('YYYY-MM-DD HH:mm'),
                  ],
                })
              }}
              showTime
              format={'YYYY.MM.DD HH:mm'}
            ></KrcRangePicker>
          )
        },
      },
    ]
  }, [])
  const [data, setData] = useState<any[]>([])
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [filters, setFilters] = useState<any[]>([])
  const fetchRef = useRef(0)
  const initialRef = useRef(0)

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getBadgeTiedList()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getBadgeTiedList()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getBadgeTiedList()
  }, [filters])

  useEffect(() => {
    if (!badge?.badge_id) {
      initialRef.current = 0
      setData([])
      setMeta(initialMetaData)
      setFilters([])
      return
    }

    initialRef.current = 1
    getBadgeTiedList()
  }, [badge?.badge_id])

  const handleOnClose = () => {
    onClose && onClose()
  }

  const getBadgeTiedList = () => {
    if (!badge?.badge_id) {
      return
    }

    fetchRef.current += 1
    const fetchId = fetchRef.current

    getBadgeTiedListRequest(badge.badge_id, meta, filters).then((res) => {
      if (fetchRef.current !== fetchId) {
        return
      }

      const resMeta = res.data.meta
      setData(res.data.data)
      setMeta({limit: resMeta.per_page, page: resMeta.current_page, ...resMeta})
    })
  }

  const updateTableFilter = useMemo(() => {
    const update = (data: any) => {
      setFilters((filter: any) => ({...filter, ...data}))
    }

    return debounce(update, 300)
  }, [])

  return (
    <>
      <Drawer
        title={
          <div className={'d-flex justify-content-start align-items-center gap-2'}>
            Badge Bağlı Ürün ve Kategori Listesi{' '}
            <span style={{textTransform: 'uppercase'}}>(DURUM: {badge?.status_text})</span>
            <Tooltip title='Badge pasif durumda olsa da aşağıda ürün ve kategori listesi görüntülenecektir. Eğer badge durumu pasif ise sitede doğru şekilde çalışacaktır'>
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        }
        placement='right'
        size={'large'}
        width={1024}
        onClose={handleOnClose}
        keyboard={false}
        visible={!!badge}
        extra={
          <Space>
            <a
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
              href={getBadgeTiedExportFrontendUrl(filters, badge?.badge_id as number)}
              target={'_blank'}
              rel='noreferrer'
            >
              Dışarı Aktar
            </a>
          </Space>
        }
      >
        {badge?.badge_id ? (
          <KrcTable
            columns={columns}
            data={data}
            searchableProps={{
              isSearchable: true,
              onSearch: (data, id) => {
                if (!updateTableFilter) {
                  return
                }

                updateTableFilter({[id]: data})
              },
            }}
            paginationProps={{
              isPagination: true,
              limit: meta.limit,
              page: meta.page,
              paginationData: meta,
              onChangeLimit: (limit) => {
                if (!setMeta) {
                  return
                }

                setMeta((data) => ({...data, limit}))
              },
              onChangePage: (page) => {
                if (!setMeta) {
                  return
                }

                setMeta((data) => ({...data, page}))
              },
            }}
          />
        ) : undefined}
      </Drawer>
    </>
  )
}
