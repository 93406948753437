import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  ExcludeCouponContext,
  ExcludeCouponProvider,
} from '../../../../providers/campaign_and_coupon/ExcludeCouponProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {AddProductsToCouponExcludeDrawer} from '../../../drawers/campaign_and_coupon/AddProductsToCouponExcludeDrawer'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {ExcludedProductList} from './ExcludedProductList'

const ExcludeCouponListWrapperWithProvider: FC = () => {
  const {setIsShowCreateExcludeCouponDrawer} = useContext(ExcludeCouponContext)
  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <KrcButton onClick={() => setIsShowCreateExcludeCouponDrawer(true)}>
            Exclude Ekle
          </KrcButton>
        </div>
      </Toolbar>
      <PageTitle breadcrumbs={[]}>Exclude İşlemleri</PageTitle>
      <ExcludedProductList />
      <AddProductsToCouponExcludeDrawer />
    </>
  )
}

export const ExcludeCouponListWrapper = () => {
  return (
    <ExcludeCouponProvider>
      <ExcludeCouponListWrapperWithProvider />
    </ExcludeCouponProvider>
  )
}
