import React, {useEffect, useMemo, useState} from 'react'
import {Cell} from 'react-table'

import {IOrderModel} from '../../../../components/models/order/OrderModel'
import {getProperty} from '../../../../helpers/ArrayHelper'
import {priceFormat} from '../../../../helpers/NumberHelper'
import {orderSubBlockModel} from '../../../models/order/OrderSubBlockModel'
import {KrcTable} from '../../../organisms/tables/KrcTable'

const Totals: React.FC<IOrderModel> = ({order}) => {
  const [data, setData] = useState<orderSubBlockModel[]>([])

  useEffect(() => {
    if (order) {
      const currency = getProperty(order.customProperties, 'Currency') ?? ''
      const total = order.orderAmount
      const discount = order.totalDiscountAmount
      const shipping = order.shippingProps

      setData([
        {
          key: 1,
          field: 'Ara Toplam',
          value: `${priceFormat(total + discount - shipping.amount)} ${currency}`,
        },
        {
          key: 2,
          field: 'Kampanya İndirimi',
          value: (discount ? '-' : '') + `${priceFormat(discount)} ${currency}`,
        },
        {
          key: 2,
          field: 'Kargo Ücreti',
          value: shipping.free ? 'Ücretsiz' : `${priceFormat(shipping.amount)} ${currency}`,
        },
        {
          key: 3,
          field: 'Toplam Fiyat',
          value: `${priceFormat(total)} ${currency}`,
        },
      ])
    }
  }, [order])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'field',
        width: '80%',
        Cell: ({row}: Cell<any>) => <div className='text-end'>{row.original.field}</div>,
      },
      {
        Header: '',
        accessor: 'value',
        Cell: ({value}: Cell<any>) => <span style={{fontWeight: 'bold'}}>{value}</span>,
      },
    ],
    []
  )

  return (
    <div className='block-wrapper radius'>
      {order && (
        <KrcTable
          columns={columns}
          data={data}
          showHeader={false}
          cellClassName='font-weight-normal'
          stylesProps={{style: {margin: 0, padding: 0}, className: ['order-block-table']}}
        />
      )}
    </div>
  )
}

export default Totals
