import {CheckboxValueType} from 'antd/lib/checkbox/Group'
import moment from 'moment'
import * as querystring from 'query-string'
import React, {useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {FiltersDataType} from '../../../modules/partials/components/filter/models/FilterModel'
import {
  getProductFilterListExportFrontendUrl,
  productFilterListExportRequest,
} from '../../../requests/products/ProductExportRequest'
import {getProductsFilterRequest} from '../../../requests/products/ProductRequest'
import {minimalProductType} from '../../models/products/MinimalProductModel'
import {useExport} from '../exports/useExport'
import {usePagination} from '../pagination/usePagination'

const statusOptions = [
  {
    value: '1',
    label: 'Aktif',
  },
  {
    value: '0',
    label: 'Pasif',
  },
]

const imageOptions = [
  {
    value: '1',
    label: 'Görseli Olan',
  },
  {
    value: '0',
    label: 'Görseli Olmayan',
  },
]

const stockStatusOptions = [
  {
    value: '1',
    label: 'Aktif',
  },
  {
    value: '0',
    label: 'Pasif',
  },
]

const searchPriority = [
  {
    value: '2',
    label: 'Çok Yüksek',
  },
  {
    value: '1',
    label: 'Yüksek',
  },
  {
    value: '0',
    label: 'Normal',
  },
  {
    value: '-1',
    label: 'Düşük',
  },
  {
    value: '-2',
    label: 'Çok Düşük',
  },
]

const dataAreaOptions = [
  {
    value: 'cook',
    label: 'Cook',
  },
  {
    value: 'deu',
    label: 'Deu',
  },
  {
    value: 'ems',
    label: 'Ems',
  },
  {
    value: 'hedt',
    label: 'Hedt',
  },
  {
    value: 'jmbo',
    label: 'Jmbo',
  },
  {
    value: 'klmy',
    label: 'Klmy',
  },
  {
    value: 'krc',
    label: 'Krc',
  },
  {
    value: 'krch',
    label: 'Krch',
  },
]

const packetTypeOptions = [
  {
    value: 'TA',
    label: 'TA',
  },
  {
    value: 'TB',
    label: 'TB',
  },
  {
    value: 'TIP1',
    label: 'TIP1',
  },
  {
    value: 'TIP2',
    label: 'TIP2',
  },
]

const kmiyOptions = [
  {
    value: '1',
    label: 'Evet',
  },

  {
    value: '0',
    label: 'Hayır',
  },
]

export const useProductListFilter = (onChangeMetaData?: any, defaultMeta?: any) => {
  const navigate = useNavigate()
  const [filters, setFilters] = useState<FiltersDataType>()
  const [recentFiltersOfResults, setRecentFiltersOfResults] = useState<FiltersDataType>()
  const [checkboxData, setCheckboxData] = useState<CheckboxValueType[]>()
  const [data, setData] = useState<any[]>([])
  const fetchRef = useRef(0)
  const [showAdvancedFilter, setShowAdvancedFilter] = useState<boolean>(false)
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false)
  const [clear, setClear] = React.useState(0)
  const {checkExport, downloadExcelUrl} = useExport()

  const updateFilter = (filter: object) => {
    setFilters((filters: FiltersDataType | undefined) => {
      return {...filters, ...filter}
    })
  }

  const getProductFilter = (page: null | number = null) => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    let _meta = meta
    setIsFilterLoading(true)
    setRecentFiltersOfResults(filters)
    if (page) {
      _meta.page = page
    }

    getProductsFilterRequest(_meta, ['description', 'category.description'], {
      ...filters,
      with_prices: true,
    })
      .then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }
        const products = res.data.data.map((product: minimalProductType) => {
          return {
            id: product.product_id,
            model: product.model,
            quantity: product.total_quantity,
            stock_status: product.stock_status,
            name: product.details?.name,
            category: product.category,
            price: product.prices?.price,
            priceKmiy: product.prices?.price_kmiy,
            sellPrice: product.prices?.sell_price ?? product.price_kmiy,
            specialPrice: product.prices?.special_price,
            cartPrice: product.prices?.price_cart_discount,
            image: product.cdn_image,
            lastUpdateText: product.date_modified_text,
            status: product.status,
            lastUpdate: moment(product.date_modified).format('DD.MM.y'),
          }
        })

        const metaData = res.data.meta
        setData(products)
        updateMetaData({limit: metaData.per_page, page: metaData.current_page, ...metaData})
        setIsFilterLoading(false)
      })
      .catch(() => {
        setIsFilterLoading(false)
      })
  }

  const {meta, setMeta, updateMetaData} = usePagination({
    callback: getProductFilter,
    onChangeMeta: onChangeMetaData,
    defaultMeta: defaultMeta,
    enableReinitialize: true,
  })

  const updateShowAdvanceFilter = (data: boolean) => {
    setShowAdvancedFilter(data)
  }

  const getExportProductFilterListUrl = (willBeFilteredProductIds?: number[]) => {
    let params = {
      filterSearchData: recentFiltersOfResults,
    }

    params.filterSearchData = {...params.filterSearchData, product_ids: willBeFilteredProductIds}
    return getProductFilterListExportFrontendUrl(params)
  }

  const exportProductFilterList = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
    })

    productFilterListExportRequest(params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  return {
    updateFilter,
    setCheckboxData,
    getProductFilter,
    updateShowAdvanceFilter,
    // setMeta,
    getExportProductFilterListUrl,
    exportProductFilterList,
    statusOptions,
    stockStatusOptions,
    searchPriority,
    dataAreaOptions,
    packetTypeOptions,
    kmiyOptions,
    filters,
    checkboxData,
    data,
    setData,
    meta,
    setMeta,
    showAdvancedFilter,
    isFilterLoading,
    downloadExcelUrl,
    imageOptions,
    clear,
    setClear,
  }
}
