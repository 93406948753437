import React, {FC, useContext} from 'react'

import {ProductSelectionEnum} from '../../../enums/ProductSelectionEnum'
import {ProductSelectionContext} from '../../../providers/tables/ProductSelectionProvider'
import {transferPropsType} from '../../models/components/TransferTableModel'
import {TransferTable} from '../tables/TransferTable'

interface IProductSelectionList {
  removeAllExcluded?: number
  transferProps?: transferPropsType
  clear?: number
}

export const ProductSelectionList: FC<IProductSelectionList> = ({
  removeAllExcluded = 0,
  transferProps,
  clear,
}) => {
  const {
    data,
    meta,
    type,
    columns,
    transferIds,
    updateMeta,
    setSelectedItems,
    updateTableFilter,
    updateTransferData,
    updateExcludedProductIds,
    setExcludedTempProductIds,
  } = useContext(ProductSelectionContext)

  return (
    <>
      <TransferTable
        data={data}
        columns={columns}
        outerHeight={type === ProductSelectionEnum.TEXT ? 485 : 580}
        transferProps={{
          onTransfer: (data) => {
            if (!updateTransferData) {
              return
            }

            updateTransferData(data)
          },
          newTransferredIds: transferIds,
          ...transferProps,
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: (data, id) => {
            if (!updateTableFilter) {
              return
            }

            updateTableFilter({[id]: data})
          },
        }}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangeLimit: (limit) => {
            if (!updateMeta) {
              return
            }
            updateMeta({limit})
          },
          onChangePage: (page) => {
            if (!updateMeta) {
              return
            }
            updateMeta({page})
          },
        }}
        clear={clear}
        excludedProps={{
          onExcluded: (data) => {
            if (updateExcludedProductIds) {
              updateExcludedProductIds(data)
            }
          },
          onTempExcluded: (data) => {
            if (setExcludedTempProductIds) {
              setExcludedTempProductIds(data)
            }
          },
          removeAllExcluded,
        }}
        onSelectedItems={setSelectedItems}
      />
    </>
  )
}
