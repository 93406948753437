import {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'

import {CategoryProductSortingContext} from '../../../../../../../providers/category/sorting/CategoryProductSortingProvider'
import {
  getCategoryProductEditorsChoiceParametricSortingRequest,
  getCategoryProductMostSellerInputAttributesRequest,
  getCategoryProductMostSellerManufacturersTypeRequest,
} from '../../../../../../../requests/categories/sorting/CategorySortingRequest'
import {
  categoryMostSellerInputAttrType,
  categoryMostSellerManufacturersType,
  categoryMostSellerType,
} from '../../../../../../models/categories/CategoryModel'
import {categoryUrlType} from '../../../../../../models/categories/CategoryProductModel'

const schema = Yup.object({
  first_line_count: Yup.string(),
  product_name: Yup.string(),
  product_group: Yup.array(),
  discount_rate: Yup.string(),
  product_manufacturer: Yup.array(),
  product_color: Yup.array(),
  product_number_of_people: Yup.array(),
  product_number_of_piece: Yup.array(),
})

const _initialValues = {
  first_line_count: '',
  product_name: '',
  product_group: [],
  discount_rate: '',
  product_manufacturer: [],
  product_color: [],
  product_number_of_people: [],
  product_number_of_piece: [],
}
const initialAttributes = {
  group: [],
  brand: [],
  color: [],
  number_of_people: [],
  number_of_piece: [],
}

const initialManufacturers = [
  {
    id: '',
    text: '',
  },
]

export const useCategoryProductSortingMostSeller = () => {
  const {categoryId} = useParams<categoryUrlType>()
  const [initialValues, setInitialValues] = useState<categoryMostSellerType>(_initialValues)
  const [categoryMostSeller, setCategoryMostSeller] = useState<categoryMostSellerType>({
    first_line_count: '',
    product_name: '',
    product_group: [],
    discount_rate: '',
    product_manufacturer: [],
    product_color: [],
    product_number_of_people: [],
    product_number_of_piece: [],
  })

  const [categoryMostSellerInputAttributes, setCategoryMostSellerInputAttributes] =
    useState<categoryMostSellerInputAttrType>(initialAttributes)

  const [categoryMostSellerManufacturers, setCategoryMostSellerManufacturers] =
    useState<categoryMostSellerManufacturersType>(initialManufacturers)

  const {updateCategoryProductMostSellerSorting} = useContext(CategoryProductSortingContext)

  useEffect(() => {
    setInitialValues({
      first_line_count: categoryMostSeller.first_line_count ?? '',
      product_name: categoryMostSeller.product_name ?? '',
      product_group: categoryMostSeller.product_group ?? [],
      discount_rate: categoryMostSeller.discount_rate ?? '',
      product_manufacturer: categoryMostSeller.product_manufacturer ?? [],
      product_color: categoryMostSeller.product_color ?? [],
      product_number_of_people: categoryMostSeller.product_number_of_people ?? [],
      product_number_of_piece: categoryMostSeller.product_number_of_piece ?? [],
    })
  }, [categoryMostSeller])

  useEffect(() => {
    getCategoryProductMostSeller()
    getCategoryProductMostSellerInputAttributes()
    getCategoryMostSellerManufacturersTypes()
  }, [])

  const getCategoryProductMostSeller = () => {
    if (!categoryId) {
      return
    }

    getCategoryProductEditorsChoiceParametricSortingRequest(Number(categoryId)).then((res) => {
      if (!res) {
        return
      }
      setCategoryMostSeller(res.data)
    })
  }

  const getCategoryProductMostSellerInputAttributes = () => {
    if (!categoryId) {
      return
    }
    getCategoryProductMostSellerInputAttributesRequest(Number(categoryId))
      .then((res) => {
        if (!res.data) {
          return
        }

        setCategoryMostSellerInputAttributes((prev) => {
          return {
            ...prev,
            group: res.data.data.group,
            color: res.data.data.color,
            number_of_people: res.data.data.number_of_people,
            number_of_piece: res.data.data.number_of_piece,
          }
        })
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const getCategoryMostSellerManufacturersTypes = () => {
    if (!categoryId) {
      return
    }

    getCategoryProductMostSellerManufacturersTypeRequest(Number(categoryId))
      .then((res) => {
        if (!res.data) {
          return
        }
        setCategoryMostSellerManufacturers(res.data.data)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  return {
    initialValues,
    schema,
    updateCategoryProductMostSellerSorting,
    categoryMostSellerInputAttributes,
    categoryMostSellerManufacturers,
  }
}
