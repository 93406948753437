import {Empty, message} from 'antd'
import React, {FC, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {IBMCampaignContext} from '../../../../providers/ibm/IBMCampaignProvider'
import {getIBMGroupCampaignsRequest} from '../../../../requests/ibm/IBMGroupRequest'
import {IBmCampaignListType} from '../../../models/ibm/IBMCampaignModel'
import {Loader} from '../../../molecules/Loader'
import {KrcTable} from '../../../organisms/tables/KrcTable'
import {KrcModal} from '../../KrcModal'

interface IIBMCampaignSortModalProps {
  onHide: () => void
}

export const IBMCampaignSortModal: FC<IIBMCampaignSortModalProps> = ({onHide}) => {
  const {selectedGroupId, sortColumns, setIBMSortedCampaigns, handleOnSaveIBMCampaignSorting} =
    useContext(IBMCampaignContext)
  const [IBMCampaigns, setIBMCampaignData] = useState<IBmCampaignListType[][]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    getIBMGroupCampaignsRequest({}, {page: 1, limit: 10}, selectedGroupId, 0, 1)
      .then((res) => {
        setIBMCampaignData(res.data.data)
      })
      .catch((err) => {
        message.error('Kampanyalar getirilirken bir hata oluştu.')
        message.error(err.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
        }}
        modalHeaderProps={{
          title: 'IBM Kampanyalarını Sırala',
          onHide,
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10'>
          {IBMCampaigns.length > 0 ? (
            <KrcTable
              columns={sortColumns}
              data={IBMCampaigns}
              outerHeight={280}
              sortableProps={{
                isSortable: true,
                onSortChange: (campaigns) => {
                  setIBMSortedCampaigns(() => campaigns)
                },
              }}
            />
          ) : isLoading ? (
            <Loader />
          ) : (
            <Empty
              image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
              imageStyle={{
                height: 60,
              }}
              description={<span>Grup altında durumu görünür olan kampanya bulunamadı.</span>}
            ></Empty>
          )}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={onHide}
              style={{marginLeft: 10}}
            >
              İptal Et
            </button>
            {IBMCampaigns.length !== 0 && (
              <button
                type='button'
                onClick={() => handleOnSaveIBMCampaignSorting()}
                className='btn btn-primary'
                style={{marginLeft: 10}}
              >
                Kaydet
              </button>
            )}
          </div>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
