import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {LandingWrapper} from '../components/pages/landings/details/LandingWrapper'
import {LandingListWrapper} from '../components/pages/landings/lists/LandingListWrapper'
import {LandingListExportWrapper} from '../components/pages/landings/lists/exports/LandingListExportWrapper'

export const LandingRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/export'} element={<LandingListExportWrapper />} />
        <Route path={'/create'} element={<LandingWrapper />} />
        <Route path={'/:landingId/update'} element={<LandingWrapper />} />
        <Route path={'/'} element={<LandingListWrapper />} />
      </Routes>
    </>
  )
}
