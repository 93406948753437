import debounce from 'lodash/debounce'
import {Moment} from 'moment/moment'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'

import {CategoryProductsProcessContext} from '../../../../providers/category/products/CategoryProductsProcessProvider'
import {initialMetaData} from '../../../../providers/tables/ProductSelectionProvider'
import {getCategoryLinkedPromotionsRequest} from '../../../../requests/categories/promotion/CategoryLinkedPromotionRequests'
import {requestDateFormat, viewDateFormat} from '../../../../service/DateService'
import {KrcAlert} from '../../../atoms/KrcAlert'
import {KrcEmptyFilter} from '../../../atoms/KrcEmptyFilter'
import {KrcRangePicker} from '../../../atoms/forms/KrcRangePicker'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {paginationMetaType} from '../../../models/GeneralModel'
import {
  ICategoryLinkedPromotion,
  categoryLinkedPromotionFilterType,
} from '../../../models/categories/CategoriesPromotionModel'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const CategoryLinkedPromotions: FC<ICategoryLinkedPromotion> = ({pageType}) => {
  const [data, setData] = useState<any[]>([])
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [filter, setFilter] = useState<categoryLinkedPromotionFilterType>({})
  const fetchRequestRef = useRef(0)
  const initialRef = useRef(0)

  const {category, setPageType} = useContext(CategoryProductsProcessContext)

  useEffect(() => {
    setPageType(pageType)
  }, [])

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 80,
        manualWidth: 80,
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Kampanya ID',
        accessor: 'promotion_id',
        maxWidth: 130,
        manualWidth: 130,
      },
      {
        Header: 'Kampanya Adı',
        accessor: 'promotion_name',
        manualWidth: 280,
        maxWidth: 280,
      },
      {
        Header: 'Kampanya Kodu',
        accessor: 'promotion_coupon_code',
        manualWidth: 240,
        maxWidth: 240,
      },
      {
        Header: 'Kampanya Durumu',
        accessor: 'promotion_status',
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                {row.original.promotion_status === true ? (
                  <KrcAlert message='Aktif' type='success' className={'py-1'} />
                ) : (
                  <></>
                )}

                {row.original.promotion_status === false ? (
                  <KrcAlert message='Pasif' type='error' className={'py-1'} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        },
        maxWidth: 150,
        manualWidth: 150,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={undefined}
                placeholder={'Seçiniz'}
                options={[
                  {value: '1', label: 'Aktif'},
                  {value: '0', label: 'Pasif'},
                ]}
                style={{width: '100%'}}
                className={'ant-selector-border'}
                onChange={(data) => {
                  if (initialRef.current === 0) {
                    return
                  }
                  handleOnFilter(data, 'promotion_status')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'promotion_date_start',
        maxWidth: 200,
        manualWidth: 200,
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                onSelected={handleOnSelectedStartDateRangePicker}
                size={'middle'}
                showTime={true}
                format={'DD.MM.YYYY HH:mm:ss'}
              />
            </>
          )
        },
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'promotion_date_end',
        maxWidth: 200,
        manualWidth: 200,
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                onSelected={handleOnSelectedEndDateRangePicker}
                size={'middle'}
                showTime={true}
                format={'DD.MM.YYYY HH:mm:ss'}
              />
            </>
          )
        },
      },
    ]
  }, [])

  useEffect(() => {
    getCategoryLinkedPromotion()
  }, [])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getCategoryLinkedPromotion()
  }, [filter])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getCategoryLinkedPromotion()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getCategoryLinkedPromotion()
  }, [meta.page])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  const getCategoryLinkedPromotion = () => {
    fetchRequestRef.current += 1
    const fetchId = fetchRequestRef.current

    if (!category) {
      return
    }

    getCategoryLinkedPromotionsRequest(category.category_id, filter, meta).then((res) => {
      if (fetchRequestRef.current !== fetchId) {
        return
      }

      const _data = res.data.data.map((item: any) => {
        return {
          id: item.id,
          promotion_id: item.promotion.promotion_id,
          promotion_name: item.promotion.name,
          promotion_coupon_code: item.promotion.coupon_code || '-',
          promotion_status: item.promotion.status,
          promotion_date_start:
            item.promotion.date_start === '0000-00-00 00:00:00'
              ? '-'
              : viewDateFormat(item.promotion.date_start),
          promotion_date_end: item.promotion.date_end
            ? viewDateFormat(item.promotion.date_end)
            : '-',
        }
      })

      setData(_data)
      const resMeta = res.data.meta
      setMeta({limit: resMeta.per_page, page: resMeta.current_page, ...resMeta})
    })
  }

  const handleOnSelectedStartDateRangePicker = (date: Moment[] | null) => {
    if (!date) {
      setFilter((data) => {
        return {...data, promotion_date_start: undefined}
      })
      return
    }
    let startDate = requestDateFormat(date ? date[0] : null, 'YYYY-MM-DD HH:mm')
    let endDate = requestDateFormat(date ? date[1] : null, 'YYYY-MM-DD HH:mm')
    let value: (string | null)[] | undefined = undefined
    if (startDate || endDate) {
      value = [startDate, endDate]
    }

    setFilter((data) => {
      return {
        ...data,
        promotion_date_start: value,
      }
    })
  }

  const handleOnSelectedEndDateRangePicker = (date: Moment[] | null) => {
    if (!date) {
      setFilter((data) => {
        return {...data, promotion_date_end: undefined}
      })
      return
    }
    let startDate = requestDateFormat(date ? date[0] : null, 'YYYY-MM-DD HH:mm')
    let endDate = requestDateFormat(date ? date[1] : null, 'YYYY-MM-DD HH:mm')
    let value: (string | null)[] | undefined = undefined
    if (startDate || endDate) {
      value = [startDate, endDate]
    }
    setFilter((data) => {
      return {
        ...data,
        promotion_date_end: value,
      }
    })
  }

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data?: categoryLinkedPromotionFilterType) => {
        if (!data) {
          return {[rowId]: value}
        }

        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  return (
    <>
      <KrcTable
        columns={columns}
        data={data}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            setMeta((data) => ({...data, page}))
          },
          onChangeLimit: (limit) => {
            setMeta((data) => ({...data, limit}))
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: handleOnFilter,
        }}
      />
    </>
  )
}
