import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {PageTitle} from '../../../../../../../_metronic/layout/core'
import {useDeal} from '../../../../../hooks/deals/useDeal'
import {ExcelExport} from '../../../../../layout/excel/ExcelExportWrapper'
import {Toolbar} from '../../../../../organisms/toolbars/Toolbar'

export const DealProductExportWrapper = () => {
  const {exportDealProducts, downloadExcelUrl} = useDeal()

  return (
    <>
      <Toolbar />
      <PageTitle>Fırsat Ürünleri Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport
              handleExportData={exportDealProducts}
              downloadExcelUrl={downloadExcelUrl}
            />
          }
        />
      </Routes>
    </>
  )
}
