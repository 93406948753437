import {OrderedListOutlined} from '@ant-design/icons'
import {Tooltip} from 'antd'
import cn from 'classnames'
import debounce from 'lodash/debounce'
import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Cell} from 'react-table'

import {getCategorySortsRequest} from '../../../requests/categories/sorting/CategorySortingRequest'
import {KrcEmptyFilter} from '../../atoms/KrcEmptyFilter'
import {KrcInput} from '../../atoms/forms/KrcInput'
import {KrcNumberInput} from '../../atoms/forms/KrcNumberInput'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {paginationMetaType} from '../../models/GeneralModel'
import {minimalCategoryType} from '../../models/categories/CategoryModel'
import {CategorySortDataType} from '../../models/categories/CategorySortModel'
import {useSortingWeight} from '../sorting/useSortingWeight'

export const useCategorySortingList = () => {
  const fetchRef = useRef(0)
  const [categorySorts, setCategorySorts] = useState<minimalCategoryType[]>([])
  const [meta, setMeta] = useState<paginationMetaType>({limit: 10, page: 1})
  const initialRef = useRef(0)

  const [filterSearchData, setFilterSearchData]: any = useState({})

  const {orderTypes, getOrderTypes} = useSortingWeight()

  useEffect(() => {
    getCategorySorts()
  }, [filterSearchData])

  useEffect(() => {
    getCategorySorts()

    getOrderTypes()
  }, [])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getCategorySorts()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getCategorySorts()
  }, [meta.page])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  const handleOnFilter = React.useMemo(() => {
    const filter = (value: any, id: string | number) => {
      setFilterSearchData((data: any) => ({...data, [id]: value}))
    }

    return debounce(filter, 250)
  }, [filterSearchData])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Kategori ID',
        accessor: 'category_id',
        maxWidth: 100,
        manualWidth: 100,
        Filter: () => {
          return (
            <>
              <KrcNumberInput
                onChange={(data) => {
                  handleOnFilter(data, 'category_id')
                }}
                className={'form-control'}
                placeholder={`Kategori ID`}
              />
            </>
          )
        },
      },
      {
        Header: 'Kategori Adı',
        accessor: 'category_name',
        Filter: () => {
          return (
            <>
              <KrcInput
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const data = e.target.value ? e.target.value : undefined
                  handleOnFilter(data, 'category_name')
                }}
                className={'form-control'}
                placeholder={`Kategori Adı`}
              />
            </>
          )
        },
      },
      {
        Header: 'Varsayılan Sıralamalar',
        accessor: 'default_sorting_types',
        maxWidth: 300,
        minWidth: 144,
        Cell: ({row}: Cell) => (
          <div className='d-flex flex-wrap align-items-center'>
            {row.values.default_sorting_types.map((sortingType: React.ReactNode, index: number) => (
              <div key={index} className={'col-12'}>
                {sortingType}
              </div>
            ))}
          </div>
        ),
        Filter: () => {
          return (
            <>
              <KrcSelect
                options={orderTypes}
                mode={'multiple'}
                placeholder={'Varsayılan Sıralama Türü'}
                maxTagCount={'responsive'}
                style={{width: 200}}
                className={'ant-selector-border'}
                showArrow={true}
                onChange={(data) => {
                  if (!Array.isArray(data)) {
                    return
                  }

                  handleOnFilter(data, 'default_sorting_types')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Aktif Sıralama',
        accessor: 'editors_choice_sorting_type',
        maxWidth: 200,
        minWidth: 200,
        manualWidth: 200,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: 'right',
            }}
          >
            Aksiyon
          </div>
        ),
        maxWidth: 60,
        minWidth: 80,
        manualWidth: 65,
        accessor: 'col5',
        id: 'click-me-button',
        Cell: ({row}: Cell) => (
          <span className='text-end d-flex'>
            <Tooltip title={'Sırala'} className={cn('ml-2')}>
              <Link
                to={`/categories/${row.values.category_id}/sorting`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                style={{color: 'unset'}}
              >
                <OrderedListOutlined />
              </Link>
            </Tooltip>
          </span>
        ),
        Filter: <KrcEmptyFilter />,
      },
    ],
    [orderTypes]
  )

  const getCategorySorts = () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current

    getCategorySortsRequest(meta, filterSearchData).then((res) => {
      if (fetchId !== fetchRef.current) {
        return
      }

      const categorySorts = res.data.data.map((categorySort: CategorySortDataType) => {
        const defaultSortingTypes = categorySort.default_sorting_types

        let sortingTypeTexts: React.ReactNode[] = []

        if (defaultSortingTypes) {
          Object.keys(defaultSortingTypes).forEach((storeText: string) => {
            const sortingType = defaultSortingTypes[storeText]

            sortingTypeTexts.push(
              (
                <>
                  <span>{storeText}</span>: <span style={{fontWeight: 400}}>{sortingType}</span>
                </>
              ) as React.ReactNode
            )
          })
        }

        if (sortingTypeTexts.length === 0) {
          sortingTypeTexts.push(
            (
              <>
                <span className={'text-gray-600'}>(Sistem Varsayılanı)</span>
              </>
            ) as React.ReactNode
          )
        }

        return {
          category_id: categorySort.category_id,
          category_name: categorySort.category_name,
          default_sorting_types: sortingTypeTexts,
          editors_choice_sorting_type: categorySort.editors_choice_sorting_type,
        }
      })
      const restMeta = res.data.meta
      setCategorySorts(categorySorts)
      setMeta({limit: restMeta.per_page, page: restMeta.current_page, ...restMeta})
    })
  }

  return {
    columns,
    getCategorySorts,
    categorySorts,
    meta,
    setMeta,
  }
}
