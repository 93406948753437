import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {PassiveProductsExport} from './PassiveProductExport'

const Body: FC<any> = () => {
  return (
    <Routes>
      <Route path='/' element={<PassiveProductsExport />} />
    </Routes>
  )
}

const PassiveProductExportWrapper: FC = () => {
  return (
    <>
      <Body />
    </>
  )
}

export {PassiveProductExportWrapper}
