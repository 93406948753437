import {FC} from 'react'

import {KrcTabs} from '../../../molecules/KrcTabs'
import {CouponHistoryList} from './CouponHistoryList'
import {CouponUpdateForm} from './CouponUpdateForm'

export const CouponUpdateTabView: FC = () => {
  return (
    <>
      <KrcTabs
        isActiveKey={true}
        tabsClassName={'mb-1 mt-2'}
        tabsProps={{defaultActiveKey: 'update-form'}}
        tabProps={[
          {
            eventKey: 'update-form',
            title: 'Genel',
            children: <CouponUpdateForm />,
            key: 'update-form',
          },
          {
            eventKey: 'history',
            title: 'Kupon Geçmişi',
            children: <CouponHistoryList />,
            key: 'history',
          },
        ]}
      />
    </>
  )
}
