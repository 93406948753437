import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, message} from 'antd'
import _ from 'lodash'
import debounce from 'lodash/debounce'
import moment from 'moment'
import * as querystring from 'query-string'
import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {STORES_SEARCHABLE_SELECT_OPTIONS} from '../../../../enums/PassiveProductStoreIdEnum'
import {objectIsUndefined} from '../../../../helpers/ObjectHelper'
import {PassiveProductContext} from '../../../../providers/PassiveProductProvider'
import {
  getPassiveProductRequest,
  passiveProductStoreRequest,
} from '../../../../requests/products/passive_products/PassiveProductRequest'
import {
  getPassiveProductExportFrontendUrl,
  passiveProductsExportRequest,
} from '../../../../requests/products/passive_products/exports/PassiveProductExportRequest'
import {requestDateFormat} from '../../../../service/DateService'
import {KrcEmptyFilter} from '../../../atoms/KrcEmptyFilter'
import {KrcInput} from '../../../atoms/forms/KrcInput'
import {KrcRangePicker} from '../../../atoms/forms/KrcRangePicker'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {
  getPassiveProductsType,
  minimalPassiveProductType,
  tableFilterSearchParamsType,
  tableProductType,
  willBeActiveProductType,
} from '../../../models/products/passives/PassiveProductModel'
import {useExport} from '../../exports/useExport'
import {usePagination} from '../../pagination/usePagination'

const {confirm} = Modal

type selectedPassiveProductType = {
  product_id: number
  store_id: number
}

export const usePassiveProductReceivingProductTable = (onActive?: any) => {
  const fetchRef = React.useRef(0)
  const navigate = useNavigate()

  const [data, setData] = useState<any[]>([])
  const [dataPaginationInfo, setDataPaginationInfo] = useState(null)
  const [loading, setLoading] = useState(true)
  const [unSelectAllItems, setUnSelectAllItems] = useState<number>(0)
  const [visibleTextFilter, setVisibleTextFilter] = useState<boolean>(false)

  const [selectedPassiveProducts, setSelectedPassiveProducts] = useState<
    selectedPassiveProductType[]
  >([])
  const [filterSearchData, setFilterSearchData] = useState<tableFilterSearchParamsType>({
    id: undefined,
    ids: undefined,
    name: undefined,
    model: undefined,
    models: undefined,
    store_id: undefined,
    store_ids: undefined,
    updated_ts: undefined,
  })
  const {checkExport, downloadExcelUrl} = useExport()

  const {
    willBePassiveProducts,
    willBeActiveProducts,
    addWillBeActiveProduct,
    deleteWillBePassiveProduct,
  } = useContext(PassiveProductContext)

  useEffect(() => {
    const selectedProductRowIds: any = {}
    data.forEach((product: tableProductType, key) => {
      if (selectedPassiveProducts.includes({product_id: product.id, store_id: product.store_id})) {
        selectedProductRowIds[key] = true
      }
    })
  }, [data, selectedPassiveProducts])

  useEffect(() => {
    mergeWillBePassiveProductsToData()
  }, [willBePassiveProducts])

  const mergeWillBePassiveProductsToData = () => {
    if (!willBePassiveProducts) {
      return
    }
    if (meta.page !== 1) {
      return
    }

    setData((data) => {
      return [
        ...willBePassiveProducts.filter((product) => {
          return !data.some((item) => item.id === product.id && item.store_id === product.store_id)
        }),
        ...data,
      ]
    })
  }

  useEffect(() => {
    getPassiveProducts()
  }, [])

  const getPassiveProducts = () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current

    let productIndexParams = {
      page: meta?.page,
      limit: meta?.limit,
      relations: ['product', 'product.description'] as string[],
      filterSearchData,
      excludedProducts:
        willBeActiveProducts?.map((item) => {
          return {product_id: item.productId, store_id: item.storeId}
        }) ?? [],
    } as getPassiveProductsType

    getPassiveProductRequest(productIndexParams)
      .then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }

        let products = mappingResultPassiveProducts(res.data.data)
        const paginationMeta = res.data.meta

        const _meta = res.data.meta

        updateMetaData({..._meta, limit: _meta.per_page, page: _meta.current_page})
        setData(products)
        setDataPaginationInfo(paginationMeta)
        setLoading(false)
        if (objectIsUndefined(filterSearchData)) {
          mergeWillBePassiveProductsToData()
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  const {meta, updateMetaData, changePage, changeLimit} = usePagination({
    callback: getPassiveProducts,
    filter: filterSearchData,
  })

  useEffect(() => {
    if (willBeActiveProducts?.length === 0) {
      return
    }

    getPassiveProducts()
  }, [willBeActiveProducts])

  const activateSelectedPassiveProducts = async () => {
    if (selectedPassiveProducts.length === 0) {
      return false
    }

    const activeProducts = selectedPassiveProducts.map((item: any) => {
      return {
        productId: item.id,
        storeId: item.store_id,
      }
    }) as willBeActiveProductType[]

    confirm({
      title: `${selectedPassiveProducts.length} pasif ürünü aktifleştirmek istiyor musunuz?`,
      icon: <ExclamationCircleOutlined />,
      content:
        'Aktifleştirmek istediğinizden emin misiniz? Bu işlem sonradan geriye alınamayacaktır.',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        addWillBeActiveProduct(activeProducts)
        setSelectedPassiveProducts([])
        setUnSelectAllItems((data) => data + 1)
        activeProducts.forEach((product) => {
          deleteWillBePassiveProduct({productId: product.productId, storeId: product.storeId})
        })
        onActive && onActive(activeProducts)
        message.info(
          'Seçilen pasif ürün(ler) aktif edilmesi için Kaydet ve Yayınla basılması bekliyor.'
        )
      },
    })
  }

  const createPassiveProducts = async (
    formData: any,
    comment: string | null,
    selectedStoreId: number
  ) => {
    const formattedBodyFormData = formData.map((formDataItem: any) => {
      return {
        product_id: formDataItem.id,
        store_ids: [selectedStoreId],
        comment: comment,
      }
    })

    return passiveProductStoreRequest({passive_products: formattedBodyFormData}).then(() => {
      getPassiveProducts()
    })
  }

  const setDataOriginals = (original: tableFilterSearchParamsType, isSelected: boolean) => {
    if (!original.id || !original.store_id) {
      console.error('product_id veya store_id undefined olmaması gerekiyor')
      return
    }

    const originalPassiveProduct: selectedPassiveProductType = {
      product_id: original.id,
      store_id: original.store_id,
    }

    if (isSelected) {
      setSelectedPassiveProducts((passiveProducts) => {
        return Array.from(new Set([...passiveProducts, originalPassiveProduct]))
      })
      setUnSelectAllItems((data) => data + 1)
      return
    }

    setSelectedPassiveProducts((passiveProducts) => {
      return passiveProducts.filter((passiveProduct) => {
        return !_.isEqual(passiveProduct, originalPassiveProduct)
      })
    })
    setUnSelectAllItems((data) => data + 1)
  }

  const mappingResultPassiveProducts: any = (passiveProducts: minimalPassiveProductType[]) => {
    return passiveProducts.map((passiveProduct: minimalPassiveProductType) => {
      return {
        id: passiveProduct.product_id,
        name: passiveProduct.product.details?.name,
        store_id: passiveProduct.store_id,
        store_id_text: passiveProduct.store_id_text,
        model: passiveProduct.product.model,
        updated_ts: moment(passiveProduct.updated_ts).format('DD.MM.y'),
        comment: passiveProduct.comment,
      }
    })
  }

  const handleSearch = React.useMemo(() => {
    const loadTableFilterSearch = (value: string, rowId: any) => {
      if (value !== undefined && value.length < 2) {
        return
      }

      setFilterSearchData((data) => {
        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilterSearch, 250)
  }, [])

  const getExportPassiveProductsUrl = () => {
    let params = {
      filterSearchData,
    }

    return getPassiveProductExportFrontendUrl(params)
  }

  const exportPassiveProducts = () => {
    const urlParams = window.location.search.substring(1)
    let params = querystring.parse(urlParams, {
      arrayFormat: 'bracket',
      parseBooleans: true,
      parseNumbers: false,
    })

    passiveProductsExportRequest(params)
      .then((res) => {
        if (!res.data?.success) {
          return
        }

        checkExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Ürün Id',
        accessor: 'id',
        manualWidth: 80,
        maxWidth: 80,
        Filter: () => {
          return (
            <>
              <KrcInput
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFilterSearchData((filterSearchData) => {
                    return {
                      ...filterSearchData,
                      id: e.target.value ? Number(e.target.value) : undefined,
                    }
                  })
                }}
                style={{width: 75}}
                placeholder={`ID`}
              />
            </>
          )
        },
      },
      {
        Header: 'Model',
        accessor: 'model',
        manualWidth: 160,
        maxWidth: 160,
        Filter: () => {
          return (
            <>
              <KrcInput
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFilterSearchData((filterSearchData) => {
                    return {
                      ...filterSearchData,
                      model: e.target.value ? e.target.value : undefined,
                    }
                  })
                }}
                placeholder={`Model`}
              />
            </>
          )
        },
        // @ts-ignore
        Cell: ({row}) => {
          return (
            <div style={{width: 150}}>{row.original.model ? row.original.model : 'Model Yok'}</div>
          )
        },
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
        cellClassName: 'text-break',
        manualWidth: 200,
        maxWidth: 200,
        Filter: () => {
          return (
            <>
              <KrcInput
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFilterSearchData((filterSearchData) => {
                    return {
                      ...filterSearchData,
                      name: e.target.value ? e.target.value : undefined,
                    }
                  })
                }}
                className={'form-control'}
                placeholder={`Ürün Adı`}
                style={{minWidth: '110px'}}
              />
            </>
          )
        },
      },
      {
        Header: 'Mağaza Adı (ID)',
        accessor: 'store_id',
        manualWidth: 140,
        maxWidth: 140,
        // @ts-ignore
        Cell: ({row}) => (
          <div style={{width: '100%'}}>
            {
              // @ts-ignore
              `${row.original.store_id_text ? row.original.store_id_text : 'Bilinmiyor'} (${
                row.original.store_id
              })`
            }
          </div>
        ),
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={'multiple'}
                placeholder={'Seçiniz'}
                options={STORES_SEARCHABLE_SELECT_OPTIONS}
                maxTagCount={'responsive'}
                style={{width: 130}}
                className={'ant-selector-border'}
                onChange={(data) => {
                  if (!Array.isArray(data)) {
                    return
                  }

                  setFilterSearchData((filterSearchData) => {
                    return {
                      ...filterSearchData,
                      store_ids: data,
                    }
                  })
                }}
              />
            </>
          )
        },
      },

      {
        Header: 'Oluşturma Tarihi',
        accessor: 'updated_ts',
        manualWidth: 140,
        maxWidth: 140,
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                onSelected={(data) => {
                  let startDate = requestDateFormat(data ? data[0] : null, 'YYYY-MM-DD')
                  let endDate = requestDateFormat(data ? data[1] : null, 'YYYY-MM-DD')

                  let value: (string | null)[] | undefined = undefined

                  if (startDate || endDate) {
                    value = [startDate, endDate]
                  }

                  setFilterSearchData((filterSearchData) => {
                    return {
                      ...filterSearchData,
                      updated_ts: value,
                    }
                  })
                }}
                size={'middle'}
                format={'DD.MM.YYYY'}
                style={{width: 130, borderRadius: 5}}
              />
            </>
          )
        },
      },
      {
        Header: 'Yorum',
        accessor: 'comment',
        manualWidth: 150,
        maxWidth: 150,
        Filter: KrcEmptyFilter,
      },
    ]
  }, [])

  return {
    getPassiveProducts,
    setFilterSearchData,
    setSelectedPassiveProducts,
    activateSelectedPassiveProducts,
    setDataOriginals,
    createPassiveProducts,
    getExportPassiveProductsUrl,
    exportPassiveProducts,
    changePage,
    changeLimit,
    setVisibleTextFilter,
    visibleTextFilter,
    handleSearch,
    filterSearchData,
    loading,
    data,
    dataPaginationInfo,
    selectedPassiveProducts,
    downloadExcelUrl,
    columns,
    meta,
    unSelectAllItems,
  }
}
