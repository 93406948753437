import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {GameCardContext, GameCardProvider} from '../../../../providers/games/GameCardProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {GameCardList} from './GameCardList'

const GameCardListWrapperToolbar: FC = () => {
  const {setGameCardDrawerVisible} = useContext(GameCardContext)

  return (
    <>
      <div className='d-flex align-items-center py-1'>
        <KrcButton
          size={'middle'}
          onClick={() => {
            setGameCardDrawerVisible(true)
          }}
          id='kt_toolbar_primary_button'
        >
          {'Oyun Kartı Oluştur'}
        </KrcButton>
      </div>
    </>
  )
}

export const GameCardListWrapperWithoutProvider = () => {
  const {game} = useContext(GameCardContext)

  return (
    <>
      <Toolbar rightField={<GameCardListWrapperToolbar />}></Toolbar>
      <PageTitle>
        Karaca Oyun Kartları - {game ? `(ID: ${game.game_id}) ${game.game_name}` : '...'}
      </PageTitle>
      <GameCardList />
    </>
  )
}

export const GameCardListWrapper = () => {
  return (
    <>
      <GameCardProvider>
        <GameCardListWrapperWithoutProvider />
      </GameCardProvider>
    </>
  )
}
