import {SelectProps} from 'antd'
import {FC, useEffect} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useCustomerGroupTypeFilter} from '../../hooks/customer/useCustomerGroupTypeFilter'
import {ICustomerGroupsTypeFilter} from '../../models/customer/CustomerGroupsFilterModel'

const customerGroupFilterConfig = {
  default: {
    style: {width: '100%'},
    mode: undefined,
    size: 'middle',
    placeholder: 'Müşteri Grubu',
  },
}

export const CustomerGroupTypeFilter: FC<ICustomerGroupsTypeFilter & SelectProps> = ({
  onSelected,
  children,
  clear,
  ...props
}) => {
  const currentProps = useKrcProp({
    config: customerGroupFilterConfig.default,
    props,
  }).getCurrentProps()

  const {handleOnChange, options, customerGroupTypeId} = useCustomerGroupTypeFilter()

  useEffect(() => {
    if (!customerGroupTypeId) {
      return
    }
    onSelected(customerGroupTypeId)
  }, [customerGroupTypeId])

  return (
    <>
      <KrcSelect
        {...currentProps}
        showArrow
        allowClear
        mode={undefined}
        style={{width: 160}}
        onChange={handleOnChange}
        className={'ant-selector-border'}
        options={options}
      />
    </>
  )
}
