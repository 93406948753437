import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import * as Yup from 'yup'

import {MANUFACTURER_STORES} from '../../../enums/ManufacturerStoresEnum'
import {ManufacturerContext} from '../../../providers/ManufacturerProvider'
import {FileManagerModal} from '../../../service/FileManager/FileManagerModal'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {ICreateManufacturerModal} from '../../models/manufacturers/ManufacturerStoreModel'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'
import {KrcModal} from '../KrcModal'

const initValues = {
  name: '',
  meta_title: '',
  meta_description: '',
  description: '',
  seo_text: '',
  keyword: '',
  display_seo_text: 1,
  store_ids: [],
  sort_order: 0,
  status: 1,
  is_followable: 1,
  image: '',
}

const createManufacturerSchema = Yup.object({
  name: Yup.string().required('İsim alanı zorunludur'),
  meta_title: Yup.string().nullable(),
  meta_description: Yup.string().nullable(),
  description: Yup.string().nullable(),
  seo_text: Yup.string().nullable(),
  keyword: Yup.string().nullable(),
  display_seo_text: Yup.number().required('Seo metni görüntüleme alanı zorunludur'),
  store_ids: Yup.array().nullable(),
  sort_order: Yup.number().required('Sıralama alanı zorunludur'),
  status: Yup.number().required('Yayın durumu alanı zorunludur'),
  is_followable: Yup.number().required('Takip durumu alanı zorunludur'),
  followable_stores: Yup.array().nullable(),
  image: Yup.string().nullable(),
})

export const ManufacturerCreateModal: FC<ICreateManufacturerModal> = ({onHide}) => {
  const {storeManufacturer} = useContext(ManufacturerContext)
  const [initialValues, setInitialValues] = useState<any>(initValues)
  const [storeIds, setStoreIds] = useState<number[]>([])
  const [image, setImage] = useState<string>('')

  useEffect(() => {
    setInitialValues(initValues)
  }, [])

  const handleOnSubmit = (data: FormikValues) => {
    const requestBody = {
      ...data,
      store_ids: storeIds,
      image: image,
      is_followable: Number(data.is_followable),
      display_seo_text: Number(data.display_seo_text),
      status: Number(data.status),
    }
    storeManufacturer(requestBody)
  }

  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
        }}
        modalHeaderProps={{
          title: 'Yeni Üretici Marka (Manufacturer) Oluştur',
          onHide,
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10'>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={createManufacturerSchema}
            validateOnMount={true}
          >
            {() => {
              return (
                <Form className='form mt-6 space-between'>
                  <div className={'row mb-3'}>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'name'}
                        labelTitle={'Manufacturer Adı'}
                        required
                        tooltipProps={{
                          status: true,
                          text: 'Manufacturer isim değerini giriniz.',
                        }}
                      />
                    </div>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'meta_title'}
                        labelTitle={'Meta Başlık'}
                        tooltipProps={{
                          status: true,
                          text: 'Meta başlık değerini giriniz.',
                        }}
                      />
                    </div>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'meta_description'}
                        labelTitle={'Meta Açıklaması'}
                        tooltipProps={{
                          status: true,
                          text: 'Meta açıklama değerini giriniz.',
                        }}
                      />
                    </div>
                  </div>
                  <div className={'row mb-3'}>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'description'}
                        labelTitle={'Açıklama'}
                        required
                        tooltipProps={{
                          status: true,
                          text: 'Açıklama değerini giriniz.',
                        }}
                      />
                    </div>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'seo_text'}
                        labelTitle={'SEO Yazısı'}
                        tooltipProps={{
                          status: true,
                          text: 'SEO Yazısı değerini giriniz.',
                        }}
                      />
                    </div>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'keyword'}
                        labelTitle={'SEO Kelimesi'}
                        tooltipProps={{
                          status: true,
                          text: 'SEO Kelimesi değerini giriniz.',
                        }}
                      />
                    </div>
                  </div>
                  <div className={'row mb-3'}>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2'}
                        name={'display_seo_text'}
                        labelTitle={'SEO Yazısı Durumu'}
                        required
                        tooltipProps={{
                          status: true,
                          text: 'SEO Yazısı Durumunu Seçiniz.',
                        }}
                      >
                        <Field as='select' name='display_seo_text' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Aktif</option>
                          <option value={0}>Pasif</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'number'}
                        name={'sort_order'}
                        labelTitle={'Sıralama'}
                        tooltipProps={{
                          status: true,
                          text: 'Sıralama değerini giriniz.',
                        }}
                      />
                    </div>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2 z-index-9999'}
                        name={'store_ids'}
                        labelTitle={'İlgili Mağazalar'}
                        tooltipProps={{
                          status: true,
                          text: 'İlgili mağazaları seçin.',
                        }}
                      >
                        <KrcSelect
                          maxTagCount='responsive'
                          mode={'multiple'}
                          placeholder={'Seçiniz'}
                          size={'large'}
                          dropdownStyle={{zIndex: 9999}}
                          className={'ant-selector-border custom-ant-select-selection-placeholder'}
                          options={MANUFACTURER_STORES.map((store) => {
                            return {
                              value: store.value,
                              label: store.label,
                            }
                          })}
                          onChange={(storeId) => {
                            if (!storeId) {
                              return
                            }
                            setStoreIds(storeId)
                          }}
                          style={{width: '100%'}}
                        />
                      </KrcFormikField>
                    </div>
                  </div>
                  <div className={'row mb-3'}>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2'}
                        name={'status'}
                        labelTitle={'Durum'}
                        required
                        tooltipProps={{
                          status: true,
                          text: 'Durum Değerini Seçiniz',
                        }}
                      >
                        <Field as='select' name='status' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Aktif</option>
                          <option value={0}>Pasif</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2'}
                        name={'is_followable'}
                        labelTitle={'Takip Edilebilir Mi?'}
                        required
                        tooltipProps={{
                          status: true,
                          text: 'SEO Yazısı Durumunu Seçiniz.',
                        }}
                      >
                        <Field as='select' name='is_followable' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Aktif</option>
                          <option value={0}>Pasif</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                    <div className={'col-4'}>
                      <KrcFormikField
                        className={'mb-2 mt-2'}
                        name={'image'}
                        labelTitle={'Görsel Seçiniz'}
                      >
                        <FileManagerModal
                          onSelected={(link) => {
                            setImage(link ? link : '')
                          }}
                        />
                      </KrcFormikField>
                    </div>
                  </div>
                  <div className={'d-flex justify-content-end mt-7'}>
                    <button
                      type='button'
                      className='btn btn-secondary'
                      onClick={onHide}
                      style={{marginLeft: 10}}
                    >
                      İptal Et
                    </button>
                    <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                      Kaydet
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
