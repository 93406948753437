export const BADGE_LOCATION_INVISIBLE_ID = 0
export const BADGE_LOCATION_A_ID = 1
export const BADGE_LOCATION_B_ID = 2
export const BADGE_LOCATION_C_ID = 3
export const BADGE_LOCATION_D_ID = 4
export const BADGE_LOCATION_E_ID = 6

export const BADGE_LOCATIONS = [
  {
    badge_location_id: BADGE_LOCATION_A_ID,
    badge_location_name: 'A',
  },
  {
    badge_location_id: BADGE_LOCATION_B_ID,
    badge_location_name: 'B',
  },
  {
    badge_location_id: BADGE_LOCATION_C_ID,
    badge_location_name: 'C',
  },
  {
    badge_location_id: BADGE_LOCATION_D_ID,
    badge_location_name: 'D',
  },
  {
    badge_location_id: BADGE_LOCATION_E_ID,
    badge_location_name: 'E',
  },
  {
    badge_location_id: BADGE_LOCATION_INVISIBLE_ID,
    badge_location_name: 'Gürünmez',
  },
]
