import debounce from 'lodash/debounce'
import {OptionData} from 'rc-select/lib/interface'
import React, {useEffect, useState} from 'react'

import {getCouponPrefixesRequest} from '../../../requests/campaign_and_coupon/CouponPrefixRequests'
import {ICouponPrefix} from '../../models/campaign_and_coupon/CouponPrefixFilterModel'

export const useCouponPrefixFilter = ({debounceTime = 500, initialLoad = false}) => {
  const [options, setOptions] = useState<OptionData[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [couponPrefixIds, setCouponPrefixIds] = useState<number[] | number>([])
  const [selectedItems, setSelectedItems] = useState<OptionData[] | OptionData>([])
  const fetchRef = React.useRef(0)

  const handleSearch = React.useMemo(() => {
    const loadOptions = (search: string) => {
      searchCouponPrefix(search)
    }
    return debounce(loadOptions, debounceTime)
  }, [])

  const searchCouponPrefix = (search: string, initial = false) => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    setOptions([])
    setFetching(true)
    getCouponPrefixesRequest({name: search}, {page: 1, limit: 10}, 1).then((res) => {
      if (fetchId !== fetchRef.current) {
        return
      }
      const couponPrefixes: OptionData[] = res.data.data.map((couponPrefix: ICouponPrefix) => {
        return {value: couponPrefix.id, label: couponPrefix.prefix}
      })
      setOptions(couponPrefixes)
      setFetching(false)
    })
  }

  const handleOnChange = (payload: Array<OptionData> | OptionData) => {
    if (!payload) {
      setSelectedItems([])
      setCouponPrefixIds([])
      return
    }
    setSelectedItems(payload)
    if (Array.isArray(payload)) {
      const couponPrefixIds = payload.map((data: OptionData) => data.value) as number[]
      setCouponPrefixIds(couponPrefixIds)
    } else {
      const couponPrefixId = payload.value as number
      setCouponPrefixIds(couponPrefixId)
    }
  }

  useEffect(() => {
    if (initialLoad) {
      searchCouponPrefix(' ', true)
    }
  }, [])

  return {
    options,
    fetching,
    handleSearch,
    handleOnChange,
    selectedItems,
    couponPrefixIds,
  }
}
