import {Button, Empty} from 'antd'
import React, {FC, useContext} from 'react'

import {FooterModulesContext} from '../../../../../providers/FooterModulesProvider'
import {FooterModuleNewSubModuleModal} from '../../../../modals/footer/FooterModuleNewSubModuleModal'
import {IUpdateFooterModuleSubModule} from '../../../../models/footer/StoreModulesModel'
import {FooterModuleSubModuleTable} from './FooterModuleSubModuleTable'

type FooterSubModuleTabPropsType = {
  selectedModuleId: number
}
export const FooterSubModuleTab: FC<FooterSubModuleTabPropsType> = ({
  selectedModuleId,
}: FooterSubModuleTabPropsType) => {
  const {
    selectedModuleInformation,
    selectedModuleSubModules,
    saveSubModules,
    visibleCreateSubModal,
    setVisibleCreateSubModal,
  } = useContext(FooterModulesContext)

  const handleSave = (modifiedData: IUpdateFooterModuleSubModule) => {
    if (!selectedModuleInformation) return
    saveSubModules(selectedModuleInformation?.module_id, modifiedData)
  }

  return (
    <>
      {selectedModuleInformation &&
      selectedModuleSubModules &&
      selectedModuleSubModules.length > 0 ? (
        <FooterModuleSubModuleTable data={selectedModuleSubModules} onSave={handleSave} />
      ) : (
        <Empty
          image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
          imageStyle={{
            height: 60,
          }}
          description={
            <span>{selectedModuleInformation?.module_name} mağazası için başlık bulunamadı.</span>
          }
        >
          <Button type='primary' onClick={() => setVisibleCreateSubModal(true)}>
            Şimdi Başlık Oluştur
          </Button>
        </Empty>
      )}
      {visibleCreateSubModal && (
        <FooterModuleNewSubModuleModal onHide={() => setVisibleCreateSubModal(false)} />
      )}
    </>
  )
}
