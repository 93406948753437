import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  ProductListContext,
  ProductListProvider,
} from '../../../../providers/products/ProductListProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {ProductList} from './ProductList'

const ProductListWrapperWithoutProvider: FC = () => {
  const {setIsShowStoreDrawer} = useContext(ProductListContext)
  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <KrcButton
            size={'middle'}
            onClick={() => setIsShowStoreDrawer(true)}
            className={'mr-3'}
            id='kt_toolbar_primary_button'
          >
            Toplu Store İşlemleri
          </KrcButton>
        </div>
      </Toolbar>
      <PageTitle>Ürün Listesi</PageTitle>
      <ProductList />
    </>
  )
}

export const ProductListWrapper: FC = () => {
  return (
    <ProductListProvider>
      <ProductListWrapperWithoutProvider />
    </ProductListProvider>
  )
}
