import React, {Suspense} from 'react'
import {useSelector} from 'react-redux'
import {Navigate, Route, Routes} from 'react-router-dom'

import {FallbackView} from '../../_metronic/partials'
import {BadgeRoutes} from './BadgeRoutes'
import {BannerRoutes} from './BannerRoutes'
import {CampaignAndCouponRoutes} from './CampaignAndCouponRoutes'
import {CategoryRoutes} from './CategoryRoutes'
import {DealRoutes} from './DealRoutes'
import {FaqRoutes} from './FaqRoutes'
import {FooterRoutes} from './FooterRoutes'
import {GameRoutes} from './GameRoutes'
import {IBMRoutes} from './IBMRoutes'
import {InformationRoutes} from './InformationRoutes'
import {LandingRoutes} from './LandingRoutes'
import {LayoutRoutes} from './LayoutRoutes'
import {ManufacturerRoutes} from './ManufacturerRoutes'
import {MenuRoutes} from './MenuRoutes'
import {OrderRoutes} from './OrderRoutes'
import {PermissionRoutes} from './PermissionRoutes'
import {ProductRoutes} from './ProductRoutes'
import {ReviewRoutes} from './ReviewRoutes'
import {RoleRoutes} from './RoleRoutes'
import {ScoreBoardRoutes} from './ScoreBoardRoutes'
import {ShoppingListRoutes} from './ShoppingListRoutes'
import {UrlAliasRoutes} from './UrlAliasRoutes'
import {UserRoutes} from './UserRoutes'

export function PrivateRoutes() {
  const {user} = useSelector((state: any) => state.auth)

  return (
    <Suspense fallback={<FallbackView />}>
      <Routes>
        {user?.data?.can_view_game && <Route path='/games/*' element={<GameRoutes />} />}
        <Route path='/scoreboard/*' element={<ScoreBoardRoutes />} />
        <Route path='/layouts/*' element={<LayoutRoutes />} />
        <Route path='/banners/*' element={<BannerRoutes />} />
        <Route path='/badges/*' element={<BadgeRoutes />} />
        <Route path='/products/*' element={<ProductRoutes />} />
        <Route path='/categories/*' element={<CategoryRoutes />} />
        <Route path='/landings/*' element={<LandingRoutes />} />
        <Route path='/deals/*' element={<DealRoutes />} />
        <Route path='/menus/*' element={<MenuRoutes />} />
        <Route path='/footer/*' element={<FooterRoutes />} />
        <Route path='/manufacturer/*' element={<ManufacturerRoutes />} />
        <Route path='/url/aliases/*' element={<UrlAliasRoutes />} />
        <Route path='/coupon/*' element={<CampaignAndCouponRoutes />}></Route>
        {user?.data?.can_view_order_questioning && (
          <Route path='/orders/*' element={<OrderRoutes />} />
        )}
        <Route path='/ibm/*' element={<IBMRoutes />}></Route>
        <Route path='/faq/*' element={<FaqRoutes />}></Route>
        <Route path='/information/*' element={<InformationRoutes />}></Route>
        <Route path='/users/*' element={<UserRoutes />}></Route>
        <Route path='/roles/*' element={<RoleRoutes />}></Route>
        <Route path='/reviews/*' element={<ReviewRoutes />}></Route>
        <Route path='/permissions/*' element={<PermissionRoutes />}></Route>
        <Route path='/shopping-lists/*' element={<ShoppingListRoutes />}></Route>
        <Route path={'/'} element={<Navigate to='/badges' replace />}></Route>
        <Route path={'*'} element={<Navigate to='error/404' />}></Route>
      </Routes>
    </Suspense>
  )
}
