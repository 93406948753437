import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {Popconfirm, Tooltip} from 'antd'
import moment from 'moment'
import React, {FC, useContext, useEffect, useRef} from 'react'
import {Cell} from 'react-table'

import {
  DEAL_PRODUCT_ACTIVE,
  DEAL_PRODUCT_PENDING_APPROVAL,
  DEAL_PRODUCT_WILL_BE_ACTIVE,
} from '../../../../../enums/DealProductEnum'
import {DealContext} from '../../../../../providers/DealProvider'
import {requestDateFormat} from '../../../../../service/DateService'
import {KrcEmptyFilter} from '../../../../atoms/KrcEmptyFilter'
import {KrcRangePicker} from '../../../../atoms/forms/KrcRangePicker'
import {DealProductDrawer} from '../../../../drawers/DealProductDrawer'
import {DealProductModal} from '../../../../modals/deals/DealProductModal'
import {badgeListDataType} from '../../../../models/badges/BadgeModel'
import {IDealProduct, dealProductCampaignsType} from '../../../../models/deals/DealModel'
import {KrcTable} from '../../../../organisms/tables/KrcTable'

export const DealProduct: FC<IDealProduct> = ({pageType}) => {
  const {
    visibleDealProductModal,
    dealProducts,
    getDealProducts,
    dealId,
    setSortedDealProductIds,
    updateDealProductSort,
    sortedDealProductIds,
    meta,
    updateMeta,
    setPageType,
    setFilter,
    filter,
    setWillBeUpdatedProduct,
    removeDealProduct,
    updateTableFilter,
  } = useContext(DealContext)
  const initialRef = useRef(0)

  const columns = React.useMemo(() => {
    let data = [
      {
        Header: 'Model',
        accessor: 'model',
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
        maxWidth: 100,
      },
      {
        Header: 'Ürün Fiyatı (kmiy)',
        accessor: 'price',
        maxWidth: 150,
        Cell: ({row}: any) => {
          return <div>{row.original.price}&nbsp;₺</div>
        },
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'İndirimli Fiyatı',
        accessor: 'special_price',
        maxWidth: 150,
        Cell: ({row}: any) => {
          if (!row.original.special_price) {
            return <div>-</div>
          }
          return <div>{row.original.special_price}&nbsp;₺</div>
        },
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Sepet İndirimli Fiyatı',
        accessor: 'cart_discount',
        maxWidth: 150,
        Cell: ({row}: any) => {
          if (!row.original.price_cart_discount) {
            return <div>-</div>
          }
          return <div>{row.original.price_cart_discount}&nbsp;₺</div>
        },
        Filter: KrcEmptyFilter,
      },
      {
        Header: 'Başlangıç ve Bitiş Tarihi',
        accessor: 'dateStart',
        Filter: () => {
          return (
            <KrcRangePicker
              size={'middle'}
              style={{borderRadius: 5}}
              onSelected={(value) => {
                if (initialRef.current === 0) {
                  return
                }

                if (!value) {
                  updateTableFilter(undefined, 'date')
                  return
                }

                updateTableFilter(
                  [
                    requestDateFormat(value[0], 'YYYY-MM-DD HH:mm'),
                    requestDateFormat(value[1], 'YYYY-MM-DD HH:mm'),
                  ],
                  'date'
                )
              }}
              showTime
              format={'YYYY.MM.DD HH:mm'}
            />
          )
        },
        Cell: ({row}: Cell<any>) => {
          return (
            <div>
              <span>{row.original.dateStart}</span>
              <br />
              <span>{row.original.dateEnd}</span>
            </div>
          )
        },
      },
      {
        Header: 'Sıralama',
        accessor: 'sort_order',
        Filter: <></>,
      },
      {
        Header: 'Stok Bilgisi',
        maxWidth: 150,
        Cell: ({row}: Cell<any>) => (
          <>
            <div>Mevcut&nbsp;Stok:&nbsp;{row.original.quantity}</div>
            <div>Kampanya&nbsp;Stoku:&nbsp;{row.original.campaignQuantity ?? '-'}</div>
            <div>Satış&nbsp;Adeti:&nbsp;{row.original.campaignSaleQuantity}</div>
            <div>Kalan&nbsp;kampanya&nbsp;Stoku:&nbsp;{row.original.campaignRemainingQuantity}</div>
          </>
        ),
      },
    ]

    if (
      pageType === DEAL_PRODUCT_ACTIVE ||
      pageType === DEAL_PRODUCT_PENDING_APPROVAL ||
      pageType === DEAL_PRODUCT_WILL_BE_ACTIVE
    ) {
      // @ts-ignore
      data.push({
        Header: 'Aksiyonlar',
        accessor: 'action',
        Cell: ({row}: Cell<any>) => (
          <div className={'d-flex flex-row'}>
            <Tooltip title={'Güncelle'}>
              <div
                onClick={(e) => {
                  e.preventDefault()
                  if (!setWillBeUpdatedProduct) {
                    return
                  }

                  setWillBeUpdatedProduct({
                    id: row.original.id,
                    product_id: row.original.product_id,
                    date_start: moment(row.original.rawStartDate).format('YYYY-MM-DD HH:mm'),
                    date_end: moment(row.original.rawEndDate).format('YYYY-MM-DD HH:mm'),
                    rawStartDate: row.original.rawStartDate,
                    rawEndDate: row.original.rawEndDate,
                    anotherLink: row.original.anotherLink ?? '',
                    anotherTitle: row.original.anotherTitle ?? '',
                    anotherImage: row.original.anotherImage ?? '',
                    fullAnotherImage: row.original.fullAnotherImage ?? '',
                    anotherButtonText: row.original.anotherButtonText ?? '',
                    behaveLikeBoutique: row.original.behaveLikeBoutique ?? false,
                    quantity: row.original.campaignQuantity ?? null,
                    badgeList: row.original.badgeList.map((item: badgeListDataType) => {
                      return {value: item.badge_id, label: item.badge?.name}
                    }),
                    campaigns: row.original.campaigns.map((item: dealProductCampaignsType) => {
                      return {value: item.category.category_id, label: item.category.details?.name}
                    }),
                  })
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
              >
                <EditOutlined />
              </div>
            </Tooltip>

            <Tooltip title={'Sil'}>
              <Popconfirm
                title='Bu ürünü fırsattan çıkartmak istediğinden emin misin ?'
                onConfirm={(e) => removeDealProduct(row.original.id)}
                okText='Evet'
                cancelText='Hayır'
              >
                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                  <DeleteOutlined />
                </div>
              </Popconfirm>
            </Tooltip>
          </div>
        ),
        Filter: KrcEmptyFilter,
      })
    }

    return data
  }, [pageType])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      updateMeta({...meta, page: 1})
      return
    }

    getDealProducts(pageType)
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getDealProducts(pageType)
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0 || filter === undefined) {
      return
    }

    getDealProducts(pageType)
  }, [filter])

  useEffect(() => {
    setPageType(pageType)
    setFilter(undefined)
  }, [pageType])

  useEffect(() => {
    if (!dealId) {
      initialRef.current = 0
      return
    }

    initialRef.current = 1
    getDealProducts(pageType)
  }, [dealId])

  return (
    <>
      <div>
        <div>
          <KrcTable
            outerHeight={280}
            sortableProps={{
              isSortable: pageType === DEAL_PRODUCT_ACTIVE,
              onSortChange: (value) => {
                setSortedDealProductIds(
                  value.map((item: any) => {
                    return item.id
                  })
                )
              },
            }}
            paginationProps={{
              isPagination: pageType !== DEAL_PRODUCT_ACTIVE,
              paginationData: meta,
              page: meta.page,
              limit: meta.limit,
              onChangePage: (page: number) => {
                updateMeta({...meta, page})
              },
              onChangeLimit: (limit: number) => {
                updateMeta({...meta, limit})
              },
            }}
            searchableProps={{
              isSearchable: true,
              onSearch: updateTableFilter,
            }}
            columns={columns}
            data={dealProducts}
          />
          {pageType === DEAL_PRODUCT_ACTIVE && (
            <div className={'d-flex flex-end'}>
              <button
                type='submit'
                className='btn btn-primary'
                style={{marginLeft: 'auto'}}
                onClick={updateDealProductSort}
                disabled={dealProducts.length === 0 || sortedDealProductIds.length === 0}
              >
                Sıralamayı Kaydet
              </button>
            </div>
          )}
        </div>
        {visibleDealProductModal && <DealProductModal />}
        <DealProductDrawer />
      </div>
    </>
  )
}
