import cn from 'classnames'
import React, {FC, useContext, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  IBMCampaignContext,
  IBMCampaignProvider,
} from '../../../../providers/ibm/IBMCampaignProvider'
import {getIBMCampaignsExportFrontendUrl} from '../../../../requests/ibm/IBMCampaignRequest'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {IBMCampaignCreateDrawer} from '../../../drawers/IBM/IBMCampaignCreateDrawer'
import {IBMCampaignSortModal} from '../../../modals/ibm/sort/IBMCampaignSortModal'
import {IBmCampaignUrlType} from '../../../models/ibm/IBMCampaignModel'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {IBMCampaignList} from './IBMCampaignList'

const IBMCampaignTabWrapperWithoutProvider: FC = () => {
  const {
    setSelectedGroupId,
    getIBMCampaigns,
    isShowSortingCampaignModal,
    setIsShowSortingCampaignModal,
    isShowCreateCampaignDrawer,
    setIsShowCreateCampaignDrawer,
    selectedGroupId,
    selectedGroupTitle,
    getIBMGroupInformationById,
  } = useContext(IBMCampaignContext)

  const {groupId} = useParams<IBmCampaignUrlType>()

  useEffect(() => {
    if (groupId) {
      setSelectedGroupId(Number(groupId))
      getIBMGroupInformationById(Number(groupId))
      getIBMCampaigns(null, Number(groupId))
    }
  }, [groupId])
  return (
    <>
      <Toolbar
        rightField={
          <div className='d-flex align-items-center py-1'>
            <a
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-3'
              href={getIBMCampaignsExportFrontendUrl(selectedGroupId)}
              target={'_blank'}
              rel='noreferrer'
            >
              Kampanyaları Dışarı Aktar
            </a>
            <KrcButton
              size={'middle'}
              className={'me-4'}
              type={'default'}
              onClick={() => {
                setIsShowSortingCampaignModal(true)
              }}
              id='kt_toolbar_primary_button'
            >
              {'Kampanyaları Sırala'}
            </KrcButton>
            <Link
              to={`/ibm/campaign/create/${groupId}`}
              className={cn({'btn btn-sm btn-primary me-4': true})}
              id='kt_toolbar_primary_button'
            >
              Kampanya Oluştur
            </Link>
          </div>
        }
      ></Toolbar>
      <PageTitle>{selectedGroupTitle} - Kampanya Listesi </PageTitle>
      <IBMCampaignList />
      {isShowSortingCampaignModal && (
        <IBMCampaignSortModal onHide={() => setIsShowSortingCampaignModal(false)} />
      )}
      <IBMCampaignCreateDrawer
        visible={isShowCreateCampaignDrawer}
        onHide={() => setIsShowCreateCampaignDrawer(false)}
      />
    </>
  )
}

export const IBMCampaignListWrapper: FC = () => {
  return (
    <>
      <IBMCampaignProvider>
        <IBMCampaignTabWrapperWithoutProvider />
      </IBMCampaignProvider>
    </>
  )
}
