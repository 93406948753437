import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''

export const CATEGORY_BANNER_DESCRIPTON_URL = `${API_URL}/categories`

export function getCategoryBannerDescriptionRequest(categoryId: number) {
  return axios.get(`${CATEGORY_BANNER_DESCRIPTON_URL}/${categoryId}/banner-description`)
}

export function updateCategoryBannerDescriptionRequest(categoryId: number, body: any) {
  return axios.post(`${CATEGORY_BANNER_DESCRIPTON_URL}/${categoryId}/banner-description`, body)
}
