import React, {useContext} from 'react'

import {GameContext} from '../../../providers/games/GameProvider'
import {CreateOrUpdateGameDrawer} from '../../drawers/games/CreateOrUpdateGameDrawer'
import {GameSettingDrawer} from '../../drawers/games/GameSettingDrawer'
import {gameType} from '../../models/games/GameModel'
import {KrcTable} from '../../organisms/tables/KrcTable'

export const GameList = () => {
  const {columns, games, setWillBeSortedGameIds, willBeSortedGameIds, updateSortGame} =
    useContext(GameContext)

  return (
    <>
      <KrcTable
        columns={columns}
        data={games}
        outerHeight={210}
        sortableProps={{
          isSortable: true,
          onSortChange: (games) => {
            const ids = games.map((game: gameType) => game.game_id)
            setWillBeSortedGameIds(ids)
          },
        }}
      />
      <div className={'d-flex flex-end'}>
        <button
          type='submit'
          className='btn btn-primary'
          style={{marginLeft: 'auto'}}
          onClick={updateSortGame}
          disabled={willBeSortedGameIds.length === 0}
        >
          Sıralamayı Kaydet
        </button>
      </div>
      <CreateOrUpdateGameDrawer />
      <GameSettingDrawer />
    </>
  )
}
