import axios from 'axios'

import {layoutUpdateDataType} from '../../components/models/layouts/LayoutModel'

const API_URL = process.env.REACT_APP_API_URL || ''
export const PAGE_URL = `${API_URL}/layouts/pages`

export const getPageRequest = (pageId: number) => {
  return axios.get(`${PAGE_URL}/${pageId}`)
}

export const getPagesRequest = (storeId: number) => {
  return axios.get(`${PAGE_URL}`, {
    params: {
      store_id: storeId,
    },
  })
}

export const createPageRequest = (body: {name: string; store_id: number}) => {
  return axios.post(`${PAGE_URL}`, body)
}

export const updatePageRequest = (pageId: number, body: layoutUpdateDataType) => {
  return axios.put(`${PAGE_URL}/${pageId}`, body)
}

export const copyPageRequest = (pageId: number, body: {name: string}) => {
  return axios.post(`${PAGE_URL}/${pageId}/copy`, body)
}
