export const ReviewStatusType = {
  WAITING: 0,
  APPROVED: 1,
  ARCHIVE: 2,
} as {WAITING: 0; APPROVED: 1; ARCHIVE: 2}

export const ReviewStatusTypeLabel = {
  [ReviewStatusType.WAITING]: 'Onay Bekleniyor',
  [ReviewStatusType.APPROVED]: 'Onaylandı',
  [ReviewStatusType.ARCHIVE]: 'Arsivlenmiş',
}
