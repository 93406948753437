import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {ManufacturerListWrapper} from '../components/pages/manufacturer/ManufacturerListWrapper'
import {ManufacturerUpdateWrapper} from '../components/pages/manufacturer/ManufacturerUpdateWrapper'

export const ManufacturerRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<ManufacturerListWrapper />} />
        <Route path={'/:manufacturerId/update'} element={<ManufacturerUpdateWrapper />} />
      </Routes>
    </>
  )
}
