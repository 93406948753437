import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {Badge, Popconfirm, Tooltip, message} from 'antd'
import React, {useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Cell, Column} from 'react-table'

import {usePagination} from '../../components/hooks/pagination/usePagination'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {removeEmptyItemInObject} from '../../helpers/ObjectHelper'
import {CouponFiltersDataType} from '../../modules/partials/components/filter/models/CouponFilterModel'
import {
  deleteCouponRequest,
  getCouponFilterRequest,
} from '../../requests/campaign_and_coupon/CouponRequest'

interface ICouponListContext {
  data: any[]
  setData: React.Dispatch<React.SetStateAction<any[]>>
  columns: Column<any>[]
  meta: paginationMetaType
  recentFiltersOfResults?: CouponFiltersDataType
  isFilterLoading: boolean
  clear: number
  setClear: React.Dispatch<React.SetStateAction<number>>
  isDataEmptyAfterFilter: boolean
  updateFilter: (filter: object) => void
  changePage: (page: number) => void
  changeLimit: (limit: number) => void
  filters: CouponFiltersDataType
  getCouponFilter: (page?: null | number) => void
  setIsDataEmptyAfterFilter: React.Dispatch<React.SetStateAction<boolean>>
}

export const CouponListContext = React.createContext<ICouponListContext>({
  data: [],
  setData: () => {},
  columns: [],
  meta: {limit: 10, page: 1},
  recentFiltersOfResults: undefined,
  isFilterLoading: false,
  clear: 0,
  setClear: () => {},
  isDataEmptyAfterFilter: false,
  updateFilter: () => {},
  changePage: () => {},
  getCouponFilter: () => {},
  changeLimit: () => {},
  setIsDataEmptyAfterFilter: () => {},
  filters: {
    name: '',
    code: '',
    status: null,
  },
})

export const CouponListProvider: React.FC = ({children}) => {
  const [filters, setFilters] = useState<CouponFiltersDataType>({
    name: '',
    code: '',
    status: null,
  })
  const [recentFiltersOfResults, setRecentFiltersOfResults] = useState<CouponFiltersDataType>()
  const [data, setData] = useState<any[]>([])
  const fetchRef = useRef(0)
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false)
  const [clear, setClear] = React.useState(0)
  const [isDataEmptyAfterFilter, setIsDataEmptyAfterFilter] = React.useState<boolean>(false)

  const updateFilter = (filter: object) => {
    setFilters((filters: CouponFiltersDataType | undefined) => {
      return {...filters, ...filter}
    })
  }

  const getCouponFilter = (page: null | number = null) => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    let _meta = meta
    setIsFilterLoading(true)
    setRecentFiltersOfResults(filters)
    if (page) {
      _meta.page = page
    }

    const _filters = removeEmptyItemInObject({
      ...filters,
    })

    const isFilterObjectEmpty = Object.keys(_filters).length === 0
    if (isFilterObjectEmpty) {
      message.error('Filtreleme yapabilmek için en az bir alanı doldurmalısınız.')
      setIsFilterLoading(false)
      return
    }
    getCouponFilterRequest(_meta, _filters)
      .then((response) => {
        if (fetchId !== fetchRef.current) {
          return
        }

        setData(response.data.data)
        const metaData = response.data.meta
        updateMetaData({limit: metaData.per_page, page: metaData.current_page, ...metaData})
        setIsFilterLoading(false)
        if (response.data.data.length === 0) {
          setIsDataEmptyAfterFilter(true)
        } else {
          setIsDataEmptyAfterFilter(false)
        }
      })
      .catch((error) => {
        setIsFilterLoading(false)
      })
  }

  const {meta, updateMetaData, changePage, changeLimit} = usePagination({
    callback: getCouponFilter,
  })

  const navigate = useNavigate()

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Kupon Adı',
        accessor: 'name',
        manualWidth: 400,
        maxWidth: 400,
        Cell: ({row}: any) => {
          return (
            <div style={{width: 380}}>
              <div>{row.original.name}</div>
            </div>
          )
        },
      },
      {
        Header: 'Kupon Kodu',
        accessor: 'code',
        manualWidth: 150,
        maxWidth: 150,
      },
      {
        Header: 'İndirim Oranı',
        accessor: 'discount',
        manualWidth: 120,
        maxWidth: 120,
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'date_start',
        manualWidth: 200,
        maxWidth: 200,
        Cell: ({row}: any) => {
          return new Date(row.original.date_start).toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        },
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'date_end',
        manualWidth: 200,
        maxWidth: 200,
        Cell: ({row}: any) => {
          return new Date(row.original.date_end).toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        },
      },
      {
        Header: 'Durum',
        accessor: 'status',
        manualWidth: 120,
        maxWidth: 120,
        Cell: ({row}: any) => {
          return row.original.status ? (
            <div>
              Aktif <Badge className={'ml-2'} color='green' />
            </div>
          ) : (
            <div>
              Pasif <Badge className={'ml-2'} color='red' />
            </div>
          )
        },
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        manualWidth: 100,
        maxWidth: 100,
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row justify-content-around gap-2'>
              <Tooltip title={'Düzenle'}>
                <div
                  onClick={() => {
                    navigate(`/coupon/${row.original.coupon_id}`)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  <EditOutlined />
                </div>
              </Tooltip>
              <Tooltip title={'Sil'}>
                <Popconfirm
                  title={'Bu kuponu silmek istediğinden emin misiniz?'}
                  onConfirm={() => deleteCoupon(row.original.coupon_id)}
                  okText='Evet'
                  cancelText='Hayır'
                >
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <DeleteOutlined />
                  </div>
                </Popconfirm>
              </Tooltip>
            </div>
          </>
        ),
      },
    ]
  }, [])

  const deleteCoupon = (id: number) => {
    deleteCouponRequest(id)
      .then(() => {
        setData((prev) => {
          return prev.filter((item) => item.coupon_id !== id)
        })
        message.success('Kupon başarıyla silindi')
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  }

  return (
    <>
      <CouponListContext.Provider
        value={{
          data,
          filters,
          setData,
          columns,
          meta,
          changePage,
          changeLimit,
          updateFilter,
          isFilterLoading,
          recentFiltersOfResults,
          isDataEmptyAfterFilter,
          setIsDataEmptyAfterFilter,
          clear,
          setClear,
          getCouponFilter,
        }}
      >
        {children}
      </CouponListContext.Provider>
    </>
  )
}
