import debounce from 'lodash/debounce'
import React, {FC, createContext, useEffect, useMemo, useRef, useState} from 'react'
import {Column} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {categoryVerticalAdvancedFilterDataType} from '../../components/models/categories/CategoryListFilterModel'
import {filterCategoryType} from '../../components/models/categories/CategoryModel'
import {
  CategorySelectionType,
  categoryTextAreaFilterDataType,
} from '../../components/models/categories/CategorySelectionModel'
import {CategorySelectionEnum} from '../../enums/CategorySelectionEnum'
import {categorySelectionFilterRequest} from '../../requests/categories/CategoryRequest'

interface ICategorySelectionContext {
  data: any[]
  meta: paginationMetaType
  columns: Column<any>[]
  transferData: any[]
  type: CategorySelectionType
  excludedTempCategoryIds?: number[]
  setExcludedTempCategoryIds?: (data: number[]) => void
  setType?: (data: CategorySelectionType) => void
  tableFilter?: any
  transferIds?: number[]
  updateTransferData?: (data: any[]) => any
  updateMeta?: (data: object) => any
  updateData?: (data: any[]) => void
  getCategories?: () => void
  updateFilters?: (
    filters: categoryVerticalAdvancedFilterDataType | categoryTextAreaFilterDataType
  ) => void
  updateExcludedCategoryIds?: (data: number[]) => void
  updateTableFilter?: (data: any) => void
  selectedItems?: any[]
  setSelectedItems?: (data: any[]) => void
}

export const CategorySelectionContext = createContext<ICategorySelectionContext>({
  type: CategorySelectionEnum.TEXT,
  data: [],
  meta: {limit: 10, page: 1},
  transferData: [],
  transferIds: undefined,
  columns: [],
})

export const initialMetaData = {limit: 10, page: 1}

interface ICategorySelectionProvider {
  includedCategoryIds?: number[]
  onFilterChange?: (
    filters: categoryVerticalAdvancedFilterDataType | categoryTextAreaFilterDataType
  ) => any
  onFilterTypeChange?: (type: CategorySelectionType) => any
  onMetaChange?: (meta: paginationMetaType) => any
  filterCategoryIds?: number[]
}

export const CategorySelectionProvider: FC<ICategorySelectionProvider> = ({
  children,
  includedCategoryIds,
  onFilterChange,
  onFilterTypeChange,
  onMetaChange,
  filterCategoryIds,
}) => {
  const [data, setData] = useState<any[]>([])
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const fetchRef = useRef(0)
  const initialRef = useRef(0)
  const [transferData, setTransferData] = useState<any[]>([])
  const [transferIds, setTransferIds] = useState<number[]>()
  const [filters, setFilters] = useState<
    categoryVerticalAdvancedFilterDataType | categoryTextAreaFilterDataType
  >()
  const [excludedCategoryIds, setExcludedCategoryIds] = useState<number[]>([])
  const [excludedTempCategoryIds, setExcludedTempCategoryIds] = useState<number[]>()
  const [tableFilter, setTableFilter] = useState<any>()
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const [type, setType] = useState<CategorySelectionType>(CategorySelectionEnum.DEFAULT)

  const getCategories = () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current

    let categoryIdsFilter = (filters as categoryVerticalAdvancedFilterDataType)?.categoryIds

    if (filterCategoryIds) {
      if (categoryIdsFilter) {
        categoryIdsFilter = [...categoryIdsFilter, ...filterCategoryIds]
      } else {
        categoryIdsFilter = filterCategoryIds
      }
    }

    const currentFilters = {
      ...filters,
      categoryIds: categoryIdsFilter,
    }
    const _excludedCategoryIds = [
      ...excludedCategoryIds,
      ...(excludedTempCategoryIds ?? []),
    ].filter((eCategoryId) => {
      if (!includedCategoryIds) {
        return true
      }

      return !includedCategoryIds.some((categoryId) => categoryId === eCategoryId)
    })

    let relations = ['description']

    categorySelectionFilterRequest(
      currentFilters,
      meta,
      _excludedCategoryIds,
      tableFilter,
      relations
    ).then((res) => {
      if (fetchId !== fetchRef.current) {
        return
      }

      const categories = res.data.data.map((category: filterCategoryType) => {
        return {
          id: category.category_id,
          type: category.type,
          name: category.description?.name ?? '',
          status: category.status,
        }
      })

      updateData(categories)
      setMeta({limit: res.data.meta.per_page, page: res.data.meta.current_page, ...res.data.meta})
    })
  }

  const columns = React.useMemo(() => {
    let currentColumns = [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 120,
      },
      {
        Header: 'Kategori Adı',
        accessor: 'name',
      },
      {
        Header: 'Kategori Türü',
        accessor: 'type',
        maxWidth: 150,
        Filter: <KrcEmptyFilter />,
      },
      {
        Header: 'Durum',
        accessor: 'status',
        maxWidth: 150,
        Cell: ({row}: any) => <div>{row.values.status ? 'Aktif' : 'Pasif'}</div>,
        Filter: <KrcEmptyFilter />,
      },
    ]

    return currentColumns
  }, [])

  useEffect(() => {
    if (!includedCategoryIds || includedCategoryIds.length === 0) {
      return
    }

    setTransferData((data) => {
      const _data = data.filter((item) => {
        return !includedCategoryIds.some((id: number) => id === item.id)
      })

      setTransferIds(
        _data.map((item) => {
          return item.id
        })
      )

      return _data
    })
  }, [includedCategoryIds])

  useEffect(() => {
    if (initialRef.current < 2) {
      initialRef.current += 1
      return
    }
  }, [excludedTempCategoryIds])

  useEffect(() => {
    if (!tableFilter || Object.keys(tableFilter).length === 0) {
      return
    }

    setExcludedTempCategoryIds([])
    getCategories()
  }, [tableFilter])

  useEffect(() => {
    if (fetchRef.current === 0) {
      return
    }

    getCategories()
  }, [meta.page])

  useEffect(() => {
    if (fetchRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
    }

    getCategories()
  }, [meta.limit])

  useEffect(() => {
    if (!filters || Object.keys(filters).length === 0) {
      return
    }

    onFilterChange && onFilterChange(filters)

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      setExcludedTempCategoryIds([])
      return
    }

    getCategories()
    setExcludedTempCategoryIds([])
  }, [filters])

  useEffect(() => {
    onFilterTypeChange && onFilterTypeChange(type)
  }, [type])

  useEffect(() => {
    onMetaChange && onMetaChange(meta)
  }, [meta])

  const updateData = (payload: any[]) => {
    setData(payload)
  }

  const updateMeta = (data: object) => {
    setMeta((meta) => ({...meta, ...data}))
  }

  const updateTransferData = (data: any[]) => {
    setTransferData(data)
  }

  const updateFilters = (
    data: categoryVerticalAdvancedFilterDataType | categoryTextAreaFilterDataType
  ) => {
    setFilters(data)
  }

  const updateExcludedCategoryIds = (data: number[]) => {
    setExcludedCategoryIds(data)
  }

  const updateTableFilter = useMemo(() => {
    const update = (data: any) => {
      setTableFilter((filter: any) => ({...filter, ...data}))
    }

    return debounce(update, 1000)
  }, [])

  useEffect(() => {
    if (transferData.length === 0) {
      return
    }

    getCategories()
  }, [excludedCategoryIds])

  return (
    <CategorySelectionContext.Provider
      value={{
        type,
        data,
        meta,
        columns,
        tableFilter,
        transferIds,
        transferData,
        selectedItems,
        excludedTempCategoryIds,
        setExcludedTempCategoryIds,
        setType,
        setSelectedItems,
        updateMeta,
        getCategories,
        updateFilters,
        updateTableFilter,
        updateTransferData,
        updateExcludedCategoryIds,
        updateData,
      }}
    >
      {children}
    </CategorySelectionContext.Provider>
  )
}
