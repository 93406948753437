import axios from 'axios'
import * as querystring from 'query-string'

import {paginationMetaType} from '../../components/models/GeneralModel'
import {tableFilterSearchParamsType} from '../../components/models/categories/CategoryProductModel'
import {
  addBadgesToProductsRequestType,
  addProductVariantType,
  bulkUpdateCurrentDateOfProductsType,
  deleteBadgesToProductsRequestType,
  deleteProductVariantsType,
  productBadgeLogFilterType,
} from '../../components/models/products/ProductModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const PRODUCT_URL = `${API_URL}/products`

export function getProductRequest(
  productId: number | string,
  relation: string[] = [],
  relationParams: object = {}
) {
  return axios.get(`${PRODUCT_URL}/${productId}`, {
    params: {
      with: relation,
      ...relationParams,
    },
  })
}

export function getProducts(meta: paginationMetaType, relation: string[], fetchParam: any) {
  return axios.get(`${PRODUCT_URL}`, {
    params: {
      limit: meta.limit,
      page: meta.page,
      with: relation,
      product_name: fetchParam.productName,
      category_name: fetchParam.categoryName,
      price: fetchParam.price,
    },
  })
}

export function getProductsFilterRequest(
  meta: paginationMetaType,
  relation: string[],
  filters: object = {}
) {
  return axios.get(`${PRODUCT_URL}/filter`, {
    params: {
      limit: meta.limit,
      page: meta.page,
      with: relation,
      ...filters,
    },
  })
}

export function productSelectionFilterRequest(
  filter: any,
  meta: paginationMetaType,
  excludedProductIds: any = [],
  filterSearchData?: tableFilterSearchParamsType,
  relation: string[] = [],
  fullPrices: boolean = false
) {
  return axios.post(`${PRODUCT_URL}/filter`, {
    category_ids: filter.categoryIds,
    badge_ids: filter.badgeIds,
    badge_types: filter.badgeTypeIds,
    manufacturer_ids: filter.manufacturerIds,
    product_name: filterSearchData?.name || filter.productName,
    min_price: filter.price?.min,
    max_price: filter.price?.max,
    product_id: filterSearchData?.id,
    product_ids: filter.productIds,
    models: filter.models,
    date_added_start: filter.addedStart,
    date_added_end: filter.addedEnd,
    model: filterSearchData?.model,
    page: meta.page,
    limit: meta.limit,
    excluded_product_ids: excludedProductIds,
    excluded_category_ids: filter.excludedCategoryIds,
    included_product_ids: filter.includedProductIds,
    with: relation,
    with_prices: fullPrices ? true : undefined,
  })
}

export function productSearchRequest(params: object) {
  return axios.get(`${PRODUCT_URL}/search`, {params})
}

export function addBadgesToProductsRequest(data: addBadgesToProductsRequestType) {
  return axios.post(`${PRODUCT_URL}/add/badges`, data)
}

export function deleteBadgeToProductsRequest(data: deleteBadgesToProductsRequestType) {
  return axios.post(`${PRODUCT_URL}/delete/badges`, data)
}

export function getProductBadgeLogsRequest(
  filters: productBadgeLogFilterType,
  meta: paginationMetaType
) {
  return axios.get(`${PRODUCT_URL}/logs/badges`, {
    params: {
      ...filters,
      limit: meta.limit,
      page: meta.page,
    },
  })
}

export function exportProductBadgeLogRequest(filters: productBadgeLogFilterType) {
  return axios.get(`${PRODUCT_URL}/logs/badges/export`, {
    params: {
      ...filters,
    },
  })
}

export function checkProductBadgeLogRequest(filename: string) {
  return axios.get(`${PRODUCT_URL}/logs/badges/export/check`, {
    params: {
      file_name: filename,
    },
  })
}

export function bulkUpdateCurrentDateOfProducts(data: bulkUpdateCurrentDateOfProductsType) {
  return axios.put(`${PRODUCT_URL}/date-available`, data)
}

export function getProductVariantsRequest(productId: number | string) {
  return axios.get(`${PRODUCT_URL}/${productId}/variants`)
}

export function deleteProductVariantsRequest(
  productId: number | string,
  data: deleteProductVariantsType[]
) {
  return axios.delete(`${PRODUCT_URL}/${productId}/variants/multi`, {
    data: {product_variants: data},
  })
}

export function addProductVariantsRequest(
  productId: number | string,
  data: addProductVariantType[]
) {
  return axios.post(`${PRODUCT_URL}/${productId}/variants`, {product_variants: data})
}

export const getProductBadgeLogExportFrontendUrl = (filter: productBadgeLogFilterType) => {
  let url = `/products/badges/logs/export`

  return `${url}?${querystring.stringify(
    {
      ...filter,
    },
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}

type multiProductStoreRequestType = {
  product_ids: number[]
  store_ids: number[]
}

export const attachMultipleProductToStoreRequest = (data: multiProductStoreRequestType) => {
  return axios.post(`${PRODUCT_URL}/stores/bulk`, data)
}

export const detachMultipleProductToStoreRequest = (data: multiProductStoreRequestType) => {
  return axios.delete(`${PRODUCT_URL}/stores/bulk`, {data})
}
