import {DeleteOutlined, SettingOutlined, UsergroupAddOutlined} from '@ant-design/icons'
import {Popconfirm, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {createContext, useEffect, useMemo, useRef, useState} from 'react'
import {Cell, Column} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {StatusCell} from '../../components/atoms/cells/StatusCell'
import {KrcSelect} from '../../components/atoms/forms/KrcSelect'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {PermissionDataType} from '../../components/models/permissions/PermissionModel'
import {RoleDataType} from '../../components/models/roles/RoleModel'
import {UserDataType} from '../../components/models/user/UserModel'
import {removeEmptyItemInObject} from '../../helpers/ObjectHelper'
import {fetchUsersRequest} from '../../requests/users/UserRequest'
import {
  addPermissionsToUserRequest,
  deletePermissionFromUserRequest,
  fetchUserPermissionsRequest,
} from '../../requests/users/permissions/UserPermissionRequest'
import {
  addRolesToUserRequest,
  deleteRoleFromUserRequest,
  fetchUserRolesRequest,
} from '../../requests/users/roles/UserRoleRequest'
import {initialMetaData} from '../tables/ProductSelectionProvider'

interface IUserContext {
  isLoading: boolean
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  filter: any
  columns: Column<any>[]
  setFilter: React.Dispatch<React.SetStateAction<any>>
  handleOnFilter: (data: any, key: string | number) => void
  UserData: UserDataType[]
  openUserRoleModal: (user: UserDataType) => void
  hideUserRoleModal: () => void
  UserRoleData: RoleDataType[]
  UserPermissionData: PermissionDataType[]
  RoleManagementTargetUser: UserDataType | null
  PermissionManagementTargetUser: UserDataType | null
  roleColumns: Column<any>[]
  permissionColumns: Column<any>[]
  willBeAddedRoleIds: number[] | number
  setWillBeAddedRoleIds: React.Dispatch<React.SetStateAction<number[]>>
  willBeAddedPermissionIds: number[] | number
  setWillBeAddedPermissionIds: React.Dispatch<React.SetStateAction<number[]>>
  assignSelectedRolesToUser: () => void
  deleteRoleOfUser: (roleId: number) => void
  openUserPermissionModal: (user: UserDataType) => void
  getUserPermissions: (user: UserDataType) => void
  hideUserPermissionModal: () => void
  assignSelectedPermissionsToUser: () => void
  deletePermissionOfUser: (permissionId: number) => void
}

export const UserContext = createContext<IUserContext>({
  isLoading: false,
  meta: initialMetaData,
  setMeta: () => {},
  filter: {},
  setFilter: () => {},
  handleOnFilter: () => {},
  columns: [],
  UserData: [],
  openUserRoleModal: () => {},
  hideUserRoleModal: () => {},
  UserRoleData: [],
  UserPermissionData: [],
  RoleManagementTargetUser: null,
  PermissionManagementTargetUser: null,
  roleColumns: [],
  permissionColumns: [],
  willBeAddedRoleIds: [],
  setWillBeAddedRoleIds: () => {},
  willBeAddedPermissionIds: [],
  setWillBeAddedPermissionIds: () => {},
  assignSelectedRolesToUser: () => {},
  deleteRoleOfUser: () => {},
  openUserPermissionModal: () => {},
  getUserPermissions: () => {},
  hideUserPermissionModal: () => {},
  assignSelectedPermissionsToUser: () => {},
  deletePermissionOfUser: () => {},
})

export const UserProvider = ({children}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const initialRef = useRef(0)
  const [filter, setFilter] = useState<any>({})
  const [UserData, setUserData] = useState<UserDataType[]>([])

  // Kullanıcın rollerinin/izinlerinin listelenmesi için kullanılan state'ler
  const [UserRoleData, setUserRoleData] = useState<RoleDataType[]>([])
  const [UserPermissionData, setUserPermissionData] = useState<PermissionDataType[]>([])

  // Kullanıcının rollerinin/izinlerinin listeleneceği modalları açmak için kullanılan state'ler
  const [RoleManagementTargetUser, setRoleManagementTargetUser] = useState<UserDataType | null>(
    null
  )
  const [PermissionManagementTargetUser, setPermissionManagementTargetUser] =
    useState<UserDataType | null>(null)

  const [willBeAddedRoleIds, setWillBeAddedRoleIds] = useState<number[]>([])
  const [willBeAddedPermissionIds, setWillBeAddedPermissionIds] = useState<number[]>([])

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'user_id',
        manualWidth: 100,
        maxWidth: 100,
      },
      {
        Header: 'Tam Adı',
        accessor: 'fullname',
        manualWidth: 400,
        maxWidth: 400,
      },
      {
        Header: 'E-posta',
        accessor: 'email',
        manualWidth: 450,
        maxWidth: 450,
      },
      {
        Header: 'Durum',
        accessor: 'status',
        manualWidth: 200,
        maxWidth: 200,
        Cell: ({row}: any) => {
          return <StatusCell status={row.original.status} />
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Durum Seçiniz'}
              options={[
                {value: 1, label: 'Aktif'},
                {value: 2, label: 'Pasif'},
              ]}
              maxTagCount={'responsive'}
              style={{width: '100%'}}
              className={'ant-selector-border'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }
                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'status')
              }}
            />
          )
        },
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        manualWidth: 120,
        maxWidth: 120,
        Filter: <KrcEmptyFilter />,
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row justify-content-start gap-3'>
              <Tooltip title={'Rolleri Yönet'}>
                <div
                  onClick={() => openUserRoleModal(row.original)}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  <UsergroupAddOutlined />
                </div>
              </Tooltip>
              <Tooltip title={'İzinleri Yönet'}>
                <div
                  onClick={() => openUserPermissionModal(row.original)}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  <SettingOutlined />
                </div>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    []
  )

  const roleColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 100,
      },
      {
        Header: 'Rol Adı',
        accessor: 'name',
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        Filter: <KrcEmptyFilter />,
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row justify-content-around'>
              <Tooltip title={'Sil'}>
                <Popconfirm
                  title='Bu rolü silmek istediğinden emin misin ?'
                  onConfirm={(e) => deleteRoleOfUser(row.original.id)}
                  okButtonProps={{loading: isLoading}}
                  okText='Evet'
                  cancelText='Hayır'
                >
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <DeleteOutlined />
                  </div>
                </Popconfirm>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    []
  )

  const permissionColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 100,
      },
      {
        Header: 'İzin Adı',
        accessor: 'name',
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        Filter: <KrcEmptyFilter />,
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row justify-content-around'>
              <Tooltip title={'Sil'}>
                <Popconfirm
                  title='Bu izini/yetkiyi silmek istediğinden emin misin ?'
                  onConfirm={(e) => deletePermissionOfUser(row.original.id)}
                  okButtonProps={{loading: isLoading}}
                  okText='Evet'
                  cancelText='Hayır'
                >
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <DeleteOutlined />
                  </div>
                </Popconfirm>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getUsers()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    getUsers()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getUsers()
  }, [filter])

  useEffect(() => {
    initialRef.current = 1
    getUsers()
  }, [])

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }
    return debounce(loadTableFilter, 250)
  }, [])

  const getUsers = () => {
    const _filter = removeEmptyItemInObject(filter)
    setIsLoading(true)
    fetchUsersRequest(_filter, meta)
      .then((response) => {
        const _meta = response.data.meta
        setMeta({limit: _meta.per_page, page: _meta.current_page, ..._meta})
        setUserData(response.data.data)
      })
      .catch((error) => {
        message.error('Kullanıcılar getirilirken bir hata oluştu.')
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const openUserRoleModal = (user: UserDataType) => {
    setRoleManagementTargetUser(user)
    getUserRoles(user)
  }

  const getUserRoles = (user: UserDataType) => {
    setIsLoading(true)
    fetchUserRolesRequest(user.user_id)
      .then((response) => {
        setUserRoleData(response.data.data)
      })
      .catch((error) => {
        message.error(`Kullanıcının (${user.fullname}) rolleri getirilirken bir hata oluştu.`)
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const hideUserRoleModal = () => {
    setRoleManagementTargetUser(null)
    setUserRoleData([])
  }

  const assignSelectedRolesToUser = () => {
    if (willBeAddedRoleIds.length < 1) {
      message.warn('Lütfen en az bir rol seçiniz.')
      return
    }

    if (!RoleManagementTargetUser) {
      message.error('Rol tanımlanacak kullanıcı bulunamadı. Sayfayı yenileyip tekrar dene.')
      return
    }

    setIsLoading(true)
    message.loading('Kullanıcıya rol(ler) tanımlanıyor...')
    addRolesToUserRequest(RoleManagementTargetUser.user_id, willBeAddedRoleIds)
      .then((response) => {
        message.success(response.data.message)
        getUserRoles(RoleManagementTargetUser)
        setWillBeAddedRoleIds([])
      })
      .catch((error) => {
        message.error('Kullanıcıya rol(ler) tanımlanırken bir hata oluştu.')
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteRoleOfUser = (roleId: number) => {
    if (!RoleManagementTargetUser) {
      message.error('Rolü kaldırılacak kullanıcı bulunamadı. Sayfayı yenileyip tekrar dene.')
      return
    }

    setIsLoading(true)
    message.loading('Kullanıcıdan rol kaldırılıyor...')
    deleteRoleFromUserRequest(RoleManagementTargetUser.user_id, roleId)
      .then((response) => {
        message.success('Rol kullanıcıdan kaldırıldı.')
        getUserRoles(RoleManagementTargetUser)
        setWillBeAddedRoleIds([])
      })
      .catch((error) => {
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const openUserPermissionModal = (user: UserDataType) => {
    setPermissionManagementTargetUser(user)
    getUserPermissions(user)
  }

  const getUserPermissions = (user: UserDataType) => {
    setIsLoading(true)
    fetchUserPermissionsRequest(user.user_id)
      .then((response) => {
        setUserPermissionData(response.data.data)
      })
      .catch((error) => {
        message.error(`Kullanıcının (${user.fullname}) izinleri getirilirken bir hata oluştu.`)
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const hideUserPermissionModal = () => {
    setPermissionManagementTargetUser(null)
    setUserPermissionData([])
  }

  const assignSelectedPermissionsToUser = () => {
    if (willBeAddedPermissionIds.length < 1) {
      message.warn('Lütfen en az bir izin seçiniz.')
      return
    }

    if (!PermissionManagementTargetUser) {
      message.error('İzin tanımlanacak kullanıcı bulunamadı. Sayfayı yenileyip tekrar dene.')
      return
    }

    setIsLoading(true)
    message.loading('Kullanıcıya izin(ler) tanımlanıyor...')
    addPermissionsToUserRequest(PermissionManagementTargetUser.user_id, willBeAddedPermissionIds)
      .then((response) => {
        message.success(response.data.message)
        getUserPermissions(PermissionManagementTargetUser)
        setWillBeAddedPermissionIds([])
      })
      .catch((error) => {
        message.error('Kullanıcıya izin(ler) tanımlanırken bir hata oluştu.')
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deletePermissionOfUser = (permissionId: number) => {
    if (!PermissionManagementTargetUser) {
      message.error('İzin kaldırılacak kullanıcı bulunamadı. Sayfayı yenileyip tekrar dene.')
      return
    }

    setIsLoading(true)
    message.loading('Kullanıcıdan izin kaldırılıyor...')
    deletePermissionFromUserRequest(PermissionManagementTargetUser.user_id, permissionId)
      .then((response) => {
        message.success('İzin kullanıcıdan kaldırıldı.')
        getUserPermissions(PermissionManagementTargetUser)
        setWillBeAddedPermissionIds([])
      })
      .catch((error) => {
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <UserContext.Provider
        value={{
          isLoading,
          meta,
          setMeta,
          filter,
          setFilter,
          columns,
          handleOnFilter,
          UserData,
          openUserRoleModal,
          hideUserRoleModal,
          UserRoleData,
          UserPermissionData,
          RoleManagementTargetUser,
          PermissionManagementTargetUser,
          roleColumns,
          permissionColumns,
          willBeAddedRoleIds,
          setWillBeAddedRoleIds,
          willBeAddedPermissionIds,
          setWillBeAddedPermissionIds,
          assignSelectedRolesToUser,
          deleteRoleOfUser,
          openUserPermissionModal,
          getUserPermissions,
          hideUserPermissionModal,
          assignSelectedPermissionsToUser,
          deletePermissionOfUser,
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  )
}
