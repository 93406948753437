import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'
import {CouponPrefixUpdateDataType} from '../../components/models/campaign_and_coupon/CouponPrefixModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const CAMPAIGN_AND_COUPON_URL = `${API_URL}/coupon`

export function getCouponPrefixesRequest(
  filter: object = {},
  {page, limit}: paginationMetaType = {page: 1, limit: 10},
  all_data: number = 0
) {
  return axios.get(`${CAMPAIGN_AND_COUPON_URL}/prefixes`, {
    params: {
      page,
      limit,
      all_data,
      ...filter,
    },
  })
}

export function updateCouponPrefixRequest(couponId: number, data: CouponPrefixUpdateDataType) {
  return axios.put(`${CAMPAIGN_AND_COUPON_URL}/prefixes/${couponId}`, data)
}

export function createCouponPrefixRequest(data: CouponPrefixUpdateDataType) {
  return axios.post(`${CAMPAIGN_AND_COUPON_URL}/prefixes`, data)
}

export function deleteCouponPrefixRequest(couponId: number) {
  return axios.delete(`${CAMPAIGN_AND_COUPON_URL}/prefixes/${couponId}`)
}

export function fetchExcludedCouponProductsRequest(params: object = {}) {
  return axios.get(`${CAMPAIGN_AND_COUPON_URL}/excluded-products`, {
    params: {
      ...params,
    },
  })
}

export function addExcludedCouponProductRequest(data: object = {}) {
  return axios.post(`${CAMPAIGN_AND_COUPON_URL}/excluded-products`, data)
}

export function removeExcludedCouponProductRequest(excludedRecordId: number) {
  return axios.delete(`${CAMPAIGN_AND_COUPON_URL}/excluded-products/${excludedRecordId}`)
}
