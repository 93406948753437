import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import React, {FC, createContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {
  approveProductImportRequest,
  getDownloadExampleFileUrl,
} from '../../requests/products/import/ProductImportRequest'

interface IProductImportContext {
  productImportId?: number
  setProductImportId: (productImportId: number | undefined) => void
  setImportLink: (signature: string | undefined) => void
  approveProductImport: () => void
  redirectToDownloadExampleFile: () => void
}

export const ProductImportContext = createContext<IProductImportContext>({
  productImportId: 0,
  setProductImportId: () => {},
  setImportLink: () => {},
  approveProductImport: () => {},
  redirectToDownloadExampleFile: () => {},
})

const {confirm} = Modal

export const ProductImportProvider: FC = ({children}) => {
  const [productImportId, setProductImportId] = useState<number>()
  const [importLink, setImportLink] = useState<string>()
  let navigate = useNavigate()

  useEffect(() => {
    return () => {
      setProductImportId(undefined)
      setImportLink(undefined)
    }
  }, [])

  const approveProductImport = () => {
    if (!productImportId || !importLink) {
      return
    }

    confirm({
      title: `Toplu ürün ekleme`,
      icon: <ExclamationCircleOutlined />,
      content: 'Toplu ürün eklemek istediğinizden emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        approveProductImportRequest(importLink).then(() => {
          navigate(`/products/importing/${productImportId}`)
        })
      },
    })
  }

  const redirectToDownloadExampleFile = () => {
    window.location.href = getDownloadExampleFileUrl()
  }

  return (
    <>
      <ProductImportContext.Provider
        value={{
          productImportId,
          approveProductImport,
          setImportLink,
          setProductImportId,
          redirectToDownloadExampleFile,
        }}
        children={children}
      />
    </>
  )
}
