export const MARRIAGE = 1
export const GIFT = 2
export const HOME_AND_LIVING = 3

export const SHOPPING_LIST_THEMES = [
  {
    value: MARRIAGE,
    label: 'Evlilik',
    url: 'shopping_list/theme_images/evlilik.jpg',
  },
  {
    value: GIFT,
    label: 'Hediye',
    url: 'shopping_list/theme_images/hediye.jpg',
  },
  {
    value: HOME_AND_LIVING,
    label: 'Ev ve Yaşam',
    url: 'shopping_list/theme_images/evyasam.jpg',
  },
]
