import {Alert, message} from 'antd'
import React, {FC, useContext, useEffect, useRef} from 'react'

import {CouponListContext} from '../../../providers/campaign_and_coupon/CouponListProvider'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {KrcInput} from '../../atoms/forms/KrcInput'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {paginationMetaType} from '../../models/GeneralModel'

interface ICouponListFilter {
  onChange?: (data: any[]) => void
  metaData?: paginationMetaType
  onChangeMetaData?: (data: paginationMetaType) => void
  willBeFilteredCouponIds?: number[]
  onChangeFilterData?: React.Dispatch<React.SetStateAction<boolean>>
}

export const CouponListFilter: FC<ICouponListFilter> = ({onChange}) => {
  const {
    filters,
    updateFilter,
    getCouponFilter,
    data,
    setData,
    isFilterLoading,
    clear,
    setClear,
    isDataEmptyAfterFilter,
    setIsDataEmptyAfterFilter,
  } = useContext(CouponListContext)

  const initialRef = useRef(0)

  useEffect(() => {
    initialRef.current = 1
  }, [])

  useEffect(() => {
    if (!onChange) {
      return
    }

    onChange(data)
  }, [data])

  return (
    <div>
      <div className={'d-flex justify-content-between mb-4'}>
        <div className={'d-flex gap-7 flex-row align-items-center'}>
          <div className={'d-flex flex-row justify-content-center align-items-center gap-2'}>
            <label className={'mb-1'}>
              <span>Kupon Adı:</span>
            </label>
            <KrcInput
              placeholder={'Ad Giriniz'}
              value={filters.name || ''}
              onChange={(e) => updateFilter({name: e.target.value})}
              allowClear
              style={{width: 250, height: 32, borderRadius: 5}}
              className={''}
            />
          </div>
          <div className={'d-flex flex-row justify-content-center align-items-center gap-2'}>
            <label className={'mb-1'}>
              <span>Kupon Kodu:</span>
            </label>
            <KrcInput
              value={filters.code || ''}
              placeholder={'Kod Giriniz'}
              onChange={(e) => updateFilter({code: e.target.value})}
              allowClear
              style={{width: 150, height: 32, borderRadius: 5}}
              className={''}
            />
          </div>
          <div className={'d-flex flex-row justify-content-center align-items-center gap-2'}>
            <label className={'mb-1'}>
              <span>Durum:</span>
            </label>
            <KrcSelect
              placeholder={'Durum Seçiniz'}
              onChange={(data) => {
                updateFilter({status: data ? data : null})
              }}
              value={filters.status || null}
              clear={clear}
              options={[
                {
                  value: '1',
                  label: 'Aktif',
                },
                {
                  value: '0',
                  label: 'Pasif',
                },
              ]}
              className={'select-filter-custom'}
            />
          </div>
        </div>
        <div className={'d-flex justify-content-end align-items-center gap-2'}>
          <KrcButton
            type={'default'}
            className={'border-2 border-danger text-danger'}
            style={{borderRadius: 5}}
            onClick={() => {
              message.success(
                'Filtreler Temizlendi. Temizlenmemiş olabilecek filtreler için kontrol sağlamayı unutmayın.'
              )
              updateFilter({
                name: null,
                code: null,
                status: null,
              })
              setClear((prevState) => prevState + 1)
              setData([])
              setIsDataEmptyAfterFilter(false)
            }}
          >
            Filtreleri Temizle
          </KrcButton>
          <KrcButton
            loading={isFilterLoading}
            type={'default'}
            style={{borderRadius: 5, width: 100}}
            onClick={() => {
              getCouponFilter(1)
            }}
          >
            Filtrele
          </KrcButton>
        </div>
      </div>
      {isDataEmptyAfterFilter && (
        <Alert
          message='Filtreleme sonucunda herhangi bir veri bulunamadı! Farklı filtreler ile tekrar deneyiniz.'
          type='info'
          className={'rounded-3'}
          showIcon
        />
      )}
    </div>
  )
}
