import Title from 'antd/lib/typography/Title'
import React, {FC, useContext} from 'react'

import {CategorySelectionEnum} from '../../../../enums/CategorySelectionEnum'
import {CategorySelectionContext} from '../../../../providers/tables/CategorySelectionProvider'
import {useKrcProp} from '../../../hooks/components/useKrcProp'
import {ICategorySelectionHeader} from '../../../models/categories/CategorySelectionModel'

const initialCategorySelectionHeaderProps = {
  default: {
    title: 'Kategori seçimi',
    titleLevel: 4,
    selectedItemCount: 0,
    searchOption: {
      advancedStatus: true,
      advancedTitle: 'Gelişmiş seçim',
      textStatus: true,
      textTitle: 'Metin seçimi',
    },
    selectedItemText: 'Seçili kategori sayısı',
    type: CategorySelectionEnum.TEXT,
  },
}

export const CategorySelectionHeader: FC<ICategorySelectionHeader> = ({...props}) => {
  const currentProps = useKrcProp({
    config: initialCategorySelectionHeaderProps.default,
    props,
  }).getCurrentProps()

  const {setType, selectedItems} = useContext(CategorySelectionContext)

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <div className={'d-flex align-items-center'}>
          <Title level={currentProps.titleLevel}>{currentProps.title}</Title>
          <span className='h-20px border-gray-200 border-start ms-3 mx-2 mb-2' />
          <span className={'mb-2 ml-2 text-muted'}>
            {currentProps.selectedItemText} ({selectedItems?.length || 0})
          </span>
        </div>
        <div
          className={'text-primary mt-1 text-end fs-5 pointer'}
          role={'button'}
          onClick={() => {
            if (!setType || !currentProps.searchOption.textStatus) {
              return
            }

            setType(CategorySelectionEnum.TEXT)
          }}
        >
          {currentProps.searchOption.textStatus ? currentProps.searchOption.textTitle : null}
        </div>
      </div>
    </>
  )
}
