import debounce from 'lodash/debounce'
import {Moment} from 'moment'
import React, {FC, useEffect, useRef, useState} from 'react'

import {STORES_SEARCHABLE_SELECT_OPTIONS} from '../../../../../enums/PassiveProductStoreIdEnum'
import {ProductBadgeLogType} from '../../../../../enums/ProductBadgeLogType'
import {initialMetaData} from '../../../../../providers/tables/ProductSelectionProvider'
import {getPassiveProductLogsRequest} from '../../../../../requests/products/passive_products/PassiveProductRequest'
import {viewDateFormat} from '../../../../../service/DateService'
import {KrcAlert} from '../../../../atoms/KrcAlert'
import {KrcRangePicker} from '../../../../atoms/forms/KrcRangePicker'
import {KrcSelect} from '../../../../atoms/forms/KrcSelect'
import {paginationMetaType} from '../../../../models/GeneralModel'
import {productBadgeLogFilterType} from '../../../../models/products/ProductModel'
import {passiveProductLogType} from '../../../../models/products/passives/PassiveProductModel'
import {KrcTable} from '../../../../organisms/tables/KrcTable'

export const PassiveProductActivity: FC = () => {
  const [data, setData] = useState<any[]>([])
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [filter, setFilter] = useState<productBadgeLogFilterType>({})
  const fetchRequestRef = useRef(0)
  const initialRef = useRef(0)

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Ürün Model',
        accessor: 'model',
        maxWidth: 100,
        manualWidth: 150,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'product_name',
      },
      {
        Header: 'Store Adı (ID)',
        accessor: 'store_name',
        maxWidth: 150,
        manualWidth: 150,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={'multiple'}
                placeholder={'Store Filtresi'}
                options={STORES_SEARCHABLE_SELECT_OPTIONS}
                style={{width: 120}}
                className={'ant-selector-border'}
                maxTagCount={1}
                onChange={(data) => {
                  if (initialRef.current === 0) {
                    return
                  }
                  const _data = data.map((item: string) => {
                    return parseInt(item)
                  })

                  handleOnFilter(_data, 'store_ids')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'İşlemi Gerçekleşiren \n Kullanıcı Adı',
        accessor: 'user_name',
        maxWidth: 150,
        manualWidth: 150,
      },
      {
        Header: 'Durum',
        accessor: 'type',
        maxWidth: 130,
        manualWidth: 130,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={undefined}
                placeholder={'Durum Filtresi'}
                options={[
                  {value: 'success', label: 'Başarılı'},
                  {value: 'error', label: 'Başarısız'},
                ]}
                style={{width: 120}}
                className={'ant-selector-border'}
                onChange={(data) => {
                  if (initialRef.current === 0) {
                    return
                  }
                  handleOnFilter(data, 'type')
                }}
              />
            </>
          )
        },
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                {row.original.type === ProductBadgeLogType.SUCCESS ? (
                  <KrcAlert message='Başarılı' type='success' className={'py-1'} />
                ) : (
                  <></>
                )}

                {row.original.type === ProductBadgeLogType.ERROR ? (
                  <KrcAlert message='Başarısız' type='error' className={'py-1'} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Açıklama',
        accessor: 'message',
        Cell: ({row}: any) => <div dangerouslySetInnerHTML={{__html: row.original.message}}></div>,
        maxWidth: 250,
        Filter: <></>,
      },
      {
        Header: 'Gerçekleşme Tarihi',
        accessor: 'created_at',
        maxWidth: 150,
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                onSelected={handleOnSelectedRangePicker}
                size={'middle'}
                showTime={true}
                format={'DD.MM.YYYY HH:mm'}
              />
            </>
          )
        },
      },
    ]
  }, [])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getPassiveProductLogs()
  }, [filter])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getPassiveProductLogs()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getPassiveProductLogs()
  }, [meta.page])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  const getPassiveProductLogs = () => {
    fetchRequestRef.current += 1
    const fetchId = fetchRequestRef.current

    getPassiveProductLogsRequest(filter, meta).then((res) => {
      if (fetchRequestRef.current !== fetchId) {
        return
      }

      const _data = res.data.data.map((item: passiveProductLogType) => {
        let storeName =
          item.store_id === 1 ? `Web (${item.store_id})` : `${item.store?.name} (${item.store_id})`

        return {
          model: item.product.model,
          product_name: item.product?.minimal_details?.name,
          store_name: storeName,
          store_id: item.store_id,
          store_name_id_text: storeName,
          user_name: item.user?.fullname,
          type: item.type,
          message: item.message,
          created_at: viewDateFormat(item.created_at),
        }
      })

      setData(_data)
      const resMeta = res.data.meta
      setMeta({limit: resMeta.per_page, page: resMeta.current_page, ...resMeta})
    })
  }

  const handleOnSelectedRangePicker = (date: Moment[] | null) => {
    if (!date) {
      setFilter((data) => {
        return {...data, created_at_range: undefined}
      })
      return
    }

    setFilter((data) => {
      return {
        ...data,
        created_at_range: [date[0].toISOString(), date[1].toISOString()],
      }
    })
  }

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data?: productBadgeLogFilterType) => {
        if (!data) {
          return {[rowId]: value}
        }

        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  return (
    <>
      <KrcTable
        columns={columns}
        data={data}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            setMeta((data) => ({...data, page}))
          },
          onChangeLimit: (limit) => {
            setMeta((data) => ({...data, limit}))
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: handleOnFilter,
        }}
      />
    </>
  )
}
