import {Drawer, Space} from 'antd'
import {FieldArray, Form, Formik} from 'formik'
import React, {ChangeEvent, FC, useContext, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'

import {GameContext} from '../../../providers/games/GameProvider'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {KrcFormikInput} from '../../atoms/forms/KrcFormikInput'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'

interface FormValues {
  game_settings: {
    min_score: number
    max_score: number | null
    amount: number
    coupon_limit: number
  }[]
}

const _initialValues: FormValues = {
  game_settings: [{min_score: 0, max_score: 0, amount: 0, coupon_limit: 0}],
}

const validationSchema = Yup.object().shape({
  game_settings: Yup.array().of(
    Yup.object({
      min_score: Yup.number()
        .required('Puan min alanı zorunlu')
        .min(0, "Puan Min 0'dan büyük veya eşit olmalıdır."),
      max_score: Yup.number()
        .nullable()
        .when('min_score', (min_score, schema) => {
          if (min_score > 0) {
            return schema.min(min_score + 1, `Puan Max ${min_score}'dan büyük olmalıdır.`)
          }

          return schema
        })
        .test(
          'score-max-greater-than-score-min',
          "Puan max, Puan min'den büyük olmalıdır",
          (value, parent) => {
            // @ts-ignore
            if (parent.options.index === parent.from[1].value.game_settings.length - 1) {
              return true
            }

            return parent.parent.max_score > parent.parent.min_score
          }
        ),
      amount: Yup.number()
        .required('Kupon tutarı alanı zorunlu')
        .test(
          'must-be-greater-than-the-previous-coupon-amount',
          'Bir önceki Kupon Tutarından büyük olmalı',
          (value, parent) => {
            // @ts-ignore
            if (parent.options.index === 0) {
              return true
            }

            // @ts-ignore
            return value > parent.from[1].value.game_settings[parent.options.index - 1].amount
          }
        ),
      coupon_limit: Yup.number()
        .required('Kupon Kullanım Limiti')
        .test(
          'must-be-greater-than-first-coupon-amount-coupon-usage-limit',
          'Kupon kullanım limiti ilk kupon tutarından küçük olamaz',
          (value, parent) => {
            // @ts-ignore
            return value + 1 > parent.from[1].value.game_settings[0].amount
          }
        )
        .test(
          'must-be-greater-than-the-previous-coupon-usage-limit',
          'Kupon kullanım limiti bir önceki kupon limitinden küçük olamaz',
          (value, parent) => {
            // @ts-ignore
            if (parent.options.index === 0) {
              return true
            }
            // @ts-ignore
            const indexLength = parent.options.index - 1
            // @ts-ignore
            return value >= parent.from[1].value.game_settings[indexLength].coupon_limit
          }
        ),
    })
  ),
})

export const GameSettingDrawer: FC = () => {
  const [initialValues, setInitialValues] = useState<FormValues>(_initialValues)
  const submitRef = useRef<HTMLButtonElement | null>(null)
  const {
    gameSettingDrawerVisible,
    setGameSettingDrawerVisible,
    willBeUpdatedGameSettings,
    updateGameSettings,
    setWillBeUpdatedGameSettings,
    setUpdatingGameId,
  } = useContext(GameContext)
  const handleOnHide = () => {
    setGameSettingDrawerVisible(false)
    setWillBeUpdatedGameSettings(null)
    setUpdatingGameId(null)

    setInitialValues(_initialValues)
  }

  useEffect(() => {
    if (!willBeUpdatedGameSettings || willBeUpdatedGameSettings?.length === 0) {
      return
    }

    setInitialValues({
      game_settings: willBeUpdatedGameSettings,
    })
  }, [willBeUpdatedGameSettings])

  const onSubmit = (value: any) => {
    updateGameSettings(value)
  }

  return (
    <>
      <Drawer
        title={'Karaca Oyun Ayarları'}
        placement='right'
        width={900}
        onClose={handleOnHide}
        keyboard={false}
        maskClosable={false}
        visible={gameSettingDrawerVisible}
        extra={
          <Space>
            <button
              onClick={() => {
                submitRef.current?.click()
              }}
              className='btn btn-success'
              style={{marginLeft: 10}}
            >
              Kaydet
            </button>
          </Space>
        }
      >
        {gameSettingDrawerVisible && (
          <Formik<FormValues>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({values, setFieldValue}) => {
              return (
                <Form>
                  <FieldArray
                    name='game_settings'
                    render={(arrayHelpers) => {
                      let maxIndex = values.game_settings.length - 1

                      return (
                        <div>
                          {values.game_settings.map((field, index) => (
                            <div className='d-flex align-items-end mr-5 mb-2' key={index}>
                              <KrcFormikField
                                className={'col-md-2 mr-3'}
                                name={`game_settings.${index}.min_score`}
                                labelTitle={'Puan Min'}
                              >
                                <KrcFormikInput
                                  name={`game_settings.${index}.min_score`}
                                  type={'number'}
                                  disabled={index !== 0}
                                />
                              </KrcFormikField>
                              <KrcFormikField
                                className={'col-md-2 mr-3'}
                                name={`game_settings.${index}.max_score`}
                                labelTitle={'Puan Max'}
                              >
                                <KrcFormikInput
                                  name={`game_settings.${index}.max_score`}
                                  type={'number'}
                                  onChangeCapture={(e: ChangeEvent<HTMLInputElement>) => {
                                    if (values.game_settings[index + 1]) {
                                      setFieldValue(
                                        `game_settings.${index + 1}.min_score`,
                                        Number(e.target.value) + 1
                                      )
                                    }
                                  }}
                                />
                              </KrcFormikField>
                              <KrcFormikField
                                className={'col-md-3 mr-3'}
                                name={`game_settings.${index}.amount`}
                                labelTitle={'Kupon Tutarı'}
                              >
                                <KrcFormikInput
                                  name={`game_settings.${index}.amount`}
                                  type={'number'}
                                />
                              </KrcFormikField>
                              <KrcFormikField
                                className={'col-md-3 mr-3'}
                                name={`game_settings.${index}.coupon_limit`}
                                labelTitle={'Kupon Kullanım Limiti'}
                              >
                                <KrcFormikInput
                                  name={`game_settings.${index}.coupon_limit`}
                                  type={'number'}
                                  onChangeCapture={(e: ChangeEvent<HTMLInputElement>) => {
                                    const scoreRangesLength = values.game_settings.length

                                    for (let i = 0; i < scoreRangesLength - index; i++) {
                                      setFieldValue(
                                        `game_settings.${index + i}.coupon_limit`,
                                        Number(e.target.value)
                                      )
                                    }
                                  }}
                                />
                              </KrcFormikField>
                              {maxIndex === index && index !== 0 && (
                                <KrcButton
                                  type={'default'}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -
                                </KrcButton>
                              )}
                            </div>
                          ))}
                          <div className='d-flex justify-content-end mt-5'>
                            <KrcButton
                              size={'middle'}
                              onClick={() => {
                                arrayHelpers.push({
                                  min_score:
                                    (values.game_settings[values.game_settings.length - 1]
                                      .max_score ??
                                      values.game_settings[values.game_settings.length - 1]
                                        .min_score) + 1,
                                  max_score: null,
                                  amount: 0,
                                  coupon_limit:
                                    values.game_settings[values.game_settings.length - 1]
                                      .coupon_limit,
                                })
                              }}
                            >
                              Puan Aralığı Ekle
                            </KrcButton>
                          </div>

                          <button
                            type='submit'
                            ref={submitRef}
                            className='d-none'
                            style={{marginLeft: 10}}
                          />
                        </div>
                      )
                    }}
                  />
                </Form>
              )
            }}
          </Formik>
        )}
      </Drawer>
    </>
  )
}
