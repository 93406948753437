import TextArea from 'antd/lib/input/TextArea'
import {FC} from 'react'

import {KrcButton} from '../../atoms/buttons/KrcButton'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useProductTextAreaFilter} from '../../hooks/products/useProductTextAreaFilter'
import {IProductTextAreaFilter} from '../../models/products/ProductListFilterModel'

const defaultTextAreaProps = {
  default: {
    rows: 6,
    placeholder:
      'Model numarası veya Ürün ID ile toplu arama yapılabilir. \nÖrneğin: \n7126119 \n6949288 \n153.03.01.1091 ',
  },
}

const defaultKrcButtonProps = {
  default: {
    className: 'w-15',
    size: 'large',
    type: 'primary',
  },
}

export const ProductTextAreaFilter: FC<IProductTextAreaFilter> = ({
  textAreaProps,
  krcButtonProps,
  onFilter,
  withExtraText = false,
}) => {
  const {handleOnChange, getProductIdOrModelInText} = useProductTextAreaFilter()

  const currentTextAreaProps = useKrcProp({
    config: defaultTextAreaProps.default,
    props: textAreaProps,
  }).getCurrentProps()

  const currentKrcButtonProps = useKrcProp({
    config: defaultKrcButtonProps.default,
    props: krcButtonProps,
  }).getCurrentProps()

  const handleOnFilter = () => {
    if (!onFilter) {
      return
    }

    onFilter(getProductIdOrModelInText(withExtraText))
  }

  return (
    <>
      <TextArea {...currentTextAreaProps} onChange={handleOnChange}></TextArea>
      <div className={'d-flex justify-content-end mt-3'}>
        <KrcButton {...currentKrcButtonProps} onClick={handleOnFilter}>
          Filtrele
        </KrcButton>
      </div>
    </>
  )
}
