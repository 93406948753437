import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {ShoppingListCreateFormWrapper} from '../components/pages/shopping-lists/ShoppingListCreateFormWrapper'
import {ShoppingListUpdateFormWrapper} from '../components/pages/shopping-lists/ShoppingListUpdateFormWrapper'
import {ShoppingListWrapper} from '../components/pages/shopping-lists/ShopppingListWrapper'

export const ShoppingListRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<ShoppingListWrapper />} />
        <Route path={'/create'} element={<ShoppingListCreateFormWrapper />} />
        <Route path={'/:listId'} element={<ShoppingListUpdateFormWrapper />} />
      </Routes>
    </>
  )
}
