import {PlusCircleOutlined} from '@ant-design/icons'
import Title from 'antd/lib/typography/Title'
import React, {FC} from 'react'

import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcRangePicker} from '../../../atoms/forms/KrcRangePicker'
import {useAddCategoriesBadge} from '../../../hooks/categories/badges/useAddCategoriesBadge'
import {CategorySelection} from '../../../layout/filters/category/CategorySelection'
import {BadgeFilter} from '../../../molecules/filters/BadgeFilter'

export const AddCategoriesBadge: FC = () => {
  const {
    setSelectedCategoryIds,
    setWillBeAddedBadgeIds,
    clear,
    willBeAddedBadgeIds,
    setWillBeAddedBadgeDateRange,
    addBadgeToCategories,
  } = useAddCategoriesBadge()

  return (
    <>
      <div className={'d-flex row'}>
        <div className={'col-8'}>
          <CategorySelection
            clear={clear}
            headerProps={{
              titleLevel: 5,
            }}
            textAreaProps={{
              placeholder: 'Kategori ID ile toplu arama yapılabilir. \nÖrneğin: \n11 \n13846',
            }}
            onSelectedItems={(data) => {
              setSelectedCategoryIds(
                data?.map((item) => {
                  return item.id
                }) as number[]
              )
            }}
            transferProps={{
              button: {
                title: '',
                isActive: false,
              },
            }}
          />
        </div>
        <div className={'col-4'} style={{width: 'max-content'}}>
          <div className={'d-flex flex-column'}>
            <div className={'d-flex'}>
              <Title level={5}>Badge Başlangıç ve Bitiş Tarihi</Title>
            </div>
            <div className={'d-flex flex-row'}>
              <KrcRangePicker
                className={'flex-1'}
                showTime
                placeholder={['Eklenme Tarihi Başlangıç', 'Ekleme Tarihi Bitiş']}
                format={'DD.MM.YYYY HH:mm'}
                allowClear
                onSelected={(value) => {
                  if (!value) {
                    setWillBeAddedBadgeDateRange({
                      date_start: null,
                      date_end: null,
                    })
                    return
                  }
                  setWillBeAddedBadgeDateRange({
                    date_start: value[0].format('YYYY-MM-DD HH:mm'),
                    date_end: value[1].format('YYYY-MM-DD HH:mm'),
                  })
                }}
                size={'middle'}
              />
            </div>
          </div>
          <div className={'d-flex flex-column mt-3'}>
            <div className={'d-flex'}>
              <Title level={5}>Badge</Title>
              <span className={'required'} />
            </div>
            <div className={'d-flex flex-row'}>
              <BadgeFilter
                value={willBeAddedBadgeIds}
                className={'select-filter-custom'}
                style={{maxWidth: 402}}
                dropdownMatchSelectWidth={true}
                onSelected={(data) => {
                  setWillBeAddedBadgeIds(data as number[])
                }}
                placeholder={'Lütfen seçiniz'}
              />
            </div>
          </div>
          <div className={'d-flex justify-content-between mt-3'}>
            <div></div>
            <KrcButton
              className={'d-flex align-items-center justify-content-center'}
              type={'primary'}
              onClick={() => {
                addBadgeToCategories()
              }}
              icon={<PlusCircleOutlined style={{lineHeight: 0}} />}
            >
              <span>Tamamla</span>
            </KrcButton>
          </div>
        </div>
      </div>
    </>
  )
}
