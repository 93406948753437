import React, {FC} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {KTSVG} from '../../../_metronic/helpers'
import {useKrcProp} from '../hooks/components/useKrcProp'
import {IKrcModal} from '../models/components/KrcModalModel'

const initialModalProps = {
  default: {
    size: 'lg',
    show: true,
    id: 'krc-modal',
    dialogClassName: 'w-75',
  },
}

const initialModalHeaderProps = {
  default: {
    visible: true,
    title: 'Modal',
    className: '',
  },
}

export const KrcModal: FC<IKrcModal> = ({
  modalProps,
  modalHeaderProps,
  children,
  modalDialogProps,
}) => {
  const currentModalProps = useKrcProp({
    config: initialModalProps.default,
    props: modalProps,
  }).getCurrentProps()

  const currentModalHeaderProps = useKrcProp({
    config: initialModalHeaderProps.default,
    props: modalHeaderProps,
  }).getCurrentProps()

  return (
    <>
      <Modal {...currentModalProps} centered={true}>
        <div
          className={`modal-dialog modal-dialog-centered mw-900px mb-0 w-100 ${modalDialogProps?.className}`}
        >
          <div className='modal-content'>
            {currentModalHeaderProps.visible && (
              <Modal.Header className={currentModalHeaderProps.className}>
                <h2>{currentModalHeaderProps.title}</h2>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  onClick={currentModalHeaderProps.onHide}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </div>
              </Modal.Header>
            )}
            {children}
          </div>
        </div>
      </Modal>
    </>
  )
}
