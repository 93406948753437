import {SelectProps, Spin} from 'antd'
import React, {FC, useEffect} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {usePermissionFilter} from '../../hooks/permissions/usePermissionFilter'
import {IRoleFilter} from '../../models/roles/RoleFilterModel'

const permissionFilterConfig = {
  default: {
    style: {width: '100%'},
    mode: 'multiple',
    size: 'middle',
    placeholder: 'İzin Filtre Listesi',
    allowClear: false,
  },
}

export const PermissionFilter: FC<IRoleFilter & SelectProps> = ({
  onSelected,
  dataType = 'value',
  clear,
  ...props
}) => {
  const currentProps = useKrcProp({config: permissionFilterConfig.default, props}).getCurrentProps()
  const {handleOnChange, handleSearch, permissionIds, fetching, options, selectedItems} =
    usePermissionFilter()

  useEffect(() => {
    if (!onSelected) {
      return
    }

    if (dataType === 'value') {
      onSelected(permissionIds)
      return
    }

    onSelected(selectedItems)
  }, [permissionIds])

  return (
    <>
      <KrcSelect
        clear={clear}
        {...currentProps}
        showArrow
        filterOption={false}
        labelInValue
        onSearch={handleSearch}
        onChange={handleOnChange}
        options={options}
        notFoundContent={fetching ? <Spin size='small' /> : null}
      />
    </>
  )
}
