import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import {Drawer, Dropdown, Menu, Modal, Space, Tooltip, message} from 'antd'
import moment from 'moment'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'
import {Cell} from 'react-table'

import {
  APPROVAL_PROCESS,
  CANCELED,
  FINISHED,
  PROCESSING,
  WAITING,
} from '../../enums/CampaignConstraintType'
import {CategoryProductsProcessContext} from '../../providers/category/products/CategoryProductsProcessProvider'
import {initialMetaData} from '../../providers/tables/ProductSelectionProvider'
import {requestDateFormat} from '../../service/DateService'
import {KrcAlert} from '../atoms/KrcAlert'
import {KrcEmptyFilter} from '../atoms/KrcEmptyFilter'
import {KrcButton} from '../atoms/buttons/KrcButton'
import {KrcNumberInput} from '../atoms/forms/KrcNumberInput'
import {KrcRangePicker} from '../atoms/forms/KrcRangePicker'
import {KrcSelect} from '../atoms/forms/KrcSelect'
import {CategoryBatchConstraintProductModal} from '../modals/categories/CategoryBatchConstraintProductModal'
import {CategoryProductModal} from '../modals/categories/CategoryProductModal'
import {paginationMetaType} from '../models/GeneralModel'
import {IConstraintProductListToCampaign} from '../models/categories/CategoryProductModel'
import {KrcTable} from '../organisms/tables/KrcTable'

export const ConstraintProductListToCampaign: FC<IConstraintProductListToCampaign> = ({
  category,
  visible,
  onHide,
}) => {
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)

  const initialRef = useRef(0)

  const {
    willBeUpdatedProduct,
    setWillBeUpdatedProduct,
    setVisibleCategoryProductModal,
    handleOnConstraintFilter,
    getCategoryConstraintProducts,
    categoryConstraintProducts,
    selectedConstraintProducts,
    setSelectedConstraintProducts,
    unSelectAllConstraintProducts,
    visibleCategoryConstraintProductModal,
    setVisibleCategoryConstraintProductModal,
    deleteSelectedConstraintProduct,
    constraintListTableFilter,
  } = useContext(CategoryProductsProcessContext)

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Model',
        accessor: 'model',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
        maxWidth: 150,
        manualWidth: 150,
      },
      {
        Header: 'Stok',
        accessor: 'stock',
        maxWidth: 150,
        manualWidth: 150,
        Filter: () => {
          return (
            <>
              <KrcNumberInput
                style={{width: '100%'}}
                placeholder={'Stok Filtresi'}
                onChange={(data) => {
                  handleOnConstraintFilter(data, 'stock')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'startDate',
        maxWidth: 150,
        manualWidth: 150,
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                onSelected={(data) => {
                  const value = data
                    ? [requestDateFormat(data[0]), requestDateFormat(data[1])]
                    : null
                  handleOnConstraintFilter(value, 'start_date')
                }}
                size={'middle'}
                showTime
                format={'DD.MM.YYYY HH:mm:ss'}
              />
            </>
          )
        },
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'endDate',
        maxWidth: 150,
        manualWidth: 150,
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                onSelected={(data) => {
                  const value = data
                    ? [requestDateFormat(data[0]), requestDateFormat(data[1])]
                    : null
                  handleOnConstraintFilter(value, 'end_date')
                }}
                size={'middle'}
                showTime
                format={'DD.MM.YYYY HH:mm:ss'}
              />
            </>
          )
        },
      },
      {
        Header: 'Durum',
        accessor: 'type',
        maxWidth: 170,
        manualWidth: 170,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={'multiple'}
                placeholder={'Durum Filtresi'}
                options={[
                  {value: APPROVAL_PROCESS, label: 'Onay Sürecinde'},
                  {value: WAITING, label: 'Aktiflik Sürecinde'},
                  {value: PROCESSING, label: 'Aktif'},
                  {value: FINISHED, label: 'Tamamlandı'},
                  {value: CANCELED, label: 'Red / İptal Edildi'},
                ]}
                maxTagCount={'responsive'}
                style={{width: '100%'}}
                className={'ant-selector-border'}
                onChange={(data) => {
                  if (!Array.isArray(data)) {
                    return
                  }

                  handleOnConstraintFilter(data.length > 0 ? data : null, 'type')
                }}
              />
            </>
          )
        },
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.type === null && <KrcAlert message='Onay Sürecinde' type='warning' />}
              {row.original.type === WAITING && (
                <KrcAlert message='Aktiflik Sürecinde' type='warning' />
              )}
              {row.original.type === PROCESSING && <KrcAlert message='Aktif' type='info' />}
              {row.original.type === FINISHED && <KrcAlert message='Tamamlandı' type='success' />}
              {row.original.type === CANCELED && (
                <KrcAlert message='Red / İptal Edildi' type='error' />
              )}
            </>
          )
        },
      },
      {
        Header: 'Aksiyonlar',
        accessor: '',
        manualWidth: 95,
        maxWidth: 95,
        Cell: ({row}: Cell<any>) => {
          const {confirm} = Modal
          const handleOnEditConstraintProduct = () => {
            setWillBeUpdatedProduct({
              category_id: category.category_id,
              constraint_ids: [row.original.id],
              date_start:
                row.original.startDate === '-'
                  ? undefined
                  : moment(row.original.startDate, 'DD.MM.YYYY HH:mm'),
              date_end:
                row.original.endDate === '-'
                  ? undefined
                  : moment(row.original.endDate, 'DD.MM.YYYY HH:mm'),
              quantity: row.original.remainingStock ?? '',
            })

            setVisibleCategoryProductModal(true)
          }

          const handleOnRemoveConstraintProduct = () => {
            confirm({
              title: 'Uyarı',
              icon: <ExclamationCircleOutlined />,
              content: 'Ürünü listeden kaldırmak istediğinize emin misiniz?',
              okText: 'Evet',
              okType: 'primary',
              cancelText: 'Vazgeç',
              onOk() {
                deleteSelectedConstraintProduct({
                  category_id: category.category_id,
                  constraint_ids: [row.original.id],
                })
              },
            })
          }

          const productAddActionsDropdownMenu = (
            <Menu>
              <Menu.Item
                title={'Güncelle'}
                onClick={handleOnEditConstraintProduct}
                icon={<EditOutlined />}
                key={'editConstraintProduct'}
              >
                Güncelle
              </Menu.Item>
              <Menu.Item
                title={'Kaldır'}
                onClick={handleOnRemoveConstraintProduct}
                icon={<DeleteOutlined />}
                key={'removeConstraintProduct'}
                danger={true}
              >
                Listeden Kaldır
              </Menu.Item>
            </Menu>
          )

          return (
            <div className={'d-flex flex-row justify-content-center'}>
              {!(row.original.type === FINISHED || row.original.type === CANCELED) && (
                <>
                  <Dropdown overlay={productAddActionsDropdownMenu} trigger={['click']}>
                    <Tooltip title={'Seçenekler'}>
                      <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'>
                        <EllipsisOutlined />
                      </div>
                    </Tooltip>
                  </Dropdown>
                </>
              )}
            </div>
          )
        },
        Filter: KrcEmptyFilter,
      },
    ]
  }, [])

  const handleOnHide = () => {
    onHide && onHide(false)
  }

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getCategoryConstraintProducts()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getCategoryConstraintProducts()
  }, [meta.page])

  useEffect(() => {
    if (!visible) {
      initialRef.current = 0
      return
    }

    setMeta(initialMetaData)
    initialRef.current = 1
  }, [visible])

  useEffect(() => {
    getCategoryConstraintProducts()
  }, [constraintListTableFilter])

  const handleOnShowBatchConstriantProductUpdateModal = () => {
    if (selectedConstraintProducts.length === 0) {
      message.warning('Lütfen en az bir ürün seçiniz.')
      return
    }
    const canceledOrFinishedProducts = selectedConstraintProducts.filter(
      (product) => product?.type === 'canceled' || product?.type === 'finished'
    )
    if (canceledOrFinishedProducts.length > 0) {
      message.warning('Seçilen ürünlerden tamamlanmış veya reddedilmiş olanlar bulunmaktadır.')
      return
    }
    setVisibleCategoryConstraintProductModal(true)
  }

  return (
    <>
      <Drawer
        width={1024}
        title={'Kısıtlı Ürün Listesi'}
        placement='right'
        size={'large'}
        onClose={handleOnHide}
        keyboard={false}
        maskClosable={false}
        visible={visible}
        extra={
          <Space>
            <KrcButton type={'default'} size={'large'} onClick={handleOnHide}>
              İptal
            </KrcButton>
            <KrcButton size={'large'} onClick={handleOnShowBatchConstriantProductUpdateModal}>
              Seçilenleri Toplu Güncelle
            </KrcButton>
          </Space>
        }
      >
        <KrcTable
          columns={columns}
          data={categoryConstraintProducts}
          selectableProps={{
            isSelectable: true,
            onSelectedItems: setSelectedConstraintProducts,
            unSelectAllItems: unSelectAllConstraintProducts,
            uniqueKey: 'id',
          }}
          searchableProps={{
            isSearchable: true,
            onSearch: (search, id) => {
              handleOnConstraintFilter(search, id)
            },
          }}
          paginationProps={{
            isPagination: true,
            limit: meta.limit,
            page: meta.page,
            paginationData: meta,
            onChangeLimit: (limit) => {
              setMeta((data) => ({...data, limit}))
            },
            onChangePage: (page) => {
              setMeta((data) => ({...data, page}))
            },
          }}
        />
      </Drawer>
      {willBeUpdatedProduct && <CategoryProductModal />}
      {visibleCategoryConstraintProductModal && (
        <CategoryBatchConstraintProductModal categoryId={category.category_id} />
      )}
    </>
  )
}
