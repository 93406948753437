import React, {FC, useContext} from 'react'

import {CategoryLandingContext} from '../../../../providers/category/landings/CategoryLandingProvider'
import {CategoryLandingMap} from '../../../layout/categories/landings/maps/CategoryLandingMap'
import {MenuLandingMap} from '../../../layout/menus/landings/maps/MenuLandingMap'
import {CategoryLandingImageModal} from '../../../modals/categories/landings/CategoryLandingImageModal'

export const CategoryLandingMaps: FC = () => {
  const {categoryLandingMaps, getCategoryLandings, willBeUpdatedCategory, setSelectedCategoryId} =
    useContext(CategoryLandingContext)

  return (
    <>
      <div className={'row'}>
        <div className={'col-4'}>
          <MenuLandingMap
            headerProps={{
              title: 'Menü',
            }}
          />
        </div>

        {Object.keys(categoryLandingMaps).map((key) => {
          if (categoryLandingMaps[key].length === 0) {
            return <div key={`category-landing-${key}`}></div>
          }

          return (
            <div className={'col-4'} key={`category-landing-${key}`}>
              <CategoryLandingMap
                headerProps={{
                  title: 'Kategori',
                }}
                categories={categoryLandingMaps[key]}
                onSelect={(item: any) => {
                  if (!item || key === '1') {
                    return
                  }
                  setSelectedCategoryId(item.id)
                  getCategoryLandings(item.id)
                }}
              />
            </div>
          )
        })}

        {willBeUpdatedCategory && <CategoryLandingImageModal />}
      </div>
    </>
  )
}
