import {UploadOutlined} from '@ant-design/icons'
import {Button, Upload} from 'antd'
import {UploadFile} from 'antd/lib/upload/interface'
import {getUUID} from 'rc-select/es/utils/commonUtil'
import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {KTSVG} from '../../../_metronic/helpers'
import {uploadImageUrlFormat} from '../ImageService'
import {FileManager} from './FileManager'
import {useFileManager} from './useFileManager'

interface IFileManagerModal {
  onSelected: (inputValue: string | null, fullUrl: string | null) => any
  multiple?: boolean
  className?: string
  defaultImage?: string
  tempUploadDir?: string
  open?: number
  showImage?: boolean
}

type inputValueType = {
  field_id: null
  full_url: string
  sender: string
  url: string
}

export const FileManagerModal: FC<IFileManagerModal> = ({
  children,
  onSelected,
  multiple = false,
  className = 'file-manager-full-width',
  defaultImage,
  tempUploadDir = '',
  open,
  showImage = true,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [inputValue, setInputValue] = useState<inputValueType | null>(null)
  const [isOpenFileManager, setIsOpenFileManager] = useState<boolean>(false)

  const setFieldValue = (val: inputValueType) => setInputValue(val)
  const fileManager = useFileManager({setFieldValue})

  useEffect(() => {
    if (!inputValue) {
      return
    }

    onSelected(uploadImageUrlFormat(inputValue.url), inputValue.full_url)

    handleSetFileList(inputValue.url, inputValue.full_url)
    setIsOpenFileManager(false)
  }, [inputValue])

  useEffect(() => {
    if (defaultImage === undefined) {
      return
    }

    handleSetFileList(defaultImage)
  }, [])

  useEffect(() => {
    if (defaultImage === undefined) {
      return
    }

    if (inputValue && defaultImage === inputValue.url) {
      return
    }

    handleSetFileList(defaultImage)
  }, [defaultImage])

  useEffect(() => {
    if (isOpenFileManager) {
      fileManager.handleFileManager()
    }
  }, [isOpenFileManager])

  const handleOnHide = () => {
    setIsOpenFileManager(false)
  }

  useEffect(() => {
    if (!open) {
      return
    }

    setIsOpenFileManager(true)
  }, [open])

  const handleSetFileList = (url: string, full_url: string | null = null) => {
    if (!url) {
      setFileList([])
      return
    }

    if (!full_url) {
      full_url = url
    }

    const fileListItem = {
      uid: getUUID() as string,
      name: url.split('/')[url.split('/').length - 1],
      status: 'done',
      url: full_url,
    } as UploadFile

    if (!multiple) {
      setFileList([fileListItem])
    } else {
      setFileList((fileList) => [...fileList, fileListItem])
    }
  }

  return (
    <>
      <Upload
        listType='picture'
        className={className}
        fileList={showImage ? fileList : undefined}
        onRemove={(file) => {
          onSelected(null, null)
          setFileList((fileList) => {
            return fileList.filter((fileListItem) => fileListItem.uid !== file.uid)
          })
        }}
        showUploadList={{showPreviewIcon: false}}
        openFileDialogOnClick={false}
      >
        {children ? (
          children
        ) : (
          <Button
            className={'w-100'}
            icon={<UploadOutlined />}
            onClick={() => {
              setIsOpenFileManager(true)
            }}
          >
            Upload
          </Button>
        )}
      </Upload>
      <Modal size='xl' show={isOpenFileManager} onHide={() => {}} dialogClassName='w-100'>
        <div className='w-100'>
          <div className='modal-content'>
            <Modal.Header className={'p-3'}>
              <div />
              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleOnHide}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </Modal.Header>
            <Modal.Body className='p-0 m-0 row'>
              <FileManager
                domain={`${process.env.REACT_APP_URL}`}
                crossdomain={true}
                target={fileManager.target}
                isOpen={fileManager.isOpen}
                akey={`${process.env.REACT_APP_AKEY}`}
                height={700}
                className={'p-0'}
                tempUploadDir={tempUploadDir}
              />
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  )
}
