import {Route, Routes} from 'react-router-dom'

import {UrlAliasListWrapper} from '../components/pages/url_alias/UrlAliasListWrapper'
import {UrlAliasListExportWrapper} from '../components/pages/url_alias/exports/UrlAliasListExportWrapper'

export const UrlAliasRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/export'} element={<UrlAliasListExportWrapper />} />
        <Route path={'/'} element={<UrlAliasListWrapper />} />
      </Routes>
    </>
  )
}
