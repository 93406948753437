import React, {useContext} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {CategoryLandingContext} from '../../../../providers/category/landings/CategoryLandingProvider'
import {FileManagerModal} from '../../../../service/FileManager/FileManagerModal'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcModal} from '../../KrcModal'

export const CategoryLandingImageModal = () => {
  const {
    willBeUpdatedCategory,
    setWillBeUpdatedCategory,
    setSelectedCategoryId,
    updateCategoryLandingImage,
  } = useContext(CategoryLandingContext)
  const handleOnHide = () => {
    setSelectedCategoryId(undefined)
    setWillBeUpdatedCategory(undefined)
  }

  return (
    <>
      <KrcModal
        modalProps={{
          onHide: () => {
            handleOnHide()
          },
        }}
        modalHeaderProps={{
          title: 'Kategori Landing Image Güncelleme',
          onHide: () => {
            handleOnHide()
          },
        }}
      >
        <Modal.Body className='py-lg-7 px-lg-7 d-flex justify-content-center'>
          <FileManagerModal
            onSelected={(link) => {
              setWillBeUpdatedCategory((willBeUpdatedCategory) => {
                if (!willBeUpdatedCategory) {
                  return willBeUpdatedCategory
                }

                return {...willBeUpdatedCategory, image: link}
              })
            }}
            defaultImage={willBeUpdatedCategory?.image ?? ''}
          />
          <KrcButton className={'ml-3'} onClick={updateCategoryLandingImage}>
            Kaydet
          </KrcButton>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
