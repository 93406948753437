import {message} from 'antd'
import {isEmptyArray} from 'formik'
import React, {FC, createContext, useState} from 'react'

import {productSelectionItemsType} from '../../../components/models/products/ProductSelectionModel'
import {KrcWarningNotification} from '../../../components/molecules/KrcWarningNotification'
import {bulkUpdateCurrentDateOfProducts} from '../../../requests/products/ProductRequest'

interface IProductAvailableContext {
  selectedProducts: productSelectionItemsType[]
  setSelectedProducts: React.Dispatch<React.SetStateAction<productSelectionItemsType[]>>
  clear: number
  setClear: React.Dispatch<React.SetStateAction<number>>
  dateAvailable?: string
  setDateAvailable: React.Dispatch<React.SetStateAction<string | undefined>>
  updateDateAvailable: () => void
}

export const ProductAvailableContext = createContext<IProductAvailableContext>({
  selectedProducts: [],
  setSelectedProducts: () => {},
  clear: 0,
  setClear: () => {},
  dateAvailable: '',
  setDateAvailable: () => {},
  updateDateAvailable: () => {},
})

export const ProductAvailableProvider: FC = ({children}) => {
  const [selectedProducts, setSelectedProducts] = useState<productSelectionItemsType[]>([])
  const [clear, setClear] = useState<number>(0)
  const [dateAvailable, setDateAvailable] = useState<string>()

  const updateDateAvailable = () => {
    if (!dateAvailable) {
      message.warn('Geçerli tarih boş bırakılamaz.')
      return
    }

    if (selectedProducts.length === 0) {
      message.warn('Ürünlerin gerçerli tarih güncellebilmek için lütfen ürün(ler) seçiniz.')
      return
    }

    const selectedProductIds = selectedProducts.map((selectedProduct) => selectedProduct.id)

    bulkUpdateCurrentDateOfProducts({
      product_ids: selectedProductIds,
      date_available: dateAvailable,
    }).then((res) => {
      const data = res.data.data

      if (
        isEmptyArray(data.url_alias) ||
        isEmptyArray(data.price) ||
        isEmptyArray(data.quantity_and_ordered_quantity)
      ) {
        message.success('Ürün(lerin) geçerlilik tarihi güncellendi.')
        setClear((data) => data + 1)
      }

      if (!isEmptyArray(data.url_alias)) {
        KrcWarningNotification({
          data: data.url_alias,
          title: 'Link',
          copyKey: 'model',
        })
      }

      if (!isEmptyArray(data.price)) {
        KrcWarningNotification({
          data: data.price,
          title: 'Fiyat',
          copyKey: 'model',
        })
      }

      if (!isEmptyArray(data.quantity_and_ordered_quantity)) {
        KrcWarningNotification({
          data: data.quantity_and_ordered_quantity,
          title: 'Stok',
          copyKey: 'model',
        })
      }
    })
  }

  return (
    <>
      <ProductAvailableContext.Provider
        value={{
          selectedProducts,
          setSelectedProducts,
          clear,
          setClear,
          dateAvailable,
          setDateAvailable,
          updateDateAvailable,
        }}
      >
        {children}
      </ProductAvailableContext.Provider>
    </>
  )
}
