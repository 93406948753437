import React, {FC, useContext, useEffect} from 'react'

import {IBMCampaignSpecialGroupContext} from '../../../../providers/ibm/IBMCampaignSpecialGroupProvider'
import {fetchIBMCampaignGeneralInformation} from '../../../../requests/ibm/IBMCampaignRequest'
import {KrcTabs} from '../../../molecules/KrcTabs'
import {CampaignDetailTab} from './tabs/CampaignDetailTab'
import {CampaignPdpInformationTab} from './tabs/CampaignPdpInformationTab'
import {GeneralInformationTab} from './tabs/GeneralInformationTab'
import {SpecialGroupTab} from './tabs/SpecialGroupTab'
import {SpecialGroupsInformationTab} from './tabs/SpecialGroupsInformationTab'

interface IBMCampaignTabsProps {
  selectedCampaignId: number
}
export const IBMCampaignTabsView: FC<IBMCampaignTabsProps> = ({selectedCampaignId}) => {
  const {setSelectedCampaignId} = useContext(IBMCampaignSpecialGroupContext)
  const [isCampaignManuallyCreated, setIsCampaignManuallyCreated] = React.useState(false)
  useEffect(() => {
    if (!selectedCampaignId) {
      return
    }
    setSelectedCampaignId(selectedCampaignId)
    fetchIBMCampaignGeneralInformation(selectedCampaignId).then((response) => {
      setIsCampaignManuallyCreated(response.data.data.is_manual)
    })
  }, [selectedCampaignId])

  const tabProps = isCampaignManuallyCreated
    ? [
        {
          eventKey: 'general-info',
          title: 'Kampanyalar Sayfası',
          children: <GeneralInformationTab activeCampaignId={selectedCampaignId} />,
          key: 'general-info',
        },
        {
          eventKey: 'campaign-detail',
          title: 'Kampanya Detay',
          children: <CampaignDetailTab activeCampaignId={selectedCampaignId} />,
          key: 'campaign-detail',
        },
        {
          eventKey: 'pdp-information',
          title: 'Kampanya PDP',
          children: <CampaignPdpInformationTab activeCampaignId={selectedCampaignId} />,
          key: 'pdp-information',
        },
      ]
    : [
        {
          eventKey: 'general-info',
          title: 'Kampanyalar Sayfası',
          children: <GeneralInformationTab activeCampaignId={selectedCampaignId} />,
          key: 'general-info',
        },
        {
          eventKey: 'campaign-detail',
          title: 'Kampanya Detay',
          children: <CampaignDetailTab activeCampaignId={selectedCampaignId} />,
          key: 'campaign-detail',
        },
        {
          eventKey: 'pdp-information',
          title: 'Kampanya PDP',
          children: <CampaignPdpInformationTab activeCampaignId={selectedCampaignId} />,
          key: 'pdp-information',
        },
        {
          eventKey: 'special-group-information',
          title: 'Özel Gruplar Bilgisi',
          children: <SpecialGroupsInformationTab activeCampaignId={selectedCampaignId} />,
          key: 'special-group-information',
        },
        {
          eventKey: 'special-groups',
          title: 'Özel Gruplar',
          children: <SpecialGroupTab activeCampaignId={selectedCampaignId} />,
          key: 'special-groups',
        },
      ]

  return (
    <>
      <KrcTabs
        isActiveKey={true}
        tabsClassName={'mb-1'}
        tabsProps={{
          defaultActiveKey: 'general-info',
        }}
        tabProps={tabProps}
      />
    </>
  )
}
