import {IBadge} from '../../components/models/badges/BadgeModel'
import {ReduxAction} from '../../helpers/redux/ReduxAction'
import {
  ADD_BADGE,
  CHANGE_VISIBLE_BADGE_MODAL,
  DELETE_BADGE,
  SET_BADGE,
  SET_BADGES,
  SET_SELECTED_BADGES,
  UPDATE_BADGE,
} from './BadgeActionType'

const initialState = {
  badges: [],
  selectedBadges: [],
  badge: null,
  visibleBadgeModal: false,
}

const badgeReducer = (state = initialState, {type, payload}: ReduxAction) => {
  switch (type) {
    case SET_BADGES:
      return {...state, badges: payload}
    case SET_BADGE:
      return {...state, badge: payload}
    case CHANGE_VISIBLE_BADGE_MODAL:
      return {...state, visibleBadgeModal: payload}
    case ADD_BADGE:
      return {...state, badges: [...state.badges, payload]}
    case UPDATE_BADGE:
      return {
        ...state,
        badges: state.badges.map((badge: IBadge) => {
          if (badge.badge_id !== payload.badgeId) {
            return badge
          }

          return {...badge, ...payload.badge}
        }),
      }
    case DELETE_BADGE:
      return {
        ...state,
        badges: state.badges.filter((badge: IBadge) => badge.badge_id !== payload.badgeId),
      }
    case SET_SELECTED_BADGES:
      return {
        ...state,
        selectedBadges: payload,
      }
    default:
      return state
  }
}

export default badgeReducer
