export const COOKLPUS = 0
export const KARACA_HOME = 1
export const KARACA = 2
export const EMSAN = 3
export const HEPSIBURADA = 4
export const N11 = 5
export const TRENDYOL = 6
export const CICEK_SEPETI = 8
export const AMAZON_TR = 9
export const GITTI_GIDIYOR = 11
export const KARACA_APP = 12
export const PERA_BULVARI = 13
export const HOMEND = 18
export const KASMIR = 22
export const VODAFONE = 23

export const STORES = [
  {
    store_id: COOKLPUS,
    store_id_text: 'Cookplus',
  },
  {
    store_id: KARACA_HOME,
    store_id_text: 'Karaca Home',
  },
  {
    store_id: KARACA,
    store_id_text: 'Karaca',
  },
  {
    store_id: EMSAN,
    store_id_text: 'Emsan',
  },
  {
    store_id: HEPSIBURADA,
    store_id_text: 'Hepsiburada',
  },
  {
    store_id: N11,
    store_id_text: 'N11',
  },
  {
    store_id: TRENDYOL,
    store_id_text: 'Trendyol',
  },
  {
    store_id: CICEK_SEPETI,
    store_id_text: 'Çiçek Sepeti',
  },
  {
    store_id: AMAZON_TR,
    store_id_text: 'Amazon TR',
  },
  {
    store_id: KARACA_APP,
    store_id_text: 'Karaca App',
  },
  {
    store_id: PERA_BULVARI,
    store_id_text: 'Pera Bulvarı',
  },
  {
    store_id: HOMEND,
    store_id_text: 'Homend',
  },
  {
    store_id: GITTI_GIDIYOR,
    store_id_text: 'Gitti Gidiyor',
  },
  {
    store_id: KASMIR,
    store_id_text: 'Kaşmir Halı',
  },
  {
    store_id: VODAFONE,
    store_id_text: 'Vodafone',
  },
]
