import {Form, Formik} from 'formik'
import React, {FC, useContext} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {CategoryProductsProcessContext} from '../../../providers/category/products/CategoryProductsProcessProvider'
import {requestDateFormat} from '../../../service/DateService'
import {KrcDatePicker} from '../../atoms/forms/KrcDatePicker'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'
import {KrcModal} from '../KrcModal'

export const CategoryProductModal: FC = () => {
  const {
    setVisibleCategoryProductModal,
    willBeUpdatedProduct,
    setWillBeUpdatedProduct,
    updateSelectedCategoryProduct,
  } = useContext(CategoryProductsProcessContext)

  return (
    <>
      <KrcModal
        modalProps={{
          onHide: () => {
            setVisibleCategoryProductModal(false)
          },
        }}
        modalHeaderProps={{
          title: 'Kısıtlı Ürünleri Güncelle',
          onHide: () => {
            setVisibleCategoryProductModal(false)
          },
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10 row justify-content-center'>
          {willBeUpdatedProduct && (
            <Formik
              initialValues={willBeUpdatedProduct}
              enableReinitialize
              onSubmit={(values) => {
                updateSelectedCategoryProduct({
                  ...values,
                  date_start: requestDateFormat(values.date_start),
                  date_end: requestDateFormat(values.date_end),
                })
              }}
            >
              {() => (
                <Form className='form row' noValidate>
                  <KrcFormikField name={'date'} required={false} labelTitle={'Başlangıç Tarihi'}>
                    <KrcDatePicker
                      showTime
                      allowClear={false}
                      className={'ml-4'}
                      defaultValue={willBeUpdatedProduct.date_start}
                      onChange={(date, dateString) => {
                        setWillBeUpdatedProduct((data) => {
                          return (
                            data && {
                              ...data,
                              date_start: date ?? undefined,
                            }
                          )
                        })
                      }}
                    ></KrcDatePicker>
                  </KrcFormikField>

                  <KrcFormikField name={'date'} required={false} labelTitle={'Bitiş Tarihi'}>
                    <KrcDatePicker
                      showTime
                      allowClear={false}
                      className={'ml-4'}
                      defaultValue={willBeUpdatedProduct.date_end}
                      onChange={(date, dateString) => {
                        setWillBeUpdatedProduct((data) => {
                          return (
                            data && {
                              ...data,
                              date_end: date ?? undefined,
                            }
                          )
                        })
                      }}
                    ></KrcDatePicker>
                  </KrcFormikField>

                  <KrcFormikField type={'number'} name={'quantity'} labelTitle={'Kampanya Stok'} />

                  <div className='col-md-12 d-flex justify-content-end mt-5'>
                    <button
                      type='submit'
                      className='w-25 btn btn-secondary mr-3'
                      onClick={() => {
                        setVisibleCategoryProductModal(false)
                      }}
                    >
                      İptal
                    </button>
                    <button type='submit' className='w-25 btn btn-primary'>
                      Güncelle
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </KrcModal>
    </>
  )
}
