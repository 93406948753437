import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, message} from 'antd'
import {getUUID} from 'rc-select/es/utils/commonUtil'
import React, {useState} from 'react'

import {deleteBadgeToCategoriesRequest} from '../../../../requests/categories/badges/CategoryBadge'

const {confirm} = Modal

export const useDeleteCategoriesBadge = () => {
  const [clear, setClear] = useState<number>(0)
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([])
  const [willBeDeletedBadgeId, setWillBeDeletedBadgeId] = useState<number | null>()
  const [deleteBadgeKey, setDeleteBadgeKey] = useState<string>('delete-categories-badge-filter')

  const deleteBadgeToCategory = () => {
    if (!willBeDeletedBadgeId) {
      message.warning(
        'Kategori(ler)den badge silebilmek için Badge alanında badge seçilmek zorundadır.'
      )
      return
    }

    if (selectedCategoryIds.length === 0) {
      message.warning('Badge silebilmek için kategori seçilmeli.')
      return
    }

    confirm({
      title: `Kategori(lerden) badge silinecek`,
      icon: <ExclamationCircleOutlined />,
      content: 'Kategori(lerden) badge silinecek emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        const key = 'categories-badges'
        message.loading({
          content: 'Kategorilerden badge siliniyor..',
          key,
        })
        deleteBadgeToCategoriesRequest({
          category_ids: selectedCategoryIds,
          badge_id: willBeDeletedBadgeId,
        })
          .then(() => {
            message.success({
              content: 'Kategorilerden badge silindi.',
              key,
            })
            setClear((data) => data + 1)
            setDeleteBadgeKey((value) => `${value}-${getUUID()}`)
            setWillBeDeletedBadgeId(null)
            setSelectedCategoryIds([])
          })
          .catch(() => {
            message.error({
              content: 'Kategorilerden badge silinemedi. Lütfen tekrar deneyin.',
              key,
            })
          })
      },
    })
  }

  return {
    willBeDeletedBadgeId,
    clear,
    deleteBadgeKey,
    setSelectedCategoryIds,
    setWillBeDeletedBadgeId,
    deleteBadgeToCategory,
  }
}
