import Editor, {EditorProps} from '@monaco-editor/react'
import React, {FC} from 'react'

import {useEditor} from '../../hooks/components/useEditor'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {IKrcEditorModel} from '../../models/components/KrcEditorModel'

const editorConfig = {
  default: {
    theme: 'light',
  } as EditorProps,
}

export const KrcEditor: FC<EditorProps & IKrcEditorModel> = ({
  config = 'default',
  onChange,
  ...props
}) => {
  const currentProps = useKrcProp({config: editorConfig[config], props}).getCurrentProps()
  const {handleOnChange} = useEditor()

  return (
    <Editor
      onChange={(value: any) => {
        if (!onChange) {
          return
        }
        handleOnChange(value, onChange)
      }}
      {...currentProps}
    />
  )
}
