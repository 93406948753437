import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''

export const BANNER_URL = `${API_URL}/banners`

export const getBannerRequest = (bannerId: number) => {
  return axios.get(`${BANNER_URL}/${bannerId}`)
}

export const searchBannerRequest = (search: string) => {
  return axios.get(`${BANNER_URL}/search`, {
    params: {name: search},
  })
}

export const updateBannerRequest = (
  bannerId: number,
  body: {name: string; store_id: number | null}
) => {
  return axios.put(`${BANNER_URL}/${bannerId}`, body)
}

export const createBannerGroupRequest = (bannerId: number, body: {name: string}) => {
  return axios.post(`${BANNER_URL}/${bannerId}/groups`, body)
}

export const getBannerGroupRequest = (bannerId: number, bannerGroupId: number) => {
  return axios.get(`${BANNER_URL}/${bannerId}/groups/${bannerGroupId}`)
}
