import {Image} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'

import {PACKET_CAMPAIGN_TYPE} from '../../../enums/IBMCampaignUpdateTypeEnum'
import {initialMetaData} from '../../../providers/category/sorting/CategoryProductSortingProvider'
import {fetchIBMCampaignsSpecialGroupProducts} from '../../../requests/ibm/IBMCampaignRequest'
import {viewDateFormat} from '../../../service/DateService'
import {paginationMetaType} from '../../models/GeneralModel'
import {IBmCampaignDetailUrlType} from '../../models/ibm/IBMCampaignDetail'

export const useIBMCampaignSpecialGroupProducts = () => {
  const {campaignId} = useParams<IBmCampaignDetailUrlType>()

  const [campaignSpecialGroupProducts, setCampaignSpecialGroupProducts] = useState<any[]>([])
  const [specialGroupProductsMeta, setSpecialGroupProductsMeta] =
    useState<paginationMetaType>(initialMetaData)
  const initialRef = useRef(0)
  const fetchRef = React.useRef(0)
  const [showSpecialGroupUpdateModal, setShowSpecialGroupUpdateModal] = useState(false)
  const [isCampaignTypePacket, setIsCampaignTypePacket] = useState(false)
  const [specialGroupSortedProducts, setSpecialGroupSortedProducts] = useState<any[]>([])

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const parentCampaignId = queryParams.get('campaignId')

  const specialGroupProductsColumn = React.useMemo(() => {
    return [
      {
        Header: 'Görsel',
        accessor: 'image',
        Cell: ({row}: any) => (
          <Image
            preview={{
              maskClassName: 'rounded-circle',
            }}
            width={60}
            height={60}
            className={'rounded-circle'}
            src={'https://cdn.karaca.com/image/' + row.original.image}
            style={{minWidth: 52}}
          />
        ),
        maxWidth: 52,
        manualWidth: 52,
      },
      {
        Header: 'ID',
        accessor: 'product_id',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'model',
        accessor: 'model',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
        maxWidth: 150,
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'start_date',
        maxWidth: 150,
        manualWidth: 150,
        Cell: ({row}: any) => (
          <div>
            {row.original.start_date ? (
              viewDateFormat(row.original.start_date, 'DD-MM-YYYY')
            ) : (
              <span className={'text-danger'}>Belirtilmemiş</span>
            )}
          </div>
        ),
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'end_date',
        maxWidth: 150,
        manualWidth: 150,
        Cell: ({row}: any) => (
          <div>
            {row.original.end_date ? (
              viewDateFormat(row.original.end_date, 'DD-MM-YYYY')
            ) : (
              <span className={'text-danger'}>Belirtilmemiş</span>
            )}
          </div>
        ),
      },
      {
        Header: 'Sıra',
        accessor: 'sort_order',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'Durum',
        accessor: 'status',
        Cell: ({row}: any) => <div>{row.original.status ? 'Aktif' : 'Pasif'}</div>,
      },
    ]
  }, [])
  const specialGroupProductsSortColumn = React.useMemo(() => {
    return [
      {
        Header: 'Görsel',
        accessor: 'image',
        Cell: ({row}: any) => (
          <Image
            preview={{
              maskClassName: 'rounded-circle',
            }}
            width={60}
            height={60}
            className={'rounded-circle'}
            src={'https://cdn.karaca.com/image/' + row.original.image}
            style={{minWidth: 52}}
          />
        ),
        maxWidth: 52,
        manualWidth: 52,
      },
      {
        Header: 'ID',
        accessor: 'product_id',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'model',
        accessor: 'model',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
        maxWidth: 150,
      },
    ]
  }, [])

  useEffect(() => {
    setCategorySpecialGroupProducts()
  }, [])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    setCategorySpecialGroupProducts()
  }, [specialGroupProductsMeta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (specialGroupProductsMeta.page !== 1) {
      setSpecialGroupProductsMeta({...specialGroupProductsMeta, page: 1})
      return
    }

    setCategorySpecialGroupProducts()
  }, [specialGroupProductsMeta.limit])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  const setCategorySpecialGroupProducts = () => {
    if (!campaignId) {
      return
    }
    fetchRef.current += 1
    const fetchId = fetchRef.current
    fetchIBMCampaignsSpecialGroupProducts(Number(campaignId), specialGroupProductsMeta).then(
      (res) => {
        if (fetchId !== fetchRef.current) {
          return
        }

        const _meta = res.data.meta
        const linkedCampaignLists = res.data.campaigns

        if (parentCampaignId) {
          const linkedCampaign = linkedCampaignLists.find(
            (campaign: any) => campaign.id === Number(parentCampaignId)
          )
          setIsCampaignTypePacket(linkedCampaign.type === PACKET_CAMPAIGN_TYPE)
        } else {
          setIsCampaignTypePacket(false)
        }

        setSpecialGroupProductsMeta({..._meta, limit: _meta.per_page, page: _meta.current_page})

        setCampaignSpecialGroupProducts(res.data.data)
      }
    )
  }

  return {
    campaignSpecialGroupProducts,
    setCampaignSpecialGroupProducts,
    specialGroupProductsMeta,
    setSpecialGroupProductsMeta,
    specialGroupProductsColumn,
    setCategorySpecialGroupProducts,
    showSpecialGroupUpdateModal,
    setShowSpecialGroupUpdateModal,
    isCampaignTypePacket,
    campaignId,
    setSpecialGroupSortedProducts,
    specialGroupSortedProducts,
    specialGroupProductsSortColumn,
  }
}
