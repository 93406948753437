import {RollbackOutlined} from '@ant-design/icons'
import React, {FC, useContext, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {SPECIAL_GROUPS} from '../../../../enums/IBMCampaignTabTypeEnums'
import {IBMCampaignSpecialGroupContext} from '../../../../providers/ibm/IBMCampaignSpecialGroupProvider'
import {IBMCampaignSpecialGroupProvider} from '../../../../providers/ibm/IBMCampaignSpecialGroupProvider'
import {fetchIBMCampaignGeneralInformation} from '../../../../requests/ibm/IBMCampaignRequest'
import {getIBMCampaignSpecialGroupsExportFrontendUrl} from '../../../../requests/ibm/IBMCampaignSpecialGroupRequest'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {IBMSpecialGroupSortModal} from '../../../modals/ibm/sort/IBMSpecialGroupSortModal'
import {UpdateSpecialGroupModal} from '../../../modals/ibm/special-group/UpdateSpecialGroupModal'
import {IBmCampaignDetailUrlType} from '../../../models/ibm/IBMCampaignDetail'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {IBMCampaignTabsView} from './IBMCampaignTabsView'

export const ToolBarRightField: FC = () => {
  const {campaignId} = useParams<IBmCampaignDetailUrlType>()
  const [isCampaignManuallyCreated, setIsCampaignManuallyCreated] = React.useState(false)
  const navigate = useNavigate()
  const {setIsShowSortingCampaignSpecialGroupModal, pageType} = useContext(
    IBMCampaignSpecialGroupContext
  )
  useEffect(() => {
    if (!campaignId) {
      return
    }
    fetchIBMCampaignGeneralInformation(Number(campaignId)).then((response) => {
      setIsCampaignManuallyCreated(response.data.data.is_manual)
    })
  }, [campaignId])

  if (!isCampaignManuallyCreated) {
    return (
      <div className='d-flex align-items-center py-1'>
        <KrcButton
          size={'middle'}
          className={'me-4 d-flex align-items-center justify-content-center'}
          type={'default'}
          icon={<RollbackOutlined />}
          onClick={() => {
            navigate(-1)
          }}
          id='kt_toolbar_primary_button'
        >
          {'Kampanyalar Listesine Dön'}
        </KrcButton>
        {pageType === SPECIAL_GROUPS ? (
          <>
            <a
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-3'
              href={getIBMCampaignSpecialGroupsExportFrontendUrl(Number(campaignId))}
              target={'_blank'}
              rel='noreferrer'
            >
              Özel Grupları Dışarı Aktar
            </a>
            <KrcButton
              size={'middle'}
              onClick={() => {
                setIsShowSortingCampaignSpecialGroupModal(true)
              }}
              id='kt_toolbar_primary_button'
            >
              {'Özel Grupları Sırala'}
            </KrcButton>
          </>
        ) : null}
      </div>
    )
  } else {
    return (
      <div className='d-flex align-items-center py-1'>
        <KrcButton
          size={'middle'}
          className={'me-4 d-flex align-items-center justify-content-center'}
          type={'default'}
          icon={<RollbackOutlined />}
          onClick={() => {
            navigate(-1)
          }}
          id='kt_toolbar_primary_button'
        >
          {'Kampanyalar Listesine Dön'}
        </KrcButton>
      </div>
    )
  }
}

export const IBMCampaignDetailWithoutWrapper: FC = () => {
  const {campaignId} = useParams<IBmCampaignDetailUrlType>()
  const {
    showSpecialGroupUpdateModal,
    setShowSpecialGroupUpdateModal,
    isShowSortingCampaignSpecialGroupModal,
    setIsShowSortingCampaignSpecialGroupModal,
  } = useContext(IBMCampaignSpecialGroupContext)

  return (
    <>
      <Toolbar rightField={<ToolBarRightField />}></Toolbar>
      <PageTitle>IBM Kampanyaları - Kampanya Detay</PageTitle>
      <IBMCampaignTabsView selectedCampaignId={Number(campaignId)} />
      {isShowSortingCampaignSpecialGroupModal && (
        <IBMSpecialGroupSortModal onHide={() => setIsShowSortingCampaignSpecialGroupModal(false)} />
      )}
      {showSpecialGroupUpdateModal && (
        <UpdateSpecialGroupModal onHide={() => setShowSpecialGroupUpdateModal(false)} />
      )}
    </>
  )
}

export const IBMCampaignDetailWrapper: FC = () => {
  return (
    <IBMCampaignSpecialGroupProvider>
      <IBMCampaignDetailWithoutWrapper />
    </IBMCampaignSpecialGroupProvider>
  )
}
