import {SelectProps, Spin} from 'antd'
import React, {FC, useEffect} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useCategoryFilter} from '../../hooks/categories/useCategoryFilter'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {ICategoryFilter} from '../../models/categories/CategoryFilterModel'

const categoryFilterConfig = {
  default: {
    style: {width: '100%'},
    mode: 'multiple',
    size: 'middle',
    placeholder: 'Kategori Filtre Listesi',
    allowClear: false,
  },
}

export const CategoryFilter: FC<ICategoryFilter & SelectProps> = ({
  onSelected,
  categoryType,
  dataType = 'value',
  children,
  clear,
  withPath,
  ...props
}) => {
  const currentProps = useKrcProp({config: categoryFilterConfig.default, props}).getCurrentProps()
  const {fetching, options, handleSearch, handleOnChange, selectedCategoryIds, selectedItems} =
    useCategoryFilter({
      categoryType,
      withPath,
    })
  const initialRef = React.useRef(0)

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current = 1

      return
    }

    if (dataType === 'value') {
      onSelected(selectedCategoryIds)
      return
    }

    onSelected(selectedItems)
  }, [selectedCategoryIds])

  return (
    <>
      <KrcSelect
        clear={clear}
        {...currentProps}
        showArrow
        filterOption={false}
        labelInValue
        onSearch={handleSearch}
        onChange={handleOnChange}
        options={options}
        notFoundContent={fetching ? <Spin size='small' /> : null}
      >
        {children}
      </KrcSelect>
    </>
  )
}
