import React, {useContext} from 'react'

import {DealListContext} from '../../../../providers/DealListProvider'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export function DealsList() {
  const {columns, deals} = useContext(DealListContext)

  return (
    <>
      <div className={`card deals-list`}>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <KrcTable columns={columns} data={deals} />
          </div>
        </div>
      </div>
    </>
  )
}
