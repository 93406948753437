import {useState} from 'react'

const badgeTypes = [
  {value: 1, label: 'Fiyat Badge'},
  {value: 2, label: 'Ürün Badge'},
  {value: 3, label: 'Kampanya Badge'},
  {value: 4, label: 'Kargo Badge'},
  {value: 5, label: 'İndirim Badge'},
]

export const useBadgeTypeFilter = () => {
  const [badgeTypeIds, setBadgeTypeIds] = useState<number[]>([])

  function handleChange(payload: number[]) {
    if (!payload) {
      return
    }

    setBadgeTypeIds(payload)
  }

  return {
    handleChange,
    badgeTypeIds,
    options: badgeTypes,
  }
}
