import {Space, Spin} from 'antd'
import React, {FC} from 'react'

export const LoadingInTheMiddle: FC = () => {
  return (
    <div className={'d-flex justify-content-center align-items-center h-100'}>
      <Space size='middle'>
        <Spin size='default' />
      </Space>
    </div>
  )
}
