import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {useCampaign} from '../../../../hooks/categories/campaigns/useCampaign'
import {ExcelExport} from '../../../../layout/excel/ExcelExportWrapper'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'

export const CampaignApprovalProductExportWrapper: FC = () => {
  const {exportCategoryDetailPendingApprovalProducts, downloadExcelUrl, exportErrorMessage} =
    useCampaign()

  return (
    <>
      <Toolbar />
      <PageTitle>Kategori Onay Bekleyen Ürünleri Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport
              handleExportData={exportCategoryDetailPendingApprovalProducts}
              downloadExcelUrl={downloadExcelUrl}
              errorMessage={exportErrorMessage}
            />
          }
        ></Route>
      </Routes>
    </>
  )
}
