import React from 'react'

import {GameScoreListFilter} from '../../../organisms/filters/GameScoreListFilter'
import {GameScoreBoardListTable} from '../../../organisms/tables/GameScoreBoardListTable'

export const GameScoreBoardList: React.FC = () => {
  return (
    <>
      <GameScoreListFilter />
      <GameScoreBoardListTable />
    </>
  )
}
