import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import cn from 'classnames'
import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {LandingContext, LandingProvider} from '../../../../providers/LandingProvider'
import {KrcAlert} from '../../../atoms/KrcAlert'
import {KrcTabs} from '../../../molecules/KrcTabs'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {LandingDetail} from './LandingDetail'
import {LandingStatic} from './LandingStatic'

const {confirm} = Modal

const LandingBody: FC = () => {
  return (
    <>
      <KrcTabs
        isActiveKey={true}
        tabsProps={{defaultActiveKey: 'home'}}
        tabProps={[
          {
            eventKey: 'home',
            title: 'Sayfa bilgisi',
            children: <LandingDetail />,
          },
          {
            eventKey: 'static-page',
            title: 'Statik sayfa',
            children: <LandingStatic />,
          },
        ]}
      />
    </>
  )
}

const LandingWrapperWithProvider: FC = () => {
  const {isValid, landing, updateLanding, createLanding} = useContext(LandingContext)

  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <div
            className={cn({
              'btn btn-sm btn-primary': true,
              disabled: !isValid,
            })}
            onClick={() => {
              // if (isUpdateForm) {
              confirm({
                title: `Yapılan tüm değişiklikler kaydedilecek emin misin? `,
                icon: <ExclamationCircleOutlined />,
                content:
                  'Yapılan tüm değişiklikler kayıt edilip yayınalacaktır geri dönüşü olmayabilir emin misiniz?',
                okText: 'Tamam',
                cancelText: 'İptal',
                onOk() {
                  if (landing) {
                    updateLanding()
                    return
                  }
                  createLanding()
                },
              })
            }}
            id='kt_toolbar_primary_button'
          >
            {landing ? 'Güncelle' : 'Oluştur'}
          </div>
        </div>
      </Toolbar>
      <PageTitle>Landing</PageTitle>
      {landing?.is_old_landing ? (
        <KrcAlert
          message='DİKKAT! Bu landing, eski landing yapısına aittir. Bu yüzden yaptığınız her değişiklik web tarafında gözükmeyebilir. Gözükmediği durumda eski landing içeriklerini, oluşturduğunuz yeni landing yapısına taşıyabilirsiniz.'
          type='error'
          style={{borderRadius: '15px', marginBottom: '20px'}}
        />
      ) : (
        <></>
      )}
      <LandingBody />
    </>
  )
}

export const LandingWrapper: FC = () => {
  return (
    <LandingProvider>
      <LandingWrapperWithProvider />
    </LandingProvider>
  )
}
