import React, {useContext} from 'react'

import {GameCardContext} from '../../../../providers/games/GameCardProvider'
import {CreateGameCardDrawer} from '../../../drawers/games/cards/CreateGameCardDrawer'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const GameCardList = () => {
  const {columns, gameCards} = useContext(GameCardContext)

  return (
    <>
      <KrcTable columns={columns} data={gameCards} outerHeight={210} />
      <CreateGameCardDrawer />
    </>
  )
}
