import {ReduxAction} from '../../helpers/redux/ReduxAction'
import {SET_DEAL, SET_WILL_BE_ADDED_DEAL} from './DealActionType'

const initialState = {deals: [], deal: null, willBeAddedDeal: null}

const dealReducer = (state = initialState, {type, payload}: ReduxAction) => {
  switch (type) {
    case SET_DEAL:
      return {...state, deal: payload}
    case SET_WILL_BE_ADDED_DEAL:
      return {...state, willBeAddedDeal: payload}
    default:
      return state
  }
}

export default dealReducer
