import {DeleteOutlined} from '@ant-design/icons'
import {Avatar, Popconfirm, Tooltip, message} from 'antd'
import React, {FC, createContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Cell, Column, Row} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {gameCardInitialValueType} from '../../components/models/games/GameCardModel'
import {gameType, gameUrlType} from '../../components/models/games/GameModel'
import {errorMessage} from '../../helpers/ErrorHelper'
import {
  createGameCardRequest,
  deleteGameCardRequest,
  getGameCardsRequest,
} from '../../requests/games/GameCardRequest'
import {getGameDetailRequest} from '../../requests/games/GameRequest'

interface IGameCardContext {
  columns: Column<any>[]
  game?: gameType
  gameCards: any
  setGameCards: React.Dispatch<React.SetStateAction<any>>
  gameCardDrawerVisible: boolean
  setGameCardDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  createGameCard: (willBeCreatedGame: any) => void
  getGameCards: () => void
}

export const GameCardContext = createContext<IGameCardContext>({
  columns: [],
  game: undefined,
  gameCards: [],
  setGameCards: () => {},
  gameCardDrawerVisible: false,
  setGameCardDrawerVisible: () => {},
  createGameCard: () => {},
  getGameCards: () => {},
})

export const GameCardProvider: FC = ({children}) => {
  const {gameId} = useParams<gameUrlType>()
  const [game, setGame] = useState<gameType>()

  const [gameCards, setGameCards] = useState<gameType[]>([])
  const [gameCardDrawerVisible, setGameCardDrawerVisible] = useState<boolean>(false)

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'game_card_id',
      },
      {
        Header: 'Başlık/Açıklama',
        accessor: 'game_card_title',
      },
      {
        Header: 'Deeplink',
        accessor: 'game_card_deeplink',
      },
      {
        Header: 'Görsel',
        accessor: 'game_card_image',
        Cell: ({row}: Cell<Row>) => (
          <Avatar size={52} src={row.values.game_card_image} style={{minWidth: 52}} />
        ),
        maxWidth: 52,
        manualWidth: 52,
      },
      {
        Header: () => <div style={{textAlign: 'right'}}>Aksiyon</div>,
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        accessor: 'col5',
        id: 'click-me-button',
        Cell: ({row}: Cell<Row>) => (
          <div className='d-flex flex-row text-end justify-content-end'>
            <Tooltip title={'Sil'}>
              <Popconfirm
                title='Bu oyun kartını silmek istediğinden emin misin ?'
                onConfirm={(e) => deleteGameCard(row.values.game_card_id)}
                // okButtonProps={{loading: confirmLoading}}
                okText='Evet'
                cancelText='Hayır'
              >
                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                  <DeleteOutlined />
                </div>
              </Popconfirm>
            </Tooltip>
          </div>
        ),
        Filter: KrcEmptyFilter,
      },
    ],
    []
  )

  useEffect(() => {
    getGameDetail()
    getGameCards()
  }, [])

  const getGameDetail = () => {
    getGameDetailRequest(Number(gameId)).then((res) => {
      const resData = res.data.data ?? []

      setGame(resData)
    })
  }

  const getGameCards = () => {
    getGameCardsRequest(Number(gameId)).then((res) => {
      const resData = res.data.data ?? []

      setGameCards(resData)
    })
  }

  const createGameCard = (willBeCreatedGame: gameCardInitialValueType) => {
    const key = 'create-game-card'

    message.loading({content: 'Oyun Kartı oluşturuluyor...', key, duration: 0})

    createGameCardRequest(Number(gameId), willBeCreatedGame)
      .then((res) => {
        message.success({content: 'Oyun Kartı Başarıyla oluşturuldu.', key})
        getGameCards()
        setGameCardDrawerVisible(false)
      })
      .catch(() => {
        message.error({content: 'Oyun Kartı Başarıyla oluşturulamadı.', key})
      })
  }

  const deleteGameCard = (gameCardId: number) => {
    const key = 'delete-game-card'

    message.loading({
      content: 'Oyun Kartı siliniyor...',
      key,
    })

    deleteGameCardRequest(Number(gameId), gameCardId)
      .then(() => {
        message.success({
          content: 'Oyun Kartı silindi.',
          key,
        })

        getGameCards()
      })
      .catch((e) => {
        errorMessage(e)
        message.destroy(key)
      })
  }

  return (
    <>
      <GameCardContext.Provider
        value={{
          columns,
          game,
          gameCards,
          setGameCards,
          gameCardDrawerVisible,
          setGameCardDrawerVisible,
          createGameCard,
          getGameCards,
        }}
      >
        {children}
      </GameCardContext.Provider>
    </>
  )
}
