import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {ScoreBoardWrapper} from '../components/pages/games/scores/ScoreBoardWrapper'

export const ScoreBoardRoutes: FC = () => {
  return (
    <>
      <>
        <Routes>
          <Route path={'/'} element={<ScoreBoardWrapper />} />
        </Routes>
      </>
    </>
  )
}
