import React, {useEffect, useMemo, useState} from 'react'

import {IOrderModel} from '../../../../../components/models/order/OrderModel'
import {orderSubBlockModel} from '../../../../models/order/OrderSubBlockModel'
import {KrcTable} from '../../../../organisms/tables/KrcTable'

const CustomerGeneralInformation: React.FC<IOrderModel> = (props) => {
  const [data, setData] = useState<orderSubBlockModel[]>([])

  useEffect(() => {
    setData([
      {
        key: 1,
        field: 'Müşteri Bilgileri',
        value: props.order.invoiceCustomer.firstName + ' ' + props.order.invoiceCustomer.lastName,
      },
      {
        key: 2,
        field: 'Müşteri Grubu',
        value: '#',
      },
      {
        key: 3,
        field: 'E-posta Adresi',
        value: props.order.invoiceCustomer.email,
      },
      {
        key: 4,
        field: 'Telefon',
        value: props.order.invoiceCustomer.phone,
      },
      {
        key: 5,
        field: 'IP Adresi',
        value: '#',
      },
      {
        key: 6,
        field: 'Tarayıcı Bilgisi',
        value: '#',
      },
    ])
  }, [props.order.invoiceCustomer])

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'field',
        width: '40%',
      },
      {
        Header: '',
        accessor: 'value',
      },
    ]
  }, [])

  return (
    <>
      {props.order && (
        <KrcTable
          columns={columns}
          data={data}
          showHeader={false}
          cellClassName='font-weight-normal'
          stylesProps={{style: {margin: 0, padding: 0}, className: ['order-block-table']}}
        />
      )}
    </>
  )
}

export default CustomerGeneralInformation
