import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {FooterModulesContext} from '../../../providers/FooterModulesProvider'
import {ICreateFooterModuleModal} from '../../models/footer/StoreModulesModel'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'
import {KrcModal} from '../KrcModal'

const initValues = {
  module_name: '',
  module_url: '',
  module_status: 1,
  module_sort: '',
}

export const FooterModuleCreateModal: FC<ICreateFooterModuleModal> = ({onSubmit, onHide}) => {
  const {updateOrCreateModuleSchema, createNewModuleToStore, currentStore} =
    useContext(FooterModulesContext)
  const [initialValues, setInitialValues] = useState<any>(initValues)

  useEffect(() => {
    setInitialValues(initValues)
  }, [])

  const handleOnSubmit = (data: FormikValues) => {
    createNewModuleToStore({
      module_name: data.module_name,
      url: data.module_url,
      status: data.module_status,
      sort: data.module_sort,
    })
  }
  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
        }}
        modalHeaderProps={{
          title: `Yeni Modül Oluştur (${currentStore?.store_name})`,
          onHide,
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10'>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={updateOrCreateModuleSchema}
            validateOnMount={true}
          >
            {() => {
              return (
                <Form className='form mt-6 space-between'>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'module_name'}
                        labelTitle={'Modül Adı'}
                        required
                        tooltipProps={{
                          status: true,
                          text: 'Modülün adını giriniz.',
                        }}
                      />
                    </div>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'module_url'}
                        labelTitle={'Modül Url'}
                        tooltipProps={{
                          status: true,
                          text: 'Modülün url adresini giriniz.',
                        }}
                      />
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'number'}
                        name={'module_sort'}
                        labelTitle={'Modül Sıralaması'}
                        required
                        tooltipProps={{
                          status: true,
                          text: 'Modülün sıralamasını giriniz.',
                        }}
                      />
                    </div>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'w-100'}
                        name={'module_status'}
                        labelTitle={'Durum'}
                        required
                        tooltipProps={{
                          status: true,
                          text: 'Modülün durumunu seçiniz.',
                        }}
                      >
                        <Field as='select' name='module_status' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Aktif</option>
                          <option value={0}>Pasif</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                  </div>
                  <div className={'d-flex justify-content-end mt-7'}>
                    <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                      Kaydet
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
