import {OptionData} from 'rc-select/lib/interface'
import {useEffect, useRef, useState} from 'react'

import {fetchOrderStatusOptions} from '../../../requests/orders/OrderRequest'

export const useOrderStatusTypeFilter = () => {
  const [orderStatusTypeId, setOrderStatusTypeId] = useState<number>()
  const [fetching, setFetching] = useState<boolean>(false)
  const [options, setOptions] = useState<{label: string; value: number}[]>([])
  const fetchRef = useRef(0)

  useEffect(() => {
    loadOptions()
  }, [])

  const loadOptions = () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    setOptions([])
    setFetching(true)

    fetchOrderStatusOptions().then((res) => {
      if (fetchId !== fetchRef.current) {
        return
      }
      setOptions(res.data.data)
      setFetching(false)
    })
  }

  const handleOnChange = (payload: Array<OptionData>) => {
    if (!payload) {
      return
    }

    setOrderStatusTypeId(Number(payload))
  }

  return {
    orderStatusTypeId,
    handleOnChange,
    fetching,
    options,
  }
}
