import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
export const BADGE_URL = `${API_URL}/badges`

export function getBadgesRequest(
  filter: object = {},
  {page, limit}: paginationMetaType,
  relations?: string[]
) {
  return axios.get(`${BADGE_URL}`, {
    params: {
      page,
      limit,
      ...filter,
      with: relations,
    },
  })
}

export function getBadgeTiedListRequest(badgeId: number, meta: paginationMetaType, filter: any[]) {
  return axios.get(`${BADGE_URL}/${badgeId}/tied`, {
    params: {
      limit: meta.limit,
      page: meta.page,
      ...filter,
    },
  })
}

export function getBadgeRequest() {
  return axios.get(BADGE_URL)
}

export function createBadgeRequest(body: any) {
  return axios.post(BADGE_URL, body)
}

export function updateBadgeRequest(badgeId: number, body: any) {
  return axios.put(`${BADGE_URL}/${badgeId}`, body)
}

export function deleteBadgeRequest(badgeId: number) {
  return axios.delete(`${BADGE_URL}/${badgeId}`)
}

export function multiDeleteBadgeRequest(badgeIds: number[]) {
  return axios.delete(`${BADGE_URL}/multi`, {data: {badge_ids: badgeIds}})
}
