import {Route, Routes} from 'react-router-dom'

import {CategoriesBadgeWrapper} from '../components/pages/categories/badge/CategoriesBadgeWrapper'
import {CampaignApprovalProductExportWrapper} from '../components/pages/categories/campaigns/exports/CampaignApprovalProductExportWrapper'
import {CampaignHistoryListApprovalProductExportWrapper} from '../components/pages/categories/campaigns/exports/CampaignHistoryListApprovalProductExportWrapper'
import {CampaignListApprovalProductsExportWrapper} from '../components/pages/categories/campaigns/exports/CampaignListApprovalProductsExportWrapper'
import {CampaignSalesProductsExportWrapper} from '../components/pages/categories/campaigns/exports/CampaignSalesProductsExportWrapper'
import {CampaignWaitingApprovalImportWrapper} from '../components/pages/categories/campaigns/import/CampaignWaitingApprovalImportWrapper'
import {CampaignWaitingApprovalImporting} from '../components/pages/categories/campaigns/import/CampaignWaitingApprovalImporting'
import {CampaignPendingApprovalListWrapper} from '../components/pages/categories/campaigns/list/CampaignPendingApprovalListWrapper'
import {CampaignPendingApprovalProductsWrapper} from '../components/pages/categories/campaigns/products/CampaignPendingApprovalProductsWrapper'
import {CategoryDetailWrapper} from '../components/pages/categories/detail/CategoryDetailWrapper'
import {CategoryListWrapper} from '../components/pages/categories/lists/CategoryListWrapper'
import {CategoryExportWrapper} from '../components/pages/categories/lists/exports/CategoryExportWrapper'
import {CategoryListProductsExportWrapper} from '../components/pages/categories/lists/exports/CategoryListProductsExportWrapper'
import {CategoryMapsWrapper} from '../components/pages/categories/maps/CategoryMapWrapper'
import {CategoryProductsProcessWrapper} from '../components/pages/categories/products/CategoryProductsProcessWrapper'
import {CategoryProductsExportWrapper} from '../components/pages/categories/products/exports/CategoryProductsExportWrapper'
import {CategoryProductSortingWrapper} from '../components/pages/categories/products/sorting/CategoryProductSortingWrapper'
import {CategorySortingListWrapper} from '../components/pages/categories/sortings/CategorySortingListWrapper'
import {SortingWeightExportWrapper} from '../components/pages/sorting/SortingWeightExportWrapper'
import {SortingWeightWrapper} from '../components/pages/sorting/SortingWeightWrapper'

export const CategoryRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/sales/products/export'} element={<CampaignSalesProductsExportWrapper />} />
        <Route
          path={'/campaigns-waiting-approval/export'}
          element={<CampaignListApprovalProductsExportWrapper />}
        />
        <Route
          path={'/campaigns-waiting-approval/history/export'}
          element={<CampaignHistoryListApprovalProductExportWrapper />}
        />
        <Route
          path={'/campaigns-waiting-approval/history'}
          element={<CampaignPendingApprovalListWrapper />}
        />
        <Route
          path={'/campaigns-waiting-approval/import/:importId/importing'}
          element={<CampaignWaitingApprovalImporting />}
        />
        <Route
          path={'/campaigns-waiting-approval/import'}
          element={<CampaignWaitingApprovalImportWrapper />}
        />
        <Route
          path={'/campaigns-waiting-approval'}
          element={<CampaignPendingApprovalListWrapper />}
        />
        <Route path={'/:categoryId/sorting'} element={<CategoryProductSortingWrapper />} />
        <Route
          path={'/:categoryId/products/pending-approvals/export'}
          element={<CampaignApprovalProductExportWrapper />}
        />
        <Route
          path={'/:categoryId/products/pending-approvals/history'}
          element={<CampaignPendingApprovalProductsWrapper />}
        />
        <Route
          path={'/:categoryId/products/pending-approvals'}
          element={<CampaignPendingApprovalProductsWrapper />}
        />
        <Route path={'/export'} element={<CategoryExportWrapper />} />
        <Route path={'/export-products'} element={<CategoryListProductsExportWrapper />} />
        <Route path={'/:categoryId/products/export'} element={<CategoryProductsExportWrapper />} />
        <Route path={'/maps'} element={<CategoryMapsWrapper />} />
        <Route path={'/'} element={<CategoryListWrapper />} />
        <Route path={'/sorting-weights'} element={<SortingWeightWrapper />} />
        <Route path={'/sorting-weights/export'} element={<SortingWeightExportWrapper />} />
        <Route path={'/details/'}>
          <Route path=':categoryId' element={<CategoryDetailWrapper type={'update'} />} />
          <Route path=':categoryId/products' element={<CategoryProductsProcessWrapper />} />
          <Route path='' element={<CategoryDetailWrapper type={'create'} />} />
        </Route>
        <Route path={'/sorts'} element={<CategorySortingListWrapper />} />
        <Route path={'/badges'} element={<CategoriesBadgeWrapper />} />
      </Routes>
    </>
  )
}
