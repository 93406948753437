import {message} from 'antd'
import _ from 'lodash'
import {FC, createContext, useState} from 'react'

import {
  activeProductRequestType,
  activeProductType,
  deactivateProductRequestType,
  deactivateProductType,
  deleteWillBePassiveProductType,
  willBeActiveProductType,
  willBePassiveProductType,
} from '../components/models/products/passives/PassiveProductModel'
import {
  passiveProductActivateRequest,
  passiveProductStoreRequest,
} from '../requests/products/passive_products/PassiveProductRequest'

type passiveProductContextType = {
  willBeActiveProducts?: willBeActiveProductType[]
  willBePassiveProducts?: willBePassiveProductType[]
  addWillBeActiveProduct: (data: willBeActiveProductType | willBeActiveProductType[]) => void
  deleteWillBeActiveProduct: (data: willBeActiveProductType) => void
  addWillBePassiveProduct: (data: willBePassiveProductType | willBePassiveProductType[]) => void
  deleteWillBePassiveProduct: (data: deleteWillBePassiveProductType) => void
  deactivateProduct: (data: deactivateProductType[]) => void
  activeProduct: (data: activeProductType[]) => void
}

export const PassiveProductContext = createContext<passiveProductContextType>({
  addWillBeActiveProduct: () => {},
  deleteWillBeActiveProduct: () => {},
  addWillBePassiveProduct: () => {},
  deleteWillBePassiveProduct: () => {},
  deactivateProduct: () => {},
  activeProduct: () => {},
})

export const PassiveProductProvider: FC = ({children}) => {
  const [willBeActiveProducts, setWillBeActiveProducts] = useState<willBeActiveProductType[]>([])
  const [willBePassiveProducts, setWillBePassiveProducts] = useState<willBePassiveProductType[]>([])

  const addWillBeActiveProduct = (payload: willBeActiveProductType | willBeActiveProductType[]) => {
    if (Array.isArray(payload)) {
      setWillBeActiveProducts((data) => [...data, ...payload])
      return
    }
    setWillBeActiveProducts((data) => [...data, payload])
  }

  const deleteWillBeActiveProduct = (payload: willBeActiveProductType) => {
    setWillBeActiveProducts((data) => {
      return data.filter((item) => {
        if (item.productId === payload.productId) {
          return item.storeId !== payload.storeId
        }

        return true
      })
    })
  }

  const addWillBePassiveProduct = (
    payload: willBePassiveProductType | willBePassiveProductType[]
  ) => {
    if (Array.isArray(payload)) {
      setWillBePassiveProducts((data) => [...data, ...payload])
      return
    }

    setWillBePassiveProducts((data) => [...data, payload])
  }

  const deleteWillBePassiveProduct = (payload: deleteWillBePassiveProductType) => {
    setWillBePassiveProducts((data) => {
      return data.filter((item) => {
        if (item.id === payload.productId) {
          return item.store_id !== payload.storeId
        }

        return true
      })
    })
  }

  const deactivateProduct = (payload: deactivateProductType[]) => {
    let newPassiveProduct = [] as deactivateProductRequestType[]

    payload.forEach((item) => {
      const index = newPassiveProduct.findIndex((product) => product.product_id === item.productId)
      if (index >= 0) {
        newPassiveProduct[index] = {
          ...newPassiveProduct[index],
          store_ids: _.uniq([...newPassiveProduct[index].store_ids, item.storeId]),
        }
      } else {
        newPassiveProduct = [
          ...newPassiveProduct,
          {
            product_id: item.productId,
            store_ids: [item.storeId],
            comment: item.comment,
          },
        ]
      }
    })

    const key = 'create-passive-product'
    message.loading({content: 'Ürünler pasif hale getiriliyor...', key})

    passiveProductStoreRequest({passive_products: newPassiveProduct})
      .then(() => {
        message.success({content: 'Ürünler pasif olarak eklendi.', key})
        setWillBePassiveProducts([])
      })
      .catch((e) => {
        message.error({content: 'Ürünler pasif olarak eklenemedi. Lütfen tekrar deneyiniz', key})
      })
  }

  const activeProduct = (payload: activeProductType[]) => {
    let newActiveProduct = [] as activeProductRequestType[]

    payload.forEach((item) => {
      const index = newActiveProduct.findIndex((product) => product.product_id === item.productId)
      if (index >= 0) {
        newActiveProduct[index] = {
          ...newActiveProduct[index],
          store_ids: _.uniq([...newActiveProduct[index].store_ids, item.storeId]),
        }
      } else {
        newActiveProduct = [
          ...newActiveProduct,
          {
            product_id: item.productId,
            store_ids: [item.storeId],
          },
        ]
      }
    })

    const key = 'passive-product-activating'

    message.loading({content: 'Ürünler aktif hale getiriliyor...', key})

    passiveProductActivateRequest({passive_products: newActiveProduct})
      .then(() => {
        message.success({content: 'Ürünler aktif edildi.', key})
        setWillBeActiveProducts([])
      })
      .catch(() => {
        message.error({content: 'Ürünler aktif edilemedi. Lütfen tekrar deneyiniz', key})
      })
  }

  return (
    <>
      <PassiveProductContext.Provider
        value={{
          willBeActiveProducts,
          willBePassiveProducts,
          deactivateProduct,
          activeProduct,
          addWillBeActiveProduct,
          deleteWillBeActiveProduct,
          addWillBePassiveProduct,
          deleteWillBePassiveProduct,
        }}
      >
        {children}
      </PassiveProductContext.Provider>
    </>
  )
}
