import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {useIBMGroupsAndCampaignsExport} from '../../../hooks/IBM/useIBMGroupsAndCampaignsExport'
import {ExcelExport} from '../../../layout/excel/ExcelExportWrapper'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'

export const IBMGroupsAndCampaignsExportWrapper: FC = () => {
  const {exportIBMGroupsAndCampaigns, downloadExcelUrl} = useIBMGroupsAndCampaignsExport()

  return (
    <>
      <Toolbar />
      <PageTitle>IBM Grup & Kampanyalarını Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport
              handleExportData={exportIBMGroupsAndCampaigns}
              downloadExcelUrl={downloadExcelUrl}
            />
          }
        />
      </Routes>
    </>
  )
}
