import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {
  CampaignWaitingApprovalImportProvider,
  ImportContext,
} from '../../../../../providers/category/campaigns/CampaignWaitingApprovalImportProvider'
import {KrcButton} from '../../../../atoms/buttons/KrcButton'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'
import {CampaignWaitingApprovalImport} from './CampaignWaitingApprovalImport'

const CampaignWaitingApprovalImportToolbarRightField: FC = () => {
  const {importId, approveImport} = useContext(ImportContext)
  return (
    <>
      {importId ? (
        <div className='d-flex align-items-center py-1'>
          <KrcButton size={'middle'} onClick={approveImport} id='kt_toolbar_primary_button'>
            {'Onayla'}
          </KrcButton>
        </div>
      ) : undefined}
    </>
  )
}

const CampaignWaitingApprovalImportWrapperWithoutProvider: FC = () => {
  return (
    <>
      <Toolbar rightField={<CampaignWaitingApprovalImportToolbarRightField />}></Toolbar>
      <PageTitle>Toplu Kampanya Ürünü Onaylama/Reddetme</PageTitle>
      <CampaignWaitingApprovalImport />
    </>
  )
}

export const CampaignWaitingApprovalImportWrapper: FC = () => {
  return (
    <>
      <CampaignWaitingApprovalImportProvider>
        <CampaignWaitingApprovalImportWrapperWithoutProvider />
      </CampaignWaitingApprovalImportProvider>
    </>
  )
}
