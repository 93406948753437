import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
const PRODUCT_UPDATE_IMPORT_URL = `${API_URL}/products/import-update`

export function approveProductUpdateImportRequest(importLink: string) {
  return axios.post(importLink)
}

export function getDownloadExampleFileUrl() {
  return `${PRODUCT_UPDATE_IMPORT_URL}/download-example-file`
}

export function productProductUpdateImportingRequest(productUpdateImportId: number) {
  return axios.get(`${PRODUCT_UPDATE_IMPORT_URL}/${productUpdateImportId}`)
}

export function checkProductExcelUpdateImportStatus(productExcelUpdateImportId: number) {
  return axios.get(`${PRODUCT_UPDATE_IMPORT_URL}/${productExcelUpdateImportId}/check-status`)
}
