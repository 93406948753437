import React, {FC} from 'react'
import {Route, Routes} from 'react-router-dom'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {useDealList} from '../../../../hooks/deals/useDealList'
import {ExcelExport} from '../../../../layout/excel/ExcelExportWrapper'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'

export const DealListExportWrapper: FC = () => {
  const {exportDeals, downloadExcelUrl} = useDealList()

  return (
    <>
      <Toolbar />
      <PageTitle>Fırsatları Dışarı Aktar</PageTitle>
      <Routes>
        <Route
          path='/'
          element={
            <ExcelExport handleExportData={exportDeals} downloadExcelUrl={downloadExcelUrl} />
          }
        />
      </Routes>
    </>
  )
}
