import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {
  checkIBMCampaignSpecialGroupProductsExportRequest,
  exportIBMCampaignSpecialGroupProductsRequest,
} from '../../../requests/ibm/IBMCampaignSpecialGroupRequest'

export const useIBMCampaignSpecialGroupProductsExport = () => {
  const navigate = useNavigate()
  const [downloadExcelUrl, setDownloadExcelUrl] = useState<any>(null)
  const urlParams = window.location.search.substring(1)
  const ibmCampaignId = urlParams.split('special_group_id=')[1].split('&')[0]

  const exportIBMCampaignSpecialGroupProducts = () => {
    exportIBMCampaignSpecialGroupProductsRequest(Number(ibmCampaignId))
      .then((res) => {
        if (!res.data?.success) {
          return
        }
        checkIBMCampaignSpecialGroupProductsExport(res.data.data.file_name)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/error/404')
        }
      })
  }

  const checkIBMCampaignSpecialGroupProductsExport = (filename: string) => {
    checkIBMCampaignSpecialGroupProductsExportRequest(Number(ibmCampaignId), filename).then(
      (response) => {
        if (!response.data.data.is_exists) {
          setTimeout(() => {
            checkIBMCampaignSpecialGroupProductsExport(filename)
          }, 1000)
          return
        }
        setDownloadExcelUrl(response.data.data.download_excel_url)
      }
    )
  }

  return {
    exportIBMCampaignSpecialGroupProducts,
    downloadExcelUrl,
  }
}
