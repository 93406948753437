export const COOKPLUS = 0
export const KARACA_HOME = 1
export const KARACA = 2
export const EMSAN = 3
export const KARACA_APP = 12
export const PERA_BULVARI = 13
export const HOMEND = 18
export const KASMIR_HALI = 22

export const IBM_CAMPAIGN_STORES = [
  {
    value: COOKPLUS,
    label: 'Cookplus',
  },
  {
    value: KARACA_HOME,
    label: 'Karaca Home',
  },
  {
    value: KARACA,
    label: 'Karaca',
  },
  {
    value: EMSAN,
    label: 'Emsan',
  },
  {
    value: KARACA_APP,
    label: 'Karaca App',
  },
  {
    value: PERA_BULVARI,
    label: 'Pera Bulvarı',
  },
  {
    value: HOMEND,
    label: 'Homend',
  },
  {
    value: KASMIR_HALI,
    label: 'Kasmir Hali',
  },
]
