import {EditOutlined} from '@ant-design/icons'
import {Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {FC, createContext, useEffect, useRef, useState} from 'react'
import {Cell, Column} from 'react-table'

import {KrcAlert} from '../components/atoms/KrcAlert'
import {KrcSelect} from '../components/atoms/forms/KrcSelect'
import {usePagination} from '../components/hooks/pagination/usePagination'
import {paginationMetaType} from '../components/models/GeneralModel'
import {urlAliasTableType, urlAliasType} from '../components/models/url_alias/UrlAliasModel'
import {errorMessage} from '../helpers/ErrorHelper'
import {removeEmptyItemInObject} from '../helpers/ObjectHelper'
import {
  createUrlAliasRequest,
  getUrlAliasRequest,
  updateUrlAliasRequest,
} from '../requests/url/UrlAliasRequest'
import {initialMetaData} from './tables/ProductSelectionProvider'

interface IUrlAliasContext {
  data: any[]
  columns: Column<any>[]
  handleOnFilter: (data: any, key: string | number) => void
  meta: paginationMetaType
  changePage: (page: number) => void
  changeLimit: (limit: number) => void
  visibleTextFilter: boolean
  visibleCreateModal: boolean
  setVisibleTextFilter: React.Dispatch<React.SetStateAction<boolean>>
  filter: any
  setFilter: React.Dispatch<React.SetStateAction<any>>
  willBeUpdatedUrlAlias?: urlAliasTableType
  setWillBeUpdatedUrlAlias: React.Dispatch<React.SetStateAction<urlAliasTableType | undefined>>
  getUrlAlias: () => void
  setVisibleCreateModal: React.Dispatch<React.SetStateAction<boolean>>
  updateUrlAlias: (urlAliasId: number, newUrlAlias: any) => Promise<unknown>
  createUrlAlias: (newUrlAlias: any) => Promise<unknown>
}

export const UrlAliasContext = createContext<IUrlAliasContext>({
  data: [],
  columns: [],
  handleOnFilter: () => {},
  meta: initialMetaData,
  filter: {},
  visibleTextFilter: false,
  visibleCreateModal: false,
  setVisibleTextFilter: () => {},
  setFilter: () => {},
  setWillBeUpdatedUrlAlias: () => {},
  getUrlAlias: () => {},
  setVisibleCreateModal: () => {},
  changePage: (value: number) => {},
  changeLimit: (value: number) => {},
  updateUrlAlias: () => new Promise(() => {}),
  createUrlAlias: () => new Promise(() => {}),
})

export const UrlAliasProvider: FC = ({children}) => {
  const [data, setData] = useState<any[]>([])
  const [filter, setFilter] = useState<any>({})
  const [visibleTextFilter, setVisibleTextFilter] = useState<boolean>(false)
  const [willBeUpdatedUrlAlias, setWillBeUpdatedUrlAlias] = useState<urlAliasTableType>()
  const initialRef = useRef(0)

  const [visibleCreateModal, setVisibleCreateModal] = useState<boolean>(false)

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'Query',
        accessor: 'query',
        maxWidth: 200,
        manualWidth: 200,
      },
      {
        Header: 'Url',
        accessor: 'keyword',
        manualWidth: 200,
        maxWidth: 200,
      },
      {
        Header: 'Durumu',
        accessor: 'status',
        maxWidth: 120,
        manualWidth: 120,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={undefined}
                placeholder={'Durum'}
                options={[
                  {value: 200, label: '200'},
                  {value: 301, label: '301'},
                  {value: 302, label: '302'},
                  {value: 404, label: '404'},
                ]}
                maxTagCount={'responsive'}
                style={{width: '100%'}}
                className={'ant-selector-border'}
                showArrow={true}
                onChange={(data) => {
                  if (initialRef.current === 0) {
                    return
                  }

                  handleOnFilter(data ? data : undefined, 'status')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Yönlendirme',
        accessor: 'redirect',
        maxWidth: 280,
        manualWidth: 280,
      },
      {
        Header: 'Sayfa Türü',
        accessor: 'page_type',
        maxWidth: 150,
        manualWidth: 150,
      },
      {
        Header: 'noindex',
        accessor: 'noindex',
        maxWidth: 120,
        manualWidth: 120,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                {row.original.noindex && <KrcAlert message={'Evet'} type={'success'} />}
                {!row.original.noindex && <KrcAlert message={'Hayır'} type={'warning'} />}
              </div>
            </div>
          )
        },
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={undefined}
                placeholder={'Seçiniz'}
                options={[
                  {value: 1, label: 'Evet'},
                  {value: 2, label: 'Hayır'},
                ]}
                maxTagCount={'responsive'}
                style={{width: '100%'}}
                className={'ant-selector-border'}
                showArrow={true}
                onChange={(data) => {
                  if (initialRef.current === 0) {
                    return
                  }

                  const _data = data ? (data === 1 ? 1 : 0) : undefined
                  handleOnFilter(_data, 'noindex')
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Aksiyonlar',
        accessor: '',
        manualWidth: 100,
        maxWidth: 100,
        Filter: () => {
          return <></>
        },
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row justify-content-start'>
              <div></div>
              <Tooltip title={'noindex Güncelle'}>
                <div
                  onClick={(e) => {
                    setWillBeUpdatedUrlAlias(row.original)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                >
                  <EditOutlined />
                </div>
              </Tooltip>
            </div>
          </>
        ),
      },
    ]
  }, [])

  useEffect(() => {
    initialRef.current = 1

    getUrlAlias()
  }, [])

  const getUrlAlias = () => {
    const _filter = removeEmptyItemInObject(filter)
    getUrlAliasRequest(_filter, meta).then((res) => {
      const urlAlias = res.data.data.map((item: urlAliasType) => {
        return {
          id: item.url_alias_id,
          query: item.query,
          keyword: item.keyword,
          status: item.status,
          redirect: item.redirect,
          noindex: item.noindex,
          page_type: item.page_type,
        }
      })

      const _meta = res.data.meta

      updateMetaData({limit: _meta.per_page, page: _meta.current_page, ..._meta})
      setData(urlAlias)
    })
  }

  const {meta, updateMetaData, changePage, changeLimit} = usePagination({
    callback: getUrlAlias,
    filter,
  })

  const updateUrlAlias = (urlAliasId: number, newUrlAlias: any) => {
    return new Promise((resolve, reject) => {
      const key = 'update-url-alias'
      message.loading({content: 'Urlalias güncelleniyor...', key})
      updateUrlAliasRequest(urlAliasId, newUrlAlias)
        .then((res) => {
          message.success({content: 'Urlalias başarıyla güncellendi.', key})
          resolve(res)
        })
        .catch(() => {
          message.error({content: 'Urlalias güncellenemedi.', key})
          reject()
        })
    })
  }

  const createUrlAlias = (newUrlAlias: any) => {
    return new Promise((resolve, reject) => {
      const key = 'create-url-alias'
      message.loading({content: 'Url Alias ekleniyor...', key})
      createUrlAliasRequest(newUrlAlias)
        .then((res) => {
          message.success({content: 'Url Alias başarıyla eklendi.', key})
          resolve(res)
        })
        .catch((err) => {
          errorMessage(err)
          reject()
        })
    })
  }

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  return (
    <>
      <UrlAliasContext.Provider
        value={{
          data,
          columns,
          handleOnFilter,
          meta,
          changePage,
          changeLimit,
          filter,
          visibleTextFilter,
          visibleCreateModal,
          setVisibleTextFilter,
          setFilter,
          willBeUpdatedUrlAlias,
          setWillBeUpdatedUrlAlias,
          getUrlAlias,
          setVisibleCreateModal,
          updateUrlAlias,
          createUrlAlias,
        }}
      >
        {children}
      </UrlAliasContext.Provider>
    </>
  )
}
