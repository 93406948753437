import debounce from 'lodash/debounce'
import React, {useEffect, useRef, useState} from 'react'

import {searchBannerRequest} from '../../../requests/banner/BannerRequest'
import {bannerSearchDataType} from '../../models/banners/BannerFilterModel'
import {krcSelectOptions} from '../../models/components/KrcSelectModel'

export const useBannerFilter = (debounceTime = 500) => {
  const [options, setOptions] = useState<krcSelectOptions[]>([])
  const [initialOptions, setInitialOptions] = useState<krcSelectOptions[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [bannerIds, setBannerIds] = useState<number[] | number>([])
  const [selectedItems, setSelectedItems] = useState<krcSelectOptions | krcSelectOptions[]>()
  const [search, setSearch] = useState<string>('')
  const fetchRef = useRef(0)
  const initialRef = useRef(0)

  const handleSearch = React.useMemo(() => {
    const loadOptions = (name: string, initial?: boolean) => {
      setSearch(name)

      if (name.length < 2 && !initial) {
        return
      }

      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      searchBannerRequest(name)
        .then((res) => {
          if (fetchId !== fetchRef.current) {
            return
          }

          const banners: krcSelectOptions[] = res.data.data.map((banner: bannerSearchDataType) => {
            return {value: banner.banner_id, label: banner.name}
          })

          if (initial) {
            setInitialOptions(banners)
          }

          setOptions(banners)
        })
        .finally(() => {
          setFetching(false)
        })
    }

    return debounce(loadOptions, debounceTime)
  }, [])

  function handleOnChange(payload: Array<krcSelectOptions> | krcSelectOptions) {
    if (!payload) {
      setSelectedItems([])
      setBannerIds([])
      return
    }

    setSelectedItems(payload)

    if (Array.isArray(payload)) {
      const badgeIds = payload.map((data: krcSelectOptions) => data.value) as number[]
      setBannerIds(badgeIds)
      return
    }

    const badgeId = payload.value as number
    setBannerIds(badgeId)
  }

  useEffect(() => {
    if (initialRef.current !== 0 && search.length !== 0) {
      return
    }

    setOptions(initialOptions)
  }, [search])

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current += 1
    }

    handleSearch('', true)
  }, [])

  return {
    handleOnChange,
    handleSearch,
    bannerIds,
    fetching,
    options,
    selectedItems,
  }
}
