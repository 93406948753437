import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  CategoryMapContext,
  CategoryMapProvider,
} from '../../../../providers/tables/CategoryMapProvider'
import {CategoryMap} from '../../../layout/categories/maps/CategoryMap'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'

export const CategoryMapWrapperBody: FC = () => {
  const {categoryMaps, groupTitle, updateGroupTitle, getCategoriesMap} =
    useContext(CategoryMapContext)

  return (
    <>
      <div className={'row'}>
        {Object.keys(categoryMaps).map((key) => {
          const category = categoryMaps[key]
          const deep = parseInt(key)

          if (category.length === 0) {
            return null
          }

          return (
            <div className={'col-4'} key={`category-map-${key}`}>
              <CategoryMap
                headerProps={{
                  title: deep === 0 ? 'Ana Kategori' : 'Alt Kategori',
                  subTitle: {key: 'Grubu', value: groupTitle[key]},
                }}
                data={categoryMaps[key]}
                onSelect={(data) => {
                  if (!updateGroupTitle || !getCategoriesMap || deep === 3) {
                    return
                  }

                  getCategoriesMap(data.original.id, deep + 1)
                  updateGroupTitle(data.original.name, deep + 1)
                }}
                deep={deep}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export const CategoryMapsWrapper: FC<any> = () => {
  return (
    <>
      <Toolbar />
      <PageTitle>Kategori Haritası</PageTitle>
      <CategoryMapProvider>
        <CategoryMapWrapperBody />
      </CategoryMapProvider>
    </>
  )
}
