import Title from 'antd/lib/typography/Title'
import React, {FC} from 'react'

import {useKrcProp} from '../../hooks/components/useKrcProp'
import {IMappingTableHeaderModel} from '../../models/menus/MenuMapModel'

const mappingTableHeaderConfig = {
  default: {
    style: {
      padding: 15,
    },
  },
}

export const MappingTableHeader: FC<IMappingTableHeaderModel> = ({
  config = 'default',
  title,
  subTitle,
  children,
  ...props
}) => {
  const currentProps = useKrcProp({
    config: mappingTableHeaderConfig[config],
    props,
  }).getCurrentProps()

  return (
    <>
      <div
        className={'d-flex justify-content-between border border-gray-200 rounded'}
        {...currentProps}
      >
        <div className={'d-flex flex-column'}>
          <Title level={4}>{title}</Title>
          {subTitle && (
            <div>
              <span className={'text-gray-600'}>{subTitle.key}: </span>
              <span className={'text-gray-800'}>{subTitle.value}</span>
            </div>
          )}
        </div>
        {children}
      </div>
    </>
  )
}
