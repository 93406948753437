import {useEffect, useState} from 'react'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CategoryIconType} from '../../models/categories/CategoryIconModel'

const icons = [
  {
    key: 'flag-1',
    alt: 'Flag-1',
    src: toAbsoluteUrl('/media/outline/flag-1.svg'),
    className: 'h-24px cursor-pointer',
  },
  {
    key: 'flag-2',
    alt: 'Flag-2',
    src: toAbsoluteUrl('/media/outline/flag-2.svg'),
    className: 'h-24px cursor-pointer',
  },
  {
    key: 'flag-3',
    alt: 'Flag-3',
    src: toAbsoluteUrl('/media/outline/flag-3.svg'),
    className: 'h-24px cursor-pointer',
  },
  {
    key: 'grid-2-h',
    alt: 'Grid-2-h',
    src: toAbsoluteUrl('/media/outline/grid-2-horizontal.svg'),
    className: 'h-24px cursor-pointer',
  },
  {
    key: 'grid-2-v',
    alt: 'Grid-2-v',
    src: toAbsoluteUrl('/media/outline/grid-2-vertical.svg'),
    className: 'h-24px cursor-pointer',
  },
  {
    key: 'grid-4',
    alt: 'Grid-4',
    src: toAbsoluteUrl('/media/outline/grid-4.svg'),
    className: 'h-24px cursor-pointer',
  },
]

export const usePickCategoryIcon = (defaultIconKey?: string | null) => {
  const [selectedIcon, setSelectedIcon] = useState<CategoryIconType>()

  useEffect(() => {
    if (defaultIconKey) {
      setSelectedIcon(icons.find((icon) => icon.key === defaultIconKey))
    }
  }, [defaultIconKey])

  const handleOnChangeIcon = (icon: CategoryIconType) => {
    setSelectedIcon(icon)
  }

  return {
    handleOnChangeIcon,
    icons,
    selectedIcon,
  }
}
