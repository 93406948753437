import {Drawer, Space, message} from 'antd'
import Title from 'antd/lib/typography/Title'
import {Field, Form, Formik} from 'formik'
import React, {FC, useContext, useRef, useState} from 'react'
import * as Yup from 'yup'

import {ProductUpdateContext} from '../../../../providers/products/updates/ProductUpdateProvider'
import {ProductVariantContext} from '../../../../providers/products/updates/variants/ProductVariantProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {ProductSelection} from '../../../layout/filters/ProductSelection'
import {productVariantDrawerIValueType} from '../../../models/products/ProductModel'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

const schema = Yup.object({
  type: Yup.string().required('Tür zorunlu alandır.'),
})

const _initValues = {
  type: 'color',
  sort_order: 0,
}

export const AddProductVariantDrawer: FC = () => {
  const [initValues, setInitValues] = useState<productVariantDrawerIValueType>(_initValues)
  const [selectedProductVariantIds, setSelectedProductVariantIds] = useState<number[]>([])
  const {addProductVariant} = useContext(ProductVariantContext)
  const {setProductVariantDrawerVisible, productVariantDrawerVisible} =
    useContext(ProductUpdateContext)
  const handleOnClose = () => {
    setProductVariantDrawerVisible(false)
  }
  const submitRef = useRef<HTMLButtonElement | null>(null)

  const handleOnSubmit = (value: productVariantDrawerIValueType) => {
    if (selectedProductVariantIds.length === 0) {
      message.warn('Ürüne varyant(lar) ekleyebilmek için lütfen ürün seçiniz.')

      return
    }
    const data = selectedProductVariantIds.map((productVariantId) => {
      return {
        product_id: productVariantId,
        type: value.type,
        sort_order: value.sort_order,
      }
    })

    addProductVariant(data)
  }

  return (
    <>
      <Drawer
        title={
          <Title level={4} className={'mb-0 mt-1'}>
            Ürüne Varyant(lar) Ekle
          </Title>
        }
        placement='right'
        size={'large'}
        onClose={handleOnClose}
        keyboard={false}
        maskClosable={false}
        visible={productVariantDrawerVisible}
        extra={
          <Space>
            <KrcButton type={'default'} size={'large'} onClick={handleOnClose}>
              İptal
            </KrcButton>
            <KrcButton
              size={'large'}
              onClick={() => {
                submitRef.current?.click()
              }}
            >
              Seçilen Ürünleri Ekle
            </KrcButton>
          </Space>
        }
      >
        <Formik
          validationSchema={schema}
          initialValues={initValues}
          onSubmit={handleOnSubmit}
          validate={setInitValues}
          enableReinitialize
        >
          {() => (
            <Form className='form row mb-3' noValidate>
              <KrcFormikField name={'type'} labelTitle={'Tür'} required>
                <Field as='select' name='type' className='form-select' aria-label='Select example'>
                  <option value='color'>Renk</option>
                  <option value='size'>Boyut</option>
                </Field>
              </KrcFormikField>

              <KrcFormikField name={'sort_order'} labelTitle={'Sıralama'}>
                <Field
                  type='number'
                  className='form-control'
                  name={'sort_order'}
                  autoComplete='off'
                />
              </KrcFormikField>

              <button type='submit' ref={submitRef} className='d-none' style={{marginLeft: 10}} />
            </Form>
          )}
        </Formik>
        {productVariantDrawerVisible && (
          <ProductSelection
            headerProps={{
              titleLevel: 5,
              searchOption: {
                advancedStatus: false,
              },
            }}
            textAreaProps={{
              placeholder:
                'Model numarası veya Ürün ID ile toplu arama yapılabilir. \nÖrneğin: \n7126119 \n6949288 \n153.03.01.1091 ',
            }}
            onSelectedItems={(data) => {
              if (!data) {
                return
              }

              setSelectedProductVariantIds(data.map((item) => item.id))
            }}
            transferProps={{
              button: {
                title: '',
                isActive: false,
              },
            }}
            withImage={true}
          />
        )}
      </Drawer>
    </>
  )
}
