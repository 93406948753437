import clsx from 'clsx'
import React, {FC, useEffect} from 'react'

import {usePickCategoryIcon} from '../../hooks/categories/usePickCategoryIcon'
import {CategoryIconType} from '../../models/categories/CategoryIconModel'

interface IPickCategoryIcon {
  title?: string
  defaultIcon?: string | null
  onChangeIcon?: (selectedIcon: CategoryIconType) => void
}

export const PickCategoryIcon: FC<IPickCategoryIcon> = ({
  defaultIcon,
  onChangeIcon,
  title = 'Kategori Icon',
}) => {
  const {handleOnChangeIcon, icons, selectedIcon} = usePickCategoryIcon(defaultIcon)
  const initialRef = React.useRef(0)

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current = 1
      return
    }

    if (onChangeIcon && selectedIcon) {
      onChangeIcon(selectedIcon)
    }
  }, [selectedIcon])

  return (
    <>
      <div
        className={'d-flex p-4 flex-column'}
        style={{borderRadius: '7px', backgroundColor: '#F3F3F3'}}
      >
        <span style={{fontSize: 14, fontWeight: 400}}>{title}</span>
        <div className={'mt-2 d-flex gap-3'}>
          {icons.map((icon) => {
            return (
              <div
                key={`icon-${icon.key}`}
                className={clsx({'selected-category-icon': selectedIcon?.key === icon.key})}
                onClick={() => handleOnChangeIcon(icon)}
              >
                <img alt={icon.alt} src={icon.src} className={icon.className} />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
