import {Route, Routes} from 'react-router-dom'

import {BannerManagerWrapper} from '../components/pages/banners/managers/BannerManagerWrapper'
import {BannerImageManagerWrapper} from '../components/pages/images/managers/BannerImageManagerWrapper'

export const BannerRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<BannerManagerWrapper />} />
        <Route path={'/:bannerId'} element={<BannerManagerWrapper />} />
        <Route path={'/:bannerId/images/'}>
          <Route path={':imageId'} element={<BannerImageManagerWrapper />} />
        </Route>
      </Routes>
    </>
  )
}
