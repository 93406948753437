import {Empty, message} from 'antd'
import React, {FC, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {IBMContext} from '../../../../providers/ibm/IBMProvider'
import {fetchIBMGroupsRequest} from '../../../../requests/ibm/IBMGroupRequest'
import {IBmGroupSortListType} from '../../../models/ibm/IBMGroupModel'
import {Loader} from '../../../molecules/Loader'
import {KrcTable} from '../../../organisms/tables/KrcTable'
import {KrcModal} from '../../KrcModal'

interface IIBMGroupSortModalProps {
  onHide: () => void
}

export const IBMGroupSortModal: FC<IIBMGroupSortModalProps> = ({onHide}) => {
  const {sortColumns, setIBMSortedGroups, handleOnSaveIBMGroupSorting} = useContext(IBMContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [IBMGroups, setIBMGroups] = useState<IBmGroupSortListType[][]>([])

  useEffect(() => {
    setIsLoading(true)
    fetchIBMGroupsRequest({}, {page: 1, limit: 10}, 1)
      .then((res) => {
        setIBMGroups(res.data.data)
      })
      .catch((err) => {
        message.error('IBM Grupları getirilirken bir hata oluştu.')
        message.error(err.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
        }}
        modalHeaderProps={{
          title: 'IBM Kampanyalarını Sırala',
          onHide,
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10'>
          {IBMGroups.length > 0 ? (
            <KrcTable
              columns={sortColumns}
              data={IBMGroups}
              outerHeight={280}
              sortableProps={{
                isSortable: true,
                onSortChange: (campaigns) => {
                  setIBMSortedGroups(() => campaigns)
                },
              }}
            />
          ) : isLoading ? (
            <Loader />
          ) : (
            <Empty
              image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
              imageStyle={{
                height: 60,
              }}
              description={<span>Herhangi bir grup bulunamadı.</span>}
            ></Empty>
          )}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={onHide}
              style={{marginLeft: 10}}
            >
              İptal Et
            </button>
            <button
              type='button'
              onClick={() => handleOnSaveIBMGroupSorting()}
              className='btn btn-primary'
              style={{marginLeft: 10}}
            >
              Kaydet
            </button>
          </div>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
