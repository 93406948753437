import {FC} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {ProductAvailableProvider} from '../../../../providers/products/available/ProductAvailableProvider'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {ProductAvailable} from './ProductAvailable'

const ProductAvailableWrapperWithoutProvider: FC = () => {
  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Toplu Geçerlilik Tarihi Güncelleme</PageTitle>
      <ProductAvailable />
    </>
  )
}

export const ProductAvailableWrapper = () => {
  return (
    <>
      <ProductAvailableProvider>
        <ProductAvailableWrapperWithoutProvider></ProductAvailableWrapperWithoutProvider>
      </ProductAvailableProvider>
    </>
  )
}
