import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {Image, Popconfirm, Tooltip, message} from 'antd'
import React, {FC, createContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {Column, Row} from 'react-table'

import {
  ICategoryBanner,
  categoryBannerDataType,
} from '../../../components/models/categories/CategoryBannerModel'
import {DrawerEnum} from '../../../enums/DrawerEnum'
import {MULTI_DELETE_CATEGORY_BANNER_KEY} from '../../../enums/category/banner/CategoryBannerMessageKeyEnum'
import {
  deleteCategoryBannerRequest,
  getCategoryBannersRequest,
} from '../../../requests/categories/banner/CategoryBannerRequest'
import {
  deleteBanner,
  setBanner,
  setBanners,
} from '../../../store/Category/Banner/CategoryBannerAction'

interface ICategoryBannerContext {
  imagePath: string
  uploadPath: string
  visibleDrawer: boolean
  updateVisibleDrawer: (data: boolean) => void
  drawerType: DrawerEnum
  updateDrawerType: (data: DrawerEnum) => void
  columns: Column<any>[]
  banners: categoryBannerDataType[]
  banner?: categoryBannerDataType
  visibleBannerModal: boolean
  multiDeleteBanner: (row: any) => void
  sendDeleteBanner: (e: any, row: any) => void
  handleShow: (data: ICategoryBanner) => void
  getCategoryBanners: () => void
  isLoading: boolean
  setIsLoading: (data: boolean) => void
}

export const CategoryBannerContext = createContext<ICategoryBannerContext>({
  imagePath: '',
  uploadPath: '',
  visibleDrawer: false,
  updateVisibleDrawer: () => {},
  drawerType: DrawerEnum.CREATE,
  updateDrawerType: () => {},
  columns: [],
  visibleBannerModal: false,
  multiDeleteBanner: () => {},
  sendDeleteBanner: () => {},
  handleShow: () => {},
  getCategoryBanners: () => {},
  banners: [],
  isLoading: false,
  setIsLoading: () => {},
})

export const imagePath = 'https://cdn.karaca.com/image/'
export const uploadPath = '/image/2024/category_banner'

export const CategoryBannerProvider: FC = ({children}) => {
  const dispatch = useDispatch()
  const {categoryId} = useParams<string>()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const {banners, visibleBannerModal, banner} = useSelector((state: any) => state.categoryBanner)
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false)
  const [drawerType, setDrawerType] = useState<DrawerEnum>(DrawerEnum.CREATE)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const updateVisibleDrawer = (visibility: boolean) => {
    setVisibleDrawer(visibility)
  }

  const updateDrawerType = (drawer: DrawerEnum) => {
    setDrawerType(drawer)
  }

  useEffect(() => {
    getCategoryBanners()
  }, [])

  useEffect(() => {
    if (!banners) {
      return
    }

    dispatch(setBanners(banners))
  }, [banners])

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: 'Başlık / Açıklama',
        accessor: 'title',
        maxWidth: 175,
        manualWidth: 175,
      },
      {
        Header: 'Bağlantı',
        accessor: 'link',
        maxWidth: 330,
        manualWidth: 330,
      },
      {
        Header: 'Görsel',
        accessor: 'image',
        maxWidth: 100,
        manualWidth: 100,
        Cell: ({row}: any) =>
          row.original.image && (
            <Image
              preview={{
                maskClassName: 'rounded-circle',
              }}
              width={60}
              height={60}
              className={'rounded-circle'}
              src={imagePath + row.original.image}
              style={{minWidth: 52}}
            />
          ),
      },
      {
        Header: 'Mobil Görsel',
        accessor: 'mobile_image',
        maxWidth: 100,
        manualWidth: 100,
        Cell: ({row}: any) =>
          row.original.mobile_image && (
            <Image
              preview={{
                maskClassName: 'rounded-circle',
              }}
              width={60}
              height={60}
              className={'rounded-circle'}
              src={imagePath + row.original.mobile_image}
              style={{minWidth: 52}}
            />
          ),
      },
      {
        Header: 'ID',
        accessor: 'type_id',
        maxWidth: 115,
        manualWidth: 115,
      },
      {
        Header: 'Grup',
        accessor: 'type',
        maxWidth: 100,
        manualWidth: 100,
      },
      {
        Header: () => <div style={{textAlign: 'right'}}>Aksiyon</div>,
        accessor: 'col5',
        manualWidth: 100,
        maxWidth: 100,
        id: 'click-me-button',
        Cell: ({row}: {row: Row<any>}) => (
          <div className='text-end'>
            <Tooltip title={'Güncelle'}>
              <div
                onClick={() => handleShow(row.original)}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 banner-update'
              >
                <EditOutlined />
              </div>
            </Tooltip>

            <Tooltip title={'Sil'}>
              <Popconfirm
                title='Bu bannerı silmek istediğinizden emin misiniz?'
                onConfirm={(e) => sendDeleteBanner(e, row)}
                okButtonProps={{loading: confirmLoading}}
                okText='Evet'
                cancelText='Hayır'
              >
                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                  <DeleteOutlined />
                </div>
              </Popconfirm>
            </Tooltip>
          </div>
        ),
      },
    ],
    []
  )

  const handleShow = (banner: ICategoryBanner) => {
    updateDrawerType(DrawerEnum.UPDATE)
    updateVisibleDrawer(true)
    dispatch(setBanner(banner))
  }

  const sendDeleteBanner = (e: any, row: any) => {
    e.preventDefault()
    setConfirmLoading(true)

    deleteCategoryBannerRequest(row.original.category_id, row.original.id).then(() => {
      setConfirmLoading(false)
      dispatch(deleteBanner(row.original.id))
      getCategoryBanners()
    })
  }

  const multiDeleteBanner = (banners: any) => {
    message.loading({content: 'Bannerlar siliniyor.', key: MULTI_DELETE_CATEGORY_BANNER_KEY})

    const total = banners.length

    banners.forEach((banner: ICategoryBanner, index: number) => {
      deleteCategoryBannerRequest(banner.category_id, banner.id).then(() => {
        dispatch(deleteBanner(banner.id))

        if (index === total - 1) {
          getCategoryBanners()
          message.success({content: 'Bannerlar silindi.', key: MULTI_DELETE_CATEGORY_BANNER_KEY})
        }
      })
    })
  }

  const getCategoryBanners = () => {
    getCategoryBannersRequest(Number(categoryId))
      .then((res) => {
        dispatch(setBanners(res.data.data))
      })
      .catch((e) => {
        message.error('Bannerlar getirilirken bir hata oluştu.')
      })
  }

  return (
    <>
      <CategoryBannerContext.Provider
        value={{
          imagePath,
          uploadPath,
          getCategoryBanners,
          visibleDrawer,
          updateVisibleDrawer,
          drawerType,
          updateDrawerType,
          columns,
          sendDeleteBanner,
          multiDeleteBanner,
          handleShow,
          banner,
          banners,
          visibleBannerModal,
          isLoading,
          setIsLoading,
        }}
      >
        {children}
      </CategoryBannerContext.Provider>
    </>
  )
}
