import {Popconfirm} from 'antd'
import {Field, Form, Formik} from 'formik'
import React, {FC, useContext} from 'react'

import {FooterModulesContext} from '../../../../../providers/FooterModulesProvider'
import {Loader} from '../../../../molecules/Loader'
import {KrcFormikField} from '../../../../molecules/forms/KrcFormikField'

interface FooterModuleInformationProps {
  moduleInformation: {
    module_id: number
    module_name: string
    module_url: string | null
    module_store: number
    module_status: number
    module_sort: number
  }
}

export const FooterModuleInformationTab: FC<FooterModuleInformationProps> = ({
  moduleInformation,
}) => {
  const {
    updateOrCreateModuleSchema,
    selectedModuleInformation,
    deleteModuleFromStore,
    updateModuleInformation,
  } = useContext(FooterModulesContext)

  const handleOnDelete = (moduleId: number) => {
    deleteModuleFromStore(moduleId)
  }
  return (
    <>
      {selectedModuleInformation &&
      selectedModuleInformation.module_id === moduleInformation.module_id ? (
        <Formik
          enableReinitialize
          initialValues={{
            module_name: selectedModuleInformation.module_name,
            module_url: selectedModuleInformation.module_url,
            module_status: selectedModuleInformation.module_status,
            module_sort: selectedModuleInformation.module_sort,
          }}
          onSubmit={(values) => {
            updateModuleInformation(moduleInformation.module_id, {
              module_name: values.module_name,
              url: values.module_url,
              status: values.module_status,
              sort: values.module_sort,
            })
          }}
          validationSchema={updateOrCreateModuleSchema}
          validateOnMount={true}
        >
          {(formik) => {
            return (
              <Form className='form mt-6 space-between'>
                <div className={'row'}>
                  <div className={'col-3'}>
                    <KrcFormikField
                      className={'mb-2'}
                      type={'text'}
                      name={'module_name'}
                      labelTitle={'Modül Adı'}
                      required
                      tooltipProps={{
                        status: true,
                        text: 'Modülün adını giriniz.',
                      }}
                    />
                  </div>
                  <div className={'col-3'}>
                    <KrcFormikField
                      className={'mb-2'}
                      type={'text'}
                      name={'module_url'}
                      labelTitle={'Modül Url'}
                      tooltipProps={{
                        status: true,
                        text: 'Modülün url adresini giriniz.',
                      }}
                    />
                  </div>

                  <div className={'col-3'}>
                    <KrcFormikField
                      className={'mb-2'}
                      type={'number'}
                      name={'module_sort'}
                      labelTitle={'Modül Sıralaması'}
                      required
                      tooltipProps={{
                        status: true,
                        text: 'Modülün sıralamasını giriniz.',
                      }}
                    />
                  </div>
                  <div className={'col-3'}>
                    <KrcFormikField
                      className={'w-100'}
                      name={'module_status'}
                      labelTitle={'Durum'}
                      required
                      tooltipProps={{
                        status: true,
                        text: 'Modülün durumunu seçiniz.',
                      }}
                    >
                      <Field as='select' name='module_status' className='form-select'>
                        <option value={''} disabled={true}>
                          Seçiniz
                        </option>
                        <option value={1}>Aktif</option>
                        <option value={0}>Pasif</option>
                      </Field>
                    </KrcFormikField>
                  </div>
                </div>
                <div className={'d-flex justify-content-end mt-7'}>
                  <Popconfirm
                    title='Modülü silmek istediğinize emin misiniz?'
                    onConfirm={(e) => handleOnDelete(selectedModuleInformation?.module_id)}
                    okText='Evet'
                    cancelText='İptal'
                    placement='topLeft'
                  >
                    <button type='button' className='btn btn-danger' style={{marginLeft: 10}}>
                      Sil
                    </button>
                  </Popconfirm>
                  <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                    Kaydet
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      ) : (
        <Loader />
      )}
    </>
  )
}
