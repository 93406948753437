import axios from 'axios'

import {ISearchGameScoreForm} from '../../providers/games/ScoreBoardProvider'

const API_URL = process.env.REACT_APP_API_URL || ''
export const SCOREBOARD_URL = `${API_URL}/scoreboard`

export const getGameScoreRequest = (formData: ISearchGameScoreForm) => {
  const searchParams = {
    type: formData.type,
    game_id: formData.gameId,
    start_date: formData.startDate,
    end_date: formData.endDate,
  }
  return axios.post(`${SCOREBOARD_URL}`, searchParams)
}

export const exportGameScoreRequest = (formData: ISearchGameScoreForm) => {
  let searchParams = {
    type: formData.type,
    game_id: formData.gameId,
    start_date: formData.startDate,
    end_date: formData.endDate,
  }
  return axios.post(`${SCOREBOARD_URL}/export`, searchParams)
}
