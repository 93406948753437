import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, message} from 'antd'
import React, {FC, createContext, useState} from 'react'

import {willBeUpdateProductType} from '../../components/models/products/BulkPriceTriggerModel'
import {updateAllSpecialProductCart} from '../../requests/products/special_cart/ProductSpecialCartUpdate'

const {confirm} = Modal

interface IBulkPriceTriggerContext {
  updateAllSpecialProductCartHandle: () => void
  willBeUpdateProducts: willBeUpdateProductType[]
  setWillBeUpdateProducts: (willBeUpdateProduct: willBeUpdateProductType[]) => void
  willBeTriggeredStores: string
  setWillBeTriggeredStores: (willBeTriggeredStores: string) => void
}

export const BulkPriceTriggerContext = createContext<IBulkPriceTriggerContext>({
  updateAllSpecialProductCartHandle: () => {},
  willBeUpdateProducts: [],
  setWillBeUpdateProducts: () => {},
  willBeTriggeredStores: '',
  setWillBeTriggeredStores: () => {},
})

export const BulkPriceTriggerProvider: FC = ({children}) => {
  const [willBeUpdateProducts, setWillBeUpdateProducts] = useState<willBeUpdateProductType[]>([])
  const [willBeTriggeredStores, setWillBeTriggeredStores] = useState<string>('')
  const updateAllSpecialProductCartHandle = () => {
    if (!willBeTriggeredStores) {
      message.error('Lütfen bir mağaza seçiniz.', 3)
      return
    }
    confirm({
      title: `Bütün ürün fiyatları güncellenecek`,
      icon: <ExclamationCircleOutlined />,
      content: 'Bütün ürün fiyatları güncellenecektir. Emin misiniz?',
      okText: 'Tamam',
      cancelText: 'İptal',
      onOk() {
        updateAllSpecialProductCart(willBeTriggeredStores)
          .then((r) => {
            message.success('Bütün ürünler güncellenmek üzere kuyruğa gönderildi.')
          })
          .catch((err) => {
            message.error(
              'Ürünleri güncellerken sorun oluştu: ' + err?.response?.data?.message || err.message
            )
          })
      },
    })
  }

  return (
    <>
      <BulkPriceTriggerContext.Provider
        value={{
          updateAllSpecialProductCartHandle,
          willBeUpdateProducts,
          setWillBeUpdateProducts,
          willBeTriggeredStores,
          setWillBeTriggeredStores,
        }}
      >
        {children}
      </BulkPriceTriggerContext.Provider>
    </>
  )
}
