import {EditOutlined} from '@ant-design/icons'
import {Tooltip} from 'antd'
import React, {FC, useContext} from 'react'
import {Link} from 'react-router-dom'

import {PAGE_TYPE} from '../../../../../enums/CampaignTypeEnum'
import {CampaignContext} from '../../../../../providers/CampaignProvider'
import {minimalCategoryType} from '../../../../models/categories/CategoryModel'
import {KrcTable} from '../../../../organisms/tables/KrcTable'

export const CampaignPendingApprovalList: FC = () => {
  const {campaigns, updateSelectedCampaignIds, pageType} = useContext(CampaignContext)

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'category_id',
      },
      {
        Header: 'Adı',
        accessor: 'details.name',
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: 'right',
            }}
          >
            Aksiyon
          </div>
        ),
        maxWidth: 60,
        minWidth: 80,
        manualWidth: 65,
        accessor: 'col5',
        id: 'click-me-button',
        Cell: ({row}: any) => {
          let url = `/categories/${row.original.category_id}/products/pending-approvals`
          if (pageType === PAGE_TYPE.HISTORY) {
            url = `/categories/${row.original.category_id}/products/pending-approvals/history`
          }

          return (
            <div className='text-end'>
              <Tooltip title={'Güncelle'}>
                <Link
                  to={url}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                  style={{color: 'unset'}}
                >
                  <EditOutlined />
                </Link>
              </Tooltip>
            </div>
          )
        },
      },
    ],
    [campaigns]
  )
  return (
    <>
      <KrcTable
        columns={columns}
        data={campaigns}
        selectableProps={{
          uniqueKey: 'category_id',
          isSelectable: true,
          onSelectedItems: (data: minimalCategoryType[]) => {
            updateSelectedCampaignIds(data)
          },
        }}
      />
    </>
  )
}
