import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {Popconfirm, Tooltip} from 'antd'
import moment from 'moment-timezone'
import React, {FC, createContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Cell, Column} from 'react-table'

import {dealDataType} from '../components/models/deals/DealModel'
import {deleteDealRequest, getDealsRequest} from '../requests/deals/DealRequest'

interface IDealListContext {
  deals: dealDataType[]
  columns: Column<any>[]
}

export const DealListContext = createContext<IDealListContext>({deals: [], columns: []})

export const DealListProvider: FC = ({children}) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false)
  const [deals, setDeals] = useState<dealDataType[]>([])
  const columns = React.useMemo(
    () => [
      {
        Header: 'Deal ID',
        accessor: 'deal_id', // accessor is the "key" in the data
      },
      {
        Header: 'Adı',
        accessor: 'name',
      },
      {
        Header: 'Durum',
        accessor: 'status',
        Cell: ({row}: Cell) => <div>{row.values.status ? 'Aktif' : 'Pasif'}</div>,
      },
      {
        Header: () => <div style={{textAlign: 'right'}}>Aksiyon</div>,
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        accessor: 'col5',
        id: 'click-me-button',
        Cell: ({row}: Cell) => (
          <div className='text-end'>
            <Tooltip title={'Güncelle'}>
              <Link
                to={`/deals/${row.values.deal_id}/update`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                style={{color: 'unset'}}
              >
                <EditOutlined />
              </Link>
            </Tooltip>
            <Tooltip title={'Sil'}>
              <Popconfirm
                title='Bu deal silmek istediğinden emin misin ?'
                onConfirm={(e) => sendDeleteDeal(e, row)}
                okButtonProps={{loading: confirmLoading}}
                okText='Evet'
                cancelText='Hayır'
              >
                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                  <DeleteOutlined />
                </div>
              </Popconfirm>
            </Tooltip>
          </div>
        ),
      },
    ],
    []
  )

  const sendDeleteDeal = (e: any, row: any) => {
    e.preventDefault()
    setConfirmLoading(true)
    const dealId = row.original.deal_id

    deleteDealRequest(dealId).then(() => {
      setConfirmLoading(false)
      setDeals((deals) => {
        return deals.filter((deal) => {
          return deal.deal_id !== dealId
        })
      })
    })
  }

  const getDeals = () => {
    getDealsRequest().then((res) => {
      const deals = res.data.data.map((deal: dealDataType) => {
        return {
          ...deal,
          created_at: moment(deal.created_at).format('DD-MM-YYYY'),
        }
      })
      setDeals(deals)
    })
  }

  useEffect(() => {
    getDeals()
  }, [])

  return (
    <>
      <DealListContext.Provider value={{deals, columns}}>{children}</DealListContext.Provider>
    </>
  )
}
