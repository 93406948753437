import {FC, createContext, useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {paginationMetaType} from '../components/models/GeneralModel'
import {categoryDataType, minimalCategoryType} from '../components/models/categories/CategoryModel'
import {PAGE_MODE, PAGE_TYPE} from '../enums/CampaignTypeEnum'
import {getCategoriesRequest} from '../requests/categories/CategoryRequest'

interface ICampaignContext {
  pageType: PAGE_TYPE
  campaigns: minimalCategoryType[]
  campaignMeta: paginationMetaType
  selectedCampaignIds: number[]
  updateSelectedCampaignIds: (data: minimalCategoryType[]) => void
  setCampaignMeta: (data: paginationMetaType) => void
  pageMode: PAGE_MODE.MY_REQUESTS | PAGE_MODE.NORMAL
}

export const CampaignContext = createContext<ICampaignContext>({
  pageType: PAGE_TYPE.CURRENT,
  campaigns: [],
  campaignMeta: {limit: 50, page: 1},
  selectedCampaignIds: [],
  updateSelectedCampaignIds: () => {},
  setCampaignMeta: () => {},
  pageMode: PAGE_MODE.NORMAL,
})

interface ICampaignProviderProps {
  pageMode?: PAGE_MODE.MY_REQUESTS | PAGE_MODE.NORMAL
}

export const CampaignProvider: FC<ICampaignProviderProps> = ({
  pageMode = PAGE_MODE.NORMAL,
  children,
}) => {
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<number[]>([])
  const [campaigns, setCampaigns] = useState<minimalCategoryType[]>([])
  const [campaignMeta, setCampaignMeta] = useState<paginationMetaType>({limit: 50, page: 1})
  const [pageType, setPageType] = useState<PAGE_TYPE>(PAGE_TYPE.CURRENT)
  const location = useLocation()
  const fetchRef = useRef(0)

  useEffect(() => {
    if (location.pathname.split('/').pop() === 'history') {
      setPageType(PAGE_TYPE.HISTORY)
      return
    }
    setPageType(PAGE_TYPE.CURRENT)
  }, [location])

  useEffect(() => {
    if (fetchRef.current === 0) {
      return
    }

    getCategoriesPendingApproval()
  }, [campaignMeta.limit, campaignMeta.page])

  useEffect(() => {
    getCategoriesPendingApproval()
  }, [pageType])

  const getCategoriesPendingApproval = () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    let fetchParams: object = {has_pending_approval_products: 1}

    if (pageMode === PAGE_MODE.MY_REQUESTS) {
      fetchParams = {has_my_pending_approval_products_requests: 1}
    }

    if (pageType === PAGE_TYPE.HISTORY) {
      fetchParams = {has_history_approval_products: 1}

      if (pageMode === PAGE_MODE.MY_REQUESTS) {
        fetchParams = {has_my_history_approval_products_requests: 1}
      }
    }

    getCategoriesRequest(campaignMeta, ['description'], fetchParams).then((res) => {
      if (fetchId !== fetchRef.current) {
        return
      }

      const categories = res.data.data.map((category: categoryDataType) => {
        return {...category, category_name: category.details?.name}
      })

      const meta = res.data.meta

      setCampaigns(categories)
      setCampaignMeta({...meta, limit: meta.per_page, page: meta.current_page})
    })
  }

  const updateSelectedCampaignIds = (originals: minimalCategoryType[]) => {
    setSelectedCampaignIds(originals.map((original) => original.category_id))
  }

  return (
    <>
      <CampaignContext.Provider
        value={{
          pageType,
          selectedCampaignIds,
          campaigns,
          campaignMeta,
          updateSelectedCampaignIds,
          setCampaignMeta,
          pageMode,
        }}
      >
        {children}
      </CampaignContext.Provider>
    </>
  )
}
