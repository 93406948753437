import React, {FC} from 'react'

import {ELEMENT_CSS, ELEMENT_TWIG} from '../../../../enums/LandingElementTypeEnum'
import {KrcEditor} from '../../../atoms/forms/KrcEditor'
import {useLandingStatic} from '../../../hooks/landings/useLandingStatic'

export const LandingStatic: FC = () => {
  const {twigEditor, cssEditor, handleOnChangeEditor} = useLandingStatic()

  return (
    <>
      <div className='pb-10'>
        <div className='webview-html-content-container'>
          <div className='webview-html-content-header mt-10'>
            <h5 className='text-gray-600'>Webview HTML Content</h5>
          </div>
          <KrcEditor
            height='60vh'
            defaultLanguage='twig'
            value={twigEditor.element_data}
            onChange={(value) => {
              handleOnChangeEditor(value as string, ELEMENT_TWIG)
            }}
            className={'border'}
          />
        </div>

        <div className='webview-css-style-container'>
          <div className='webview-css-style-header mt-10'>
            <h5 className='text-gray-600'>Webview CSS Style</h5>
          </div>
          <KrcEditor
            height='30vh'
            defaultLanguage='css'
            value={cssEditor.element_data}
            onChange={(value) => {
              handleOnChangeEditor(value as string, ELEMENT_CSS)
            }}
            className={'border'}
          />
        </div>
      </div>
    </>
  )
}
