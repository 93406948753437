import {FC, useContext} from 'react'

import {CouponListContext} from '../../../../providers/campaign_and_coupon/CouponListProvider'
import {CouponListFilter} from '../../../organisms/filters/CouponListFilter'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const CouponList: FC = () => {
  const {data, setData, meta, changePage, changeLimit, columns} = useContext(CouponListContext)

  return (
    <>
      <CouponListFilter onChange={setData} />
      <KrcTable
        columns={columns}
        data={data}
        paginationProps={{
          isPagination: true,
          page: meta.page,
          limit: meta.limit,
          paginationData: meta,
          onChangePage: (page) => {
            changePage(page)
          },
          onChangeLimit: (limit) => {
            changeLimit(limit)
          },
        }}
      />
    </>
  )
}
