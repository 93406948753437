import {usePassiveProductReceivingProductTable} from '../../../../../hooks/products/passives/usePassiveProductReceivingProductTable'
import {ExcelExport} from '../../../../../layout/excel/ExcelExportWrapper'

export const PassiveProductsExport = () => {
  const {exportPassiveProducts, downloadExcelUrl} = usePassiveProductReceivingProductTable()

  return (
    <ExcelExport handleExportData={exportPassiveProducts} downloadExcelUrl={downloadExcelUrl} />
  )
}
