import {useEffect, useState} from 'react'
import {TabProps} from 'react-bootstrap-v5/lib/cjs'
import {TabsProps} from 'react-bootstrap-v5/lib/esm/Tabs'
import {useLocation, useNavigate} from 'react-router-dom'

export const useKrcTabs = (tabProps: TabProps[], tabsProps: TabsProps, isActiveKey: boolean) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [activeKey, setActiveKey] = useState<string>()

  useEffect(() => {
    if (!activeKey) {
      setActiveKey(tabsProps.defaultActiveKey as string)
    }

    if (!isActiveKey) {
      return
    }
    const key = location.hash.replace('#', '')
    const tabKeys = tabProps.map((tabProp) => {
      return tabProp.eventKey
    })

    if (tabKeys.some((tabKey: any) => tabKey === key)) {
      setActiveKey(key)
    }
  }, [location])

  const handleOnSelect = (eventKey: any) => {
    if (!isActiveKey) {
      setActiveKey(eventKey)
      return
    }

    setActiveKey(eventKey)
    navigate(`#${eventKey}`)
  }

  return {activeKey, setActiveKey, handleOnSelect}
}
