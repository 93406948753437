/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useSelector} from 'react-redux'

import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const {user} = useSelector((state: any) => state.auth)

  return (
    <>
      <AsideMenuItemWithSub
        to='/categories'
        title='Kategoriler'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/krc/categories.svg'
      >
        <AsideMenuItem to='/categories' title='Kategori Yönetimi' hasBullet={true} />
        <AsideMenuItem to='/categories/maps' title='Harita' hasBullet={true} />
        <AsideMenuItem to='/categories/sorts' title='Sıralama Yönetimi' hasBullet={true} />
        <AsideMenuItemWithSub
          to='/categories/campaigns-waiting-approval'
          title='Onay Bekleyen Kampanyalar'
          fontIcon='bi-archive'
          hasBullet={true}
          priority={2}
        >
          {user.data.can_approval_campaign ? (
            <AsideMenuItem
              to='/categories/campaigns-waiting-approval'
              title='Liste'
              hasBullet={true}
            />
          ) : (
            <AsideMenuItem
              to='/categories/campaigns-waiting-approval'
              title='Onay Taleplerim'
              hasBullet={true}
            />
          )}
          <AsideMenuItemWithSub
            to='/categories/campaigns-waiting-approval/import/*'
            title={'Toplu İşlemler'}
            fontIcon='bi-archive'
            hasBullet={true}
          >
            <AsideMenuItem
              to='/categories/campaigns-waiting-approval/import'
              title='Kampanya Ürünü Onaylama/Reddetme'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to='/*'
          title={'Toplu İşlemler'}
          fontIcon='bi-archive'
          hasBullet={true}
        >
          <AsideMenuItem to='/categories/badges' title='Badge İşlemleri' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/product'
        title='Ürünler'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/krc/products.svg'
      >
        <AsideMenuItem to='/products' title='Ürün Listesi' hasBullet={true} />
        <AsideMenuItem to='/products/passive' title='Pasif Ürünler' hasBullet={true} />
        <AsideMenuItem to='/badges' hasBullet={true} title={'Badge'} />
        <AsideMenuItemWithSub
          to='/*'
          title={'Toplu İşlemler'}
          fontIcon='bi-archive'
          hasBullet={true}
        >
          <AsideMenuItem to='/products/badges' title='Badge İşlemleri' hasBullet={true} />
          {/*
          <AsideMenuItem
            to='/products/new/prices'
            title='Fiyatı Güncellenecek Ürünler'
            hasBullet={true}
          />
          */}
          <AsideMenuItem to='/products/import' title='Ürün Açma' hasBullet={true} />
          <AsideMenuItem to='/products/update' title='Ürün Güncelle' hasBullet={true} />
          {/*
          <AsideMenuItem
            to='/products/bulk-price-trigger'
            title='6.1 Fiyat Tetikleme'
            hasBullet={true}
          />
          */}
          <AsideMenuItem to='/products/available' title={'Geçerlilik Tarihi'} hasBullet={true} />
        </AsideMenuItemWithSub>

        {/*<AsideMenuItem to='/products/attributes' title='Özellikler' hasBullet={true} />*/}
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/campaigns'
        title='Kampanya'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/krc/campaigns.svg'
      >
        <AsideMenuItem to='/deals' title='Deal' hasBullet={true} />
        <AsideMenuItem to='/ibm/groups' title='IBM Kampanya' hasBullet={true} />
        <AsideMenuItemWithSub to='/coupon' title='Kuponlar' hasBullet={true}>
          {/*<AsideMenuItem to='/coupon' title='Kupon Yönetimi' hasBullet={true} />*/}
          {/*<AsideMenuItem to='/coupon/prefix' title='Kupon Prefix' hasBullet={true} />*/}
          <AsideMenuItem to='/coupon/exclude' title='Exclude İşlemleri' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/footer'
        title='İçerik'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/krc/content.svg'
      >
        <AsideMenuItem to='/footer' title='Footer' hasBullet={true} />
        <AsideMenuItem to='/menus' title='Menü Yönetimi' hasBullet={true} />
        <AsideMenuItem to='/menus/landings' title='Kategori Landing' hasBullet={true} />
        <AsideMenuItem to='/url/aliases' title='URL Redirects' hasBullet={true} />
        <AsideMenuItem to='/landings' title='Landing Page' hasBullet={true} />
        <AsideMenuItem to='/manufacturer' title='Markalar' hasBullet={true} />
        <AsideMenuItem to='/layouts' title='Layouts' hasBullet={true} />
        <AsideMenuItem to='/faq' title='Sıkça Sorulan Sorular' hasBullet={true} />
        <AsideMenuItem to='/information' title='Bilgi Sayfaları' hasBullet={true} />
        <AsideMenuItem to='/reviews' title='Yorumlar' hasBullet={true} />
        <AsideMenuItem to='/shopping-lists' title='Alışveriş Listeleri' hasBullet={true} />
        <AsideMenuItem to='/reviews' title='Yorumlar' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/ibm/groups'
        title='Özellikler'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/krc/features.svg'
      >
        {user?.data?.can_view_game && (
          <AsideMenuItemWithSub to='/games' title='Karaca Oyun'>
            <AsideMenuItem to='/games' title='Oyunlar' hasBullet={true} />
            <AsideMenuItem to='/scoreboard' title='Oyun Puan Tablosu' hasBullet={true} />
          </AsideMenuItemWithSub>
        )}
        {/*<AsideMenuItem to='/orders' title='Siparişler' hasBullet={true} />*/}
        {user?.data?.can_view_order_questioning && (
          <AsideMenuItem to='/orders/questioning' title='Sipariş Sorgulama' hasBullet={true} />
        )}
        <AsideMenuItem to='/users' title='Kullanıcılar' hasBullet={true} />
        <AsideMenuItemWithSub to='/*' title='Yetkilendirme İşlemleri' fontIcon='bi-archive'>
          <AsideMenuItem to='/permissions' title='İzinler' hasBullet={true} />
          <AsideMenuItem to='/roles' title='Roller' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
    </>
  )
}
