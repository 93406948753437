export const NONE = 0
export const ALL = 1
export const HEPSIBURADA = 4
export const N11 = 5
export const TRENDYOL = 6
export const CICEK_SEPETI = 8
export const AMAZONTR = 9
export const VODAFONE = 23
export const MP_ALL = 10

export const ALL_MP_STORES = [HEPSIBURADA, N11, TRENDYOL, CICEK_SEPETI, AMAZONTR, VODAFONE]

export const STORES = [
  {
    store_id: ALL,
    store_id_text: 'Web',
  },
  {
    store_id: HEPSIBURADA,
    store_id_text: 'Hepsiburada',
  },
  {
    store_id: N11,
    store_id_text: 'N11',
  },
  {
    store_id: TRENDYOL,
    store_id_text: 'Trendyol',
  },
  {
    store_id: CICEK_SEPETI,
    store_id_text: 'Çiçek Sepeti',
  },
  {
    store_id: AMAZONTR,
    store_id_text: 'Amazon TR',
  },
  {
    store_id: VODAFONE,
    store_id_text: 'Vodafone',
  },
]

export const ADDABLE_STORES = [
  {
    store_id: ALL,
    store_id_text: 'Web',
  },
  {
    store_id: HEPSIBURADA,
    store_id_text: 'Hepsiburada',
  },
  {
    store_id: N11,
    store_id_text: 'N11',
  },
  {
    store_id: TRENDYOL,
    store_id_text: 'Trendyol',
  },
  {
    store_id: CICEK_SEPETI,
    store_id_text: 'Çiçek Sepeti',
  },
  {
    store_id: AMAZONTR,
    store_id_text: 'Amazon TR',
  },
  {
    store_id: VODAFONE,
    store_id_text: 'Vodafone',
  },
  {
    store_id: MP_ALL,
    store_id_text: 'MP Tümü',
  },
]

export const STORES_SEARCHABLE_SELECT_OPTIONS = [
  {
    value: ALL,
    label: 'Web',
  },
  {
    value: HEPSIBURADA,
    label: 'Hepsiburada',
  },
  {
    value: N11,
    label: 'N11',
  },
  {
    value: TRENDYOL,
    label: 'Trendyol',
  },
  {
    value: CICEK_SEPETI,
    label: 'Çiçek Sepeti',
  },
  {
    value: VODAFONE,
    label: 'Vodafone',
  },
  {
    value: AMAZONTR,
    label: 'Amazon TR',
  },
]
