import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {PRODUCT_SPECIAL_ALLOWED_DATA_AREAS} from '../../../../enums/DataAreaEnum'
import {BulkPriceTriggerContext} from '../../../../providers/products/BulkPriceTriggerProvider'
import {BulkPriceTriggerProvider} from '../../../../providers/products/BulkPriceTriggerProvider'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {UpdateBulkPriceProductSelectionWrapper} from './UpdateBulkPriceProductSelectionWrapper'

const BulkPriceTriggerToolbarRightField: FC = () => {
  const {updateAllSpecialProductCartHandle, setWillBeTriggeredStores} =
    useContext(BulkPriceTriggerContext)
  return (
    <>
      <div className={'col-5 d-flex align-items-center py-1'}>
        <KrcSelect
          placeholder={'Axapta Grup Şirket'}
          options={PRODUCT_SPECIAL_ALLOWED_DATA_AREAS.map((store: any) => {
            return {value: store.id, label: store.id.toUpperCase()}
          })}
          maxTagCount={'responsive'}
          onChange={(value) => {
            setWillBeTriggeredStores(value)
          }}
          labelTitle={' Şirket: '}
          style={{width: '100%'}}
          tooltipProps={{
            status: true,
            text: 'Axapta grup şirket. (data_area_id)',
          }}
          className={'ant-selector-border'}
        />
        <div className='d-flex align-items-center py-1 ms-2'>
          <KrcButton
            size={'middle'}
            onClick={() => updateAllSpecialProductCartHandle()}
            id='kt_toolbar_primary_button'
          >
            Tüm Ürünlerin Fiyatını Güncelle
          </KrcButton>
        </div>
      </div>
    </>
  )
}

const BulkPriceTriggerWrapperWithoutProvider: FC = () => {
  return (
    <>
      <Toolbar rightField={<BulkPriceTriggerToolbarRightField />}></Toolbar>
      <PageTitle>6.1 Toplu Fiyat Tetikleme</PageTitle>
      <UpdateBulkPriceProductSelectionWrapper />
    </>
  )
}

export const BulkPriceTriggerWrapper: FC = () => {
  return (
    <>
      <BulkPriceTriggerProvider>
        <BulkPriceTriggerWrapperWithoutProvider />
      </BulkPriceTriggerProvider>
    </>
  )
}
