import {PictureOutlined} from '@ant-design/icons'
import {Avatar, Image, message} from 'antd'
import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useEffect, useState} from 'react'

import {FAQ_STORES} from '../../../enums/FaqStoresTypeEnum'
import {FileManagerModal} from '../../../service/FileManager/FileManagerModal'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {KrcRichTextEditorWithTable} from '../../atoms/forms/KrcRichTextEditorWithTable'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useFaqUpdateForm} from '../../hooks/faq/useFaqUpdateForm'
import {Loader} from '../../molecules/Loader'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'

export const FaqUpdateForm: FC = () => {
  const DEFAULT_IMAGE_PATH = 'https://cdn.karaca.com/'
  const {faqs, faqInformation, updateFaq} = useFaqUpdateForm()
  const [stores, setStores] = useState<number[]>([])
  const [parentTopic, setParentTopic] = useState<number | string>()
  const [metaDescription, setMetaDescription] = useState<string>('')
  const [icon, setIcon] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  useEffect(() => {
    if (!faqInformation) return
    if (faqInformation && faqInformation.stores) {
      setStores(faqInformation.stores)
    }
    setParentTopic(faqInformation.topic_id)
    setMetaDescription(faqInformation.meta_description)
    setIcon(faqInformation.icon)
    setDescription(faqInformation.description)
  }, [faqInformation])

  const handleOnSubmit = (values: FormikValues) => {
    const parentTopicValue = parentTopic === '--Yok--' ? 0 : parentTopic

    if (!values.title) {
      message.error('Konular Sorular alanı boş bırakılamaz.')
      return
    }

    if (!description) {
      message.error('Açıklama alanı boş bırakılamaz.')
      return
    }

    if (!stores || stores.length === 0) {
      message.error('En az bir mağaza seçmelisiniz.')
      return
    }

    updateFaq({
      ...values,
      stores: stores,
      topic_id: parentTopicValue,
      meta_description: metaDescription,
      icon: icon,
      description: description,
    })
  }

  return (
    <div>
      {!faqInformation || faqs.length === 0 || !faqInformation.title ? (
        <Loader />
      ) : (
        <div>
          <Formik initialValues={faqInformation} onSubmit={handleOnSubmit}>
            {() => {
              return (
                <Form className={'form mt-6 space-between'}>
                  <div className={'row'}>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'title'}
                        required
                        labelTitle={'Konular Sorular'}
                      />
                    </div>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'seo_url_keyword'}
                        labelTitle={'Seo Kelimesi'}
                      />
                    </div>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2'}
                        name={'status'}
                        required
                        labelTitle={'Durum'}
                      >
                        <Field as='select' name='status' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Aktif</option>
                          <option value={0}>Pasif</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'number'}
                        name={'sort_order'}
                        labelTitle={'Sıra'}
                      />
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2 z-index-9999'}
                        name={'stores'}
                        required
                        labelTitle={'Mağazalar'}
                      >
                        <KrcSelect
                          size={'large'}
                          mode={'multiple'}
                          maxTagCount='responsive'
                          className={
                            'ant-selector-border custom-ant-select-selection-placeholder w-full'
                          }
                          defaultValue={faqInformation.stores}
                          value={stores}
                          style={{width: '100%'}}
                          onChange={(data) => setStores(data)}
                          options={FAQ_STORES.map((store) => {
                            return {value: store.id, label: store.store_name}
                          })}
                        />
                      </KrcFormikField>
                    </div>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2'}
                        name={'leftmenu'}
                        required
                        labelTitle={'Sol Menu'}
                      >
                        <Field as='select' name='leftmenu' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Evet</option>
                          <option value={0}>Hayır</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2'}
                        name={'featured'}
                        required
                        labelTitle={'Öne Çıkar'}
                      >
                        <Field as='select' name='featured' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Evet</option>
                          <option value={0}>Hayır</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                    <div className={'col-3'}>
                      <KrcFormikField
                        className={'mb-2 z-index-9999'}
                        name={'parent'}
                        labelTitle={'Soru Başlığı'}
                      >
                        <KrcSelect
                          size={'large'}
                          maxTagCount='responsive'
                          disabled={!faqInformation.topic_id}
                          aria-readonly={true}
                          className={
                            'ant-selector-border custom-ant-select-selection-placeholder w-full'
                          }
                          defaultValue={
                            faqInformation.topic_id ? faqInformation.topic_id : '--Yok--'
                          }
                          value={parentTopic ? parentTopic : '--Yok--'}
                          style={{width: '100%'}}
                          onChange={(data) => setParentTopic(data)}
                          options={faqs.map((faq: any) => {
                            return {value: faq.faq_id, label: faq.title}
                          })}
                        />
                      </KrcFormikField>
                    </div>
                  </div>
                  <div className={'row mt-2'}>
                    <div className={'col-6'}>
                      <div className={'form-group'}>
                        <label className={'mb-2'}>Meta Açıklaması</label>
                        <textarea
                          className={'form-control'}
                          rows={5}
                          onChange={(e) => {
                            setMetaDescription(e.target.value)
                          }}
                          name={'meta_description'}
                          defaultValue={metaDescription}
                          value={metaDescription}
                        />
                      </div>
                    </div>
                    <div className={'col-4 ms-5'}>
                      <div className={'d-flex justify-content-center align-content-center center'}>
                        <p>İkon</p>
                      </div>
                      <div className={'d-flex flex-row justify-content-around align-items-center'}>
                        {icon ? (
                          <div>
                            <Image
                              preview={{
                                maskClassName: 'rounded-circle',
                              }}
                              width={80}
                              height={80}
                              className={'rounded-circle'}
                              src={DEFAULT_IMAGE_PATH + icon}
                            />
                          </div>
                        ) : (
                          <div>
                            <Avatar
                              size={80}
                              icon={<PictureOutlined />}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                          </div>
                        )}
                        <div className={'mt-2'}>
                          <FileManagerModal
                            onSelected={(link) => {
                              setIcon(link ?? '')
                            }}
                            defaultImage={icon ? DEFAULT_IMAGE_PATH + icon : undefined}
                          />
                        </div>
                      </div>
                      <div>
                        <KrcButton
                          type={'default'}
                          className={'border-2 mt-2 w-100'}
                          style={{borderRadius: 5}}
                          onClick={() => {
                            message.success('İcon Kaldırıldı.')
                            setIcon('')
                          }}
                        >
                          Görseli Kaldır
                        </KrcButton>
                      </div>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'form-group mt-3'}>
                      <label className={'mb-1'}>
                        Açıklama<span className={'text-danger ms-1'}>*</span>
                      </label>
                      <KrcRichTextEditorWithTable
                        className={'mt-4'}
                        value={description}
                        height={250}
                        onChange={(value) => {
                          if (value) {
                            setDescription(value)
                          } else {
                            setDescription('')
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={'d-flex justify-content-end mt-7'}>
                    <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                      Kaydet
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      )}
    </div>
  )
}
