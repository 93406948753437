import {Route, Routes} from 'react-router-dom'

import {InformationListWrapper} from '../components/pages/information/main/InformationListWrapper'
import {InformationSubTopicListWrapper} from '../components/pages/information/sub/InformationSubTopicListWrapper'
import {InformationSubTopicCreateFormWrapper} from '../components/pages/information/sub/store/InformationSubTopicCreateFormWrapper'
import {InformationSubTopicUpdateFormWrapper} from '../components/pages/information/sub/update/InformationSubTopicUpdateFormWrapper'

export const InformationRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<InformationListWrapper />} />
        <Route path={'/:informationId'} element={<InformationSubTopicListWrapper />} />
        <Route
          path={'/:topicId/update/:informationId'}
          element={<InformationSubTopicUpdateFormWrapper />}
        />
        <Route path={'/:topicId/create'} element={<InformationSubTopicCreateFormWrapper />} />
      </Routes>
    </>
  )
}
