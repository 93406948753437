import {SelectProps} from 'antd'
import {FC, useEffect} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useOrderStatusTypeFilter} from '../../hooks/order/useOrderStatusTypeFilter'
import {IOrderStatusTypeFilter} from '../../models/order/OrderStatusTypeFilterModel'

const orderStatusFilterConfig = {
  default: {
    style: {width: '100%'},
    mode: undefined,
    size: 'middle',
    placeholder: 'Sipariş Durumu',
  },
}
export const OrderStatusTypeFilter: FC<IOrderStatusTypeFilter & SelectProps> = ({
  onSelected,
  children,
  clear,
  ...props
}) => {
  const currentProps = useKrcProp({
    config: orderStatusFilterConfig.default,
    props,
  }).getCurrentProps()

  const {handleOnChange, options, orderStatusTypeId} = useOrderStatusTypeFilter()

  useEffect(() => {
    if (!orderStatusTypeId) {
      return
    }
    onSelected(orderStatusTypeId)
  }, [orderStatusTypeId])

  return (
    <>
      <KrcSelect
        {...currentProps}
        clear={clear}
        showArrow
        allowClear
        mode={undefined}
        style={{width: 160}}
        onChange={handleOnChange}
        className={'ant-selector-border'}
        options={options}
      />
    </>
  )
}
