import {Field, Form, Formik} from 'formik'
import {OptionData} from 'rc-select/lib/interface'
import React, {FC, useContext} from 'react'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'

import {CategoryDetailContext} from '../../../../providers/category/detail/CategoryDetailProvider'
import {FileManagerModal} from '../../../../service/FileManager/FileManagerModal'
import {KrcAlert} from '../../../atoms/KrcAlert'
import {CategoryTypeEnum} from '../../../models/categories/CategoryModel'
import {categoryUrlType} from '../../../models/categories/CategoryProductModel'
import {CategoryFilter} from '../../../molecules/filters/CategoryFilter'
import {CategoryTypeFilter} from '../../../molecules/filters/CategoryTypeFilter'
import {StoreFilter} from '../../../molecules/filters/StoreFilter'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'
import {PickCategoryIcon} from '../../../organisms/categories/PickCategoryIcon'
import {CategoryGhostFilters} from '../../../organisms/categories/ghosts/CategoryGhostFilters'

const informationSchema = Yup.object({
  name: Yup.string().required('Kategori adı zorunlu alandır.'),
  short_name: Yup.string(),
  parent_category: Yup.object().nullable(),
  status: Yup.bool(), // Default false
  url_alias: Yup.string(),
  top: Yup.bool(),
  stores: Yup.object().nullable(),
  category_type: Yup.object().required('Kategori tipi zorunlu alandır.'),
  add_to_cart_store: Yup.bool(),
})

export const CategoryInformation: FC = () => {
  const {
    information,
    filters,
    filterUniqueIds,
    setIsInformationValid,
    updateFilters,
    updateInformation,
    handleInformationOnSubmit,
    onChangeInformationValidate,
  } = useContext(CategoryDetailContext)
  const {categoryId} = useParams<categoryUrlType>()

  return (
    <>
      {information.is_ibm_campaign && (
        <KrcAlert
          className={'mt-2'}
          style={{borderRadius: '5px'}}
          type={'warning'}
          message={'Bu Kategori IBM kampanyalı olduğu için düzenlenemez.'}
        />
      )}
      <Formik
        enableReinitialize
        validationSchema={informationSchema}
        initialValues={information}
        onSubmit={handleInformationOnSubmit}
        validate={onChangeInformationValidate}
        validateOnMount={true}
      >
        {(formik) => {
          setTimeout(() => {
            setIsInformationValid(formik.isValid)
          }, 0)

          return (
            <Form className='form row mt-5' noValidate>
              <div
                className={'col-md-6 d-flex'}
                style={{flexDirection: 'column', paddingRight: 40}}
              >
                <div className={'d-flex'} style={{flexDirection: 'column'}}>
                  <KrcFormikField
                    className={'mb-2'}
                    name={'name'}
                    labelTitle={'Kategori Adı'}
                    required={true}
                  />
                  <KrcFormikField
                    className={'mb-2'}
                    name={'short_name'}
                    labelTitle={'Webde Görünecek Kategori Adı'}
                  />
                </div>
                <div
                  className={'d-flex align-items-center'}
                  style={{justifyContent: 'space-between'}}
                >
                  <KrcFormikField
                    name={'parent_category'}
                    className={'mb-2 col-md-6'}
                    labelTitle={'Bağlı olduğu kategori'}
                  >
                    <CategoryFilter
                      value={information.parent_category}
                      className={'select-filter-custom'}
                      placeholder={'Lütfen seçiniz'}
                      categoryType={CategoryTypeEnum.ALL}
                      dataType={'item'}
                      dropdownStyle={{zIndex: 99999}}
                      showSearch={true}
                      mode={undefined}
                      withPath={true}
                      allowClear={true}
                      onClear={() => {
                        updateInformation('parent_category', null)
                      }}
                      onSelected={(data) => {
                        const item = (data[0] ?? null) as OptionData | null

                        if (!item) {
                          return
                        }
                        updateInformation('parent_category', {
                          value: item.value,
                          label: item.label,
                        })
                      }}
                    />
                  </KrcFormikField>
                  <KrcFormikField
                    className={'d-flex align-items-start col-md-6 ml-5 mt-6'}
                    name={'top'}
                  >
                    <Field style={{marginTop: '0.20rem'}} type={'checkbox'} name={'top'} />
                    <span className={'ml-2'}>Üst Menüde Göster</span>
                  </KrcFormikField>
                </div>
                <div className={'mb-2 d-flex'}>
                  <KrcFormikField className={'col-md-6'} name={'stores'} labelTitle={'Mağazalar'}>
                    <StoreFilter
                      value={information.stores}
                      className={'select-filter-custom'}
                      placeholder={'Lütfen seçiniz'}
                      showSearch={true}
                      dataType={'item'}
                      dropdownStyle={{zIndex: 99999}}
                      onSelected={(data) => {
                        if (!data) {
                          return
                        }

                        updateInformation(
                          'stores',
                          (data as OptionData[]).map((item) => {
                            return {
                              value: item.value,
                              label: item.label,
                            }
                          })
                        )
                      }}
                    />
                  </KrcFormikField>
                  <div className={'col-md-6'} style={{paddingLeft: 25}}>
                    <KrcFormikField
                      name={'category_type'}
                      required={true}
                      className={''}
                      labelTitle={'Kategori Tipi'}
                    >
                      <CategoryTypeFilter
                        className={'select-filter-custom'}
                        value={information.category_type}
                        mode={undefined}
                        onSelected={(data) => {
                          const item = (data[0] ?? null) as OptionData | null

                          if (!item) {
                            return
                          }

                          updateInformation('category_type', {
                            value: item.value,
                            label: item.label,
                          })
                        }}
                      />
                    </KrcFormikField>
                  </div>
                </div>
                <div className={'col-md-7 d-flex'} style={{flexDirection: 'column'}}>
                  <KrcFormikField
                    className={'mb-2'}
                    name={'url_alias'}
                    labelTitle={'SEO URL kelimesi'}
                    placeholder={'SEO URL'}
                    tooltipProps={{
                      status: true,
                      text: 'Kategori URL eğer girilmezse, kategori adı kullanılır.',
                    }}
                  />
                </div>
                <div className={'d-flex flex-column'}>
                  <KrcFormikField
                    className={'mb-2 col-md-3'}
                    name={'add_to_cart_store'}
                    labelTitle={'Sepete Ekleme Durumu'}
                  >
                    <Field as='select' name='add_to_cart_store' className='form-select'>
                      <option value=''>Seçiniz</option>
                      <option value='1'>Açık</option>
                      <option value='0'>Kapalı</option>
                    </Field>
                  </KrcFormikField>
                  <KrcFormikField
                    className={'mb-2 col-md-3'}
                    name={'status'}
                    labelTitle={'Durumu'}
                    required={true}
                  >
                    <Field
                      as='select'
                      name='status'
                      className='form-select'
                      aria-label='Select example'
                    >
                      <option value=''>Seçiniz</option>
                      <option value='1'>Aktif</option>
                      <option value='0'>Pasif</option>
                    </Field>
                  </KrcFormikField>
                </div>
              </div>
              <div className={'col-md-6 d-flex gap-5 flex-column'} style={{paddingLeft: 40}}>
                <div>
                  <PickCategoryIcon
                    defaultIcon={information.icon}
                    onChangeIcon={(icon) => {
                      updateInformation('icon', icon.key)
                    }}
                  />
                </div>
                {categoryId ? (
                  <>
                    <div>
                      <KrcFormikField
                        name={'image'}
                        labelTitle={'Kategori Web Image'}
                        className={''}
                      >
                        <FileManagerModal
                          onSelected={(link) => {
                            updateInformation('image', link)
                          }}
                          tempUploadDir={`image/category/${categoryId}`}
                          defaultImage={information.image ?? ''}
                        />
                      </KrcFormikField>

                      {/*TODO: Yeni yapıya geçilince kullanıcak component*/}
                      {/*<BannerImages*/}
                      {/*  title={'Kategori Web Image'}*/}
                      {/*  images={[*/}
                      {/*    'https://via.placeholder.com/125x125',*/}
                      {/*    'https://via.placeholder.com/125x125',*/}
                      {/*    'https://via.placeholder.com/125x125',*/}
                      {/*    'https://via.placeholder.com/125x125',*/}
                      {/*    'https://via.placeholder.com/125x125',*/}
                      {/*  ]}*/}
                      {/*/>*/}
                    </div>
                    <div>
                      <KrcFormikField
                        name={'image'}
                        labelTitle={'Kategori Web Image'}
                        className={''}
                      >
                        <FileManagerModal
                          onSelected={(link) => {
                            updateInformation('mobile_image', link)
                          }}
                          tempUploadDir={`image/category/mobile/${categoryId}`}
                          defaultImage={information.mobile_image ?? ''}
                        />
                      </KrcFormikField>
                      {/*<BannerImages*/}
                      {/*  title={'Kategori Mobile Image'}*/}
                      {/*  images={[*/}
                      {/*    'https://via.placeholder.com/125x125',*/}
                      {/*    'https://via.placeholder.com/125x125',*/}
                      {/*    'https://via.placeholder.com/125x125',*/}
                      {/*    'https://via.placeholder.com/125x125',*/}
                      {/*    'https://via.placeholder.com/125x125',*/}
                      {/*  ]}*/}
                      {/*/>*/}
                    </div>
                  </>
                ) : (
                  <>
                    <KrcAlert
                      type={'info'}
                      message={'Görsel yükleyebilmek için lütfen önce kategoriyi oluşturunuz.'}
                    ></KrcAlert>
                  </>
                )}
              </div>
              {information.category_type?.value === CategoryTypeEnum.GHOST && (
                <div className={'mt-10'}>
                  {filters.map((filter, deep) => (
                    <div
                      key={`ghost-filter-group-${filterUniqueIds[deep]}`}
                      className={'d-flex p-4 flex-column mt-5 mb-5 align-items-center gap-5'}
                      style={{borderRadius: '7px', backgroundColor: '#F3F3F3'}}
                    >
                      {/*<Tooltip title={'Sil'}>*/}
                      {/*  <Popconfirm*/}
                      {/*    title='Bu filtreyi silmek istediğinden emin misin ?'*/}
                      {/*    onConfirm={(e) => {*/}
                      {/*      deleteFilter(deep)*/}
                      {/*    }}*/}
                      {/*    okText='Evet'*/}
                      {/*    cancelText='Hayır'*/}
                      {/*  >*/}
                      {/*    <div*/}
                      {/*      className='btn btn-icon btn-active-color-primary btn-sm'*/}
                      {/*      style={{position: 'absolute', right: 40}}*/}
                      {/*    >*/}
                      {/*      <CloseOutlined />*/}
                      {/*    </div>*/}
                      {/*  </Popconfirm>*/}
                      {/*</Tooltip>*/}

                      <CategoryGhostFilters
                        deep={deep}
                        defaultFilters={filter}
                        onChangeFilters={(data) => {
                          updateFilters(deep, data)
                        }}
                      />
                    </div>
                  ))}
                  {/*<div className={'d-flex justify-content-center'}>*/}
                  {/*<KrcButton*/}
                  {/*  onClick={() => {*/}
                  {/*    addFilter([])*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Filtre Ekle*/}
                  {/*</KrcButton>*/}
                  {/*</div>*/}
                </div>
              )}
              {!information.is_ibm_campaign && (
                <div className={'d-flex justify-content-end mt-7 mb-5'}>
                  <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                    Kaydet
                  </button>
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
