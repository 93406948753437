import {message} from 'antd'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {AVAILABLE_FAQ_STORES} from '../../../enums/FaqStoresTypeEnum'
import {getFaqRequest, showFaqRequest, updateFaqRequest} from '../../../requests/faq/FaqRequest'

type FaqUrlParams = {
  faqId: string
}

export const useFaqUpdateForm = () => {
  const {faqId} = useParams<FaqUrlParams>()
  const [isLoading, setIsLoading] = useState(false)
  const [faqs, setFaqs] = useState<any>([])
  const [faqInformation, setFaqInformation] = useState<any>({})

  const urlParams = window.location.search.substring(1)
  const selectedStoreId = urlParams.split('store=')[1].split('&')[0]

  useEffect(() => {
    setIsLoading(true)
    getAllFaqTitles()
    getFaqInformation()
    setIsLoading(false)
  }, [])

  const getAllFaqTitles = () => {
    getFaqRequest({}, {page: 1, limit: 10}, Number(selectedStoreId), 1)
      .then((response) => {
        setFaqs(response.data.data)
      })
      .catch((error) => {
        message.error('Sıkça sorulan sorular getirilirken bir hata oluştu!')
      })
  }

  const getFaqInformation = () => {
    showFaqRequest(Number(faqId))
      .then((response) => {
        const responseStores = response.data.data.stores
        const availableStores = responseStores.filter((store: number) =>
          AVAILABLE_FAQ_STORES.includes(store)
        )
        setFaqInformation({
          ...response.data.data,
          title: response.data.data.description.title,
          description: response.data.data.description.description,
          meta_description: response.data.data.description.meta_description,
          icon: response.data.data.description.icon,
          stores: availableStores,
        })
      })
      .catch((error) => {
        message.error('Sıkça sorulan sorular getirilirken bir hata oluştu!')
      })
  }

  const updateFaq = (values: any) => {
    message.info('Sıkça sorulan soru güncelleniyor...')
    updateFaqRequest(Number(faqId), values)
      .then((response) => {
        message.success('Sıkça sorulan soru başarıyla güncellendi.')
        getFaqInformation()
      })
      .catch((error) => {
        message.error('Sıkça sorulan soru güncellenirken bir hata oluştu!')
      })
  }

  return {
    isLoading,
    faqs,
    faqInformation,
    updateFaq,
  }
}
