import React from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {ShoppingListUpdateForm} from './forms/ShoppingListUpdateForm'

export const ShoppingListUpdateFormWrapper = () => {
  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Alışveriş Listesi Güncelle</PageTitle>
      <ShoppingListUpdateForm />
    </>
  )
}
