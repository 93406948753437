import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal, Tooltip, message} from 'antd'
import cn from 'classnames'
import React, {FC, useContext, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'

import {PageTitle} from '../../../../../../../_metronic/layout/core'
import {CategoryProductsProcessContext} from '../../../../../../providers/category/products/CategoryProductsProcessProvider'
import {KrcButton} from '../../../../../atoms/buttons/KrcButton'
import {ConstraintProductListToCampaign} from '../../../../../drawers/ConstraintProductListToCampaign'
import {AddProductsToCategoryDrawer} from '../../../../../drawers/category/product/AddProductsToCategoryDrawer'
import {CategoryProductProcessSummaryTable} from '../../../../../modals/CategoryProductProcessSummaryTable'
import {ICategoryProductProcessPage} from '../../../../../models/categories/CategoryProductModel'
import {productTextSearchType} from '../../../../../models/products/ProductListFilterModel'
import {productTextAreaFilterDataType} from '../../../../../models/products/ProductSelectionModel'
import {Loader} from '../../../../../molecules/Loader'
import {ProductTextAreaFilter} from '../../../../../molecules/filters/ProductTextAreaFilter'
import {TableHeader} from '../../../../../molecules/headers/TableHeader'
import {KrcTable} from '../../../../../organisms/tables/KrcTable'
import {Toolbar} from '../../../../../organisms/toolbars/Toolbar'

const {confirm} = Modal
const CategoryProductsProcessToolbar: FC = () => {
  const {
    setAddProductDrawerVisible,
    addSelectedProductsToWillBeDeletedProducts,
    removeCategoryProductByFilteredProducts,
    setVisibleConstraintListFilter,
    category,
    meta,
    textFilter,
  } = useContext(CategoryProductsProcessContext)

  /**
   * Filtrelenen ürünleri direkt olarak işleyebilir miyiz gerekli koşullar sağlanıyor mu kontrol eder.
   */
  const checkFilteredAllProductsIsAvailableToHandle = () => {
    if (!meta?.total || meta?.total < 1) {
      message.warn('Filtrelenmiş ürün bulunamadı.')
      return false
    }

    if (textFilter === undefined) {
      message.warn('Önce metin seçimi ile filtre yapmalısınız.')
      return false
    }

    if (
      (textFilter as productTextAreaFilterDataType)?.productIds.length === 0 &&
      (textFilter as productTextAreaFilterDataType)?.models.length === 0
    ) {
      message.warn('Önce metin seçimi ile filtre yapmalısınız.')
      return false
    }

    return true
  }

  const handleToRemoveFilteredAllProducts = () => {
    if (!checkFilteredAllProductsIsAvailableToHandle()) {
      return
    }

    confirm({
      title: `DİKKAT!`,
      icon: <ExclamationCircleOutlined />,
      content: `${meta.total} ürünün tümünü kontrol etmeden silip kaydetmek üzeresiniz. Ürünleri silip kaydetmek istediğinize emin misiniz?`,
      okText: 'Devam',
      cancelText: 'Vazgeç',
      onOk() {
        confirm({
          title: `${meta.total} adet ürün silinecek`,
          icon: <ExclamationCircleOutlined />,
          content: `${meta.total} adet ürün kategoriden silinecek ve kaydedilecek. Devam etmek istiyor musunuz?`,
          okText: 'Ürünleri Sil ve Kaydet',
          cancelText: 'İptal',
          onOk() {
            removeCategoryProductByFilteredProducts(textFilter as productTextSearchType)
          },
        })
      },
    })
  }

  return (
    <>
      <div>
        {category?.category_type === 'campaign' && (
          <KrcButton
            size={'middle'}
            type={'default'}
            onClick={() => {
              setVisibleConstraintListFilter(true)
            }}
            id='kt_toolbar_primary_button'
            className={'mr-3'}
          >
            Kısıtlı Ürün Listesi
          </KrcButton>
        )}
        <KrcButton
          size={'middle'}
          type={'default'}
          onClick={() => {
            addSelectedProductsToWillBeDeletedProducts()
          }}
          className={'mr-3'}
          id='kt_toolbar_primary_button'
        >
          Seçili Ürünleri Sil
        </KrcButton>
        <KrcButton
          size={'middle'}
          type={'default'}
          onClick={() => {
            handleToRemoveFilteredAllProducts()
          }}
          className={'mr-3'}
          id='kt_toolbar_primary_button'
        >
          Filtrelenen Ürünleri Sil ve Kaydet
        </KrcButton>
        <KrcButton
          size={'middle'}
          onClick={() => {
            setAddProductDrawerVisible(true)
          }}
          id='kt_toolbar_primary_button'
        >
          Ürün Ekle
        </KrcButton>
      </div>
    </>
  )
}

export const CategoryProductProcessList: FC<ICategoryProductProcessPage> = ({pageType}) => {
  const {
    columns,
    productsToTable,
    meta,
    updateMeta,
    handleOnFilter,
    pageTitle,
    addProductDrawerVisible,
    setAddProductDrawerVisible,
    addWillBeAddedProducts,
    category,
    updateCategoryProduct,
    setSelectedProducts,
    unSelectAllItems,
    selectedProducts,
    visibleTextFilter,
    setVisibleTextFilter,
    setTextFilter,
    getExportCategoryProductsUrl,
    visibleConstraintListFilter,
    setVisibleConstraintListFilter,
    willBeAddedProducts,
    willBeDeletedProducts,
    setPageType,
    isProductsToTableLoading,
    initialRef,
    getCategoryProducts,
  } = useContext(CategoryProductsProcessContext)
  const {user} = useSelector((state: any) => state.auth)

  useEffect(() => {
    setPageType(pageType)

    if (initialRef.current !== 0) {
      getCategoryProducts()
    }
  }, [])

  return (
    <>
      <Toolbar>
        <CategoryProductsProcessToolbar />
      </Toolbar>
      <PageTitle>{pageTitle}</PageTitle>

      <TableHeader title={'Kategori Ürünleri'} selectedItemCount={selectedProducts.length}>
        <div className={'d-flex'}>
          <Link className={'text-primary mr-3'} role={'button'} to={getExportCategoryProductsUrl()}>
            Ürünleri dışarı aktar
          </Link>

          <div
            className={'text-primary'}
            role={'button'}
            onClick={() => {
              setVisibleTextFilter((data: boolean) => !data)
            }}
          >
            Metin ile ara
          </div>
        </div>
      </TableHeader>

      <div className={cn({'d-block': visibleTextFilter}, {'d-none': !visibleTextFilter})}>
        <ProductTextAreaFilter
          onFilter={(data: productTextSearchType) => {
            setTextFilter(data)
          }}
        />
      </div>

      {isProductsToTableLoading ? (
        <Loader />
      ) : (
        <KrcTable
          columns={columns}
          data={productsToTable}
          outerHeight={visibleTextFilter ? 450 : 260}
          paginationProps={{
            isPagination: true,
            limit: meta.limit,
            page: meta.page,
            paginationData: meta,
            onChangeLimit: (limit) => {
              updateMeta({limit})
            },
            onChangePage: (page) => {
              updateMeta({page})
            },
          }}
          searchableProps={{
            isSearchable: true,
            onSearch: handleOnFilter,
          }}
          selectableProps={{
            isSelectable: true,
            onSelectedItems: (data) => {
              setSelectedProducts(data)
            },
            unSelectAllItems,
          }}
        />
      )}
      <div className={'d-flex justify-content-end w-full'}>
        <KrcButton
          className={'mt-2'}
          disabled={willBeAddedProducts.length === 0 && willBeDeletedProducts.length === 0}
          size={'large'}
          onClick={() => {
            confirm({
              width: 1000,
              title: category?.is_category_products_has_to_be_approved
                ? `Onaya göndermek istediğinizden emin misiniz ?`
                : `Yapılan tüm değişiklikler kaydedilecek emin misin ?`,
              icon: <ExclamationCircleOutlined />,
              content: (
                <CategoryProductProcessSummaryTable
                  willBeAddedProducts={willBeAddedProducts}
                  willBeDeletedProducts={willBeDeletedProducts}
                  type={category?.category_type ?? 'category'}
                />
              ),
              closable: true,
              okText: 'Tamam',
              cancelText: 'İptal',
              onOk() {
                updateCategoryProduct()
              },
            })
          }}
        >
          {category?.is_category_products_has_to_be_approved ? 'Onaya Gönder' : 'Kaydet ve Yayınla'}
        </KrcButton>
        {category?.is_category_products_has_to_be_approved && user.data.can_urgent_approval && (
          <Tooltip placement={'top'} title={'Onaylama sizin tarafınızdan gerçekleşecektir'}>
            <KrcButton
              className={'mt-2 ant-btn-danger ml-3'}
              disabled={willBeAddedProducts.length === 0 && willBeDeletedProducts.length === 0}
              size={'large'}
              onClick={() => {
                confirm({
                  width: 1000,
                  title: `Onaya göndermeden işlem yapmak istediğinizden emin misiniz ?`,
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <CategoryProductProcessSummaryTable
                      willBeAddedProducts={willBeAddedProducts}
                      willBeDeletedProducts={willBeDeletedProducts}
                      type={category?.category_type ?? 'category'}
                    />
                  ),
                  closable: true,
                  okText: 'Tamam',
                  cancelText: 'İptal',
                  onOk() {
                    updateCategoryProduct(true)
                  },
                })
              }}
            >
              Acil Onay
            </KrcButton>
          </Tooltip>
        )}
      </div>
      {category && (
        <AddProductsToCategoryDrawer
          visible={addProductDrawerVisible}
          onClose={() => {
            setAddProductDrawerVisible(false)
          }}
          onOk={(data, constraint) => {
            addWillBeAddedProducts(data, constraint)
          }}
          type={category?.category_type}
        />
      )}
      {category && (
        <ConstraintProductListToCampaign
          category={category}
          visible={visibleConstraintListFilter}
          onHide={setVisibleConstraintListFilter}
        />
      )}
    </>
  )
}
