import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import * as Yup from 'yup'

import {UrlAliasContext} from '../../../../providers/UrlAliasProvider'
import {ICreateUrlAliasModal} from '../../../models/url_alias/UrlAliasModel'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'
import {KrcModal} from '../../KrcModal'

const initialValues = {
  query: '',
  keyword: '',
  status: '',
  redirect: '',
  noindex: false,
  page_type: '',
}

const schema = Yup.object({
  query: Yup.string().required('Query zorunlu alan.').max(255),
  keyword: Yup.string().required('Keyword zorunlu alan.').max(255),
  status: Yup.number()
    .nullable(true)
    .min(100, 'Minimum 100 olabilir.')
    .max(599, 'Maximum 599 olabilir.'),
  redirect: Yup.string().max(255),
  noindex: Yup.string().required('NoIndex zorunlu alan.'),
  page_type: Yup.string().max(255),
})

export const UrlAliasCreateModal: FC<ICreateUrlAliasModal> = ({onSubmit, onHide}) => {
  const [initValues, setInitValues] = useState<any>(initialValues)

  const {createUrlAlias} = useContext(UrlAliasContext)

  useEffect(() => {
    setInitValues(initialValues)
  }, [])

  const handleOnSubmit = (data: FormikValues) => {
    createUrlAlias({
      noindex: data.noindex,
      page_type: data.page_type || undefined,
      query: data.query,
      keyword: data.keyword,
      status: data.status || undefined,
      redirect: data.redirect || undefined,
    }).then(() => {
      onSubmit && onSubmit()
    })
  }

  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
        }}
        modalHeaderProps={{
          title: 'Url Alias Oluştur',
          onHide,
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10'>
          <Formik
            validationSchema={schema}
            initialValues={initValues}
            onSubmit={handleOnSubmit}
            validate={setInitValues}
            enableReinitialize
          >
            {() => (
              <Form className='form row' noValidate>
                <KrcFormikField
                  className={'mb-2 col-md-6'}
                  name={'query'}
                  labelTitle={'Query'}
                  required
                >
                  <Field type='text' name='query' className='form-control' autoComplete='off' />
                </KrcFormikField>

                <KrcFormikField
                  className={'mb-2 col-md-6'}
                  name={'keyword'}
                  labelTitle={'Keyword'}
                  required
                >
                  <Field type='text' name='keyword' className='form-control' autoComplete='off' />
                </KrcFormikField>

                <KrcFormikField className={'mb-2 col-md-6'} name={'status'} labelTitle={'Status'}>
                  <Field type='number' name='status' className='form-control' autoComplete='off' />
                </KrcFormikField>

                <KrcFormikField
                  className={'mb-2 col-md-6'}
                  name={'redirect'}
                  labelTitle={'Redirect'}
                >
                  <Field type='text' name='redirect' className='form-control' autoComplete='off' />
                </KrcFormikField>

                <KrcFormikField
                  className={'mb-2 col-md-6'}
                  name={'noindex'}
                  labelTitle={'No Index'}
                  required
                >
                  <Field as='select' name='noindex' className='form-select'>
                    <option value='1'>Evet</option>
                    <option value='0'>Hayır</option>
                  </Field>
                </KrcFormikField>

                <KrcFormikField
                  className={'mb-2 col-md-6'}
                  name={'page_type'}
                  labelTitle={'Page Type'}
                >
                  <Field type='text' name='page_type' className='form-control' autoComplete='off' />
                </KrcFormikField>

                <div className={'d-flex justify-content-end mt-7'}>
                  <button type='submit' className='btn btn-success' style={{marginLeft: 10}}>
                    Kaydet
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
