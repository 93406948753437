import {message} from 'antd'
import React, {FC} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

import {useKrcProp} from '../hooks/components/useKrcProp'

const configCopyToClipboard = {
  default: {},
}

interface IConfigLink {
  config?: 'default'
  onCopy?(text: string, result: boolean): void
}

export const KrcCopyToClipboard: FC<IConfigLink & CopyToClipboard.Props> = ({
  config = 'default',
  children,
  onCopy,
  ...props
}) => {
  const currentProps = useKrcProp({config: configCopyToClipboard[config], props}).getCurrentProps()

  return (
    <>
      <CopyToClipboard
        onCopy={(text, result) => {
          message.success('Panoya Kopyalandı')

          if (typeof onCopy === 'function') {
            onCopy(text, result)
          }
        }}
        {...currentProps}
      >
        {children}
      </CopyToClipboard>
    </>
  )
}
