export const STATUS_PENDING = 'pending'
export const STATUS_REJECTED = 'rejected'
export const STATUS_APPROVED = 'approved'

export enum PAGE_TYPE {
  CURRENT = 'current',
  HISTORY = 'history',
}

export enum PAGE_MODE {
  NORMAL = 'normal',
  MY_REQUESTS = 'my_requests',
}
