import {PictureOutlined} from '@ant-design/icons'
import {Avatar, Divider, Drawer, Image, Space, Spin} from 'antd'
import {Field, Form, Formik} from 'formik'
import React, {FC, useEffect, useRef} from 'react'

import {BADGE_LOCATIONS} from '../../enums/BadgeLocationTypeEnum'
import {BADGE_STATUS_TYPES} from '../../enums/BadgeStatusTypeEnums'
import {DrawerEnum} from '../../enums/DrawerEnum'
import {IBM_CAMPAIGN_SEO_URL_TYPES} from '../../enums/IBMCampaignSeoUrlTypeEnum'
import {FileManagerModal} from '../../service/FileManager/FileManagerModal'
import {KrcInput} from '../atoms/forms/KrcInput'
import {KrcRichTextEditorWithTable} from '../atoms/forms/KrcRichTextEditorWithTable'
import {KrcSelect} from '../atoms/forms/KrcSelect'
import {useBadgeDrawer} from '../hooks/badges/useBadgeDrawer'
import {useSeoUrl} from '../hooks/url/useSeoUrl'
import {IBadgeDrawer} from '../models/badges/BadgeModel'
import {KrcFormikField} from '../molecules/forms/KrcFormikField'

export const BadgeDrawer: FC<IBadgeDrawer> = ({
  onHide,
  onSave,
  type,
  badgeData,
  visible = false,
}) => {
  const {
    initValues,
    visibleDrawer,
    schema,
    handleOnSubmit,
    stores,
    setInitValues,
    drawerDataWithoutFormik,
    setDrawerDataWithoutFormik,
    initialValues,
    setVisibleDrawer,
    badgeAppUrlData,
    setBadgeAppUrlData,
  } = useBadgeDrawer({
    type,
    badgeData,
    onSave,
  })

  const submitRef = useRef(null)
  const {type: urlType, typeId, handleSearch, isLoading: loadingHook} = useSeoUrl()
  useEffect(() => {
    if (!visibleDrawer) {
      onClose()
    }
  }, [visibleDrawer])

  useEffect(() => {
    if (!visible) {
      setInitValues(initialValues)
      setDrawerDataWithoutFormik(undefined)
      setVisibleDrawer(true)
      setBadgeAppUrlData(undefined)
    }
  }, [visible])

  useEffect(() => {
    setBadgeAppUrlData((data) => {
      if (data) {
        return {...data, badge_url_app_type_id: typeId, badge_url_app_type: urlType}
      }
    })
  }, [urlType, typeId])

  const onClose = () => {
    onHide && onHide()
  }

  return (
    <>
      <Drawer
        title={type === DrawerEnum.CREATE ? 'Badge Oluştur' : 'Badge Güncelle'}
        placement='right'
        size={'large'}
        onClose={onClose}
        keyboard={false}
        maskClosable={false}
        visible={visible}
        extra={
          <Space>
            <button className='btn btn-secondary' onClick={onHide}>
              İptal
            </button>
            <button
              onClick={() => {
                // @ts-ignore
                submitRef.current.click()
              }}
              className='btn btn-success'
              style={{marginLeft: 10}}
            >
              Kaydet
            </button>
          </Space>
        }
      >
        <Formik
          validationSchema={schema}
          initialValues={initValues}
          onSubmit={handleOnSubmit}
          validate={setInitValues}
          enableReinitialize
        >
          {() => (
            <Form className='form row' noValidate>
              <KrcFormikField name={'name'} labelTitle={'Badge Adı'} required>
                <Field type='text' name='name' className='form-control' autoComplete='off' />
              </KrcFormikField>

              <KrcFormikField name={'panel_name'} labelTitle={'Panel Badge Adı'} required>
                <Field type='text' name='panel_name' className='form-control' autoComplete='off' />
              </KrcFormikField>

              <KrcFormikField name={'badge_type'} labelTitle={'Badge Türü'} required>
                <Field as='select' name='badge_type' className='form-select'>
                  <option value=''>Seçiniz</option>
                  <option value='1'>Fiyat Badge</option>
                  <option value='2'>Ürün Badge</option>
                  <option value='3'>Kampanya Badge</option>
                  <option value='4'>Kargo Badge</option>
                  <option value='5'>İndirim Badge</option>
                </Field>
              </KrcFormikField>

              <KrcFormikField
                name={'badge_description'}
                labelTitle={'Açıklama'}
                className={'mb-2 col-md-12'}
              >
                <KrcRichTextEditorWithTable
                  value={drawerDataWithoutFormik?.description ?? ''}
                  onChange={(value) => {
                    if (value) {
                      setDrawerDataWithoutFormik((data) => ({...data, description: value}))
                    } else {
                      setDrawerDataWithoutFormik((data) => ({...data, description: ''}))
                    }
                  }}
                  height={200}
                />
              </KrcFormikField>

              <KrcFormikField name={'location'} labelTitle={'Konum'} required>
                <Field
                  as='select'
                  name='location'
                  className='form-select'
                  aria-label='Select example'
                >
                  <option value=''>Seçiniz</option>
                  {BADGE_LOCATIONS.map((location) => (
                    <option key={location.badge_location_id} value={location.badge_location_id}>
                      {location.badge_location_name}
                    </option>
                  ))}
                </Field>
              </KrcFormikField>

              <KrcFormikField
                type={'number'}
                name={'priority'}
                labelTitle={'Öncelik'}
                required={true}
              />

              <KrcFormikField name={'badgeUrl'} labelTitle={'Badge Url - Web'}>
                <Field type='text' className='form-control' name='badgeUrl' autoComplete='off' />
              </KrcFormikField>

              <KrcFormikField
                name={'badgeStore'}
                labelTitle={'Badge store'}
                className={'mb-2 col-md-6 d-flex flex-column'}
              >
                <KrcSelect
                  mode={'multiple'}
                  value={drawerDataWithoutFormik?.badgeStore}
                  defaultValue={drawerDataWithoutFormik?.badgeStore}
                  size={'large'}
                  className={'ant-selector-border custom-ant-select-selection-placeholder'}
                  style={{borderRadius: 5}}
                  options={stores}
                  placeholder={'Badge Store'}
                  dropdownStyle={{zIndex: 9999}}
                  maxTagCount='responsive'
                  onChange={(badgeStore) => {
                    if (!Array.isArray(badgeStore)) {
                      return
                    }
                    setDrawerDataWithoutFormik((data) => ({...data, badgeStore}))
                  }}
                />
              </KrcFormikField>
              <div className={'row'}>
                <div className={'pr-4'}>
                  <KrcFormikField
                    className={'mb-3'}
                    type={'text'}
                    name={'badge_url_app'}
                    labelTitle={'Badge Url - APP'}
                  >
                    <div className={'d-flex gap-4'}>
                      <div className={'col-4'}>
                        <KrcInput
                          size={'large'}
                          className={'mb-3'}
                          type={'text'}
                          name={'badge_url_app'}
                          defaultValue={badgeAppUrlData?.badge_url_app ?? ''}
                          value={badgeAppUrlData?.badge_url_app}
                          onChange={(e) => {
                            if (e.target.value === '') {
                              setBadgeAppUrlData({
                                badge_url_app: '',
                                badge_url_app_type: '',
                                badge_url_app_type_id: 0,
                              })
                            }

                            handleSearch(e.target.value)
                            setBadgeAppUrlData((data) => {
                              if (data) {
                                return {...data, badge_url_app: e.target.value}
                              }
                            })
                          }}
                        />
                      </div>
                      <div className={'col-3'}>
                        {loadingHook ? (
                          <div className={'bg-white px-5 pt-1 pb-2 mb-3'}>
                            <Spin />
                          </div>
                        ) : (
                          <KrcInput
                            size={'large'}
                            className={'mb-3'}
                            type={'text'}
                            name={'badge_url_app_type_id'}
                            defaultValue={badgeAppUrlData?.badge_url_app_type_id ?? 0}
                            value={badgeAppUrlData?.badge_url_app_type_id}
                            onChange={(e) => {
                              setBadgeAppUrlData((data) => {
                                if (data) {
                                  return {...data, badge_url_app_type_id: Number(e.target.value)}
                                }
                              })
                            }}
                          />
                        )}
                      </div>
                      <div className={'col-5'}>
                        {loadingHook ? (
                          <div className={'bg-white px-5 py-2 mb-3'}>
                            <Spin />
                          </div>
                        ) : (
                          <KrcSelect
                            size={'large'}
                            placeholder={'Seçiniz'}
                            defaultValue={badgeAppUrlData?.badge_url_app_type ?? ''}
                            className={
                              'ant-selector-border custom-ant-select-selection-placeholder'
                            }
                            value={badgeAppUrlData?.badge_url_app_type}
                            style={{width: '100%'}}
                            options={[
                              {value: '', label: 'Seçiniz', disabled: true},
                              ...IBM_CAMPAIGN_SEO_URL_TYPES,
                            ]}
                            onChange={(value) => {
                              setBadgeAppUrlData((data) => {
                                if (data) {
                                  return {...data, badge_url_app_type: value}
                                }
                              })
                            }}
                          ></KrcSelect>
                        )}
                      </div>
                    </div>
                  </KrcFormikField>
                </div>
              </div>
              <KrcFormikField name={'amount'} labelTitle={'Tutar'}>
                <Field type='number' className='form-control' name={'amount'} autoComplete='off' />
              </KrcFormikField>

              <KrcFormikField name={'priceOperation'} labelTitle={'Tutar İşlem'}>
                <Field
                  as='select'
                  name='priceOperation'
                  className='form-select'
                  aria-label='Select example'
                >
                  <option value=''>Seçiniz</option>
                  <option value='1'>Fiyat</option>
                  <option value='2'>Yüzde</option>
                </Field>
              </KrcFormikField>

              <KrcFormikField type={'number'} name={'fontSize'} labelTitle={'Yazı Boyutu'} />

              <KrcFormikField
                type={'color'}
                className={'mb-3 mt-3 col-md-3'}
                name={'fontColor'}
                labelTitle={'Yazı Renk'}
              />

              <KrcFormikField
                type={'color'}
                className={'mb-3 mt-3 col-md-3'}
                name={'fontBackgroundColor'}
                labelTitle={'Arkaplan Renk'}
              />

              <KrcFormikField name={'status'} labelTitle={'Durum'} required>
                <Field
                  as='select'
                  name='status'
                  className='form-select'
                  aria-label='Select example'
                >
                  <option value=''>Seçiniz</option>
                  <option value='1'>Aktif</option>
                  <option value='2'>Pasif</option>
                </Field>
              </KrcFormikField>
              <button type='submit' ref={submitRef} className='d-none' style={{marginLeft: 10}} />
              <KrcFormikField name={'image'} labelTitle={'Görsel'}>
                <div className={'d-flex flex-row justify-content-center gap-6 align-items-center'}>
                  <div className={'d-flex justify-content-center mb-2 me-2'}>
                    {!initValues?.full_image || type === DrawerEnum.CREATE ? (
                      <div className={'d-flex flex-column align-items-center'}>
                        <Avatar
                          size={50}
                          icon={<PictureOutlined />}
                          style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        />
                      </div>
                    ) : (
                      initValues.full_image && (
                        <Image
                          src={initValues.full_image}
                          className={'rounded-circle'}
                          preview={{
                            maskClassName: 'rounded-circle',
                          }}
                          width={50}
                          height={50}
                        />
                      )
                    )}
                  </div>
                  {visible && (
                    <FileManagerModal
                      onSelected={(link) => {
                        setDrawerDataWithoutFormik((data) => ({...data, image: link}))
                      }}
                      defaultImage={initValues?.full_image ?? ''}
                      tempUploadDir={'/image/2024/badge'}
                    />
                  )}
                </div>
              </KrcFormikField>
              <KrcFormikField name={'plp_status'} labelTitle={'PLP Durumu'} required>
                <Field
                  as='select'
                  name='plp_status'
                  className='form-select'
                  aria-label='Select example'
                >
                  <option value=''>Seçiniz</option>
                  <option value={BADGE_STATUS_TYPES.SHOW_VALUE}>
                    {BADGE_STATUS_TYPES.SHOW_LABEL}
                  </option>
                  <option value={BADGE_STATUS_TYPES.HIDE_VALUE}>
                    {BADGE_STATUS_TYPES.HIDE_LABEL}
                  </option>
                </Field>
              </KrcFormikField>
              <KrcFormikField name={'pdp_status'} labelTitle={'PDP Durumu'} required>
                <Field
                  as='select'
                  name='pdp_status'
                  className='form-select'
                  aria-label='Select example'
                >
                  <option value=''>Seçiniz</option>
                  <option value={BADGE_STATUS_TYPES.SHOW_VALUE}>
                    {BADGE_STATUS_TYPES.SHOW_LABEL}
                  </option>
                  <option value={BADGE_STATUS_TYPES.HIDE_VALUE}>
                    {BADGE_STATUS_TYPES.HIDE_LABEL}
                  </option>
                </Field>
              </KrcFormikField>
            </Form>
          )}
        </Formik>
        <Divider />
        <div>
          <div>Notlar:</div>
          <div>1-) Kırmızı alanlar zorunludur.</div>
          <div>
            2-) Girilen tutarda indirim uygulanması için sadece{' '}
            <span className={'fw-bold text-danger'}>Badge Türü : İndirim</span> Badge olan
            badgelerde uygulanır diğer badgelerde seçilirse indirim uygulanmaz.
          </div>
          <div>
            3-) Yazı Boyutu, Yazı Renk, Arka Plan Renk zorunlu alan değildir boş bırakılırsa default
            olarak Yazı Boyutu : 13, Yazı Renk : #fff, Arka Plan Renk : #000
          </div>
        </div>
      </Drawer>
    </>
  )
}
