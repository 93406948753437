import {FC, useContext} from 'react'

import {CategorySelectionEnum} from '../../../../enums/CategorySelectionEnum'
import {CategorySelectionContext} from '../../../../providers/tables/CategorySelectionProvider'
import {ICategorySelectionFilter} from '../../../models/categories/CategorySelectionModel'
import {CategoryTextAreaFilter} from '../../../molecules/filters/CategoryTextAreaFilter'

export const CategorySelectionFilter: FC<ICategorySelectionFilter> = ({
  onFilter,
  textAreaProps,
}) => {
  const {type} = useContext(CategorySelectionContext)

  return (
    <>
      {type === CategorySelectionEnum.TEXT ? (
        <CategoryTextAreaFilter onFilter={onFilter} textAreaProps={textAreaProps} />
      ) : (
        <></>
      )}
    </>
  )
}
