import {Select, SelectProps} from 'antd'
import cn from 'classnames'
import {OptionData} from 'rc-select/lib/interface'
import React, {FC, useEffect, useRef, useState} from 'react'

import {useKrcProp} from '../../hooks/components/useKrcProp'
import {ISelectFilter} from '../../models/components/KrcSelectModel'
import {InfoTooltip} from '../InfoTooltip'

const selectConfig = {
  default: {
    defaultValue: undefined,
    options: [],
    labelInValue: true,
  } as SelectProps,
}

const tooltipPropsConfig = {
  status: false,
}

export const KrcSelect: FC<ISelectFilter & SelectProps> = ({
  children,
  config = 'default',
  required = false,
  labelTitle,
  tooltipProps,
  clear,
  ...props
}) => {
  const currentProps = useKrcProp({config: selectConfig[config], props}).getCurrentProps()

  const [data, setData] = useState<
    string | undefined | null | string[] | number[] | OptionData | OptionData[]
  >(currentProps.defaultValue)
  const initialRef = useRef(0)
  const currentTooltipProps = useKrcProp({
    config: tooltipPropsConfig,
    props: tooltipProps,
  }).getCurrentProps()

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    setData(undefined)
  }, [clear])

  useEffect(() => {
    setData(currentProps.defaultValue)
  }, [currentProps.defaultValue])

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current += 1

      return
    }
  }, [])

  return (
    <>
      {labelTitle && (
        <label className='form-label'>
          <div className={'d-flex align-items-center'}>
            <span className={cn({required: required})}>{labelTitle}</span>
            <InfoTooltip status={currentTooltipProps.status} {...currentTooltipProps}></InfoTooltip>
          </div>
        </label>
      )}
      <Select
        value={data}
        allowClear
        {...currentProps}
        onChange={(item) => {
          if (currentProps.onChange) {
            if (props.labelInValue) {
              currentProps.onChange(item)
            } else {
              if (Array.isArray(item)) {
                currentProps.onChange(item.map((item) => item.value))
              } else {
                currentProps.onChange(item.value)
              }
            }
          }

          setData(item)
        }}
      >
        {children}
      </Select>
    </>
  )
}
