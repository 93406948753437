import axios from 'axios'
import * as querystring from 'query-string'

import {badgeFilterType} from '../../components/models/badges/BadgeModel'

const API_URL = process.env.REACT_APP_API_URL || ''
export const BADGE_URL = `${API_URL}/badges`

export const exportBadgeRequest = (params: object) => {
  return axios.get(`${BADGE_URL}/export`, {
    params: params,
  })
}

export const checkBadgeExportRequest = ($fileName: string) => {
  return axios.get(`${BADGE_URL}/check-export`, {
    params: {
      file_name: $fileName,
    },
  })
}

type getBadgeExportFrontendUrlType = {
  filters?: badgeFilterType
  badgeIds: number[]
}

export const getBadgeExportFrontendUrl = ({filters, badgeIds}: getBadgeExportFrontendUrlType) => {
  return `/badges/export?${querystring.stringify(
    {
      ...filters,
      badge_ids: badgeIds || [],
    },
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}

export const exportBadgeTiedRequest = (params: object) => {
  return axios.get(`${BADGE_URL}/tied/export`, {
    params: params,
  })
}

export const checkBadgeTiedExportRequest = ($fileName: string) => {
  return axios.get(`${BADGE_URL}/tied/check-export`, {
    params: {
      file_name: $fileName,
    },
  })
}

export const getBadgeTiedExportFrontendUrl = (filters: any[], badgeId: number) => {
  return `/badges/tied/export?${querystring.stringify(
    {...filters, badge_id: badgeId},
    {
      arrayFormat: 'bracket',
      skipNull: true,
    }
  )}`
}
