import {PictureOutlined} from '@ant-design/icons'
import {Avatar, Drawer, Image, Select, SelectProps, Space, message} from 'antd'
import {Field, Form, Formik, FormikValues} from 'formik'
import {isEmpty} from 'lodash'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'

import {
  CAMPAIGN_COMPANIES,
  CAMPAIGN_TAGS,
  CAMPAIGN_TYPES,
} from '../../../enums/IBMCampaignUpdateTypeEnum'
import {IBMCampaignContext} from '../../../providers/ibm/IBMCampaignProvider'
import {fetchIBMGroupsRequest} from '../../../requests/ibm/IBMGroupRequest'
import {requestDateFormat} from '../../../service/DateService'
import {FileManagerModal} from '../../../service/FileManager/FileManagerModal'
import {KrcButton} from '../../atoms/buttons/KrcButton'
import {KrcDatePicker} from '../../atoms/forms/KrcDatePicker'
import {KrcRichTextEditorWithTable} from '../../atoms/forms/KrcRichTextEditorWithTable'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {IBmCampaignUrlType} from '../../models/ibm/IBMCampaignModel'
import {BadgeFilter} from '../../molecules/filters/BadgeFilter'
import {KrcFormikField} from '../../molecules/forms/KrcFormikField'

interface IIBMCampaignCreateDrawerProps {
  visible: boolean
  onHide: () => any
}

interface SelectType {
  value: number
  label: string
}

const formikInitialValues = {
  campaign_name: '',
  badge_id: null,
  campaign_cart_title: '',
  campaign_company: null,
  campaign_description: '',
  campaign_detail_page_image: '',
  campaign_group_id: null,
  campaign_margin: null,
  campaign_page_status: 0,
  campaign_pdp_status: 0,
  campaign_pdp_title: '',
  campaign_price: null,
  campaign_sort: null,
  campaign_status: 0,
  campaign_tag: null,
  date_start: '',
  date_end: '',
  campaign_title: '',
  campaign_type: null,
  campaigns_page_image: '',
  campaigns_page_title: '',
  url_alias_campaign_seo_url: '',
  campaign_keywords: [],
  campaign_conditions: '',
}

export const IBMCampaignCreateDrawer: FC<IIBMCampaignCreateDrawerProps> = ({visible, onHide}) => {
  const {storeIBMCampaigns} = useContext(IBMCampaignContext)
  const {groupId} = useParams<IBmCampaignUrlType>()
  const DEFAULT_IMAGE_PATH = 'https://cdn.karaca.com/image/'
  const [campaignDescription, setCampaignDescription] = useState('')
  const [campaignBadge, setCampaignBadge] = useState<SelectType | null>(null)
  const [startDate, setStartDate] = useState<string | null>('')
  const [endDate, setEndDate] = useState<string | null>('')
  const [campaignGroupId, setCampaignGroupId] = useState<number>(groupId ? Number(groupId) : 0)
  const [campaignTag, setCampaignTag] = useState<SelectType | null>(null)
  const [campaignCompany, setCampaignCompany] = useState<SelectType | null>(null)
  const [campaignType, setCampaignType] = useState<SelectType | null>(null)
  const [campaignPageImage, setCampaignPageImage] = useState<string>('')
  const [campaignDetailImage, setCampaignDetailImage] = useState<string>('')
  const [campaignKeywords, setCampaignKeywords] = useState<SelectProps['options']>([])
  const [campaignConditions, setCampaignConditions] = useState<string>('')

  const submitRef = useRef(null)

  const [IBMGroupOptions, setIBMGroupOptions] = useState<any[]>([])
  const initialRef = useRef(0)

  useEffect(() => {
    if (initialRef.current === 0) {
      fetchIBMGroupsRequest({}, {page: 1, limit: 10}, 1).then((res) => {
        const _data = res.data.data
        const _options = _data.map((item: any) => {
          return {value: item.ibm_group_id, label: item.ibm_group_title}
        })
        setIBMGroupOptions(_options)
      })
    }
  }, [])

  const handleChangeKeyword = (value: any) => {
    setCampaignKeywords(
      value.map((item: string, index: number) => {
        return {
          value: index,
          label: item,
        }
      })
    )
  }

  const handleOnSubmit = (values: FormikValues) => {
    if (campaignBadge) {
      if (isEmpty(campaignBadge)) {
        values.badge_id = formikInitialValues.badge_id
      } else {
        values.badge_id = campaignBadge?.value
      }
    } else {
      values.badge_id = formikInitialValues.badge_id
    }

    if (campaignTag !== formikInitialValues.campaign_tag) {
      values.campaign_tag = campaignTag
    } else {
      values.campaign_tag = formikInitialValues.campaign_tag
    }

    if (campaignCompany !== formikInitialValues.campaign_company) {
      values.campaign_company = campaignCompany
    } else {
      values.campaign_company = formikInitialValues.campaign_company
    }

    if (campaignType !== formikInitialValues.campaign_type) {
      values.campaign_type = campaignType
    } else {
      values.campaign_type = formikInitialValues.campaign_type
    }

    if (startDate !== formikInitialValues.date_start) {
      values.date_start = startDate
    } else {
      values.date_start = formikInitialValues.date_start
    }

    if (endDate !== formikInitialValues.date_end) {
      values.date_end = endDate
    } else {
      values.date_end = formikInitialValues.date_end
    }

    if (campaignGroupId !== formikInitialValues.campaign_group_id) {
      values.campaign_group_id = campaignGroupId
    } else {
      values.campaign_group_id = formikInitialValues.campaign_group_id
    }

    if (campaignPageImage !== formikInitialValues.campaigns_page_image) {
      values.campaigns_page_image = campaignPageImage
    } else {
      values.campaigns_page_image = formikInitialValues.campaigns_page_image
    }

    if (campaignDetailImage !== formikInitialValues.campaign_detail_page_image) {
      values.campaign_detail_page_image = campaignDetailImage
    } else {
      values.campaign_detail_page_image = formikInitialValues.campaign_detail_page_image
    }

    if (campaignKeywords !== formikInitialValues.campaign_keywords) {
      values.campaign_keywords = campaignKeywords
    } else {
      values.campaign_keywords = formikInitialValues.campaign_keywords
    }

    if (!values.campaign_group_id || values.campaign_group_id.length === 0) {
      message.error('Kampanya Grubu Seçmelisiniz.')
      return
    }

    if (!values.campaign_name) {
      message.error('Kampanya Adı boş bırakılamaz.')
      return
    }

    if (!values.url_alias_campaign_seo_url) {
      message.error('Yönlendirilecek URL boş bırakılamaz.')
      return
    }

    if (!campaignConditions) {
      message.error('Kampanya Koşulları boş bırakılamaz.')
      return
    }

    if (!values.campaign_keywords || values.campaign_keywords.length === 0) {
      message.error('Anahtar Kelimeler boş bırakılamaz.')
      return
    }

    if (!values.campaigns_page_title) {
      message.error('Kampanyalar Sayfası Başlığı boş bırakılamaz.')
      return
    }

    if (!values.date_start || !values.date_end) {
      message.error('Başlangıç ve Bitiş Tarihi seçmek zorunludur.')
      return
    }

    const requestBody = {
      ...values,
      campaign_description: campaignDescription,
      campaign_conditions: campaignConditions,
    }

    storeIBMCampaigns(requestBody)
  }

  return (
    <>
      <Drawer
        title={'IBM Kampanya Oluştur'}
        placement='right'
        size={'large'}
        onClose={onHide}
        visible={visible}
        extra={
          <Space>
            <button className='btn btn-secondary' onClick={onHide}>
              İptal
            </button>
            <button
              onClick={() => {
                // @ts-ignore
                submitRef.current.click()
              }}
              className='btn btn-success'
              style={{marginLeft: 10}}
            >
              Kaydet
            </button>
          </Space>
        }
      >
        <Formik initialValues={formikInitialValues} onSubmit={handleOnSubmit}>
          <Form className={'form'}>
            <div className={'row'}>
              <div className={'col-6'}>
                <KrcFormikField
                  type={'text'}
                  className={'w-100'}
                  name={'campaign_name'}
                  readonly={false}
                  required
                  labelTitle={'Kampanya Adı'}
                />
              </div>
              <div className={'col-6'}>
                <KrcFormikField
                  className={'mb-3'}
                  type={'text'}
                  name={'campaign_title'}
                  labelTitle={'Kampanya Başlığı'}
                />
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-6'}>
                <KrcFormikField
                  className={'mb-3'}
                  type={'text'}
                  name={'url_alias_campaign_seo_url'}
                  required
                  labelTitle={'Yönlendirilecek URL'}
                />
              </div>
              <div className={'col-6'}>
                <KrcFormikField
                  className={'mb-2'}
                  name={'campaign_status'}
                  labelTitle={'Aktiflik Durumu'}
                >
                  <Field as='select' name='campaign_status' className='form-select'>
                    <option value={undefined} disabled={true}>
                      Seçiniz
                    </option>
                    <option value={1}>Aktif</option>
                    <option value={0}>Pasif</option>
                  </Field>
                </KrcFormikField>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-4'}>
                <KrcFormikField
                  className={'mb-2'}
                  type={'number'}
                  name={'campaign_sort'}
                  labelTitle={'Kampanya Sıralama'}
                />
              </div>
              <div className={'col-4'}>
                <KrcFormikField
                  className={'mb-2'}
                  type={'number'}
                  name={'campaign_price'}
                  labelTitle={'Kampanya Fiyatı'}
                />
              </div>
              <div className={'col-4'}>
                <KrcFormikField
                  className={'mb-2'}
                  type={'number'}
                  name={'campaign_margin'}
                  labelTitle={'Belirlenen Marj'}
                />
              </div>
            </div>
            <div className={'col-12'}>
              <div className={'form-group'}>
                <label className={'mb-1'}>Kampanya Açıklaması</label>
                <textarea
                  className={'form-control'}
                  rows={3}
                  onChange={(e) => setCampaignDescription(e.target.value)}
                  name={'campaign_description'}
                  defaultValue={campaignDescription}
                  value={campaignDescription}
                />
              </div>
            </div>
            <div className={'col-12 mt-1'}>
              <div className={'form-group'}>
                <label className={'mb-1'}>
                  Kampanya Koşulları<span className={'text-danger'}>*</span>
                </label>
                <KrcRichTextEditorWithTable
                  className={'mt-2 pb-4'}
                  value={campaignConditions}
                  onChange={(value) => {
                    if (value) {
                      setCampaignConditions(value)
                    } else {
                      setCampaignConditions('')
                    }
                  }}
                />
              </div>
            </div>
            <div className={'row mt-3'}>
              <div className={'col-6'}>
                <KrcFormikField name={'badge_id'} required={false} labelTitle={'Badge'}>
                  <BadgeFilter
                    mode={undefined}
                    showSearch={true}
                    size={'large'}
                    style={{width: '200%'}}
                    allowClear={true}
                    onClear={() => {
                      setCampaignBadge(null)
                    }}
                    value={campaignBadge}
                    defaultValue={campaignBadge}
                    className={'select-filter-custom'}
                    dataType={'item'}
                    onSelected={(data) => {
                      //@ts-ignore
                      setCampaignBadge(data)
                    }}
                    dropdownStyle={{zIndex: 99999}}
                    placeholder={'Badge Ara'}
                  />
                </KrcFormikField>
              </div>
              <div className={'col-6'}>
                <KrcFormikField
                  className={'w-100'}
                  type={'text'}
                  name={'campaign_pdp_title'}
                  labelTitle={'PDP Başlığı'}
                />
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-4'}>
                <KrcFormikField
                  className={'w-100'}
                  name={'campaign_page_status'}
                  labelTitle={'Sayfa Görüntülenme Durumu'}
                >
                  <Field as='select' name='campaign_page_status' className='form-select'>
                    <option value={undefined} disabled={true}>
                      Seçiniz
                    </option>
                    <option value={1}>Görünür</option>
                    <option value={0}>Görünmez</option>
                  </Field>
                </KrcFormikField>
              </div>
              <div className={'col-4'}>
                <KrcFormikField
                  className={'mb-2'}
                  name={'campaign_pdp_status'}
                  labelTitle={'PDP Durumu'}
                >
                  <Field as='select' name='campaign_pdp_status' className='form-select'>
                    <option value={undefined} disabled={true}>
                      Seçiniz
                    </option>
                    <option value={1}>Aktif</option>
                    <option value={0}>Pasif</option>
                  </Field>
                </KrcFormikField>
              </div>
              <div className={'col-4'}>
                <KrcFormikField
                  className={'mb-2'}
                  type={'text'}
                  name={'campaign_keywords'}
                  required
                  labelTitle={'Anahtar Kelimeler'}
                >
                  <Select
                    size={'large'}
                    mode='tags'
                    style={{width: '100%'}}
                    placeholder='Etiket Giriniz'
                    onChange={handleChangeKeyword}
                    value={campaignKeywords?.map((item) => {
                      return {
                        value: item.label,
                        label: item.label,
                      }
                    })}
                    options={campaignKeywords?.map((item) => {
                      return {
                        value: item.label,
                        label: item.label,
                      }
                    })}
                  />
                </KrcFormikField>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col'}>
                <KrcFormikField
                  className={'mb-2'}
                  type={'text'}
                  name={'campaign_cart_title'}
                  labelTitle={'Kampanya Sepet Başlığı'}
                />
              </div>
              <div className={'col'}>
                <KrcFormikField
                  className={'mb-2'}
                  type={'text'}
                  name={'campaigns_page_title'}
                  required
                  labelTitle={'Kampanyalar Sayfası Başlığı'}
                />
              </div>
            </div>
            <div className={'row'}>
              <div className={'col'}>
                <KrcFormikField name={'date'} required labelTitle={'Başlangıç Tarihi'}>
                  <KrcDatePicker
                    showTime
                    size={'large'}
                    style={{width: '200%'}}
                    onChanged={(date) => {
                      if (!date.date_formatted) {
                        setStartDate(null)
                      }
                    }}
                    onSelect={(date) => {
                      const date_start = date ?? undefined
                      setStartDate(requestDateFormat(date_start))
                    }}
                  ></KrcDatePicker>
                </KrcFormikField>
              </div>
              <div className={'col'}>
                <KrcFormikField name={'date'} required labelTitle={'Bitiş Tarihi'}>
                  <KrcDatePicker
                    size={'large'}
                    showTime
                    style={{width: '200%'}}
                    onChanged={(date) => {
                      if (!date.date_formatted) {
                        setEndDate(null)
                      }
                    }}
                    onSelect={(date) => {
                      const date_end = date ?? undefined
                      setEndDate(requestDateFormat(date_end))
                    }}
                  ></KrcDatePicker>
                </KrcFormikField>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-6'}>
                <KrcFormikField
                  className={'mb-2'}
                  type={'number'}
                  name={'campaign_group_id'}
                  labelTitle={'Kampanya Grubu'}
                >
                  <KrcSelect
                    mode={undefined}
                    placeholder={'Kampanya Grubu Seçiniz'}
                    size={'large'}
                    options={IBMGroupOptions}
                    maxTagCount={'responsive'}
                    style={{width: '100%'}}
                    className={'ant-selector-border'}
                    showArrow={true}
                    defaultValue={campaignGroupId}
                    onChange={(data) => {
                      setCampaignGroupId(data)
                    }}
                  />
                </KrcFormikField>
              </div>
              <div className={'col-6'}>
                <KrcFormikField
                  className={'mb-2'}
                  type={'text'}
                  name={'campaign_tag'}
                  labelTitle={'Kampanya Tag '}
                >
                  <KrcSelect
                    placeholder={'Kampanya Tag'}
                    size={'large'}
                    options={CAMPAIGN_TAGS}
                    maxTagCount={'responsive'}
                    style={{width: '100%'}}
                    className={'ant-selector-border'}
                    showArrow={true}
                    defaultValue={campaignTag?.value}
                    value={campaignTag}
                    onChange={(data) => {
                      setCampaignTag(data)
                    }}
                  />
                </KrcFormikField>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-6'}>
                <KrcFormikField
                  type={'text'}
                  name={'campaign_company'}
                  labelTitle={'Kampanya Şirketi'}
                >
                  <KrcSelect
                    placeholder={'Kampanya Şirketi'}
                    size={'large'}
                    options={CAMPAIGN_COMPANIES}
                    maxTagCount={'responsive'}
                    style={{width: '200%'}}
                    className={'ant-selector-border'}
                    showArrow={true}
                    defaultValue={campaignCompany?.value}
                    value={campaignCompany}
                    onChange={(data) => {
                      setCampaignCompany(data)
                    }}
                  />
                </KrcFormikField>
              </div>
              <div className={'col-6'}>
                <KrcFormikField type={'number'} name={'campaign_type'} labelTitle={'Kampanya Türü'}>
                  <KrcSelect
                    placeholder={'Tür Filtresi'}
                    size={'large'}
                    options={CAMPAIGN_TYPES}
                    maxTagCount={'responsive'}
                    style={{width: '200%'}}
                    className={'ant-selector-border'}
                    showArrow={true}
                    defaultValue={campaignType?.value}
                    value={campaignType}
                    onChange={(data) => {
                      setCampaignType(data)
                    }}
                  />
                </KrcFormikField>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-6'}>
                <div className={'flex justify-content-center align-content-center center'}>
                  <p>Kampanya Sayfa Görseli</p>
                </div>
                <div className={'d-flex flex-row justify-content-around align-items-center'}>
                  {campaignPageImage ? (
                    <div>
                      <Image
                        preview={{
                          maskClassName: 'rounded-circle',
                        }}
                        width={50}
                        height={50}
                        className={'rounded-circle'}
                        src={DEFAULT_IMAGE_PATH + campaignPageImage}
                      />
                    </div>
                  ) : (
                    <div>
                      <Avatar
                        size={50}
                        icon={<PictureOutlined />}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    </div>
                  )}
                  <div className={'mt-2'}>
                    <FileManagerModal
                      onSelected={(link) => {
                        setCampaignPageImage(link ?? '')
                      }}
                      defaultImage={
                        campaignPageImage ? DEFAULT_IMAGE_PATH + campaignPageImage : undefined
                      }
                      tempUploadDir={'/image/2024/ibm/page_image'}
                    />
                  </div>
                </div>
                <div>
                  <KrcButton
                    type={'default'}
                    className={'border-2 danger mt-2 w-100'}
                    style={{borderRadius: 5}}
                    onClick={() => {
                      message.success('Kampanya Detay Görseli Kaldırıldı.')
                      setCampaignPageImage('')
                    }}
                  >
                    Görseli Kaldır
                  </KrcButton>
                </div>
              </div>
              <div className={'col-6'}>
                <div className={'flex justify-content-center align-content-center center'}>
                  <p>Kampanya Detay Görseli</p>
                </div>
                <div className={'d-flex flex-row justify-content-around align-items-center'}>
                  {campaignDetailImage ? (
                    <div>
                      <Image
                        preview={{
                          maskClassName: 'rounded-circle',
                        }}
                        width={50}
                        height={50}
                        className={'rounded-circle'}
                        src={DEFAULT_IMAGE_PATH + campaignDetailImage}
                      />
                    </div>
                  ) : (
                    <div>
                      <Avatar
                        size={50}
                        icon={<PictureOutlined />}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    </div>
                  )}
                  <div className={'mt-2'}>
                    <FileManagerModal
                      onSelected={(link) => {
                        setCampaignDetailImage(link ?? '')
                      }}
                      defaultImage={
                        campaignDetailImage ? DEFAULT_IMAGE_PATH + campaignDetailImage : undefined
                      }
                      tempUploadDir={'/image/2024/ibm/detail_image'}
                    />
                  </div>
                </div>
                <div>
                  <KrcButton
                    type={'default'}
                    className={'border-2 danger mt-2 w-100'}
                    style={{borderRadius: 5}}
                    onClick={() => {
                      message.success('Kampanya Detay Görseli Kaldırıldı.')
                      setCampaignDetailImage('')
                    }}
                  >
                    Görseli Kaldır
                  </KrcButton>
                </div>
              </div>
            </div>
            <button type='submit' ref={submitRef} className='d-none' style={{marginLeft: 10}} />
          </Form>
        </Formik>
      </Drawer>
    </>
  )
}
