import {Route, Routes} from 'react-router-dom'

import {RoleListWrapper} from '../components/pages/roles/RoleListWrapper'

export const RoleRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<RoleListWrapper />} />
      </Routes>
    </>
  )
}
