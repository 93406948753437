import {message} from 'antd'
import {isEmptyArray} from 'formik'
import React, {FC, createContext, useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router'
import {useNavigate, useParams} from 'react-router-dom'
import {generateUniqueID} from 'web-vitals/dist/modules/lib/generateUniqueID'

import {
  categoryTypeOptions,
  oldWithCategoryTypeOptions,
} from '../../../components/hooks/categories/useCategoryTypeFilter'
import {useDeepCompareEffect} from '../../../components/hooks/useDeepCompareEffect'
import {
  categoryDescType,
  categoryDetailType,
  createCategoryType,
} from '../../../components/models/categories/CategoryDetailModel'
import {
  CategoryGhostFilterType,
  ghostPageType,
} from '../../../components/models/categories/CategoryGhostModel'
import {CategoryTypeEnum} from '../../../components/models/categories/CategoryModel'
import {categoryUrlType} from '../../../components/models/categories/CategoryProductModel'
import {krcSelectOptions} from '../../../components/models/components/KrcSelectModel'
import {storeDataType} from '../../../components/models/stores/StoreModel'
import {errorMessage} from '../../../helpers/ErrorHelper'
import {
  createCategoryRequest,
  getCategoryRequest,
  updateCategoryRequest,
} from '../../../requests/categories/CategoryRequest'

interface ICategoryDetailContext {
  desc: categoryDescType
  information: categoryDetailType
  filters: CategoryGhostFilterType[][]
  filterUniqueIds: string[]
  setIsInformationValid: React.Dispatch<React.SetStateAction<boolean>>
  setIsDescValid: React.Dispatch<React.SetStateAction<boolean>>
  updateFilters: (deep: number, filters: CategoryGhostFilterType[]) => void
  updateInformation: (key: string, value: any) => void
  handleInformationOnSubmit: (data: any) => void
  onChangeInformationValidate: (data: any) => void
  handleDescOnSubmit: (data: any) => void
  informationIsChanged: boolean
  descIsChanged: boolean
  onChangeDescValidate: (data: categoryDescType) => void
  updateDesc: (key: string, value: any) => void
  updateCategoryType: (value: krcSelectOptions) => void
  categoryType: krcSelectOptions
}

export const CategoryDetailContext = createContext<ICategoryDetailContext>({
  filters: [],
  filterUniqueIds: [],
  information: {
    name: '',
    short_name: '',
    parent_category: null,
    status: '0',
    icon: null,
    url_alias: '',
    top: false,
    stores: [],
    category_type: null,
    add_to_cart_store: '',
    image: null,
    mobile_image: null,
    is_ibm_campaign: false,
  },
  desc: {
    meta_title: '',
    meta_description: '',
    meta_keyword: '',
    description: '',
    icon: null,
    is_ibm_campaign: false,
  },
  informationIsChanged: false,
  descIsChanged: false,
  categoryType: {value: '*', label: '*'},
  setIsInformationValid: () => {},
  setIsDescValid: () => {},
  updateFilters: () => {},
  updateInformation: () => {},
  handleInformationOnSubmit: () => {},
  onChangeInformationValidate: () => {},
  handleDescOnSubmit: () => {},
  onChangeDescValidate: () => {},
  updateDesc: () => {},
  updateCategoryType: () => {},
})

export const CategoryDetailProvider: FC = ({children}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [informationRetryCopy, setInformationRetryCopy] = useState(0)
  const [isInformationValid, setIsInformationValid] = useState(false)
  const [isDescValid, setIsDescValid] = useState(false)
  const [filters, setFilters] = useState<CategoryGhostFilterType[][]>([[]])
  const [filterUniqueIds] = useState<string[]>([generateUniqueID()])
  const {categoryId} = useParams<categoryUrlType>()
  const fetchRef = useRef(0)
  const [descRetryCopy, setDescRetryCopy] = useState(0)
  const [information, setInformation] = useState<categoryDetailType>({
    name: '',
    short_name: '',
    parent_category: null,
    status: '0',
    icon: null,
    url_alias: '',
    top: false,
    stores: [],
    category_type: null,
    add_to_cart_store: '',
    image: null,
    mobile_image: null,
    is_ibm_campaign: false,
  })
  const [desc, setDesc] = useState<categoryDescType>({
    meta_title: '',
    meta_description: '',
    meta_keyword: '',
    description: '',
    icon: null,
    is_ibm_campaign: false,
  })
  const {isChanged: informationIsChanged} = useDeepCompareEffect(
    {information, filters},
    informationRetryCopy
  )
  const {isChanged: descIsChanged} = useDeepCompareEffect({desc}, descRetryCopy)
  const [categoryType, setCategoryType] = useState<krcSelectOptions>({value: '*', label: '*'})

  const updateFilters = (deep: number, currentFilters: CategoryGhostFilterType[]) => {
    setFilters((filters) => {
      let _filters = [...filters]
      _filters[deep] = currentFilters

      return _filters
    })
  }

  const updateCategoryType = (categoryType: krcSelectOptions) => {
    setCategoryType(categoryType)
  }

  const updateInformation = (key: string, value: any) => {
    setInformation((details) => ({...details, [key]: value}))
  }

  const updateCategory = (data: any, type: ghostPageType) => {
    const key = 'update-category'

    message.loading({content: 'Güncelleniyor...', key})
    updateCategoryRequest(Number(categoryId), {...data})
      .then((res) => {
        if (type === 'description') {
          setDescRetryCopy((item) => item + 1)
        } else if (type === 'information') {
          setInformationRetryCopy((item) => item + 1)
        }

        message.success({content: 'Başarıyla Güncellendi.', key})
        getCategory(categoryId)
      })
      .catch((err) => {
        errorMessage(err, true)
        message.destroy(key)
      })
  }

  const handleInformationOnSubmit = (data: categoryDetailType) => {
    if (!isInformationValid) {
      return
    }

    const {
      name,
      short_name,
      category_type,
      add_to_cart_store,
      parent_category,
      stores,
      top,
      status,
      icon,
      url_alias,
      image,
      mobile_image,
    } = data

    let _data = {
      description: {
        name,
        short_name,
      },
      category: {
        top,
        icon,
        status,
        parent_id: parent_category?.value ?? null,
        category_type: category_type?.value,
        add_to_basket_mobile: add_to_cart_store,
        image,
        mobile_image,
      },
      store_ids: stores?.map((item) => item.value),
      url_alias,
      tab_type: 'information',
    } as createCategoryType

    if (_data.category.category_type === CategoryTypeEnum.GHOST) {
      if (!isEmptyArray(filters?.filter((filter) => !isEmptyArray(filter)))) {
        _data['filters'] = filters

        const isFilters = _data['filters']?.some((filter) => {
          return filter[0].key === 'categories'
        })

        if (isFilters) {
          _data['filters'][0] = [
            ..._data['filters'][0],
            ...[{key: 'category_type', value: categoryType}],
          ]
        }
      }
    }

    if (categoryId) {
      updateCategory(_data, 'information')
    } else {
      const key = 'process-category'

      message.loading({content: 'Kategori olusturuluyor...', key})

      createCategoryRequest(_data)
        .then((res) => {
          message.success({content: 'Kategori olusturuldu.', key})
          const categoryId = res.data.data.category_id
          navigate(`/categories/details/${categoryId}`, {replace: true})
          getCategory(categoryId, true)
        })
        .catch((e) => {
          errorMessage(e)
          message.destroy(key)
        })
    }
  }

  const formatFilters = (filters: any) => {
    let formattedFilters = []

    formattedFilters = Object.entries(filters)
      .filter(([key, value]) => key !== 'attributes')
      .map(([key, value]) => {
        return {
          key,
          value,
        }
      })
    if (filters.attributes) {
      const attributes = Object.entries(filters.attributes).map(([key, value]) => {
        const attributeValue = value as {value: any; label: any[]}

        return {
          key: `attribute_${attributeValue.value}`,
          value: Array.isArray(attributeValue.label)
            ? attributeValue.label.map((attribute: string | number) => {
                return {
                  value: `attribute_${attribute}_${attributeValue.value}`,
                  label: attribute,
                }
              })
            : {
                value: `attribute_${attributeValue.label}_${attributeValue.value}`,
                label: attributeValue.label,
              },
        }
      })
      formattedFilters = formattedFilters.concat(attributes)
    }

    return formattedFilters
  }

  const getCategory = (categoryId?: string, initial?: boolean) => {
    if (!categoryId) {
      return
    }

    fetchRef.current += 1
    const fetchId = fetchRef.current

    getCategoryRequest(Number(categoryId), [
      'description',
      'parentCategory.minimalDescription',
      'stores',
    ]).then((res) => {
      if (fetchId !== fetchRef.current || !res.data.data) {
        return
      }

      const {
        filters: ghostFilters,
        add_to_basket_mobile,
        category_type,
        parent_category,
        url_alias,
        icon,
        status,
        details,
        stores,
        top,
        image,
        mobile_image,
        is_ibm_campaign,
      } = res.data.data

      const categoryType =
        oldWithCategoryTypeOptions.find((item) => item.value === category_type) ??
        categoryTypeOptions[0]

      if (location.hash === '' || location.hash.slice(1) === 'information' || initial) {
        setInformation((information) => {
          let data = {
            ...information,
            icon,
            name: details.name,
            short_name: details.short_name ?? '',
            status: status ? '1' : '0',
            url_alias: url_alias.keyword,
            category_type: categoryType,
            top,
            add_to_cart_store: add_to_basket_mobile ? '1' : '0',
            image: image,
            mobile_image: mobile_image,
            is_ibm_campaign: is_ibm_campaign,
          }

          if (parent_category) {
            data = {
              ...data,
              parent_category: {
                value: parent_category.category_id,
                label: parent_category?.minimal_description?.name,
              },
            }
          }

          if (stores) {
            data = {
              ...data,
              stores: stores.map((store: storeDataType) => {
                return {
                  value: store.store_id,
                  label: store.name,
                }
              }),
            }
          }

          return data
        })

        if (ghostFilters) {
          if (ghostFilters?.category_type) {
            updateCategoryType(ghostFilters.category_type)
            delete ghostFilters.category_type
          }

          const formattedFilters = formatFilters(ghostFilters)

          updateFilters(0, formattedFilters as CategoryGhostFilterType[])
        }

        setInformationRetryCopy((item) => item + 1)
      }

      if (location.hash.slice(1) === 'description' || initial) {
        setDesc({
          icon,
          meta_title: details.meta_title ?? '',
          meta_description: details.meta_description ?? '',
          meta_keyword: details.meta_keyword ?? '',
          description: details.description ?? '',
          is_ibm_campaign,
        })
        setDescRetryCopy((item) => item + 1)
      }
    })
  }

  const onChangeInformationValidate = (data: any) => {
    setInformation((details) => ({...details, ...data}))
  }

  const handleDescOnSubmit = (data: any) => {
    if (!isDescValid) {
      return
    }

    updateCategory(
      {
        description: data,
        tab_type: 'description',
      },
      'description'
    )
  }

  const onChangeDescValidate = (data: categoryDescType) => {
    setDesc((desc) => {
      return {...desc, ...data}
    })
  }

  const updateDesc = (key: string, value: any) => {
    setDesc((desc) => ({...desc, [key]: value}))
  }

  useEffect(() => {
    if (categoryId) {
      getCategory(categoryId, true)
    }
  }, [])

  return (
    <>
      <CategoryDetailContext.Provider
        value={{
          desc,
          filters,
          information,
          categoryType,
          descIsChanged,
          filterUniqueIds,
          informationIsChanged,
          updateDesc,
          updateFilters,
          setIsDescValid,
          updateInformation,
          handleDescOnSubmit,
          updateCategoryType,
          onChangeDescValidate,
          setIsInformationValid,
          handleInformationOnSubmit,
          onChangeInformationValidate,
        }}
      >
        {children}
      </CategoryDetailContext.Provider>
    </>
  )
}
