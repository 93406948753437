import debounce from 'lodash/debounce'
import {OptionData} from 'rc-select/lib/interface'
import React, {useEffect, useRef, useState} from 'react'

import {searchIBMCampaignSpecialRequest} from '../../../requests/ibm/IBMCampaignSpecialRequest'
import {
  IBmCampaignSpecialGroupSearchType,
  IIBMCampaignSpecialFilter,
} from '../../models/ibm/IBMCampaignSpecialGroupModel'

export const useIBMCampaignSpecialFilter = ({
  debounceTime = 500,
  initialLoad = false,
}: IIBMCampaignSpecialFilter) => {
  const fetchRef = useRef(0)
  const [options, setOptions] = useState<OptionData[]>()
  const [defaultOptions, setDefaultOptions] = useState<OptionData[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedItems, setSelectedItems] = useState<OptionData[]>([])
  const [search, setSearch] = useState<string>()

  const handleOnSearch = React.useMemo(() => {
    const loadOptions = (search: string) => {
      searchIBMCampaignSpecial(search)
    }

    return debounce(loadOptions, debounceTime)
  }, [])

  const searchIBMCampaignSpecial = (search: string, initial = false) => {
    setSearch(search)

    fetchRef.current += 1
    const fetchId = fetchRef.current
    setLoading(true)

    searchIBMCampaignSpecialRequest(search)
      .then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }

        const specialGroups: OptionData[] = res.data.data.map(
          (specialGroup: IBmCampaignSpecialGroupSearchType) => {
            return {value: specialGroup.id, label: specialGroup.group_name}
          }
        )
        if (initial) {
          setDefaultOptions(specialGroups)
        }

        setOptions(specialGroups)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleOnChange = (payload: Array<OptionData> | OptionData) => {
    if (!payload) {
      return
    }

    if (Array.isArray(payload)) {
      setSelectedItems(payload)

      return
    }

    setSelectedItems([payload] as Array<OptionData>)
  }

  useEffect(() => {
    if (!search) {
      return
    }

    if (initialLoad) {
      setOptions(defaultOptions)
    }
  }, [selectedItems])

  useEffect(() => {
    if (initialLoad) {
      searchIBMCampaignSpecial('', true)
    }
  }, [])

  return {
    options,
    loading,
    selectedItems,
    handleOnSearch,
    handleOnChange,
  }
}
