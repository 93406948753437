import {minimalProductType, minimalProductWithPivotType} from '../products/MinimalProductModel'
import {CategoryMapTableType} from './CategoryMapModel'

export type categoryDataType = {
  category_id: number
  image: string
  himage: string
  category_type: string
  icon: string
  top: boolean
  column: number
  sort_order: number
  status: boolean
  date_added: string
  date_modified: string
  referal: string
  video_url: string
  url: string
  parent_id: number
  products_count: number

  active_product_count: number
  is_category_products_has_to_be_approved: boolean
  details?: categoryDetailType
  products?: minimalProductType[]
  approval_products?: minimalProductWithPivotType
  history_approval_products?: minimalProductWithPivotType
}

export enum CategoryTypeEnum {
  ALL = '*',
  CATEGORY = 'category',
  BOUTIQUE = 'boutique',
  CAMPAIGN = 'campaign',
  GHOST = 'ghost',
}

export type minimalCategoryType = {
  category_id: number
  name: string
  last_update: string
  sort_order: number
  details?: categoryDetailType
  minimal_description?: categoryDetailType
  description?: categoryDetailType
}

export type categoryDetailType = {
  name: string
}

export type categorySearchType = {
  category_id: number
  details: {
    name: string
  }
}

export type categoryPathType = {
  category_id: number
  path: string
}

export interface IUseCategoryFilter {
  debounceTime?: number
  categoryType?: CategoryTypeEnum
  withPath?: boolean
}

export interface ICategoryLandingMap {
  headerProps: {
    title: string
    subTitle?: {
      key: string
      value: string
    }
  }
  categories: CategoryMapTableType[]
  onSelect?: (data: any) => any
}

export type willBeUpdatedCategoryType = {
  category_id: number
  image: null | string
}

export type categoryMostSellerType = {
  first_line_count?: number | string
  product_name?: string
  product_group?: string[]
  discount_rate?: number | string
  product_manufacturer?: string[]
  product_color?: string[]
  product_number_of_people?: string[]
  product_number_of_piece?: string[]
}

type categoryMostSellerInputAttrTypeObject = {
  attribute_id: number
  text: string
}

export type categoryMostSellerInputAttrType = {
  group: categoryMostSellerInputAttrTypeObject[]
  brand: categoryMostSellerInputAttrTypeObject[]
  color: categoryMostSellerInputAttrTypeObject[]
  number_of_people: categoryMostSellerInputAttrTypeObject[]
  number_of_piece: categoryMostSellerInputAttrTypeObject[]
}

type categoryMostSellerManufacturersTypeObject = {
  id: number | string
  text: string
}

export type categoryMostSellerManufacturersType = categoryMostSellerManufacturersTypeObject[]

export interface ICategorySortPage {
  pageType: string
}

export type filterCategoryType = {
  category_id: number
  type: string
  date_modified_text: string
  date_modified: string
  status: boolean
  description?: categoryDetailType
  minimal_description?: categoryDetailType
}
