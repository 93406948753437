import moment from 'moment'
import {useContext, useEffect, useState} from 'react'

import {ALL_MP_STORES, MP_ALL, STORES} from '../../../enums/PassiveProductStoreIdEnum'
import {PassiveProductContext} from '../../../providers/PassiveProductProvider'
import {
  deactivateProductType,
  passiveProductModalDataType,
  passiveProductTableDataType,
  willBePassiveProductType,
} from '../../models/products/passives/PassiveProductModel'

export const useAddProductToPassiveProduct = () => {
  const [includedProductIds, setIncludedProductIds] = useState<number[]>([])
  const [save, setSave] = useState<number>(0)
  const [tempTransferData, setTempTransferData] = useState<passiveProductTableDataType[]>()
  const [tempPassiveProduct, setTempPassiveProduct] = useState<passiveProductModalDataType[]>()
  const [dataNotYetTransferred, setDataNotYetTransferred] =
    useState<passiveProductTableDataType[]>()

  const {
    willBeActiveProducts,
    willBePassiveProducts,
    addWillBePassiveProduct,
    deleteWillBeActiveProduct,
    deactivateProduct,
    activeProduct,
  } = useContext(PassiveProductContext)

  const updatePassiveProduct = (payload: passiveProductModalDataType[]) => {
    setTempPassiveProduct(payload)
  }

  const handleOnSavePassiveProduct = () => {
    if (!tempPassiveProduct) {
      return
    }

    const transferData = tempPassiveProduct
      .map((tempPassiveProduct) => {
        if (tempPassiveProduct.selectedStoreIds.includes(MP_ALL)) {
          const extraStores = ALL_MP_STORES.filter(
            (storeId) => !tempPassiveProduct.selectedStoreIds.includes(storeId)
          )
          tempPassiveProduct.selectedStoreIds.push(...extraStores)
          tempPassiveProduct.selectedStoreIds = tempPassiveProduct.selectedStoreIds.filter(
            (storeId) => storeId !== MP_ALL
          )
        }
        return tempPassiveProduct.selectedStoreIds.map((storeId) => {
          const transferData = tempTransferData?.find(
            (transferData) => transferData.id === tempPassiveProduct.id
          )
          return {
            ...transferData,
            updated_ts: moment().format('DD.MM.YYYY'),
            store_id: storeId,
            store_id_text: STORES.find((item) => item.store_id === storeId)?.store_id_text,
            comment: tempPassiveProduct.comment,
          }
        })
      })
      .flat(1) as willBePassiveProductType[]

    addWillBePassiveProduct(transferData)
    transferData?.forEach((item) => {
      deleteWillBeActiveProduct({productId: item.id, storeId: item.store_id})
    })
    setTempPassiveProduct([])
  }

  useEffect(() => {
    if (save === 0) {
      return
    }

    if (willBePassiveProducts && willBePassiveProducts.length > 0) {
      const deactivateProductData = willBePassiveProducts?.map((item) => {
        return {
          productId: item.id,
          storeId: item.store_id,
          comment: item.comment,
        }
      }) as deactivateProductType[]

      deactivateProduct(deactivateProductData)
    }

    if (willBeActiveProducts && willBeActiveProducts.length > 0) {
      const activateProductData = willBeActiveProducts?.map((item) => {
        return {
          productId: item.productId,
          storeId: item.storeId,
        }
      })

      activeProduct(activateProductData)
    }
  }, [save])

  return {
    includedProductIds,
    save,
    passiveProduct: tempPassiveProduct,
    setPassiveProduct: setTempPassiveProduct,
    tempTransferData,
    setTempTransferData,
    updatePassiveProduct,
    setSave,
    setIncludedProductIds,
    handleOnSavePassiveProduct,
    dataNotYetTransferred,
    setDataNotYetTransferred,
  }
}
