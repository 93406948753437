import {Route, Routes} from 'react-router-dom'

import {CategoryLandingMapWrapper} from '../components/pages/categories/landings/CategoryLandingMapWrapper'
import {MenuExportWrapper} from '../components/pages/menus/exports/MenuExportWrapper'
import {MenuMapWrapper} from '../components/pages/menus/maps/MenuMapWrapper'

export const MenuRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/landings'} element={<CategoryLandingMapWrapper />} />
        <Route path={'/export'} element={<MenuExportWrapper />} />
        <Route path={'/'} element={<MenuMapWrapper />} />
      </Routes>
    </>
  )
}
