import {FC, useContext} from 'react'

import {ProductUpdateContext} from '../../../../../providers/products/updates/ProductUpdateProvider'
import {ProductVariantContext} from '../../../../../providers/products/updates/variants/ProductVariantProvider'
import {AddProductVariantDrawer} from '../../../../drawers/products/variants/AddProductVariantDrawer'
import {KrcTable} from '../../../../organisms/tables/KrcTable'

export const ProductVariantList: FC = () => {
  const {columns} = useContext(ProductVariantContext)
  const {productVariants, setSelectedProductVariants} = useContext(ProductUpdateContext)

  return (
    <>
      <KrcTable
        columns={columns}
        data={productVariants}
        searchableProps={{
          isSearchable: true,
        }}
        selectableProps={{
          isSelectable: true,
          onSelectedItem: (selectItem, type) => {
            setSelectedProductVariants((selectedProductVariants) => {
              const _selectedProductVariants = selectedProductVariants

              // eklendiğinde
              if (type) {
                if (
                  _selectedProductVariants.some(
                    (item) => item.product_id === selectItem.id && item.type === selectItem.type
                  )
                ) {
                  return _selectedProductVariants
                }

                return [
                  ..._selectedProductVariants,
                  {product_id: selectItem.id, type: selectItem.type},
                ]
              }

              return _selectedProductVariants.filter((item) => {
                return !(item.product_id === selectItem.id && item.type === selectItem.type)
              })
            })
          },
        }}
      />
      <AddProductVariantDrawer />
    </>
  )
}
