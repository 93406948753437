import React, {FC, useContext} from 'react'
import {useLocation} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  ProductBadgeContext,
  ProductBadgeProvider,
} from '../../../../providers/products/badges/ProductBadgeProvider'
import {getProductBadgeLogExportFrontendUrl} from '../../../../requests/products/ProductRequest'
import {KrcTabs} from '../../../molecules/KrcTabs'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {AddProductsBadge} from './AddProductsBadge'
import {DeleteProductsBadge} from './DeleteProductsBadge'
import {ProductBadgeActivity} from './ProductBadgeActivity'

const ProductsBadgeWrapperWithoutProvider: FC = () => {
  const location = useLocation()
  const {activityFilter} = useContext(ProductBadgeContext)
  return (
    <>
      <Toolbar>
        {location.hash === '#activity' && (
          <a
            type={'button'}
            className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
            href={getProductBadgeLogExportFrontendUrl(activityFilter)}
            target={'_blank'}
            rel='noreferrer'
          >
            Dışarı Aktar
          </a>
        )}
      </Toolbar>
      <PageTitle>Ürünlere Toplu Badge İşlemleri</PageTitle>
      <KrcTabs
        isActiveKey={true}
        tabsProps={{defaultActiveKey: 'bulk-add'}}
        tabProps={[
          {
            eventKey: 'bulk-add',
            title: 'Toplu Badge Ekleme',
            children: <AddProductsBadge />,
          },
          {
            eventKey: 'bulk-delete',
            title: 'Toplu Badge Silme',
            children: <DeleteProductsBadge />,
          },
          {
            eventKey: 'activity',
            title: 'Aktiviteler',
            children: <ProductBadgeActivity />,
          },
        ]}
      />
    </>
  )
}

export const ProductsBadgeWrapper: FC = () => {
  return (
    <>
      <ProductBadgeProvider>
        <ProductsBadgeWrapperWithoutProvider />
      </ProductBadgeProvider>
    </>
  )
}
