import React, {FC, useContext, useEffect, useState} from 'react'

import {CategoryGhostFilterContext} from '../../../../providers/category/ghosts/CategoryGhostFilterContext'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {
  CategoryGhostFilterKeyType,
  ghostOptionsType,
} from '../../../models/categories/CategoryGhostModel'

export const CategoryGhostFilter: FC<{type?: CategoryGhostFilterKeyType}> = ({type}) => {
  const {options, updateFilter, filters} = useContext(CategoryGhostFilterContext)
  const [currentOption, setCurrentOption] = useState<ghostOptionsType[]>()

  const [element, setElement] = React.useState<any>()

  const findElement = (key: CategoryGhostFilterKeyType): any => {
    return options.find((option) => {
      return option.value === key
    })?.element
  }

  useEffect(() => {
    if (type) {
      setElement(findElement(type))
    }
  }, [options])

  useEffect(() => {
    setCurrentOption(
      options.filter((option) => {
        return !filters.some((filter) => filter.key === option.value) || option.value === type
      })
    )
  }, [filters, options])

  return (
    <>
      <div
        className={'d-flex flex-column py-2'}
        style={{borderRadius: '7px', backgroundColor: '#F3F3F3'}}
      >
        <div className={'d-flex'}>
          <div className={'d-flex flex-row gap-5'}>
            <KrcSelect
              placeholder={'Lütfen Seçiniz'}
              style={{width: 200, height: 'max-content'}}
              showArrow
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) => {
                const label = ((option?.label ?? '') as string).toUpperCase()

                return label.includes(input.toUpperCase())
              }}
              defaultValue={type}
              labelInValue={false}
              allowClear={false}
              onSelect={(key: CategoryGhostFilterKeyType) => {
                updateFilter(key, null, type)
              }}
              onChange={(key) => {
                if (key) {
                  setElement(findElement(key))
                }
              }}
              options={currentOption}
            />
            <div>{element}</div>
          </div>
        </div>
      </div>
    </>
  )
}
