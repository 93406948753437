import {Avatar, Select} from 'antd'
import debounce from 'lodash/debounce'
import moment from 'moment'
import React, {FC, createContext, useEffect, useRef, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {Column} from 'react-table'

import {KrcAlert} from '../../components/atoms/KrcAlert'
import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {KrcRangePicker} from '../../components/atoms/forms/KrcRangePicker'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {categoryDataType} from '../../components/models/categories/CategoryModel'
import {
  categoryUrlType,
  tableFilterPendingApprovalCampaignCurrentSearchParamsType,
  tableFilterPendingApprovalCampaignHistorySearchParamsType,
} from '../../components/models/categories/CategoryProductModel'
import {minimalProductWithPivotType} from '../../components/models/products/MinimalProductModel'
import {ManufacturerFilter} from '../../components/molecules/filters/ManufacturerFilter'
import {PAGE_TYPE, STATUS_APPROVED, STATUS_REJECTED} from '../../enums/CampaignTypeEnum'
import {ProductCategoryTypeEnum} from '../../enums/ProductCategory'
import {getCategoryRequest} from '../../requests/categories/CategoryRequest'
import {
  getCategoryHistoryApprovalProductsRequest,
  getCategoryPendingApprovalRequest,
} from '../../requests/categories/products/CategoryProductRequest'
import {requestDateFormat, viewDateFormat} from '../../service/DateService'

interface ICampaignProductContext {
  campaign?: categoryDataType
  campaignProducts: minimalProductWithPivotType[]
  selectedCampaignProductIds: number[]
  updateSelectedCampaignProductIds: (data: any[]) => void
  pageType: PAGE_TYPE | null
  columns: Column<any>[]
  note?: string
  setNote?: (data: string) => void
  modalVisible: boolean
  setModalVisible: (data: boolean) => void
  setSelectedCampaignProductIds: (data: number[]) => void
  getCategoryPendingApproval: (params?: object) => void
  isLoading: boolean
  historyProductMeta: paginationMetaType
  currentProductMeta: paginationMetaType
  setHistoryProductMeta: (data: any) => void
  setCurrentProductMeta: (data: any) => void
  getCategoryHistoryPendingApproval: (params?: object) => void
  filterPendingApprovalCampaignHistorySearchData: tableFilterPendingApprovalCampaignHistorySearchParamsType
  filterPendingApprovalCampaignCurrentSearchData: tableFilterPendingApprovalCampaignCurrentSearchParamsType
  handleOnFilter: (data: any, key: string | number) => void
  handleOnCurrentFilter: (data: any, key: string | number) => void
}

export const CampaignProductContext = createContext<ICampaignProductContext>({
  selectedCampaignProductIds: [],
  updateSelectedCampaignProductIds: () => {},
  setModalVisible: () => {},
  getCategoryPendingApproval: () => {},
  setSelectedCampaignProductIds: () => {},
  setHistoryProductMeta: () => {},
  setCurrentProductMeta: () => {},
  pageType: null,
  columns: [],
  campaignProducts: [],
  modalVisible: false,
  isLoading: false,
  historyProductMeta: {limit: 10, page: 1},
  currentProductMeta: {limit: 10, page: 1},
  getCategoryHistoryPendingApproval: () => {},
  filterPendingApprovalCampaignHistorySearchData: {
    name: undefined,
    model: undefined,
    manufacturer_ids: undefined,
    type: undefined,
    status: undefined,
    senderUserName: undefined,
    approvingUserName: undefined,
    note: undefined,
    approved_at: undefined,
  },
  filterPendingApprovalCampaignCurrentSearchData: {
    name: undefined,
    model: undefined,
    manufacturer_ids: undefined,
    type: undefined,
    senderUserName: undefined,
    sent_date: undefined,
  },
  handleOnFilter: () => {},
  handleOnCurrentFilter: () => {},
})

export const CampaignProductProvider: FC = ({children}) => {
  const location = useLocation()
  const {categoryId} = useParams<categoryUrlType>()
  const [note, setNote] = useState<string>()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [campaign, setCampaign] = useState<categoryDataType>()
  const [campaignProducts, setCampaignProducts] = useState<minimalProductWithPivotType[]>([])
  const [selectedCampaignProductIds, setSelectedCampaignProductIds] = useState<number[]>([])
  const [pageType, setPageType] = useState<PAGE_TYPE>(PAGE_TYPE.CURRENT)

  const fetchRef = useRef(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [historyProductMeta, setHistoryProductMeta] = useState<paginationMetaType>({
    limit: 10,
    page: 1,
  })
  const [currentProductMeta, setCurrentProductMeta] = useState<paginationMetaType>({
    limit: 10,
    page: 1,
  })

  const [
    filterPendingApprovalCampaignCurrentSearchData,
    setFilterPendingApprovalCampaignCurrentSearchData,
  ] = useState<tableFilterPendingApprovalCampaignCurrentSearchParamsType>({
    name: undefined,
    model: undefined,
    manufacturer_ids: undefined,
    type: undefined,
    senderUserName: undefined,
    sent_date: undefined,
  })

  const [
    filterPendingApprovalCampaignHistorySearchData,
    setFilterPendingApprovalCampaignHistorySearchData,
  ] = useState<tableFilterPendingApprovalCampaignHistorySearchParamsType>({
    name: undefined,
    model: undefined,
    manufacturer_ids: undefined,
    type: undefined,
    status: undefined,
    senderUserName: undefined,
    approvingUserName: undefined,
    note: undefined,
    approved_at: undefined,
  })

  const handleOnFilter = React.useMemo(() => {
    const filter = (value: any, id: string | number) => {
      setFilterPendingApprovalCampaignHistorySearchData((data) => ({...data, [id]: value}))
    }

    return debounce(filter, 500)
  }, [filterPendingApprovalCampaignHistorySearchData])

  const handleOnCurrentFilter = React.useMemo(() => {
    const filter = (value: any, id: string | number) => {
      setFilterPendingApprovalCampaignCurrentSearchData((data) => ({...data, [id]: value}))
    }

    return debounce(filter, 500)
  }, [filterPendingApprovalCampaignCurrentSearchData])

  const currentPageColumns = React.useMemo(() => {
    return [
      {
        Header: 'Görsel',
        accessor: 'image',
        maxWidth: 60,
        manualWidth: 60,
        Filter: KrcEmptyFilter,
        Cell: ({row}: any) => {
          return (
            <div>
              <Avatar size={52} src={row.original.image} style={{minWidth: 52}} />
            </div>
          )
        },
      },
      {
        Header: 'Model',
        accessor: 'model',
        maxWidth: 125,
        manualWidth: 125,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
      },
      {
        Header: 'Marka',
        accessor: 'manufacturerName',
        Filter: () => {
          return (
            <>
              <ManufacturerFilter
                onSelected={(manufacturerIds) => {
                  setFilterPendingApprovalCampaignCurrentSearchData(
                    (filterPendingApprovalCampaignCurrentSearchData) => {
                      return {
                        ...filterPendingApprovalCampaignCurrentSearchData,
                        manufacturer_ids: manufacturerIds as number[],
                      }
                    }
                  )
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Badges',
        accessor: 'badges',
        Filter: KrcEmptyFilter,
        Cell: ({row}: any) => {
          const badges = row.original.badges

          let firstBadge!: string
          let allBadgesExceptFirstBadge!: string

          if (badges.length > 0) {
            firstBadge = badges[0].name
          }

          if (badges.length > 1) {
            allBadgesExceptFirstBadge = ''
            badges
              .slice(1)
              .forEach((badge: any) => (allBadgesExceptFirstBadge += ', ' + badge.name))
          }

          function replaceThreeDotsWithBadges(productId: number) {
            let element = document.getElementsByClassName(
              'badges-see-more-' + productId
            ) as HTMLCollectionOf<HTMLElement>

            if (element[0].innerText === '...') {
              element[0].style.cursor = ''
              element[0].style.color = ''

              element[0].innerText = allBadgesExceptFirstBadge
            } else {
              element[0].style.cursor = 'pointer'
              element[0].style.color = '#0066ff'

              element[0].innerText = '...'
            }
          }

          if (firstBadge) {
            if (allBadgesExceptFirstBadge) {
              return (
                <>
                  {firstBadge}
                  <span
                    className={'badges-see-more-' + row.id}
                    style={{cursor: 'pointer', color: '#0066ff'}}
                    onClick={() => replaceThreeDotsWithBadges(row.id)}
                  >
                    ...
                  </span>
                </>
              )
            } else {
              return <>{firstBadge}</>
            }
          } else {
            return <>{<span>-</span>}</>
          }
        },
      },
      {
        Header: (
          <div className='d-flex row'>
            <span>Fiyat kmiy</span> <span>Fiyat</span>
          </div>
        ),
        Filter: KrcEmptyFilter,
        accessor: 'price',
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                <div className={'mb-1'}>{row.original.priceKmiy}&nbsp;₺</div>
                <span className={'text-gray-400 fs-7'}>{row.original.price}&nbsp;₺</span>
              </div>
            </div>
          )
        },
      },
      {
        Header: (
          <div className='d-flex row'>
            <span>Sepet İndirim</span> <span>İndirim</span>
          </div>
        ),
        Filter: KrcEmptyFilter,
        maxWidth: 150,
        manualWidth: 150,
        accessor: 'specialPrice',
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex row'}>
              <>
                {row.original.cartPrice ? (
                  <>
                    <div className={'mb-1'}>{row.original.cartPrice}&nbsp;₺</div>
                  </>
                ) : (
                  <div>-</div>
                )}
                {row.original.specialPrice ? (
                  <div className={'mb-1'}>{row.original.specialPrice}&nbsp;₺</div>
                ) : (
                  <div>-</div>
                )}
              </>
            </div>
          )
        },
      },
      {
        Header: 'Onaylanınca Oluşacak Sepet İndirimli Fiyatı',
        maxWidth: 150,
        manualWidth: 150,
        accessor: 'priceAfterApproval',
        Filter: KrcEmptyFilter,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              {row.original.priceAfterApproval ? (
                <>
                  <div className={'mb-1'}>{row.original.priceAfterApproval}&nbsp;₺</div>
                </>
              ) : (
                <>-</>
              )}
            </div>
          )
        },
      },
      {
        Header: (
          <div className='d-flex row'>
            <span>Kısıtlar:</span>
            <span style={{fontSize: 11}}>Stok</span>
            <span style={{fontSize: 11}}>Başlangıç Tarihi</span>
            <span style={{fontSize: 11}}>Bitiş Tarihi</span>
          </div>
        ),
        maxWidth: 150,
        manualWidth: 150,
        accessor: 'constraint',
        Filter: KrcEmptyFilter,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex row'}>
              {row.original?.constraint?.stock ? (
                <div className={'text-muted'} style={{fontSize: 12}}>
                  {row.original.constraint.stock}
                </div>
              ) : (
                <div>-</div>
              )}
              {row.original?.constraint?.start_date ? (
                <div className={'text-muted'} style={{fontSize: 12}}>
                  {moment(row.original.constraint.start_date).format('DD.MM.YYYY HH:mm:ss')}
                </div>
              ) : (
                <div>-</div>
              )}
              {row.original?.constraint?.end_date ? (
                <div className={'text-muted'} style={{fontSize: 12}}>
                  {moment(row.original.constraint.end_date).format('DD.MM.YYYY HH:mm:ss')}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          )
        },
      },
      {
        Header: 'Tipi',
        accessor: 'type',
        maxWidth: 130,
        manualWidth: 130,
        Filter: (
          <>
            <Select
              size='middle'
              style={{width: '75%'}}
              placeholder='Durum'
              filterOption={false}
              labelInValue
              onChange={(value: any) => {
                setFilterPendingApprovalCampaignCurrentSearchData(
                  (filterPendingApprovalCampaignCurrentSearchData) => {
                    return {
                      ...filterPendingApprovalCampaignCurrentSearchData,
                      type: value?.value,
                    }
                  }
                )
              }}
              options={[
                {value: ProductCategoryTypeEnum.WILL_BE_ADDED, label: 'Eklenecek'},
                {value: ProductCategoryTypeEnum.WILL_BE_REMOVED, label: 'Silinecek'},
              ]}
              showArrow
              allowClear={true}
              className='form-select'
            />
          </>
        ),
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                {row.original.type === ProductCategoryTypeEnum.WILL_BE_ADDED ? (
                  <KrcAlert message='Eklenecek' type='success' style={{borderRadius: '15px'}} />
                ) : (
                  <></>
                )}

                {row.original.type === ProductCategoryTypeEnum.WILL_BE_REMOVED ? (
                  <KrcAlert message='Silinecek' type='error' style={{borderRadius: '15px'}} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Gönderen Kullanıcı',
        accessor: 'senderUserName',
      },
      {
        Header: 'İşlem Tarihi',
        accessor: 'dateAdded',
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                style={{minWidth: 100}}
                onSelected={(data) => {
                  let startDate = requestDateFormat(data ? data[0] : null, 'YYYY-MM-DD HH:mm')
                  let endDate = requestDateFormat(data ? data[1] : null, 'YYYY-MM-DD HH:mm')
                  let value: (string | null)[] | undefined = undefined
                  if (startDate || endDate) {
                    value = [startDate, endDate]
                  }
                  setFilterPendingApprovalCampaignCurrentSearchData(
                    (filterPendingApprovalCampaignCurrentSearchData) => {
                      return {
                        ...filterPendingApprovalCampaignCurrentSearchData,
                        sent_date: value,
                      }
                    }
                  )
                }}
                size={'middle'}
                showTime
                format={'DD.MM.YYYY HH:mm'}
              />
            </>
          )
        },
      },
    ]
  }, [])

  const historyPageColumn = React.useMemo(() => {
    return [
      {
        Header: 'Görsel',
        accessor: 'image',
        maxWidth: 60,
        manualWidth: 60,
        Filter: KrcEmptyFilter,
        Cell: ({row}: any) => {
          return (
            <div>
              <Avatar size={52} src={row.original.image} style={{minWidth: 52}} />
            </div>
          )
        },
      },
      {
        Header: 'Model',
        accessor: 'model',
        maxWidth: 125,
        manualWidth: 125,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
      },
      {
        Header: 'Marka',
        accessor: 'manufacturerName',
        Filter: () => {
          return (
            <>
              <ManufacturerFilter
                onSelected={(manufacturerIds) => {
                  setFilterPendingApprovalCampaignHistorySearchData(
                    (filterPendingApprovalCampaignHistorySearchData) => {
                      return {
                        ...filterPendingApprovalCampaignHistorySearchData,
                        manufacturer_ids: manufacturerIds as number[],
                      }
                    }
                  )
                }}
              />
            </>
          )
        },
      },
      {
        Header: 'Badges',
        accessor: 'badges',
        Filter: KrcEmptyFilter,
        Cell: ({row}: any) => {
          const badges = row.original.badges

          let firstBadge!: string
          let allBadgesExceptFirstBadge!: string

          if (badges.length > 0) {
            firstBadge = badges[0].name
          }

          if (badges.length > 1) {
            allBadgesExceptFirstBadge = ''
            badges
              .slice(1)
              .forEach((badge: any) => (allBadgesExceptFirstBadge += ', ' + badge.name))
          }

          function replaceThreeDotsWithBadges(productId: number) {
            let element = document.getElementsByClassName(
              'badges-see-more-' + productId
            ) as HTMLCollectionOf<HTMLElement>

            if (element[0].innerText === '...') {
              element[0].style.cursor = ''
              element[0].style.color = ''

              element[0].innerText = allBadgesExceptFirstBadge
            } else {
              element[0].style.cursor = 'pointer'
              element[0].style.color = '#0066ff'

              element[0].innerText = '...'
            }
          }

          if (firstBadge) {
            if (allBadgesExceptFirstBadge) {
              return (
                <>
                  {firstBadge}
                  <span
                    className={'badges-see-more-' + row.id}
                    style={{cursor: 'pointer', color: '#0066ff'}}
                    onClick={() => replaceThreeDotsWithBadges(row.id)}
                  >
                    ...
                  </span>
                </>
              )
            } else {
              return <>{firstBadge}</>
            }
          } else {
            return <>{<span>-</span>}</>
          }
        },
      },
      {
        Header: 'Fiyat',
        accessor: 'price',
        Filter: KrcEmptyFilter,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                <div className={'mb-1'}>{row.original.priceKmiy}&nbsp;₺</div>
                <span className={'text-gray-400 fs-7'}>{row.original.price}&nbsp;₺</span>
              </div>
            </div>
          )
        },
      },
      {
        Header: (
          <div className='d-flex row'>
            <span>Sepet İndirim</span> <span>İndirim</span>
          </div>
        ),
        maxWidth: 150,
        manualWidth: 150,
        accessor: 'specialPrice',
        Filter: KrcEmptyFilter,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex row'}>
              <>
                {row.original.cartPrice ? (
                  <>
                    <div className={'mb-1'}>{row.original.cartPrice}&nbsp;₺</div>
                  </>
                ) : (
                  <div>-</div>
                )}
                {row.original.specialPrice ? (
                  <div className={'mb-1'}>{row.original.specialPrice}&nbsp;₺</div>
                ) : (
                  <div>-</div>
                )}
              </>
            </div>
          )
        },
      },
      {
        Header: (
          <div className='d-flex row'>
            <span>Kısıtlar:</span>
            <span style={{fontSize: 11}}>Stok</span>
            <span style={{fontSize: 11}}>Başlangıç Tarihi</span>
            <span style={{fontSize: 11}}>Bitiş Tarihi</span>
          </div>
        ),
        maxWidth: 150,
        manualWidth: 150,
        accessor: 'constraint',
        Filter: KrcEmptyFilter,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex row'}>
              {row.original?.constraint?.stock ? (
                <div className={'text-muted'} style={{fontSize: 12}}>
                  {row.original.constraint.stock}
                </div>
              ) : (
                <div>-</div>
              )}
              {row.original?.constraint?.start_date ? (
                <div className={'text-muted'} style={{fontSize: 12}}>
                  {moment(row.original.constraint.start_date).format('DD.MM.YYYY HH:mm:ss')}
                </div>
              ) : (
                <div>-</div>
              )}
              {row.original?.constraint?.end_date ? (
                <div className={'text-muted'} style={{fontSize: 12}}>
                  {moment(row.original.constraint.end_date).format('DD.MM.YYYY HH:mm:ss')}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          )
        },
      },
      {
        Header: 'Tipi',
        accessor: 'type',
        maxWidth: 130,
        manualWidth: 130,
        Filter: (
          <>
            <Select
              size='middle'
              style={{width: '75%'}}
              placeholder='Durum'
              filterOption={false}
              labelInValue
              onChange={(value: any) => {
                setFilterPendingApprovalCampaignHistorySearchData(
                  (filterPendingApprovalCampaignHistorySearchData) => {
                    return {
                      ...filterPendingApprovalCampaignHistorySearchData,
                      type: value?.value,
                    }
                  }
                )
              }}
              options={[
                {value: ProductCategoryTypeEnum.WILL_BE_ADDED, label: 'Eklenecek'},
                {value: ProductCategoryTypeEnum.WILL_BE_REMOVED, label: 'Silinecek'},
              ]}
              showArrow
              allowClear={true}
              className='form-select'
            />
          </>
        ),
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                {row.original.type === ProductCategoryTypeEnum.WILL_BE_ADDED ? (
                  <KrcAlert message='Eklenecek' type='success' style={{borderRadius: '15px'}} />
                ) : (
                  <></>
                )}

                {row.original.type === ProductCategoryTypeEnum.WILL_BE_REMOVED ? (
                  <KrcAlert message='Silinecek' type='error' style={{borderRadius: '15px'}} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Durumu',
        accessor: 'status_text',
        maxWidth: 170,
        manualWidth: 170,
        Filter: (
          <>
            <Select
              size='middle'
              style={{width: '75%'}}
              placeholder='Durum'
              filterOption={false}
              labelInValue
              onChange={(value: any) => {
                setFilterPendingApprovalCampaignHistorySearchData(
                  (filterPendingApprovalCampaignHistorySearchData) => {
                    return {
                      ...filterPendingApprovalCampaignHistorySearchData,
                      status: value?.value,
                    }
                  }
                )
              }}
              options={[
                {value: STATUS_APPROVED, label: 'Onaylandı'},
                {value: STATUS_REJECTED, label: 'Reddedildi'},
              ]}
              showArrow
              allowClear={true}
              className='form-select'
            />
          </>
        ),
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                {row.original.status === STATUS_APPROVED ? (
                  <KrcAlert message='Onaylandı' type='success' style={{borderRadius: '15px'}} />
                ) : (
                  <></>
                )}
                {row.original.status === STATUS_REJECTED ? (
                  <KrcAlert message='Reddedildi' type='error' style={{borderRadius: '15px'}} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Gönderen Kullanıcı',
        accessor: 'senderUserName',
      },
      {
        Header: 'Onaylayan Kullanıcı',
        accessor: 'approvingUserName',
      },
      {
        Header: 'Not',
        accessor: 'note',
      },
      {
        Header: 'Onaylama tarihi',
        accessor: 'approvedDate',
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                style={{minWidth: 100}}
                onSelected={(data) => {
                  let startDate = requestDateFormat(data ? data[0] : null, 'YYYY-MM-DD HH:mm')
                  let endDate = requestDateFormat(data ? data[1] : null, 'YYYY-MM-DD HH:mm')
                  let value: (string | null)[] | undefined = undefined
                  if (startDate || endDate) {
                    value = [startDate, endDate]
                  }
                  setFilterPendingApprovalCampaignHistorySearchData(
                    (filterPendingApprovalCampaignHistorySearchData) => {
                      return {
                        ...filterPendingApprovalCampaignHistorySearchData,
                        approved_at: value,
                      }
                    }
                  )
                }}
                size={'middle'}
                showTime
                format={'DD.MM.YYYY HH:mm'}
              />
            </>
          )
        },
      },
    ]
  }, [])

  const [columns, setColumns] = useState<Column<any>[]>(currentPageColumns)

  useEffect(() => {
    if (location.pathname.split('/').pop() === 'history') {
      setPageType(PAGE_TYPE.HISTORY)
      setColumns(historyPageColumn)
      return
    }

    setPageType(PAGE_TYPE.CURRENT)
    setColumns(currentPageColumns)
  }, [location])

  useEffect(() => {
    if (location.pathname.split('/').pop() === 'history') {
      setPageType(PAGE_TYPE.HISTORY)
      return
    }

    setPageType(PAGE_TYPE.CURRENT)
  }, [])

  useEffect(() => {
    if (!categoryId || !pageType) {
      return
    }

    setSelectedCampaignProductIds([])
    setCampaignProducts([])

    if (pageType === PAGE_TYPE.CURRENT) {
      getCategoryPendingApproval()
      return
    }

    getCategoryHistoryPendingApproval()
  }, [categoryId, pageType])

  useEffect(() => {
    if (!categoryId || !pageType || pageType !== PAGE_TYPE.HISTORY) {
      return
    }

    setSelectedCampaignProductIds([])
    setCampaignProducts([])

    getCategoryHistoryPendingApproval()
  }, [historyProductMeta.page])

  useEffect(() => {
    getCategoryRequest(Number(categoryId), ['description']).then((res) => {
      setCampaign(res.data.data)
    })
  }, [categoryId])

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (historyProductMeta.page > 1) {
      setHistoryProductMeta((data) => ({...data, page: 1}))
      return
    }

    if (!categoryId || !pageType || pageType !== PAGE_TYPE.HISTORY) {
      return
    }

    setSelectedCampaignProductIds([])
    setCampaignProducts([])

    getCategoryHistoryPendingApproval()
  }, [filterPendingApprovalCampaignHistorySearchData])

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (currentProductMeta.page > 1) {
      setCurrentProductMeta((data) => ({...data, page: 1}))
      return
    }

    if (!categoryId || !pageType || pageType !== PAGE_TYPE.CURRENT) {
      return
    }

    setSelectedCampaignProductIds([])
    setCampaignProducts([])

    getCategoryPendingApproval()
  }, [filterPendingApprovalCampaignCurrentSearchData])

  const getCategoryPendingApproval = (params = {}) => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    setIsLoading(true)

    getCategoryPendingApprovalRequest(Number(categoryId), ['description'], {
      limit: currentProductMeta.limit,
      page: currentProductMeta.page,
      ...params,
      ...filterPendingApprovalCampaignCurrentSearchData,
    })
      .then((res) => {
        if (fetchRef.current !== fetchId) {
          return
        }

        const campaignProducts = res.data.data.map((product: minimalProductWithPivotType) => {
          return {
            product_id: product.product_id,
            product_to_category_approval_id: product.product_to_category_approval_id,
            name: product?.details?.name,
            image: product.cdn_image,
            type: product.type,
            price: product.prices?.price,
            priceKmiy: product.prices?.price_kmiy,
            specialPrice: product.prices?.special_price,
            cartPrice: product.prices?.price_cart_discount,
            priceAfterApproval: product.price_after_approval?.price,
            model: product.model,
            sku: product.sku,
            manufacturerName: product.manufacturer?.name,
            senderUserName: product.sender_user
              ? product.sender_user.firstname + ' ' + product.sender_user.lastname
              : '',
            constraint: product.constraint,
            badges: product?.badges
              ?.map((badgeList: any) => {
                if (!badgeList.badge) {
                  return null
                }
                return {
                  name: badgeList.badge.name,
                }
              })
              .filter((badge) => badge),
            lastPrice: product.new_price,
            status_text: product.status_text,
            status: product.status,
            dateAdded: viewDateFormat(product.date_added),
          }
        })

        const _meta = res.data.meta

        setCurrentProductMeta({..._meta, limit: _meta.per_page, page: _meta.current_page})
        setCampaignProducts(campaignProducts)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const getCategoryHistoryPendingApproval = (params = {}) => {
    setIsLoading(true)
    fetchRef.current += 1
    const fetchId = fetchRef.current

    getCategoryHistoryApprovalProductsRequest(Number(categoryId), ['description'], {
      limit: historyProductMeta.limit,
      page: historyProductMeta.page,
      ...params,
      ...filterPendingApprovalCampaignHistorySearchData,
    })
      .then((res) => {
        if (fetchRef.current !== fetchId) {
          return
        }

        const campaignProducts = res.data.data.map((product: minimalProductWithPivotType) => {
          return {
            product_id: product.product_id,
            name: product?.details?.name,
            image: product.cdn_image,
            type: product.type,
            price: product.prices?.price,
            priceKmiy: product.prices?.price_kmiy,
            specialPrice: product.prices?.special_price,
            cartPrice: product.prices?.price_cart_discount,
            manufacturerName: product.manufacturer?.name,
            model: product.model,
            approvedDate: moment(product.approved_date).format('DD-MM-YYYY HH:mm:ss'),
            constraint: product.constraint,
            badges: product?.badges
              ?.map((badgeList: any) => {
                if (!badgeList.badge) {
                  return null
                }
                return {
                  name: badgeList.badge.name,
                }
              })
              .filter((badge) => badge),
            lastPrice: product.price,
            status_text: product.status_text,
            status: product.status,
            senderUserName: product.sender_user
              ? product.sender_user.firstname + ' ' + product.sender_user.lastname
              : '',
            approvingUserName: product.approving_user
              ? product.approving_user.firstname + ' ' + product.approving_user.lastname
              : '',
            note: product.note,
          }
        })

        const _meta = res.data.meta

        setHistoryProductMeta({..._meta, limit: _meta.per_page, page: _meta.current_page})
        setCampaignProducts(campaignProducts)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const updateSelectedCampaignProductIds = (originals: any[]) => {
    setSelectedCampaignProductIds(
      originals.map((original) => original.product_to_category_approval_id)
    )
  }

  return (
    <>
      <CampaignProductContext.Provider
        value={{
          note,
          columns,
          pageType,
          campaign,
          isLoading,
          modalVisible,
          campaignProducts,
          selectedCampaignProductIds,
          historyProductMeta,
          currentProductMeta,
          setNote,
          setModalVisible,
          getCategoryPendingApproval,
          updateSelectedCampaignProductIds,
          setSelectedCampaignProductIds,
          setHistoryProductMeta,
          setCurrentProductMeta,
          getCategoryHistoryPendingApproval,
          filterPendingApprovalCampaignHistorySearchData,
          filterPendingApprovalCampaignCurrentSearchData,
          handleOnFilter,
          handleOnCurrentFilter,
        }}
      >
        {children}
      </CampaignProductContext.Provider>
    </>
  )
}
