import {Alert, AlertProps} from 'antd'
import React, {FC} from 'react'

import {useKrcProp} from '../hooks/components/useKrcProp'

const alertConfig = {
  default: {
    style: {borderRadius: '15px'},
  } as AlertProps,
}

interface IAlertConfig {
  config?: 'default'
}

export const KrcAlert: FC<IAlertConfig & AlertProps> = ({config = 'default', ...props}) => {
  const currentProps = useKrcProp({config: alertConfig[config], props}).getCurrentProps()

  return <Alert {...currentProps} />
}
