import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
const USER_URL = `${API_URL}/users`

export function fetchUserPermissionsRequest(userId: number) {
  return axios.get(`${USER_URL}/${userId}/permissions`)
}

export function addPermissionsToUserRequest(userId: number, permissionIds: number[]) {
  return axios.post(`${USER_URL}/${userId}/permissions`, {
    permission_ids: permissionIds,
  })
}

export function deletePermissionFromUserRequest(userId: number, permissionId: number) {
  return axios.delete(`${USER_URL}/${userId}/permissions/${permissionId}`)
}
