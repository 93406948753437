import {InputNumber} from 'antd'
import {InputNumberProps} from 'antd/lib/input-number'
import {FC} from 'react'

import {useKrcProp} from '../../hooks/components/useKrcProp'
import {INumberInputConfig} from '../../models/components/KrcNumberInputModel'

const numberInputConfig = {
  default: {
    size: 'middle',
    style: {borderRadius: 5},
  } as InputNumberProps,
}

export const KrcNumberInput: FC<InputNumberProps & INumberInputConfig> = ({
  config = 'default',
  ...props
}) => {
  const currentProps = useKrcProp({config: numberInputConfig[config], props}).getCurrentProps()

  return <InputNumber {...currentProps}></InputNumber>
}
