import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
const PRODUCT_IMPORT_URL = `${API_URL}/products/import`

export function approveProductImportRequest(importLink: string) {
  return axios.post(importLink)
}

export function getDownloadExampleFileUrl() {
  return `${PRODUCT_IMPORT_URL}/download-example-file`
}

export function productProductImportingRequest(productImportId: number) {
  return axios.get(`${PRODUCT_IMPORT_URL}/${productImportId}`)
}

export function checkProductExcelImportStatus(productExcelImportId: number) {
  return axios.get(`${PRODUCT_IMPORT_URL}/${productExcelImportId}/check-status`)
}
