import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {Badge, Popconfirm, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Link, useParams} from 'react-router-dom'

import {removeEmptyItemInObject} from '../../../helpers/ObjectHelper'
import {initialMetaData} from '../../../providers/tables/ProductSelectionProvider'
import {deleteFaqTopicRequest, getFaqTopicsRequest} from '../../../requests/faq/FaqDetailRequest'
import {KrcEmptyFilter} from '../../atoms/KrcEmptyFilter'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {paginationMetaType} from '../../models/GeneralModel'
import {FaqDataType} from '../../models/faq/FaqModel'

type FaqUrlParams = {
  faqId: string
}
export const useFaqTopicList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<any>({})
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [topics, setTopics] = useState<FaqDataType[]>([])
  const fetchRef = useRef(0)
  const initialRef = useRef(0)

  const urlParams = window.location.search.substring(1)
  const selectedStoreId = urlParams.split('store=')[1].split('&')[0]

  const {faqId} = useParams<FaqUrlParams>()

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'faq_id',
        manualWidth: 120,
        maxWidth: 120,
      },
      {
        Header: 'Konular/Sorular',
        accessor: 'title',
        manualWidth: 650,
        maxWidth: 650,
      },
      {
        Header: 'Durum',
        accessor: 'status',
        manualWidth: 170,
        maxWidth: 170,
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.status ? (
                <div>
                  Aktif <Badge className={'ml-2'} color='green' />
                </div>
              ) : (
                <div>
                  Pasif <Badge className={'ml-2'} color='red' />
                </div>
              )}
            </>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Durum seçiniz'}
              options={[
                {value: 1, label: 'Aktif'},
                {value: 2, label: 'Pasif'},
              ]}
              maxTagCount={'responsive'}
              className={'ant-selector-border w-100'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }

                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'status')
              }}
            />
          )
        },
      },
      {
        Header: 'Sıra',
        accessor: 'sort_order',
        manualWidth: 100,
        maxWidth: 100,
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        manualWidth: 130,
        maxWidth: 130,
        Filter: <KrcEmptyFilter />,
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex justify-content-center gap-3'}>
              <Tooltip title={'Güncelle'}>
                <Link
                  to={`/faq/update/${row.values.faq_id}?store=${selectedStoreId}`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  style={{color: 'unset'}}
                >
                  <EditOutlined />
                </Link>
              </Tooltip>
              <Tooltip title={'Sil'}>
                <Popconfirm
                  title='Bu konuyu silmek istediğinden emin misiniz?'
                  onConfirm={() => removeFaqSubTopic(row.original.faq_id)}
                  okText='Evet'
                  cancelText='Hayır'
                >
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <DeleteOutlined />
                  </div>
                </Popconfirm>
              </Tooltip>
            </div>
          )
        },
      },
    ]
  }, [])

  useEffect(() => {
    if (!setMeta) {
      return
    }

    setMeta(meta)
  }, [meta.to, meta.from, meta.last_page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (!setMeta) {
      return
    }
    setMeta(meta)
    getFaqSubTopics()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }
    getFaqSubTopics()
  }, [meta.limit])

  useEffect(() => {
    initialRef.current = 1
  }, [])

  useEffect(() => {
    if (!meta) {
      return
    }

    setMeta(meta)
  }, [meta])

  useEffect(() => {
    if (!topics) {
      return
    }

    setTopics(topics)
  }, [topics])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getFaqSubTopics()
  }, [filter])

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  const getFaqSubTopics = (page: null | number = null) => {
    setIsLoading(true)
    fetchRef.current += 1
    const fetchId = fetchRef.current
    let _meta = meta
    const _filter = removeEmptyItemInObject(filter)
    if (page) {
      _meta.page = page
    }
    getFaqTopicsRequest(_filter, _meta, Number(faqId), Number(selectedStoreId))
      .then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }
        const metaData = res.data.meta
        setTopics(res.data.data)
        setMeta({limit: metaData.per_page, page: metaData.current_page, ...metaData})
      })
      .catch((err) => {
        message.error('Sıkça sorulan sorular getirilirken bir hata oluştu')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const removeFaqSubTopic = (id: number) => {
    deleteFaqTopicRequest(id, Number(selectedStoreId))
      .then((res) => {
        message.success('Sıkça sorulan soru başarıyla silindi')
        getFaqSubTopics()
      })
      .catch((err) => {
        message.error('Sıkça sorulan soru silinirken bir hata oluştu')
      })
  }

  return {
    isLoading,
    columns,
    topics,
    meta,
    setMeta,
    handleOnFilter,
    getFaqSubTopics,
  }
}
