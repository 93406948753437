import React, {FC} from 'react'

import {useCategorySortingList} from '../../../hooks/categories/useCategorySortingList'
import {KrcTable} from '../../../organisms/tables/KrcTable'

export const CategorySortingList: FC<any> = () => {
  const {columns, categorySorts, meta, setMeta} = useCategorySortingList()

  return (
    <>
      <KrcTable
        columns={columns}
        data={categorySorts}
        searchableProps={{
          isSearchable: true,
        }}
        paginationProps={{
          isPagination: true,
          paginationData: meta,
          page: meta.page,
          limit: meta.limit,
          onChangePage: (page: number) => {
            setMeta({...meta, page})
          },
          onChangeLimit: (limit: number) => {
            setMeta({...meta, limit})
          },
        }}
      />
    </>
  )
}
