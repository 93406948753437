import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
export const LANDING_URL = `${API_URL}/landings`

export function getLandingsRequest() {
  return axios.get(LANDING_URL)
}

export function getLandingRequest(landingId: number, params: object = {}) {
  return axios.get(`${LANDING_URL}/${landingId}`, {params})
}

export function createLandingRequest(body: any, params: object = {}) {
  return axios.post(LANDING_URL, body, {params})
}

export function updateLandingRequest(landingId: number, body: any, params: object = {}) {
  return axios.put(`${LANDING_URL}/${landingId}`, body, {params})
}

export function deleteLandingRequest(landingId: number) {
  return axios.delete(`${LANDING_URL}/${landingId}`)
}

export function multiDeleteLandingRequest(landingIds: number[]) {
  return axios.delete(`${LANDING_URL}/multi`, {data: {landing_ids: landingIds}})
}
