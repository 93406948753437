import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || ''
const CUSTOMER_URL = `${API_URL}/customer`

export function fetchCustomers(customerId?: number) {
  const url = customerId ? `${CUSTOMER_URL}/${customerId}` : `${CUSTOMER_URL}`

  return axios.get(url)
}

export function updateCustomer(customerId: number, fields: any) {
  return axios.patch(`${CUSTOMER_URL}/${customerId}`, fields)
}

export function fetchCustomerGroups() {
  return axios.get(`${CUSTOMER_URL}/group`)
}

export function customerSearchRequest(search: string) {
  return axios.get(`${CUSTOMER_URL}/search`, {params: {search}})
}
