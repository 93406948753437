import {PlusCircleOutlined} from '@ant-design/icons'
import React, {FC, useContext} from 'react'
import {useLocation} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {DealContext, DealProvider} from '../../../../providers/DealProvider'
import {getDealProductsExportFrontendUrl} from '../../../../requests/deals/DealExportRequest'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcTabs} from '../../../molecules/KrcTabs'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {DealDetail} from './DealDetail'
import {DealProductWrapper} from './products/DealProductWrapper'

const DealBody: FC = () => {
  const {dealId} = useContext(DealContext)

  return (
    <>
      <KrcTabs
        isActiveKey={true}
        tabsProps={{defaultActiveKey: 'home'}}
        tabProps={[
          {
            eventKey: 'home',
            title: 'Sayfa bilgisi',
            children: <DealDetail />,
          },
          {
            eventKey: 'deal-products',
            title: 'Fırsat Ürünleri',
            children: <DealProductWrapper />,
            disabled: !dealId,
          },
        ]}
      />
    </>
  )
}

const DealWrapperWithProvider: FC = () => {
  const {dealId, setVisibleAddProductsDrawer, dealDetailSubmitRef, filter, pageType} =
    useContext(DealContext)
  const location = useLocation()

  return (
    <>
      <Toolbar>
        {location.hash !== '#deal-products' ? (
          <div className='d-flex align-items-center py-1'>
            <KrcButton
              type={'primary'}
              onClick={() => {
                if (!dealDetailSubmitRef) {
                  return
                }
                dealDetailSubmitRef.current.click()
              }}
            >
              {dealId ? 'Güncelle' : 'Oluştur'}
            </KrcButton>
          </div>
        ) : (
          <div className='d-flex align-items-center py-1'>
            <a
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-3'
              href={getDealProductsExportFrontendUrl(Number(dealId), {
                ...filter,
                page_type: pageType,
              })}
              target={'_blank'}
              rel='noreferrer'
            >
              Dışarı Aktar
            </a>
            <KrcButton
              className={'d-flex align-items-center justify-content-center'}
              type={'primary'}
              onClick={() => {
                setVisibleAddProductsDrawer(true)
              }}
              icon={<PlusCircleOutlined style={{lineHeight: 0}} />}
            >
              <span>Ürün Ekle</span>
            </KrcButton>
          </div>
        )}
      </Toolbar>
      <PageTitle>Deal</PageTitle>
      <DealBody />
    </>
  )
}

export const DealWrapper: FC = () => {
  return (
    <DealProvider>
      <DealWrapperWithProvider />
    </DealProvider>
  )
}
