import {Field, Form, Formik, FormikHelpers} from 'formik'
import React, {useContext} from 'react'

import {OrderContext} from '../../../../providers/orders/OrderQuestioningProvider'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

interface FormValues {
  phone: string
  email: string
  channel: string
}

const RightForm: React.FC = () => {
  const {setFilter} = useContext(OrderContext)

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    setFilter(values)
  }

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {}

    if (!values.channel) {
      errors.channel = 'Satış Kanalı zorunlu bir alan'
    }

    if (!values.phone) {
      errors.phone = 'Telefon zorunlu bir alan'
    }

    return errors
  }

  return (
    <Formik
      initialValues={{phone: '', email: '', channel: ''}}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({isSubmitting, handleChange}) => (
        <Form style={{display: 'flex', flexWrap: 'wrap'}}>
          <KrcFormikField name='phone' className='p-3 pt-0 col-3'>
            <Field type='text' name='phone' className='form-control' placeholder='Telefon' />
          </KrcFormikField>

          <KrcFormikField name='email' className='p-3 pt-0 col-3'>
            <Field
              type='text'
              name='email'
              className='form-control'
              placeholder='E-posta'
              disabled
            />
          </KrcFormikField>

          <KrcFormikField name='channel' className='p-3 pt-0 col-3'>
            <KrcSelect
              placeholder='Satış Kanalı'
              options={[
                {value: 'WEB', label: 'Web'},
                {value: 'APP', label: 'App'},
              ]}
              onChange={(data) => handleChange({target: {name: 'channel', value: data}})}
              style={{width: '100%'}}
              className='custom-krc-select'
            />
          </KrcFormikField>

          <div className='p-3 pt-0 col-2'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={isSubmitting}
              style={{height: 'fit-content'}}
            >
              Filtrele
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default RightForm
