import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import React, {FC, useEffect} from 'react'

import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useTransferTable} from '../../hooks/components/useTransferTable'
import {IKrcTable} from '../../models/components/TableModel'
import {ITransferTable, transferPropsType} from '../../models/components/TransferTableModel'
import {KrcTable} from '../../organisms/tables/KrcTable'

const {confirm} = Modal

const initialTransferProps = {
  default: {
    config: {
      confirmActive: false,
      confirm: {},
    },
    button: {
      isActive: true,
      title: '<< Aktar',
    },
  } as transferPropsType,
}

export const TransferTable: FC<ITransferTable & IKrcTable> = ({
  data,
  columns,
  transferProps,
  excludedProps,
  onSelectedItems,
  clear,
  ...props
}) => {
  const currentTransferProps = useKrcProp({
    config: initialTransferProps.default,
    props: transferProps,
  }).getCurrentProps()

  const {
    currentData,
    tempExcludeIds,
    transferredItemIds,
    unSelectAllItems,
    onHandleTransfer,
    setCurrentData,
    setTransferredItemIds,
    setSelectedItems,
    selectedItems,
    removeItem,
    setTempExcludeIds,
    setUnSelectAllItems,
  } = useTransferTable({
    data,
    currentTransferProps,
  })

  useEffect(() => {
    setCurrentData(data)
  }, [data])

  useEffect(() => {
    if (clear === undefined || clear === 0) {
      return
    }

    setCurrentData([])
    setSelectedItems([])
    setTransferredItemIds([])
    setTempExcludeIds([])
    setUnSelectAllItems((data) => data + 1)
  }, [clear])

  useEffect(() => {
    if (!excludedProps?.onTempExcluded) {
      return
    }

    excludedProps.onTempExcluded(tempExcludeIds)
  }, [tempExcludeIds])

  useEffect(() => {
    if (!excludedProps?.onExcluded) {
      return
    }

    excludedProps.onExcluded(transferredItemIds)
  }, [transferredItemIds])

  useEffect(() => {
    if (!onSelectedItems) {
      return
    }

    onSelectedItems(selectedItems)
  }, [selectedItems])

  useEffect(() => {
    if (!transferProps?.newTransferredIds) {
      return
    }

    setTransferredItemIds(transferProps?.newTransferredIds)
  }, [transferProps?.newTransferredIds])

  useEffect(() => {
    if (!excludedProps?.removeAllExcluded) {
      return
    }

    setTransferredItemIds([])
    setTempExcludeIds([])
  }, [excludedProps?.removeAllExcluded])

  return (
    <>
      <KrcTable
        columns={columns}
        data={currentData}
        selectableProps={{
          isSelectable: true,
          onSelectedItems: setSelectedItems,
          unSelectAllItems,
        }}
        {...props}
      />
      <div className={'d-flex justify-content-between'}>
        {currentTransferProps?.button && currentTransferProps.button.isActive ? (
          <div
            className={'text-primary mt-3 fs-5 pointer'}
            role={'button'}
            onClick={onHandleTransfer}
          >
            {currentTransferProps.button.title}
          </div>
        ) : (
          <div></div>
        )}
        <div
          className={'text-primary mt-3 text-end fs-5 pointer'}
          role={'button'}
          style={{marginRight: 20}}
          onClick={() => {
            confirm({
              title: `${selectedItems.length} adet seçim tablodan kaldıralacak`,
              icon: <ExclamationCircleOutlined />,
              content:
                'Bu kısımdan bu öğeyi kaldırmak herhangi bir değişikliğe yol açmaz yalnızca tablo içerisinden kaldırılır',
              okText: 'Tamam',
              cancelText: 'İptal',
              onOk() {
                removeItem()
              },
            })
          }}
        >
          Kaldır
        </div>
      </div>
    </>
  )
}
