import debounce from 'lodash/debounce'
import {Moment} from 'moment/moment'
import React, {FC, useContext, useEffect, useRef, useState} from 'react'

import {ProductBadgeLogType} from '../../../../../../enums/ProductBadgeLogType'
import {CategoryProductsProcessContext} from '../../../../../../providers/category/products/CategoryProductsProcessProvider'
import {initialMetaData} from '../../../../../../providers/tables/ProductSelectionProvider'
import {getCategoryProductsActivityRequests} from '../../../../../../requests/categories/products/CategoryProductRequest'
import {viewDateFormat} from '../../../../../../service/DateService'
import {KrcAlert} from '../../../../../atoms/KrcAlert'
import {KrcRangePicker} from '../../../../../atoms/forms/KrcRangePicker'
import {KrcSelect} from '../../../../../atoms/forms/KrcSelect'
import {paginationMetaType} from '../../../../../models/GeneralModel'
import {
  ICategoryProductProcessPage,
  categoryProductLogType,
} from '../../../../../models/categories/CategoryProductModel'
import {productBadgeLogFilterType} from '../../../../../models/products/ProductModel'
import {KrcTable} from '../../../../../organisms/tables/KrcTable'

export const CategoryProductsProcessActivity: FC<ICategoryProductProcessPage> = ({pageType}) => {
  const [data, setData] = useState<any[]>([])
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const [filter, setFilter] = useState<productBadgeLogFilterType>({})
  const fetchRequestRef = useRef(0)
  const initialRef = useRef(0)

  const {category, setPageType} = useContext(CategoryProductsProcessContext)

  useEffect(() => {
    setPageType(pageType)
  }, [])

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Ürün Model',
        accessor: 'model',
        maxWidth: 120,
        manualWidth: 120,
      },
      {
        Header: 'Ürün Adı',
        accessor: 'product_name',
        manualWidth: 300,
        maxWidth: 300,
      },
      {
        Header: 'İşlemi Gerçekleşiren \n Kullanıcı Adı',
        accessor: 'user_name',
        maxWidth: 220,
        manualWidth: 220,
      },
      {
        Header: 'Durum',
        accessor: 'type',
        maxWidth: 150,
        manualWidth: 150,
        Filter: () => {
          return (
            <>
              <KrcSelect
                mode={undefined}
                placeholder={'Seçiniz'}
                options={[
                  {value: 'success', label: 'Başarılı'},
                  {value: 'error', label: 'Başarısız'},
                ]}
                style={{width: 120}}
                className={'ant-selector-border'}
                onChange={(data) => {
                  if (initialRef.current === 0) {
                    return
                  }
                  handleOnFilter(data, 'type')
                }}
              />
            </>
          )
        },
        Cell: ({row}: any) => {
          return (
            <div className={'d-flex ml-2'}>
              <div>
                {row.original.type === ProductBadgeLogType.SUCCESS ? (
                  <KrcAlert message='Başarılı' type='success' className={'py-1'} />
                ) : (
                  <></>
                )}

                {row.original.type === ProductBadgeLogType.ERROR ? (
                  <KrcAlert message='Başarısız' type='error' className={'py-1'} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Açıklama',
        accessor: 'message',
        Cell: ({row}: any) => <div dangerouslySetInnerHTML={{__html: row.original.message}}></div>,
        maxWidth: 140,
        manualWidth: 140,
        Filter: <></>,
      },
      {
        Header: 'Gerçekleşme Tarihi',
        accessor: 'created_at',
        maxWidth: 260,
        manualWidth: 260,
        Filter: () => {
          return (
            <>
              <KrcRangePicker
                onSelected={handleOnSelectedRangePicker}
                size={'middle'}
                showTime={true}
                style={{borderRadius: 5, border: '1px solid #d9d9d9'}}
                format={'DD.MM.YYYY HH:mm'}
              />
            </>
          )
        },
      },
    ]
  }, [])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getCategoryProductsLogs()
  }, [category])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getCategoryProductsLogs()
  }, [filter])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    if (meta.page !== 1) {
      setMeta((data) => ({...data, page: 1}))
      return
    }

    getCategoryProductsLogs()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }

    getCategoryProductsLogs()
  }, [meta.page])

  useEffect(() => {
    initialRef.current = 1

    if (initialRef.current !== 0) {
      getCategoryProductsLogs()
    }
  }, [])

  const getCategoryProductsLogs = () => {
    fetchRequestRef.current += 1
    const fetchId = fetchRequestRef.current

    if (!category) {
      return
    }

    getCategoryProductsActivityRequests(category.category_id, filter, meta).then((res) => {
      if (fetchRequestRef.current !== fetchId) {
        return
      }

      const _data = res.data.data.map((item: categoryProductLogType) => {
        return {
          model: item.product?.model,
          product_name: item.product?.minimal_details?.name,
          user_name: item.user?.fullname,
          type: item.type,
          message: item.message,
          created_at: viewDateFormat(item.created_at),
        }
      })

      setData(_data)
      const resMeta = res.data.meta
      setMeta({limit: resMeta.per_page, page: resMeta.current_page, ...resMeta})
    })
  }

  const handleOnSelectedRangePicker = (date: Moment[] | null) => {
    if (!date) {
      setFilter((data) => {
        return {...data, created_at_range: undefined}
      })
      return
    }

    setFilter((data) => {
      return {
        ...data,
        created_at_range: [date[0].toISOString(), date[1].toISOString()],
      }
    })
  }

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data?: productBadgeLogFilterType) => {
        if (!data) {
          return {[rowId]: value}
        }

        return {...data, ...{[rowId]: value}}
      })
    }

    return debounce(loadTableFilter, 250)
  }, [])

  return (
    <>
      <KrcTable
        columns={columns}
        data={data}
        paginationProps={{
          isPagination: true,
          limit: meta.limit,
          page: meta.page,
          paginationData: meta,
          onChangePage: (page) => {
            setMeta((data) => ({...data, page}))
          },
          onChangeLimit: (limit) => {
            setMeta((data) => ({...data, limit}))
          },
        }}
        searchableProps={{
          isSearchable: true,
          onSearch: handleOnFilter,
        }}
      />
    </>
  )
}
