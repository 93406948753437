import {Row} from 'antd'
import {FC} from 'react'

import {IKrcGridProductTable} from '../../models/components/GridProductTableModel'
import {KrcGridProductCard} from '../../molecules/KrcGridProductCard'
import {LoadingInTheMiddle} from '../../molecules/LoadingInTheMiddle'

export const KrcGridProductTable: FC<IKrcGridProductTable> = ({data}) => {
  return (
    <>
      {data && data.length > 0 ? (
        <Row style={{marginTop: 15}}>
          {data.map((item) => {
            return (
              <div className={'flex flex-wrap gap-3'} key={item.id}>
                <KrcGridProductCard
                  id={item.id}
                  name={item.name}
                  model={item.model}
                  status={item.status}
                  image={item.image}
                />
              </div>
            )
          })}
        </Row>
      ) : (
        <LoadingInTheMiddle />
      )}
    </>
  )
}
