import {message} from 'antd'

export const errorMessage = (error: any, printErrorMessage: boolean = true) => {
  if (!error.response) {
    return
  }
  let response = error.response
  let data = response.data
  let statusCode = response.status
  const defaultMessage = 'Bir şeyler ters gitti. Lütfen daha sonra tekrar dene.'
  let msg = ''

  if (403 === statusCode) {
    return
  }

  if (422 === statusCode) {
    if (typeof data === 'object' && data !== null && data.hasOwnProperty('errors')) {
      if (typeof data.errors === 'object') {
        Object.keys(data.errors).forEach((key, index) => {
          msg = data.errors[key][0]
        })
      }
    }

    msg += ' Girdiğin bilgileri kontrol edip tekrar dene.'
  }

  if (printErrorMessage) {
    const willBePrintedMessage = msg || defaultMessage

    if (willBePrintedMessage) {
      message.error(willBePrintedMessage)
    }
  }

  return msg ? msg : null
}
