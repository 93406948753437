import {SelectProps, Spin} from 'antd'
import React, {FC, useEffect} from 'react'

import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {useKrcProp} from '../../hooks/components/useKrcProp'
import {useStoreFilter} from '../../hooks/stores/useStoreFilter'
import {IStoreFilter} from '../../models/stores/StoreModel'

const storeFilterConfig = {
  default: {
    style: {width: '100%'},
    mode: 'multiple',
    size: 'middle',
    placeholder: 'Mağazalar',
    dropdownStyle: {zIndex: 99999},
  },
}

export const StoreFilter: FC<IStoreFilter & SelectProps> = ({
  onSelected,
  dataType = 'value',
  children,
  clear,
  storeType,
  ...props
}) => {
  let currentProps = useKrcProp({config: storeFilterConfig.default, props}).getCurrentProps()
  const {fetching, options, handleSearch, handleOnChange, selectedStoreIds, selectedItems} =
    useStoreFilter({storeType})
  const initialRef = React.useRef(0)

  useEffect(() => {
    if (initialRef.current === 0) {
      initialRef.current = 1

      return
    }

    if (!onSelected) {
      return
    }

    if (dataType === 'value') {
      onSelected(selectedStoreIds)
      return
    }

    onSelected(selectedItems)
  }, [selectedStoreIds])

  useEffect(() => {
    if (currentProps.showSearch) {
      currentProps = {...currentProps, onSearch: handleSearch}
    }
  }, [])

  return (
    <>
      <KrcSelect
        clear={clear}
        {...currentProps}
        showArrow
        filterOption={false}
        allowClear={false}
        labelInValue
        onChange={handleOnChange}
        options={options}
        notFoundContent={fetching ? <Spin size='small' /> : null}
      >
        {children}
      </KrcSelect>
    </>
  )
}
