import React from 'react'

import {IOrderModel} from '../../../../components/models/order/OrderModel'
import {KrcTabs} from '../../../molecules/KrcTabs'
import CustomerGeneralInformation from './tabs/CustomerGeneralInformation'
import CustomerInvoiceInformation from './tabs/CustomerInvoiceInformation'
import CustomerShippingInformation from './tabs/CustomerShippingInformation'

const Customer: React.FC<IOrderModel> = (props) => (
  <div className='block-wrapper radius krc'>
    <KrcTabs
      isActiveKey={true}
      tabsProps={{defaultActiveKey: 'customer'}}
      tabProps={[
        {
          eventKey: 'customer',
          title: 'Müşteri Detayı',
          children: props.order ? <CustomerGeneralInformation order={props.order} /> : null,
        },
        {
          eventKey: 'invoice',
          title: 'Fatura Adresi',
          children: props.order ? <CustomerInvoiceInformation order={props.order} /> : null,
        },
        {
          eventKey: 'shipping',
          title: 'Kargo Adresi',
          children: props.order ? <CustomerShippingInformation order={props.order} /> : null,
        },
      ]}
    />
  </div>
)

export default Customer
