import {Select} from 'antd'
import cn from 'classnames'
import React, {FC, useContext} from 'react'
import {Link} from 'react-router-dom'

import {PageTitle} from '../../../../_metronic/layout/core'
import {FAQ_STORES} from '../../../enums/FaqStoresTypeEnum'
import {FaqContext, FaqProvider} from '../../../providers/faq/FaqProvider'
import {Toolbar} from '../../organisms/toolbars/Toolbar'
import {FaqList} from './FaqList'

const FaqListWrapperWithoutProvider: FC = () => {
  const {selectedStoreId, setSelectedStoreId} = useContext(FaqContext)

  return (
    <>
      <Toolbar>
        <div className='d-flex align-items-center py-1'>
          <Link
            to={`/faq/create?store=${selectedStoreId}`}
            className={cn({'btn btn-sm btn-primary me-4': true})}
            id='kt_toolbar_primary_button'
          >
            Oluştur
          </Link>
          <span className={'fs-5 mr-3 text-dark fw-bolder'}>Mağazalar:</span>
          {selectedStoreId && (
            <>
              <Select
                className={'ant-selector-border custom-ant-select-selection-placeholder w-full'}
                defaultValue={selectedStoreId.toString()}
                value={selectedStoreId.toString()}
                style={{width: 120}}
                onChange={(data) => setSelectedStoreId(data)}
                options={FAQ_STORES.map((store) => {
                  return {value: store.id.toString(), label: store.store_name}
                })}
              />
            </>
          )}
        </div>
      </Toolbar>
      <PageTitle>Sıkça Sorulan Sorular</PageTitle>
      <FaqList />
    </>
  )
}

export const FaqListWrapper: FC = () => {
  return (
    <FaqProvider>
      <FaqListWrapperWithoutProvider />
    </FaqProvider>
  )
}
