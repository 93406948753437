import {Select} from 'antd'
import React, {FC, useContext} from 'react'
import {Link} from 'react-router-dom'

import {CategoryMapContext} from '../../../../providers/tables/CategoryMapProvider'
import {useCategoryMap} from '../../../hooks/categories/useCategoryMap'
import {ICategoryMap} from '../../../models/categories/CategoryMapModel'
import {MappingTableBody} from '../../../organisms/mapping_table/MappingTableBody'
import {MappingTableHeader} from '../../../organisms/mapping_table/MappingTableHeader'

export const CategoryMap: FC<ICategoryMap> = ({data, onSelect, headerProps, deep}) => {
  const {selectedCategoryId, setSelectedCategoryId, columns} = useCategoryMap()
  const {getCategoriesMap, categoryType, fetchLoading} = useContext(CategoryMapContext)

  return (
    <>
      <MappingTableHeader {...headerProps}>
        <div className={'d-flex flex-column w-50'}>
          {selectedCategoryId ? (
            <Link
              to={`/categories/details/${selectedCategoryId}/products`}
              className={'text-primary mt-1 text-end fs-5 pointer'}
              role={'button'}
            >
              {'Detaya Git'}
            </Link>
          ) : null}
          {deep === 0 && (
            <Select
              size='middle'
              placeholder='Kategori tipi'
              defaultValue={['Kategori Türü']}
              filterOption={false}
              labelInValue
              onChange={({value}: any) => {
                if (!getCategoriesMap) {
                  return
                }

                getCategoriesMap(0, 0, value)
              }}
              options={categoryType}
              showArrow
              className='form-select'
              disabled={fetchLoading}
            />
          )}
        </div>
      </MappingTableHeader>
      <MappingTableBody
        data={data}
        columns={columns}
        onSelect={(data: any) => {
          setSelectedCategoryId(data.original.id)

          if (onSelect) {
            onSelect(data)
          }
        }}
      />
    </>
  )
}
