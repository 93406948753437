import {EditOutlined} from '@ant-design/icons'
import {Badge, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {useEffect, useRef} from 'react'
import {Cell, Column} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {KrcNumberInput} from '../../components/atoms/forms/KrcNumberInput'
import {KrcSelect} from '../../components/atoms/forms/KrcSelect'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {
  CouponPrefixModelType,
  CouponPrefixUpdateDataType,
} from '../../components/models/campaign_and_coupon/CouponPrefixModel'
import {removeEmptyItemInObject} from '../../helpers/ObjectHelper'
import {
  createCouponPrefixRequest,
  getCouponPrefixesRequest,
  updateCouponPrefixRequest,
} from '../../requests/campaign_and_coupon/CouponPrefixRequests'

interface ICouponPrefixContext {
  columns: Column<any>[]
  handleOnFilter: (data: any, key: string | number) => void
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  filter: any
  setFilter: React.Dispatch<React.SetStateAction<any>>
  data: CouponPrefixModelType[]
  setData: (data: CouponPrefixModelType[]) => void
  isShowCreateModal: boolean
  setIsShowCreateModal: (isShowCreateModal: boolean) => void
  isShowUpdateModal: boolean
  setIsShowUpdateModal: (isShowUpdateModal: boolean) => void
  willBeUpdatePrefix: CouponPrefixModelType | null
  setWillBeUpdatePrefix: React.Dispatch<React.SetStateAction<CouponPrefixModelType | null>>
  updateCouponPrefix: (data: CouponPrefixUpdateDataType) => void
  createCouponPrefix: (data: CouponPrefixUpdateDataType) => void
  isDataEmptyAfterFilter: boolean
  setIsDataEmptyAfterFilter: (isDataEmptyAfterFilter: boolean) => void
}

export const CouponPrefixContext = React.createContext<ICouponPrefixContext>({
  columns: [],
  handleOnFilter: () => {},
  meta: {limit: 10, page: 1},
  setMeta: () => {},
  filter: {},
  setFilter: () => {},
  data: [],
  setData: () => {},
  isShowCreateModal: false,
  setIsShowCreateModal: () => {},
  isShowUpdateModal: false,
  setIsShowUpdateModal: () => {},
  willBeUpdatePrefix: null,
  setWillBeUpdatePrefix: () => {},
  updateCouponPrefix: () => {},
  createCouponPrefix: () => {},
  isDataEmptyAfterFilter: false,
  setIsDataEmptyAfterFilter: () => {},
})

export const CouponPrefixProvider: React.FC = ({children}) => {
  const [meta, setMeta] = React.useState<paginationMetaType>({limit: 10, page: 1})
  const [filter, setFilter] = React.useState<any>({})
  const [data, setData] = React.useState<CouponPrefixModelType[]>([])
  const [isShowCreateModal, setIsShowCreateModal] = React.useState<boolean>(false)
  const [isShowUpdateModal, setIsShowUpdateModal] = React.useState<boolean>(false)
  const [willBeUpdatePrefix, setWillBeUpdatePrefix] = React.useState<CouponPrefixModelType | null>(
    null
  )
  const [isDataEmptyAfterFilter, setIsDataEmptyAfterFilter] = React.useState<boolean>(false)

  const initialRef = useRef(0)

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        manualWidth: 130,
        maxWidth: 130,
        Filter: () => {
          return (
            <KrcNumberInput
              className={'w-100'}
              min={0}
              placeholder={'ID'}
              addonBefore='ID'
              onChange={(data) => {
                handleOnFilter(data, 'id')
              }}
            />
          )
        },
      },
      {
        Header: 'Prefix Adı',
        accessor: 'prefix',
        manualWidth: 200,
        maxWidth: 200,
      },
      {
        Header: 'Negatif Marjdan Hariç Mi?',
        accessor: 'is_excluding_negative_margin',
        manualWidth: 220,
        maxWidth: 220,
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.is_excluding_negative_margin ? (
                <div>
                  Evet <Badge className={'ml-2'} color='green' />
                </div>
              ) : (
                <div>
                  Hayır <Badge className={'ml-2'} color='red' />
                </div>
              )}
            </>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Durum Seçiniz'}
              options={[
                {value: 1, label: 'Evet'},
                {value: 2, label: 'Hayır'},
              ]}
              maxTagCount={'responsive'}
              style={{width: '100%'}}
              className={'ant-selector-border'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }
                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'is_excluding_negative_margin')
              }}
            />
          )
        },
      },
      {
        Header: 'Marj Oranı',
        accessor: 'negative_margin',
        manualWidth: 160,
        maxWidth: 160,
        Filter: () => {
          return (
            <KrcNumberInput
              className={'w-100'}
              addonAfter='%'
              placeholder={'Marj'}
              onChange={(data) => {
                handleOnFilter(data, 'negative_margin')
              }}
            />
          )
        },
        Cell: ({row}: any) => {
          return <>{row.original.negative_margin ? row.original.negative_margin + '%' : ''}</>
        },
      },
      {
        Header: 'Sepette Görünme Durumu',
        accessor: 'is_show_in_cart',
        manualWidth: 200,
        maxWidth: 200,
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.is_show_in_cart ? (
                <div>
                  Görünür <Badge className={'ml-2'} color='green' />
                </div>
              ) : (
                <div>
                  Görünmez <Badge className={'ml-2'} color='red' />
                </div>
              )}
            </>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Durum Seçiniz'}
              options={[
                {value: 1, label: 'Görünür'},
                {value: 2, label: 'Görünmez'},
              ]}
              maxTagCount={'responsive'}
              style={{width: '100%'}}
              className={'ant-selector-border'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }
                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'is_show_in_cart')
              }}
            />
          )
        },
      },
      {
        Header: 'Durum',
        accessor: 'status',
        manualWidth: 160,
        maxWidth: 160,
        Cell: ({row}: any) => {
          return (
            <>
              {row.original.status ? (
                <div>
                  Aktif <Badge className={'ml-2'} color='green' />
                </div>
              ) : (
                <div>
                  Pasif <Badge className={'ml-2'} color='red' />
                </div>
              )}
            </>
          )
        },
        Filter: () => {
          return (
            <KrcSelect
              mode={undefined}
              placeholder={'Durum Seçiniz'}
              options={[
                {value: 1, label: 'Aktif'},
                {value: 2, label: 'Pasif'},
              ]}
              maxTagCount={'responsive'}
              style={{width: '100%'}}
              className={'ant-selector-border'}
              showArrow={true}
              onChange={(data) => {
                if (initialRef.current === 0) {
                  return
                }
                const _data = data ? (data === 1 ? '1' : '0') : undefined
                handleOnFilter(_data, 'status')
              }}
            />
          )
        },
      },
      {
        Header: 'İşlem',
        accessor: 'actions',
        Filter: <KrcEmptyFilter />,
        manualWidth: 100,
        maxWidth: 100,
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row justify-content-around'>
              <Tooltip title={'Düzenle'}>
                <div
                  onClick={() => {
                    setWillBeUpdatePrefix(row.original)
                    setIsShowUpdateModal(true)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  <EditOutlined />
                </div>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getCouponPrefixes()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    getCouponPrefixes()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getCouponPrefixes()
  }, [filter])

  useEffect(() => {
    initialRef.current = 1
    getCouponPrefixes()
  }, [])

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }
    return debounce(loadTableFilter, 250)
  }, [])

  const getCouponPrefixes = () => {
    const _filter = removeEmptyItemInObject(filter)
    getCouponPrefixesRequest(_filter, meta)
      .then((response) => {
        const _meta = response.data.meta
        setMeta({limit: _meta.per_page, page: _meta.current_page, ..._meta})
        setData(response.data.data)
        if (response.data.data.length === 0) {
          setIsDataEmptyAfterFilter(true)
        } else {
          setIsDataEmptyAfterFilter(false)
        }
      })
      .catch((error) => {
        message.error('Kupon önekleri getirilirken bir hata oluştu.')
      })
  }

  const updateCouponPrefix = (data: CouponPrefixUpdateDataType) => {
    message.info('Kupon prefix güncelleniyor.')
    if (!willBeUpdatePrefix) return
    updateCouponPrefixRequest(willBeUpdatePrefix.id, data)
      .then((response) => {
        message.success('Kupon prefix güncellendi.')
        setIsShowUpdateModal(false)
        getCouponPrefixes()
      })
      .catch((error) => {
        message.error('Kupon prefix güncellenirken bir hata oluştu.')
      })
  }

  const createCouponPrefix = (data: CouponPrefixUpdateDataType) => {
    message.info('Kupon prefix oluşturuluyor.')
    createCouponPrefixRequest(data)
      .then((response) => {
        message.success('Kupon prefix oluşturuldu.')
        setIsShowCreateModal(false)
        getCouponPrefixes()
      })
      .catch((error) => {
        message.error('Kupon prefix oluşturulurken bir hata oluştu.')
      })
  }

  return (
    <CouponPrefixContext.Provider
      value={{
        columns,
        handleOnFilter,
        meta,
        setMeta,
        data,
        setData,
        isShowCreateModal,
        setIsShowCreateModal,
        isShowUpdateModal,
        setIsShowUpdateModal,
        willBeUpdatePrefix,
        setWillBeUpdatePrefix,
        filter,
        setFilter,
        updateCouponPrefix,
        createCouponPrefix,
        isDataEmptyAfterFilter,
        setIsDataEmptyAfterFilter,
      }}
    >
      {children}
    </CouponPrefixContext.Provider>
  )
}
