export const COOKPLUS = 0
export const KARACA_HOME = 1
export const KARACA = 2
export const EMSAN = 3
export const HEPSIBURADA = 4
export const N11 = 5
export const TRENDYOL = 6
export const JUMBO = 7
export const CICEK_SEPETI = 8
export const AMAZON_TR = 9
export const GITTI_GIDIYOR = 11
export const KARACA_APP = 12
export const PERA_BULVARI = 13
export const WEIMER = 16
export const HOMEND = 18
export const AMAZON_EU = 21
export const KASMIR_HALI = 22
export const VODAFONE = 23

export const COUPON_STORES = [
  {
    value: COOKPLUS,
    label: 'Cookplus',
  },
  {
    value: KARACA_HOME,
    label: 'Karaca Home',
  },
  {
    value: KARACA,
    label: 'Karaca',
  },
  {
    value: EMSAN,
    label: 'Emsan',
  },
  {
    value: HEPSIBURADA,
    label: 'Hepsiburada',
  },
  {
    value: N11,
    label: 'N11',
  },
  {
    value: TRENDYOL,
    label: 'Trendyol',
  },
  {
    value: JUMBO,
    label: 'Jumbo',
  },
  {
    value: CICEK_SEPETI,
    label: 'Çiçek Sepeti',
  },
  {
    value: AMAZON_TR,
    label: 'Amazon TR',
  },
  {
    value: GITTI_GIDIYOR,
    label: 'Gitti Gidiyor',
  },
  {
    value: KARACA_APP,
    label: 'Karaca App',
  },
  {
    value: PERA_BULVARI,
    label: 'Pera Bulvarı',
  },
  {
    value: WEIMER,
    label: 'Weimer',
  },
  {
    value: HOMEND,
    label: 'Homend',
  },
  {
    value: AMAZON_EU,
    label: 'Amazon EU',
  },
  {
    value: KASMIR_HALI,
    label: 'Kasmir Hali',
  },
  {
    value: VODAFONE,
    label: 'Vodafone',
  },
]
