import React, {FC} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {KrcButton} from '../../../atoms/buttons/KrcButton'
import {KrcTabs} from '../../../molecules/KrcTabs'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {SortingWeightActivityList} from '../../sorting/SortingWeightActivityList'
import {CategorySortingList} from './CategorySortingList'

const CategorySortingListWrapperRightField: FC<any> = () => {
  useDispatch()
  return (
    <>
      <div className='d-flex align-items-center py-1'>
        <Link to={'/categories/sorting-weights'}>
          <KrcButton size={'middle'} className={'mr-3'} id='kt_toolbar_primary_button'>
            Sıralama Ağırlığı Güncelle
          </KrcButton>
        </Link>
      </div>
    </>
  )
}

export const CategorySortingListWrapper: FC = () => {
  return (
    <>
      <Toolbar rightField={<CategorySortingListWrapperRightField />} />
      <PageTitle breadcrumbs={[]}>Kategoriler - Sıralama Yönetimi</PageTitle>
      <KrcTabs
        isActiveKey={true}
        tabsProps={{defaultActiveKey: 'view'}}
        tabsClassName={''}
        tabProps={[
          {
            eventKey: 'view',
            title: 'Liste',
            children: <CategorySortingList />,
          },
          {
            eventKey: 'sorting',
            title: 'Aktiviteler',
            children: <SortingWeightActivityList />,
          },
        ]}
      />
    </>
  )
}
