import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {Popconfirm, Tooltip} from 'antd'
import React, {FC, createContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Cell, Column} from 'react-table'

import {landingDataType} from '../components/models/landings/LandingModel'
import {PAGE_TYPES} from '../enums/LandingPageTypeEnum'
import {deleteLandingRequest, getLandingsRequest} from '../requests/landings/LandingRequest'

interface ILandingListContext {
  landings: landingDataType[]
  columns: Column<any>[]
}

export const LandingListContext = createContext<ILandingListContext>({
  landings: [],
  columns: [],
})

export const LandingListProvider: FC = ({children}) => {
  const [confirmLoading] = React.useState(false)
  const [landings, setLandings] = useState<landingDataType[]>([])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Landing ID',
        accessor: 'landing_id', // accessor is the "key" in the data
        manualWidth: 100,
        maxWidth: 100,
      },
      {
        Header: 'Adı',
        accessor: 'name',
        manualWidth: 500,
        maxWidth: 500,
      },
      {
        Header: 'Sayfa Türü',
        accessor: 'page_type',
        manualWidth: 170,
        maxWidth: 170,
        Cell: ({row}: Cell) => (
          <div>
            {
              // @ts-ignore
              PAGE_TYPES[row.values.page_type]
            }
          </div>
        ),
      },
      {
        Header: 'Durum',
        accessor: 'status',
        manualWidth: 170,
        maxWidth: 170,
        Cell: ({row}: Cell) => <div>{row.values.status ? 'Aktif' : 'Pasif'}</div>,
      },
      {
        Header: 'Landing Yapısı',
        accessor: 'is_old_landing',
        manualWidth: 170,
        maxWidth: 170,
        Cell: ({row}: Cell) => <div>{row.values.is_old_landing ? 'Eski' : 'Yeni'}</div>,
      },
      {
        Header: () => <div style={{textAlign: 'right'}}>Aksiyon</div>,
        maxWidth: 120,
        manualWidth: 120,
        accessor: 'col5',
        id: 'click-me-button',
        Cell: ({row}: Cell) => (
          <div className='text-end'>
            <Tooltip title={'Güncelle'}>
              <Link
                to={`/landings/${row.values.landing_id}/update`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 badge-update'
                style={{color: 'unset'}}
              >
                <EditOutlined />
              </Link>
            </Tooltip>

            <Tooltip title={'Sil'}>
              <Popconfirm
                title='Bu badge silmek istediğinden emin misin ?'
                onConfirm={(e) => sendDeleteLanding(e, row.values.landing_id)}
                okButtonProps={{loading: confirmLoading}}
                okText='Evet'
                cancelText='Hayır'
              >
                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                  <DeleteOutlined />
                </div>
              </Popconfirm>
            </Tooltip>
          </div>
        ),
      },
    ],
    [landings]
  )

  const sendDeleteLanding = (e: React.MouseEvent<HTMLElement> | undefined, landingId: number) => {
    e?.preventDefault()

    deleteLandingRequest(landingId).then(() => {
      setLandings((landings) => {
        return landings.filter((landing) => {
          return landing.landing_id !== landingId
        })
      })
    })
  }

  const getLandings = () => {
    getLandingsRequest().then((res) => {
      setLandings(res.data.data)
    })
  }

  useEffect(() => {
    getLandings()
  }, [])

  return (
    <>
      <LandingListContext.Provider value={{landings, columns}}>
        {children}
      </LandingListContext.Provider>
    </>
  )
}
