import React, {FC, useContext} from 'react'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {MenuMapContext, MenuMapProvider} from '../../../../providers/tables/MenuMapProvider'
import {getMenuExportFrontendUrl} from '../../../../requests/menus/MenuExportRequest'
import {KrcSelect} from '../../../atoms/forms/KrcSelect'
import {KrcTabs} from '../../../molecules/KrcTabs'
import {Toolbar} from '../../../organisms/toolbars/Toolbar'
import {MenuActivityList} from './MenuActivityList'
import {MenuListing} from './MenuListing'

const MenuMapWrapperWithProvider: FC = () => {
  const {currentStore, selectCurrentStore, options, activeTabKey, setActiveTabKey} =
    useContext(MenuMapContext)

  return (
    <>
      <Toolbar>
        {activeTabKey === 'menu-management' && (
          <div className='d-flex align-items-center py-1'>
            <a
              type={'button'}
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder mr-3'
              href={getMenuExportFrontendUrl(currentStore?.store_id)}
              target={'_blank'}
              rel='noreferrer'
            >
              Dışarı Aktar
            </a>
            <span className={'fs-5 mr-3 text-dark fw-bolder'}>Mağazalar:</span>
            {currentStore && Object.keys(currentStore).length > 0 && (
              <KrcSelect
                showSearch
                defaultValue={currentStore.store_id}
                options={options}
                onChange={(data) => {
                  if (!selectCurrentStore) {
                    return
                  }
                  selectCurrentStore(data)
                }}
                style={{width: 200}}
                placeholder='Arama'
                optionFilterProp='children'
              />
            )}
          </div>
        )}
      </Toolbar>
      <PageTitle>Menü</PageTitle>
      <KrcTabs
        isActiveKey={true}
        onActiveKeyChange={(activeKey) => {
          activeKey && setActiveTabKey(activeKey)
        }}
        tabsProps={{defaultActiveKey: activeTabKey}}
        tabsClassName={''}
        tabProps={[
          {
            eventKey: 'menu-management',
            title: 'Menü Yönetimi',
            children: <MenuListing />,
          },
          {
            eventKey: 'activities',
            title: 'Menü Aktiviteleri',
            children: <MenuActivityList />,
          },
        ]}
      />
    </>
  )
}

export const MenuMapWrapper: FC = () => {
  return (
    <>
      <MenuMapProvider>
        <MenuMapWrapperWithProvider />
      </MenuMapProvider>
    </>
  )
}
