import {message} from 'antd'
import {Field, Form, Formik, FormikHelpers} from 'formik'
import {useNavigate} from 'react-router-dom'

import {getOrder} from '../../../../providers/orders/OrderQuestioningProvider'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'

interface FormValues {
  order: string
}

const LeftForm: React.FC = () => {
  const navigate = useNavigate()

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const orderNumber = Number(values.order)

    if (isNaN(orderNumber)) {
      message.error('Sipariş ID geçerli bir sayı değil.')
      return
    }

    try {
      const response = await getOrder(orderNumber)

      if (response && response.data && response.data.data) {
        navigate(`/orders/${orderNumber}/details`)
      } else {
        message.error('Sipariş bulunamadı.')
      }
    } catch (error) {
      message.error('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.')
    }
  }

  return (
    <Formik
      initialValues={{order: ''}}
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors: Partial<FormValues> = {}
        if (!values.order) {
          errors.order = 'Sipariş ID boş olamaz'
        }
        return errors
      }}
    >
      {({isSubmitting}) => (
        <Form style={{display: 'flex', flexWrap: 'wrap'}}>
          <KrcFormikField name={'order'}>
            <Field type='text' name='order' className='form-control' placeholder='Sipariş ID' />
          </KrcFormikField>

          <div className={'d-flex'} style={{marginLeft: '16px'}}>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={isSubmitting}
              style={{height: 'fit-content'}}
            >
              Sorgula
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default LeftForm
