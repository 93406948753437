import {message} from 'antd'
import cn from 'classnames'
import React, {FC, useEffect, useState} from 'react'

import {KrcButton} from '../../atoms/buttons/KrcButton'
import {KrcSelect} from '../../atoms/forms/KrcSelect'
import {KrcLink} from '../../atoms/links/KrcLink'
import {useProductListFilter} from '../../hooks/products/useProductListFilter'
import {IProductListFilter} from '../../models/products/ProductListFilterModel'
import {InputConstraintFilter} from '../../molecules/filters/InputConstraintFilter'
import {ProductAdvancedFilter} from './ProductAdvancedFilter'

const productConstraintPlaceholderList = {
  product_name: 'örn: Karaca Hatır',
  model: 'örn: 123.45.67.789',
  product_id: 'örn: 123',
  sku: 'örn: 123456789',
}
const productConstraintOptions = [
  {
    value: 'model',
    label: 'Ürün kodu',
  },
  {
    value: 'product_name',
    label: 'Ürün Adı',
  },
  {
    value: 'product_id',
    label: "Ürün Id'si",
  },
  {
    value: 'sku',
    label: 'Ürün Sku',
  },
]

export const ProductListFilter: FC<IProductListFilter> = ({
  onChange,
  updateMetaData,
  onChangeMetaData,
  onChangeFilterType,
  willBeFilteredProductIds,
}) => {
  const {
    updateFilter,
    statusOptions,
    stockStatusOptions,
    getProductFilter,
    data,
    setData,
    setMeta,
    showAdvancedFilter,
    updateShowAdvanceFilter,
    isFilterLoading,
    getExportProductFilterListUrl,
    clear,
    setClear,
  } = useProductListFilter(onChangeMetaData, updateMetaData)
  const [clearAdvancedFilter, setClearAdvancedFilter] = useState(false)
  const [keywordValue, setKeywordValue] = useState<string>('')
  const [productConstraintSelectValue, setProductConstraintSelectValue] = useState<string>('model')

  const handleOnChangeKeywordValue = (newValue: string) => {
    setKeywordValue(newValue)
  }

  const handleClearAdvancedFilter = () => {
    setClearAdvancedFilter(true)
    setData([])
    setMeta({limit: 10, page: 1})
  }

  useEffect(() => {
    if (!onChange) {
      return
    }

    onChange(data)
  }, [data])

  useEffect(() => {
    if (!onChangeFilterType) {
      return
    }
    onChangeFilterType(showAdvancedFilter)
  }, [showAdvancedFilter])

  return (
    <>
      <div className={'mb-3'}>
        <div className={'d-flex justify-content-between'}>
          <div className={'d-flex gap-7'}>
            <InputConstraintFilter
              clear={clear}
              onChanged={(data) => {
                updateFilter({
                  product_id: null,
                  model: null,
                  sku: null,
                  product_name: null,
                  [data.key]: data.value,
                })
                setProductConstraintSelectValue(data.key)
              }}
              selectProps={{
                options: productConstraintOptions,
                value: productConstraintSelectValue,
              }}
              placeholderList={productConstraintPlaceholderList}
            />
            <KrcSelect
              placeholder={'Stok Durumu'}
              onChange={(data) => {
                updateFilter({stock_status: data ? parseInt(data) : null})
              }}
              clear={clear}
              options={stockStatusOptions}
              className={'select-filter-custom'}
            />
            <KrcSelect
              placeholder={'Durum'}
              onChange={(data) => {
                updateFilter({status: data ? parseInt(data) : null})
              }}
              clear={clear}
              options={statusOptions}
              className={'select-filter-custom'}
            />
          </div>
          <div className={'d-flex gap-2'}>
            <KrcButton
              type={'default'}
              className={'border-2 border-danger text-danger'}
              style={{borderRadius: 5}}
              onClick={() => {
                message.success(
                  'Filtreler Temizlendi. Temizlenmemiş olabilecek filtreler için kontrol sağlamayı unutmayın.'
                )
                setClear((prevState) => prevState + 1)
                handleClearAdvancedFilter()
              }}
            >
              Filtreleri Temizle
            </KrcButton>
            <KrcButton
              type={'default'}
              loading={isFilterLoading}
              style={{borderRadius: 5, width: 100}}
              onClick={() => {
                getProductFilter(1)
              }}
            >
              Filtrele
            </KrcButton>
            <KrcLink
              type={'default'}
              className={'ant-btn ant-btn-default'}
              style={{borderRadius: 5}}
              to={getExportProductFilterListUrl(willBeFilteredProductIds)}
              target={'_blank'}
            >
              Sonuçları Dışarı Aktar
            </KrcLink>
            <KrcButton
              type={'default'}
              style={{borderRadius: 5}}
              onClick={() => {
                updateShowAdvanceFilter(!showAdvancedFilter)
              }}
            >
              <i className='bi bi-sliders' />
            </KrcButton>
          </div>
        </div>
        <ProductAdvancedFilter
          onChanged={(filters: any) => {
            updateFilter(filters)
          }}
          clearFilter={clearAdvancedFilter}
          handleOnChangeKeywordValue={handleOnChangeKeywordValue}
          keywordValue={keywordValue}
          onClearFilter={() => setClearAdvancedFilter(false)}
          clear={clear}
          className={
            cn({'d-flex': showAdvancedFilter, 'd-none': !showAdvancedFilter}) + ' flex-column mt-10'
          }
        />
      </div>
    </>
  )
}
