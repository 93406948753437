import axios from 'axios'

import {paginationMetaType} from '../../components/models/GeneralModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const IBM_GROUP_URL = `${API_URL}/ibm/groups`

export function fetchIBMGroupsRequest(
  filter: any,
  {page, limit}: paginationMetaType,
  all_data: number = 0
) {
  return axios.get(`${IBM_GROUP_URL}`, {
    params: {
      page,
      limit,
      all_data,
      ...filter,
    },
  })
}

export function getIBMGroupInformationByIdRequest(id: number) {
  return axios.get(`${IBM_GROUP_URL}/${id}`)
}

export function createIBMGroupRequest(data: any) {
  return axios.post(`${IBM_GROUP_URL}`, data)
}

export function updateIBMGroupRequest(id: number, data: any) {
  return axios.put(`${IBM_GROUP_URL}/${id}`, data)
}

export function updateIBMGroupSort(data: number[]) {
  return axios.put(`${IBM_GROUP_URL}/sort`, {
    ibm_group_ids: data,
  })
}

export function getIBMGroupCampaignsRequest(
  filter: any,
  {page, limit}: paginationMetaType,
  campaignGroupId: number,
  all_data: number = 0,
  is_sort: number = 0
) {
  return axios.get(`${IBM_GROUP_URL}/${campaignGroupId}/campaigns`, {
    params: {
      page,
      limit,
      all_data,
      is_sort,
      ...filter,
    },
  })
}

export function getIBMGroupExportFrontendUrl() {
  return `/ibm/groups/export`
}

export function getIBMGroupsAndCampaignsExportFrontendUrl() {
  return `/ibm/groups/export-all`
}

export function exportIBMGroupRequest() {
  return axios.get(`${IBM_GROUP_URL}/export`)
}

export function exportIBMGroupsAndCampaignsRequest() {
  return axios.get(`${IBM_GROUP_URL}/export-all`)
}

export function checkIBMGroupExportRequest(fileName: string) {
  return axios.get(`${IBM_GROUP_URL}/check-export`, {
    params: {
      file_name: fileName,
    },
  })
}

export function checkIBMGroupsAndCampaignsExportRequest(fileName: string) {
  return axios.get(`${IBM_GROUP_URL}/check-export-all`, {
    params: {
      file_name: fileName,
    },
  })
}
