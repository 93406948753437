import {Space, Spin} from 'antd'
import {FC, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {ExcelImportStatusEnum} from '../../../../../enums/ExcelImportStatusEnum'
import {KrcButton} from '../../../../atoms/buttons/KrcButton'
import {useCampaignWaitingApprovalImport} from '../../../../hooks/categories/campaigns/import/useCampaignWaitingApprovalImport'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'

export const CampaignWaitingApprovalImporting: FC = () => {
  const navigate = useNavigate()
  const [isImporting, setIsImporting] = useState<boolean>(true)

  const {importId} = useParams()
  const {checkImportStatus, excelImportStatus} = useCampaignWaitingApprovalImport()

  useEffect(() => {
    if (!importId) {
      navigate('/error/404')
      return
    }

    setIsImporting(true)
    checkImportStatus(parseInt(importId))
  }, [])

  useEffect(() => {
    if (
      excelImportStatus &&
      [
        ExcelImportStatusEnum.STATUS_ERROR,
        ExcelImportStatusEnum.STATUS_VALIDATION_ERROR,
        ExcelImportStatusEnum.STATUS_AUTHORIZATION_ERROR,
        ExcelImportStatusEnum.STATUS_COMPLETED,
      ].includes(excelImportStatus)
    ) {
      setIsImporting(false)
    } else {
      setIsImporting(true)
    }
  }, [excelImportStatus])

  const navigateToCampaignWaitingApprovalExcelImportPage = () => {
    navigate('/categories/campaigns-waiting-approval/import')
  }

  return (
    <>
      <Toolbar></Toolbar>
      <PageTitle>Toplu Kampanya Ürünü Onaylama/Reddetme</PageTitle>
      <div className={'d-flex justify-content-center align-items-start h-100'}>
        <Space size='middle'>
          {isImporting ? (
            <>
              {excelImportStatus === ExcelImportStatusEnum.STATUS_UPLOADED ? (
                <div className='d-flex flex-column'>
                  <span>Import edilmesi için kuyrukta bekliyor. Lütfen bekleyiniz...</span>
                  <Spin className={'mt-2'} size={'large'}></Spin>
                </div>
              ) : (
                <div className='d-flex flex-column'>
                  <span>Import ediliyor.. Lütfen bekleyin</span>
                  <Spin className={'mt-2'} size={'large'}></Spin>
                </div>
              )}
            </>
          ) : (
            <>
              {excelImportStatus === ExcelImportStatusEnum.STATUS_COMPLETED ? (
                <div className='d-flex flex-column justify-content-center text-center'>
                  <span>Başarıyla Import edildi!</span>
                  <KrcButton
                    className={'mt-2'}
                    onClick={navigateToCampaignWaitingApprovalExcelImportPage}
                    id='kt_toolbar_primary_button'
                  >
                    {'Toplu Kampanya Ürünü Onaylama/Reddetme ekranına geri dön'}
                  </KrcButton>
                </div>
              ) : (
                <div className='d-flex flex-column justify-content-center text-center'>
                  <span>Ürünler import edilemedi!</span>
                  <KrcButton
                    className={'mt-2'}
                    onClick={navigateToCampaignWaitingApprovalExcelImportPage}
                  >
                    {'Toplu Kampanya Ürünü Onaylama/Reddetme ekranına geri dön'}
                  </KrcButton>
                </div>
              )}
            </>
          )}
        </Space>
      </div>
    </>
  )
}
