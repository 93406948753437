import {message} from 'antd'
import {Field, Form, Formik, FormikValues} from 'formik'
import React, {FC, useContext} from 'react'
import {Modal} from 'react-bootstrap-v5'

import {IBMContext} from '../../../../providers/ibm/IBMProvider'
import {ICreateIBMGroupModalProps, createIBMGroupDataType} from '../../../models/ibm/IBMGroupModel'
import {KrcFormikField} from '../../../molecules/forms/KrcFormikField'
import {KrcModal} from '../../KrcModal'

const formikInitialValues = {
  ibm_group_name: '',
  ibm_group_title: '',
  ibm_group_status: 0,
  ibm_group_sort: 0,
}

export const CreateIBMGroupModal: FC<ICreateIBMGroupModalProps> = ({onHide}) => {
  const {createIBMGroup} = useContext(IBMContext)
  const handleOnSubmit = (values: FormikValues) => {
    if (!values.ibm_group_name) {
      message.error('IBM Grup Adı boş bırakılamaz')
      return
    }
    createIBMGroup(values as createIBMGroupDataType)
  }

  return (
    <>
      <KrcModal
        modalProps={{
          size: 'lg',
        }}
        modalHeaderProps={{
          title: 'IBM Grup Oluştur',
          onHide,
        }}
      >
        <Modal.Body className='py-lg-10 px-lg-10'>
          <Formik initialValues={formikInitialValues} onSubmit={handleOnSubmit}>
            {() => {
              return (
                <Form className='form mt-6 space-between'>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'ibm_group_name'}
                        labelTitle={'IBM Grup Adı'}
                      />
                    </div>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'text'}
                        name={'ibm_group_title'}
                        labelTitle={'IBM Grup Başlığı'}
                        tooltipProps={{
                          status: true,
                          text: 'Grubun başlığını giriniz.',
                        }}
                      />
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'w-100'}
                        name={'ibm_group_status'}
                        labelTitle={'IBM Grup Durum'}
                        required
                        tooltipProps={{
                          status: true,
                          text: 'Grubun yayın durumunu seçiniz',
                        }}
                      >
                        <Field as='select' name='ibm_group_status' className='form-select'>
                          <option value={''} disabled={true}>
                            Seçiniz
                          </option>
                          <option value={1}>Aktif</option>
                          <option value={0}>Pasif</option>
                        </Field>
                      </KrcFormikField>
                    </div>
                    <div className={'col-6'}>
                      <KrcFormikField
                        className={'mb-2'}
                        type={'number'}
                        name={'ibm_group_sort'}
                        labelTitle={'IBM Grup Sırası'}
                        tooltipProps={{
                          status: true,
                          text: 'Grubun sırasını giriniz',
                        }}
                      />
                    </div>
                  </div>
                  <div className={'d-flex justify-content-end mt-7'}>
                    <button
                      type='button'
                      onClick={onHide}
                      className='btn btn-secondary'
                      style={{marginLeft: 10}}
                    >
                      İptal
                    </button>
                    <button type='submit' className='btn btn-primary' style={{marginLeft: 10}}>
                      Kaydet
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </KrcModal>
    </>
  )
}
