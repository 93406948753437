import {DeleteOutlined} from '@ant-design/icons'
import {Popconfirm, Tooltip, message} from 'antd'
import debounce from 'lodash/debounce'
import React, {createContext, useEffect, useMemo, useRef, useState} from 'react'
import {Cell, Column} from 'react-table'

import {KrcEmptyFilter} from '../../components/atoms/KrcEmptyFilter'
import {paginationMetaType} from '../../components/models/GeneralModel'
import {
  PermissionDataType,
  createPermissionDataType,
} from '../../components/models/permissions/PermissionModel'
import {removeEmptyItemInObject} from '../../helpers/ObjectHelper'
import {
  createPermissionRequest,
  deletePermissionRequest,
  fetchPermissionsRequest,
} from '../../requests/permissions/PermissionRequest'
import {initialMetaData} from '../tables/ProductSelectionProvider'

interface IPermissionContext {
  isLoading: boolean
  meta: paginationMetaType
  setMeta: React.Dispatch<React.SetStateAction<paginationMetaType>>
  filter: any
  columns: Column<any>[]
  setFilter: React.Dispatch<React.SetStateAction<any>>
  handleOnFilter: (data: any, key: string | number) => void
  PermissionData: PermissionDataType[]
  showCreateModal: boolean
  setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>
  createPermission: (data: createPermissionDataType) => void
  deletePermission: (permissionId: number) => void
}

export const PermissionContext = createContext<IPermissionContext>({
  isLoading: false,
  meta: initialMetaData,
  setMeta: () => {},
  filter: {},
  setFilter: () => {},
  handleOnFilter: () => {},
  columns: [],
  PermissionData: [],
  showCreateModal: false,
  setShowCreateModal: () => {},
  createPermission: () => {},
  deletePermission: () => {},
})

export const PermissionProvider = ({children}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [meta, setMeta] = useState<paginationMetaType>(initialMetaData)
  const initialRef = useRef(0)
  const [filter, setFilter] = useState<any>({})
  const [PermissionData, setPermissionData] = useState<PermissionDataType[]>([])

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 100,
      },
      {
        Header: 'İzin Adı',
        accessor: 'name',
      },
      {
        Header: 'İşlemler',
        accessor: 'actions',
        Filter: <KrcEmptyFilter />,
        Cell: ({row}: Cell<any>) => (
          <>
            <div className='d-flex flex-row justify-content-around'>
              <Tooltip title={'Sil'}>
                <Popconfirm
                  title='Bu izni silmek istediğinden emin misin ?'
                  onConfirm={(e) => deletePermission(Number(row.values.id))}
                  okButtonProps={{loading: isLoading}}
                  okText='Evet'
                  cancelText='Hayır'
                >
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <DeleteOutlined />
                  </div>
                </Popconfirm>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getPermissions()
  }, [meta.limit])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    getPermissions()
  }, [meta.page])

  useEffect(() => {
    if (initialRef.current === 0) {
      return
    }
    if (meta.page !== 1) {
      setMeta((data: paginationMetaType) => ({...data, page: 1}))
      return
    }
    getPermissions()
  }, [filter])

  useEffect(() => {
    initialRef.current = 1
    getPermissions()
  }, [])

  const handleOnFilter = React.useMemo(() => {
    const loadTableFilter = (value: any, rowId: string | number) => {
      setFilter((data: any) => {
        return {...data, ...{[rowId]: value}}
      })
    }
    return debounce(loadTableFilter, 250)
  }, [])

  const getPermissions = () => {
    const _filter = removeEmptyItemInObject(filter)
    setIsLoading(true)
    fetchPermissionsRequest(_filter, meta)
      .then((response) => {
        const _meta = response.data.meta
        setMeta({limit: _meta.per_page, page: _meta.current_page, ..._meta})
        setPermissionData(response.data.data)
      })
      .catch((error) => {
        message.error('İzinler getirilirken bir hata oluştu.')
        message.error(error.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const createPermission = (data: createPermissionDataType) => {
    message.info('İzin oluşturuluyor...')
    createPermissionRequest(data)
      .then((response) => {
        message.success('İzin başarıyla oluşturuldu.')
        setShowCreateModal(false)
        getPermissions()
      })
      .catch((error) => {
        message.error('İzin oluşturulurken bir hata oluştu.')
        message.error(error.response.data.message)
      })
  }

  const deletePermission = (permissionId: number) => {
    message.info('İzin siliniyor...')
    deletePermissionRequest(permissionId)
      .then((response) => {
        message.success('İzin başarıyla silindi.')
        getPermissions()
      })
      .catch((error) => {
        message.error('İzin silinirken bir hata oluştu.')
        message.error(error.response.data.message)
      })
  }

  return (
    <>
      <PermissionContext.Provider
        value={{
          isLoading,
          meta,
          setMeta,
          filter,
          setFilter,
          columns,
          handleOnFilter,
          PermissionData,
          showCreateModal,
          setShowCreateModal,
          createPermission,
          deletePermission,
        }}
      >
        {children}
      </PermissionContext.Provider>
    </>
  )
}
