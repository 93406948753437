import debounce from 'lodash/debounce'
import React, {useState} from 'react'

import {fetchKeywordAccordingTheSearch} from '../../../requests/url/SeoUrlRequest'

export const useSeoUrl = (debounceTime = 500) => {
  const [type, setType] = useState<string>('')
  const [typeId, setTypeId] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const fetchRef = React.useRef(0)

  const handleSearch = React.useMemo(() => {
    const loadOptions = (search: string) => {
      if (search.length < 2) {
        return
      }

      fetchRef.current += 1
      const fetchId = fetchRef.current
      setType('')
      setIsLoading(true)
      fetchKeywordAccordingTheSearch(search).then((res) => {
        if (fetchId !== fetchRef.current) {
          return
        }
        setType(res.data.data.type || '')
        setTypeId(parseInt(res.data.data.type_id || 0))
        setIsLoading(false)
      })
    }

    return debounce(loadOptions, debounceTime)
  }, [])

  return {
    type,
    typeId,
    isLoading,
    handleSearch,
  }
}
