import React, {FC, useContext} from 'react'

import {ModalEnum} from '../../../enums/ModalEnum'
import {MenuMapContext} from '../../../providers/tables/MenuMapProvider'
import {IMenuMap} from '../../models/menus/MenuMapModel'
import {MappingTableBody} from '../../organisms/mapping_table/MappingTableBody'
import {MappingTableHeader} from '../../organisms/mapping_table/MappingTableHeader'

export const MenuMap: FC<IMenuMap> = ({data, onSelect, headerProps, deep, parentData}) => {
  const {columns, updateSelectedMenu, openMenuModal} = useContext(MenuMapContext)

  return (
    <>
      <MappingTableHeader {...headerProps}>
        <div
          className={'cursor-pointer'}
          onClick={(e) => {
            e.preventDefault()

            openMenuModal(ModalEnum.CREATE, deep, parentData)
          }}
        >
          Menu ekle
        </div>
      </MappingTableHeader>

      <MappingTableBody
        data={data}
        columns={columns}
        onSelect={(data: any) => {
          if (updateSelectedMenu) {
            updateSelectedMenu(data.original.data, deep)
          }
          if (onSelect) {
            onSelect(data)
          }
        }}
      />
    </>
  )
}
