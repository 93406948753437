import {Card, Image, Tag, Tooltip} from 'antd'
import {FC, useEffect, useState} from 'react'

import {IKrcGridTableProductObject} from '../models/components/GridProductTableModel'

const {Meta} = Card
export const KrcGridProductCard: FC<IKrcGridTableProductObject> = ({
  id,
  name,
  image,
  model,
  status,
}) => {
  const [productStatus, setProductStatus] = useState<string>('')
  const [tagColor, setTagColor] = useState<string>('')

  useEffect(() => {
    if (status === 1) {
      setProductStatus('Aktif')
      setTagColor('green')
    } else {
      setProductStatus('Pasif')
      setTagColor('default')
    }
  }, [status])

  return (
    <>
      <Card style={{width: 300}}>
        <Image src={image} width={250} height={250} alt={name} style={{marginBottom: 20}} />
        <Tooltip placement='topRight' title={name}>
          <Meta title={name} style={{marginTop: 20}} />
        </Tooltip>
        <section style={{marginTop: 12}}>
          <p>Model: {model}</p>
          <p>Ürün Id: {id}</p>
        </section>
        <Tag style={{marginTop: 10}} color={tagColor}>
          {productStatus}
        </Tag>
      </Card>
    </>
  )
}
