import axios from 'axios'

import {paginationMetaType} from '../../../components/models/GeneralModel'
import {categoryLinkedPromotionFilterType} from '../../../components/models/categories/CategoriesPromotionModel'

const API_URL = process.env.REACT_APP_API_URL || ''
const CATEGORY_URL = `${API_URL}/categories`

export function getCategoryLinkedPromotionsRequest(
  categoryId: number,
  filters: categoryLinkedPromotionFilterType,
  meta: paginationMetaType
) {
  return axios.get(`${CATEGORY_URL}/${categoryId}/promotions`, {
    params: {
      ...filters,
      limit: meta.limit,
      page: meta.page,
    },
  })
}
