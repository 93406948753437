import {Route, Routes} from 'react-router-dom'

import {ExcludeCouponListWrapper} from '../components/pages/campaign_and_coupon/list/ExcludeCouponListWrapper'
import {CouponCreateFormWrapper} from '../components/pages/campaign_and_coupon/manage/CouponCreateFormWrapper'
import {CouponManagerWrapper} from '../components/pages/campaign_and_coupon/manage/CouponManagerWrapper'
import {CouponUpdateFormWrapper} from '../components/pages/campaign_and_coupon/manage/CouponUpdateFormWrapper'
import {CouponPrefixWrapper} from '../components/pages/campaign_and_coupon/prefix/CouponPrefixWrapper'

export const CampaignAndCouponRoutes = () => {
  return (
    <Routes>
      <Route path={'/'} element={<CouponManagerWrapper />} />
      <Route path={'/create'} element={<CouponCreateFormWrapper />} />
      <Route path={'/prefix'} element={<CouponPrefixWrapper />} />
      <Route path={'/exclude'} element={<ExcludeCouponListWrapper />} />
      <Route path={'/:couponId'} element={<CouponUpdateFormWrapper />} />
    </Routes>
  )
}
