import React, {FC, useContext} from 'react'
import {useLocation} from 'react-router-dom'

import {PageTitle} from '../../../../../../_metronic/layout/core'
import {
  ACTIVE_CATEGORY_PRODUCT_SORT_METHOD,
  MANUAL_SORTING,
} from '../../../../../enums/CategorySortingPageTypeEnum'
import {
  CategoryProductSortingContext,
  CategoryProductSortingProvider,
} from '../../../../../providers/category/sorting/CategoryProductSortingProvider'
import {KrcButton} from '../../../../atoms/buttons/KrcButton'
import {CategoryTypeEnum} from '../../../../models/categories/CategoryModel'
import {KrcTabs} from '../../../../molecules/KrcTabs'
import {EditorChoiceToolbar} from '../../../../molecules/toolbars/categories/products/sorts/EditorChoiceToolbar'
import {Toolbar} from '../../../../organisms/toolbars/Toolbar'
import {CategoryProductSorting} from './CategoryProductSorting'
import {CategoryProductSortingView} from './CategoryProductSortingView'
import {CategoryDefaultSortActivityList} from './logs/CategoryDefaultSortActivityList'
import {CategoryEditorsChoiceActivityList} from './logs/CategoryEditorsChoiceActivityList'

const CategorySortingWrapperWithoutProvider: FC = () => {
  const location = useLocation()

  const {category, pageType, activeSortMethod, deleteActiveCategoryProductSortMethod} = useContext(
    CategoryProductSortingContext
  )

  return (
    <>
      <Toolbar>
        {category?.category_type !== CategoryTypeEnum.GHOST && (
          <div className={'d-flex'}>
            <div className='mr-4 d-flex flex-column justify-content-center align-items-center'>
              <span>Aktif Sıralama: </span>
              <span>
                <b>
                  {activeSortMethod && ACTIVE_CATEGORY_PRODUCT_SORT_METHOD[activeSortMethod]
                    ? ACTIVE_CATEGORY_PRODUCT_SORT_METHOD[activeSortMethod]
                    : 'Aktif Sıralama Yok'}
                </b>
              </span>
            </div>
            {(activeSortMethod ?? false) && (
              <KrcButton
                danger
                size={'middle'}
                onClick={() => {
                  deleteActiveCategoryProductSortMethod()
                }}
                className={'mr-3'}
                id='kt_toolbar_primary_button'
              >
                Aktif Sıralamayı kaldır
              </KrcButton>
            )}
            {location.hash === '#sorting' && pageType === MANUAL_SORTING && <EditorChoiceToolbar />}
          </div>
        )}
      </Toolbar>
      <PageTitle>
        Kategori Sıralama İşlemleri{category && category?.details && ' - ' + category.details.name}
      </PageTitle>
      <KrcTabs
        isActiveKey={true}
        tabsProps={{defaultActiveKey: 'view'}}
        tabsClassName={''}
        tabProps={[
          {
            eventKey: 'view',
            title: 'Görünüm',
            children: <CategoryProductSortingView />,
          },
          {
            eventKey: 'sorting',
            title: 'Sıralama',
            children: <CategoryProductSorting />,
          },
          {
            eventKey: 'editors-choice-activities',
            title: 'Editörün Seçimi Aktiviteleri',
            disabled: category?.category_type === CategoryTypeEnum.GHOST,
            children: <CategoryEditorsChoiceActivityList />,
          },
          {
            eventKey: 'default-sort-activities',
            title: 'Varsayılan Sıralama Aktiviteleri',
            children: <CategoryDefaultSortActivityList />,
          },
        ]}
      />
    </>
  )
}
export const CategoryProductSortingWrapper: FC = () => {
  return (
    <CategoryProductSortingProvider>
      <CategorySortingWrapperWithoutProvider />
    </CategoryProductSortingProvider>
  )
}
